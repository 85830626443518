import { combineReducers } from "redux";
import { clone } from 'lodash';


const isLoading = (state=false, action) => {
  switch(action.type) {
    case 'LOAD_TALENT_EVENTS_REQUEST':
      return true;
      
    case 'LOAD_TALENT_EVENTS_SUCCESS':
      return false;
      
    default:
      return state;
  }
};

const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_PACK_EVENTS_SUCCESS':
    case 'LOAD_TALENT_EVENTS_SUCCESS':
    case 'LOAD_AGENCY_EVENTS_SUCCESS':
      const newState = clone(state);
      for(const id in action.events) {
        newState[id] = action.events[id];
      }
      return newState;
      
    case 'LOAD_EVENT':
    case 'UPDATE_EVENT':
    case 'ADD_EVENT':
      return {
        ...state,
        [action.eventId]: action.event, 
      };
      
    case 'REMOVE_EVENT': 
      const { [action.eventId]:removedEvent, ...events } = state;
      return events;
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byPack = (state={}, action) => {
  let newState = {};
  
  switch(action.type) {
    
    case 'LOAD_PACK_EVENTS_SUCCESS':
      return {
        ...state,
        [action.packId]: {...state[action.packId], ...action.events}
      };
      
    case 'UPDATE_EVENT':
      for(const packId in state) {
        newState[packId] = {};
        for(const eventId in state[packId]) {
          newState[packId][eventId] = (eventId === action.eventId) 
            ? action.event 
            : state[packId][eventId];
        }
      }
      return newState;
      
    case 'ADD_EVENT':
      if(!action.packId) {
        return state;
      }
      return {
        ...state,
        [action.packId]: {
          ...state[action.packId],
          [action.event.id]: action.event,
        }, 
      };
      
    case 'REMOVE_EVENT':
      for(const packId in state) {
        newState[packId] = {};
        for(const eventId in state[packId]) {
          if(eventId !== action.eventId) {
            newState[packId][eventId] = state[packId][eventId];
          }
        }
      }
      return newState;
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byTalent = (state={}, action) => {
  let newState = {};
  
  switch(action.type) {
    case 'LOAD_TALENT_EVENTS_SUCCESS':
      return {
        ...state,
        [action.talentId]: {...state[action.talentId], ...action.events}
      };
      
    case 'UPDATE_EVENT':
      for(const talentId in state) {
        newState[talentId] = {};
        for(const eventId in state[talentId]) {
          newState[talentId][eventId] = (eventId === action.eventId) 
            ? action.event 
            : state[talentId][eventId];
        }
      }
      return newState;
      
    case 'ADD_EVENT':
      if(!action.talentId) {
        return state;
      }
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          [action.event.id]: action.event,
        }, 
      };
      
    case 'REMOVE_EVENT':
      for(const talentId in state) {
        newState[talentId] = {};
        for(const eventId in state[talentId]) {
          if(eventId !== action.eventId) {
            newState[talentId][eventId] = state[talentId][eventId];
          }
        }
      }
      return newState;
      
    case 'LOGOUT':
      return {};
    
    default: 
      return state;
  }
};

const byAgency = (state={}, action) => {
  let newState = {};
  
  switch(action.type) {
    
    case 'LOAD_AGENCY_EVENTS_SUCCESS':
      return {
        ...state,
        [action.agencyId]: {...state[action.agencyId], ...action.events}
      };
      
    case 'UPDATE_EVENT':
      for(const agencyId in state) {
        newState[agencyId] = {};
        for(const eventId in state[agencyId]) {
          newState[agencyId][eventId] = (eventId === action.eventId) 
            ? action.event 
            : state[agencyId][eventId];
        }
      }
      return newState;
      
    case 'ADD_EVENT':
      if(!action.agencyId) {
        return state;
      }
      return {
        ...state,
        [action.agencyId]: {
          ...state[action.agencyId],
          [action.event.id]: action.event,
        }, 
      };
      
    case 'REMOVE_EVENT':
      for(const agencyId in state) {
        newState[agencyId] = {};
        for(const eventId in state[agencyId]) {
          if(eventId !== action.eventId) {
            newState[agencyId][eventId] = state[agencyId][eventId];
          }
        }
      }
      return newState;
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byUser = (state={}, action) => {
  let newState = {};
  
  switch(action.type) {
    
    case 'LOAD_USER_EVENTS_SUCCESS':
      return {
        ...state,
        [action.userId]: {...state[action.userId], ...action.events}
      };
      
    case 'UPDATE_EVENT':
      for(const userId in state) {
        newState[userId] = {};
        for(const eventId in state[userId]) {
          newState[userId][eventId] = (eventId === action.eventId) 
            ? action.event 
            : state[userId][eventId];
        }
      }
      return newState;
      
    case 'ADD_EVENT':
      if(!action.userId) {
        return state;
      }
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          [action.event.id]: action.event,
        }, 
      };
      
    case 'REMOVE_EVENT':
      for(const userId in state) {
        newState[userId] = {};
        for(const eventId in state[userId]) {
          if(eventId !== action.eventId) {
            newState[userId][eventId] = state[userId][eventId];
          }
        }
      }
      return newState;
      
    case 'LOGOUT':
    case 'SET_AGENCY':
      return {};
      
    default: 
      return state;
  }
};



export default combineReducers({
  isLoading,
  all,
  byPack,
  byTalent,
  byAgency,
  byUser,
});