import React, { Component } from 'react';
import { connect } from 'react-redux';
import { approveTalent, unapproveTalent, subscribeToUnapprovedTalents, unsubscribeFromUnapprovedTalents, removeTalentForApprovalFlag } from 'actions';
import TalentCard from 'ui/OldTalentCard';
import Container from 'ui/Container';
import Title from 'ui/Title';
import Button from 'ui/Button';
import Media from 'ui/Media';
import { Link } from 'react-router-dom';
import { mediaFormats } from 'config';
import MessageTalentDialog from 'ui/MessageTalentDialog';

import "./index.css";

class ApproveTalents extends Component {

  state = {
    isMessageTalentsDialogOpen: false,
    talentId: null,
  }

  componentDidMount() {
    this.props.subscribeToUnapprovedTalents();
  }
  
  componentWillUnmount() {
    this.props.unsubscribeFromUnapprovedTalents();
  }

  handleMessageTalent = talentId => {
    this.setState({
      isMessageTalentsDialogOpen: true,
      talentId,
    });
  }

  closeMessageDialog = () => this.setState({isMessageTalentsDialogOpen:false})
  
  approve = (e, talentId) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.approveTalent(talentId);
  }

  clear = (e, talentId) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.removeTalentForApprovalFlag(talentId);
  }

  render() {
    const props = this.props;
    
    if(!props.talents) {
      return null;
    }
    
    return (
      <div className="approvetalents-wrapper">
        <Container>
          <Title>Approve Talent</Title>
        
          {Object.keys(props.talents)
            .map(talentId => props.talents[talentId])
            .map(talent => {

              let image = "avatar";
              if(talent.image && talent.image.cloudinary_id) {
                image = talent.image;
              }
              else if(talent.media) {
                const img = Object.keys(talent.media)
                  .map(id => talent.media[id])
                  .sort((a, b) => a.order < b.order ? -1 : 1)
                  .find(media => !media.format || mediaFormats.indexOf(media.format) !== -1);

                if(img && img.cloudinary_id) {
                  image = img;
                }
              }

              return (
                <div className="approvetalents-item" key={talent.id}>

                  <Link to={{pathname:`/talents/${talent.id}`, state:{modal:true, forApproval:true}}}>
                    <div className="approvetalents-item-wrapper">
                      <Media
                        media={image}
                        width={48} height={48}
                        crop="fill" face icon="user"
                        backgroundColor="#f5f5f5"
                      />
                      <div style={{padding:16}}>{talent.firstName || ""} {talent.lastName || "New Talent"}</div>
                      <div style={{flex:1}}/>
                      <Button icon="approve" raised={true} label="Approve" onClick={(e) => this.approve(e, talent.id)} style={{marginRight:8}}/>
                      <Button icon="close" onClick={(e) => this.clear(e, talent.id)} style={{marginRight:8}}/>
                    </div>
                  </Link>

                  {/*
                  <TalentCard 
                    talent={talent} 
                    isSelected={talent.approved}
                    onSelect={() => {
                      if(talent.approved) {
                        props.unapproveTalent(talent.id);
                      }
                      else {
                        props.approveTalent(talent.id);
                      }
                    }}
                    onNewMessageClick={() => this.handleMessageTalent(talent.id)}
                  />
                  */}

                </div>
              );
            })
          }
        </Container>

        <MessageTalentDialog
          open={this.state.isMessageTalentsDialogOpen}
          onRequestClose={this.closeMessageDialog}
          talentId={this.state.talentId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    agencyId: state.user.agencyId,
    talents: state.agencyTalents.forApproval,
  };
};

const mapDispatchToProps = {
  approveTalent,
  unapproveTalent,
  subscribeToUnapprovedTalents,
  unsubscribeFromUnapprovedTalents,
  removeTalentForApprovalFlag,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveTalents);