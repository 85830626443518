import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import Container from 'ui/Container';
import Title, { Tab, Action } from 'ui/Title';
import { Modal, useRouter } from 'skybolt-ui';

function KitComponent(props) {
  let wrapper = useRef();
  let app = useRef();
  let instance = useRef();
  const { history } = useRouter();

  let { component, on={}, ...forwardProps } = props;

  useLayoutEffect(() => {
    if(!component) {
      return;
    }
    let Component = window.skyboltkit[component];
    if(!Component) {
      return;
    }
    if(app.current == Component) {
      if(instance.current) {
        instance.current.$set(forwardProps);
      }
      return;
    }

    if(instance.current) {
      instance.current.$destroy();
    }
    
    app.current = Component;
    instance.current = new Component({
      target:wrapper.current, 
      props:forwardProps,
    });

    for(let event in on) {
      instance.current.$on(event, on[event]);
    }

  }, [wrapper.current, window.skyboltkit, component, forwardProps]);

  useEffect(() => {
    return () => {
      if(instance.current) {
        instance.current.$destroy();
      }
    }
  }, []);

  function reroute(e) {
    let anchor = e.target.closest("a");
    if(anchor) {
      e.preventDefault();
      history.push(anchor.getAttribute('href'));
    }
  }

  return (
    <div
      onClick={reroute}
      ref={wrapper}
      style={{
        display: 'block',
        width: '100%',
      }}
    />
  );
}


export default function(props) {
  
  const { history } = useRouter();

  return (
    <Container>
      <Title 
        actions={[
          <Action icon="plus" onClick={() => history.push("/admin/scheduled-messages/new")}/>
        ]}>
        Scheduled Messages
      </Title>
      <div>
        <KitComponent component="ScheduledMessagesList" />
      </div>
      {props.match.params.id && (
        <Modal status="open" onRequestClose={() => history.push("/admin/scheduled-messages")}> 
          <KitComponent 
            component="ScheduledMessagesComposer" 
            id={props.match.params.id}
            on={{'close': () => history.push("/admin/scheduled-messages")}}
          />
        </Modal>
      )}
    </Container>
  );
}