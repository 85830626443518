import React from 'react';
import Fields from './Fields';
import ResumeSections from './ResumeSections';

export default () => (
  <div style={{display:'flex', alignItems:'flex-start', flexWrap:'wrap', margin:"0 -16px 40px -16px"}}>
    <div style={{flex:3, minWidth:300, margin:"0 16px"}}>
      <Fields/>
    </div>
    <div style={{flex:1, minWidth:300, margin:"0 16px"}}>
      <ResumeSections/>
    </div>
  </div>
);