import React, { useState } from 'react';
import { useQuery } from 'skybolt-api';
import {
  Header
} from 'skybolt-ui';
import Resume from './Resume';
import Media from './Media';
import Info from './Info';




export default props => {


  // Load Talent Data

  const data = useQuery(`{
    talent(talentId:$talentId) {
      fullName
      image(width:200, height:200, face:true)
    }
  }`, {talentId:props.talentId});



  // Active Tab

  const [tab, setTab] = useState(props.tab || "info");
  const tabEl = tab === 'resume' ? <Resume talentId={props.talentId}/> :
                tab === 'media' ? <Media talentId={props.talentId}/> :
                <Info talentId={props.talentId}/>;


  // Layout

  if(!data) {
    return null;
  }

  return (
    <div>
      <Header
        image={data.talent.image}
        name={data.talent.fullName}
        tab={tab}
        onTab={setTab}
        tabs={[
          {key:"info", label:"Info.", icon:"talent"},
          {key:"media", label:"Media", icon:"media"},
          {key:"resume", label:"Resume", icon:"resume"},
        ]}
      />
      {tabEl}
    </div>
  );
};