import { combineReducers } from "redux";


const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_AGENCIES_SUCCESS':
      return action.agencies;
      
    case 'LOAD_AGENCY_SUCCESS':
    case 'UPDATE_AGENCY':
      if(!action.agency) {
        return state;
      };
      
      return {
        ...state,
        [action.agency.id]: action.agency,
      };
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};


export default combineReducers({
  all,
});