import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  InputText,
  Button,
  ThemeContext,
  Menu,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';


export default function InputPack({
  value = {},
  style,
  className,
  onChange,
  placeholder,
  ...props
}) {

  const [query, setQuery] = useState("");

  const data = useQuery(`{
    search: packs(query:$query, limit:5) {
      packId
      name
    }
  }`, {query});

  const emitChange = pack => {
    if(onChange) {
      onChange(pack);
    }
    setQuery(pack.name || "");
  };

  const theme = useContext(ThemeContext);

  const containerRef = useRef();
  const inputRef = useRef();
  const [focused, setFocused] = useState(false);

  return (
    <div style={{display:'flex', alignItems:'flex-start', justifyContent:'stretch', ...style}} ref={containerRef} className={className}>
      <InputText
        value={query}
        onChange={query => {
          if(!query) {
            emitChange({})
          }
          setQuery(query);
        }}
        style={{flex:1, marginBottom:0}}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        ref={inputRef}
        placeholder={placeholder}
        onRule={true}
        clearable={true}
      />
      <Menu key="menu" open={query && data && data.search.length && focused} anchor={containerRef} onRequestClose={() => inputRef.current.blur()}>
        {data && data.search && data.search.map(pack =>
          <Button
            key={pack.packId}
            style={{
              display: 'block',
              minWidth: 80,
              backgroundColor: pack.packId === value.packId ? theme.color.accent : 'transparent',
              borderRadius: 0,
            }}
            onMouseDown={e => e.preventDefault()}
            onClick={e => emitChange(pack)}>
            {pack.name}
          </Button>
        )}
      </Menu>
    </div>
  );

}