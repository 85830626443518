import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';


export default ({
  width='auto', 
  height='auto',
  style={}
}) => (
  <div 
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width,
      height,
      ...style,
    }}>
    <CircularProgress size={20}/>
  </div>
);