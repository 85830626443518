import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery } from 'skybolt-api';
import {loadTransactions} from './useTransactions';
import {loadUpcomingPayments} from './useUpcomingPayments';
import ChangeAddressButton from './ChangeAddressButton';
import ChangeCCButton from './ChangeCCButton';
import {
  Text,
  Rule,
  Card,
  Button,
  Icon,
} from 'skybolt-ui';
import { isEmpty } from 'lodash';


export default function UserBilling(props) {

  const data = useQuery(`{
    me: user {
      permissions {
        canAdminTalents
      }
    }
    user(userId:$userId) {
      userId
      epayId,
    }
  }`, {userId:props.match.params.userId});

  const [transactionLimit, setTransactionLimit] = useState(10);
  const [transactions, setTransactions] = useState(null);
  const [payments, setPayments] = useState(null);
  const [card, setCard] = useState(null);
  const [address, setAddress] = useState(null);

  const userId = data ? data.user.userId : null;

  useEffect(() => {
    if(!userId) {
      return;
    }
    loadTransactions(userId).then(res => {
      if(!res) {
        setTransactions([]);
        return;
      }
      setTransactions(res);
    });
    loadUpcomingPayments(userId).then(res => {
      if(!res || !res.length) {
        setPayments(false);
        setAddress(false);
        setCard(false);
        return;
      }
      setPayments(res);
      setAddress(res[0].address || false);
      setCard(res[0].card || false);
    });
  }, [userId]);

  let nextPayment, byMonth, byYear;
  if(payments) {
    nextPayment = payments.sort((a, b) => moment(a.date).isBefore(b.date) ? -1 : 1)[0];
    byMonth = payments.filter(p => p.enabled).filter(p => p.cycle === 'month').reduce((t, p) => t += parseFloat(p.amount), 0);
    byYear = payments.filter(p => p.enabled).filter(p => p.cycle === 'year').reduce((t, p) => t += parseFloat(p.amount), 0);
  }

  return (
    <div>
      <div style={{display:'flex', alignItems:'flex-end', height:40}}>
        <Text title>BILLING</Text>
      </div>
      <Rule style={{marginBottom:24}}/>

      <div style={{marginBottom:32}}>
        {!!byMonth && <Text block large>Paying ${byMonth.toFixed(2)}/month.</Text>}
        {!!byYear && <Text block large>Paying ${byYear.toFixed(2)}/year.</Text>}
        {!!nextPayment && <Text block>Next payment of ${parseFloat(nextPayment.amount).toFixed(2)} due on {moment(nextPayment.date).format("dddd, MMMM Do YYYY")}.</Text>}
        {payments === null && (
          <div style={{textAlign:"center"}}>
            <Icon name="loading" large/>
          </div>
        )}
        {payments === false && (
          <Text block>No payment information.</Text>
        )}
        {!!data && !!data.me.permissions.canAdminTalents &&
          <Text block>USAePay: {data.user.epayId || "No user USAePay account."}</Text>
        }
      </div>

      <div style={{display:'flex', flexWrap:'wrap'}}>
        <div style={{flex:1, minWidth:300, marginRight:16, marginBottom:32}}>
          <Text title>BILLING ADDRESS</Text>
          <Rule style={{marginBottom:8}}/>
          <Card style={{padding:16}}>
            <div style={{minHeight:60}}>
              {
                // Show a loader until payments are loaded.
                // Once that happens, we should have an address.

                address !== null ? (
                  address !== false ? (
                    <Text>
                      {address.street}<br/>
                      {address.city} {address.state} {address.zip}
                    </Text>
                  )
                  : (
                    <Text>No Address</Text>
                  )
                )
                : (
                  <Icon name="loading" large/>
                )
              }
            </div>
            {data && <ChangeAddressButton userId={data.user.userId} onChange={setAddress}/>}
          </Card>
        </div>
        <div style={{flex:1, minWidth:300, marginRight:16, marginBottom:32}}>
          <Text title>PAYMENT METHOD</Text>
          <Rule style={{marginBottom:8}}/>
          <Card style={{padding:16}}>
            <div style={{minHeight:60}}>
              {
                // Show a loader until payments are loaded.
                // Once that happens, we should have an card.

                card !== null ? (
                  card !== false ? (
                    <Text>
                      Paying by credit card.<br/>
                      {card.number}<br/>
                      {card.expires} {card.zip}
                    </Text>
                  )
                  : (
                    <Text>No Credit Card</Text>
                  )
                )
                : (
                  <Icon name="loading" large/>
                )
              }
            </div>
            {data && <ChangeCCButton userId={data.user.userId} onChange={setCard}/>}
          </Card>
        </div>
      </div>

      <div style={{display:'flex', alignItems:'flex-end', height:40}}>
        <Text title>RECENT TRANSACTIONS</Text>
      </div>
      <Rule style={{marginBottom:8}}/>

      {
        // If transactions is null, render a loader.

        !transactions ? (
          <div style={{textAlign:'center', padding:40}}>
            <Icon name="loading" large/>
          </div>
        )

        // If transactions is empty, render a "Transactions will appear here"
        // message.

        : isEmpty(transactions) ? (
          <div style={{textAlign:'center', padding:40}}>
            <Text>No Transactions</Text>
          </div>
        )

        // Render each transaction in a card.

        : transactions.slice(0, transactionLimit).map(transaction => (
          <Card key={transaction.id} style={{display:'flex', padding:16, marginBottom:4, alignItems:'center'}}>
            <Text block style={{marginRight:16}}>
              {moment(transaction.date).format('MM/DD/YYYY')}
            </Text>
            <Text block style={{marginRight:16}}>
              {transaction.description}
            </Text>
            <div style={{flex:1}}/>
            <Text block style={{marginRight:16}}>
              ${parseFloat(transaction.amount).toFixed(2)}
            </Text>
            <Text block style={{marginRight:16}}>
              {transaction.status}
            </Text>
          </Card>
        ))
      }


      {
        // If there are more transactions than the transactionLimit is allowing,
        // display a button to show more.

        !!transactions && transactions.length > transactionLimit && (
          <Button
            label="Show More"
            onClick={() => setTransactionLimit(transactionLimit + 10)}
          />
        )
      }

      <div style={{height:32}}/>

    </div>
  );
}