import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { ThemeContext, Icon } from 'skybolt-ui';

export default function Hamburger(props) {

  const {
    children,
    container = document.body,

    width = 240,

    onOpen = ()=>null,
    onClose = ()=>null,
    onToggle = ()=>null,

    ...iconProps
  } = props;


  const [ isOpen, setOpen ] = useState(false);
  const close = () => {
    document.body.style.overflow = 'auto';
    onClose();
    onToggle(false);
    setOpen(false);
  };
  const open = () => {
    document.body.style.overflow = 'hidden';
    onOpen();
    onToggle(true);
    setOpen(true);
  };
  const closeWithDelay = (e) => {
    e && e.stopPropagation();
    setTimeout(close, 300);
  };
  const toggle = () => {
    if(isOpen) {
      close();
    }
    else {
      open();
    }
  };


  const theme = useContext(ThemeContext);


  const burgerStyle = {
    display: 'inline-block',
    fill: 'white',
    cursor: 'pointer',
  };

  const overlayStyle = {
    position: 'fixed',
    zIndex: 2000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: isOpen ? "rgba(0,0,0,0.25)" : "rgba(0,0,0,0)",
    transition: "backgroundColor 200ms ease-in-out",
    pointerEvents: isOpen ? "auto" : "none",
    cursor: "pointer",
    overflowY: 'auto',
  };

  const menuStyle = {
    position: 'absolute',
    top: 0,
    left: isOpen ? 0 : -(width+6),
    width: width,
    minHeight: '100%',
    boxShadow: "3px 0 6px rgba(0,0,0,0.25)",
    backgroundColor: theme ? theme.color.primaryDarker : "#424242",
    transition: isOpen ? "left 225ms ease-out" : "left 195ms ease-in",
  };

  const menu = (
    <div key="menu" style={overlayStyle} onClick={close}>
      <div style={menuStyle} onClick={closeWithDelay}>
        {children}
      </div>
    </div>
  );

  return [
    <div key="icon" onClick={toggle} style={burgerStyle}>
      <Icon name="menu" {...iconProps}/>
    </div>,
    ReactDOM.createPortal(menu, container)
  ];

}