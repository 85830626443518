import React, { useContext } from 'react';
import { Container, Text, Rule, Button, useRouter, Route, ThemeContext } from 'skybolt-ui';
import ProfitAndLoss from './ProfitAndLoss.js';

export default function Reports(props) {

  const {match: { path, url }} = useRouter();
  const theme = useContext(ThemeContext);

  return (
    <Container style={{marginBottom:80}}>

      {/* Title */}

      <div className="billing-invoices-list-title">
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>REPORTS</Text>
          <div style={{flex:1}}/>
        </div>
        <Rule/>
        <div style={{marginBottom:24}}>
          <Button
            label="Profit and Loss"
            href={`${url}/profit-and-loss`}
            style={{
              borderRadius: 0,
              backgroundColor: true ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
        </div>
      </div>

      <Route path={`${path}/profit-and-loss`} component={ProfitAndLoss}/>



    </Container>
  );
}