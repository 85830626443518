import React, { Component } from 'react';
import { colors } from 'skybolt-ui';
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { loadTalent, loadTalentAgency } from 'actions';
import { cloudinaryUrl } from 'skybolt-api';
import { resumeSections } from 'config';
import moment from 'moment';
// import { mediaFormats } from 'config';
import { map, isString, isObject, isArray, isEmpty, min, max, get } from 'lodash';

import "./index.css";




function formatPhoneNumber(s) {
  var s2 = (""+s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? s : "(" + m[1] + ") " + m[2] + "-" + m[3];
}

// function getTalentImageId(talent) {
//   if(talent.media) {
//     const image = Object.keys(talent.media)
//       .map(id => talent.media[id])
//       .sort((a, b) => a.order < b.order ? -1 : 1)
//       .find(media => !media.format || mediaFormats.indexOf(media.format) !== -1);

//     if(image && image.cloudinary_id) {
//       return image.cloudinary_id;
//     }
//   }

//   if(talent.image && talent.image.cloudinary_id) {
//     return talent.image.cloudinary_id;
//   }

//   return 'avatar';
// }

const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const talent = state.talents.all[talentId];

  let agencyId, agency;
  if(talent && talent.agencyId) {
    agencyId = talent.agencyId;
    agency = state.agencies.all[agencyId];
  }

  const sections = get(state, `agencies.all[${agencyId}].resumeSections`, resumeSections);

  return {
    talentId,
    agencyId,
    talent,
    agency,
    sections,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  loadTalent,
  loadTalentAgency,
};





class Resume extends Component {
  loaded = 0

  UNSAFE_componentWillMount() {
    if(this.props.talentId) {
      this.props.loadTalent(this.props.talentId);
      this.props.loadTalentAgency(this.props.talentId);
    }
  }

  print = () => {
    window.parent.document.dispatchEvent(new CustomEvent('PRINTING'));
    window.print();
  }

  render() {
    if(!this.props.talentId) {
      return (
        <div>Talent not found.</div>
      );
    }

    if(!this.props.talent || !this.props.agency) {
      return <div></div>;
    }

    const {
      agency,
      talent,
      sections,
    } = this.props;

    const {
      firstName,
      lastName,
    } = talent;

    const logoId = agency.logo.cloudinary_id;
    const logoSource = cloudinaryUrl(logoId, {
      height: 120,
      secure: true,
    });

    const fields = Object.keys(agency.talentFields || {})
      .map(id => ({...agency.talentFields[id], id}))
      .filter(field => {
        if(!field.isPublic) {
          return false;
        }

        if(field.category === 'system') {
          return false;
        }

        if(!talent[field.id]) {
          return false;
        }

        if(isObject(talent[field.id]) && isEmpty(talent[field.id])) {
          return false;
        }

        if(isArray(talent[field.id]) && isEmpty(talent[field.id])) {
          return false;
        }

        return true;
      })
      .reduce((all, field) => {
        const category = field.category || 'Other';
        const fields = all[category] || {};
        return {
          ...all,
          [category]: {
            ...fields,
            [field.id]: field
          }
        };
      }, {});

    const bio = talent.bio || "";
    const skills = talent.skills || "";
    const experience = talent.experience || {};

    const byType = Object.keys(experience).reduce(function(byType, id) {
      const item = experience[id];
      if(!byType[item.type]) byType[item.type] = [];
      byType[item.type].unshift({...item, id});
      return byType;
    }, {});

    const categoryCount = Object.keys(byType).length;

    return (
      <div className="print-resume-wrapper">
        <header className="print-resume-header">

          <div className="print-resume-info-wrapper">
            <div className="print-resume-name-wrapper">
              <h1 className="print-resume-name">{firstName} {lastName}</h1>
            </div>
            <ul className="print-resume-fields">
               {map(fields, (categoryFields, category) =>
                <div key={category} style={{paddingBottom:8}}>
                  {categoryCount > 1 &&
                    <div style={{color:colors.grey[6]}} className="print-resume-field-category">{category}</div>
                  }

                  {Object.keys(categoryFields)
                    .map(i => categoryFields[i])
                    .sort((a, b) => !a.order || a.order < b.order ? -1 : 1)
                    .map((field) => {
                      let value = talent[field.id];
                      let units = field.units;

                      if(field.type === 'range' && isArray(value)) {
                  	    value = `${min(value)}-${max(value)}`;
                  	  }

                  	  // todo: remove this check after September 2018
                      else if(field.type === 'range' && isObject(value) && value.from && value.to) {
                        value = `${value.from}-${value.to}`;
                      }

                  	  else if(field.type === 'date') {
                        value = moment(value).format('MM/DD/YYYY');
                      }

                      else if(isArray(value)) {
                        value = value.join(', ');
                      }

                      else if(field.isFeet) {
                        value = `${Math.floor(value / 12)}'${Math.floor(value % 12)}"`.trim();
                        units = "";
                      }

                      if(!value || !isString(value)) {
                        return null;
                      }

                      return (
                        <li key={field.id} className="print-resume-field">
                          <span className="print-resume-field-name">{field.name}: </span>
                          <span className="print-resume-field-value">{value} {units}</span>
                        </li>
                      );
                    })
                  }
                </div>
               )}
            </ul>
          </div>
          <div className="print-resume-agency-wrapper">
            <div className="print-resume-agency-logo">
              <img
                alt={agency.name}
                src={logoSource}
                onLoad={this.print}
                height={40}
              />
            </div>
            <h3 className="print-resume-contact">
              {agency.name}<br/>
              <strong>{agency.contactName || ""}</strong><br/>
              <strong>{formatPhoneNumber(agency.primaryPhone || "")}</strong><br/>
              {agency.displayEmail || agency.contactEmail || ""}<br/>
              {agency.website || ""}<br/>
            </h3>
          </div>
        </header>

        {bio && <article className="print-resume-section">
          <h3 className="print-resume-subheader">Biography</h3>
          <p className="print-resume-text">{bio}</p>
        </article>}

        {sections.map(type => {
          if(!byType[type]) {
            return null;
          }

          return (
            <article key={type} className="print-resume-section">

              <h3 className="print-resume-subheader">{type}</h3>

              <ul className="print-resume-exp-ul">
                {byType[type]
                  .sort((a, b) => {
                    const aOrder = a.order || 0;
                    const bOrder = b.order || 0;
                    return aOrder > bOrder ? 1 : -1;
                  })
                  .map(item =>
                    <li key={item.id} className="print-resume-exp-li">
                      <span className="print-resume-exp-title">{item.title}</span>
                      <span className="print-resume-exp-role">{item.role}</span>
                      <span className="print-resume-exp-company">{item.company}</span>
                    </li>
                  )
                }
              </ul>

            </article>
          );
        })}

        {skills && <article className="print-resume-section">
          <h3 className="print-resume-subheader">Special Skills</h3>
          <p className="print-resume-text">{skills}</p>
        </article>}

      </div>
    );
  }
}





export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(Resume));