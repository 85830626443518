import React from 'react';
import Icon from 'ui/Icon';

const iconProps = {
  size: 14,
  color: "#ddd",
  style: {
    marginRight:8
  }
};

export default ({field}) => {
  return (
    <div className="talentfields-li" style={{opacity: field.isEnabled !== false ? 1 : 0.3}}>
      
      <div className="talentfields-li-name">{field.name}</div>
      
      <div className="talentfields-li-type">
        <span>{field.type}</span>
      </div>
      
      <div className="talentfields-li-description">
        {field.type === 'number' &&
          <span>From {field.min}{field.units} to {field.max}{field.units}</span>
        }
        
        {field.type === 'range' &&
          <span>From {field.min}{field.units} to {field.max}{field.units}</span>
        }
        
        {field.type === 'select' &&
          <span>{field.multiple ? 'Any' : 'One'} of {Object.keys(field.options || {}).length} options</span>
        }
      </div>
      
      <div className="talentfields-li-opts">
        <Icon name="edit" isActive={field.isEditableByTalent} {...iconProps}/>
        <Icon name="visible" isActive={field.isPublic}  {...iconProps}/>
        <Icon name="card" isActive={field.isVisibleOnCard} {...iconProps}/>
        <Icon name="history" isActive={field.trackChanges} {...iconProps}/>
      </div>
      
    </div>
  );
};