import React, { Component } from 'react';
import { connect } from 'react-redux';
import { verifyTalentEmail } from 'actions';

import "./index.css";

import Placeholder from 'ui/Placeholder';






const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const emailId = ownProps.match.params.emailId;
  return {
    talentId,
    emailId,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  verifyTalentEmail,
};








class VerifyEmail extends Component {
  
  componentDidMount() {
    this.props.verifyTalentEmail(this.props.talentId, this.props.emailId);
  }
  
  render() {
    
    return (
      <div className="verifyemail">
      
        <Placeholder 
          icon="email"
          message={"Thank you. Your email has been verified."}
          label="Back To Profile"
          onClick={()=>this.props.history.push(`/talents/${this.props.talentId}`)}
        />
        
        
      </div>
    );
  }

}



export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);