import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadAgencies } from 'actions';

import "./index.css";

import { Link } from 'react-router-dom';
import Container from 'ui/Container';
import Title from 'ui/Title';
import List from 'ui/List';


const mapStateToProps = (state, ownProps) => {
  return {
    agencies: state.agencies.all,
  };
};

const mapDispatchToProps = {
  loadAgencies
};



class View extends Component {
  UNSAFE_componentWillMount() {
    this.props.loadAgencies();
  }

  render() {
    const {agencies} = this.props;

    return (
      <Container>
        <Title>Agencies</Title>

        <List>
          {Object.keys(agencies)
            .map(id => agencies[id])
            .map(agency =>
              <Link
                key={agency.id}
                to={`/admin/agencies/${agency.id}`}
                className="agencies-li">
                {agency.name}
              </Link>
            )
          }
        </List>

      </Container>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(View);