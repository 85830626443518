import React from 'react';
import injectSheet from 'react-jss';
import TextField from 'ui/TextField';
import Button from 'ui/Button';


const styles = {
  email: {
    display: 'flex',
  },
  emailField: {
    flex: 2,
    marginRight: 8,
  },
  labelField: {
    flex: 1,
    marginRight: 8,
  },
  icon: {
    flex: 0,
  }
};

export class EmailsField extends React.Component {
  
  static defaultProps = {
    initialValue: {},
    onChange: ()=>null,
  }
  
  state = {
    emails: {},
  }
  
  componentDidMount() {
    this.setState({emails:this.props.initialValue});
  }
  
  updateEmail = (id, email) => {
    const emails = {...this.state.emails};
    if(!emails[id]) {
      emails[id] = {email:"", label:""};
    }
    emails[id].email = email;
    this.setState({emails});
    this.props.onChange(emails);
  }
  
  updateLabel = (id, label) => {
    const emails = {...this.state.emails};
    if(!emails[id]) {
      emails[id] = {email:"", label:""};
    }
    emails[id].label = label;
    this.setState({emails});
    this.props.onChange(emails);
  }
  
  remove = (id) => {
    let emails = {...this.state.emails};
    delete emails[id];
    this.setState({emails});
  }
  
  render() {
    let fields = Object.keys(this.state.emails).map(id => 
      this.renderEmail(this.state.emails[id], id, true)
    );
    fields.push(this.renderEmail());
    return fields;
  }
  
  renderEmail(email={}, id, canRemove=false) {
    const { classes } = this.props;
    
    if(!id) {
      id = Date.now();
    }
    
    return (
      <div key={id} className={classes.email}>
        <TextField
          key={`${id}-email`}
          inputKey={`${id}-email-input`}
          placeholder={"Email Address"}
          initialValue={email.email || ""}
          onChangeText={email => this.updateEmail(id, email)}
          underline={true}
          className={classes.emailField}
        />
        <TextField
          key={`${id}-label`}
          placeholder="Label"
          initialValue={email.label || ""}
          onChangeText={label => this.updateLabel(id, label)}
          underline={true}
          className={classes.labelField}
          style={{marginRight: canRemove ? 0 : 48}}
        />
        {canRemove && (
          <Button icon="remove" onClick={() => this.remove(id)}/>
        )}
      </div>
    );
  }
}


export default injectSheet(styles)(EmailsField);
