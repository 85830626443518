import React, { useEffect } from 'react';
import { updateTalent, requiredSubscription, updateEpayAccount } from 'skybolt-api';
import { useRouter, Modal, Loading } from 'skybolt-ui';

export default function() {
  const route = useRouter();
  const history = route.history;
  const talentId = route.match.params.talentId;

  useEffect(() => {
    let isCancelled = false;

    async function doit() {
      const sub = await requiredSubscription({talentId, status:"PAID"});

      if(isCancelled) {
        return;
      }

      let confirm = null;
      if(sub.setup && sub.initial) {
        confirm = `
          You will be charged a setup fee of $${parseFloat(sub.setup).toFixed(2)} plus
          the first month for a total of $${parseFloat(sub.setup + sub.initial).toFixed(2)}
          today, and $${parseFloat(sub.talent).toFixed(2)} every ${sub.cycle} until you cancel.
        `;
      }
      else if(sub.setup) {
        confirm = `
          You will be charged a setup fee of $${parseFloat(sub.setup).toFixed(2)},
          and $${parseFloat(sub.talent).toFixed(2)} every ${sub.cycle} until you cancel.
        `;
      }
      else if(sub.initial) {
        confirm = `
          You will be charged $${parseFloat(sub.setup + sub.initial).toFixed(2)} for the
          first month, and $${parseFloat(sub.talent).toFixed(2)} every ${sub.cycle} until
          you cancel.
        `;
      }
      if(confirm) {
        const confirmed = await Modal.confirm(confirm);
        if(!confirmed) {
          await updateTalent({
            talentId: talentId,
            update: {
              statusHistory: {
                [Date.now()]: `CANCELED by talent`,
              },
              status: "CANCELED",
              requireSubscription: false,
              commission: 0,
            },
          });
          history.replace(`/talents/${talentId}`);
          return;
        }
      }

      if(sub.epayId && (sub.amount || sub.initial)) {
        try {
          await updateEpayAccount(sub);
        }
        catch(error) {
          if(error.field && error.field === "plan") {
            history.replace(`/talents/${talentId}/subscription/plan`);
            return;
          }
          if(error.field && error.field === "card") {
            history.replace(`/talents/${talentId}/subscription/payment`);
            return;
          }
          console.error(error);
          await Modal.alert("Something went wrong. Please try again. If the problem persists, please contact Skybolt support.");
          history.replace(`/talents/${talentId}`);
          return;
        }
      }

      await updateTalent({
        talentId: talentId,
        update: {
          statusHistory: {
            [Date.now()]: `PAID by talent`,
          },
          status: "PAID",
          requireSubscription: false,
          commission: sub.commission,
        },
      });

      history.replace(`/talents/${talentId}`);
    }
    doit();

    return () => isCancelled = true;
  }, []);

  return <Loading/>;
}