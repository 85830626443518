import React, { Component } from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Tabs from 'ui/Tabs';



class Dialog extends Component {
  
  static defaultProps = {
    tabs: [],
    actions: [],
  }
  
  render() {
    const { image, title, actions, tabs } = this.props;
    
    let imageEl = null;
    if(image && React.isValidElement(image)) {
      imageEl = (
        <div className="header-image">
          {image}
        </div>
      );
    }
    
    let tabEls = React.Children.map(tabs, (el, i) => {
      if(!React.isValidElement(el)) {
        return null;
      }
      return React.cloneElement(el, {key:i});
    });
    
    let actionsEl = null;
    if(actions.length > 0) {
      actionsEl = (
        <div className="header-actions">
          {React.Children.map(this.props.actions, (el, i) => {
            if(!React.isValidElement(el)) {
              return null;
            }
            return React.cloneElement(el, {key:i});
          })}
        </div>
      );
    }
    
    return (
      <div className="header-content">
        
        {imageEl}
        
        <div className="header-info">
          <div className="header-title">
            <div className="header-title-text">{title}</div>
            {actionsEl && <div className="header-title-rule"></div>}
            {actionsEl}
          </div>
          
          <Tabs className="header-tabs">
            {tabEls}
          </Tabs>
          
        </div>
      </div>
    );
  }
}

export default muiThemeable()(Dialog);