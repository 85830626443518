import React from 'react';
import { Container, Text, Rule, useRouter, Redirect, Loading } from 'skybolt-ui';
import Info from './Info';
import { get } from 'lodash';
import { useQuery } from 'skybolt-api';



export default function PackInfo(props) {

  const route = useRouter();
  const packId = get(route, 'match.params.packId', null);

  const data = useQuery(`{
    user {
      userId
      agencyId
      permissions {
        canPack
      }
    }
    pack(packId:$packId) {
      agencyId
      userId
    }
  }`, {packId});

  if(!data) {
    return <Loading/>;
  }

  // The user can edit this package info
  const canEdit =
    // if the user has permission to edit packages,
    data.user.permissions.canPack &&
    // and the package was created by this user,
    (data.pack.userId === data.user.userId ||
    // or the pack is in the user's primary agency.
    data.pack.agencyId === data.user.agencyId);

  if(!canEdit) {
    return (
      <Redirect to="talents"/>
    );
  }

  return (
    <Container>
      <div style={{paddingTop:32}}>
        <Text title>INFO.</Text>
      </div>
      <Rule style={{marginBottom:24}}/>
      <Info/>
    </Container>
  );
}