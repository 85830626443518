/*

# Message Talent Dialog

A dialog window to create a new message to a single talent.

Props:

- talentId
- open
- onRequestClose

*/


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { messageTalentSearch, loadCannedMessages } from 'actions';

import Dialog from 'ui/Dialog';
import MessageComposer from 'ui/MessageComposer';





export const mapStateToProps = (state, ownProps) => {
  let agencyId = ownProps.agencyId || state.user.agencyId;
  const count = state.talents.searchCount;

  return {
    count,
    agencyId,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  messageTalentSearch,
  loadCannedMessages,
};






export class MessageAgencyTalentsDialog extends Component {
  UNSAFE_componentWillMount() {
    this.props.loadCannedMessages(this.props.agencyId);
  }

  send = async message => {
    this.props.onRequestClose();
    return await this.props.messageTalentSearch(message);
  }

  render() {
    if(this.props.isLoading) {
      return null;
    }

    return (
      <Dialog
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
        bodyStyle={{backgroundColor:'#fafafa'}}>

        <MessageComposer
          draftKey="searchtalents"
          onCancel={this.props.onRequestClose}
          onSend={this.send}
          showToField={false}
          title={`Message ${this.props.count} Talent`}
        />

      </Dialog>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageAgencyTalentsDialog);