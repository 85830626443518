import React, {useRef, useContext, useState} from 'react';
import { Icon, Menu, Text, Flag, Rule, ThemeContext } from 'skybolt-ui';
import NavButton from './NavButton';
import { useQuery } from 'skybolt-api';

export default function() {

  const theme = useContext(ThemeContext);
  const anchor = useRef();
  const [isOpen, setOpen] = useState(false);
  const open = () => setOpen(true);
  const close = () => setOpen(false);

  const data = useQuery(`{
    agency {
      agencyId
      requireTalentApproval
      useContacts
      useBilling
      allowUnassignedTalents
      requireTalentMediaApproval
    }
    user {
      permissions {
        canApproveTalents
        canApproveMedia
        canConfigAgency
        canAdminUsers
        canAddAgents
        canAddCastingDirectors
        canAdminAgencies
        canAdminTalents
        canAdminFAQ
        canSpotlight
        canAddTalents
        canAgencyBilling
      }
    }
    unapprovedMediaCount,
    unapprovedTalentsCount,
  }`);

  if(!data) {
    return null;
  }


  // The button.

  const buttons = (
    <NavButton block onClick={open} key="buttons" ref={anchor} style={{padding:'24px 16px 20px'}}>
      Admin <Icon name="caret" small color={theme.color.lightTextDark}/>
      {!isOpen && <Flag top={8} right={20}>{data.unapprovedMediaCount + data.unapprovedTalentsCount}</Flag>}
    </NavButton>
  );


  // The menu.

  const buttonStyle = {display:'block', width:200, marginLeft:-10, color:theme.color.lightText};

  const menu = (
    <Menu
      style={{padding:16, borderTopLeftRadius:0, borderTopRightRadius:0, background:theme.color.primaryDarker, borderTop:'1px solid #aaa', display:'flex', alignItems:'flex-start'}}
      key="menu"
      anchor={anchor}
      open={isOpen}
      onRequestClose={close}>

        <div style={{flex:1}}>
          <Text block color={theme.color.lightText}>Maintenance</Text>
          <Rule small color={theme.color.lightTextDarker} style={{margin:'8px 0 16px'}}/>

          {data.agency.requireTalentApproval && data.user.permissions.canApproveTalents &&
            <NavButton icon="approvetalent" style={buttonStyle} href="/admin/approve-talents">
              Approve Talent
              {!!data.unapprovedTalentsCount && <Flag top={8} right={32}>{data.unapprovedTalentsCount}</Flag>}
            </NavButton>
          }

          {data.agency.requireTalentMediaApproval && data.user.permissions.canApproveMedia &&
            <NavButton icon="approvemedia" style={buttonStyle} href="/media/approve">
              Approve Media
              {!!data.unapprovedMediaCount && <Flag top={8} right={32}>{data.unapprovedMediaCount}</Flag>}
            </NavButton>
          }

          {data.user.permissions.canConfigAgency &&
            <NavButton icon="gear" style={buttonStyle} href={`/admin/agencies/${data.agency.agencyId}`}>Agency Config.</NavButton>
          }

          {data.user.permissions.canAdminUsers &&
            <NavButton icon="user" style={buttonStyle} href="/admin/users">Users</NavButton>
          }

          {data.user.permissions.canAdminAgencies &&
            <NavButton icon="agency" style={buttonStyle} href="/admin/agencies">Agencies</NavButton>
          }

          {data.user.permissions.canAdminTalents &&
            <NavButton icon="talent" style={buttonStyle} href="/admin/talents">Talent</NavButton>
          }

          {/*data.user.permissions.canConfigAgency && data.agency.useContacts &&
            <NavButton icon="contact" style={buttonStyle} href="/admin/contacts">Contacts</NavButton>
          */}

          {data.user.permissions.canAdminTalents &&
            <NavButton icon="invitation" style={buttonStyle} href="/admin/invites">Admin Invitations</NavButton>
          }

          {data.user.permissions.canSpotlight &&
            <NavButton icon="stars" style={buttonStyle} href="/admin/spotlights">Spotlights</NavButton>
          }

          {data.user.permissions.canConfigAgency &&
            <NavButton icon="gear" external={true} style={buttonStyle} href="/admin/scheduled-messages" label="Scheduled Messages"></NavButton>
          }

          {data.user.permissions.canAdminTalents &&
            <a style={{textDecoration:'none'}} href="https://us-central1-skybolt-99adb.cloudfunctions.net/commissionCSV" download={true} target="_blank" rel="noopener noreferrer">
              <NavButton style={buttonStyle} icon="file-spreadsheet">Commission CSV</NavButton>
            </a>
          }
        </div>

        <div style={{flex:1}}>
          <Text block color={theme.color.lightText}>Network</Text>
          <Rule small color={theme.color.lightTextDarker} style={{margin:'8px 0 16px'}}/>

          {data.user.permissions.canAddTalents && data.agency.allowUnassignedTalents &&
            <NavButton icon="plus" style={buttonStyle} href="/admin/add-talent">Add Talent</NavButton>
          }

          {data.user.permissions.canAddTalents &&
            <NavButton icon="invitation" style={buttonStyle} href="/admin/invite-talent">Invite Talent</NavButton>
          }

          {data.user.permissions.canAddAgents &&
            <NavButton icon="invitation" style={buttonStyle} href="/admin/invite-agent">Invite Agent</NavButton>
          }

          {data.user.permissions.canAddCastingDirectors &&
            <NavButton icon="invitation" style={buttonStyle} href="/admin/invite-castingdirector">Invite Guest</NavButton>
          }

          <Rule small color={theme.color.lightTextDarker} style={{margin:'8px 0 16px'}}/>

          {data.user.permissions.canApproveTalents &&
            <NavButton external={true} icon="fa-solid fa-fw fa-clipboard-user" style={buttonStyle} href="/applications" label="Talent Applications"></NavButton>
          }

          {data.user.permissions.canAddTalents &&
            <NavButton icon="invitation" style={buttonStyle} href="/admin/invitations">Invitations</NavButton>
          }
        </div>

    </Menu>
  );


  return [buttons, menu];

}
