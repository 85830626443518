import React, { useState } from 'react';
import { useQuery } from 'skybolt-api';
import {
  Loading,
  Icon,
  Card,
  Text,
  InputText,
  Button,
  Rule,
  useRouter,
} from 'skybolt-ui';

export default props => {

  // Load data.

  const route = useRouter();
  const data = useQuery(`{
    agency(agencyId:$agencyId) {
      resumeSections
    }
  }`, {agencyId:route.match.params.agencyId});


  // Handle adding new sections.

  const [adding, setAdding] = useState(false);
  const [newSection, setNewSection] = useState("");
  const toggleAdding = () => setAdding(!adding);
  function addSection() {
    setAdding(false);
    setNewSection("");
  }


  // Render

  if(!data) {
    return <Loading/>;
  }

  return (
    <div>
      <div style={{display:'flex', alignItems:'center', height:40}}>
        <Text title>RESUME SECTIONS</Text>
        <div style={{flex:1}}/>
        <Button title icon={adding ? "close" : "plus"} onClick={toggleAdding}/>
      </div>
      <Rule style={{marginBottom:8}}/>
      <Text block small light style={{marginBottom:24}}>Drag to rearrange how experience is displayed on Talent resumes.</Text>

      {adding &&
        <Card style={{padding:8}}>
          <InputText
            block
            placeholder="New Section"
            value={newSection}
            onChange={setNewSection}
            autofocus
          />
          <Button raised onClick={addSection}>Add Section</Button>
        </Card>
      }

      {data.agency.resumeSections.map(section =>
        <Card key={section} style={{padding:"4px 4px 4px 8px", display:'flex', alignItems:'center'}}>
          <Text>{section}</Text>
          <div style={{flex:1}}/>
          <Icon name="grip-lines" color="textLight" small/>
        </Card>
      )}
    </div>
  );
};