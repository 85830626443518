import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadAgency, updateAgency } from 'actions';

// todo: get rid of these, use skybolt-ui instead.
import { Redirect, Route, Switch } from 'react-router-dom';
import InfoIcon from 'material-ui/svg-icons/action/info';
import FieldsIcon from 'material-ui/svg-icons/action/assignment';
import MessagesIcon from 'material-ui/svg-icons/communication/email';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Messaging from './Messaging';
import InfoForm from './Info';
import SettingsForm from './Settings';
import TalentFields from './TalentFields';


import Container from 'ui/Container';
import Loading from 'ui/Loading';
import Header, { Image, Action } from 'ui/Header';
import { Tab } from 'ui/Tabs';

import "./index.css";


const mapStateToProps = (state, ownProps) => {
  const agencyId = ownProps.match.params.agencyId;


  const userId = state.user.id;
  const user = state.users.all[userId];
  const hasPermission = user.permissions.canAdminAgencies || user.agencyId === agencyId;

  return {
    hasPermission,
    agencyId,
    agency: state.agencies.all[agencyId],
    permissions: state.user.permissions,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  updateAgency,
  loadAgency,
};


class Agency extends Component {
  static defaultProps = {
    zDepth: 1,
    className: '',
  }

  UNSAFE_componentWillMount() {
    this.props.loadAgency(this.props.agencyId);
  }

  render() {

    if(!this.props.hasPermission) {
      return <Redirect to="/"/>;
    }

    const {
      agencyId,
      agency,
      updateAgency,
      className,
      permissions: {
        canAdminAgencies,
      },
      match: { path, url },
    } = this.props;

    if(!agency) {
      return (
        <div style={{padding:120, textAlign:'center'}}>
          <Loading/>
        </div>
      );
    }

    return (
      <Container className={className}>

        <Header
          title={agency.name}
          actions={[
            <Action icon="download" label="Download" onClick={() => this.downloadLink.click()}/>
          ]}
          image={<Image cloudinaryId={agency.logo.cloudinary_id}/>}
          tabs={[
            <Tab
              path={path}
              to={url}
              label="Info."
              icon={<InfoIcon/>}
            />,
            <Tab
              path={`${path}/fields`}
              to={`${url}/fields`}
              label="Talent Fields"
              icon={<FieldsIcon/>}
            />,
            <Tab
              path={`${path}/messages`}
              to={`${url}/messages`}
              label="Messaging"
              icon={<MessagesIcon/>}
            />,
            <Tab
              path={`${path}/settings`}
              to={`${url}/settings`}
              label=""
              icon={<SettingsIcon/>}
              hidden={!canAdminAgencies}
            />
          ]}
          style={{
            marginTop:32,
          }}
        />

        <Switch>
          <Route
            path={`${path}/messages`}
            render={props =>
              <Messaging agencyId={agencyId} {...props}/>
            }
          />

          <Route
            path={`${path}/fields/:fieldId?`}
            render={props => <TalentFields style={{paddingBottom:40}} {...props}/>}
          />

          <Route
            path={`${path}/settings`}
            render={props =>
              <SettingsForm
                agency={agency}
                onUpdate={update => updateAgency(agencyId, update)}
                {...props}
              />
            }
          />
          <Route
            render={props =>
              <InfoForm
                agency={agency}
                showNotes={this.props.permissions.canAdminAgencies}
                onUpdate={update => updateAgency(agencyId, update)}
                {...props}
              />
            }
          />
        </Switch>

        <a
          ref={el => this.downloadLink = el}
          style={{
            position: 'absolute',
            left: -1000,
          }}
          href={`https://us-central1-skybolt-99adb.cloudfunctions.net/agencyTalentsCSV?agencyId=${agencyId}`}
          download={true}
          rel="noopener noreferrer"
          target="_blank">
        </a>

      </Container>
    );

  }
}

const View = muiThemeable()(Agency);



export default connect(mapStateToProps, mapDispatchToProps)(View);