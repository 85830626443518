/*
Check for use cases and redirect accordingly:
  - talent that require new talent subscription
  - talent that require new user subscription
  - siblings on user subscription
  - siblings on agency subscription
  - flagged by craig
  - existing subscription that requires plan
  - existing subscription that requires payment
  - expiring talent
  - todo: talent paid for by agency
  - todo: talent comped by agent

*/

import React from 'react';
import {
  Redirect,
  useRouter,
  Loading,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';


export default function TalentSubscriptionCheck() {
  const route = useRouter();
  const talentId = route.match.params.talentId;

  const data = useQuery(`{
    talent(talentId:$talentId) {
      fullName
      status
      epayId
      plan
      dateExpires
      agency {
        agencyId
        requireTalentSubscription
        requireUserSubscription
        chargeTalentsToAgency
      }
      user {
        userId
        agencyId
        epayId
        plan
      }
    }
  }`, {talentId});

  if(!data) {
    return <Loading/>;
  }

  // console.log('checking', data);
  // return <Loading/>;

  // If we're charging the talent to the agency.
  if(data.talent.agency.chargeTalentsToAgency) {
    return <Redirect to="charge-to-agency"/>;
  }

  // If the talent's agency requires a user subscription, redirect them to
  // the user subscription.
  if(data.talent.agency.requireUserSubscription) {
    // If the talent has an existing subscription that has expired,
    // prompt them for payment and update the credit card.
    if(data.talent.user.plan && data.talent.user.epayId && data.talent.status === "EXPIRED") {
      return <Redirect to="payment"/>;
    }
    // If the talent's agency requires a talent subscription,
    // prompt talent to choose a plan and enter a credit card.
    if(!data.talent.user.plan) {
      return <Redirect to="plan"/>;
    }
    // If billing the talent, but they don't have an epay account.
    if(!data.talent.user.epayId) {
      return <Redirect to="payment"/>;
    }
  }


  // If the talent's agency requires a talent subscription.
  if(data.talent.agency.requireTalentSubscription) {
    // If the talent has an existing subscription that has expired,
    // prompt them for payment and update the credit card.
    if(data.talent.plan && data.talent.epayId && data.talent.status === "EXPIRED") {
      return <Redirect to="payment"/>;
    }
    // If the talent's agency requires a talent subscription,
    // prompt talent to choose a plan and enter a credit card.
    if(!data.talent.plan) {
      return <Redirect to="plan"/>;
    }
    // If billing the talent, but they don't have an epay account.
    if(!data.talent.epayId) {
      return <Redirect to="payment"/>;
    }
  }

  // If the talent is expiring soon.
  if(data.talent.dateExpires) {
    return <Redirect to="payment"/>;
  }

  // If none of these are the case, then just show thank you.
  return <Redirect to="thanks"/>;
}