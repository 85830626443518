import React from 'react';
import { useQuery } from 'skybolt-api';
import moment from 'moment';

import { 
  Container,
  Card,
  Text,
  Rule,
  Button,
  Icon,
} from 'skybolt-ui';


export default props => {
  
  const data = useQuery(`{
    spotlights {
      dateModified
      spotlightId
      name
      numTalents
      talents(limit:10) {
        talentId
        firstName
        lastName
        image(width:32, height:32, face:true)
      }
    }
  }`);
  
  
  return (
    <Container>
      <div style={{padding:"32px 0 24px 0"}}>
        <div style={{display:'flex', alignItems:'flex-end', height:40}}>
          <Text title>SPOTLIGHTS</Text>
          <div style={{flex:1}}/>
          <Button 
            href="create"
            style={{
              background:"#cccccc",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width:40,
              height:40,
              padding: 0,
              borderRadius: 0,
            }}>
            <Icon name="plus" color="white" size={24}/>
          </Button>
        </div>
        <Rule/>
      </div>
    
      {!data && 
        <div style={{margin:"40px auto", textAlign:'center'}}>
          <Icon name="loading"/>
        </div>
      }
      
      {data && (!data.spotlights || data.spotlights.length === 0) &&
        <div style={{display:'block', margin:"40px auto", textAlign:'center'}}>
          <div>
            <Icon name="spotlights" color="greyLight" size="200"/>
          </div>
          <Text paragraph color="textLight" centered>No Spotlights</Text>
        </div>
      }
      
      {data && Array.isArray(data.spotlights) && data.spotlights
        .sort((a,b) => a.dateModified > b.dateModified ? -1 : 1)
        .map(spotlight => {
          return (
            <Card key={spotlight.spotlightId}>
              <Button href={spotlight.spotlightId} style={{display:'flex', alignItems:'center', flexWrap:'wrap'}}>
                <div style={{flex:1}}>
                  <Text block large>{spotlight.name}</Text>
                  <Text block small color="textLight">{moment(spotlight.dateModified).format("MM/DD/YYYY hh:mma")}</Text>
                </div>
                <div style={{breakInside:'avoid', display:'flex', alignItems:'center'}}> 
                  {spotlight.talents.map(talent => 
                    <img key={talent.talentId} src={talent.image} alt={`${talent.firstName} ${talent.lastName}`} style={{margin:"4px 4px 4px 0"}}/>
                  )}
                  {spotlight.numTalents - spotlight.talents.length > 0 && 
                    <Text>and {spotlight.numTalents - spotlight.talents.length} more.</Text>
                  }
                </div>
              </Button>
            </Card>
          );
        })
      }
    </Container>
  );
};