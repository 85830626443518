/**
 * PrivateRoute will redirect any unauthed user to /login.
 * `/login` will return to the supplied `from` when complete.
 */

import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Route, Redirect } from 'skybolt-ui';


export default function({component:Component, render, ...rest}) {
  const user = firebase.auth().currentUser;
  const isLoggedIn = user && !user.isAnonymous;
  return (
    <Route {...rest} render={props => (
      isLoggedIn ? (
        !!Component ? <Component {...props}/> :
        !!render ? render(props) :
        null
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { redirect: props.location }
        }}/>
      )
    )}/>
  );
}
