import React, { useContext, useState, useEffect } from 'react';
import { useQuery, updateTalent, loadEpayAccount, updateEpayAccount } from 'skybolt-api';
import {
  Redirect,
  Container,
  Card,
  Text,
  Button,
  ThemeContext,
  Rule,
  Icon,
  Modal,
} from 'skybolt-ui';
import { map, isEmpty } from 'lodash';
import moment from 'moment';


export default function TalentAddons(props) {

  // Get the talent id from the router.
  // Redirect back to root if this isn't given or there is an error retrieving it.

  let talentId;
  try {
    talentId = props.match.params.talentId;
  }
  catch(error) {
    talentId = null;
  }


  // Get talent addon data.

  const data = useQuery(`{
    talent(talentId:$talentId) {
      plan {
        cycle
        addons
      }
      epayId
      commission
    }
  }`, {talentId});


  // Remove `requireAddons` flag on first load.

  useEffect(() => {
    updateTalent({talentId, update:{requireAddons:null}});
  }, []);


  // Store talent addon selection in local state.

  const [addons, setAddons] = useState({});

  useEffect(() => {
    if(!data) {
      return;
    }
    const addons = {};
    for(let key in data.talent.plan.addons) {
      addons[key] = !!data.talent.plan.addons[key].enabled;
    }
    setAddons(addons);
  }, [data]);

  const toggleAddon = addon => setAddons({...addons, [addon]:!addons[addon]});


  // Submit changes.

  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const submit = async () => {
    if(submitting) {
      return;
    }
    setSubmitting(true);

    // Get the talent's epay account.
    let epayAccount;
    if(!data.talent.epayId) {
      setError("No payment account found. Please contact support for more information.");
      setSubmitting(false);
      return;
    }
    try {
      epayAccount = await loadEpayAccount(data.talent.epayId);
    }
    catch(error) {
      setError("No payment account found. Please contact support for more information.");
      setSubmitting(false);
      return;
    }
    if(!epayAccount || !epayAccount.enabled) {
      setError("No payment account found. Please contact support for more information.");
      setSubmitting(false);
      return;
    }

    const existingAmount = epayAccount.amount ? parseFloat(epayAccount.amount) : 0;
    const existingCommission = data.talent.commission || 0;
    const cycle = epayAccount.cycle.toLowerCase().indexOf('mon') > -1 ? 'month' : 'year';
    const next = epayAccount.next;

    // Figure out how much these addons should amount to by adding them all up
    // then removing the existing charges.
    let addonsAmount = 0;
    let addonsCommission = 0;
    for(let addon in addons) {
      if(addons[addon]) {
        addonsAmount += data.talent.plan.addons[addon].talent || 0;
        addonsCommission += data.talent.plan.addons[addon].commission || 0;
      }
    }
    for(let addon in (data.talent.plan.addons || [])) {
      if(data.talent.plan.addons[addon].enabled) {
        addonsAmount -= data.talent.plan.addons[addon].talent || 0;
        addonsCommission -= data.talent.plan.addons[addon].commission || 0;
      }
    }

    // figure out prorate amount.
    const daysInCycle = cycle === 'month' ? moment().daysInMonth() : 365;
    const daysToNext = -moment().diff(next, "days");
    const prorateAmount = addonsAmount * (daysToNext / daysInCycle);

    // Confirm these changes with the user, allowing them to bail out.
    if(addonsAmount > 0) {
      const confirm = await Modal.confirm(`
        Are you sure? You will be billed $${prorateAmount.toFixed(2)} now, and an additional
        $${addonsAmount.toFixed(2)}/${cycle} for these addons.
      `);
      if(!confirm) {
        setSubmitting(false);
        return;
      }
    }

    // Update the talent.
    const change = {
      commission: existingCommission + addonsCommission,
    };
    for(let addon in addons) {
      change[`plan.addons.${addon}.enabled`] = addons[addon];
      change[data.talent.plan.addons[addon].feature] = addons[addon];
    }
    updateTalent({talentId, change});


    // Update the epay account.
    updateEpayAccount({
      epayId: data.talent.epayId,
      amount: existingAmount + addonsAmount,
      initial: prorateAmount,
    });


    // And redirect back to their account.
    setSubmitting(false);
    if(props.history) {
      props.history.replace('info');
    }

  };



  // Layout

  const theme = useContext(ThemeContext);

  if(!talentId) {
    return <Redirect to="/"/>;
  }

  if(!data) {
    return null;
  }

  if(isEmpty(data.talent.plan.addons)) {
    return <Redirect to="info"/>;
  }

  return (
    <div style={{width:'100%'}}>
      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          padding: '40px 16px 80px 16px',
          marginBottom: -40,
          textAlign: 'center',
          backgroundColor: theme.color.accent
        }}>
        <Text>
          <Text style={{fontSize:"60px", lineHeight:'.9em', color:'white', letterSpacing:4}} bold>SELECT</Text><br/>
          <Text style={{fontSize:"54px", lineHeight:'.9em', color:'white', letterSpacing:4}}>ADDONS</Text>
        </Text>
        <Rule small color="white" style={{margin:'32px auto 24px'}}/>
        <Text style={{color:theme.color.lightTextLighter, textAlign:'center', fontSize:16, lineHeight:'18px'}}>
          Enable additional features by selecting from the available addons below.
        </Text>
      </div>

      <Container style={{paddingBottom:40}}>

        {map(data.talent.plan.addons, (addon, key) =>
          <Card
            key={key}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              maxWidth: 600,
              padding: "16px 16px 16px 16px",
              margin: "0 auto 24px auto",
              backgroundColor: !!addons[key] ? theme.color.accentBackground : 'white',
            }}>
            <Icon style={{flexShrink:0}} name={addon.icon} size={80} color="accent"/>
            <div style={{padding:"0 16px"}}>
              <Text block large>{addon.name}</Text>
              <Text style={{paddingBottom:4}}>{addon.description}</Text>
              <Text block accent>${parseFloat(addon.talent).toFixed(2)}/{data.talent.plan.cycle}</Text>
            </div>
            <Button
              style={{
                flexShrink:0,
                backgroundColor: !!addons[key] ? theme.color.greyLight : theme.color.accent,
              }}
              label={!!addons[key] ? "Disable" : "Click here to Enable"}
              raised
              onClick={() => toggleAddon(key)}
            />
          </Card>
        )}


        {!data &&
          <div style={{padding:80, textAlign:'center'}}>
            <Icon name="loading" large/>
          </div>
        }

        {error &&
          <Text error block centered>{error}</Text>
        }

        <div style={{textAlign:'center', marginTop:24}}>
          <Button raised accent={!submitting} label={submitting ? "Hang Tight..." : "Continue"} onClick={submit}/>
        </div>

      </Container>
    </div>
  );
}