import React from 'react';
import {
  Text,
  Rule,
  Icon,
} from 'skybolt-ui';

export default function ClientMessages(props) {
  return (
    <div>
      <div style={{display:'flex', alignItems:'flex-end', height:40}}>
        <Text title>Messages</Text>
      </div>
      <Rule style={{marginBottom:24}}/>
      <div style={{textAlign:'center'}}>
        <div>
          <Icon name="surprise" duotone size={80} color="accentLighter" style={{marginBottom:16}}/>
        </div>
        <Text>This list is empty.</Text>
      </div>
    </div>
  );
}