import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useState } from 'react';
import { Button, Modal, Text } from 'skybolt-ui';


export default function UserResetPasswordButton({onClick, email, ...props}) {
  const [menu, setMenu] = useState('false');

  const sendResetLink = () => {
    firebase.auth().sendPasswordResetEmail(email);
    setMenu('sent');
  };

  return [
    <Button
      key="button"
      label="Send Password Reset Link"
      raised
      onClick={() => setMenu('open')}
      {...props}
    />,
    <Modal
      key="modal"
      status={menu}
      onRequestClose={() => setMenu('closed')}
      modalStyle={{
        maxWidth:400,
        padding: "32px 16px 16px 16px",
      }}>

      <Text style={{paddingBottom:32}}>Send a password reset link to {email}.</Text>

      <div style={{display:'flex', justifyContent:'flex-end'}}>
        <Button raised onClick={() => setMenu('closed')} style={{marginRight:16}}>Nevermind</Button>
        <Button
          raised
          accent
          onClick={sendResetLink}>
          Send It
        </Button>
      </div>
    </Modal>
  ];
}