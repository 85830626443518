import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadPack } from 'actions';
import moment from 'moment';

import { Link } from 'react-router-dom';
import Page from 'ui/Page';
import Media from 'ui/Media';
import Loading from 'ui/Loading';

import './index.css';


export const mapStateToProps = (state, ownProps) => {
  const packId = ownProps.packId;
  const pack = ownProps.pack || state.packs.all[packId];

  return {
    packId,
    pack,
  };
};

export const mapDispatchToProps = {
  loadPack,
};


export class PackLI extends Component {

  UNSAFE_componentWillMount() {
    if(this.props.packId && !this.props.pack) {
      this.props.loadPack(this.props.packId);
    }
  }

  render() {
    const pack = this.props.pack;

    if(!pack) {
      return (
        <Page className="packli">
          <Loading/>
        </Page>
      );
    }


    const image = pack.preview
        ? <img alt={pack.name} src={pack.preview} className="packli-image"/>
        : <Media icon="pack" backgroundColor="#f0f0f0"  className="packli-image" width={64} height={64}/>;

    return (
      <Link to={`/packs/${pack.id}`} style={{display:'block', textDecoration:'none'}}>
        <Page className="packli">
          {image}
          <div className="packli-name">{pack.name}</div>
          <div className="packli-date">{moment(pack.dateCreated).format("MM/DD/YYYY")}</div>
        </Page>
      </Link>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(PackLI);