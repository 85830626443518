import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { get as getProperty } from 'lodash';
import _ from 'lodash';
import {
  loadUserEvents,
} from 'actions';

import Container from 'ui/Container';
import Title, { Tab, Checkbox, Filter } from 'ui/Title';
import EventList from 'ui/EventList';
import EventDialog from 'ui/EventDialog';


const mapStateToProps = (state, ownProps) => {
  let events = state.events.byUser[ state.user.id ] || {};
  const canViewUserCalendar = getProperty(state, 'user.permissions.canEditTalents', false);

  const agencyId = _.get(state, 'user.agencyId', null);
  const agency = _.get(state, `agencies.all[${agencyId}]`, null);

  return {
    events,
    canViewUserCalendar,
    agency,
    ...ownProps,
  };
};


const mapDispatchToProps = {
  loadUserEvents,
};


class Schedule extends Component {

  state = {
    date: moment(),
    limit: 100,
    event: null,
    isDialogOpen: false,
    showEventTypes: [],
  }

  UNSAFE_componentWillMount() {
    this.loadEvents();
    if(this.props.date) {
      this.setState({date:this.props.date});
    }
  }



  loadEvents = () => {
    this.props.loadUserEvents({
      fromDate: this.state.date.valueOf(),
      limit: this.state.limit,
    });
  }

  openDialog = event => {
    this.setState({isDialogOpen:true, event});
  }

  closeDialog = () => {
    this.setState({isDialogOpen:false});
  }



  render() {

    const events = Object.keys(this.props.events || {})
      .map(id => ({...this.props.events[id], id}))
      .filter(event => {
        if(!this.state.showEventTypes.length) {
          return true;
        }
        if(event.packId) {
          if(this.state.showEventTypes.indexOf("ALL_PACK") !== -1) {
            return true;
          }
          if(this.state.showEventTypes.indexOf(event.type) !== -1) {
            return true;
          }
        }
        else {
          if(this.state.showEventTypes.indexOf("ALL_UNAVAILABLE") !== -1) {
            return true;
          }
        }
        return false;
      });
    

    let filters = [];
    if(this.props.canViewUserCalendar) {
      filters = [
        <Filter 
          key="type"
          label="Types"
          value={this.state.showEventTypes}
          options={[
            {label:"All Package Events", value:"ALL_PACK"},
            {label:"All Unavailable", value:"ALL_UNAVAILABLE"},
            ...Object.values(this.props.agency?.eventTypes || {})
              .map(type => ({label:type.label, value:type.label})),
          ]}
          optionValueKey="value"
          optionDisplayKey="label"
          onChange={showEventTypes => this.setState({showEventTypes})}
        />
      ];
    }

    const tabs = [
      <Tab
        to={`month`}
        label="Calendar"
      />,
      <Tab
        isActive={true}
        label="Schedule"
      />
    ];

    return (
      <Container style={{paddingBottom:40}}>

        <Title
          filters={filters}
          tabs={tabs}>
          Schedule
        </Title>

        <EventList
          date={this.props.date}
          events={events}
          onClickEvent={this.openDialog}
        />

        <EventDialog
          open={this.state.isDialogOpen}
          onRequestClose={this.closeDialog}
          event={this.state.event}
        />

      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);