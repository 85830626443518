import React, { useContext } from 'react';
import {
  useQuery
} from 'skybolt-api';
import {
  Card,
  Text,
  Button,
  ThemeContext,
  Rule,
  useLayout,
  useRouter,
} from 'skybolt-ui';

export default function Plan(props) {

  const router = useRouter();
  const talentId = router.match.params.talentId;

  const data = useQuery(`{
    talent(talentId:$talentId) {
      agencyId
      user {
        talents
      }
    }
  }`, {talentId});

  let amount;
  if(data) {
    const numSiblings = data.talent.user.talents
      .filter(t => t.agencyId === data.talent.agencyId)
      .length;
    const talent1 = props.plan.talent;
    const talent2 = props.plan.talent2 || props.plan.talentFamilyDiscount;
    const talent3 = props.plan.talent3;
    amount = numSiblings > 2 && talent3 ? talent3 :
             numSiblings > 1 && talent2 ? talent2 :
             talent1;
  }
  const cycle = props.plan.cycle === 'month' ? "Month" : "Year";
  const setup = props.plan.talentSetup;


  // Render

  const theme = useContext(ThemeContext);
  const { width } = useLayout();

  return (
    <Card
      depth={1}
      style={{
        boxSizing: 'border-box',
        margin: width > 400 ? "0 8px 8px 8px" : "0 0 16px 0",
        flex:1,
        backgroundColor: theme.color.background,
        width: '100%',
        maxWidth:400,
        ...props.style
      }}>

      <div
        style={{
          minHeight: 100,
          backgroundColor: theme.color.tint(theme.color.accent, 20),
          paddingTop: 16,
          borderTop: '20px solid',
          borderColor: theme.color.tint(theme.color.accent, 40),
          textAlign: 'center',
        }}>

        <Text white bold style={{fontSize:24}}>{props.plan.name}</Text>

        <div style={{padding:'8px 0'}}>
          {props.plan.talent === 0 ? (
            <div>
              <Text white large>FREE</Text>
            </div>
          ) : (
            <div>
              <Text white size={40}>$</Text>
              <Text white size={40}>{parseFloat(amount).toFixed(2)}</Text>

              <Text white size={40}>&nbsp;/&nbsp;{cycle}</Text>
            </div>
          )}
        </div>

        <Text block white small>
          {!!setup && `Plus one-time $${setup} setup fee.`}
        </Text>

      </div>

      <div style={{padding:16}}>

        <div style={{paddingBottom:16}}>
          {props.plan.features.map((feature, i) =>
            <div key={i}>
              <Text>{feature}</Text>
              <Rule style={{margin:'8px 0'}}/>
            </div>
          )}
        </div>

        <div style={{textAlign:'center'}}>
          <Button
            label="CHOOSE THIS PLAN"
            raised
            accent
            onClick={() => props.onSelect(props.plan)}
          />
        </div>

      </div>

    </Card>
  );
}