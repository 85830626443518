import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  subscribeToTalentThreads,
  unsubscribeFromTalentThreads,
  addThread,
  addMessage,
  loadAgency,
  removeThread,
  removeThreadFromTalent,
  removeThreadFromUser,
  removeThreadFromPack,
} from 'actions';
import { isEmpty, get } from 'lodash';

import Title, { Action } from 'ui/Title';
import ThreadsList from 'ui/ThreadsList';
import Loading from 'ui/Loading';
import Placeholder from 'ui/Placeholder';
import MessageComposer from 'ui/MessageComposer';

import './index.css';



export const mapStateToProps = (state, ownProps) => {
  const userId = state.user.id;
  const talentId = ownProps.match.params.talentId;
  const threadId = ownProps.match.params.threadId;

  const user = state.users.all[ state.user.id ];
  const talent = state.talents.all[talentId];
  const threads = state.threads.byTalent[talentId];

  const isLoggedIn = state.user.isLoggedIn;
  const isOwnTalent = isLoggedIn && !!user.talents && !!user.talents[talentId];

  let contactRecipients = null;
  let contactTooltip = "";
  if(isOwnTalent && talent) {
    contactTooltip = "Contact Agent";

    if(talent.agencyContact) {
      contactRecipients = {
        [talent.agencyContact.userId]: talent.agencyContact,
        [talentId]: {
          name: `${talent.firstName || ""} ${talent.lastName || ""}`.trim(),
          talentId: talentId,
        }
      };
    }
    else if(talent.agencyId) {
      let agency = state.agencies.all[ talent.agencyId ];
      if(agency) {
        contactRecipients = {
          [talent.agencyId]: {
            email: agency.contactEmail,
            name: agency.contactName,
          },
          [talentId]: {
            name: `${talent.firstName || ""} ${talent.lastName || ""}`.trim(),
            talentId: talentId,
          }
        };
      }
    }
  }
  if(isLoggedIn && state.user.permissions.canMessageTalents) {
    contactTooltip = "Contact Talent";
    contactRecipients = {
      [state.user.id]: {
        name: `${state.user.firstName || ""} ${state.user.lastName || ""}`.trim(),
        userId: state.user.id,
      },
      [talentId]: {
        name: `${talent.firstName || ""} ${talent.lastName || ""}`.trim(),
        talentId: talentId,
      }
    };
  }

  const canAccess = isOwnTalent || get(state, 'user.permissions.canMessageTalents', false);

  return {
    userId,
    talentId,
    threadId,

    talent,
    threads,
    user,
    contactTooltip,
    contactRecipients,

    canAccess,
    isOwnTalent,
  };
};

export const mapDispatchToProps = {
  subscribeToTalentThreads,
  unsubscribeFromTalentThreads,
  addThread,
  addMessage,
  removeThread,
  removeThreadFromTalent,
  removeThreadFromUser,
  removeThreadFromPack,
  loadAgency,
};




export class TalentMessages extends Component {

  state = {
    isAdding: false,
  }

  componentDidMount() {
    this.limit = 30;
    this.subscribeToThreads();
    this.props.loadAgency(this.props.talent.agencyId);
  }
  componentWillUnmount() {
    this.unsubscribeFromThreads();
  }

  openComposer = () => this.setState({isAdding:true})
  closeComposer = () => this.setState({isAdding:false})


  send = async message => {
    this.closeComposer();

    let thread = await this.props.addThread({
      recipients: this.props.contactRecipients,
      subject: message.subject,
    });

    return await this.props.addMessage(thread.id, message);
  }

  subscribeToThreads() {
    if(!this.props.canAccess) {
      return;
    }
    this.props.subscribeToTalentThreads(this.props.talentId, this.limit);
  }

  unsubscribeFromThreads() {
    if(!this.props.canAccess) {
      return;
    }
    this.props.unsubscribeFromTalentThreads(this.props.talentId);
  }

  loadMoreThreads = () => {
    const limit = Object.keys(this.props.threads).length + 30;
    if(limit <= this.limit) {
      return;
    }

    this.limit = limit;
    this.unsubscribeFromThreads();
    this.subscribeToThreads();
  }


  render() {
    if(!this.props.canAccess) {
      return null;
    }

    const actions = [];
    if(this.props.contactRecipients && !this.state.isAdding) {
      actions.push(
        <Action icon="plus" onClick={this.openComposer} tooltip={this.props.contactTooltip}/>
      );
    }
    if(this.state.isAdding) {
      actions.push(
        <Action icon="close" onClick={this.closeComposer}/>
      );
    }

    return (
      <div>

        <Title actions={actions} style={{marginBottom:0}}>
          Messages
        </Title>

        {!this.props.threads &&
          <Loading style={{marginTop:40}}/>
        }

        {!!this.props.threads && isEmpty(this.props.threads) &&
          <Placeholder
            icon="messages"
            message={`
              You don't have any messages.
              All messages ${this.props.isOwnTalent ? 'from your agent' : ''} will appear here.
            `}
          />
        }

        {this.state.isAdding &&
          <MessageComposer
            showTitle={false}
            showToDisplay={true}
            showToField={false}
            onSend={this.send}
            showSMS={!this.props.isOwnTalent}
          />
        }

        {!isEmpty(this.props.threads) &&
          <ThreadsList
            threads={this.props.threads}
            onLazyLoad={this.loadMoreThreads}
          />
        }

      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(TalentMessages);