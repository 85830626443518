import React, { useCallback, useState } from 'react';
import {
  Container,
  Text,
  Button,
  Rule,
  Loading,
  Redirect,
  Card,
  Form,
  InputText,
  InputNumber,
  InputSwitch,
  InputTime,
  InputDate,
  InputHTML,
  useRouter,
} from 'skybolt-ui';
import { useQuery, updateDebit } from 'skybolt-api';
import InputTalent from './InputTalent';
import InputPackage from './InputPackage';
import InputClient from './InputClient';
import { debounce, get } from 'lodash';
import "./index.css";


export default function(props) {

  // Load data

  const route = useRouter();
  const history = route.history;
  const debitId = get(route, 'match.params.debitId', null);
  const state = get(route, 'location.state', null);
  const referrer = get(route, 'location.state.referrer', null);

  const data = useQuery(`{
    debit(debitId:$debitId)
    user {
      permissions {
        canAdminBilling
      }
    }
  }`, {debitId});
  

  // Function to commit updates to an invoice on a debounce.

  const handleChange = useCallback(debounce((_, change) => {
    updateDebit({debitId, update:change});
  }, 500), [debitId]);

  const [ isDeleting, setDeleting ] = useState(false);
  const deleteDebit = async () => {
    if(isDeleting) {
      return;
    }
    setDeleting(true);
    await updateDebit({debitId, update:{status:"DELETED"}});
    history.push({pathname:referrer || '../list', state:{...state, referrer:""}});
  };


  // Render

  if(!data) {
    return <Loading/>;
  }

  if(data.debit.status !== "DRAFT" && !data.user.permissions.canAdminBilling) {
    return <Redirect to="view"/>;
  }

  return (
    <Container className="billingpaymentedit">
      <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
        <Text title>PAYMENT</Text>
      </div>

      <Rule style={{marginBottom:24}}/>

      <Card style={{marginBottom:40}}>
        <Form value={data.debit} onChange={handleChange} className="form">
          <InputTalent className="talent" title="Talent"/>
          <InputPackage className="package" title="Package"/>
          <InputClient className="client" title="Client"/>
          <InputNumber className="invoiceNo" name="invoiceNumber" title="Invoice Number"/>
          <InputDate className="date" name="dateJob" title="Job Date"/>
          <InputDate className="dateCheck" name="dateCheck" title="Pay Date"/>
          <InputTime className="timeFrom" name="jobTimeFrom" title="Time From"/>
          <InputTime className="timeTo" name="jobTimeTo" title="Time To"/>
          <InputText className="hours" name="jobHours" title="Hours"/>
          <InputHTML className="comment" name="comment" title="Comment" hint="Displayed on talent checks."/>
          <InputNumber className="gross" name="grossAmount" title="Gross Amount"/>
          <InputNumber className="tax" name="taxAmount" title="Tax Amount"/>
          <InputNumber className="other" name="otherAmount" title="Other Amount"/>
          <InputNumber className="agency" name="agencyAmount" title="Agency Fee"/>
          <InputNumber className="amount" name="amount" title="Amount"/>
          <InputSwitch className="is1099" name="is1099" title="Is 1099 Payment"/>
          <div className="notes">
            {data?.debit.creditId && 
              <p>
                This payment was generated by a credit added to Accounts Receivable.
                Changes to this payment will not be reflected in Accounts Receivable and 
                may cause an imbalance in reporting.
              </p>
            }
            {data?.debit.invoiceId && 
              <p>
                This payment was generated by an Invoice. Changes to this payment 
                will not be reflected in the associated invoice and may cause 
                an imbalance in reporting. 
              </p>
            }
            <InputHTML name="notes" title="Notes" hint="Only visible to agents"/>
          </div>
          <div></div>
        </Form>
        <div className="nav">
          {/* <Button raised accent label="Done" href={{pathname:referrer || '../list', state:{...state, referrer:""}}}/> */}
          <Button raised accent label="Done" href={data.debit.status == 'SENT' ? "/billing/accounts-payable/sent" : '/billing/accounts-payable/pending'}/>
          <Button label={isDeleting ? "Hang Tight..." : "Delete"} onClick={deleteDebit}/>
        </div>
      </Card>

    </Container>
  );
}