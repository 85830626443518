/* global fetch */
import React, { useContext } from 'react';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { Text, Currency, Rule, Button, Container, Icon, InputText, InputSelect, InputDate, ThemeContext, Card, Loading } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';
import "./index.css";
import downloadCSV from './utils/downloadCSV.js';
import filtersDescription from './utils/filtersDescription.js'
import InputClient from './components/InputClient.js';

export default function Invoices(props) {
  const { state={}, pathname } = props.location;
  const {
    status,
    clientId,
    jobType,
    invoiceNumber,
    dateFrom,
    dateTo,
    orderBy="date",
    orderDirection="desc",
    limit = 100,
  } = state;

  const data = useQuery(`{
    agency {
      timezone
      invoices(
        status:$status,
        clientId:$clientId,
        jobType:$jobType,
        invoiceNumber:$invoiceNumber,
        dateFrom:$dateFrom,
        dateTo:$dateTo,
        limit:$limit,
        orderBy:$orderBy,
        orderDirection:$orderDirection
      ) {
        invoiceId
        invoiceNumber
        jobType
        date
        datePaid
        clientId
        client {
          clientId
          name
        }
        status
        total
        subtotal
        agencyFeeTotal
        commissionTotal

      }
      jobTypes
    }
    user {
      permissions {
        canAgencyBilling
        canAgencyBillingReports
      }
    }
  }`, {status, clientId, jobType, invoiceNumber, dateFrom, dateTo, limit, orderBy, orderDirection});

  let timezone = data?.agency?.timezone || moment.tz.guess();

  const setQuery = update => props.history.replace(pathname, {...state, ...update});
  // const [isBuildingReport, setBuildingReport] = useState(false);

  function setOrderBy(val) {
    if(orderBy === val) {
      if(orderDirection === "desc") {
        setQuery({orderDirection:"asc"});
      }
      else {
        setQuery({orderDirection:"desc"});
      }
    }
    else {
      setQuery({orderBy:val, orderDirection:"desc"});
    }
  }

  const theme = useContext(ThemeContext);

  function download() {
    downloadCSV(data.agency.invoices, {
      dateFrom,
      dateTo,
      jobType,
      clientId,
      status,
      invoiceNumber,
      orderBy,
      orderDirection,
    })
  }

  if(!data) {
    return <Loading/>;
  }

  return (
    <Container style={{marginBottom:80}}>

      {/* Title */}

      <div className="billing-invoices-list-title">
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>INVOICES</Text>
          <div style={{flex:1}}/>
          {data.user.permissions.canAgencyBillingReports &&
            <InputDate
              placeholder="From..."
              value={dateFrom}
              onChange={val => setQuery({dateFrom: moment(val).tz(timezone).startOf('day').valueOf()})}
              onRule={true}
              style={{width:120}}
              tailStyle={{paddingTop:18, paddingBottom:8}}
              clearable
            />
          }
          {data.user.permissions.canAgencyBillingReports &&
            <InputDate
              placeholder="To..."
              value={dateTo}
              onChange={val => setQuery({dateTo: moment(val).tz(timezone).endOf('day').valueOf()})}
              onRule={true}
              style={{width:120}}
              tailStyle={{paddingTop:18, paddingBottom:8}}
              clearable
            />
          }
          <InputText
            onRule
            placeholder="Invoice Number..."
            clearable
            value={invoiceNumber}
            onChange={invoiceNumber => setQuery({invoiceNumber})}
          />
          <InputSelect
            onRule
            placeholder="Type..."
            clearable="All"
            value={jobType}
            strict options={data.agency.jobTypes}
            onChange={jobType => setQuery({jobType})}
          />
          <InputClient
            onRule
            placeholder="Client..."
            clearable="All"
            value={clientId}
            onChange={clientId => setQuery({clientId})}
          />
          <Button onRule href="create" icon="plus"/>
          {data.user.permissions.canAgencyBillingReports &&
            <Button onRule onClick={download} icon="file-download"/>
          }
        </div>
        <Rule/>
        <div style={{marginBottom:24}}>
          <Button
            label="All"
            onClick={() => setQuery({status:null, orderBy:"date"})}
            style={{
              borderRadius: 0,
              backgroundColor: !status ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
          <Button
            label="Drafts"
            onClick={() => setQuery({status:"DRAFT", orderBy:"date"})}
            style={{
              borderRadius: 0,
              backgroundColor: status === "DRAFT" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
          <Button
            label="PP&O"
            onClick={() => setQuery({status:"SENT", orderBy:"date"})}
            style={{
              borderRadius: 0,
              backgroundColor: status === "SENT" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
          <Button
            label="Posted"
            onClick={() => setQuery({status:"POSTED", orderBy:"date"})}
            style={{
              borderRadius: 0,
              backgroundColor: status === "POSTED" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
          <Button
            label="Paid"
            onClick={() => setQuery({status:"PAID", orderBy:"datePaid"})}
            style={{
              borderRadius: 0,
              backgroundColor: status === "PAID" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
          <Button
            label="Overdue"
            onClick={() => setQuery({status:"OVERDUE", orderBy:"date"})}
            style={{
              borderRadius: 0,
              backgroundColor: status === "OVERDUE" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
        </div>
      </div>

      <Text>
        {filtersDescription({status, clientId, jobType, invoiceNumber, dateFrom, dateTo, limit, orderBy, orderDirection})}
      </Text>


      {/* If this list is empty, display a message. */}

      {isEmpty(data.agency.invoices) ? (
        <div style={{textAlign:'center'}}>
          <div>
            <Icon
              name={status === "OVERDUE" || status === "DRAFT" ? "umbrella-beach" : "surprise"}
              duotone size={80} color="accentLighter" style={{marginBottom:16}}
            />
          </div>
          <Text>
            {
              status === "OVERDUE" ? "There are no overdue invoices!" :
              status === "DRAFT" ? "There are no draft invoices." :
              "This list is empty."
            }
          </Text>
        </div>
      ) : (
        <div>


          {/* Table Heading */}

          <div style={{display:'flex', alignItems:'center', padding:8}}>
            <Button onClick={() => setOrderBy('date')} block style={{flex:1, padding:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
              <Text 
                small 
                style={{lineHeight:'32px', display:'inline-block', marginRight:8}} 
                color={orderBy === 'date' ? theme.color.accent : 
                       status !== 'PAID' ? theme.color.text : 
                       theme.color.textLighter}>
                Invoice Date
              </Text>
              <Icon
                color={orderBy === 'date' ? theme.color.accent : 
                       status !== 'PAID' ? theme.color.text : 
                       theme.color.textLighter}
                name={orderBy === 'date' && orderDirection === 'asc' ? "caret-up" : "caret-down"}
                small
                style={{marginTop:-1}}
              />
            </Button>
            <Button onClick={() => setOrderBy('datePaid')} block style={{flex:1, padding:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
              <Text 
                small 
                style={{lineHeight:'32px', display:'inline-block', marginRight:8}} 
                color={orderBy === 'datePaid' ? theme.color.accent : 
                       status === 'PAID' ? theme.color.text : 
                       theme.color.textLighter}>
                Date Paid
              </Text>
              <Icon
                color={orderBy === 'datePaid' ? theme.color.accent : 
                       status === 'PAID' ? theme.color.text : 
                       theme.color.textLighter}
                name={orderBy === 'datePaid' && orderDirection === 'asc' ? "caret-up" : "caret-down"}
                small
                style={{marginTop:-1}}
              />
            </Button>
            <Button onClick={() => setOrderBy('invoiceNumber')} block style={{flex:1, padding:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
              <Text small style={{lineHeight:'32px', display:'inline-block', marginRight:8}} color={orderBy === 'invoiceNumber' ? theme.color.accent : theme.color.textLight}>
                Invoice Number
              </Text>
              <Icon
                color={orderBy === 'invoiceNumber' ? theme.color.accent : theme.color.textLighter}
                name={orderBy === 'invoiceNumber' && orderDirection === 'asc' ? "caret-up" : "caret-down"}
                small
                style={{marginTop:-1}}
              />
            </Button>

            <Button onClick={() => setOrderBy('jobType')} block style={{flex:1, padding:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
              <Text small style={{lineHeight:'32px', display:'inline-block', marginRight:8}} color={orderBy === 'jobType' ? theme.color.accent : theme.color.textLight}>
                Job Type
              </Text>
              <Icon
                color={orderBy === 'jobType' ? theme.color.accent : theme.color.textLighter}
                name={orderBy === 'jobType' && orderDirection === 'asc' ? "caret-up" : "caret-down"}
                small
                style={{marginTop:-1}}
              />
            </Button>

            <Button onClick={() => setOrderBy('clientId')} block style={{flex:1, padding:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
              <Text small style={{lineHeight:'32px', display:'inline-block', marginRight:8}} color={orderBy === 'clientId' ? theme.color.accent : theme.color.textLight}>
                Client
              </Text>
              <Icon
                color={orderBy === 'clientId' ? theme.color.accent : theme.color.textLighter}
                name={orderBy === 'clientId' && orderDirection === 'asc' ? "caret-up" : "caret-down"}
                small
                style={{marginTop:-1}}
              />
            </Button>

            <div style={{flex:1}}>
              <Text small light>
                Status
              </Text>
            </div>
            <div style={{flex:1, textAlign:'right'}}>
              <Text small light>
                Total
              </Text>
            </div>
            <div style={{flex:1, textAlign:'right'}}>
              <Text small light>
                Subtotal
              </Text>
            </div>
            <div style={{flex:1, textAlign:'right'}}>
              <Text small light>
                Owed to Talent
              </Text>
            </div>
            <div style={{flex:1, textAlign:'right'}}>
              <Text small light>
                Total Commissions
              </Text>
            </div>
          </div>


          {/* Table body */}

          {data.agency.invoices.map((invoice, i) => (

            <div key={invoice.invoiceId}>
              <Card
                style={{display:'flex', alignItems:'center', boxSizing:'border-box', minHeight:40, padding:8, marginBottom:4, cursor:'pointer'}}
                onClick={() => props.history.push(invoice.invoiceId)}>
                <div style={{flex:1}}>
                  <Text>{moment(invoice.date).tz(timezone).format("MM/DD/YYYY")}</Text>
                </div>
                <div style={{flex:1}}>
                  <Text>{invoice.datePaid ? moment(invoice.datePaid).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
                </div>
                <div style={{flex:1}}>
                  <Text bold>{invoice.invoiceNumber}</Text>
                </div>
                <div style={{flex:1}}>
                  <Text>{invoice.jobType}</Text>
                </div>
                <div style={{flex:1, paddingRight:16}}>
                  <Text>{invoice.client.name}</Text>
                </div>
                <div style={{flex:1}}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color: invoice.status === "PAID" ? "green" :
                             invoice.status === "POSTED" ? "orange" :
                             invoice.status === "OVERDUE" ? "red" :
                             "#aaaaaa"
                    }}>
                    {invoice.status}
                  </Text>
                </div>
                <div style={{flex:1, textAlign:'right'}}>
                  <Text>${parseFloat(invoice.total || 0, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
                </div>
                <div style={{flex:1, textAlign:'right'}}>
                  <Text>${parseFloat(invoice.subtotal || 0, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
                </div>
                <div style={{flex:1, textAlign:'right'}}>
                  <Currency>{Number(invoice.total) - Number(invoice.agencyFeeTotal) - Number(invoice.commissionTotal)}</Currency>
                </div>
                <div style={{flex:1, textAlign:'right'}}>
                  <Text>${parseFloat((invoice.agencyFeeTotal + invoice.commissionTotal) || 0, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
                </div>
              </Card>

              {/* If we're ordering by job type or agency, break out each value and put totals below. */}

              {(orderBy === 'jobType' || orderBy === 'clientId') && (!data.agency.invoices[i+1] || data.agency.invoices[i][orderBy] !== data.agency.invoices[i+1][orderBy]) && (
                <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-end', padding:8, marginBottom:40}}>
                  <div style={{flex:6}}/>
                  <div style={{flex:1, textAlign:'right'}}>
                    <Currency bold>{data.agency.invoices.filter(j => j[orderBy] === data.agency.invoices[i][orderBy]).reduce((t, c) => t + (Number(c.total) || 0), 0)}</Currency>
                  </div>
                  <div style={{flex:1, textAlign:'right'}}>
                    <Currency bold>{data.agency.invoices.filter(j => j[orderBy] === data.agency.invoices[i][orderBy]).reduce((t, c) => t + (Number(c.subtotal) || 0), 0)}</Currency>
                  </div>
                  <div style={{flex:1, textAlign:'right'}}>
                    <Currency bold>{data.agency.invoices.filter(j => j[orderBy] === data.agency.invoices[i][orderBy]).reduce((t, c) => t + ((Number(c.total) || 0) - (Number(c.agencyFeeTotal) || 0) - (Number(c.commissionTotal) || 0)), 0)}</Currency>
                  </div>
                  <div style={{flex:1, textAlign:'right'}}>
                    <Currency bold>{data.agency.invoices.filter(j => j[orderBy] === data.agency.invoices[i][orderBy]).reduce((t, c) => t + (Number(c.agencyFeeTotal + c.commissionTotal) || 0), 0)}</Currency>
                  </div>
                </div>
              )}

            </div>
          ))}
        </div>
      )}

      {data.agency.invoices.length === limit &&
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', padding:16}}>
          <Button label="Load More" onClick={() => setQuery({limit:limit + 50})}/>
        </div>
      }

      {data.user.permissions.canAgencyBillingReports && data.agency.invoices.length > 0 && data.agency.invoices.length < limit &&
        <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-end', paddingTop:24}}>
          <div style={{flex:6}}/>
          <div style={{flex:1, textAlign:'right'}}>
            <Rule style={{marginBottom:8}}/>
            <Currency large bold>{data.agency.invoices.reduce((t, c) => t + (Number(c.total) || 0), 0)}</Currency>
          </div>
          <div style={{flex:1, textAlign:'right'}}>
            <Rule style={{marginBottom:8}}/>
            <Currency large bold>{data.agency.invoices.reduce((t, c) => t + (Number(c.subtotal) || 0), 0)}</Currency>
          </div>
          <div style={{flex:1, textAlign:'right'}}>
            <Rule style={{marginBottom:8}}/>
            <Currency large bold>{data.agency.invoices.reduce((t, c) => t + ((Number(c.total) || 0) - (Number(c.agencyFeeTotal) || 0) - (Number(c.commissionTotal) || 0)), 0)}</Currency>
          </div>
          <div style={{flex:1, textAlign:'right'}}>
            <Rule style={{marginBottom:8}}/>
            <Currency large bold>{data.agency.invoices.reduce((t, c) => t + (Number(c.agencyFeeTotal + c.commissionTotal) || 0), 0)}</Currency>
          </div>
        </div>
      }

    </Container>
  );

}
