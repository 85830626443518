import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { updateTalent, useQuery } from 'skybolt-api';
import { Button, Text, Modal, useRouter } from 'skybolt-ui';
import { isEmpty } from 'lodash';


const h1style = {
  padding: 0,
  margin: "0 0 8px 0",
  fontSize: "24px",
  lineHeight: "32px",
  fontWeight: "normal",
  color: "#ADADAD",
};


function KitComponent(props) {
  let wrapper = useRef();
  let app = useRef();
  let instance = useRef();

  let { component, ...forwardProps } = props;

  useLayoutEffect(() => {
    if(!component) {
      return;
    }
    let Component = window.skyboltkit[component];
    if(!Component) {
      return;
    }
    if(app.current == Component) {
      if(instance.current) {
        instance.current.$set(forwardProps);
      }
      return;
    }

    if(instance.current) {
      instance.current.$destroy();
    }
    
    app.current = Component;
    instance.current = new Component({target:wrapper.current, props:forwardProps});

    instance.current.$on('close', () => {
      if(props.onClose) {
        props.onClose();
      }
    });

  }, [wrapper.current, window.skyboltkit, component, forwardProps]);

  useEffect(() => {
    return () => {
      if(instance.current) {
        instance.current.$destroy();
      }
    }
  }, []);

  return (
    <div
      ref={wrapper}
      style={{
        display: 'block',
        width: '100%',
      }}
    />
  );
}

export default function(props) {

  const route = useRouter();
  const talentId = route.match.params.talentId;

  const data = useQuery(`{
    talent(talentId:$talentId) {
      legalName,
      fullName,
      billingEmail,
      billingAccount,
    }
  }`, {talentId});

  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState({});
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  function cancel() {
    setEditing(false);
    setValue({});
  }

  async function save() {
    if(isEmpty(value)) {
      return;
    }
    if(saving) {
      return;
    }
    setSaving(true);
    try {
      await updateTalent({
        talentId, 
        billing: {
          ...value,
          nameOnCheck: data.talent.legalName || data.talent.fullName || "",
        }
      });
    }
    catch(error) {
      console.log('error', error);
      setError(error.message);
      setSaving(false);
      return;
    }

    setSaving(false);
    setEditing(false);
    setValue({});
  }

  // if(isEditing) {

  //   return (
  //     <Form value={value} onChange={setValue} style={{maxWidth:600}}>
  //       {/* <InputText title="Full Name" hint="Checks will be made out to this name" name="nameOnCheck"/> */}
  //       <p style={{fontSize:16, fongWeight:'bold', padding:0, margin:0}}>{data.talent.legalName || data.talent.fullName}</p>
  //       <p style={{fontSize:11, padding:0, paddingBottom:16, margin:0, color:"#aaaaaa"}}>Checks will be made out to this name</p>
  //       <InputText title="SSN" name="taxId"/>
  //       <InputText title="Payment Email" name="paymentEmail"/>
  //       <InputPhone title="Payment Phone" name="paymentPhone"/>
  //       <InputText title="Bank Account Number" name="accountNumber"/>
  //       <InputText title="Routing Number" name="routingNumber"/>
  //       <nav>
  //         <Button raised accent onClick={save}>{saving ? "Saving..." : "Save"}</Button>
  //         <Button onClick={cancel}>Cancel</Button>
  //       </nav>
  //       {error &&
  //         <Text block error>{error}</Text>
  //       }
  //     </Form>
  //   );
  // }

  if(!data) {
    return null;
  }

  if(data.talent.billingAccount) {
    return (
      <div>
        <Text block>Email address: {data.talent.billingEmail}</Text>
        <Text paragraph>Account: ...{data.talent.billingAccount}</Text>
        <Button block raised style={{marginBottom:4}} onClick={() => setEditing(true)}>Update your banking information</Button>
        <Modal status={isEditing} onRequestClose={() => setEditing(false)}>
          <div style={{padding:32}}>
            <h1 style={h1style}>Bank Account</h1>
            <KitComponent component="TalentBankAccountForm" talentId={talentId} onClose={() => setEditing(false)}/>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div>
      <Button block raised style={{marginBottom:4}} onClick={() => setEditing(true)}>Link your bank account</Button>
      <Text block small light>
        Linking a bank account will allow <br/>
        your agent to pay you through ACH <br/>
        for work that you do
      </Text>
      <Modal status={isEditing} onRequestClose={() => setEditing(false)}>
        <div style={{padding:32}}>
          <h1 style={h1style}>Bank Account</h1>
          <KitComponent component="TalentBankAccountForm" talentId={talentId} onClose={() => setEditing(false)}/>
        </div>
      </Modal>
    </div>
  );
}
