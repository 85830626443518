import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { readMessage } from 'actions';
import { cloudinaryUrl } from 'skybolt-api';

import Media from 'ui/Media';
import Button from 'ui/Button';


export const mapStateToProps = (state, ownProps) => {
  return ownProps;
};

export const mapDispatchToProps = {
  readMessage,
};


class Message extends Component {
  UNSAFE_componentWillMount() {
    this.readTimer = setTimeout(
      () => this.props.readMessage(this.props.message.id),
      1000
    );
  }

  componentWillUnmount() {
    clearTimeout(this.readTimer);
  }

  render() {
    const {
      message: {
        from,
        body,
        date,
        modality,
        attachments=[],
      }
    } = this.props;

    const icon = modality === 'sms' ? "sms" : "message";

    return (
      <div className="threadslist-message">

        <Media
          width={48}
          height={49}
          cloudinaryId={from.cloudinaryId}
          icon={icon}
          iconSize={24}
          className="threadslist-message-image"
          style={{
            backgroundColor: "#9E9E9E",
            marginBottom:-1,
          }}
        />

        <div className="threadslist-message-from">
          {from.name || from.email || from.phone}
        </div>

        <div className="threadslist-message-content">
          <div
            className="threadslist-message-body"
            dangerouslySetInnerHTML={{__html:body}}
          />

          {attachments.length > 0 &&
            <div className="threadslist-message-attachments">
              {attachments.map(attachment =>
                <div key={attachment.cloudinary_id} className="threadslist-message-attachment">
                  <Media media={attachment} icon="file" width={100} className="threadslist-message-attachment-preview"/>
                  <div className="threadslist-message-attachment-name">{attachment.name}</div>
                  <a
                    href={cloudinaryUrl(attachment, attachment)}
                    className="threadslist-message-attachment-overlay"
                    download={true}
                    rel="noopener noreferrer"
                    target="_blank">
                    <Button icon="download" color="white" iconSize={24}/>
                  </a>
                </div>
              )}
            </div>
          }
        </div>

        {date &&
          <div className="threadslist-message-date">
            {moment(date).format("M/DD/YY h:mma")}
          </div>
        }

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Message);