import React, { Component } from 'react';
import { connect } from 'react-redux';

import { 
  subscribeToThreads,
  unsubscribeFromThreads,
} from 'actions';



const mapStateToProps = (state, ownProps) => {
  const threadId = ownProps.match.params.threadId;
  const userId = state.user.id;
  const threads = state.threads.byUser[userId];
  const canMessageAllTalents = state.user.permissions.canPack;
  const agencyId = state.user.agencyId;
  const isLoading = state.threads.isLoading;
  
  return {
    isLoading,
    threadId,
    threads,
    userId,
    agencyId,
    canMessageAllTalents,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  subscribeToThreads,
  unsubscribeFromThreads,
};




class Archived extends Component {
  render() {
    return (
      <div>
        Archived
        {this.props.threadId}
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Archived);