import React from 'react';
import { Switch, Route, Redirect } from 'skybolt-ui';
import Edit from './Edit';
import View from './View';

export default function Invoice(props) {
  const {match:{ url, path }} = props;

  return (
    <Switch>
      <Route path={`${path}/edit`} component={Edit}/>
      <Route path={`${path}/view`} component={View}/>
      <Redirect to={`${url}/edit`}/>
    </Switch>
  );
}