import React, { Component } from 'react';
import { connect } from 'react-redux';
import { subscribeToPackThreads, unsubscribeFromPackThreads } from 'actions';

import ThreadsList from 'ui/ThreadsList';
import Container from 'ui/Container';
import Title from 'ui/Title';




export const mapStateToProps = (state, ownProps) => {
  const { url, path } = ownProps.match;

  const packId = ownProps.match.params.packId;
  const threadId = ownProps.match.params.threadId;
  const threads = state.threads.byPack[packId] || {};

  return {
    url,
    path,
    packId,
    threadId,
    threads,
  };
};

export const mapDispatchToProps = {
  subscribeToPackThreads,
  unsubscribeFromPackThreads,
};





export class Messages extends Component {
  UNSAFE_componentWillMount() {
    this.props.subscribeToPackThreads(this.props.packId);
  }

  componentWillUnmount() {
    this.props.unsubscribeFromPackThreads(this.props.packId);
  }

  render() {
    return (
      <Container>
        <Title>Messages</Title>
        <ThreadsList threads={this.props.threads}/>
      </Container>
    );
  }
}






export default connect(mapStateToProps, mapDispatchToProps)(Messages);