import React from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { withRouter } from 'react-router-dom';
import { isString } from 'lodash';

import Icon from 'ui/Icon';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';

import './index.css';


const baseStyle = {
};

export default withRouter(muiThemeable()(({
  raised=false,
  primary=false,
  floating=false,
  active=false,
  disabled=false,
  label,
  tooltip="",
  labelStyle={},
  icon,
  width, 
  height,
  iconSize=16,
  color,
  backgroundColor,
  isReversed,
  className="",
  style={},
  buttonStyle={},
  onClick,
  onMouseDown,
  to=null,
  tooltipPosition="bottom-center",
  muiTheme:{ palette },
  history,
}) => {
  
  if(backgroundColor && !color) {
    const c = backgroundColor.substring(1);      // strip #
    const rgb = parseInt(c, 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >>  8) & 0xff;  // extract green
    const b = (rgb >>  0) & 0xff;  // extract blue
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    color = luma < 120 ? palette.lightTextColor : palette.textColor;
  }
  
  if(to) {
    const originalOnClick = onClick || (()=>null);
    onClick = (e) => {
      originalOnClick(e);
      history.push(to);
    };
  } 
  
  color = color ? color : 
          active ? palette.accent : 
          disabled ? "rgba(0,0,0,0.2)" :
          palette.primary;
  
  if(isString(icon)) {
    icon = <Icon name={icon} size={iconSize} isReversed={isReversed} color={color}/>;
  }
  
  if(floating) {
    return (
      <div 
        className={`button-floating ${className}`.trim()}
        style={style}>
        
        <div className="button-icon" style={{...baseStyle}}>
          <IconButton 
            onClick={onClick} 
            onMouseDown={onMouseDown} 
            tooltip={tooltip} 
            tooltipPosition={tooltipPosition}
            style={buttonStyle}
            disabled={disabled}>
            {icon}
          </IconButton>
        </div>
        
      </div>
    );
  }
  
  if(raised) {
    return (
      <div 
        className={`button-raised ${className} ${isReversed ? 'is-reversed' : ''}`.trim()}
        style={{...baseStyle, ...style}}>
        <RaisedButton 
          label={label}
          icon={icon}
          labelColor={color}
          backgroundColor={backgroundColor}
          secondary={primary}
          disabled={disabled}
          onClick={onClick}
          onMouseDown={onMouseDown}
          style={buttonStyle}
          labelStyle={{top:1, ...labelStyle}}
        />
      </div>
    );  
  }
  
  if(label) {
    return (
      <div 
        className={`button-flat ${className} ${isReversed ? 'is-reversed' : ''}`.trim()}
        style={{...baseStyle, ...style}}>
        <FlatButton 
          label={label}
          labelStyle={{top:1, ...labelStyle, color}}
          icon={icon}
          style={buttonStyle}
          onClick={onClick}
          onMouseDown={onMouseDown}
          disabled={disabled}
        />
      </div>
    );
  }
  
  return (
    <div 
      className={`button-icon ${className} ${isReversed ? 'is-reversed' : ''}`.trim()}
      style={{...baseStyle, backgroundColor, width, height, ...style}}
      onClick={onClick}>
      
      <IconButton 
        width={width}
        height={height}
        tooltip={tooltip}
        tooltipPosition={tooltipPosition}
        style={buttonStyle}
        disabled={disabled}
        onMouseDown={onMouseDown}>
        {icon}
      </IconButton>
      
    </div>
  );
  
}));