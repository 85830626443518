import React, { useState } from 'react';
import {
  Modal,
  Button,
  Text,
  Form,
  InputText,
} from 'skybolt-ui';
import { useQuery, updateEpayAccount } from 'skybolt-api';

export default function UserBillingChangeAddressButton(props) {

  // Load the given user's epay account and the epay account for each of their
  // talents.

  const data = useQuery(`{
    user(userId:$userId) {
      epayId
      talents {
        status
        epayId
      }
    }
  }`, {userId:props.userId});


  // Keep hold of the modal and form value.

  const [status, setStatus] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [address, setAddress] = useState({});


  // Change CC details. This will update all accounts for the user and for
  // and talent that the user owns.

  const changeAddress = async () => {

    if(isUpdating) {
      return;
    }
    setUpdating(true);

    if(!address.street || !address.city || !address.state || !address.zip) {
      setError("Please enter all fields.");
      setUpdating(false);
      return;
    }

    try {
      if(data.user.epayId) {
        await updateEpayAccount({epayId:data.user.epayId, address});
      }
      for(const talent of data.user.talents) {
        if(talent.epayId) {
          await updateEpayAccount({epayId:talent.epayId, address});
        }
      }
    }
    catch(error) {
      console.log(error);
      setError(error.message || "Something went wrong, please try again.");
      setUpdating(false);
      return;
    }

    if(props.onChange) {
      props.onChange(address);
    }

    setStatus("sent");

  };


  // Render

  return [
    <Button key="button" raised onClick={() => setStatus('open')}>Change Address</Button>,
    <Modal
      key="modal"
      status={status}
      onRequestClose={() => setStatus(false)}
      modalStyle={{
        maxWidth:400,
        padding:16,
      }}>
      <Text paragraph>Change Address</Text>

      <Form value={address} onChange={v => setAddress(v)}>
        <InputText title="Street" name="street"/>
        <div style={{display:'flex'}}>
          <InputText title="City" name="city" style={{flex:4, marginRight:8}}/>
          <InputText title="State" name="state" style={{flex:1, marginRight:8}}/>
          <InputText title="Zip" name="zip" style={{flex:2}}/>
        </div>
      </Form>

      <Text centered error>{error}</Text>

      <div style={{display:'flex', justifyContent:'flex-end'}}>
        <Button label="Cancel" style={{marginRight:8}} onClick={() => setStatus(false)}/>
        <Button
          raised
          accent={!isUpdating}
          label={isUpdating ? "Hang Tight..." : "Change"}
          onClick={changeAddress}
        />
      </div>
    </Modal>
  ];
}