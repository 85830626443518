import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { 
  duplicatePack,
  subscribeToPack,
  updatePack,
} from 'actions';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';

import "./index.css";

import Media from 'ui/Media';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import PackIcon from 'material-ui/svg-icons/action/work';
import ShortlistIcon from 'material-ui/svg-icons/av/playlist-add-check';
import ScheduleIcon from 'material-ui/svg-icons/action/perm-contact-calendar';
import MoreIcon from 'material-ui/svg-icons/navigation/more-horiz';
import IconButton from 'material-ui/IconButton';





const mapStateToProps = (state, ownProps) => {
  const packId = ownProps.packId || ownProps.pack.id;
  const pack = state.packs.all[packId];
  const permissions = state.user.permissions;
  return {
    ...ownProps,
    packId,
    userId: state.user.id,
    permissions,
    pack,
  };
};

const mapDispatchToProps = {
  duplicatePack,
  subscribeToPack,
  updatePack,
};





class PackCard extends Component {
  
  state = {
    open: false,
  }
  
  componentDidMount() {
    this.unsubscribeFromPack = this.props.subscribeToPack(this.props.pack.id);
  }
  
  componentWillUnmount() {
    if(this.unsubscribeFromPack) {
      this.unsubscribeFromPack();
    }
  }
  
  duplicate = async () => {
    const { duplicatePack, pack, history:{ push } } = this.props;
    const newPack = await duplicatePack(pack.id);
    push(`/packs/${newPack.id}`);
  }

  archive = () => {
    const { updatePack, pack } = this.props;
    updatePack(pack.id, {isArchived:true});
  }

  unArchive = () => {
    const { updatePack, pack } = this.props;
    updatePack(pack.id, {isArchived:null});
  }
  
  renderImage() {
    const accentColor = this.props.muiTheme.palette.accent600;
    const preview = this.props.pack.preview;
    
    const packUpdated = get(this.props.pack, 'dateUpdated', null);
    const packViewed = get(this.props.pack, `dateViewed[${this.props.userId}]`, null);
    const isUnread = packViewed && packUpdated && packViewed < packUpdated;
    
    // console.log(this.props.pack.name, isUnread, packViewed, packUpdated);
    
    const Wrap = ({children}) => (
      <div 
        className="packcard-image"
        style={{
          borderRight: !!isUnread ? `4px solid ${accentColor}` : 'none',
          marginRight: !!isUnread ? 12 : 16,
        }}>
        {children}
      </div>
    );
    
    if(!preview) {
      return (
        <Wrap>
          <Media 
            icon="pack" 
            width={60} 
            height={60} 
            backgroundColor="white"
            className="packcard-image-image"
          />
        </Wrap>
      );
    }
    
    return (
      <Wrap>
        <img src={preview} alt="preview" className="packcard-image-image"/>
      </Wrap>
    );
  }
  
  renderInfo() {
    const {
      pack,
      muiTheme:{ palette }
    } = this.props;
    
    const contributors = Object.keys(pack.recipients || {})
      .map(key => pack.recipients[key])
      .filter(recipient => !!recipient)
      .map(recipient => recipient.name)
      .join(', ');

    return (
      <div className="packcard-info">
        <Link 
          to={`/packs/${pack.id}`} 
          style={{color:palette.textColor}}
          className="packcard-name">
          {pack.name}
        </Link>
        
        <Link to={`/packs/${pack.id}`} className="packcard-subtitle">
          {pack.userName || contributors}
        </Link>
        
      </div>
    );
  }
  
  renderLastMessage() {
    // if(this.props.userId !== this.props.pack.userId) {
    //   return <div className="packcard-message"></div>;
    // }
    
    const pack = this.props.pack;
    let name = "";
    let message = "";
    
    if(pack.lastMessage) {
      name = get(pack, 'lastMessage.from.name', "");
      const bodyEl = document.createElement("DIV");
      bodyEl.innerHTML = pack.lastMessage.body;
      message = bodyEl.textContent || bodyEl.innerText || null;
      // document.removeElement(bodyEl);
    }
    
    
    if(!name || !message) {
      return <div className="packcard-message"></div>;
    }
    
    return (
      <Link to={`/packs/${pack.id}`} className="packcard-message">
        <div className="packcard-title">{name}</div>
        <div className="packcard-subtitle">{message}</div>
      </Link>
    );
  }
  
  renderDate() {
    const dateUpdated = this.props.pack.dateUpdated;
    const diff = moment().diff(dateUpdated, 'week');
    const date = diff < 1 ? moment(dateUpdated).fromNow() : moment(dateUpdated).format('MM/DD/YY hh:mma');
    
    return (
      <div className="packcard-date">
        <div className="packcard-subtitle">
          {date}
        </div>
      </div>
    );
  }
  
  renderTalents() {
    // if(this.props.userId !== this.props.pack.userId) {
    //   return <div className="packcard-stat"></div>;
    // }
    
    const {
      pack,
      muiTheme:{ palette }
    } = this.props;
    const packUpdated = get(this.props.pack, 'dateTalentsUpdated', null);
    const packViewed = get(this.props.pack, `dateViewed[${this.props.userId}]`, null);
    const active = packViewed && packUpdated && packViewed < packUpdated;
    const count = pack.talentsCount;
    const color = active ? palette.accent600 : palette.grey400;
    
    return (
      <Link to={`/packs/${pack.id}`} className="packcard-stat" style={{color}}>
        <PackIcon color={color} style={{width:14, height:14, marginRight:4}}/>
        <div>
          {count || "–"}
        </div>
      </Link>
    );
  }
  
  renderShortlist() {
    // if(this.props.userId !== this.props.pack.userId) {
    //   return <div className="packcard-stat"></div>;
    // }
    
    const {
      pack,
      muiTheme:{ palette }
    } = this.props;
    const packUpdated = get(this.props.pack, 'dateShortlistUpdated', null);
    const packViewed = get(this.props.pack, `dateViewed[${this.props.userId}]`, null);
    const active = packViewed && packUpdated && packViewed < packUpdated;
    const count = pack.shortlistSize;
    const color = active ? palette.accent600 : palette.grey400;
    
    return (
      <Link to={`/packs/${pack.id}`} className="packcard-stat" style={{color}}>
        <ShortlistIcon color={color} style={{width:14, height:14, marginRight:4}}/>
        <div>
          {count || "–"}
        </div>
      </Link>
    );
  }
  
  renderSchedule() {
    // if(this.props.userId !== this.props.pack.userId) {
    //   return <div className="packcard-stat"></div>;
    // }
    
    const {
      pack,
      muiTheme:{ palette }
    } = this.props;
    const packUpdated = get(this.props.pack, 'dateScheduleUpdated', null);
    const packViewed = get(this.props.pack, `dateViewed[${this.props.userId}]`, null);
    const active = packViewed && packUpdated && packViewed < packUpdated;
    const count = pack.eventsCount;
    const color = active ? palette.accent600 : palette.grey400;
    
    return (
      <Link to={`/packs/${pack.id}`} className="packcard-stat" style={{color}}>
        <ScheduleIcon color={color} style={{width:14, height:14, marginRight:4}}/>
        <div>
          {count || "–"}
        </div>
      </Link>
    );
  }
  
  renderActions() {
    // if(this.props.userId !== this.props.pack.userId) {
    //   return <div className="packcard-actions"></div>;
    // }
    
    const {
      permissions: { canPack },
      muiTheme:{ palette },
    } = this.props;
    
    if(!canPack) {
      return null;
    }
    
    return ( 
      <div className="packcard-actions" ref={el => this.anchorEl = el}>
        <IconButton 
          onClick={() => this.setState({open:true})}>
          <MoreIcon color={palette.grey400}/>
        </IconButton>
      </div>
    );
  }
  
  render() {
    return (
      <div className={`packcard ${this.props.className} ${this.props.pack.isArchived ? 'is-archived' : ''}`} style={this.props.style}>
        {this.renderImage()}
        {this.renderInfo()}
        {this.renderLastMessage()}
        {this.renderDate()}
        {this.renderTalents()}
        {this.renderShortlist()}
        {this.renderSchedule()}
        {this.renderActions()}
        
        <Popover
          open={this.state.open}
          anchorEl={this.anchorEl}
          anchorOrigin={{horizontal: 'middle', vertical: 'center'}}
          targetOrigin={{horizontal: 'right', vertical: 'top'}}
          onRequestClose={()=> this.setState({open:false})}>
          <Menu>
            <MenuItem primaryText="Duplicate" onClick={this.duplicate}/>
            {this.props.pack.isArchived ? (
              <MenuItem primaryText="Unarchive" onClick={this.unArchive}/>
            ):(
              <MenuItem primaryText="Archive" onClick={this.archive}/>
            )}
          </Menu>
        </Popover>
        
      </div>
    );
  }
}



export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    muiThemeable()(
      PackCard
    )
  )
);
