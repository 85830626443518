import { combineReducers } from "redux";


const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_SUBSCRIPTION':
      return {
        ...state,
        [action.subscription.id]: action.subscription
      };
      
    case 'LOAD_TALENT_SUBSCRIPTION_REQUEST':
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          isLoading: true,
        }
      };
      
    case 'LOAD_TALENT_SUBSCRIPTION':
      return {
        ...state,
        [action.talentId]: action.subscription
      };
      
    case 'LOAD_TALENT_SUBSCRIPTION_FAILURE':
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          isLoading: false,
          error: action.error,
        }
      };
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byTalent = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_TALENT_SUBSCRIPTION_REQUEST':
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          isLoading: true,
        }
      };
      
    case 'LOAD_TALENT_SUBSCRIPTION':
      return {
        ...state,
        [action.talentId]: action.subscription
      };
      
    case 'LOAD_TALENT_SUBSCRIPTION_FAILURE':
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          isLoading: false,
          error: action.error,
        }
      };
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};




/* DEPRICATED */
const byUser = (state={}, action) => {
  if(action.type === 'LOGOUT') {
    return {};
  }
  
  if(!action.userId) {
    return state;
  }
  
  switch(action.type) {
    case 'LOAD_SUBSCRIPTION_REQUEST':
      return {
        ...state,
        [action.userId]: {
          isLoading: true,
        },
      };
      
    case 'LOAD_SUBSCRIPTION':
      return {
        ...state,
        [action.userId]: {
          data: action.subscription, 
          isLoading:false
        }
      };
      
    case 'LOAD_SUBSCRIPTION_ERROR':
      return {
        ...state,
        [action.userId]: {
          isLoading: false,
          message: action.message,
          error: action.code,
        },
      };
      
    default: 
      return state;
  }
};

/* DEPRICATED */
const byAgency = (state={}, action) => {
  if(action.type === 'LOGOUT') {
    return {};
  }
  
  if(!action.agencyId) {
    return state;
  }
  
  switch(action.type) {
    case 'LOAD_SUBSCRIPTION_REQUEST':
      return {
        ...state,
        [action.agencyId]: {
          isLoading: true,
        },
      };
      
    case 'LOAD_SUBSCRIPTION':
      return {
        ...state,
        [action.agencyId]: {
          data: action.subscription, 
          isLoading:false
        }
      };
      
    case 'LOAD_SUBSCRIPTION_ERROR':
      return {
        ...state,
        [action.agencyId]: {
          isLoading: false,
          message: action.message,
          error: action.code,
        },
      };
      
    default: 
      return state;
  }
};





export default combineReducers({
  all,
  byTalent,
  byUser,
  byAgency,
});