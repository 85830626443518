import React from 'react';
import { useRouter, Container, Text, Rule, Card, Button } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';
import { get } from 'lodash';

export default function() {
  const {
    match: {
      params: {
        invitationId
      }
    }
  } = useRouter();

  const data = useQuery(`{
    invitation(invitationId:$invitationId) {
      email
    }
  }`, {invitationId});

  const email = get(data, 'invitation.email', null);

  return (
    <Container>
      <div style={{paddingTop:32}}>
        <Text title>INVITE GUEST</Text>
      </div>
      <Rule style={{marginBottom:24}}/>
      <Card style={{padding:16}}>
        <Text large paragraph>An invitation has been sent{email ? ` to ${email}.` : '.'}</Text>
        <Button raised href="/admin/invite-guest">Send another Invitation</Button>
      </Card>
    </Container>
  );
}