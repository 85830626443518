import React from 'react';
import {
  Container,
  Header,
  Switch, Route, Redirect,
  useRouter,
  Loading,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';
import Info from './Info';
import Invoices from './Invoices';
import Credits from './Credits';
import Messages from './Messages';
import Settings from './Settings';



export default function Client(props) {

  const {match:{ url, path, params:{ clientId }}} = useRouter();


  // Load data.

  const data = useQuery(`{
    user {
      permissions {
        canAdminBilling
      }
    }
    client(clientId:$clientId) {
      name
    }
  }`, {clientId});


  // Layout

  if(!data) {
    return <Loading/>;
  }

  const tabs = [
    {label:"Info.", icon:"book-user", href:`${url}/info`},
    // {label:"Messages", icon:"envelope", href:`${url}/messages`},
    {label:"Invoices", icon:"file-invoice-dollar", href:`${url}/invoices`},
    {label:"Payments Received", icon:"receipt", href:`${url}/credits`},
  ];

  if(data.user.permissions.canAdminBilling) {
    //tabs.push({label:"Settings", icon:"cog", href:`${url}/settings`});
  }

  return (
    <Container style={{paddingTop:24}}>

      <Header
        name={data.client.name || "New Client"}
        tabs={tabs}
        style={{marginBottom:24}}
      />

      <Switch>
        <Route path={`${path}/info`} component={Info}/>
        <Route path={`${path}/messages`} component={Messages}/>
        <Route path={`${path}/invoices`} component={Invoices}/>
        <Route path={`${path}/credits`} component={Credits}/>
        <Route path={`${path}/settings`} component={Settings}/>
        <Redirect to={`${url}/info`}/>
      </Switch>

    </Container>
  );
}