import React, { useState, useContext } from 'react';
import {
  useQuery,
  updateTalent,
} from 'skybolt-api';
import { sampleSize } from 'lodash';
import {
  useRouter,
  ThemeContext,
  Loading,
  Text,
  Rule,
  Card,
  Form,
  InputText,
  InputNumber,
  InputSelect,
  InputDate,
  Button,
  useLayout,
} from 'skybolt-ui';
import Feature from './Feature';

const features = sampleSize([
  <Feature
    key="video"
    icon="video"
    title="Unlimited Media"
    text="Show off your whole portfolio! We allow for unlimited photos, videos, and voice clips. Update or add material at anytime with no extra charges."
  />,
  <Feature
    key="calendar"
    icon="calendar"
    title="Calendar Sharing"
    text="Input your blocked out dates on your calendar to keep your agent up to date with your schedule as well as see your casting and booking appointments created by your agent."
  />,
  <Feature
    key="contact"
    icon="contact"
    title="Searchable Special Skills"
    text="Have an odd or unique skill? &nbsp;Put it in your resume's special skills section and if your agent is ever casting for that it will be easy for them to pick you out."
  />,
  // <Feature
  //   key="resume"
  //   icon="resume"
  //   title="Resume"
  //   text="Input your resume. There is nothing more that agents and casting directors love to see than a clean, uniform, and professional resume!"
  // />,
  <Feature
    key="messages"
    icon="messages"
    title="Texting Alerts"
    text="With texting alerts your agent can reach you quickly with bookings, casting notices, and other requests."
  />
], 3);


export default function TalentWelcome() {

  const router = useRouter();
  const history = router.history;
  const talentId = router.match.params.talentId;

  const data = useQuery(`{
    talent(talentId:$talentId) {
      firstName,
      lastName,
      emails,
      phones,
      agency {
        name
        welcomeText
        talentFields
      }
      requireShowWelcome,
      street,
      city,
      state,
      zip,
    }
  }`, {talentId});




  // Handle form data, errors, and submissions.

  const [value, setValue] = useState();
  const [error, setError] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  if(data && !value) {
    setValue({
      firstName: data.talent.firstName || "",
      lastName: data.talent.lastName || "",
      emails: data.talent.emails || [{email:""}],
      phones: data.talent.phones || [{number:""}],
      street: data.talent.street || "",
      city: data.talent.city || "",
      state: data.talent.state || "",
      zip: data.talent.zip || "",
    })
  }

  const handleChange = val => {
    setValue(val);
    setError("");
  };

  const fields = data ? data.talent.agency.talentFields : {};
  const requiredFields = Object.keys(fields)
    .map(id => {
      return {
        id,
        ...fields[id],
      };
    })
    .filter(field => {
      return field.isRequired && field.category !== 'system';
    })
    .sort((a,b) => !a.order & b.order ? -1 : a.order - b.order);

  const handleSubmit = async () => {
    if(isSubmitting) {
      return;
    }
    setSubmitting(true);

    for (let key of Object.keys(requiredFields)) {
      if(!value[requiredFields[key].id]) {
        setError("Please fill out fields.");
        setSubmitting(false);
        return;
      }
    }

    if(!value.street || !value.city || !value.state || !value.zip) {
      setError("Please fill out your address.");
      setSubmitting(false);
      return;
    }

    await updateTalent({
      talentId,
      change: {
        ...value,
        requireShowWelcome: null,
      }
    });

    history.replace(`/talents/${talentId}`);
  };




  // Render

  const {width} = useLayout();
  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  // if(!data.talent.requireShowWelcome) {
  //   return <Redirect to={`/talents/${talentId}`}/>;
  // }

  return (
    <div style={{width:'100%'}}>
      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          padding: '40px 16px 80px 16px',
          marginBottom: -80,
          textAlign: 'center',
          backgroundColor: theme.color.accent
        }}>
        <Text
          block
          style={{
            fontSize: data.talent.agency.name.length > 10 ? "20px" : "40px",
            lineHeight:'.9em',
            color:'white',
            paddingBottom:4,
          }}>
          WELCOME TO
        </Text>
        <Text
          block
          style={{
            fontSize: data.talent.agency.name.length > 10 ? "40px" : "60px",
            lineHeight:'.9em',
            color:'white',
            textTransform:'uppercase'
          }}>
          {data.talent.agency.name}
        </Text>
        <Rule small color="white" style={{margin:'32px auto 24px'}}/>
        <Text
          large
          style={{color:theme.color.lightTextLighter, textAlign:'center', fontSize:16, lineHeight:'18px', marginBottom:32, maxWidth:600}}
          dangerouslySetInnerHTML={{
            __html:
              data.talent.agency.welcomeText ||
              `Sizes, photos, resume... all your information
              is instantly ready at your agents fingertips
              to quickly fill casting requests and bookings.`
          }}
        />
      </div>

      <div
        style={{
          padding: '0 16px 40px 16px',
          display: "grid",
          gridTemplateColumns: `repeat(${width > 560 ? 3 : 1}, 240px)`,
          gridGap: 16,
          justifyContent:'center',
          justifyItems: 'center',
        }}>
        {features}
      </div>

      <Text large paragraph centered>Get started on your new profile.</Text>

      <Card
        depth={1}
        style={{
          maxWidth:600,
          padding:16,
          margin:'0 auto 40px auto',
        }}>

        <Form value={value} onChange={handleChange}>

          {(!fields.firstName || fields.firstName.enabled !== false) &&
            <InputText
              name="firstName"
              title={fields.firstName ? fields.firstName.name : "First Name"}
            />
          }

          {(!fields.lastName || fields.lastName.enabled !== false) &&
            <InputText
              name="lastName"
              title={fields.lastName ? fields.lastName.name : "Last Name"}
            />
          }

          {(!fields.dob || fields.dob.enabled !== false) &&
            <InputDate
              name="dob"
              title={fields.dob ? fields.dob.name : "Date of Birth"}
              hint="MM/DD/YYYY"
              style={{maxWidth:200}}
            />
          }

          {(
            (!fields.firstName || fields.firstName.enabled !== false) ||
            (!fields.lastName || fields.lastName.enabled !== false) ||
            (!fields.dob || fields.dob.enabled !== false)
           ) &&
            <Rule color={theme.color.greyLight} style={{margin:'8px 0 16px 0'}}/>
          }

          <InputText
            name="emails.0.email"
            title="Primary Email Address"
          />

          <InputText
            name="phones.0.number"
            title="Primary Phone Number"
          />

          <Rule color={theme.color.greyLight} style={{margin:'8px 0 16px 0'}}/>

          <InputText
            name="street"
            title="Street Address"
          />

          <div style={{display:'grid', gridTemplateColumns:'3fr 1fr 2fr', gap:16}}>
            <InputText
              name="city"
              title="City"
            />
            <InputText
              name="state"
              title="State"
            />
            <InputText
              name="zip"
              title="Zip"
            />
          </div>

          <Rule color={theme.color.greyLight} style={{margin:'8px 0 16px 0'}}/>

          {requiredFields.map(field => {
            switch(field.type) {
              case 'number':
              case 'range':
                return (
                  <InputNumber
                    key={field.id}
                    name={field.id}
                    title={field.name}
                    min={field.min}
                    max={field.max}
                    units={field.units}
                  />
                );
              case 'select':
                return (
                  <InputSelect
                    key={field.id}
                    name={field.id}
                    title={field.name}
                    options={field.options}
                  />
                );
              case 'date':
                return (
                  <InputDate
                    key={field.id}
                    name={field.id}
                    title={field.name}
                  />
                );
              default:
                return (
                  <InputText
                    key={field.id}
                    name={field.id}
                    title={field.name}
                  />
                );
            }
          })}

          <div style={{display:'flex', alignItems:'center', paddingTop:16}}>

            <Button
              accent={!isSubmitting}
              raised
              label={!isSubmitting ? "Get Started" : "Hang Tight..."}
              onClick={handleSubmit}
              style={{marginRight:16}}
            />

            <Text error>{error}</Text>

          </div>

        </Form>

      </Card>
    </div>
  );
}