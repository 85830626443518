

export const DEV = process.env.REACT_APP_DIST === 'development';

const devFirebaseConfig = {
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyBjjV3MGATWxMtBRA039BtzNI3FT6fwGl4",
  authDomain: "skybolt-99adb.firebaseapp.com",
  databaseURL: "https://skybolt-99adb.firebaseio.com",
  projectId: "skybolt-99adb",
  storageBucket: "skybolt-99adb.appspot.com",
  messagingSenderId: "1005495997962",
  vapidKey: "BCU546FIoQIAgG7zDmLylGwU5FDgbtf9QOT4Vuv5rp2RIIoIRw2xOtkVf4BK9jbYa-FosMReOH5QGQ0BfLvMD18",
  functionsUrl: "https://us-central1-skybolt-99adb.cloudfunctions.net/",
};

export const firebaseConfig =  DEV ? devFirebaseConfig : prodFirebaseConfig;

export const firebaseFunctionsURL = DEV ? "https://us-central1-skybolt-test.cloudfunctions.net" : "https://us-central1-skybolt-99adb.cloudfunctions.net";

const algoliaApp = DEV ? 'LQQFO2N0CV' : 'HWBJCY4R00';
const algoliaKey = DEV ? 'dfe5848a348499841739941b09618896' : 'c4d127e4f3a194b9aa0047030b4a54b0';

export const algoliaConfig = {
  applicationId: algoliaApp, // depricated
  appId: algoliaApp,
  apiKey: algoliaKey
};

export const cloudinaryCloudName = DEV ? 'dzosm28gh' : 'hllycw0yx';
export const cloudinaryUploadPreset = DEV ? 'o307peaz' : "szeod0xz";

export const cloudinaryConfig = {
  cloud_name: cloudinaryCloudName,
  cloudName: cloudinaryCloudName,
  uploadPreset: cloudinaryUploadPreset,
};

export const systemTalentFields = {
  firstName: {
    order: -6,
    name: "First Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isRequired: true,
    isVisibleOnCard: true,
  },
  middleName: {
    order: -5,
    name: "Middle Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isRequired: true,
    isVisibleOnCard: true,
  },
  lastName: {
    order: -4,
    name: "Last Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isRequired: true,
    isVisibleOnCard: true,
  },
  contactName: {
    order: -3,
    name: "Contact Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isVisibleOnCard: true,
  },
  ssn: {
    order: -2,
    name: "SSN",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isEnabled: false,
  },
  dob: {
    order: -1,
    name: "Date of Birth",
    type: "date",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isRequired: true,
    isVisibleOnCard: true,
  }
};

export const baseTalentFields = {
  age: {
    // category: 'system',
    type: 'number',
    name: "Age",
    min: 0,
    max: 100,
    order: -1,
  },
  street: {
    type: 'text',
    name: "Street",
    order: 100,
  },
  city: {
    type: 'text',
    name: "City",
    order: 101,
  },
  state: {
    type: 'text',
    name: "State",
    order: 102,
  },
  zip: {
    type: 'text',
    name: "Zip",
    order: 103,
  },
};

export const defaultTalentFields = {
  gender: {
    name: "Gender",
    isPublic: true,
    isEditableByTalent: true,
    type: "select",
    options: ["Male", "Female"],
  },
  ethnicity: {
    name: "Ethnicity",
    isPublic: true,
    isEditableByTalent: true,
    type: "select",
    options: [
      "African-American", "Asian", "Caucasian", "Indian",
      "Latin American", "Middle Eastern", "Hispanic", "Filipino",
      "Eurasian", "Samoan"
    ],
  },
  hair: {
    name: "Hair",
    isPublic: true,
    isEditableByTalent: true,
    type: "select",
    options: [
      "Auburn", "Bald", "Black", "Blonde", "Brown", "Grey", "Light Brown",
      "Red", "Salt & Pepper", "Strawberry Blonde"
    ],
  },
  eye: {
    name: "Eye",
    isPublic: true,
    isEditableByTalent: true,
    type: "select",
    options: [
      "Blue", "Blue Green", "Brown", "Green", "Hazel"
    ],
  },
  size: {
    name: "Size",
    isPublic: true,
    isEditableByTalent: true,
    type: "select",
    options: [
      "3 MO", "6 MO", "9 MO", "12 MO", "18 MO", "24 MO", "2T", "2",
      "3T", "3", "4T", "4", "5", "6", "6X", "7", "8", "10", "12", "14",
      "16", "18", "20", "JR"
    ],
  },
  bracup: {
    name: "Bra Cup",
    isPublic: true,
    isEditableByTalent: true,
    type: "select",
    options: [
      "AA", "A", "B", "C", "D", "E", "V",
    ],
  },
  unions: {
    name: "Unions",
    multiple: true,
    isPublic: true,
    isEditableByTalent: true,
    type: "select",
    options: [
      "SAG", "AFTRA", "SAG Eligible", "non-union", "AFTRA Eligible",
      "Fi-Core", "AEA", "AGVA", "AGMA"
    ],
  },
  playsAge: {
    name: "Plays Age",
    isPublic: true,
    isEditableByTalent: true,
    type: "range",
    units: "years",
    min: 0,
    max: 60,
  },
  height: {
    name: "Height",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "in.",
    min: 12,
    max: 95,
  },
  weight: {
    name: "Weight",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "lbs.",
    min: 0,
    max: 300,
  },
  bust: {
    name: "Bust",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "in.",
    min: 15,
    max: 52,
  },
  waist: {
    name: "Waist",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "in.",
    min: 15,
    max: 52,
  },
  highHips: {
    name: "High Hips",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "in.",
    min: 15,
    max: 52,
  },
  hips: {
    name: "Hips",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "in.",
    min: 15,
    max: 52,
  },
  inseam: {
    name: "Inseam",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "in.",
    min: 8,
    max: 36,
  },
  thigh: {
    name: "Thigh",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "in.",
    min: 8,
    max: 24,
  },
  shoe: {
    name: "Shoe",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    min: 1,
    max: 14,
  },
  suit: {
    name: "Suit",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    min: 33,
    max: 52,
  },
  dress: {
    name: "Dress",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    min: 1,
    max: 18,
  },
  sleeve: {
    name: "Sleeve",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "in.",
    min: 22,
    max: 38,
  },
  neck: {
    name: "Neck",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "in.",
    min: 14,
    max: 18,
  },
  bisep: {
    name: "Bisep",
    isPublic: true,
    isEditableByTalent: true,
    type: "number",
    units: "in.",
    min: 8,
    max: 20,
  },
  workexp: {
    name: "Work Permit Exp.",
    isPublic: false,
    isEditableByTalent: false,
    type: "date",
  },
  contractexp: {
    name: "Contract Exp.",
    isPublic: false,
    isEditableByTalent: true,
    type: "date",
  },
  facebook: {
    name: "Facebook URL",
    isPublic: false,
    isEditableByTalent: true,
    type: "text",
  },
  twitter: {
    name: "Twitter URL",
    isPublic: false,
    isEditableByTalent: true,
    type: "text",
  },
  instagram: {
    name: "Instagram URL",
    isPublic: false,
    isEditableByTalent: true,
    type: "text",
  },
};

export const resumeSections = [
  "Print",
  "Commercial",
  "Television",
  "Film",
  "Theater",
  "Industrial",
  "Fit Modeling",
  "Fashion Shows",
  "Music Video",
  "Promotional Model",
  "Stage Show",
  "Trade Show",
  "Tour",
  "Spokes Model",
  "Honors",
  "Voice-Over",
  "Training",
  "Internet",
  "Live Hosting",
  "Dance",
];

export const audioFormats = ['mp3', 'wav', 'm4a'];
export const imageFormats = ['jpg', 'png', 'gif', 'heic'];
export const videoFormats = ['wmv', 'mp4', 'youtube', 'mov'];
export const documentFormats = ['pdf'];

export const mediaFormats = ['jpg', 'png', 'wmv', 'mp4', 'youtube', 'mov', 'gif', 'mp3', 'wav', 'm4a', 'heic'];
export const uploadFormats = ['jpg', 'png', 'wmv', 'mp4', 'youtube', 'mov', 'gif', 'mp3', 'wav', 'm4a', 'pdf'];


export const baseTerms = `
  <h2>These “Terms of Use” set forth the terms and conditions that apply to your use of www.skybolt.net (the “Web Site”). By using the Web Site (other than to read this page for the first time), you agree to comply with all of the Terms of Use set forth herein. The right to use the Web Site is personal to you and is not transferable to any other person or entity.</h2>
  <h2>Copyrights and Trademarks</h2>
  <p>A. All materials contained on the Web Site are Copyright April 22, 1999, 2002, and 2016, Skybolt. All rights reserved.</p>
  <p>B. No person is authorized to use, copy or distribute any portion the Web Site including related graphics or its functionality.</p>
  <p>C. Skybolt and other trademarks and/or service marks (including logos and designs) found on the Web Site are trademarks/service marks that identify Skybolt and the goods and/or services provided by Skybolt. Such marks may not be used under any circumstances without the prior written authorization of Skybolt.</p>
  <p>D. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
  <h2>Links to Third-Party Web Site</h2>
  <p>Skybolt may provide hyperlinks to third-party web sites as a convenience to users of the Web Site. Skybolt does not control third-party web sites and is not responsible for the contents of any linked-to, third-party web sites or any hyperlink in a linked-to web site. Skybolt does not endorse, recommend or approve any third-party web site hyperlinked from the Web Site. Skybolt will have no liability to any entity for the content or use of the content available through such hyperlink.</p>
  <h2>Modifications to the Services and Prices</h2>
  <p>Prices for our services are subject to change without notice.We reserve the right at any time to modify or discontinue the service (or any part or content thereof) without notice at any time.We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the service.</p>
  <h2>No Representations or Warranties; Limitations on Liability</h2>
  <p>The information and materials on the Web Site could include technical inaccuracies or typographical errors. Changes are periodically made to the information contained herein. Skybolt MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY INFORMATION, MATERIALS OR GRAPHICS ON THE WEB SITE, ALL OF WHICH IS PROVIDED ON A STRICTLY “AS IS” BASIS, WITHOUT WARRANTY OF ANY KIND AND HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES WITH REGARD TO ANY INFORMATION, MATERIALS OR GRAPHICS ON THE WEB SITE, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. UNDER NO CIRCUMSTANCES SHALL THE SITE OWNER OR PUBLISHER BE LIABLE UNDER ANY THEORY OF RECOVERY, AT LAW OR IN EQUITY, FOR ANY DAMAGES, INCLUDING WITHOUT LIMITATION, SPECIAL, DIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO LOSS OF USE OR LOST PROFITS), ARISING OUT OF OR IN ANY MANNER CONNECTED WITH THE USE OF INFORMATION OR SERVICES, OR THE FAILURE TO PROVIDE INFORMATION OR SERVICES, FROM THE WEB SITE.</p>
  <h2>Non Disclosure</h2>
  <p>The agency using the Skybolt program agrees not to disclose any functionality or proprietary information to any third party who may compete against Skybolt either directly or indirectly.</p>
  <h2>Changes to These Terms of Use</h2>
  <p>Skybolt reserves the right to change these Terms of Use at any time by posting new Terms of Use at this location. You can send e-mail to Skybolt with any questions relating to these Terms of Use at info@skybolt.net.</p>
  <h2>Acceptance of Privacy Policy</h2>
  <p>This Privacy Policy is incorporated into and subject to the Terms of Use. Each time that you access or use Skybolt.net you signify that you agree to be bound by the Terms and this Privacy Policy. If you do not agree to the Terms and the terms of this Privacy Policy, you must discontinue your account with Skybolt.net</p>
  <p>Skybolt.net provides content hosting and communication services to actors, models, voice-over actors and other artists, connecting talent with agents and managers, casting directors, and other professionals rendering services in motion picture, theatrical, radio, television and other entertainment enterprises.</p>
  <h2>Notice To Talent</h2>
  <p>Skybolt.net is designed so that only your agent may be allowed to review your personal information and to contact you through Skybolt.net. By registering you will be creating a profile which will be accessible on Skybolt.net. Personal contact and address information is only accessible by your agent. Images, video, resume, and size info may be made available to anyone your agency chooses. You understand that you are affirmatively choosing to provide and post personal information about yourself including, but not limited to, images, video, audio, your real and/or stage name, sizes and stats, skills, gender, ethnical appearance, address, and phone number. You further acknowledge and agree that with the exception of personal contact information your profile may be accessible to anyone your agent chooses. Skybolt.net will not be held responsible for how those images, video, audio, etc. may be used.</p>
  <h2>Minors</h2>
  <p>We are very concerned about the safety and privacy of all of our members, particularly individuals under the age of 18. We do not knowingly collect personal information directly from children under the age of 14, nor do we allow users between 14 and 17 to directly establish their own Profiles on the Sites. Users between 14 and 17 may only open an account or create a Profile if they are represented by an adult agent or other authorized Talent Representative, or by a parent or legal guardian who has registered on their behalf and who maintains the Profile on their behalf. We reserve the right at any time to require verification of a Registering Adult and evidence of the Registering Adult’s relationship with any Minor.  If we become aware that an account has been established for a Minor without a Registering Adult’s permission and supervision, we will take steps to terminate the account and remove any corresponding information.</p>
  <h2>Information Obtained Directly From You</h2>
  <p>When you register for an account and create a Skybolt account, you or your authorized Talent Representative or Registering Adult may provide certain non-public information to create your Profile or provide billing information.</p>
  <p>For security purposes, we ask that you do not enter financial information into your Profile except in the fields provided for that information and advise that you do not put sensitive information such as social security numbers, national identification numbers, or any state, or other local identification numbers in your profile.</p>
  <h2>Information To Respond To Your Requests</h2>
  <p>You may give us your personal information so we can send you communications you request. You may opt out by emailing us at info@skybolt.net or by using our online contact form.</p>
  <h2>Images and Videos You Post</h2>
  <p>Skybolt.net reserves the right to remove any images, video, or content that may be offensive or in bad taste based solely on the Skybolt managements discretion. There is no expectation of privacy when posting content. We have no control over who your Talent Agency may send your profile too or how those recipients may use those images, videos, etc.</p>
  <h2>Contacting Us</h2>
  <p>When you provide us with your information when you sign up for our services including an email address or telephone number you agree that this action constitutes a purchase or inquiry establishing a business relationship with us. You expressly consent to our communicating with you about our services using the contact information you provided to us. You also attest that you have the legal authority over any telephone number you provide to us and can provide us with the authorization to contact you. This means we may contact you by recorded message, by e-mail, telephone and/or mobile telephone number, text (SMS) message, or any other means of communication that your wireless or other telecommunications device may be capable of receiving.</p>
  <h2>Database Information</h2>
  <p>Skybolt.net is designed to only allow your agent to view your personal contact information. Your images, resume, media, and size information may be shared with anyone your agency submits your profile to. This may be casting directors, photographers, or any decision maker looking to book you for work. Submissions by your agent that include your profile or guest access to your profile we expect are legitimate industry professionals, but we do not do background checks on who receives those profiles and cannot guarantee who might gain access to them and we have no control over how those users may use your images or media.. Therefore, there is no expectation of privacy when posting, or otherwise participating in Skybolt.net.  Only your agent however is authorized to have access to your personal contact information. You may change or cancel your account at any time. We have the right, but not the obligation, to monitor any activity and content associated with Skybolt. We reserve the right to take action in connection with any content we deem to be inappropriate.</p>
  <h2>Processing Credit Card Payments</h2>
  <p>Information may be disclosed to process credit card transactions. In this case, established third party banking processing agents and distribution institutions handle these transactions. They use the information to verify and authorize your credit card or other payment information and to process your order. In addition, we may disclose Information to third parties to verify the authenticity of any financial transaction involving our company or its subsidiaries or to update your billing information.</p>
  <h2>Accessing  your Personal Information</h2>
  <p>We want to make reasonable efforts to ensure that the information we keep for you is accurate and complete. Subject to our right to verify your authorization to change an account, you have the right at any time to access, review, update, or change your account Profile. You should promptly update your personal information if it changes or becomes inaccurate. Registered members can review and change personal information at any time by accessing their accounts. If for any reason you are concerned that the personal information you provided to us is not correct and you cannot change it by accessing your account, contact us at info@skybolt.net We may retain some information from closed accounts so that we can comply with law, prevent fraud, assist with investigations, resolve disputes, analyze or troubleshoot programs, enforce our Terms, or take other actions permitted by law. Likewise if your account or membership is terminated or suspended, we may maintain some information to prevent re-registration.</p>
  <h2>Changing your Agency</h2>
  <p>Due to privacy policies we have with talent agents your profile and its related package history cannot be moved to another agent. To change agents a new profile must be created under the new agency. All new account setup fees and monthly charges will apply. Once the new account is setup you will need to notify us by email at cancel@Skybolt.net to cancel your old account.</p>
  <h2>Canceling Your Account</h2>
  <p>You may cancel your account at any time by contacting us at cancel@skybolt.net or by using the “Contact Us” form on our website. It is your responsibility to cancel your account not your agents. Please be sure to include your name and the agent you are with. You will be responsible for the month that you are in and we will not refund partial months. If you paid for the year we will refund the unused portion of your membership to the nearest month. Your billing information will be deleted however your Skybolt profile will be archived and removed from your agent’s roster.</p>
  <h2>Administrative Messages</h2>
  <p>You may not opt-out of administrative e-mails (e.g. e-mails about your transactions or policy changes) for your account.</p>
  <h2>Security</h2>
  <p>Skybolt.net credit card transactions are processed through a third party merchant. To maintain security of your credit card information transactions are placed through SSL encryption. Since no security system can be 100% secure; accordingly, we cannot guarantee the security of any information provided to us in connection with the information that we collect.</p>
  <h2>How to Cancel</h2>
  <p>You may cancel your Skybolt membership at any time and you are only responsible for the month you are in.</p>
  <p>It is your responsibility to cancel your account not your agents.  There will be no refunds for the remainder of the month you are in.</p>
  <h2>Refunds</h2>
  <p>If you are on monthly billing you are responsible for the month you are in and we do not refund partial months.  If you have paid in advance for the year we will prorate your membership and refund the balance of your subscription to the nearest month. If you are on monthly billing you are responsible for the month you are in and we do not refund partial months.</p>
`;








/* DEPRICATED */

/*
Cost per billable item per plan. This should match plans in Braintree.
TODO: We've moved this to the individual agencies. You should pull exact pricing
from braintree instead of embedding it there so they don't get out of sync, but
you can do that later. I'm leaving these here in case some other part of the app
needs them that I'm forgetting about. But try deleting them when you get a chance.
I do know the 'agency' plan might need to be detangled.
*/
export const plans = {
  'standard': {
    name: "Monthly",
    description: "",
    cycle: "Month",
    talent: 9,
    talentSetup: 20,
    talent2: 4.5,
  },
  'standard-yearly': {
    name: "Yearly",
    cycle: "Year",
    talent: 108,
    talentSetup: 20,
    talent2: 54,
  },
  'agency': {
    name: "Billing",
    cycle: "Month",
    talent: 9,
    talentSetup: 20,
  },
  'paragon': {
    "id": "paragon",
    "name": "Starter",
    "features": [
      "General Listing on the XL Platform",
      "Customize your listing with your photo",
      "Get an annual rankings score that is seen by all coaches and recruiters",
      "Bi-monthly Paragon XL Newsletter to keep informed about the industry",
      "Paragon XL Key Chain for girls",
      "Paragon XL spirit towel for boys"
    ],
    "cycle": "Month",
    "talent": 9.95
  },
  'paragon-yearly': {
    "name": "Starter",
    "features": [
      "General Listing on the XL Platform",
      "Customize your listing with your photo",
      "Get an annual rankings score that is seen by all coaches and recruiters",
      "Bi-monthly Paragon XL Newsletter to keep informed about the industry",
      "Paragon XL Key Chain for girls",
      "Paragon XL spirit towel for boys"
    ],
    "cycle": "Year",
    "talent": 119.40
  },
  'paragon-spotlight': {
    "name": "Spotlight",
    "features": [
      "Includes all services provided in the Starter Package",
      "Upload your videos to showcase and demonstrate your talents",
      "Regularly featured in Cheerleader Spotlight section",
      "Discounts on industry vendors products",
      "Paragon XL Hair Bow for girls",
      "Paragon XL Arm Sleeve for boys"
    ],
    "cycle": "Month",
    "talent": 19.95
  },
  'paragon-spotlight-yearly': {
    "name": "Spotlight",
    "features": [
        "Includes all services provided in the Starter Package",
        "Upload your videos to showcase and demonstrate your talents",
        "Regularly featured in Cheerleader Spotlight section",
        "Discounts on industry vendors products",
        "Paragon XL Hair Bow for girls",
        "Paragon XL Arm Sleeve for boys"
    ],
    "cycle": "Year",
    "talent": 239.40
  },
  'paragon-showcase': {
    "name": "Showcase",
    "features": [
      "Includes all services provided in Starter and Spotlight Packages",
      "Get personal introductions to coaches and recruiters",
      "Access to XL Comparison Tool to see where you stand to other Cheerleaders and across the nation",
      "University Spotlight – get in depth information and background on universities and coaches",
      "Paragon XL T-Shirt"
    ],
    "cycle": "Month",
    "talent": 29.95
  },
  'paragon-showcase-yearly': {
    "name": "Showcase",
    "features": [
      "Includes all services provided in Starter and Spotlight Packages",
      "Get personal introductions to coaches and recruiters",
      "Access to XL Comparison Tool to see where you stand to other Cheerleaders and across the nation",
      "University Spotlight – get in depth information and background on universities and coaches",
      "Paragon XL T-Shirt"
    ],
    "cycle": "Year",
    "talent": 359.40
  },
};