/*

# Placeholder 


Usage

    <Placeholder 
      icon="schedule"
      message={message}
      label="Edit Event"
      onClick={this.props.canPack ? ()=>this.props.history.push('event') : null}
    />


*/


import React from 'react';
import './index.css';
import Icon from 'ui/Icon';
import Button from 'ui/Button';


export default ({
  icon,
  message,
  label,
  onClick,
}) => (
  <div className="talent-placeholder">
    <div className="talent-placeholder-icon">
      <Icon 
        name={icon}
        style={{width:300, height:300, opacity:0.03}}
      />
    </div>
    <div className="talent-placeholder-wrapper">
      <div className="talent-placeholder-message">
        {message}
      </div>
      {onClick && 
        <Button raised={true} primary={true} label={label} onClick={onClick}/>
      }
    </div>
  </div>
);