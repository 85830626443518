import React from 'react';
import Search from './Search';
import { Tabs } from 'ui/Tabs';
import Button from 'ui/Button';
import Loading from 'ui/Loading';




export default ({
  children,
  title="",
  subTitle="",
  search="",
  onSearch,
  onNext,
  onPrev,
  filters=[],
  actions=[],
  tabs,
  style={},
  textStyle={},
  isLoading=false,
  ...props
}) => (
  <div className="sui-title" style={style}>


    <div className="sui-title-body" {...props}>

      {isLoading &&
        <div className="sui-title-loading">
          <Loading/>
        </div>
      }

      <div className={`sui-title-text ${onPrev || onNext ? 'fixed-width' : ''}`} style={textStyle}>
        <span>{children}{title}</span> <span className="title-subtext">{subTitle}</span>
      </div>

      {onPrev && <Button icon="prev" iconSize={24} onClick={onPrev} className="sui-title-prev"/>}
      {onNext && <Button icon="next" iconSize={24} onClick={onNext} className="sui-title-next"/>}

      <div className="sui-title-space"/>

      <div className="sui-title-search">
        {!!onSearch &&
          <Search
            search={search}
            onChange={onSearch}
          />
        }
      </div>

      <div className="sui-title-filters">
        {React.Children.map(filters, (filter, i) => React.cloneElement(filter, {key:'filter'+i}))}
      </div>

      {React.Children.map(actions, (action, i) => React.cloneElement(action, {key:'action'+i}))}

    </div>


    {!!tabs && (
      <Tabs drawRule={false} collapse={false} className="sui-title-tabs">
        {React.Children.map(tabs, (tab, i) =>
          React.cloneElement(tab, {
            key:'tab'+i,
            style: {
              color: "#757575",
              backgroundColor: "#F5F5F5",
            },
            activeStyle: {
              color: "#757575",
              backgroundColor: "#E0E0E0",
            }
          })
        )}
      </Tabs>
    )}



  </div>
);