import React from 'react';
import { Switch, Route, Redirect } from 'skybolt-ui';
import List from './List';
import Create from './Create';
import Debit from './Debit';

export default function Payments(props) {
  const {match:{ url, path }} = props;
  return (
    <Switch>
      <Route path={`${path}/list`} component={List}/>
      <Route path={`${path}/create`} component={Create}/>
      <Route path={`${path}/:debitId`} component={Debit}/>
      <Redirect to={`${url}/list`}/>
    </Switch>
  );
}