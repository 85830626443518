import React, { Component } from 'react';
import './index.css';

import Button from 'ui/Button';
import Popover, { Menu, MenuItem } from 'ui/Popover';



export default class Wysiwyg extends Component {
  
  static defaultProps = {
    style:{},
    onSend: ()=>null,
    className: "",
    toolsClassName: "",
    contentClassName: "",
    outline: false,
  }
  
  constructor(props) {
    super(props);
    this.content = null; 
  }
  
  state = {
    fontSizePopoverOpen: false,
    anchorEl: null,
  }
  
  isBold = () => {
    if(document.queryCommandState) {
      return document.queryCommandState('bold');
    }
    return false;
  }
  
  isItalic = () => {
    if(document.queryCommandState) {
      return document.queryCommandState("italic");
    }
    return false;
  }
  
  isUnderlined = () => {
    if(document.queryCommandState) {
      return document.queryCommandState("underline");
    }
    return false;
  }
  
  
  componentDidMount() {
    if(this.props.html) {
      this.value(this.props.html);
    }
    this.content.addEventListener("paste", this.handlePaste);
  }
  
  componentWillUnmount() {
    this.content.removeEventListener("paste", this.handlePaste);
  }
  
  
  handlePaste = e => {
    e.preventDefault();
    let content; 
    
    if(e.clipboardData) {
      content = e.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, content);
    }
    else if(window.clipboardData) {
      content = window.clipboardData.getData('Text');
      document.selection.createRange().pasteHTML(content);
    }
  }
  
  focus = () => {
    if(this.focusedEl) {
      this.focusedEl.focus();
      return;
    }
    this.content.querySelector('[contenteditable=true').focus();
  }
  
  select = (from, to) => {
  }
  
  bold = () => {
    this.focus();
    document.execCommand('bold', false);
    this.forceUpdate();
    this.focus();
  }
  
  underline = () => {
    this.focus();
    document.execCommand('underline', false);
    this.forceUpdate();
    this.focus();
  }
  
  italic = () => {
    this.focus();
    document.execCommand('italic', false);
    this.forceUpdate();
    this.focus();
  }
  
  fontSize = (size) => {
    this.focus();
    document.execCommand('fontSize', false, size);
    this.forceUpdate();
    this.focus();
  }
  
  showFontSize = e => {
    this.setState({
      fontSizePopoverOpen: true,
      anchorEl: e.currentTarget,
    });
  }
  
  hideFontSize = () => {
    this.setState({fontSizePopoverOpen:false}, this.focus);
  }
  
  value = html => {
    if(html) {
      if(html.indexOf('contenteditable') === -1) {
        html = `<div contenteditable="true" data-name="message">${html}</div>`;
      }
      
      // todo: grab any exiting named contenteditable blocks content and put into
      // like-named blocks in new html.
      
      this.content.innerHTML = html;
      return;
    }
    
    html = this.content.innerHTML
      .replace(` contenteditable="true"`, '');
      
    return html;
  }
  
  raw = html => {
    if(html) {
      this.content.innerHTML = html;
    }
    else {
      return this.content.innerHTML;
    }
  }
  
  
  handleFocus = e => {
    this.focusedEl = e.target;
  }
  
  handleInput = e => {
    this.forceUpdate();
    
    if(this.props.onChange) {
      if(!this.content.textContent) {
        this.props.onChange(e, "");
        return;
      }
      
      const html = this.content.innerHTML
        .replace(` contenteditable="true"`, '');
      
      this.props.onChange(e, html);
    }
  }
  
  handleKeyUp = e => {
    this.forceUpdate();
  }
  
  
  render() {
    return (
      <div 
        className={`wysiwyg ${this.props.className}`} 
        style={this.props.style}>
        
        <div className={`wysiwyg-tools ${this.props.toolsClassName}`}>
          <Button 
            className="wysiwyg-tool" 
            icon="bold" 
            active={this.isBold()}
            onClick={this.bold}
            color="white"
            width={24}
            height={24}
            buttonStyle={{padding:0}}
          />
          <Button 
            className="wysiwyg-tool" 
            icon="italic" 
            active={this.isItalic()}
            onClick={this.italic}
            color="white"
            width={24}
            height={24}
            buttonStyle={{padding:0}}
          />
          <Button 
            className="wysiwyg-tool" 
            icon="underline" 
            active={this.isUnderlined()}
            onClick={this.underline}
            color="white"
            width={24}
            height={24}
            buttonStyle={{padding:0}}
          />
          <Button 
            className="wysiwyg-tool" 
            icon="fontSize"
            onClick={this.showFontSize}
            color="white"
            width={24}
            height={24}
            buttonStyle={{padding:0}}
          />
          
          <Popover
            open={this.state.fontSizePopoverOpen}
            anchorEl={this.state.anchorEl}
            useLayerForClickAway={false}
            anchorOrigin={{horizontal: 'middle', vertical: 'center'}}
            targetOrigin={{horizontal: 'right', vertical: 'top'}}
            onRequestClose={this.hideFontSize}>
            <Menu onChange={(e, fontSize) => this.fontSize(fontSize)}>
              <MenuItem primaryText="Title" value={5}/>
              <MenuItem primaryText="Normal Text" value={3}/>
              <MenuItem primaryText="Small Text" value={1}/>
            </Menu>
          </Popover>
        </div>
        
        <div 
          className={`wysiwyg-content ${this.props.contentClassName} ${this.props.outline ? 'is-outlined' : ''}`} 
          ref={el => this.content = el} 
          onFocus={this.handleFocus}
          onInput={this.handleInput}
          onKeyUp={this.handleKeyUp}>
          
          <div contentEditable="true" data-name="message"></div>
          
        </div>
        
      </div>
    );
    
  }
}