import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadFAQ, updateFAQ, updateFAQMedia, removeFAQ } from 'actions';

import Field from 'ui/Field';
import Button from 'ui/Button';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import FlatButton from 'material-ui/FlatButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Media from 'ui/Media';

export function mapStateToProps(state, ownProps) {
  const id = ownProps.id;
  return {
    id,
    faq: {id, ...state.faqs.all[id]},
    ...ownProps,
  };
}

export const mapDispatchToProps = {
  loadFAQ,
  updateFAQ,
  updateFAQMedia,
  removeFAQ,
};





export class FAQ extends Component {

  UNSAFE_componentWillMount() {
    this.props.loadFAQ(this.props.id);
  }

  renderMedia() {
    const faq = this.props.faq;
    if(!faq.media) {
      return null;
    }
    return (
      <div>
        <Media width={300} media={faq.media}/>
      </div>
    );
  }

  render() {
    const faq = this.props.faq;
    const updateFAQ = this.props.updateFAQ;
    const updateFAQMedia = this.props.updateFAQMedia;
    const removeFAQ = this.props.removeFAQ;

    return (
      <div className="faqadmin-faq">
        <Field label="Question">
          <TextField
            name="question"
            value={faq.question || ""}
            onChange={e => updateFAQ({...faq, question:e.target.value})}
            fullWidth
          />
        </Field>

        <Field label="Answer">
          <TextField
            name="answer"
            value={faq.answer || ""}
            onChange={e => updateFAQ({...faq, answer:e.target.value})}
            multiLine
            fullWidth
          />
        </Field>

        <Field label="Video or Image">
          {this.renderMedia()}
          <Button
            label="Upload"
            raised={true}
            onClick={() => this.imageInput.click()}
            style={{marginTop:8}}>
          </Button>
          <input
            name="imageinput"
            ref={el => this.imageInput = el}
            type="file"
            onChange={e => updateFAQMedia(faq.id, e.target.files[0])}
            style={{
              position: 'absolute',
              left: -1000,
            }}
          />
        </Field>

        <Field label="Feature on">
          <SelectField
            hintText="Section"
            value={faq.featureOn}
            onChange={(e, i, featureOn) => updateFAQ({...faq, featureOn})}>
            <MenuItem value="" primaryText="None"/>
            <MenuItem value="talentinfo" primaryText="Talent Info Forms"/>
            <MenuItem value="talentmedia" primaryText="Talent Media Forms"/>
            <MenuItem value="calendar" primaryText="Calendars"/>
          </SelectField>
        </Field>

        <div className="faqadmin-faq-role">
          <SelectField
            hintText="Role"
            value={faq.role}
            onChange={(e, i, role) => updateFAQ({...faq, role})}
            fullWidth>
            <MenuItem value="" primaryText="All"/>
            <MenuItem value="talent" primaryText="Talent"/>
            <MenuItem value="agent" primaryText="Agent"/>
          </SelectField>
        </div>

        <div className="faqadmin-faq-active">
          <Toggle
            label="Active"
            toggled={this.props.faq.isActive}
            onToggle={(e, isActive) => updateFAQ({...faq, isActive})}
            labelPosition="right"
            thumbStyle={{backgroundColor:"#aaaaaa"}}
          />
        </div>

        <div className="faqadmin-faq-remove">
          <FlatButton onClick={() => removeFAQ(faq)}>
            Remove
          </FlatButton>
        </div>

      </div>
    );
  }

}




export default connect(mapStateToProps, mapDispatchToProps)(FAQ);