/**

# Talent Card


## Props:

### Data:
  - talentId

### Display options:

  - showAgency
  - isSelected
  - isAltSelected

### Event Listeners:

  - onClick               Called with the event and path.
  - onNewMessageClick
  - onSelect

## Example Usage:

import TalentCard from 'ui/TalentCard';

<TalentCard
  talentId={...}
/>


**/

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { isEmpty, isArray, isObject, min, max, get } from 'lodash';
import { mediaFormats } from 'config';

import Media from 'ui/Media';
import Button from 'ui/Button';

import './index.css';



function formatPhoneNumber(s) {
  var s2 = (""+s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? s : "(" + m[1] + ") " + m[2] + "-" + m[3];
}

export const mapStateToProps = (state, ownProps) => {
  const talent = state.talents.all[ownProps.talentId];
  const agencyId = talent && talent.agencyId ? talent.agencyId : state.user.agencyId;
  const agency = state.agencies.all[agencyId] || {};

  return {
    talent,
    agencyId,
    agency,
    ...ownProps,
  };
};




export const TalentCard = ({
  muiTheme:{ palette },
  talentId,
  talent,
  agencyId,
  agency,
  showAgency,
  onClick=()=>null,
  onNewMessageClick,
  onSelect,
  isSelected=false,
  isAltSelected=false,
}) => {

  const highlighted = field => {
    let value = talent[field];
    if(!value) {
      return "";
    }
    try {
      talent._highlightResult[field].matchedWords.forEach(word => {
        const pattern = new RegExp(`(${word})`, 'gi');
        value = value.replace(pattern, `<em>$1</em>`);
      });
    }
    catch(err) {}

    return value;
  };


  const status = talent.status;
  const name = `${highlighted('firstName')} ${highlighted('lastName')}`.trim();
  const agencyName = showAgency ? agency.name : null;
  const contactName = highlighted('contactName');
  const notes = highlighted('notes');
  const tags = Object.keys(talent.tags || {}).map(key => talent.tags[key]).join(', ');
  const plan = !!agency.showTalentPlanOnCard && get(talent, 'plan.name', null);

  let divisions = null;
  if(agency.showTalentDivisionOnCard) {
    let data = get(talent, 'divisions', []);
    if(Array.isArray(data)) {
      divisions = data.join(', ');
    }
  }
  const isTrial = !!agency.showTalentTrialOnCard && status === "TRIAL";

  // Image

  let image = "avatar";
  if(talent.media) {
    const img = Object.keys(talent.media)
      .map(id => talent.media[id])
      .sort((a, b) => a.order < b.order ? -1 : 1)
      .find(media => !media.format || mediaFormats.indexOf(media.format) !== -1);

    if(img && img.cloudinary_id) {
      image = img;
    }
  }

  if(talent.image) {
    image = talent.image;
  }


  // Info and meta fields.

  const infoFields = [];
  const metaFields = [];

  let dateUpdated = 0;
  for(let fieldId in (talent.dateFieldUpdated || {})) {
    const date = get(talent, `dateFieldUpdated.${fieldId}.date`,  0);
    if(date > dateUpdated) {
      dateUpdated = date;
    }
  }
  if(!dateUpdated) {
    dateUpdated = talent.dateUpdated;
  }
  dateUpdated = moment(dateUpdated).format("MM/DD/YYYY");

  if(talent.dob) {
	  const years = moment().diff(talent.dob, 'years');
	  let age = years + ' yrs';
		if(years < 3) {
			age += ' ' + moment().diff(talent.dob, 'months') % 12 + ' mo';
		}
		infoFields.push({
		  id: 'dob',
		  name: 'Age',
		  val: age,
		});
	}

	Object.keys(agency.talentFields || {})
	  .map(id => ({...agency.talentFields[id], id}))
	  .sort((a, b) => !a.order || a.order < b.order ? -1 : 1)
	  .forEach(field => {
      const fieldId = field.id;
  	  if(!field.isVisibleOnCard) {
  	    return;
  	  }
  	  if(!talent[fieldId] || (isObject(talent[fieldId]) && isEmpty(talent[fieldId]))) {
  	    return;
  	  }
  	  if(field.category === 'system') {
  	    return;
  	  }
    	if(fieldId === 'dob') {
    	  const years = moment().diff(talent.dob, 'years');
    	  let age = years + ' yrs';
    		if(years < 3) {
    			age += ' ' + moment().diff(talent.dob, 'months') % 12 + ' mo';
    		}
    		infoFields.push({
    		  name:'age',
    		  val: age
    		});
    	}

    	let val = talent[fieldId];
  	  let name = field.name.slice(0, 14) || "";
  	  let units = field.units || "";

      let expired = false;
    	if(field.trackChanges && field.invalidAfter) {
      	const timeSinceUpdate = Date.now() - get(talent, `dateFieldUpdated[${fieldId}].date`, 0);
        const validFor = moment.duration(field.invalidAfter, 'days').valueOf();
        expired = timeSinceUpdate > validFor;
    	}
    	if(field.type === 'date' && field.isExpiration) {
    	  expired = moment().isAfter(val);
    	}

  	  if(field.isFeet) {
  	    val = `${Math.floor(val / 12)}'${val % 12}"`.trim();
  	    units = "";
  	  }
  	  if(field.type === 'date') {
  	    val = moment(val).format('MM/DD/YYYY');
  	  }
  	  if(field.type === 'range') {
  	    if(isArray(val)) {
  	      if(min(val) === max(val)) {
  	        val = min(val);
  	      }
  	      else {
  	        val = `${min(val)}-${max(val)}`;
  	      }
  	    }
  	    else if(isObject(val)) {
  	      val = `${val.from}-${val.to}`;
  	    }
  	  }
  	  if(isArray(val)) {
  	    val = val.join(', ');
  	  }

    	if(field.placement === 'meta') {
    	  metaFields.push({
    	    id: fieldId,
    		  name: name,
    		  val: `${val} ${units}`.trim(),
    		  expired: expired,
    		});
    	}
    	else {
    	  if(val.length > 20) {
    	    val = val.slice(0, 20).trim() + "...";
    	  }
    	  infoFields.push({
    	    id: fieldId,
    		  name: name,
    		  val: `${val} ${units}`.trim(),
    		  expired: expired,
    		});
    	}
  	});


  // Contact fields.

  const contactFields = Object.keys(talent.phones || {})
    .map(id => ({...talent.phones[id], id}))
    .filter(phone => /*phone.doContact !== false && */!!phone.label && !!phone.number)
    .slice(0, 3);


  // Upcoming Events

  const events = Object.keys(talent.upcomingEvents || [])
    .map(id => ({...talent.upcomingEvents[id], id}))
    .filter(event => moment(event.endDate).isAfter(moment().startOf('day')))
    .sort((a, b) => a.date < b.date ? -1 : 1)
    .slice(0, 5)
    .map(event => {
      let date = "";
      if(moment(event.endDate).isSame(event.date, 'day')) {
        date = moment(event.date).format("MM/DD");
      } else {
        date = `${moment(event.date).format("MM/DD")} - ${moment(event.endDate).format("MM/DD")}`;
      }

      let description = "";
      if(event.packName) {
        description += ` ${event.packName || ""}`;
      }
      else {
        description += ` ${event.notes || ""}`;
      }
      description = description.trim();

      return {id:event.id, date, description};
    });


  // Talent Settings

  const isInactive = status === 'EXPIRED' || status === 'INACTIVE' || status === 'DELETED' || status === 'CANCELED';

  return (
    <div
      className={`talentcard2 ${isInactive ? 'is-inactive' : 'is-active'}`}
      style={{
        borderBottom: `1px solid ${palette.primary50}`,
        boxShadow: isInactive ? "none" : null,
      }}>

      <div className="talentcard2-top">

        {image &&
          <Media
            media={image}
            width={130} height={130}
            crop="fill" face icon="user"
            backgroundColor="#f5f5f5"
            effect={isInactive ? "grayscale" : ''}
            style={{opacity: isInactive ? 0.2 : 1}}
            onClick={e => onClick(e, 'media')}
            className="talentcard2-image"
          />
        }

        <div className="talentcard2-info">

          <div className="talentcard2-row">
            <div
              className="talentcard2-name" onClick={onClick}>
              <div className="talentcard2-name-text" dangerouslySetInnerHTML={{__html:name}}></div>
              {agencyName && <div className="talentcard2-name-subtext">{agencyName}</div>}
              {plan && <div className="talentcard2-name-subtext">{plan}</div>}
              {divisions && <div className="talentcard2-name-subtext">{divisions}</div>}
              {isTrial && <div className="talentcard2-name-boldsubtext">TRIAL</div>}
              <div className="talentcard2-minrule"></div>
            </div>
            <div className="talentcard2-actions">
              {!!onNewMessageClick &&
                <Button
                  width={34}
                  height={34}
                  iconSize={18}
                  icon="message"
                  isReversed={true}
                  onClick={onNewMessageClick}
                />
              }
            </div>
          </div>

          <div className="talentcard2-info-fields" onClick={onClick}>
            {infoFields.map(field => (
              <div key={field.id} className="talentcard2-info-field">
                <div className="talentcard2-info-field-name">{field.name}: </div>
                <div className="talentcard2-info-field-val" style={{color: !!field.expired ? 'red' : null}}>{field.val} </div>
              </div>
            ))}
          </div>

          <div className="talentcard2-fullrule"></div>

          <div className="talentcard2-contact" onClick={e => onClick(e, 'messages')}>
            {!!contactName &&
              <div className="talentcard2-info-fields">
                <div className="talentcard2-info-field">
                  <div className="talentcard2-info-field-name">Contact: </div>
                  <div className="talentcard2-info-field-val" dangerouslySetInnerHTML={{__html:contactName}}></div>
                </div>
              </div>
            }
            <div className="talentcard2-contact-fields">
              {contactFields.map(field => (
                <div key={field.id} className="talentcard2-contact-field">
                  <div className="talentcard2-contact-field-name">{field.label}: </div>
                  <a href={`tel:${field.number}`} className="talentcard2-contact-field-val" onClick={e => e.stopPropagation()}>{formatPhoneNumber(field.number)} </a>
                </div>
              ))}
            </div>

          </div>
        </div>

      </div>

      {isInactive &&
        <div className="talentcard2-status">
          {talent.status}
        </div>
      }

      <div className="talentcard2-middle">
        {notes &&
          <div
            onClick={e => onClick(e, 'notes')}
            className="talentcard2-notes"
            style={{color:palette.primary1Color}}
            dangerouslySetInnerHTML={{__html:notes}}
          />
        }

        <div className="talentcard2-events" onClick={e => onClick(e, 'calendar/schedule')}>
          {events.length > 0 && events.map(event =>
            <div key={event.id} className="talentcard2-event" style={{color:palette.accent400}}>
              <span className="talentcard2-event-date">{event.date} </span>
              <span className="talentcard2-event-desc">{event.description}</span>
            </div>
          )}
        </div>
      </div>

      <div
        className="talentcard2-bottom" onClick={e => onClick(e, 'info')}>

        {!!tags &&
          <div className="talentcard2-tags" onClick={onClick}>
            {tags}
          </div>
        }

        <div className="talentcard2-meta">
          {metaFields.map(field => (
            <div key={field.id} className="talentcard2-meta-field">
              <div className="talentcard2-meta-field-name">{field.name}: </div>
              <div className="talentcard2-meta-field-val" style={{color: !!field.expired ? 'red' : null}}>{field.val} </div>
            </div>
          ))}
        </div>

        <div className="talentcard2-row">
          <div className="talentcard2-meta" style={{flex:1}}>
            <div className="talentcard2-meta-field-val">{dateUpdated}</div>
          </div>
          <div className="talentcard2-meta">
            <div className="talentcard2-meta-field-val">{talent.code || ""}</div>
          </div>
        </div>

      </div>

      {!!onSelect && (talent.status === 'PAID' || talent.status === 'TRIAL') &&
        <Button
          className="talentcard2-check"
          icon="selected"
          iconSize={16}
          onClick={e => onSelect(e, talentId || talent.id)}
          backgroundColor={
            isSelected ? palette.accent1Color :
            isAltSelected ? palette.grey300 :
            palette.canvasColor
          }
          color={palette.canvasColor}
        />
      }

    </div>
  );
};



export default connect(mapStateToProps)(
  muiThemeable()(TalentCard)
);