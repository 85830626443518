import React, { useEffect } from 'react';
import { Text, Icon, useRouter } from 'skybolt-ui'
import { logout } from 'skybolt-api';

export default function(props) {
  
  const {history} = useRouter();
  
  useEffect(() => {
    logout();
    history.replace('/login');
  }, [history]);
  
  return (
    <div style={{padding:"160px 16px 0", margin:"0 auto"}}>
      <Icon name="loading" color="textLight" large/> 
      <Text block light>Logging out...</Text>
    </div>
  );
}