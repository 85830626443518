import React, { useEffect, useState } from 'react';
import { Text, Icon, Button, useRouter } from 'skybolt-ui'
import { createSpotlight } from 'skybolt-api';

export default function(props) {

  const {history} = useRouter();

  const [error, setError] = useState(null);

  useEffect(() => {
    let isCanceled = false;
    let timer;
    createSpotlight()
      .then(({spotlightId}) => {
        if(isCanceled) {
          return;
        }
        timer = setTimeout(() => history.replace(spotlightId), 2000);
      })
      .catch(error => {
        setError("Could not create spotlight. Please try again.");
      });
    return () => {
      isCanceled = true;
      if(timer) clearTimeout(timer);
    };
  }, []);

  if(error) {
    return (
      <div style={{padding:"160px 16px 0", textAlign:'center'}}>
        <Icon name="loading" color="error" large/>
        <Text error paragraph>{error}</Text>
        <Button raised back>Back to Spotlights</Button>
      </div>
    );
  }

  return (
    <div style={{padding:"160px 16px 0", textAlign:'center'}}>
      <Icon name="loading" color="textLight" large/>
      <Text block light>Creating Spotlight...</Text>
    </div>
  );
}