import React from 'react';
import injectSheet from 'react-jss';
import TextField from 'ui/TextField';
import Button from 'ui/Button';


const styles = {
  phone: {
    display: 'flex',
  },
  phoneField: {
    flex: 2,
    marginRight: 8,
  },
  labelField: {
    flex: 1,
    marginRight: 8,
  },
  icon: {
    flex: 0,
  }
};

export class PhonesField extends React.Component {
  
  static defaultProps = {
    initialValue: {},
    onChange: ()=>null,
  }
  
  state = {
    phones: {},
  }
  
  componentDidMount() {
    this.setState({phones:this.props.initialValue});
  }
  
  updateNumber = (id, number) => {
    const phones = {...this.state.phones};
    if(!phones[id]) {
      phones[id] = {number:"", label:""};
    }
    phones[id].number = number;
    this.setState({phones});
    this.props.onChange(phones);
  }
  
  updateLabel = (id, label) => {
    const phones = {...this.state.phones};
    if(!phones[id]) {
      phones[id] = {number:"", label:""};
    }
    phones[id].label = label;
    this.setState({phones});
    this.props.onChange(phones);
  }
  
  remove = (id) => {
    let phones = {...this.state.phones};
    delete phones[id];
    this.setState({phones});
  }
  
  render() {
    let fields = Object.keys(this.state.phones).map(id => 
      this.renderPhone(this.state.phones[id], id, true)
    );
    fields.push(this.renderPhone());
    return fields;
  }
  
  renderPhone(phone={}, id, canRemove=false) {
    const { classes } = this.props;
    
    if(!id) {
      id = Date.now();
    }
    
    return (
      <div key={id} className={classes.phone}>
        <TextField
          key={`${id}-phone`}
          inputKey={`${id}-phone-input`}
          placeholder={"Number"}
          initialValue={phone.number || ""}
          onChangeText={number => this.updateNumber(id, number)}
          underline={true}
          className={classes.phoneField}
        />
        <TextField
          key={`${id}-label`}
          placeholder="Label"
          initialValue={phone.label || ""}
          onChangeText={label => this.updateLabel(id, label)}
          underline={true}
          className={classes.labelField}
          style={{marginRight: canRemove ? 0 : 48}}
        />
        {canRemove && (
          <Button icon="remove" onClick={() => this.remove(id)}/>
        )}
      </div>
    );
  }
}


export default injectSheet(styles)(PhonesField);
