import React from 'react';
import moment from 'moment';

import TimePicker from 'material-ui/TimePicker';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Button from 'ui/Button';

import './index.css';


export default class TimeslotInput extends React.Component {
  static defaultProps = {
    timeslot: {}
  }
  
  setDate = (date) => {
    date = moment(date);
    
    const year = date.year();
    const month = date.month();
    const day = date.date();
    
    let { from, to, type } = this.props.value;
    
    from = moment(from).year(year).month(month).date(day).valueOf();
    to = moment(from).year(year).month(month).date(day).valueOf();
    
    this.props.onChange(null, {
      from: from || null, 
      to: to || null, 
      type: type || "CASTING"
    });
  }
  
  setFrom = (date) => {
    date = moment(date);
    
    const hour = date.hour();
    const minute = date.minute();

    let { from, to, type } = this.props.value;
    
    from = moment(from).hour(hour).minute(minute).valueOf();
    
    this.props.onChange(null, {
      from: from || null, 
      to: to || null, 
      type: type || "CASTING"
    });
  }
  
  setTo = (date) => {
    date = moment(date);
    
    const hour = date.hour();
    const minute = date.minute();
    
    let { from, to, type } = this.props.value;
    
    to = moment(from).hour(hour).minute(minute).valueOf();
    
    this.props.onChange(null, {
      from: from || null, 
      to: to || null, 
      type: type || "CASTING"
    });
  }

  setType = type => {
    let { from, to } = this.props.value;
    this.props.onChange(null, {
      from: from || null, 
      to: to || null, 
      type: type || "CASTING"
    });
  }
  
  remove = () => {
    this.props.onRemove();  
  }
  
  render() {
    let { from, to, type } = this.props.value;

    if(!type) {
      type = "CASTING";
    }
    
    return (
      <div className="timeslotfield">
        <DatePicker 
          hintText="Day" 
          mode="landscape" 
          fullWidth
          style={{marginRight:'1%'}}
          value={from ? moment(from).toDate() : undefined}
          onChange={(e, val) => this.setDate(val)}
        />
        
        <TimePicker
          format="ampm"
          hintText="Start"
          fullWidth
          style={{marginRight:'1%'}}
          value={from ? moment(from).toDate() : undefined}
          onChange={(e, val) => this.setFrom(val)}
        />
        
        <TimePicker
          format="ampm"
          hintText="End"
          fullWidth
          style={{marginRight:'1%'}}
          value={to ? moment(to).toDate() : undefined}
          onChange={(e, val) => this.setTo(val)}
        />

        <SelectField
          value={type}
          placeholder="Event Type"
          onChange={(e, i, val) => this.setType(val)}>
          
          {Object.values(this.props.types).map(option => (
            <MenuItem 
              value={option.label} 
              checked={type === option.label}
              primaryText={option.label}
            />
          ))}

        </SelectField>
        
        <Button 
          onClick={this.remove}
          icon="remove"
        />
      </div>
    );
  }
}
