import React, {useState} from 'react';
import {
  Container,
  Button,
  Text,
  Rule,
  Card,
  Loading,
  InputText,
  LazyLoad,
} from "skybolt-ui";
import { useQuery } from 'skybolt-api';
import moment from 'moment';

export default function AdminTalents(props) {

  const [limit, setLimit] = useState(60);
  const [search, setSearch] = useState("");
  const data = useQuery(`{
    talents: adminTalents(limit:$limit, search:$search) {
      talentId
      image(width:40, height:40)
      fullName
      agencyId
      status
      epayId
      dateCreated
      dateUpdated
    }
  }`,
  {limit, search});

  if(!data) {
    return <Loading/>;
  }

  return (
    <Container>
      <div style={{display:'flex', alignItems:'flex-end', height:46, paddingTop:24}}>
        <Text title>TALENTS</Text>
        <div style={{flex:1}}/>
        <InputText
          name="query"
          placeholder="Search..."
          clearable
          style={{margin:0, height:38, backgroundColor:'transparent'}}
          onChange={setSearch}
        />
      </div>
      <Rule style={{marginBottom:16}}/>

      <div style={{display:'flex', padding:"0px 0px 4px 0px"}}>
        <div style={{width:56}}/>
        <div style={{flex:1}}/>
        <div style={{flex:1}}><Text small bold>Status</Text></div>
        <div style={{flex:1}}><Text small bold>Agency ID</Text></div>
        <div style={{flex:1}}><Text small bold>USAePay ID</Text></div>
        <div style={{flex:1}}><Text small bold>Date Created</Text></div>
        <div style={{flex:1}}><Text small bold>Date Modified</Text></div>
      </div>

      {data.talents
        .map(talent =>
          <Card key={talent.talentId} style={{padding:0}}>
            <Button
              href={{
                pathname:`/talents/${talent.talentId}/settings`,
                state: {modal:true}
              }}
              style={{display:'flex', alignItems:'center', marginBottom:2, padding:0}}>

              <img src={talent.image} style={{width:40, height:40, marginRight:16}} alt="headshot"/>
              <div style={{flex:1}}>
                <Text>{talent.fullName}</Text>
              </div>
              <div style={{flex:1}}><Text bold>{talent.status}</Text></div>
              <div style={{flex:1}}><Text>{talent.agencyId}</Text></div>
              <div style={{flex:1}}><Text>{talent.epayId}</Text></div>
              <div style={{flex:1}}><Text>{moment(talent.dateCreated).format('MM/DD/YYYY h:mma')}</Text></div>
              <div style={{flex:1}}><Text>{moment(talent.dateUpdated).format('MM/DD/YYYY h:mma')}</Text></div>
            </Button>
          </Card>
        )
      }

      <LazyLoad onLoad={setLimit} count={data.talents.length}/>
    </Container>
  );
}
