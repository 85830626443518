/*
# List

Simply wraps all children in some list styling.


Props:
- itemStyle     Object    Style object for Page list item wrapper.

All other props are passed through to the list container.


Usage:

<List>
  ...
</List>

*/

import React from 'react';
import Page from 'ui/Page';

export default ({
  children=[],
  itemStyle={},
  ...props
}) => {
  return (
    <div {...props}>
      {React.Children.map(children, (child, i) => {
        if(!React.isValidElement(child)) {
          return null;
        }
        
        return (
          <Page style={{marginBottom:4, padding:'8px 16px 4px 16px', ...itemStyle}}>
            {React.cloneElement(child)}
          </Page>
        );
      })}
    </div>
  );
};