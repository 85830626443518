import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { matchPath } from 'react-router-dom';


class Tabs extends Component {

  static defaultProps = {
    className: '',
    direction: 'horizontal',
    drawRule: true,
    collapse: true,
  }

  state = {
    ruleStyle: {},
    innerWidth: 0,
    outerWidth: 0,
    hideLabels: false,
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.checkSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize);
  }

  componentDidMount() {
    this.checkSize();
  }

  componentWillReceiveProps() {
    this.drawRule();
  }



  checkSize = () => {
    if(!this.props.collapse) {
      return;
    }
    let hideLabels = false;
    if(this.props.direction === 'horizontal' && this.container && this.sizer) {
      if(this.sizer.scrollWidth > this.container.clientWidth) {
        hideLabels = true;
      }
    }

    this.setState({hideLabels}, this.drawRule);
  }

  drawRule = () => {
    if(!this.props.drawRule) {
      return;
    }

    let el = null;
    let i = 0;

    React.Children.forEach(this.props.children, child => {
      if(!React.isValidElement(child)) {
        return null;
      }

      if(child.props.hidden) {
        return null;
      }

      const currentPath = window.location.pathname;
      const path = child.props.path;
      const exact = child.props.exact;

      if(path && !!matchPath(currentPath, {path, exact})) {
        el = findDOMNode(this).children[i];
      }

      i += 1;
    });


    if(!el) {
      this.setState({ruleStyle:{...this.state.ruleStyle, opacity:0}});
      return;
    }

    if(this.props.direction === 'vertical') {
      this.setState({
        ruleStyle: {
          top: el.offsetTop,
          height: el.scrollHeight,
          opacity: 1,
        }
      });
    }
    else {
      this.setState({
        ruleStyle: {
          left: el.offsetLeft,
          width: el.scrollWidth,
          opacity: 1,
        }
      });
    }
  }

  render() {
    if(!this.props.children) {
      return null;
    }



    return (
      <div
        className={`tabs is-${this.props.direction} ${this.props.className}`}
        style={this.props.style}
        ref={el => this.container = el}>

        {React.Children.map(this.props.children, (tab, key) => {
          if(!React.isValidElement(tab)) {
            return null;
          }
          return React.cloneElement(tab, {hideLabel:this.state.hideLabels, key:`tab-${key}`});
        })}

        {this.props.drawRule === true &&
          <div
            className="tabs-rule"
            style={{
              backgroundColor: this.props.muiTheme.palette.accent1Color,
              ...this.state.ruleStyle,
            }}
          />
        }

        {this.props.direction === 'horizontal' &&
          <div className="tabs-sizer" ref={el => this.sizer = el}>
            {React.Children.map(this.props.children, (tab, key) => {
              if(!React.isValidElement(tab)) {
                return null;
              }
              return React.cloneElement(tab, {
                key: `size-${key}`,
                hideLabel:false,
                containerRef:()=>null,
              });
            })}
          </div>
        }

      </div>
    );
  }
}

export default muiThemeable()(Tabs);