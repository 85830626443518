import React from 'react';
import { useQuery } from 'skybolt-api';
import {
  Loading,
  Form,
  useRouter,
} from 'skybolt-ui';

export default props => {

  const route = useRouter();
  const data = useQuery(`{
    agency(agencyId:$agencyId) {
      talentFields
    }
  }`, {agencyId:route.match.params.agencyId});

  if(!data) {
    return <Loading/>;
  }

  function handleChange(val) {
    console.log('change', val);
  }

  return (
    <Form initialValue={data.agency.talentfields} onChange={handleChange}>

    </Form>
  );
};