import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  InputText,
  FormContext,
  Button,
  ThemeContext,
  Menu,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';


// With a talent id supplied by the containing form, show the talent fullName
// and update the form when the input is changed.
export default function InputTalent(props) {
  const form = useContext(FormContext);
  const talentId = form.get('talentId');
  const talentName = form.get('talentName');
  const talentImage = form.get('talentImage');

  const [query, setQuery] = useState("");

  const data = useQuery(`{
    search: talents(search:$search, limit:5) {
      talentId
      fullName
      legalName
      image(width:150, height:150, face:true)
    }
  }`, {search:{query, status:["PAID", "TRIAL", "EXPIRED", "CANCELED"]}});


  const emitChange = talent => {
    form.update({
      talentName: talent.legalName || talent.fullName,
      talentId: talent.talentId,
      talentImage: talent.image
    });
    if(props.onChange) {
      props.onChange(talent);
    }
  };

  useEffect(() => {
    if(talentName) {
      setQuery(talentName);
    }
  }, [talentName]);

  const theme = useContext(ThemeContext);

  const containerRef = useRef();
  const inputRef = useRef();
  const [focused, setFocused] = useState(false);


  return (
    <div style={{display:'flex', alignItems:'flex-start', justifyContent:'stretch', marginBottom:16, ...props.style}} ref={containerRef} className={props.className}>
      {talentImage && (
        <img src={talentImage} alt="Headshot" style={{width:46, height:46, marginRight:4}}/>
      )}
      <InputText
        value={query}
        onChange={setQuery}
        style={{flex:1, marginBottom:0}}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        ref={inputRef}
        title={props.title}
        hint={props.hint}
      />
      <Menu key="menu" open={query && data && data.search.length && focused} anchor={containerRef} onRequestClose={() => inputRef.current.blur()}>
        {data && data.search && data.search.map((talent, i) =>
          <Button
            key={talent.talentId || i}
            style={{
              display: 'block',
              minWidth: 80,
              backgroundColor: talent.talentId === talentId ? theme.color.accent : 'transparent',
              borderRadius: 0,
            }}
            onMouseDown={e => e.preventDefault()}
            onClick={e => emitChange(talent)}>
            {talent.fullName} {talent.legalName ? `(${talent.legalName})` : ""}
          </Button>
        )}
      </Menu>
    </div>
  );

}