/*

# Date Field


Example Usage:
    
    import DateField from 'ui/DateField';

    <DateField 
      name="dob"
      style={{maxWidth:600, paddingTop:16}}
      underline={true}
      initialValue={talent.dob}
      onChange={dob => update({dob})}
    />


*/


import React from 'react';
import moment from 'moment';

import TextField from 'ui/TextField';

export default ({
  onChange,
  initialValue,
  ...props
}) => (
  <TextField
    placeholder="MM/DD/YYYY"
    
    clean={val => val.replace(/[^0-9.]*/g,"").slice(0, 8)}
    
    format={val => {
      const m = val.replace(/[^0-9.]*/g,"")
         .slice(0, 8)
         .match(/^(\d{1,2})(\d{0,2})?(\d{0,4})?$/);
         
      if(!m) {
        return val;
      }
      
      var str = "";
      if(m[1]) str += `${m[1]}`;
      if(m[2]) str += `/${m[2]}`;
      if(m[3]) str += `/${m[3]}`;
      return str;
    }}
    
    error={val => {
      if(!val) {
        return;
      }
      
      const month = val.slice(0,2);
      const day = val.slice(2, 4);
      const year = val.slice(4);
      
      if(month < 1 || month > 12) {
        return "Please enter a valid date.";
      }
      
      if(day < 1 || day > 31) {
        return "Please enter a valid date.";
      }
      
      if(year < 1900) {
        return "Please enter a valid date.";
      }
      
      if(!month || !day || !year) {
        return "Please enter a full date.";
      }
      
      const isValid = moment()
        .month(month-1)
        .date(day)
        .year(year)
        .isValid();
        
      if(!isValid) {
        return "Please enter a valid date.";
      }
    }}
    
    onChangeText={val => {
      if(!val) {
        onChange("");
        return "";
      }
      
      val = val.replace(/[^0-9.]*/g,"").slice(0, 8);
      const timestamp = moment(val, 'MMDDYYYY').valueOf();
        
      onChange(timestamp);
    }}
    
    initialValue={initialValue ? moment(initialValue).format('MM/DD/YYYY') : ""}
    
    {...props}
  />
);