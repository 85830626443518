import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadPackTalents, removePackTalent } from 'actions';
import { get } from 'lodash';

import { Switch, Route, Redirect } from 'react-router-dom';
import Container from 'ui/Container';
import Button from 'ui/Button';
import Title, { Action, Tab } from 'ui/Title';
import Messages from './Messages';
import Discussion from './Discussion';
import MessageTalentsDialog from '../MessageTalentsDialog';

import TalentCard from 'ui/OldTalentCard';

import "./index.css";



export const mapStateToProps = (state, ownProps) => {
  const { url, path } = ownProps.match;
  const packId = ownProps.match.params.packId;

  let talents = state.packTalents.byPack[packId] || {};
  talents = Object.keys(talents)
    .map(id => ({id, ...talents[id]}))
    .sort((a, b) => a.lastName > b.lastName ? 1 : -1);

  const showMessageTalents = get(state, 'user.permissions.canMessageTalents', false);

  return {
    url,
    path,
    packId,
    talents,

    showMessageTalents,
  };
};

export const mapDispatchToProps = {
  loadPackTalents,
  removePackTalent,
};




export class PackMessages extends Component {

  state = {
    isMessageTalentsDialogOpen: false,
  }

  UNSAFE_componentWillMount() {
    this.props.loadPackTalents(this.props.packId);
  }


  openMessageTalentsDialog = () => this.setState({isMessageTalentsDialogOpen:true})
  closeMessageTalentsDialog = () => this.setState({isMessageTalentsDialogOpen:false})



  renderPackTalents() {
    if(!this.props.showMessageTalents) {
      return null;
    }
    return (
      <div className="packmessages-talents">
        {this.props.talents
          .map(talent =>
            <TalentCard
              view="small"
              className="packmessages-talent"
              key={talent.id}
              talentId={talent.id}
              packId={this.props.packId}
              talent={talent}
              tab="messages"
              actions={
                <Button
                  icon="trash"
                  onClick={() => this.props.removePackTalent(this.props.packId, talent.id)}
                />
              }
            />
          )
        }
      </div>
    );
  }
  render() {
    const { url, path, talents, showMessageTalents } = this.props;


    const actions = [];
    if(showMessageTalents) {
      actions.push(
        <Action
          tooltip="Message Talent"
          icon="multimessage"
          onClick={talents.length > 0 ? this.openMessageTalentsDialog : null}
          disabled={talents.length === 0}
        />
      );
    }

    return (
      <Container>
        <Title
          actions={actions}
          tabs={[
            <Tab
              path={`${path}/messages`}
              exact={false}
              to={`${url}/messages`}
              label="Messages"
            />,
            <Tab
              path={`${path}/discussion`}
              exact={false}
              to={`${url}/discussion`}
              label="Public Discussion"
            />
          ]}>
          Messages
        </Title>

        <div className="packmessages-wrapper">

          {this.renderPackTalents()}

          <div className="packmessages-messages">
            <Switch>
              <Route path={`${path}/messages`} component={Messages}/>
              <Route path={`${path}/discussion`} component={Discussion}/>
              <Redirect to={`${url}/messages`}/>
            </Switch>
          </div>

        </div>

        <MessageTalentsDialog
          packId={this.props.packId}
          open={this.state.isMessageTalentsDialogOpen}
          onSend={this.closeMessageTalentsDialog}
          onRequestClose={this.closeMessageTalentsDialog}
        />

      </Container>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(PackMessages);