import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import {
  loadMoreTalentsSearchResults,
  searchTalents,
} from 'actions';

import "./index.css";

import { Redirect } from 'react-router-dom';
import Container from 'ui/Container';
import Title, { Action } from 'ui/Title';
import TalentCard from 'ui/OldTalentCard';
import MessageTalentDialog from 'ui/MessageTalentDialog';
import MessageSearchTalentsDialog from 'ui/MessageSearchTalentsDialog';
import { Card } from 'skybolt-ui';


const mapStateToProps = (state, ownProps) => {
  return {
    canAccess: state.user.permissions.canSeeSeekingAgencyTalent,
    canMessage: state.user.permissions.canAdminTalents,
    searchResults: state.talents.searchResults,
    searchCount: state.talents.searchCount,
    search: state.talents.search,
    history: ownProps.history,
  };
};

const mapDispatchToProps = {
  loadMoreTalentsSearchResults,
  searchTalents,
};




class TalentsSeekingAgency extends Component {

  state = {
    search: {},
    isMessageTalentsDialogOpen: false,
    isMessageSearchTalentsDialogOpen: false,
    talentId: null,
  }

  UNSAFE_componentWillMount() {
    if(this.props.canAccess) {
      this.search({query:"", filters:""});
    }
    window.addEventListener('scroll', this.handleDocumentScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleDocumentScroll);
  }

  handleDocumentScroll = e => {
    const body = document.body;
    const html = document.documentElement;
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;

    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    if(documentHeight - (scrollY + windowHeight) < 300) {
      this.props.loadMoreTalentsSearchResults();
    }
  }

  closeMessageDialog = () => this.setState({isMessageTalentsDialogOpen:false})
  closeMessageSearchDialog = () => this.setState({isMessageSearchTalentsDialogOpen:false})

  messageTalent = talentId => {
    this.setState({
      isMessageTalentsDialogOpen: true,
      talentId,
    });
  }
  searchTalents = debounce(() => {
    this.props.searchTalents({...this.state.search, filters:"isSeekingAgency:true"});
  }, 300)

  search = search => {
    this.setState({search}, this.searchTalents);
  }



  render() {
    if(!this.props.canAccess) {
      return <Redirect to="/"/>;
    }

    const searchResults = this.props.searchResults;
    const search = this.state.search;

    const actions = [];

    if(this.props.canMessage) {
      actions.push(
        <Action
          tooltip={"Message Talent"}
          icon="multimessage"
          onClick={() => this.setState({isMessageSearchTalentsDialogOpen:true})}
        />
      );
    }

    return (
      <div className="talentsseekingagency-wrapper">
        <Container>
          <Title
            search={search.query}
            actions={actions}
            onSearch={query => this.search({...search, query})}>
            Seeking Representation
          </Title>
        </Container>

        <Container className="talentsseekingagency-talents">
          {Object.keys(searchResults)
            .map(talentId => searchResults[talentId])
            .filter(t => !!t)
            .sort((a, b) => a.order < b.order ? -1 : 1)
            .map(talent => (
              <Card key={talent.id}>
                <TalentCard
                  view="medium"
                  talent={talent}
                  onClick={(e, tab="info") => {
                    this.props.history.push({
                      pathname:`/admin/talents/${talent.id}/${tab}`,
                      state:{
                        modal:true,
                      }
                    });
                  }}
                  onNewMessageClick={() => this.messageTalent(talent.id)}
                />
              </Card>
            ))
          }
        </Container>

        <MessageTalentDialog
          open={this.state.isMessageTalentsDialogOpen}
          onRequestClose={this.closeMessageDialog}
          talentId={this.state.talentId}
        />

        <MessageSearchTalentsDialog
          open={this.state.isMessageSearchTalentsDialogOpen}
          onRequestClose={this.closeMessageSearchDialog}
        />

      </div>
    );

  }
}




export default connect(mapStateToProps, mapDispatchToProps)(TalentsSeekingAgency);