import React from 'react';
import {
  Container,
  Text,
  Rule,
  Card,
  Loading,
  Redirect,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';

export default function ClientSettings(props) {

  const data = useQuery(`{
    user {
      permissions {
        canAdminBilling
      }
    }
  }`);

  if(!data) {
    return <Loading/>;
  }

  if(!data.user.permissions.canAdminBilling) {
    return <Redirect to="info"/>;
  }

  return (
    <Container>
      <div style={{display:'flex', alignItems:'flex-end', height:40}}>
        <Text title>Settings</Text>
      </div>
      <Rule style={{marginBottom:24}}/>

      <Card style={{padding:8}}>

      </Card>
    </Container>
  );
}