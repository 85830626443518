import React, { useEffect, useState } from 'react';
import { Icon, Text } from 'skybolt-ui';
import { createTalent } from 'skybolt-api';


export default function AddTalent(props) {

  const [error, setError] = useState(null);

  useEffect(() => {
    createTalent({approved:true, userId:null, requireSubscription:false})
      .then(talent => {
        props.history.replace(`/talents/${talent.talentId}`);
      })
      .catch(error => {
        setError("Something went wrong. Please try again.");
      });
  }, []);

  return (
    <div style={{textAlign:"center", paddingTop:80}}>
      <div>
        <Icon name="loading" size={120} color="greyLight"/>
      </div>
      <Text small error={!!error}>{error || "Creating Talent..."}</Text>
    </div>
  );
}