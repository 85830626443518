import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';

import './index.css';


export default props => (
  <div className="pack-create">
    <div className="pack-create-body">
      <CircularProgress size={20} className="pack-create-progress"/> 
      <span className="pack-create-message">
        Creating package...
      </span>
    </div>
  </div>
);