// Todo: Once material-ui is out of the picture, replace all this with the
// skybolt-ui Theme.

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadAgency, loadTalent, loadPack } from 'actions';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import * as colors from 'material-ui/styles/colors';

import {Theme as UITheme} from 'skybolt-ui';


class Theme extends Component {
  async componentDidMount() {
    if(this.props.agencyId) {
      this.props.loadAgency(this.props.agencyId);
    }
    
    if(this.props.talentId) {
      const talent = await this.props.loadTalent(this.props.talentId);
      this.props.loadAgency(talent.agencyId);
    }
    
    if(this.props.packId) {
      const pack = await this.props.loadPack(this.props.packId);
      this.props.loadAgency(pack.agencyId);
    }
  }
  
  render() {
    const agency = this.props.agency;
    const primaryColor = agency ? agency.primaryColor : 'blueGrey';
    const accentColor = agency ? agency.accentColor : 'blue';
    
    const muiTheme = getMuiTheme({
      palette: {
        primary: colors[primaryColor+800],
        primary50: colors[primaryColor+50],
        primary100: colors[primaryColor+100],
        primary200: colors[primaryColor+200],
        primary300: colors[primaryColor+300],
        primary400: colors[primaryColor+400],
        primary500: colors[primaryColor+500],
        primary600: colors[primaryColor+600],
        primary700: colors[primaryColor+700],
        primary800: colors[primaryColor+800],
        primary900: colors[primaryColor+900],
        
        primary1Color: colors[primaryColor+800],
        primary2Color: colors[primaryColor+400],
        primary3Color: colors[primaryColor+100],
        
        accent: colors[accentColor+700],
        accent50: colors[accentColor+50],
        accent100: colors[accentColor+100],
        accent200: colors[accentColor+200],
        accent300: colors[accentColor+300],
        accent400: colors[accentColor+400],
        accent500: colors[accentColor+500],
        accent600: colors[accentColor+600],
        accent700: colors[accentColor+700],
        accent800: colors[accentColor+800],
        accent900: colors[accentColor+900],
        
        accent1Color: colors[accentColor+700],
        accent2Color: colors[accentColor+400],
        accent3Color: colors[accentColor+50],
        
        error: colors.red800,
        error100: colors.red100,
        error200: colors.red200,
        error300: colors.red300,
        error400: colors.red400,
        error500: colors.red500,
        error600: colors.red600,
        error700: colors.red700,
        error800: colors.red800,
        error900: colors.red900,
        
        warning: colors.deepOrange800,
        warning100: colors.deepOrange100,
        warning200: colors.deepOrange200,
        warning300: colors.deepOrange300,
        warning400: colors.deepOrange400,
        warning500: colors.deepOrange500,
        warning600: colors.deepOrange600,
        warning700: colors.deepOrange700,
        warning800: colors.deepOrange800,
        warning900: colors.deepOrange900,
        
        grey: colors.grey800,
        grey100: colors.grey100,
        grey200: colors.grey200,
        grey300: colors.grey300,
        grey400: colors.grey400,
        grey500: colors.grey500,
        grey600: colors.grey600,
        grey700: colors.grey700,
        grey800: colors.grey800,
        grey900: colors.grey900,
        
        textColor: "#333333",
        secondaryTextColor: "#666666",
        alternateTextColor: "#fafafa",
        lightTextColor: "#fafafa",
        warningTextColor: colors.red800,
        
        canvasColor: '#ffffff',
        borderColor: colors[primaryColor+300],
        
        disabledColor: colors[primaryColor+200],
        pickerHeaderColor: colors[primaryColor+800],
        clockCircleColor: colors[primaryColor+100],
        shadowColor: "#333333",
      },
      
      fontFamily: "proxima-nova",
    });
    
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <UITheme theme={{primaryColor, accentColor}}>
          {this.props.children}
        </UITheme>
      </MuiThemeProvider>
    );
    
  }
}

const mapStateToProps = (state, ownProps) => {
  
  const packId = ownProps.packId;
  const pack = packId ? state.packs.all[packId] : null;
  
  const talentId = ownProps.talentId;
  const talent = talentId ? state.talents.all[talentId] : null;
  
  const agencyId = ownProps.agencyId ? ownProps.agencyId : 
                   state.user.agencyId ? state.user.agencyId : 
                   pack ? pack.agencyId : 
                   talent ? talent.agencyId : 
                   null;
                   
  const agency = agencyId ? state.agencies.all[agencyId] : null;
  
  return {
    packId,
    talentId,
    agencyId,
    
    pack,
    talent,
    agency,
  };
};

const mapDispatchToProps = {
  loadTalent,
  loadAgency,
  loadPack,
};

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
  
    