import React from 'react';
import { connect } from 'react-redux';
import { loadRecentAgencyPacks, addPack, addPackTalent } from 'actions';

import './index.css';

import { Modal } from 'skybolt-ui';
import Button from 'ui/Button';
import Media from 'ui/Media';
import TextField from 'ui/TextField';


const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    agencyId: state.user.agencyId,
    isLoading: !state.packs.recent,
    packs: Object.keys(state.packs.recent || {}).map(id => state.packs.recent[id]),
  };
};

const mapDispatchToProps = {
  loadRecentAgencyPacks,
  addPack,
  addPackTalent,
};



const PackList = ({packs, onSelect}) => packs.map(pack => 
  <div className="talentselectdialog-pack" key={pack.id} onClick={() => onSelect(pack.id)}>
    <Media 
      icon="pack"
      src={pack.preview}
      width={40} 
      height={40} 
      backgroundColor="white"
      className="talentselectdialog-pack-image"
    />
    <div className="talentselectdialog-pack-name">{pack.name}</div>
  </div>
);

class TalentSelectDialog extends React.Component {
  state = {
    name: "",
  }
  
  create = async e => {
    e && e.preventDefault();
    
    const { addPack, addPackTalent, talentId, history:{ push, replace } } = this.props;
    
    push(`/packs/create`);
    
    const pack = await addPack({name:this.state.name});
    addPackTalent(pack.id, null, talentId);
    
    setTimeout(() => replace(`/packs/${pack.id}/talents`), 1000);
  }
  
  select = async packId => {
    const { addPackTalent, talentId, history:{ push } } = this.props;
    
    addPackTalent(packId, null, talentId);
    
    push(`/packs/${packId}/talents`);
  }
  
  
  
  
  render() {
    return (
      <Modal status={this.props.open} modalStyle={{maxWidth:740, padding:16}} onRequestClose={this.props.onRequestClose}>
      
        <div className="talentselectdialog">
          
          <form onSubmit={this.create} className="talentselectdialog-create">
            <h3>Create new package</h3>
            <TextField 
              onChangeText={name => this.setState({name})}
              placeholder="Package Name"
              underline
              autoFocus
            />
            
            <Button raised={true} primary={true} label="Create Package" onClick={this.create}/>
          </form>
          
          <div className="talentselectdialog-choose">
            <h4 style={{paddingTop:2}}>or choose an existing package.</h4>
            <div>
              <PackList packs={this.props.packs} onSelect={this.select}/>
            </div>
          </div>
          
        </div>
        
      </Modal>  
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(TalentSelectDialog);