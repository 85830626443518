import React, { useState, useContext } from 'react';
import {
  InputSelect,
  FormContext,
} from 'skybolt-ui';
import { useQuery, createClient } from 'skybolt-api';
import Fuse from 'fuse.js';

export default function InputClient({
  client = {},
  onChange,
  ...props
}) {

  // Load and manage client data and search value.

  const data = useQuery(`{
    agency {
      clients
    }
  }`);

  const [search, setSearch] = useState("");


  // Handle search inputs by
  // updating the internal search and
  // calling `onSearch` prop.
  // Note: search string can be modified by prop onSearch.

  function handleSearch(value) {
    setSearch(value);
  }



  async function handleChange(value) {
    if(onChange) {
      let c = data.agency.clients.find(c => c.clientId == value);
      onChange(c);
    }
  }

  // Create options array. This is:
  // - all agency clients
  // - filtered by the search value,
  // - or a quick link to create the agency.

  let clients = [];

  if(data) {
    clients = data.agency.clients
      .map(c => ({
        value:c.clientId,
        label:c.name
      }));
  }

  if(search) {
    const fuse = new Fuse(clients, {keys:['label']});
    clients = fuse.search(search);
  }

  clients = clients.slice(0, 10);

  if(client?.name && client?.clientId && !clients.find(c => c.value == client?.clientId)) {
    clients.push({
      value: client.clientId,
      label: client.name,
    });
  }


  // Render

  if(!data) {
    return <div/>;
  }

  return (
    <InputSelect
      strict
      value={client?.clientId}
      clearable="All"
      options={clients}
      onSearch={handleSearch}
      onChange={handleChange}
      onRule
      {...props}
    />
  );
}