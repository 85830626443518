import { combineReducers } from "redux";

const all = (state=[], action) => {
  switch(action.type) {
    
    case "LOAD_TRANSACTIONS": 
      return action.transactions || [];
    
    default: 
      return state;
  }
};

export default combineReducers({
  all,
});