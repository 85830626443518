import React, { useState } from 'react';
import {Form, Card, Text, Button, InputText} from 'skybolt-ui';
import { without, uniq } from 'lodash';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';


const List = SortableContainer(props => <div style={props.style}>{props.children}</div>);
const Item = SortableElement(props => <div>{props.children}</div>);
const Handle = SortableHandle(props => <div style={props.style}>{props.children}</div>);


export default props => {

  const [input, setInput] = useState("");


  const add = () => {
    props.onChange(uniq([...props.value, input]));
    setInput("");
  };

  const remove = (val) => {
    props.onChange( without(props.value, val) );
  };

  const sort = ({oldIndex, newIndex}) => {
    const value = [...props.value];
    let [moved] = value.splice(oldIndex, 1);
    value.splice(newIndex, 0, moved);
    props.onChange(value);
  };

  return (
    <div>
      <List
        style={{marginBottom:8}}
        axis="y"
        useWindowAsScrollContainer={true}
        useDragHandle={true}
        onSortEnd={sort}>
        {props.value.map((item, i) =>
          <Item key={item} index={i}>
            <Card style={{display:'flex', alignItems:'center', padding:"2px 8px 0 8px", marginBottom:2}}>
              <Text style={{flex:1}}>{item}</Text>
              <Button icon="close" color="textLight" onClick={() => remove(item)}/>
              <Handle style={{height:32}}>
                <Button icon="drag" color="textLight"/>
              </Handle>
            </Card>
          </Item>
        )}
      </List>

      <Form style={{display:'flex', alignItems:'center'}}>
        <InputText
          title="Add Option"
          value={input}
          onChange={setInput}
          style={{margin:"0 8px 0 0"}}
        />
        <Button onClick={add}>Add</Button>
      </Form>

    </div>
  );
};