/*

Note:
Once `display:contents` is supported in all browsers, we can simplify this.
https://caniuse.com/#feat=css-display-contents
https://css-tricks.com/get-ready-for-display-contents/
https://snook.ca/archives/html_and_css/calendar-css-grid


*/


import React, { Component } from 'react';
import moment from 'moment';
import { range, isUndefined } from 'lodash';
import muiThemeable from 'material-ui/styles/muiThemeable';

import './index.css';

// import Icon from 'ui/Icon';



const RGB_MAX = 255

function hexToRgb(hex) {
  // Manage shorthand hexadecimal form
  var result = /^#?([a-f\d])([a-f\d])([a-f\d])$/i.exec(hex)
  if (result) {
    return {
      r: parseInt(`${result[1]}${result[1]}`, 16),
      g: parseInt(`${result[2]}${result[2]}`, 16),
      b: parseInt(`${result[3]}${result[3]}`, 16)
    }
  }
  // Manage hexadecimal form
  result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
  }
  return null
}

function isDark(hex) {
  let {r, g, b} = hexToRgb(hex)
  return (r + g + b) < 3 * RGB_MAX / 2
}


class EventCalendar extends Component {
  
  static defaultProps = {
    onClickDay: ()=>null,
    onClickEvent: ()=>null,
  }
  
  
  state = {
    activeEvent: null,
    events: [],
  }
  
  componentDidMount() {
    this.setEvents(this.props.events);
    if(this.el && this.el.clientWidth < 400) {
      this.props.onClickDay(this.props.date);
    }
  }
  
  componentDidUpdate(prevProps) {
    if(prevProps.date !== this.props.date || prevProps.events !== this.props.events) {
      this.setEvents(this.props.events);
    }
  }
  
  
  
  setEvents = data => {
    const date = this.props.date ? moment(this.props.date) : moment();
    const from = date.startOf('month').valueOf();
    const to = date.endOf('month').valueOf();
    
    let events = Object.keys(data)
      .map(id => data[id])
      .filter(event => {
        if(!!event.endDate) {
          if(event.date < from && event.endDate < from) {
            return false;
          }
        }
        else {
          if(event.date < from) {
            return false;
          }
        }
        if(event.date > to) {
          return false;
        }
        return true;
      });
    
    const eventOnDayAtIndex = (day, index, events) => {
      day = moment(day);
      events = events || this.state.events;
      return events.find(event => {
        if(event.index !== index) {
          return false;
        }
        if(event.date && event.endDate && day.isBetween(event.date, event.endDate, 'day', '[]')) {
          return true;
        }
        return day.isSame(event.date, 'day');
      });
    };

    events.forEach(event => {
      if(!isUndefined(event.index)) {
        return;
      }
      let index = 0;
      while(isUndefined(event.index) && index < 100) {
        if(!eventOnDayAtIndex(event.date, index, events)) {
          event.index = index;
        }
        index += 1;
      }
    });
    
    this.setState({events});
  }
  
  
  
  
  renderEvent(event, day) {

    const palette = this.props.muiTheme.palette;
    const isActive = event.id === this.state.activeEvent;
    const isStart = day.isSame(event.date, 'day');
    const isEnd = !event.endDate || day.isSame(event.endDate, 'day');
    const isMonday = day.day() === 1;
    
    let classes = ["eventcalendar-day-event"];
    if(isStart) {
      classes.push('is-start');
    }
    if(isEnd) {
      classes.push('is-end');
    }
    
    if(isUndefined(event.index)) {
      return null;
    }
    
    const top = (event.index * 25) + 48;
    const backgroundColor = Object.values(this.props.agency?.eventTypes || {}).find(t => t.label == event.type)?.color || "#EEEEEE";

    const opacity = isActive ? "1" : "0.9";

    const color = isDark(backgroundColor) ? "#fff" : "#424242";
    
    return (
      <div 
        onMouseEnter={() => this.setState({activeEvent:event.id})}
        onMouseLeave={() => this.setState({activeEvent:null})}
        onClick={e => {
          e.stopPropagation();
          this.props.onClickEvent(event);
        }}
        className={classes.join(' ')}
        style={{top, backgroundColor, opacity, color}}
        key={event.id+day.format('MM/DD/YYYY')}>
        
        {((isStart || isMonday) && this.props.showName !== false) && 
          <span>
            {event.firstName}&nbsp;
            {event.lastName}&nbsp;
            {event.packName}&nbsp;
            {event.type}&nbsp;
            {event.notes}
          </span>
        }
        
        {((isStart || isMonday) && this.props.showName === false) && 
          <span>
            {event.packName}&nbsp; 
            {event.type}&nbsp;
            {event.notes}
          </span>
        }
        
      </div>
    );
  }
  
  renderDay(day) {
    const {muiTheme:{ palette }} = this.props;
    
    const events = (this.state.events || [])
      .filter(event => {
        if(event.date && event.endDate) {
          return day.isBetween(event.date, event.endDate, 'day', '[]');
        }
        return day.isSame(event.date, 'day');
      });
    
    let maxIndex = events.reduce((m, e) => e.index > m ? e.index : m, 0);
    let height = (maxIndex + 1) * 25 + 48;
    // height = Math.min(height, 6 * 25 + 48);
    
    const isToday = day.isSame(moment(), 'day');
    const isThisMonth = day.isSame(this.props.date, 'month');
    
    return (
      <div 
        key={day.format('MM/DD')}
        className="eventcalendar-day"
        onClick={e => this.props.onClickDay(day.valueOf())}
        style={{
          borderWidth: isToday ? 1 : 0,
          borderStyle: 'solid',
          borderColor: isToday ? palette.accent700 : 'transparent',
          marginTop: isToday ? -1 : 0,
          height: height,
        }}>
        <div className={`eventcalendar-day-date ${isThisMonth ? 'this-month' : ''}`}>
          {day.date()}
        </div>
        {events/*.slice(0, 5)*/.map((event, i) => this.renderEvent(event, day))}
        {/*events.length > 5 && 
          <Icon 
            name="moreHoriz" 
            color="#95989A" 
            width={24} 
            height={24} 
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              marginLeft: 4,
            }}
          /> 
        */}
      </div>
    );
  }
  
  render() {
    const {
      date,
      className = "",
      style = {},
    } = this.props;

    const lastMonthDays = moment(date).date(0).day();
      
    return (
      <div className={`eventcalendar ${className}`} style={style} ref={el => this.el = el}>
      
        <div className="eventcalendar-title">
          <div className="eventcalendar-title-day">Monday</div>
          <div className="eventcalendar-title-day">Tuesday</div>
          <div className="eventcalendar-title-day">Wednesday</div>
          <div className="eventcalendar-title-day">Thursday</div>
          <div className="eventcalendar-title-day">Friday</div>
          <div className="eventcalendar-title-day">Saturday</div>
          <div className="eventcalendar-title-day">Sunday</div>
        </div>
        
        <div className="eventcalendar-grid">
          {range(0, lastMonthDays).map(i => this.renderDay(moment(date).date(-lastMonthDays+i+1)))}
          {range(0, moment(date).daysInMonth()).map(i => this.renderDay(moment(date).date(i+1)))}
        </div>
      </div>
    );
  }
}



export default muiThemeable()(EventCalendar);