import React, { useState, useCallback } from 'react';

import {
  useQuery,
  requiredSubscription,
  updateEpayAccount,
  updateTalent,
} from 'skybolt-api';

import {
  Text,
  Rule,
  Button,
  Icon,
  Card,
  Modal,
} from 'skybolt-ui';

export default function UserTalents(props) {

  const data = useQuery(`{
    me : user {
      userId
      fullName
    }
    user(userId:$userId) {
      talents {
        agencyId
        talentId
        image(width:40, height:40, face:true)
        fullName
        status
        epayId
        plan
      }
    }
  }`, {userId:props.match.params.userId});


  // Set Loading to the talent id being worked on.

  const [isLoading, setLoading] = useState(false);




  // Cancel a talent. This will cancel the talent, and update any epay account
  // associated with the talent.

  const cancel = useCallback(async talent => {
    setLoading(talent.talentId);

    const conf = await Modal.confirm(
      <Text paragraph>
        Are you sure you want to cancel {talent.fullName}?
      </Text>
    );
    if(!conf) {
      setLoading(false);
      return;
    }

    // Cancel the talent.
    await updateTalent({
      talentId: talent.talentId,
      update: {
        statusHistory: {
          [Date.now()]: `CANCELED by ${data.me.fullName}`,
        },
        status: "CANCELED"
      },
    });

    // Update subscription.
    const sub = await requiredSubscription({talentId:talent.talentId});
    try {
      await updateEpayAccount({
        ...sub,
        note: `
          Agency: ${talent.agencyId}
        `
      });
    }
    catch(error) {
      console.log('error', error);
    }

    setLoading(false);
  }, [data]);





  // Render

  if(!data) {
    return (
      <div style={{textAlign:'center'}}>
        <Icon name="loading" large/>
      </div>
    );
  }

  const activeAccounts = data.user.talents
    .filter(talent =>
      talent.status === "PAID" ||
      talent.status === "INACTIVE" ||
      talent.status === "TRIAL"
    );

  const inactiveAccounts = data.user.talents
    .filter(talent =>
      talent.status === "EXPIRED" ||
      talent.status === "CANCELED"
    );

  return (
    <div>

      {activeAccounts.length > 0 && (
        <div>
          <div style={{display:'flex', alignItems:'flex-end', height:40}}>
            <Text title>ACTIVE ACCOUNTS</Text>
            <div style={{flex:1}}/>
            <Button title icon="plus" href="/talents/create"/>
          </div>
          <Rule style={{marginBottom:24}}/>


          {
          // List talents with a status of PAID, INACTIVE, or TRIAL
          // Give TRIAL talent the option to upgrade.

          activeAccounts
            .map(talent => {
              const isTrial = talent.status === "TRIAL";

              if(isLoading === talent.talentId) {
                return (
                  <Card key={talent.talentId} style={{display:'flex', alignItems:'center', marginBottom:4}}>
                    <img src={talent.image} alt="Headshot"/>
                    <Button href={`/talents/${talent.talentId}`} style={{flex:1}}>
                      <Text>{talent.fullName}</Text>
                    </Button>
                    <div style={{flex:1}}></div>
                    <Icon name="loading" style={{marginRight:16}}/>
                  </Card>
                );
              }

              return (
                <Card key={talent.talentId} style={{display:'flex', alignItems:'center', marginBottom:4}}>
                  <img src={talent.image} alt="Headshot"/>
                  <Button href={`/talents/${talent.talentId}`} style={{flex:1}}>
                    <Text>{talent.fullName}</Text>
                  </Button>
                  <div style={{flex:1}}></div>
                  {isTrial && <Text accent>TRIAL</Text>}
                  <div style={{flex:1}}></div>
                  <Button onClick={() => cancel(talent)}>Cancel</Button>
                  {isTrial && <Button href={`/talents/${talent.talentId}/subscription`}>Upgrade</Button>}
                </Card>
              );

            })

          }
        </div>
      )}


      {inactiveAccounts.length > 0 && (
        <div>
          <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
            <Text title>INACTIVE ACCOUNTS</Text>
          </div>
          <Rule style={{marginBottom:24}}/>


          {
          // List talents with a status of EXPIRED or CANCELED

          inactiveAccounts
            .map(talent => {
              if(isLoading === talent.talentId) {
                return (
                  <Card key={talent.talentId} style={{display:'flex', alignItems:'center', marginBottom:4}}>
                    <img src={talent.image} alt="Headshot"/>
                    <Button href={`/talents/${talent.talentId}`} style={{flex:1}}>
                      <Text>{talent.fullName}</Text>
                    </Button>
                    <div style={{flex:1}}></div>
                    <Icon name="loading" style={{marginRight:16}}/>
                  </Card>
                );
              }

              return (
                <Card key={talent.talentId} style={{display:'flex', alignItems:'center', marginBottom:4}}>
                  <img src={talent.image} alt="Headshot"/>
                  <Button href={`/talents/${talent.talentId}`}>
                    <Text>{talent.fullName}</Text>
                  </Button>
                  <div style={{flex:1}}></div>
                  <Button href={`/talents/${talent.talentId}/subscription`}>Restart</Button>
                </Card>
              );

            })

          }
        </div>
      )}

    </div>
  );
}