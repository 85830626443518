import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addMessage,
  loadThread,
  subscribeToThreadMessages,
  unsubscribeFromThreadMessages,
} from 'actions';

import Button from 'ui/Button';

import Message from './Message';
import Composer from './Composer';



export const mapStateToProps = (state, ownProps) => {
  const userId = state.user.id;

  const threadId = ownProps.threadId;
  const thread = state.threads.all[threadId] || {};

  const recipients = Object.keys(thread.recipients)
    .map(id => thread.recipients[id])
    .reduce((all, r) => !userId || r.userId !== userId ? [...all, r.name] : all, [])
    .join(', ');

  let messages = state.messages.byThread[threadId] || {};
  messages = Object.keys(messages)
    .map(id => ({...messages[id], id}))
    .sort((a, b) => a.date < b.date ? -1 : 1)
    .slice(1); // always knock off the original message

  const meta = ownProps.meta;

  return {
    threadId,
    recipients,
    messages,
    meta,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  addMessage,
  loadThread,
  subscribeToThreadMessages,
  unsubscribeFromThreadMessages,
};




export class Subthread extends Component {
  UNSAFE_componentWillMount() {
    const threadId = this.props.threadId;
    this.props.loadThread(threadId);
    this.props.subscribeToThreadMessages(threadId);
  }

  componentWillUnmount() {
    const threadId = this.props.threadId;
    this.props.unsubscribeFromThreadMessages(threadId);
  }


  reply = message => {
    const threadId = this.props.threadId;
    const meta = this.props.meta;
    this.props.addMessage(threadId, {...message, ...meta});
  }

  render() {
    return (
      <div className="threadslist-subthread" onClick={this.props.onClick}>

        {this.props.messages.map(message =>
          <Message key={message.id} message={message}/>
        )}

        <Composer
          className="threadslist-subthread-composer"
          hintText={`Reply to ${this.props.recipients}...`}
          threadId={this.props.threadId}
          onSend={this.reply}
        />

        <Button
          icon="close"
          onClick={e => this.props.onClose()}
          className="threadslist-subthread-close"
        />
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Subthread);