import React, { Component } from 'react';

export default class SiteError extends Component {
  
  state = {
    hasError: false,
  }
  
  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    if(this.state.error) {
      return (
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', padding:"0 16px 200px 16px"}}>
          <div>
            <h1>Error</h1>
            <p>
              Try refreshing your browser to try again. If the problem persists, please
              contact support.
            </p>
            <p>
              We're sorry for the inconvenience. 
            </p>
            <div style={{
              backgroundColor: "#fafafa",
              color: "#333",
              fontSize: 10,
              padding: 16,
              margin: "0 -16px",
              borderRadius: 8,
              maxWidth: 600,
              maxHeight: 300,
              overflow: 'auto'
            }}>
              {this.state.error.message}
              {this.state.error.description}
              {this.state.error.fileName}
              {this.state.error.lineNumber}
              {this.state.info.componentStack}
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
  
}