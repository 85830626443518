import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  addCannedMessage,
  updateCannedMessage,
  loadCannedMessages,
  deleteCannedMessage,
  updateAgency,
} from 'actions';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import IconButton from 'material-ui/IconButton';
import Title, { Action } from 'ui/Title';
import Page from 'ui/Page';
import TextField from 'material-ui/TextField';
import Icon from 'ui/Icon';
import Wysiwyg from 'ui/Wysiwyg';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Card, InputText, Text, Icon as UIIcon, Button } from 'skybolt-ui';

import "./index.css";


const DragHandle = SortableHandle(() =>
  <UIIcon name="drag" color="#bdbdbd"/>
);

const SortableList = SortableContainer((props) => <div>{props.children}</div>);

const SortableListItem = SortableElement(({children, sortable=true}) => {
  return (
    <Page style={{display:'flex', alignItems:'center', marginBottom:4, padding:"0 16px 0 0"}} >
      <div style={{flex:1}}>
        {children}
      </div>
      <div style={{flex:0, paddingTop:2, cursor:sortable ? 'move' : 'default'}}>
        {sortable ? <DragHandle/> : <div style={{width:24}}/>}
      </div>
    </Page>
  );
});


class AgencyMessaging extends Component {

  state = {
    selected: null,
  }

  UNSAFE_componentWillMount() {
    this.props.loadCannedMessages(this.props.agencyId);
  }

  sort = ({oldIndex, newIndex}) => {
    let keys = Object.keys(this.props.messages)
      .sort((a, b) => this.props.messages[a].order < this.props.messages[b].order ? -1 : 1)
    
    console.log(keys);

    keys.splice(newIndex, 0, keys.splice(oldIndex, 1)[0]);

    let updatedMessages = {};
    keys.forEach((key, index) => {
      updatedMessages[key] = {
      ...this.props.messages[key],
      order: index
      };
    });

    keys.forEach(key => {
      this.props.updateCannedMessage(key, updatedMessages[key]);
    });
  }

  select = (index) => {
    this.setState({selected: index});
  }

  add = () => {
    this.props.addCannedMessage(this.props.agencyId);
    let index = Object.keys(this.props.messages).length;
    this.setState({selected: index});
  }

  renderMessage(message) {
    
    const { updateCannedMessage, muiTheme:{ palette } } = this.props;

    return (
      <Page
        style={{
          display: 'flex',
          padding:"0 8px 16px 16px",
          marginBottom:16
        }}
      >
        <div style={{flex:1}}>
          <div>
            <TextField
              name="name"
              floatingLabelText="Name"
              value={message.name || ""}
              onChange={(e, name) => updateCannedMessage(message.id, {name})}
            />
          </div>
          <div>
            <TextField
              fullWidth
              name="subject"
              floatingLabelText="Subject"
              value={message.subject || ""}
              onChange={(e, subject) => updateCannedMessage(message.id, {subject})}
            />
          </div>
          <div>
            <div
              style={{
                fontSize:12,
                paddingBottom:8,
                marginTop:16,
                color: palette.primary200,
              }}>
              Message
            </div>
            <Wysiwyg
              html={message.body}
              onChange={(e, body) => updateCannedMessage(message.id, {body})}
            />
          </div>
          <div>
            <TextField
              fullWidth
              name="sms"
              floatingLabelText="SMS Message"
              value={message.sms || ""}
              onChange={(e, sms) => updateCannedMessage(message.id, {sms})}
            />
          </div>

          <nav style={{padding:"16px 0 0 0"}}>
            <Button raised onClick={() => this.props.deleteCannedMessage(message.id)}>
              Remove Message
            </Button>
          </nav>
        </div>
        
        <div style={{flex:0}}>
          <IconButton
            onClick={() => this.setState({selected:null})}>
            <Icon name="close" size={24} isReversed={true} color="#bdbdbd"/>
          </IconButton>
        </div> 
      </Page>
    )
  }

  render() {
    const { messages, agencyId, updateCannedMessage, muiTheme:{ palette } } = this.props;

    if(!messages) {
      //todo: loader
      return null;
    }

    return (
      <div className="form-cannedmessages" style={{padding:0}}>
        <Title>SMS Messaging</Title>

        <Card style={{padding:16}}>
          <div style={{maxWidth:600}}>
            <InputText
              title="Default SMS Message"
              value={this.props.defaultSMS}
              onChange={defaultSMS => {
                this.props.updateAgency(this.props.agencyId, {defaultSMS});
              }}
              hint={`${this.props.defaultSMS.length}/160`}
              error={this.props.defaultSMS.length > 160 ? `${this.props.defaultSMS.length}/160 Keep SMS messages below 160 character.` : null}
            />
            <Text paragraph light>
              Set the default SMS message for sent with new messages.<br/>
              This can be overwritten when composing messages. <br/>
              If no default is set, first 160 characters of the main message is used.
            </Text>
          </div>
        </Card>


        <Title
          actions={[<Action onClick={this.add}/>]}>
          Canned Messages
        </Title>

        <SortableList
          axis="y"
          useWindowAsScrollContainer={true}
          useDragHandle={true}
          onSortEnd={this.sort}>

          {Object.keys(messages)
            .map(id => messages[id])
            .sort((a, b) => a.order < b.order ? -1 : 1)
            .map((message, index) =>
              this.state.selected === index ? (
                this.renderMessage(message)
              ) : (
                <SortableListItem key={message.id} index={index}>
                  <p style={{cursor:'pointer', margin:0, padding:'16px 16px 16px 16px', userSelect:'none'}} onClick={() => this.select(index)}>
                    {message.name || "New Message"}
                  </p>
                </SortableListItem>
              )
            )
          }

        </SortableList>

        {Object.keys(messages).length === 0 &&
          <div>
            No canned messages.
          </div>
        }

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {agencyId, ...props} = ownProps;
  const defaultSMS = state.agencies.all[agencyId].defaultSMS || "";
  const messages = state.cannedMessages.byAgency[agencyId];
  return {
    agencyId,
    messages,
    defaultSMS,
    ...props
  };
};

const mapDispatchToProps = {
  addCannedMessage,
  updateCannedMessage,
  loadCannedMessages,
  deleteCannedMessage,
  updateAgency,
};

export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(AgencyMessaging));

