import React, { useCallback, useState } from 'react';
import {
  useRouter,
  Text,
  Currency,
  Container,
  Rule,
  Card,
  Button,
  Loading,
  InputText,
  InputSelect,
  InputArray,
  InputNumber,
  InputDate,
  InputHTML,
  InputTime,
  Form,
  Modal,
  Icon,
  InputPhone,
} from 'skybolt-ui';
import { debounce, map } from 'lodash';
import { useQuery, updateInvoice, updateClient } from 'skybolt-api';
import InputTalent from './InputTalent';
import InputClient from './InputClient';
import InputPackage from './InputPackage';
import './index.css';

export default function EditInvoice(props) {

  const {
    match: {
      params:{
        invoiceId
      }
    }
  } = useRouter();


  const data = useQuery(`{
    invoice(invoiceId:$invoiceId) {
      agencyId
      invoiceNumber
      clientId,
      client {
        name
        poNumber
        vendorNumber
        contactName
        phones
        emails
        address
      }
      packName
      vendorNumber
      poNumber
      agencyFee
      date
      dateSent
      dateDue
      jobType
      comments
      notes
      total
      agencyFeeTotal
      commissionTotal
      status
      lineItems {
        talentId
        talentImage
        talentName
        comment
        type
        timeFrom
        timeTo
        hours
        rate
        commission
        total
      }
      subtotal
    }
    agency {
      agencyId
      jobTypes
      billingRate
      billingCommission
      billingTypes
      timezone
    }
  }`, {invoiceId});

  let timezone = data?.agency?.timezone;

  const debouncedUpdateInvoice = useCallback(debounce(update => {
    updateInvoice({invoiceId, update});
  }, 500), [invoiceId]);
  const handleChange = (_, update) => debouncedUpdateInvoice(update);


  // Client modal status and updates.
  const [isClientOpen, setClientOpen] = useState(false);
  const clientId = data && data.invoice.clientId;
  const handleClientChange = useCallback(debounce(update => updateClient({clientId, update}), 500), [clientId]);


  // Render

  return (
    <Container className="billinginvoiceedit">

      <div style={{display:"flex", alignItems:"flex-end", height:40, paddingTop:32}}>
        <Text title>Edit Invoice</Text>
        <div style={{flex:1}}/>
        <Button title icon="close" href="view"/>
      </div>
      <Rule style={{marginBottom:24}}/>

      {!data ? (
        <Loading/>
      ) : (
        <Card className="card">

          <Form value={data.invoice} onChange={handleChange}>

            <div className="info">
              <div className="client">
                <InputClient name="clientId" strict title="Client"/>
                {data.invoice.clientId &&
                  <Button
                    style={{paddingTop:18}}
                    onClick={() => setClientOpen(true)}
                    icon="external-link-square"
                  />
                }
              </div>
              <InputSelect className="type" name="jobType" strict options={data.agency.jobTypes} title="Job Type"/>
              <InputPackage className="pack" title="Package"/>
              <InputNumber className="num" name="invoiceNumber" title="Invoice Number"/>
              <InputText className="vendor" name="vendorNumber" title="Vendor Number"/>
              <InputText className="po" name="poNumber" title="PO Number"/>
              <InputDate className="date" name="date" title="Date" timezone={timezone}/>
              <InputDate className="due" name="dateDue" title="Date Due" timezone={timezone}/>
              <InputNumber className="percent" name="agencyFee" title="Agency Fee %"/>
              <InputHTML className="comments" name="comments" title="Comment" hint="Displayed on the invoice."/>
              <InputSelect className="status" name="status" strict options={["POSTED", "PAID", "OVERDUE", "VOID"]} title="Status"/>
              <InputHTML className="notes" name="notes" title="Notes" hint="Only visible to agents."/>
            </div>

            <div className="details-wrapper">
              <div className="details">
                <Text>Line Items</Text>
                <Rule style={{marginBottom:16}}/>
                <div className="titles" style={{marginBottom:8}}>
                  <Text small light block style={{flex:1, marginRight:8}}>Date</Text>
                  <Text small light block style={{flex:2, marginRight:8}}>Talent</Text>
                  <Text small light block style={{flex:1, marginRight:8}}>Type</Text>
                  <Text small light block style={{flex:1, marginRight:8}}>Comment</Text>
                  <Text small light block style={{flex:1, marginRight:8}}>From Time</Text>
                  <Text small light block style={{flex:1, marginRight:8}}>To Time</Text>
                  <Text small light block style={{flex:1, marginRight:8}}>Hours</Text>
                  <Text small light block style={{flex:1, marginRight:8}}>Rate</Text>
                  <Text small light block style={{flex:1, marginRight:8}}>Commission %</Text>
                  <Text small light block style={{flex:1, marginRight:32}}>Total</Text>
                </div>

                <InputArray
                  name="lineItems"
                  addValue={{
                    date: data.invoice.date || Date.now(),
                    rate: data.invoice.client.rate || data.agency.billingRate,
                    commission: data.agency.billingCommission
                  }}
                  addLabel="Add Line Item">

                  <InputDate
                    className="date" name="date" title="Date" hint={false}
                    style={{marginRight:8, flex:1}}
                    timezone={timezone}
                  />

                  <InputTalent
                    style={{marginRight:8, flex:2}}
                  />
                  <InputSelect
                    name="type"
                    options={map(data.agency.billingTypes, t => t.label)}
                    style={{marginRight:8, flex:1}}
                  />
                  <InputText 
                    name="comment"
                    style={{marginRight:8, flex:1}}
                  />
                  <InputTime
                    name="timeFrom"
                    style={{marginRight:8, flex:1}}
                    timezone={timezone}
                  />
                  <InputTime
                    name="timeTo"
                    style={{marginRight:8, flex:1}}
                    timezone={timezone}
                  />
                  <InputNumber
                    name="hours"
                    style={{marginRight:8, flex:1}}
                  />
                  <InputNumber
                    name="rate"
                    style={{marginRight:8, flex:1}}
                  />
                  <InputNumber
                    name="commission"
                    style={{marginRight:8, flex:1}}
                  />
                  <InputNumber
                    name="total"
                    style={{flex:1}}
                  />
                </InputArray>
              </div>
            </div>

            <div className="total-wrapper">
              <div className="totals">
                <Text small style={{marginRight:16}}>Subtotal</Text>
                <Currency large>{data.invoice.subtotal}</Currency>
                <Text small style={{marginRight:16}}>Agency Fee</Text>
                <Currency large>{data.invoice.agencyFeeTotal}</Currency>
                <Text small style={{marginRight:16}}>Total</Text>
                <Currency large bold>{data.invoice.total}</Currency>
                <Text small style={{marginRight:16}}>Commission</Text>
                <Currency large>{data.invoice.commissionTotal}</Currency>
              </div>
            </div>

          </Form>

        </Card>
      )}

      {data && data.invoice.clientId &&
        <Modal status={isClientOpen} onRequestClose={() => setClientOpen(false)}>
          <Form value={data.invoice.client} onChange={handleClientChange} style={{padding:16}}>
            <Text large paragraph>Edit Client</Text>
            <div>
              <InputText name="name" title="Client Name"/>
              <InputText name="poNumber" title="PO Number"/>
              <InputText name="vendorNumber" title="Vendor Number"/>
            </div>

            <div>
              <Text small>Contact</Text>
              <Rule style={{marginBottom:16}}/>

              <InputText name="contactName" title="Contact Name"/>

              <InputArray name="phones" addValue={{label:"Office", number:""}} addLabel="Add Phone Number">
                <Icon
                  name="phone-rotary"
                  small
                  duotone
                  style={{paddingTop:14, marginRight:8}}
                />
                <InputSelect
                  title="Label"
                  name="label"
                  options={["Office", "Home", "Mobile", "Fax"]}
                  style={{marginRight:8, width:100}}
                />
                <InputPhone
                  title="Phone Number"
                  name="number"
                  style={{flex:1}}
                />
              </InputArray>

              <InputArray name="emails" addValue={{label:"Office", email:""}} addLabel="Add Email Address">
                <Icon
                  name="envelope"
                  small
                  duotone
                  style={{paddingTop:14, marginRight:8}}
                />
                <InputSelect
                  title="Label"
                  name="label"
                  options={["Office", "Home"]}
                  style={{marginRight:8, width:100}}
                />
                <InputText
                  title="Email Address"
                  name="email"
                  style={{flex:1}}
                />
              </InputArray>

              <InputText
                name="address.street"
                title="Address"
              />
              <div style={{display:'flex'}}>
                <InputText
                  name="address.city"
                  title="City"
                  style={{flex:1, marginRight:8}}
                />
                <InputText
                  name="address.state"
                  title="State"
                  style={{width:60, marginRight:8}}
                />
                <InputText
                  name="address.zip"
                  style={{width:100}}
                  title="Zip"
                />
              </div>

            </div>
          </Form>
        </Modal>
      }
    </Container>
  );
}