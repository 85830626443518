import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouter,
} from 'skybolt-ui';

import All from './All';
import User from './User';

export default props => {
  const {match:{path, url}} = useRouter();
  return (
    <Switch>
      <Route path={`${path}/all`} component={All}/>
      <Route path={`${path}/me`} component={User}/>
      <Route path={`${path}/:userId`} component={User}/>
      <Redirect to={`${url}/all`}/>
    </Switch>
  );
};