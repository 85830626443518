/*

Generic Talent Search
Shows talent numeric and select talent fields for the agency.

Usage:

<TalentSearch
  search={search}
  onSearch={search => searchAgencyTalents(search)}
/>

TODO:

- remove `-v2` classNames once `components/TalentSearch`
  is depricated.

- add Tags search to left column

*/


import React from 'react';
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { range, get, merge, uniq, intersection, pick } from 'lodash';

import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import RangeSelect from 'ui/RangeSelect';
import { Text } from 'skybolt-ui';

import "./index.css";


export const mapStateToProps = (state, ownProps) => {
  let fields = null;
  let talentTags = [];
  let mediaTags = [];
  let canSearchLocation = false;

  const baseFields = {
    age: {
      type: 'number',
      name: "Age",
      min: 0,
      max: 100,
      order: -1,
      isPublic: true,
    },
    city: {
      type: 'text',
      name: "City",
      order: 101,
    },
    state: {
      type: 'text',
      name: "State",
      order: 102,
    },
    zip: {
      type: 'text',
      name: "Zip",
      order: 103,
    },
  };

  if(!!ownProps.agencyId) {
    const agency = get(state, `agencies.all[${ownProps.agencyId}]`, {});
    canSearchLocation = agency.canSearchLocation;
    fields = agency.talentFields || {};
    talentTags = agency.talentTags || [];
    mediaTags = agency.mediaTags || [];
    baseFields.age.isPublic = agency.showTalentDOBPublic !== false;
  }
  else {
    const agencyIds = [state.user.agencyId, ...(state.user.agencyIds || [])].filter(v => !!v);
    agencyIds.forEach(agencyId => {
      const agency = get(state, `agencies.all[${agencyId}]`, {});
      const agencyFields = agency.talentFields || {};
      const agencyTalentTags = agency.talentTags || [];
      const agencyMediaTags = agency.mediaTags || [];

      if(fields) {
        fields = pick(
          merge({}, fields, agencyFields),
          intersection(Object.keys(fields), Object.keys(agencyFields))
        );
      }
      else {
        fields = agencyFields;
      }

      if(agency.showTalentDOBPublic === false) {
        baseFields.age.isPublic = false;
      }

      if(agency.canSearchLocation) {
        canSearchLocation = true;
      }

      talentTags = [...talentTags, ...agencyTalentTags];
      mediaTags = [...mediaTags, ...agencyMediaTags];
    });
  }

  fields = merge({}, fields, baseFields);

  fields = Object.keys(fields)
    .map(id => ({id, ...fields[id]}))
    .filter(field => field.isEnabled !== false);

  if(!state.user.permissions.canEditTalents) {
    fields = fields.filter(field => field.isPublic);
  }

  fields = fields.sort((a, b) => !a.order || a.order < b.order ? -1 : 1);

  talentTags = uniq(talentTags);
  mediaTags = uniq(mediaTags);

  // Only allow agents with permission to pack to see statuses other than active.
  const canViewStatus = state.user.permissions.canEditTalents;


  return {
    fields,
    mediaTags,
    talentTags,
    canViewStatus,
    canSearchLocation,
    ...ownProps,
  };
};

export const mapDispatchToProps = {};





export const TalentSearch = ({
  search = {},
  fields = [],
  mediaTags = [],
  talentTags = [],
  className="",

  canViewStatus,
  canSearchLocation,
  ...props
}) => {

  const searchKeys = [
    'agencyId',
    'query',
    'mediaTags',
    'tags',
    'status',
    'distance',
    'geostreet',
    'geocity',
    'geostate',
    'geozip',
    ...fields.map(f => f.id),
  ];
  const emit = patch => {
    props.onSearch(
      pick({...search, ...patch}, searchKeys)
    );
  };

  return (
    <div className={`talentsearch-v2 ${className}`.trim()}>

      <div className="talentsearch-v2-section">
        <TextField
          floatingLabelText="Search"
          value={search.query || ""}
          fullWidth
          onChange={(e, query) => emit({query})}
        />

        {!!mediaTags && mediaTags.length > 0 &&
          <SelectField
            floatingLabelText="Image Tags"
            autoWidth={true}
            value={search.mediaTags || []}
            onChange={(e, i, value) => emit({mediaTags:value})}
            fullWidth
            multiple>
            {mediaTags.map(tag =>
              <MenuItem
                key={tag}
                value={tag}
                primaryText={tag}
                insetChildren={true}
                checked={search.mediaTags && search.mediaTags.indexOf(tag) > -1}
              />
            )}
          </SelectField>
        }

        {!!talentTags && talentTags.length > 0 &&
          <SelectField
            floatingLabelText="Labels"
            autoWidth={true}
            value={search.tags || []}
            onChange={(e, i, value) => emit({tags:value})}
            fullWidth
            multiple>
            {talentTags.map(tag =>
              <MenuItem
                key={tag}
                value={tag}
                primaryText={tag}
                insetChildren={true}
                checked={search.tags && search.tags.indexOf(tag) > -1}
              />
            )}
          </SelectField>
        }

        {canViewStatus &&
          <RangeSelect
            key="status"
            label="Status"
            value={search.status}
            options={['active', 'incomplete', 'trial', 'paid', 'inactive', 'expired', 'canceled']}
            onChange={(val) => emit({status:val})}
          />
        }

        {canSearchLocation &&
          <div>
            <div style={{marginTop:40}}>
              <Text>Within</Text>
              <TextField
                key="distance"
                value={search.distance || ""}
                onChange={(e, val) => emit({distance:val})}
                style={{width:60, display:'inline-block', margin:"0 8px"}}
              />
              <Text>miles of</Text>
            </div>

            <TextField
              key="street"
              floatingLabelText="Address"
              value={search.geostreet || ""}
              fullWidth
              onChange={(e, val) => emit({geostreet:val})}
            />

            <TextField
              key="city"
              floatingLabelText="City"
              value={search.geocity || ""}
              fullWidth
              onChange={(e, val) => emit({geocity:val})}
            />

            <TextField
              key="state"
              floatingLabelText="State"
              value={search.geostate || ""}
              onChange={(e, val) => emit({geostate:val})}
              style={{width:80}}
            />

            <TextField
              key="zip"
              floatingLabelText="Zip"
              value={search.geozip || ""}
              onChange={(e, val) => emit({geozip:val})}
              style={{width:150}}
            />
          </div>
        }

      </div>

      <div className="talentsearch-v2-section">
        <div className="talentsearch-v2-fields">
          {fields
            .map(field => {
              if(field.category === "system") {
                return null;
              }

              if(field.type === 'text') {
                return (
                  <TextField
                    key={field.id}
                    floatingLabelText={field.name}
                    value={search[field.id] || ""}
                    fullWidth
                    onChange={(e, val) => emit({[field.id]:val})}
                  />
                );
              }

              if(field.type === 'date' && field.isExpiration === true) {
                return (
                  <SelectField
                    key={field.id}
                    floatingLabelText={field.name}
                    autoWidth={true}
                    value={search[field.id] || null}
                    onChange={(e, i, value) => emit({[field.id]:value})}
                    fullWidth>

                    <MenuItem value={null} primaryText="" checked={!search[field.id]} insetChildren={true}/>
                    <MenuItem value="valid" primaryText="Valid" checked={search[field.id] === 'valid'} insetChildren={true}/>
                    <MenuItem value="expired" primaryText="Expired" checked={search[field.id] === 'expired'} insetChildren={true}/>

                  </SelectField>
                );
              }

              if(field.type === 'date') {
                return null;
              }

              return (
                <RangeSelect
                  key={field.id}
                  label={field.name}
                  value={search[field.id]}
                  options={field.options || range(field.min, field.max, field.step || 1)}
                  onChange={(val) => emit({[field.id]:val})}
                />
              );
            })
          }
        </div>
      </div>

    </div>
  );
};




export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(TalentSearch));