import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestPack, loadAgencies } from 'actions';
import { without } from 'lodash';

import Title from 'ui/Title';
import Container from 'ui/Container';
import Page from 'ui/Page';
import Subtitle from 'ui/Subtitle';
import Caption from 'ui/Caption';
import Field from 'ui/Field';
import TagInput from 'ui/TagInput';
import AddressField from 'ui/AddressField';
import TimeslotField from 'ui/TimeslotField';
import Button from 'ui/Button';
import Wysiwyg from 'ui/Wysiwyg';

import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';

import "./index.css";


export const mapStateToProps = (state, ownProps) => {
  
  const agencyIds = state.user.agencyIds || [];
  const agencies = agencyIds.map(id => state.agencies.all[id]).filter(agency => !!agency);
  
  return {
    agencyIds,
    agencies,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  requestPack,
  loadAgencies
};


export function AgencyCheckGroup(props) {
  return (
    <div className="createpackrequests-agency-checks">
      {props.options.map(agency => 
        <div key={agency.id} className="createpackrequests-agency-check">
          <Checkbox 
            label={agency.name}
            checked={props.agencies.indexOf(agency.id) > -1}
            onCheck={(e, checked) => {
              if(checked) {
                props.onChange([...props.agencies, agency.id]);
              }
              else {
                props.onChange(without(props.agencies, agency.id));
              }
            }}
            labelStyle={{whiteSpace:'nowrap'}}
          />
        </div>
      )}
    </div>
  );
}


export class CreatePackRequest extends Component {
  
  state = {
    agencies: [],
    invite: [],
    timeslots: [],
  }
  
  componentDidMount() {
    this.props.loadAgencies(this.props.agencyIds);
  }
  
  send = async () => {
    const { requestPack, history:{ push, replace } } = this.props;
    
    const { 
      agencies = [], 
      invite = "", 
      timeslots = [], 
      interval = 60, 
      address = {}, 
      message = "", 
      ...data 
    } = this.state;
    
    const packData = {
      ...data,
      schedule: {
        ...address,
        interval,
        timeslots,
      }
    };
    const pack = await requestPack({agencies, invite, message, data:packData});
    
    push(`/packs/create`);
    setTimeout(() => replace(`/packs/${pack.id}/talents`), 1000);
  }
  
  render() {
    return (
      <Container className="createpackrequest">
        <Title>Create Casting Request</Title>
        
        <Page pad={true} style={{marginBottom:32}}>
          <Subtitle>Contributors</Subtitle>
          <Caption style={{paddingBottom:16}}>Invite agencies or agents by email address to contribute.</Caption>
          <div className="createpackrequests-agencies">
            <AgencyCheckGroup
              options={this.props.agencies}
              agencies={this.state.agencies}
              onChange={agencies => this.setState({agencies})}
            />
          </div>
          <TagInput
            hint="Email Address"
            tags={this.state.invite}
            onChange={invite => this.setState({invite})}
            validate={email => {
              const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if(!re.test(email)) {
                return `"${email}" is not a valid email address.`;
              }
              return false;
            }}
          />
        </Page>
        
        <Page pad={true} style={{marginBottom:32}}>
          <Subtitle>Message</Subtitle>
          <Wysiwyg
            html={this.props.message}
            className="messagecomposer-wysiwyg"
            toolsClassName="messagecomposer-wysiwyg-tools"
            contentClassName="messagecomposer-wysiwyg-content"
            onChange={(e, message) => this.setState({message})}
          />
        </Page>
        
        <Page pad={true} style={{marginBottom:32}}>
          <Subtitle>Request</Subtitle>
          <Field label="Package Name">
            <TextField 
              name="name"
              fullWidth
              value={this.state.name || ""}
              onChange={(e, name) => this.setState({name})}
            />
          </Field>
          <Field label="Wardrobe">
            <TextField 
              name="wardrobe"
              fullWidth
              multiLine={true}
              value={this.state.wardrobe || ""}
              onChange={(e, wardrobe) => this.setState({wardrobe})}
            />
          </Field>
          <Field label="Description">
            <TextField 
              name="notes"
              fullWidth
              multiLine={true}
              value={this.state.notes || ""}
              onChange={(e, notes) => this.setState({notes})}
            />
          </Field>
        </Page>
        
        <Page pad={true} style={{marginBottom:32}}>
          <Subtitle>Schedule</Subtitle>
          <Field label="Location">
            <AddressField 
              value={this.state.address || {}}
              onChange={address => this.setState({address})}
              showFloatingLabels={false}
            />
          </Field>
          <Field label="Duration">
            <SelectField
              value={this.state.interval || 60}
              onChange={(e, i, interval) => this.setState({interval})}>
              <MenuItem value={10} primaryText="10 minutes"/>
              <MenuItem value={15} primaryText="15 minutes"/>
              <MenuItem value={30} primaryText="30 minutes"/>
              <MenuItem value={60} primaryText="1 hour"/>
              <MenuItem value={120} primaryText="2 hours"/>
            </SelectField>
          </Field>
          <Field label="Timeslots">
            {this.state.timeslots.map((timeslot, i) => 
              <TimeslotField
                key={i}
                value={timeslot}
                onChange={(e, val) => {
                  let timeslots = [...this.state.timeslots];
                  timeslots[i] = val;
                  this.setState({timeslots});
                }}
                onRemove={() => this.setState({timeslots:without(this.state.timeslots, timeslot)})}
              />
            )}
            <Button
              raised={true}
              label="Add Timeslot"
              onClick={() => this.setState({timeslots:[...this.state.timeslots, {}]})}
              style={{marginTop:8}}
            />
          </Field>
        </Page>
        
        <div className="createpackrequest-cols">
          <Page pad={true}>
            <Subtitle>Ad Agency</Subtitle>
            <Field label="Agency">
              <TextField
                fullWidth
                name="addAgency"
                hintText=""
                value={this.state.adAgency || ""}
                onChange={(e, adAgency) => this.setState({adAgency})}
              />
            </Field>
            
            <Field label="Address">
              <AddressField 
                value={this.state.adAgencyAddress || {}}
                onChange={adAgencyAddress => this.setState({adAgencyAddress})}
                showFloatingLabels={false}
              />
            </Field>
            
            <Field label="Producer">
              <TextField
                fullWidth
                name="adAgencyProducer"
                hintText=""
                value={this.state.adAgencyProducer || ""}
                onChange={(e, adAgencyProducer) => this.setState({adAgencyProducer})}
              />
            </Field>
            
            <Field label="Email">
              <TextField
                fullWidth
                name="adAgencyEmail"
                hintText=""
                value={this.state.adAgencyEmail || ""}
                onChange={(e, adAgencyEmail) => this.setState({adAgencyEmail})}
              />
            </Field>
            
            <Field label="Phone">
              <TextField
                fullWidth
                name="adAgencyPhone"
                hintText=""
                value={this.state.adAgencyPhone || ""}
                onChange={(e, adAgencyPhone) => this.setState({adAgencyPhone})}
              />
            </Field>
            
          </Page>
            
          <Page pad={true}>
            <Subtitle>Production Co.</Subtitle>
            <Field label="Company">
              <TextField
                fullWidth
                name="prodCo"
                hintText=""
                value={this.state.prodCo || ""}
                onChange={(e, prodCo) => this.setState({prodCo})}
              />
            </Field>
            
            <Field label="Producer">
              <TextField
                fullWidth
                name="prodCoProducer"
                hintText=""
                value={this.state.prodCoProducer || ""}
                onChange={(e, prodCoProducer) => this.setState({prodCoProducer})}
              />
            </Field>
            
            <Field label="Director">
              <TextField
                fullWidth
                name="prodCoDirector"
                hintText=""
                value={this.state.prodCoDirector || ""}
                onChange={(e, prodCoDirector) => this.setState({prodCoDirector})}
              />
            </Field>
            
            <Field label="Email">
              <TextField
                fullWidth
                name="prodCoEmail"
                hintText=""
                value={this.state.prodCoEmail || ""}
                onChange={(e, prodCoEmail) => this.setState({prodCoEmail})}
              />
            </Field>
            
            <Field label="Phone">
              <TextField
                fullWidth
                name="prodCoEmail"
                hintText=""
                value={this.state.prodCoEmail || ""}
                onChange={(e, prodCoEmail) => this.setState({prodCoEmail})}
              />
            </Field>
            
            <Field label="Other Contacts">
              <TextField
                fullWidth
                name="prodCoOther"
                hintText=""
                value={this.state.prodCoOther || ""}
                onChange={(e, prodCoOther) => this.setState({prodCoOther})}
              />
            </Field>
          </Page>
          
        </div>
        
        
        <div className="createpackrequest-nav">
          <Button 
            label="Send Request"
            raised={true}
            primary={true}
            onClick={this.send}
          />
        </div>
        
      </Container>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(CreatePackRequest);