import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  messageScheduledTalents,
} from 'actions';
import { cloudinaryUrl } from 'skybolt-api';

import Dialog from 'ui/Dialog';
import MessageComposer from 'ui/MessageComposer';


export const mapStateToProps = (state, ownProps) => {
  const pack = ownProps.pack;
  const packId = pack.id;
  const agencyId = state.user.agencyId;
  const agency = state.agencies.all[agencyId];
  
  let agencyName, logoUrl;
  
  if(agency) {
    agencyName = agency.name;
    logoUrl = cloudinaryUrl(agency.logo.cloudinary_id, {width:160});
  }
  
  return {
    packId,
    logoUrl,
    agencyName,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  messageScheduledTalents,
};



export class MessageScheduledDialog extends Component {
  
  send = message => {
    this.props.messageScheduledTalents(this.props.packId, message);
    this.props.onRequestClose(); 
  }
  
  render() {
    
    return (
      <Dialog 
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
        bodyStyle={{backgroundColor:"#fafafa"}}>
        
        <MessageComposer
          draftKey={`packscheduled-${this.props.packId}`}
          onCancel={this.props.onRequestClose}
          onSend={this.send}
          title="Message Schedule Talent"
          showToField={false}
          showSubject={true}
          showCannedMessages={false}
          showBCCSelf={false}
          html={`
            <div>
              ${this.props.logoUrl 
                ? `<div style="text-align:center; padding-top:16px; padding-bottom:16px">
                    <img src="${this.props.logoUrl}" alt="${this.props.agencyName}"/>
                  </div>`
                : ""
              }
              <div contenteditable="true" data-name="message"></div>
              <br/>
              <br/>
              <div style="padding:8px 0">
                  <a href="https://beta.skybolt.net/talents/-TALENTID-/pack-schedule/-PACKID-" style="padding:16px 32px; background-color:#eeeeee; text-decoration:none; color:#424242; border-radius:2px;">Confirm your time here.</a>
              </div>
            </div>
          `}
        />
        
      </Dialog>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(MessageScheduledDialog);