import React, { Component } from 'react';
import Large from './Large';
import Dialog from './Dialog';
import Mobile from './Mobile';


export default class Header extends Component {

  state = {
    isLarge:false,
    isDialog:false,
    isMobile:false,
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.checkSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize);
  }

  componentDidMount() {
    this.checkSize();
  }



  checkSize = () => {
    if(!this.el) {
      return;
    }

    const width = this.el.clientWidth;
    const isLarge = width && width > 800;
    const isDialog = width && width <= 800 && width >= 600;
    const isMobile = width && width < 600;

    if(isLarge !== this.state.isLarge || isDialog !== this.state.isDialog || this.state.isMobile !== isMobile) {
      this.setState({isLarge, isDialog, isMobile});
    }
  }



  render() {
    const { style, isLoading, ...props } = this.props;
    const {isLarge, isDialog, isMobile} = this.state;

    const className = isLarge ? 'is-large' :
                      isDialog ? 'is-dialog' :
                      isMobile ? 'is-mobile' :
                      "";

    return (
      <div
        className={`ui-header ${className}`}
        style={style}
        ref={el => this.el = el}>

        {!isLoading && isLarge &&
          <Large key="large" {...props}/>
        }

        {!isLoading && isDialog &&
          <Dialog key="dialog" {...props}/>
        }

        {!isLoading && isMobile &&
          <Mobile key="mobile" {...props}/>
        }

      </div>
    );
  }

}