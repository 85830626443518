import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadFAQs, addFAQ } from 'actions';

import { Redirect } from 'react-router-dom';
import Container from 'ui/Container';
import Title, { Action } from 'ui/Title';
import List from 'ui/List';
import FAQ from './FAQ';
import FAQLI from './FAQLI';

import './index.css';



export function mapStateToProps(state, ownProps) {
  return {
    hasPermission: state.user.permissions.canAdminFAQ,
    faqIds: Object.keys(state.faqs.all),
    ...ownProps,
  };
}

export const mapDispatchToProps = {
  loadFAQs,
  addFAQ,
};


export class FAQAdmin extends Component {

  state = {
    id: null,
  }

  UNSAFE_componentWillMount() {
    this.props.loadFAQs();
  }

  add = () => {
    const faq = this.props.addFAQ();
    this.setState({id:faq.id});
  }

  render() {

    if(!this.props.hasPermission) {
      return <Redirect to="/"/>;
    }

    return (
      <Container>
        <Title
          actions={[
            <Action tooltip="Add Question" onClick={this.add}/>
          ]}>
          FAQ Admin
        </Title>

        <List>
          {this.props.faqIds.map(id => {
            if(id === this.state.id) {
              return <FAQ id={id} key={id}/>;
            }
            return (
              <div key={id} onClick={(e) => this.setState({id})}>
                <FAQLI id={id}/>
              </div>
            );
          })}
        </List>

      </Container>
    );
  }

}


export default connect(mapStateToProps, mapDispatchToProps)(FAQAdmin);