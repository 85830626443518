import React, {useContext} from 'react';
import { useQuery } from 'skybolt-api';
import { Text, Card, Icon, ThemeContext } from 'skybolt-ui';



export default props => {
  const theme = useContext(ThemeContext);
  
  // Load Data
  
  const data = useQuery(`{
    talent(talentId:$talentId) {
      firstName
      middleName
      lastName
      info(public:true)
    }
  }`, {talentId:props.talentId});
  
  if(!data) {
    return <Icon name="loading" style={{margin:"160px auto"}}/>;
  }
  
  
  // Talent Name Field
  
  const name = (
    <div style={{display:'flex', alignItems:'flex-start'}}>
      <Icon name="talent" color="textLight" style={{marginRight:16}}/>
      {data.talent.firstName && <Field label="First" value={data.talent.firstName} style={{marginRight:16}}/>}
      {data.talent.middleName && <Field label="Middle" value={data.talent.middleName} style={{marginRight:16}}/>}
      {data.talent.lastName && <Field label="Last" value={data.talent.lastName}/>}
    </div>
  );
  
  
  // Talent Field Cards
  
  const categories = data.talent.info.reduce((all, field) => {
    const category = field.category;
    if(all.indexOf(category) === -1 && category !== "system") {
      all.push(category);
    }
    return all;
  }, []);
  
  
  // Layout
  
  return (
    <div style={{display:'flex', alignItems:'flex-start', flexWrap:'wrap', padding:16, backgroundColor:theme.color.greyLighter}}>
      <div style={{flex:1, minWidth:300, paddingBottom:32}}>
        {name}
      </div>
      <div style={{flex:1, minWidth:300}}>
        {categories.map(category => 
          <div key={category}>
            <Text small block style={{marginBottom:4, textTransform:'uppercase'}}>{category}</Text>
            <Card style={{padding:16, marginBottom:16, display:'grid', gridTemplateColumns:`repeat(3, 1fr)`, gridGap:8}}>
              {data.talent.info.filter(f => f.category === category).map(field => 
                <Field key={field.id} label={field.name} value={field.value}/>
              )}
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};


const Field = props => (
  <div style={{display:'flex', flexDirection:'row', alignItems:'flex-start', ...props.style}}>
    {props.icon && 
      <div style={{flex:"0 0 auto"}}>
        <Icon name={props.icon} color="textLight"/>
      </div>
    }
    <div style={{flex:"1 0 auto", display:'block'}}>
      {props.label && 
        <Text block small color="textLight" style={{textTransform:'uppercase'}}>{props.label}</Text>
      }
      <Text block>{props.value}</Text>
    </div>
  </div>
);