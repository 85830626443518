import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouter,
} from 'skybolt-ui';


import Approval from './Approval';

export default props => {
  const {match:{path}} = useRouter();
  return (
    <Switch>
      <Route path={`${path}/approve`} component={Approval}/>
      <Redirect to={`/`}/>
    </Switch>
  );
};