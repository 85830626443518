import React, { useState, useContext } from 'react';
import {
  Text,
  Rule,
  Form,
  InputText,
  Button,
  Icon,
  TalentSearch,
  Card,
  TalentGrid,
  ThemeContext,
  LazyLoad,
  useRouter,
} from 'skybolt-ui';

import {
  addTalentToSpotlight,
  removeTalentFromSpotlight,
  useQuery,
} from 'skybolt-api';


export default function SpotlightTalent(props) {

  const {
    match: {
      params: {
        spotlightId,
      }
    },
    history,
  } = useRouter();


  // Talent Search

  const [search, setSearch] = useState({});
  const [limit, setLimit] = useState(40);

  const query = `{
    agency {
      talentFields
      mediaTags
      talentTags
    }
    talents(search:$search, limit:$limit) {
      talentId
      status
      image(width:130, height:130, face:true, filterByTags:$filterByTags)
      name: fullName
      info
      contact
      notes
      tags
      meta
      dateUpdated
      code
      selected(spotlightId:$spotlightId)
    }
    spotlightTalents: talents(spotlightId:$spotlightId) {
      fullName
      image(width:32, height:32, face:true, filterByTags:$filterByTags)
      talentId
    }
  }`;
  const queryVars ={
    search: search,
    limit,
    spotlightId,
    filterByTags: search.applyMediaTags ? search.mediaTags : null,
  };
  const data = useQuery(query, queryVars);


  // Advanced Toggle

  const [advanced, _setAdvanced] = useState(false);
  function toggleAdvanced() {
    const val = !advanced;
    if(!val) {
      setSearch({});
    }
    _setAdvanced(val);
  }


  // Handle Selection

  function handleSelect(sel, talentId) {
    if(sel) {
      addTalentToSpotlight({talentId, spotlightId});
    }
    else {
      removeTalentFromSpotlight({talentId, spotlightId});
    }
  }


  // DOM

  const theme = useContext(ThemeContext);

  if(!data) {
    return (
      <Icon name="loading" size={24} style={{display:"block", margin:"80px auto"}}/>
    );
  }


  // Map talent data with talentFields to create objects consumable by
  // the talent grid.

  return (
    <div>
      <Form
        style={{display:'flex', alignItems:'flex-end', height:46}}
        initialValue={search}
        onChange={val => setSearch(val)}>

        <Text title>TALENT</Text>
        <div style={{flex:1}}/>
        <InputText
          name="query"
          placeholder="Search..."
          clearable
          style={{margin:0, height:38, backgroundColor:'transparent'}}
        />
        <Button
          style={{
            background:"#cccccc",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width:40,
            height:40,
            padding: 0,
            borderRadius: 0,
          }}
          onClick={toggleAdvanced}>
          <Icon name={advanced ? "close" : "tune"} color="white" size={24}/>
        </Button>

      </Form>

      <Rule style={{marginBottom:32}}/>

      {advanced &&
        <Card style={{padding:16, marginBottom:32}}>
          <TalentSearch
            fields={data.agency.talentFields}
            value={search}
            onChange={value => setSearch(value)}
            tags={data.agency.talentTags}
            mediaTags={data.agency.mediaTags}
          />
        </Card>
      }

      <div style={{display:'flex'}}>
        <div style={{flex:'0 0 auto', width:240, paddingRight:16}}>
          {!data.spotlightTalents || !data.spotlightTalents.length ? (
            <div style={{backgroundColor:theme.color.greyLight, padding:"120px 0"}}>
              <Text block centered>No Talent Selected</Text>
            </div>
          ) : (
            <div>
              {data.spotlightTalents.map(talent =>
                <Card key={talent.talentId} style={{display:'flex', alignItems:'center', marginBottom:2}}>
                  <img src={talent.image} alt="headshot" style={{marginRight:8, width:32, height:32}}/>
                  <Text>{talent.fullName}</Text>
                  <div style={{flex:1}}/>
                  <Button icon="close" color="textLight" onClick={() => handleSelect(false, talent.talentId)}/>
                </Card>
              )}
            </div>
          )}
        </div>
        <div style={{flex:1}}>
          <TalentGrid
            talents={data.talents}
            onSelect={handleSelect}
            onClick={(tab, talentId) => {
              history.push({
                pathname:`/talents/${talentId}/${tab}`,
                state:{
                  modal:true
                }
              });
            }}
          />
          <LazyLoad onLoad={() => {
            setLimit(limit+40);
          }}/>
        </div>
      </div>
    </div>
  );
}