/*

The desktop menu.


*/

import React, {useContext, useRef, useState, useLayoutEffect} from 'react';
import { ThemeContext, Route, Flag } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';
import NavButton from './NavButton';
import Talent from './Talent';
import Packs from './Packs';
import Admin from './Admin';
import User from '../User';
import SKYBOLT_LOGO from './logo-white.png';

export default function() {


  // Load data to define what menu items a user can see.
  // Default to a pretty empty object.

  let data = useQuery(`{
    user {
      agencyId
      permissions {
        canOwnTalents
        canPack
        canViewPacks
        canRequestPacks
        canViewTalents
        canAgencyCalendar
        canMessageTalents
        canSeeSeekingAgencyTalent
        canApproveTalents
        canApproveMedia
        canConfigAgency
        canAdminUsers
        canAdminAgencies
        canAddTalents
        canAddCastingDirectors
        canRequestPacks
        canAgencyBilling
        canAdminBilling
        canSpotlight
      }
      agency {
        image(height:40)
      }
      activeDivision
      activeTalent {
        fullName
        talentId
        image(height:60, width:60, face:true)
      }
      talents,
    }
    unreadPacksCount
    unreadMessagesCount
    unapprovedMediaCount
    unapprovedTalentsCount
  }`);

  if(!data) {
    data = {
      user: {
        permissions: {},
        agency: {},
        activeTalent: {},
        talents: [],
      },
    };
  }


  // Keep a reference of where the billing primary nav button is,
  // so we can center center the secondary menu under it.
  const billingButtonRef = useRef();
  const [billingButtonLeft, setBillingButtonLeft] = useState(0);
  useLayoutEffect(() => {
    if(billingButtonRef.current) {
      setBillingButtonLeft( billingButtonRef.current.offsetLeft );
    }
  }, [billingButtonRef.current]);



  // Layout

  const theme = useContext(ThemeContext);

  return (
    <div style={{width:'100vw', backgroundColor:theme.color.primaryDarker, boxShadow:"0 0 4px rgba(0,0,0,0.4)"}}>

      {/* This wrapper box has a relative position and 60px of padding on the right
      to accomodate the user menu. Other navigation will scroll below on smaller screens. */}
      <div style={{minWidth:'100vw', height:60, display:'flex', alignItems:'center'}}>

        {data.user.permissions.canOwnTalents ? (
          <Talent/>
        ) : (
          <div style={{position:'relative'}}>
            <img src={data.user.agency.image || SKYBOLT_LOGO} alt="logo" style={{height:40, padding:10}}/>
            {data.user.activeDivision && <Flag top={40} right={0}>{data.user.activeDivision}</Flag>}
          </div>
        )}

        {(data.user.permissions.canPack || data.user.permissions.canViewPacks) &&
          <Packs/>
        }

        {(data.user.permissions.canPack || data.user.permissions.canViewTalents) &&
          <NavButton style={{padding:'24px 16px 20px'}} href="/talent-list">Talent</NavButton>
        }

        {(data.user.permissions.canPack || data.user.talents.length > 0) &&
          <NavButton style={{padding:'24px 16px 20px'}} href="/notices">Notices</NavButton>
        }

        {(data.user.permissions.canAgencyCalendar || data.user.talents.length > 1) &&
          <NavButton style={{padding:'24px 16px 20px'}} href="/calendar">Calendar</NavButton>
        }

        {(data.user.permissions.canMessageTalents || data.user.talents.length > 1) &&
          <NavButton style={{padding:'24px 16px 20px'}} href="/messages">
            Messages <Flag top={8} right={0}>{data.unreadMessagesCount}</Flag>
          </NavButton>
        }

        {(data.user.permissions.canSeeSeekingAgencyTalent) &&
          <NavButton style={{padding:'24px 16px 20px'}} href="/seeking-agency">Seeking Rep.</NavButton>
        }

        {(data.user.permissions.canAgencyBilling || data.user.permissions.canAdminBilling) &&
          <NavButton style={{padding:'24px 16px 20px'}} href="/billing" ref={billingButtonRef}>Billing</NavButton>
        }

        {(
          data.user.agencyId && (
            data.user.permissions.canApproveTalents ||
            data.user.permissions.canApproveMedia ||
            data.user.permissions.canConfigAgency ||
            data.user.permissions.canAdminUsers ||
            data.user.permissions.canAdminAgencies ||
            data.user.permissions.canAddTalents ||
            data.user.permissions.canAddCastingDirectors ||
            data.user.permissions.canSpotlight
          )
        ) &&
          <Admin/>
        }

        <div style={{flex:1}}/>

        {data.user.permissions.canRequestPacks &&
          <NavButton style={{padding:'24px 16px 20px'}} href="/casting-request">Create Casting Request</NavButton>
        }

        <User/>

      </div>


      {/* Billing Menu */}

      <Route path="/billing" render={() => {

        return (
          <div style={{boxSizing:'border-box', minWidth:'100vw', height:40, borderTop:'1px solid #aaa', display:'flex', alignItems:'center', paddingLeft:billingButtonLeft-200}}>
            <NavButton style={{padding:'12px 16px 12px'}} href="/billing/clients">Clients</NavButton>
            <NavButton style={{padding:'12px 16px 12px'}} href="/billing/invoices">Invoices</NavButton>
            <NavButton style={{padding:'12px 16px 12px'}} href="/billing/accounts-receivable">Accounts Receivable</NavButton>
            <NavButton style={{padding:'12px 16px 12px'}} href="/billing/accounts-payable">Accounts Payable</NavButton>
          </div>
        );
      }}/>


    </div>
  );
}
