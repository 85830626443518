import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { get as getProperty } from 'lodash';
import {
  loadUserEvents,
} from 'actions';

import Container from 'ui/Container';
import Title, { Tab, Checkbox, Filter, Action } from 'ui/Title';
import EventCalendar from 'ui/EventCalendar';
import EventDialog from 'ui/EventDialog';




const mapStateToProps = (state, ownProps) => {
  let events = state.events.byUser[ state.user.id ] || {};
  const path = ownProps.match.path;
  const url = ownProps.match.url;
  const canViewUserCalendar = getProperty(state, 'user.permissions.canEditTalents', false);

  const agencyId = _.get(state, 'user.agencyId', null);
  const agency = _.get(state, `agencies.all[${agencyId}]`, null);

  return {
    agency,
    events,
    path,
    url,
    canViewUserCalendar,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  loadUserEvents,
};





class Calendar extends Component {

  state = {
    date: moment(),
    event: null,
    isDialogOpen: false,
    showEventTypes: [],
  }

  UNSAFE_componentWillMount() {
    this.loadEvents();
    if(this.props.date) {
      this.setState({date:this.props.date});
    }
  }


  loadEvents = () => {
    const date = this.state.date;
    this.props.loadUserEvents({
      fromDate: date.startOf('month').valueOf(),
      toDate: date.endOf('month').valueOf()
    });
  }

  nextMonth = () => {
    this.setState({date: this.state.date.add(1, 'month')}, this.loadEvents);
  }

  prevMonth = () => {
    this.setState({date: this.state.date.subtract(1, 'month')}, this.loadEvents);
  }

  openDialog = event => {
    this.setState({isDialogOpen:true, event});
  }

  closeDialog = () => {
    this.setState({isDialogOpen:false});
  }

  createEvent = () => {
    this.setState({isDialogOpen:true, event:{}});
  }


  render() {

    let filters = [];
    if(this.props.canViewUserCalendar) {
      filters = [
        <Filter 
          key="type"
          label="Types"
          value={this.state.showEventTypes}
          options={[
            {label:"All Package Events", value:"ALL_PACK"},
            {label:"All Unavailable", value:"ALL_UNAVAILABLE"},
            ...Object.values(this.props.agency?.eventTypes || {})
              .map(type => ({label:type.label, value:type.label})),
          ]}
          optionValueKey="value"
          optionDisplayKey="label"
          onChange={showEventTypes => this.setState({showEventTypes})}
        />,
        <Action icon="plus" onClick={this.createEvent}/>
      ];
    }

    const tabs = [
      <Tab
        isActive={true}
        label="Calendar"
      />,
      <Tab
        to="schedule"
        label="Schedule"
      />
    ];

    const events = Object.keys(this.props.events || {})
      .map(id => ({...this.props.events[id], id}))
      .filter(event => {
        if(!this.state.showEventTypes.length) {
          return true;
        }
        if(event.packId) {
          if(this.state.showEventTypes.indexOf("ALL_PACK") !== -1) {
            return true;
          }
          if(this.state.showEventTypes.indexOf(event.type) !== -1) {
            return true;
          }
        }
        else {
          if(this.state.showEventTypes.indexOf("ALL_UNAVAILABLE") !== -1) {
            return true;
          }
        }
        return false;
      });

    return (
      <Container style={{paddingBottom:40}}>

        <Title
          filters={filters}
          onNext={this.nextMonth}
          onPrev={this.prevMonth}
          tabs={tabs}>
          {moment(this.state.date).format("MMMM, YYYY")}
        </Title>

        <EventCalendar
          className="calendar2-calendar"
          date={this.state.date}
          events={events}
          agency={this.props.agency}
          onClickEvent={this.openDialog}
          onClickDay={this.props.onClickDay}
        />

        <EventDialog
          open={this.state.isDialogOpen}
          onRequestClose={this.closeDialog}
          event={this.state.event}
        />

      </Container>
    );
  }

}


export default connect(mapStateToProps, mapDispatchToProps)(Calendar);