import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isString, isNumber, isEmpty, min, max, get } from 'lodash';
import moment from 'moment';
// import { mediaFormats } from '../../config';
import { Icon as UIIcon, Text } from 'skybolt-ui';

import muiThemeable from 'material-ui/styles/muiThemeable';

import "./index.css";

import Icon from 'ui/Icon';
import Paper from 'material-ui/Paper';
import IconButton from 'material-ui/IconButton';
import SelectedIcon from 'material-ui/svg-icons/action/thumb-up';
import EventIcon from 'material-ui/svg-icons/action/event';
import Media from 'ui/Media';

function formatPhoneNumber(s) {
  var s2 = (""+s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? s : "(" + m[1] + ") " + m[2] + "-" + m[3];
}

function getTalentImage(talent) {
  if(talent.media) {
    const image = Object.keys(talent.media)
      .map(id => talent.media[id])
      // .sort((a, b) => (get(a, 'orderByRole.agent') || a.order) < (get(b, 'orderByRole.agent') || b.order) ? -1 : 1)
      .sort((a, b) => a.order < b.order ? -1 : 1)
      .find(media => media.type !== 'video');
      // .find(media => !media.format || mediaFormats.indexOf(media.format) !== -1);

    if(image && image.cloudinary_id) {
      return image;
    }
  }

  if(talent.image) {
    return talent.image;
  }

  return 'avatar';
}

function renderTalentSmall(props) {
  let {
    className,
    packId,
    eventId,
    talentId,
    talent,
    tab,
    actions,
    event,
    muiTheme:{ palette },
    isShortlist,
    onEventClick,
  } = props;

  const image = getTalentImage(talent);

  const link = !!tab
    ? {pathname:`/talents/${talentId || talent.id}/${tab}`, state:{modal:true, packId, eventId, isShortlist}}
    : {pathname:`/talents/${talentId || talent.id}/info`, state:{modal:true, packId, eventId, isShortlist}};

  return (
    <div className={`talentcard-wrapper ${className}`}>
      <Paper zDepth={1} className={`talentcard talentcard-small ${className}`}>
        <Link to={link} className="talentcard-small-image-wrapper">
          <Media
            media={image}
            width={36}
            height={36}
            crop="thumb"
            face
            className="talentcard-small-image"
          />
        </Link>
        <Link to={link} className={`talentcard-small-name`}>
          {talent.firstName} {talent.lastName}
        </Link>
        {actions &&
          <div className="talentcard-small-actions">
            {actions}
          </div>
        }
        {event &&
          <IconButton
            tooltip={
              event.status === 'confirmed' ? "Confirmed" :
              event.status === 'declined' ? "Declined" :
              "Unconfirmed"
            }
            tooltipPosition="top-center"
            style={{
              width:36,
              height:36,
              padding:0,
            }}
            onClick={onEventClick}>

            <EventIcon
              className={`talentcard-event-icon ${event.status === 'confirmed' ? 'talentcard-event-icon-confirmed' : ''}`}
              color={
                event.status === 'confirmed' ? palette.accent700 :
                event.status === 'declined' ? palette.primary100 :
                palette.primary400
              }
            />

          </IconButton>
        }
      </Paper>
    </div>
  );
}

function renderTalentMedium(props) {

  let {
    agency,
    className,
    talent,
    talentId,
    muiTheme: { palette },
    isSelected,
    onSelect,
    checkTooltip,
    packId,
    eventId,
    style,
    cardStyle,
    isShortlist,
    fields,
  } = props;

  if(!talent) {
    return null;
  }

  const infoLink = {pathname:`/talents/${talentId || talent.id}/info`, state:{modal:true, packId, eventId, isShortlist}};
  const mediaLink = {pathname:`/talents/${talentId || talent.id}/media`, state:{modal:true, packId, eventId, isShortlist}};
  const commentsLink = {pathname:`/talents/${talentId || talent.id}/comments`, state:{modal:true, packId, eventId, isShortlist}};

  let {
    firstName, 
    lastName, 
    dob,
    comments,
  } = talent;


	let smush = [];

	if(talent.dob && agency.showTalentDOBPublic !== false && (!agency.talentFields || !agency.talentFields.dob || agency.talentFields.dob.isPublic)) {
	  const years = moment().diff(dob, 'years');
	  let age = years + ' yrs';
		if(years < 3) {
			age += ' ' + moment().diff(dob, 'months') % 12 + ' mo';
		}
		smush.push(age);
	}

	for(const fieldId in fields) {
	  const field = fields[fieldId];

	  if(!field.isVisibleOnCard) {
	    continue;
	  }

	  if(!talent[fieldId]) {
	    continue;
	  }

	  if(field.category === 'system') {
	    continue;
	  }

	  if(fieldId === 'workExp' || fieldId === 'workexp' || fieldId === 'contractexp' || fieldId === 'unions') {
	    continue;
	  }

	  let val = talent[fieldId];
	  const name = field.name.slice(0, 10) || "";
	  const units = field.units || "";

	  if(fieldId === 'height') {
	    smush.push(`${name} ${Math.floor(val / 12)}'${val % 12}"`.trim());
	  }

	  else if(field.type === 'range' && min(val) && max(val) && min(val) !== max(val)) {
	    smush.push(`${name} ${min(val)}-${max(val)}`);
	  }

	  else if(field.type === 'range' && min(val) && max(val)) {
	    smush.push(`${name} ${min(val)}`);
	  }

	  else if(field.type === 'date' && moment(val).isValid()) {
	    smush.push(`${name} ${moment(val).format('MM/DD/YYYY')}`);
	  }

	  else if(Array.isArray(val)) {
	    smush.push(`${name} ${val.join(' ')} ${units}`.trim());
	  }

	  else if(isString(val)) {

      // Don't show full urls.
      let exp = new RegExp("[https:\/\/\.]?[instagram|facebook]\.com\/([^\/\?]+).*", "m");
      let m = val.match(exp);
      if(m) {
        val = m[1];
      }

      val = val.slice(0, 25);

	    smush.push(`${name} ${val}`.trim());
	  }

    else if(isNumber(val)){
      smush.push(`${name} ${val} ${units}`.trim());
    }
	}

	if(talent.isSeekingAgency) {
	  smush.push(`${talent.city || ""} ${talent.state || ""}`.trim());
	}

	smush = smush.join(', ').replace(/,[^/S]/g, ', ');

  const image = getTalentImage(talent);

  const imageCount = Object.keys(talent.media || {}).filter(id => talent.media[id].type === 'image').length;
  const videoCount = Object.keys(talent.media || {}).filter(id => talent.media[id].type === 'video').length;
  const documentCount = Object.keys(talent.media || {}).filter(id => talent.media[id].type === 'document').length;

  return (
    <div className={`talentcard-wrapper ${className}`} style={style}>
      <div className="talentcard talentcard-medium" style={cardStyle}>
        <Link to={mediaLink} className="talentcard-medium-image-wrapper">
          <Media
            media={image}
            icon="talent"
            width={300}
            height={300}
            crop="fill"
            face
            className="talentcard-medium-image"
          />
        </Link>
        <div className="talentcard-medium-info-wrapper">
          <Link to={infoLink} className="talentcard-medium-name">
            {firstName} {lastName}
          </Link>
          <Link to={infoLink} className="talentcard-medium-info">
            {smush}
          </Link>
          <Link to={commentsLink} className="talentcard-medium-notes" style={{color:palette.accent700}}>
            {comments || ""}
          </Link>
          <Link to={mediaLink}>
            <Text>
              {!!imageCount && <Text color="#aaaaaa"><UIIcon name="image" size={12}/> {imageCount} &nbsp;&nbsp;</Text>}
              {!!videoCount && <Text color="#aaaaaa"><UIIcon name="film" size={12}/> {videoCount} &nbsp;&nbsp;</Text>}
              {!!documentCount && <Text color="#aaaaaa"><UIIcon name="file" size={12}/> {documentCount} &nbsp;&nbsp;</Text>}
            </Text>
          </Link>
        </div>
      </div>
      {!!onSelect &&
        <div
          className="talentcard-check"
          style={{
            backgroundColor: isSelected ? palette.accent1Color : palette.canvasColor,
          }}>
          <IconButton
            onClick={e => onSelect(e, talentId || talent.id)}
            tooltip={checkTooltip}
            tooltipPosition="top-center"
            style={{
              padding:0,
              width: 40,
              height: 40,
            }}
            iconStyle={{width:16, height:16}}>
            {isSelected ? (
              <SelectedIcon color={palette.canvasColor}/>
            ) : (
              <div></div>
            )}
          </IconButton>
        </div>
      }
    </div>
  );
}

function renderTalentLarge(props) {
  let {
    className,
    talent,
    talentId,
    muiTheme: { palette },
    isSelected,
    isAltSelected,
    onSelect,
    checkTooltip,
    packId,
    eventId,
    unreadMessages,
    isShortlist,
    fields,
    onNewMessageClick,
    showAgency,
    agency,
    basePath="",
  } = props;

  const state = {
    modal:true,
    packId,
    eventId,
    isShortlist,
  };

  const infoLink = {pathname:`${basePath}/talents/${talentId || talent.id}/info`, state};
  const mediaLink = {pathname:`${basePath}/talents/${talentId || talent.id}/media`, state};
  const notesLink = {pathname:`${basePath}/talents/${talentId || talent.id}/notes`, state};
  const messagesLink = {pathname:`${basePath}/talents/${talentId || talent.id}/messages`, state};
  const eventsLink = {pathname:`${basePath}/talents/${talentId || talent.id}/calendar`, state};

  // Add highlighted values if they exist.
  const highlighted = field => {
    let value = talent[field];

    if(!!value && talent._highlightResult && talent._highlightResult[field] && !!talent._highlightResult[field].matchedWords) {
      talent._highlightResult[field].matchedWords.forEach(word => {
        const pattern = new RegExp(`(${word})`, 'gi');
        value = value.replace(pattern, '<em>$1</em>');
      });
    }

    return value;
  };

  let {
    // built in talent fields
    firstName,
    lastName,
    dob,
    contactName,
    notes,
    phones,
    unions,
    status,
    tags,
    upcomingEvents,

    // optional talent fields
    // Note: if the agent deletes then tries to re-add these, we'll lose the
    // keys. Need a better way of building the smush.
    contractExp,
  } = talent;

  let workExp = talent['workexp'] || talent['workExp'];

  firstName = highlighted('firstName');
  lastName = highlighted('lastName');
  contactName = highlighted('contactName');
  notes = highlighted('notes');

	let smush = [];

	if(talent.dob) {
	  const years = moment().diff(dob, 'years');
	  let age = years + ' yrs';
		if(years < 3) {
			age += ' ' + moment().diff(dob, 'months') % 12 + ' mo';
		}
		smush.push(age);
	}

	// check date field updated
  let dateUpdated = 0;
  for(let fieldId in (talent.dateFieldUpdated || {})) {
    const date = get(talent, `dateFieldUpdated.${fieldId}.date`,  0);
    if(date > dateUpdated) {
      dateUpdated = date;
    }
  }
  if(!dateUpdated) {
    dateUpdated = talent.dateUpdated;
  }
  dateUpdated = moment(dateUpdated).format("MM/DD/YYYY");

	Object.keys(fields)
	  .map(id => ({...fields[id], id}))
	  .sort((a, b) => !a.order || a.order < b.order ? -1 : 1)
	  .forEach(field => {
      const fieldId = field.id;

  	  if(!field.isVisibleOnCard) {
  	    return;
  	  }

  	  if(!talent[fieldId]) {
  	    return;
  	  }

  	  if(field.category === 'system') {
  	    return;
  	  }

  	  if(fieldId === 'workExp' || fieldId === 'workexp' || fieldId === 'contractExp' || fieldId === 'unions') {
  	    return;
  	  }

  	  let val = talent[fieldId];
  	  const name = field.name.slice(0, 10) || "";
  	  const units = field.units || "";

  	  if(field.isFeet) {
  	    smush.push(`${name} ${Math.floor(val / 12)}'${val % 12}"`.trim());
  	  }

  	  else if(field.type === 'range' && min(val) && max(val) && min(val) !== max(val)) {
  	    smush.push(`${name} ${min(val)}-${max(val)}`);
  	  }

  	  else if(field.type === 'range' && min(val) && max(val)) {
  	    smush.push(`${name} ${min(val)}`);
  	  }

  	  else if(Array.isArray(val)) {
  	    smush.push(`${name} ${val.join(' ')} ${units}`.trim());
  	  }

  	  else if(field.type === 'date') {
  	    smush.push(`${name} ${moment(val).format('MM/DD/YYYY')}`);
  	  }

  	 else if(isString(val) || isNumber(val)){
  	    smush.push(`${name} ${val} ${units}`.trim());
  	  }
  	});

	smush = smush.join(', ').replace(/,[^/S]/g, ', ');

  const isInactive = status === 'EXPIRED' || status === 'INACTIVE' || status === 'DELETED';

  return (
    <div className={`talentcard-wrapper ${isInactive ? 'talentcard-grayscale' : ''} ${className}`}>
      <Paper
        zDepth={isNumber(props.zDepth) ? props.zDepth : 1}
        className="talentcard talentcard-large">

        <div className="talentcard-large-row">
          <Link to={mediaLink} className="talentcard-large-image-wrapper">
            <Media
              media={talent.image}
              width={90}
              height={130}
              crop="fill"
              face
              greyscale={isInactive}
              className="talentcard-large-image"
              icon="user"
            />
          </Link>

          <div className="talentcard-large-info-wrapper">
            <div className="talentcard-large-row">
              <Link to={infoLink} className="talentcard-large-name">
                <span dangerouslySetInnerHTML={{__html:firstName}}/>&nbsp;
                <span dangerouslySetInnerHTML={{__html:lastName}}/>
              </Link>
              <div className="talentcard-large-actions">
                {!!onNewMessageClick &&
                  <Icon
                    className="talentcard-large-action"
                    name="message"
                    size={20}
                    isReversed={true}
                    onClick={onNewMessageClick}
                  />
                }
              </div>
            </div>
            {!!showAgency &&
              <div className="talentcard-large-agency">
                {agency.name}
              </div>
            }
            <Link to={infoLink} className="talentcard-large-info">
              {smush}
            </Link>
            {!!phones &&
              <Link to={infoLink} className="talentcard-contact">
                <div className="talentcard-contact-name"><span dangerouslySetInnerHTML={{__html:contactName}}/></div>
          			<div className="talentcard-contact-items">
        					{Object.keys(phones)
        					  .filter(key => !!phones[key])
        					  .slice(0, 2)
        					  .map(key => phones[key])
        					  .filter(phone => !!phone.label && !!phone.number)
          					.map((phone, i) =>
          						<div key={phone.number + i} className="talentcard-contact-item">
          		  				<span className="talentcard-contact-label">{phone.label}</span>
          		  				<span className="talentcard-contact-value">{formatPhoneNumber(phone.number)}</span>
          		  			</div>
          					)
        					}
        				</div>
              </Link>
            }
          </div>
        </div>

        {!!tags &&
          <Link to={infoLink} className="talentcard-tags" style={{color:palette.primary1Color}}>
            {Object.keys(tags).map(key => tags[key]).join(', ')}
          </Link>
        }

        <Link
          to={notesLink}
          className={`talentcard-notes`}
          style={{color:palette.primary1Color}}>
          <span dangerouslySetInnerHTML={{__html:notes}}/>
        </Link>

        {!isEmpty(unreadMessages) && (
          <div className="talentcard-messages">
            {Object.keys(unreadMessages)
              .map(id => unreadMessages[id])
              .sort((a, b) => a.date > b.date ? -1 : 1)
              .slice(0, 3)
              .map(message =>
                <Link
                  key={message.id}
                  to={messagesLink}
                  className="talentcard-message">
                  <Paper>
                    <div style={{display:'flex', padding:'2px 8px 2px 8px'}}>
                      <div className="talentcard-messages-body">{message.body}</div>
                      <div className="talentcard-messages-date">{moment(message.date).fromNow()}</div>
                    </div>
                  </Paper>
                </Link>
              )
            }

            {Object.keys(unreadMessages).length > 3 &&
              <Link to={messagesLink} className="talentcard-messages-flag" style={{color:palette.accent1Color}}>
                and {Object.keys(unreadMessages).length - 3} more...
              </Link>
            }
          </div>
        )}

        {!!upcomingEvents &&
          <Link to={eventsLink} className="talentcard-events" style={{color:palette.accent900}}>
            {Object.keys(upcomingEvents)
              .map(id => ({...upcomingEvents[id], id}))
              .filter(event => moment(event.endDate).isAfter(moment().startOf('day')))
              .sort((a, b) => a.date < b.date ? -1 : 1)
              .slice(0, 5)
              .map(event => {
                const date = moment(event.date);

                let dateString = date.format("MM/DD");
                if(event.endDate !== event.date) {
                  dateString += ` - ${moment(event.endDate).format("MM/DD")}`;
                }

                let desc = "";
                if(event.packName) {
                  desc += ` ${event.packName}`;
                }
                else {
                  desc += ` ${event.notes}`;
                }

                return (
                  <span key={event.id} className="talentcard-events-event">
                    <span className="talentcard-events-event-date">{dateString}</span>
                    <span className="talentcard-events-event-desc">{desc}</span>
                  </span>
                );
              })
            }
          </Link>
        }

        <div className="talentcard-meta">

          <span>{talent.code || ""}</span>

          {fields['unions'] && fields['unions'].isVisibleOnCard && Array.isArray(unions) && unions.length > 0 && <span>{unions.join(', ')}</span>}

          <span>{dateUpdated}</span>

          {/*!!dateFieldUpdated && Object.keys(fields)
            .filter(fieldId => fields[fieldId].trackChanges)
            .map(fieldId => {
              if(!dateFieldUpdated[fieldId]) {
                return null;
              }
              const name = dateFieldUpdated[fieldId].name;
              const date = moment(dateFieldUpdated[fieldId].date).format("MM/DD/YYYY");
              return <span key={fieldId}>{name} {date}</span>;
            })
          */}

          {!!workExp && fields['workExp'] && fields['workExp'].isVisibleOnCard &&
            <span
              style={{
                color: moment(workExp).isBefore(moment()) ? palette.warningTextColor : 'inherit',
              }}>
              {moment(workExp).format("[WP exp.] MM/DD/YYYY")}
            </span>
          }

          {!!workExp && fields['workexp'] && fields['workexp'].isVisibleOnCard &&
            <span
              style={{
                color: moment(workExp).isBefore(moment()) ? palette.warningTextColor : 'inherit',
              }}>
              {moment(workExp).format("[WP exp.] MM/DD/YYYY")}
            </span>
          }

          {!!contractExp && fields['contractExp'] && fields['contractExp'].isVisibleOnCard &&
            <span
              style={{
                color: moment(contractExp).isBefore(moment()) ? palette.warningTextColor : 'inherit',
              }}>
              {moment(contractExp).format("[ce.] MM/DD/YYYY")}
            </span>
          }

          {status !== 'PAID' &&
            <span
              style={{
                color: status !== 'TRIAL' ? palette.warningTextColor : 'inherit',
              }}>
              {status}
            </span>
          }

          {agency.showTalentPlanOnCard && talent.plan &&
            <span>{talent.plan.name}</span>
          }

        </div>

      </Paper>

      {!!onSelect && (talent.status === 'PAID' || talent.status === 'TRIAL') &&
        <div
          className="talentcard-check"
          style={{
            backgroundColor: isSelected ? palette.accent1Color :
                             isAltSelected ? palette.grey300 :
                             palette.canvasColor,
          }}>
          <IconButton
            onClick={e => onSelect(e, talentId || talent.id)}
            tooltip={checkTooltip}
            tooltipPosition="top-center"
            style={{
              padding:0,
              width: 40,
              height: 40,
            }}
            iconStyle={{width:16, height:16}}>
            {isSelected || isAltSelected ? (
              <SelectedIcon color={palette.canvasColor}/>
            ) : (
              <div></div>
            )}
          </IconButton>
        </div>
      }
    </div>
  );
}


const TalentCard = muiThemeable()(props => {
  if(props.view === 'small') {
    return renderTalentSmall(props);
  }
  if(props.view === 'medium') {
    return renderTalentMedium(props);
  }
  if(!props.canView) {
    return renderTalentMedium(props);
  }
  return renderTalentLarge(props);
});

const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.talentId || ownProps.talent.id;
  const talent = ownProps.talent || state.talents.all[talentId];
  const agencyId = talent.agencyId || state.user.agencyId;
  const agency = state.agencies.all[agencyId] || {};
  const fields = agency.talentFields || {};

  const canView = talent.agencyId === state.user.agencyId || state.user.permissions.canAdminTalents;

  return {
    ...ownProps,
    canView,
    talent,
    agency,
    fields,
    unreadMessages: state.unreadMessages.byTalent[talentId],
  };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentCard);

