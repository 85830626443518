import React, { useContext, useState, useEffect, useRef } from 'react';
import { InputText, FormContext, Button, ThemeContext, Menu } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';


// With a pack id supplied by the containing form, show the pack fullName
// and update the form when the input is changed.
export default function InputPack(props) {
  const form = useContext(FormContext);
  const packId = form.get('packId');
  const packName = form.get('packName');
  const packImage = form.get('packImage');

  const [query, setQuery] = useState("");

  const data = useQuery(`{
    search: packs(query:$query, limit:5) {
      packId
      name
      image(width:150, height:150, face:true)
    }
  }`, {query});

  const emitChange = pack => {
    form.update({
      packName: pack.name,
      packId: pack.packId,
      packImage: pack.image
    });
    if(props.onChange) {
      props.onChange(pack);
    }
  };

  useEffect(() => {
    if(packName) {
      setQuery(packName);
    }
  }, [packName]);

  const theme = useContext(ThemeContext);

  const containerRef = useRef();
  const inputRef = useRef();
  const [focused, setFocused] = useState(false);



  return (
    <div style={{display:'flex', alignItems:'flex-start', justifyContent:'stretch', marginBottom:16, ...props.style}} ref={containerRef} className={props.className}>
      {packImage && (
        <img src={packImage} alt="Headshot" style={{width:46, height:46, marginRight:4}}/>
      )}
      <InputText
        value={query}
        onChange={setQuery}
        style={{flex:1, marginBottom:0}}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        ref={inputRef}
        title={props.title}
        hint={props.hint}
      />
      <Menu key="menu" open={query && data && data.search.length && focused} anchor={containerRef} onRequestClose={() => inputRef.current.blur()}>
        {data && data.search && data.search.map(pack =>
          <Button
            key={pack.packId}
            style={{
              display: 'block',
              minWidth: 80,
              backgroundColor: pack.packId === packId ? theme.color.accent : 'transparent',
              borderRadius: 0,
            }}
            onMouseDown={e => e.preventDefault()}
            onClick={e => emitChange(pack)}>
            {pack.name}
          </Button>
        )}
      </Menu>
    </div>
  );

}