import React, { useState } from 'react';
import { InputSelect } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';


// With a talent id supplied by the containing form, show the talent fullName
// and update the form when the input is changed.
export default function InputTalent(props) {

  const [query, setQuery] = useState("");

  const data = useQuery(`{
    search: talents(search:$search, limit:5, status:null) {
      value: talentId
      label: fullName
    }
  }`, {search:{query}});

  return (
    <InputSelect
      onRule
      placeholder="Talent..."
      options={data ? data.search : []}
      clearable="All"
      value={props.value}
      onChange={props.onChange}
      strict
      onSearch={setQuery}
    />
  );

}