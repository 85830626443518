import React from 'react';
import Paper from 'material-ui/Paper';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import skyboltLogo from './assets/logo.png';
import "./index.css";

export default () => {
  return (
    <MuiThemeProvider>
      <div className="browserupgrade-wrapper">
        <Paper 
          zDepth={1} 
          className="browserupgrade-paper">
          
          <div className="browserupgrade-logo-wrap">
            <img src={skyboltLogo} alt="logo" className="login-logo"/>
          </div>
          
          
          <div className="browserupgrade-title">
            Your web browser isn't supported.
          </div>
          
          <a className="browserupgrade-link" href="http://outdatedbrowser.com/en">Please upgrade your browser here.</a>
        </Paper>
      </div>
    </MuiThemeProvider>
  );
};