import React, { useCallback } from 'react';
import {
  useRouter,
  Text,
  Container,
  Currency,
  Rule,
  InputHTML,
  Button,
  Card,
  Loading,
  Icon,
  Modal,
} from 'skybolt-ui';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import { useQuery, updateInvoice, createInvoice, createDebits, updateDebit } from 'skybolt-api';
import SendModalButton from '../../components/SendModalButton';

export default function(props) {

  const {
    match: {
      params:{
        invoiceId
      }
    }
  } = useRouter();


  const data = useQuery(`{
    agency {
      agencyId
      timezone
      billingOrgId
      invoice(invoiceId:$invoiceId) {
        agencyId
        status
        invoiceNumber
        clientId,
        client {
          clientId
          name
        }
        packId
        packName
        packImage
        vendorNumber
        poNumber
        date
        dateSent
        datePaid
        dateDue
        jobType
        comments
        notes
        total
        agencyFeeTotal
        commissionTotal
        subtotal
        lineItems {
          date
          talentId
          talentName
          talentImage
          comment
          type
          timeFrom
          timeTo
          hours
          rate
          commission
          total
        }
        pendingDebits: debits(status:"PENDING") {
          debitId
          status
        }
        credits
      }
    }
  }`, {invoiceId});

  let timezone = data?.agency?.timezone || moment.tz.guess();

  let lineItems = [];
  if(data && Array.isArray(data.agency.invoice.lineItems)) {
    lineItems = data.agency.invoice.lineItems;
  }

  const debouncedUpdateInvoice = useCallback(debounce(update => {
    updateInvoice({invoiceId, update});
  }, 500), [invoiceId]);

  async function markPaid() {
    if(!await Modal.confirm(`Would you like to mark this invoice as paid?`)) {
      return;
    }
    await updateInvoice({invoiceId, update:{status:"PAID", datePaid:Date.now()}});

    for(let debit of data.agency.invoice.pendingDebits) {
      await updateDebit({debitId:debit.debitId, update:{status:"DRAFT"}});
    }
  }

  async function markVoid() {
    if(data.agency.invoice.credits && data.agency.invoice.credits.length > 0) {
      await Modal.alert("You cannot void an invoice that has existing payments.");
      return;
    }

    const confirm = await Modal.confirm("Are you sure? This invoice will be marked as void. This cannot be undone.");
    if(!confirm) {
      return;
    }

    await updateInvoice({
      invoiceId,
      update: {
        status: "VOID"
      }
    });

    for(let debit of data.agency.invoice.pendingDebits) {
      await updateDebit({debitId:debit.debitId, update:{status:"DELETED"}});
    }
  }

  async function duplicate() {
    const confirm = await Modal.confirm("Are you sure? This will create a new invoice.");
    if(!confirm) {
      return;
    }

    let dup = await createInvoice({
      status: "DRAFT",
      date: data.agency.invoice.date,
      dateDue: data.agency.invoice.dateDue,
      clientId: data.agency.invoice.clientId,
      poNumber: data.agency.invoice.poNumber,
      vendorNumber: data.agency.invoice.vendorNumber,
      jobType: data.agency.invoice.jobType,
      rate: data.agency.invoice.rate,
      agencyFee: data.agency.invoice.agencyFee,
      comments: data.agency.invoice.comments,
      subtotal: data.agency.invoice.subtotal,
      agencyFeeTotal: data.agency.invoice.agencyFeeTotal,
      commissionTotal: data.agency.invoice.commissionTotal,
      total: data.agency.invoice.total,
      lineItems: data.agency.invoice.lineItems,
    });

    props.history.replace("/billing/invoices/"+dup.invoiceId);
  }



  // Render

  return (
    <Container className="billinginvoiceview">

      <div style={{display:"flex", alignItems:"flex-end", height:40, paddingTop:32}}>
        <Text title>Invoice</Text>


        {data &&
          <Text
            style={{
              display: 'inline-block',
              marginLeft: 16,
              fontWeight: 'bold',
              lineHeight: '26px',
              color: data.agency.invoice.status === "PAID" ? "green" :
                     data.agency.invoice.status === "POSTED" ? "orange" :
                     data.agency.invoice.status === "OVERDUE" ? "red" :
                     "#aaaaaa"
            }}>
            {data.agency.invoice.status}
          </Text>
        }

        <div style={{flex:1}}/>

        {/*
        <Button title href="edit">
          <Icon name="pencil-alt"/>
        </Button>
        */}

        {data && data.agency.invoice.status !== "VOID" &&
          <Button label="Void" onClick={markVoid} color="textLight"/>
        }

        {/*data && data.agency.invoice.status !== "PAID" && data.agency.invoice.status !== "VOID" &&
          <Button label="Mark Paid" onClick={markPaid} color="textLight"/>
        */}

        <Button label="Duplicate" onClick={duplicate} color="textLight"/>

        {/* If there's no linking to bill.com, give users the option to download a pdf version of the invoice. */}
        {/*{data && !data.agency.billingOrgId &&*/}
          <a style={{display:'block'}} href={`https://us-central1-skybolt-99adb.cloudfunctions.net/billingInvoicePDF?invoiceId=${invoiceId}&agencyId=${data && data.agency.agencyId}`} download={true} target="_blank" rel="noopener noreferrer">
            <Button title>
              <Icon name="download" color="white"/>
            </Button>
          </a>
        {/*}*/}
        <SendModalButton/>
      </div>
      <Rule style={{marginBottom:24}}/>

      {!data ? (
        <Loading/>
      ) : (
        <Card className="card">
          <div className="info">

            <div className="infodetails">
              <Button href={`/billing/clients/${data.agency.invoice.client.clientId}`} style={{padding:0}}>
                <Text block small>Client</Text>
                <Text large block style={{marginRight:8}}>{data.agency.invoice.client.name}</Text>
              </Button>

              {data.agency.invoice.invoiceNumber &&
                <div>
                  <Text block small>Invoice Number</Text>
                  <Text large block>{data.agency.invoice.invoiceNumber}</Text>
                </div>
              }

              {data.agency.invoice.vendorNumber &&
                <div>
                  <Text block small>Vendor Number</Text>
                  <Text large block>{data.agency.invoice.vendorNumber}</Text>
                </div>
              }

              {data.agency.invoice.poNumber &&
                <div>
                  <Text block small>PO Number</Text>
                  <Text large block>{data.agency.invoice.poNumber}</Text>
                </div>
              }

              {data.agency.invoice.jobType &&
                <div>
                  <Text block small>Job Type</Text>
                  <Text large block>{data.agency.invoice.jobType}</Text>
                </div>
              }

              {data.agency.invoice.packName &&
                <div>
                  <Text block small>Package</Text>
                  <Text large block>{data.agency.invoice.packName}</Text>
                </div>
              }

              {data.agency.invoice.date &&
                <div>
                  <Text block small>Date</Text>
                  <Text large block>{data.agency.invoice.date ? moment(data.agency.invoice.date).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
                </div>
              }

              {data.agency.invoice.dateSent &&
                <div>
                  <Text block small>Date Sent</Text>
                  <Text large block>{data.agency.invoice.dateSent ? moment(data.agency.invoice.dateSent).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
                </div>
              }
              {data.agency.invoice.datePaid &&
                <div>
                  <Text block small>Date Paid</Text>
                  <Text large block>{data.agency.invoice.datePaid ? moment(data.agency.invoice.datePaid).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
                </div>
              }

              {data.agency.invoice.dateDue &&
                <div>
                  <Text block small>Date Due</Text>
                  <Text large block>{data.agency.invoice.dateDue ? moment(data.agency.invoice.dateDue).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
                </div>
              }

              {data.agency.invoice.comments &&
                <div>
                  <Text block small>Comments</Text>
                  <div dangerouslySetInnerHTML={{__html:data.agency.invoice.comments}}/>
                </div>
              }
            </div>

            <InputHTML
              title="Notes"
              hint="Only visible to agents."
              initialValue={data.agency.invoice.notes}
              onChange={notes => debouncedUpdateInvoice({notes})}
            />

          </div>

          <div className="details-wrapper">
            <div className="details">
              <Text>Details</Text>
              <Rule style={{marginBottom:16}}/>
              <div className="titles">
                <div>
                  <Text small light>Date</Text>
                </div>
                <div style={{flex:2}}>
                  <Text small light>Talent</Text>
                </div>
                <div>
                  <Text small light>Type</Text>
                </div>
                <div>
                  <Text small light>From Time</Text>
                </div>
                <div>
                  <Text small light>To Time</Text>
                </div>
                <div>
                  <Text small light>Hours</Text>
                </div>
                <div>
                  <Text small light>Comments</Text>
                </div>
                <div style={{textAlign:'right'}}>
                  <Text small light>Rate</Text>
                </div>
                <div style={{textAlign:'right'}}>
                  <Text small light>Commission</Text>
                </div>
                <div style={{textAlign:'right'}}>
                  <Text small light>Total</Text>
                </div>
              </div>

              {lineItems.map((li, i) =>
                <div className="lineitem" key={i}>
                  <Text>{moment(li.date || data.agency.invoice.date).tz(timezone).format("MM/DD/YYYY")}</Text>
                  <div className="lineitem__talent">
                    {li.talentImage && <img src={li.talentImage} alt="headshot"/>}
                    <Text>{li.talentName}</Text>
                  </div>
                  <Text bold>{li.type}</Text>
                  <Text>{li.timeFrom ? moment(li.timeFrom).tz(timezone).format("h:mm a") : ""}</Text>
                  <Text>{li.timeTo ? moment(li.timeTo).tz(timezone).format("h:mm a") : ""}</Text>
                  <Text>{li.hours || ""}</Text>
                  <Text>{li.comment || ""}</Text>
                  <Currency style={{textAlign:'right'}}>{li.rate}</Currency>
                  <Text style={{textAlign:'right'}}>{li.commission || 0}%</Text>
                  <Currency style={{textAlign:'right'}}>{li.total}</Currency>
                </div>
              )}
            </div>
          </div>

          <div className="total-wrapper">
            <div className="totals">
              <Text small style={{marginRight:16}}>Subtotal</Text>
              <Currency large>{data.agency.invoice.subtotal}</Currency>
              <Text small style={{marginRight:16}}>Agency Fee</Text>
              <Currency large>{data.agency.invoice.agencyFeeTotal}</Currency>
              <Text small style={{marginRight:16}}>Total</Text>
              <Currency large bold>{data.agency.invoice.total}</Currency>
              <Text small style={{marginRight:16}}>Commission</Text>
              <Currency large>{data.agency.invoice.commissionTotal}</Currency>
            </div>
          </div>

        </Card>
      )}
    </Container>
  );
}