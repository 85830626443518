import React, { Component } from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { loadCannedMessages, showSnackbar, loadAgencyContacts } from 'actions';
import { isString, isEmpty, uniqBy, clone, get } from 'lodash';
import { saveDraft, removeDraft, query } from 'skybolt-api';
import { first } from 'rxjs/operators';

import './index.css';

import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';

import Button from 'ui/Button';
import TagInput from 'ui/TagInput';
import Toggle from 'ui/Toggle';
import Wysiwyg from 'ui/Wysiwyg';
import Icon from 'ui/Icon';




export const mapStateToProps = (state, ownProps) => {
  const user = state.user.id ? state.users.all[state.user.id] : null;

  const email = user ? user.email : '';
  const signature = user ? user.signature : '';

  let cannedMessages = {};
  try {
    cannedMessages = state.cannedMessages.byAgency[user.agencyId] || {};
  }
  catch(e){}

  const showCannedMessages = user ? state.user.permissions.canConfigAgency : false;

  const agencyId = state.user.agencyId;
  const enableAutocomplete = get(state, `agencies.all[${agencyId}].useContacts`, false);
  const defaultSMS = get(state, `agencies.all[${agencyId}].defaultSMS`, null);

  const showCCSelf = user ? state.user.permissions.canCCSelf : false;

  return {
    ownEmail: email,
    threadId: ownProps.threadId,
    thread: state.threads.all[ownProps.threadId],
    agencyId: agencyId,
    enableAutocomplete: enableAutocomplete,
    defaultSMS,
    signature,
    cannedMessages,
    showCannedMessages,
    showCCSelf,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  loadCannedMessages,
  showSnackbar,
  loadAgencyContacts,
};





export class MessageComposer extends Component {

  static defaultProps = {
    title: "New Message",
    subjectLabel: "Subject",
    toLabel: "To",
    className: "",
    enableAutocomplete: false,
  }

  state = {
    to: [],
    bccSender: false,
    ccSender: false,
    subject: "",
    smsDefault: "",
    smsText: "",
    attachments: [],
    sendSMS: true,
    toSearch: "",
    showAutoComplete: false,
    autocomplete: [],
    activeAutoComplete: 0,
    isSaved: false,
    isSaving: false,
    hasDraft: false,
  }

  UNSAFE_componentWillMount() {
    let state = {
      showCannedMessages: this.props.showCannedMessages,
      smsDefault: this.props.defaultSMS,
    };
    if(this.props.to) {
      state = {
        ...state,
        to: this.props.to,
        showToField: false,
      };
    }
    this.setState(state);

    this.props.loadCannedMessages(this.props.agencyId);
  }

  componentDidMount() {
    if(this.props.html && this.bodyField) {
      this.bodyField.value(this.props.html);
      this.setSMSDefaultText(this.props.html);
    }

    if(this.props.autoFocusBody) {
      this.bodyField.focus();
    }

    if(this.props.draftKey) {
      query(gql`{draft(id:$draftKey)}`, {draftKey:this.props.draftKey})
        .pipe(first())
        .subscribe(({draft}) => {
          if(!draft || isEmpty(draft)) {
            return;
          }
          this.setState({
            to: draft.to || [],
            bccSender: !!draft.bccSender,
            ccSender: !!draft.ccSender,
            subject: draft.subject || "",
            smsText: draft.smsText || "",
            attachments: draft.attachments || [],
            sendSMS: !!draft.sendSMS,
            hasDraft: true,
            isSaved: true,
          });
          if(draft.html && this.bodyField) {
            this.bodyField.raw(draft.html);
          }
        });
    }
  }

  componentWillUnmount() {
    if(this.saveTimout) {
      clearTimeout(this.saveTimout);
    }
    if(this.draftTimeout) {
      clearTimeout(this.draftTimeout);
    }
  }

  saveDraft = () => {
    const id = this.props.draftKey;
    if(!id) {
      return;
    }
    const draft = {
      to: this.state.to,
      bccSender: !!this.state.bccSender,
      ccSender: !!this.state.ccSender,
      subject: this.state.subject,
      smsText: this.state.smsText,
      attachments: this.state.attachments,
      sendSMS: !!this.state.sendSMS,
      html: this.bodyField.raw(),
    };
    saveDraft({id, draft});

    this.setState({isSaving:true});
    if(this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }
    this.saveTimeout = setTimeout(() => this.setState({isSaving:false, isSaved:true, hasDraft:true}), 1000);
  }

  discardDraft = () => {
    const id = this.props.draftKey;
    if(!id) {
      return;
    }
    removeDraft({id});
    if(this.props.onCancel) {
      this.props.onCancel();
    }
  }

  update = (update={}) => {
    this.setState({
      ...update,
      isSaved: false,
    });

    if(this.draftTimeout) {
      clearTimeout(this.draftTimeout);
    }
    this.draftTimeout = setTimeout(this.saveDraft, 1000);
  }

  searchContacts = async query => {
    this.setState({toSearch:query});

    if(!this.props.enableAutocomplete) {
      return;
    }

    if(!query) {
      this.setState({toSearch:"", showAutoComplete:false, autocomplete:[]});
      return;
    }

    const contacts = await this.props.loadAgencyContacts({search:{query}});

    let autocomplete = [];

    Object.keys(contacts).forEach(key => {
      const contact = contacts[key];
      if(!contact.emails) {
        return;
      }
      const id = contact.id;
      const name = `${contact.firstName} ${contact.lastName}`.trim();
      Object.keys(contact.emails).forEach(key => {
        const email = contact.emails[key].email;
        const label = contact.emails[key].label;
        autocomplete.push({name, email, label, id});
      });
    });

    this.setState({showAutoComplete:true, autocomplete});
  }

  handleSearchKeyDown = e => {
    if(!this.state.showAutoComplete || this.state.autocomplete.length === 0) {
      return;
    }

    if(e.keyCode === 40) { // down
      let activeAutoComplete = this.state.activeAutoComplete;
      if(activeAutoComplete < this.state.autocomplete.length - 1) {
        activeAutoComplete += 1;
      }
      this.setState({activeAutoComplete});
      return false;
    }

    if(e.keyCode === 38) { // up
      let activeAutoComplete = this.state.activeAutoComplete;
      if(activeAutoComplete > 0) {
        activeAutoComplete -= 1;
      }
      this.setState({activeAutoComplete});
      return false;
    }

    if(e.keyCode === 13) { // enter
      let to = this.state.autocomplete[this.state.activeAutoComplete];
      if(!to) {
        return;
      }
      this.addTo(to);
      return false;
    }
  }

  addTo = to => {
    let newTo = [...this.state.to, to];
    newTo = uniqBy(newTo, i => isString(i) ? i : i.email);
    this.update({to:newTo, toSearch:"", showAutoComplete:false, autocomplete:[], activeAutoComplete:0});
  }

  removeTo = (_, i) => {
    let to = clone(this.state.to);
    to.splice(i, 1);
    this.update({to});
  }

  clearAutoComplete = () => {
    this.setState({
      showAutoComplete: false,
      autocomplete: [],
      activeAutoComplete: 0,
    });
  }

  send = () => {
    let bodyHTML = this.bodyField.value();
    if(!!this.props.signature) {
      bodyHTML += `<br/><br/> ${this.props.signature}`;
    }


    let smsText = false;
    if(this.state.sendSMS) {
      if(this.state.smsText) {
        smsText = this.state.smsText;
      }
      else {
        smsText = this.state.smsDefault;
      }
    }

    const message = {
      to: this.state.to.map(to => {
        if(isString(to)) {
          return {name:to, email:to};
        }
        return to;
      }),
      bccSender: this.state.bccSender,
      ccSender: this.state.ccSender,
      subject: this.state.subject,
      body: bodyHTML,
      sms: smsText,
      attachments: this.state.attachments,
    };

    const draftId = this.props.draftKey;
    if(draftId) {
      removeDraft({id:draftId});
    }

    this.props.onSend(message);
  }

  addAttachment = file => {
    if(!file || !file.name) {
      this.props.showSnackbar("There was an error attaching your file.");
      return;
    }

    if(file.size / 1048576 > 10) {
      this.props.showSnackbar('Your file is too big. Keep it under 10MB');
      return;
    }

    let totalSize = file.size;
    for(const i in this.state.attachments) {
      totalSize += this.state.attachments[i].file.size;
    }
    if(totalSize / 1048576 > 30) {
      this.props.showSnackbar("Emails can't be bigger than 30MB");
      return;
    }


    if(file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg') {
      var fr = new FileReader();
      fr.onload = () => {
        this.setState({
          attachments: [
            ...this.state.attachments,
            {file, url:fr.result}
          ]
        });
      };
      fr.readAsDataURL(file);
      return;
    }

    this.setState({
      attachments: [
        ...this.state.attachments,
        {file, url:""}
      ]
    });
  }

  removeAttachment = (i) => {
    let attachments = [...this.state.attachments];
    attachments.splice(i, 1);
    this.setState({attachments});
  }

  setSMSDefaultText = html => {
    if(this.props.defaultSMS) {
      return;
    }
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.innerText.trim().replace(/\n/g, '').replace(/\s+/g, ' ');
    this.setState({smsDefault:text});
  }

  renderTitle() {
    if(this.props.showTitle === false) {
      return null;
    }

    if(this.props.title) {
      return  (
        <div className="messagecomposer-title">
          {this.props.title}
        </div>
      );
    }

    if(this.state.to.length > 0) {
      return (
        <div className="messagecomposer-title">
          {this.state.to.map(to => to.name).join(', ')}
        </div>
      );
    }

    return null;
  }

  renderExtraAbove() {
    if(!this.props.above) {
      return;
    }
    return this.props.above;
  }

  renderTo() {
    if(this.props.showToField === false) {
      return;
    }

    return (
      <div className="messagecomposer-to">
        <TagInput
          hint={this.props.toLabel}
          search={this.state.toSearch}
          tags={this.state.to.map(to => isString(to) ? to : to.email)}

          // onChange={to => this.setState({to})}
          validate={email => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!re.test(email)) {
              return `"${email}" is not a valid email address.`;
            }
            return false;
          }}
          underlineShow={false}

          onSearch={this.searchContacts}
          onRemove={this.removeTo}
          onAdd={this.addTo}
          onFocus={() => this.setState({showAutoComplete:true})}
          onBlur={() => this.setState({showAutoComplete:false})}
          onKeyDown={this.handleSearchKeyDown}
        />
        {this.state.showAutoComplete && !isEmpty(this.state.autocomplete) &&
          <div className="messagecomposer-to-autocomplete" onMouseDown={e => e.preventDefault()}>
            {this.state.autocomplete.slice(0, 5).map((item, i) => (
              <div
                key={`${item.id}-${item.email}`}
                className={`messagecomposer-to-autocomplete-item ${this.state.activeAutoComplete === i ? 'is-active' : ''}`}
                onClick={() => this.addTo(item)}>
                <div className="messagecomposer-to-autocomplete-name">{item.name || item.email}</div>
                {item.name &&
                  <div className="messagecomposer-to-autocomplete-email">{item.email} {!!item.label ? `(${item.label})` : ''}</div>
                }
              </div>
            ))}
          </div>
        }
      </div>
    );
  }

  renderCopySelf() {
    if(this.props.showBCCSelf !== true) {
      return null;
    }

    return (
      <div className="messagecomposer-copyself">
        <div style={{width:150, display:'inline-block'}}>
          <Toggle
            label="BCC Myself"
            toggled={this.state.bccSender}
            onToggle={(e, bccSender) => this.update({bccSender})}
          />
        </div>
        {this.props.showCCSelf &&
          <div style={{width:150, display:'inline-block'}}>
            <Toggle
              label="CC Myself"
              toggled={this.state.ccSender}
              onToggle={(e, ccSender) => this.update({ccSender})}
            />
          </div>
        }
      </div>
    );
  }

  renderSubject() {
    if(this.props.showSubject === false) {
      return null;
    }

    return (
      <div className="messagecomposer-subject">
        <TextField
          fullWidth
          hintText={this.props.subjectLabel}
          name="subject"
          value={this.state.subject}
          onChange={(e, subject) => this.update({subject})}
          underlineShow={false}
        />
      </div>
    );
  }

  renderBody() {
    return (
      <div className="messagecomposer-wysiwyg-page">
        <Wysiwyg
          ref={el => this.bodyField = el}
          html={this.props.html}
          className="messagecomposer-wysiwyg"
          toolsClassName="messagecomposer-wysiwyg-tools"
          contentClassName="messagecomposer-wysiwyg-content"
          onChange={(e, html) => {
            this.setSMSDefaultText(html);
            this.update();
          }}
        />
      </div>
    );
  }

  renderSignature() {
    if(!this.props.signature) {
      return null;
    }
    return (
      <div
        className="messagecomposer-signature"
        dangerouslySetInnerHTML={{__html: this.props.signature}}
      />
    );
  }

  renderCannedMessages() {
    if(this.props.showCannedMessages === false || this.props.hideCannedMessages === true) {
      return;
    }

    const messages =  Object.keys(this.props.cannedMessages)
      .map(key => ({...this.props.cannedMessages[key], key}))
      .sort((a, b) => a.order < b.order ? -1 : 1)
      .map(message => (
        <FlatButton
          key={message.key}
          label={message.name}
          style={{height:24, lineHeight:'24px', backgroundColor:"#BDBDBD", color:'white', margin:"0 2px 2px 0"}}
          labelStyle={{fontSize:12}}
          onClick={() => {
            this.update({subject:message.subject});
            this.bodyField.value(message.body);
            this.update({smsText:message.sms});
          }}
        />
      ));

    return (
      <div className="messagecomposer-cannedmessages">
        {messages}
      </div>
    );
  }

  renderAttachments() {
    if(this.state.attachments.length === 0) {
      return null;
    }

    return (
      <div className="messagecomposer-wysiwyg-attachments">
        <Icon name="attach" color="#BDBDBD" style={{marginTop:6}}/>

        <div className="messagecomposer-wysiwyg-attachments-previews">

          {this.state.attachments.map((attachment, i) => {
            return (
              <div key={get(attachment, 'file.name', i)} className="messagecomposer-wysiwyg-attachment">
                {attachment.url ? (
                  <img alt="Attachment" src={attachment.url} className="messagecomposer-wysiwyg-preview"/>
                ) : (
                  <Icon name="file" size={80} style={{marginTop:-6}}/>
                )}
                <div className="messagecomposer-wysiwyg-name">{get(attachment, 'file.name', "")}</div>

                <div className="messagecomposer-wysiwyg-attachment-remove-overlay">
                  <Button icon="remove" size={24} color="white" onClick={() => this.removeAttachment(i)} className="messagecomposer-wysiwyg-attachment-remove"/>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderSMS() {
    if(this.props.showSMS === false) {
      return null;
    }

    if(this.state.sendSMS === false) {
      return null;
    }

    return (
      <div className="messagecomposer-sms">
        <TextField
          hintStyle={{color:this.props.muiTheme.palette.grey300, width:'100%'}}
          hintText={<div style={{width:'100%', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}>{this.state.smsDefault}</div>}
          value={this.state.smsText}
          onChange={(e, smsText) => this.update({smsText: smsText.slice(0, 160)})}
          underlineShow={false}
          multiLine={true}
          fullWidth
        />
      </div>
    );
  }

  renderActions() {
    const {grey600, accent700} = this.props.muiTheme.palette;

    return (
      <div className="messagecomposer-actions">

        <Button icon="attach" label="Add Attachment" onClick={() => this.hiddenInput.click()}/>

        {this.props.showSMS !== false &&
          <Button
            icon="sms"
            label={this.state.sendSMS ? "Don't send SMS" : "Send as SMS"}
            color={this.state.sendSMS ? accent700 : grey600}
            onClick={() => this.update({sendSMS:!this.state.sendSMS})}
          />
        }

        <div style={{flex:1}}/>

        <div className="messagecomposer-action-draft">
          {this.state.isSaving ? "Saving..." :
           this.state.isSaved ? "Saved" :
           ""}
        </div>

        {this.state.hasDraft &&
          <Button
            key="discard"
            icon="trash"
            tooltip="Discard Draft"
            style={{marginLeft:8}}
            onClick={this.discardDraft}
            color="#BDBDBD"
          />
        }

        <Button
          style={{marginLeft:8}}
          key="send"
          icon="send"
          tooltip="Send Message"
          onClick={this.send}
          backgroundColor="#BDBDBD"
          color="#ffffff"
        />

        <input
          name="imageinput"
          ref={el => this.hiddenInput = el}
          type="file"
          onChange={e => {
            this.addAttachment(e.target.files[0]);
          }}
          style={{position: 'absolute', left: -1000}}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={`messagecomposer ${this.props.className}`}>
        {this.renderTitle()}
        {this.renderExtraAbove()}
        {this.renderTo()}
        {this.renderCopySelf()}
        {this.renderSubject()}

        <div className="messagecomposer-wysiwyg-container">
          {this.renderBody()}
          {this.renderSignature()}
          {this.renderAttachments()}
          {this.renderSMS()}
          {this.renderActions()}
        </div>

        {this.renderCannedMessages()}
      </div>
    );
  }

}


export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(MessageComposer));