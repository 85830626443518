import React, { useMemo, useContext } from 'react';
import {
  useRouter,
  Modal,
  ThemeContext,
  Text,
  Button,
  Card,
  InputSwitch,
  Rule,
  Loading,
  Icon,
  Currency,
  Container,
} from 'skybolt-ui';
import { useQuery, updateDebit, createDebits } from 'skybolt-api';
import moment from 'moment-timezone';

export default function(props) {

  const {
    history,
    location: {
      state = {},
      pathname
    },
    match: {
      url,
      params:{
        invoiceId
      }
    }
  } = useRouter();
  const {
    status = null,
    selection = [],
  } = state;
  const setQuery = (update) => history.replace(pathname, {...state, ...update});


  const data = useQuery(`{
    agency {
      agencyId
      timezone
      invoice(invoiceId:$invoiceId) {
        invoiceNumber
        lineItems
        packId
        packName
        packImage
        debits(status:$status, orderBy:null) {
          debitId
          talentName
          talentImage
          status
          dateJob
          grossAmount
          taxAmount
          agencyAmount
          otherAmount
          amount
        }
      }
    }
  }`, {invoiceId, status});

  let timezone = data?.agency?.timezone || moment.tz.guess();

  // Debit selection and actions

  const isSelected = debitId => selection.indexOf(debitId) > -1;
  const toggleSelection = debitId => {
    if(!isSelected(debitId)) {
      setQuery({selection: [...selection, debitId]});
    }
    else {
      const sel = selection.filter(s => s !== debitId);
      setQuery({selection:sel});
    }
  };

  const isAllSelected = useMemo(() => {
    for(const debit of data ? data.agency.invoice.debits : []) {
      if(!isSelected(debit.debitId)) {
        return false;
      }
    }
    return true;
  }, [selection, data && data.agency.invoice.debits]);

  const toggleSelectAll = () => {
    if(!isAllSelected) {
      setQuery({selection:data.agency.invoice.debits.map(d => d.debitId)});
    }
    else {
      setQuery({selection:[]});
    }
  };

  const selectionByStatus = status => {
    if(!data) {
      return [];
    }
    return selection.filter(debitId => {
      const debit = data.agency.invoice.debits.find(d => d.debitId === debitId);
      return debit && debit.status === status;
    });
  };
  // const sentSelection = selectionByStatus("SENT");
  const draftSelection = selectionByStatus("DRAFT");

  const markSent = async () => {
    const confirmed = await Modal.confirm(`Mark ${draftSelection.length} payments as paid.`);
    if(!confirmed) {
      return;
    }
    for(const debitId of draftSelection) {
      await updateDebit({
        debitId,
        update:{
          status:"SENT",
          dateSent: Date.now(),
        }
      });
    }
  };

  // const markDraft = async () => {
  //   const confirmed = await Modal.confirm(`Mark ${sentSelection.length} payments as outstanding.`);
  //   if(!confirmed) {
  //     return;
  //   }
  //   for(const debitId of sentSelection) {
  //     await updateDebit({
  //       debitId,
  //       update:{
  //         status:"DRAFT",
  //         dateSent: null,
  //       }
  //     });
  //   }
  // };



  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  return (
    <Container>
      <div style={{display:"flex", alignItems:"flex-end", height:40, paddingTop:32}}>
        <Text title>Talent Payments</Text>
        <div style={{flex:1}}/>
        {/* {selection.length > 0 &&
          <Text light block style={{margin:"0 8px 7px 0"}}>{selection.length} selected:</Text>
        }
        {draftSelection.length > 0 &&
          <Button
            onClick={markSent}
            icon="envelope-open-dollar"
            label="Mark Paid"
            style={{
              background:theme.color.grey,
              color: 'white',
              borderRadius: 0,
              marginLeft: 4,
            }}
          />
        } */}
        {/*sentSelection.length > 0 &&
          <Button
            onClick={markDraft}
            icon="envelope"
            label="Mark Outstanding"
            style={{
              background:theme.color.grey,
              color: 'white',
              borderRadius: 0,
              marginLeft: 4,
            }}
          />
        */}
        {/*draftSelection.length > 0 &&
          <Button
            icon="print"
            label="Print Checks"
            style={{
              background:theme.color.grey,
              color: 'white',
              borderRadius: 0,
              marginLeft: 4,
            }}
          />
        */}
      </div>
      <Rule/>
      <div style={{marginBottom:24}}>
        <Button
          label="Pending"
          onClick={() => setQuery({status:"PENDING"})}
          style={{
            borderRadius: 0,
            backgroundColor: status === "PENDING" ? theme.color.primaryLighter : theme.color.greyLight
          }}
        />
        <Button
          label="Scheduled"
          onClick={() => setQuery({status:"DRAFT"})}
          style={{
            borderRadius: 0,
            backgroundColor: status === "DRAFT" ? theme.color.primaryLighter : theme.color.greyLight
          }}
        />
        <Button
          label="Paid"
          onClick={() => setQuery({status:"SENT"})}
          style={{
            borderRadius: 0,
            backgroundColor: status === "SENT" ? theme.color.primaryLighter : theme.color.greyLight
          }}
        />
        <Button
          label="All"
          onClick={() => setQuery({status:null})}
          style={{
            borderRadius: 0,
            backgroundColor: status === null ? theme.color.primaryLighter : theme.color.greyLight
          }}
        />
      </div>

      {!status && data.agency.invoice.debits.length === 0 && data.agency.invoice.lineItems.length > 0 &&
        <div style={{padding:"32px 0", display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Icon name="surprise" duotone size={80} color="accentLighter" style={{marginBottom:16}}/>
          <Text paragraph style={{maxWidth:400, textAlign:'center'}}>
            There are no talent payments for this invoice. Would you like to automatically
            create payments from the invoice details?
          </Text>
          <Button
            raised accent
            label={`Yes, create ${data.agency.invoice.lineItems.length} ${data.agency.invoice.lineItems.length === 1 ? "payment" : "payments"}`}
            onClick={() => createDebits({invoiceId})}
          />
        </div>
      }

      {status === "SENT" && data.agency.invoice.debits.length === 0 &&
        <div style={{padding:"32px 0", display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Icon name="surprise" duotone size={80} color="accentLighter" style={{marginBottom:16}}/>
          <Text paragraph style={{maxWidth:400, textAlign:'center'}}>
            No talent payments have been made for this invoice.
          </Text>
        </div>
      }

      {status === "DRAFT" && data.agency.invoice.debits.length === 0 &&
        <div style={{padding:"32px 0", display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Icon name="sunglasses" duotone size={80} color="accentLighter" style={{marginBottom:16}}/>
          <Text paragraph style={{maxWidth:400, textAlign:'center'}}>
            There are no outstanding payments for this invoice.
          </Text>
        </div>
      }

      {data.agency.invoice.debits.length > 0 &&
        <div style={{display:'flex', alignItems:'center', paddingBottom:8}}>
          {/* <div style={{width:32}}>
            <InputSwitch
              value={isAllSelected}
              onChange={toggleSelectAll}
              style={{margin:0}}
            />
          </div> */}
          <div style={{flex:1}}>
            <Text small light>Talent</Text>
          </div>
          <div style={{flex:1}}>
            <Text small light>Job Date</Text>
          </div>
          <div style={{flex:1}}>
            <Text small light>Status</Text>
          </div>
          <div style={{flex:1}}>
            <Text small light>Gross</Text>
          </div>
          <div style={{flex:1}}>
            <Text small light>Tax</Text>
          </div>
          <div style={{flex:1}}>
            <Text small light>Commission</Text>
          </div>
          <div style={{flex:1}}>
            <Text small light>Other</Text>
          </div>
          <div style={{flex:1}}>
            <Text small light>Amount</Text>
          </div>
        </div>
      }

      {data.agency.invoice.debits.map(debit => (
        <div key={debit.debitId} style={{display:'flex', alignItems:'center'}}>

          {/* <div style={{width:32}}>
            <InputSwitch
              style={{margin:0}}
              value={selection.indexOf(debit.debitId) > -1}
              onChange={() => toggleSelection(debit.debitId)}
            />
          </div> */}

          <Card
            key={debit.debitId}
            style={{
              flex:1,
              display:'flex',
              alignItems:'center',
              marginBottom:4,
              cursor:'pointer',
              height:40,
              backgroundColor: isSelected(debit.debitId) ? theme.color.accentLighter : 'white',
            }}
            onClick={() => {
              history.push(`/billing/debits/${debit.debitId}`, {referrer:url});
            }}>

            <div style={{flex:1, display:'flex', alignItems:'center'}}>
              {debit.talentImage &&
                <img src={debit.talentImage} alt="headshot" style={{width:40, height:40, marginRight:8}}/>
              }
              <Text>{debit.talentName}</Text>
            </div>
            <div style={{flex:1}}>
              <Text>
                {debit.dateJob ? moment(debit.dateJob).tz(timezone).format("MM/DD/YYYY") : ""}
              </Text>
            </div>
            <div style={{flex:1}}>
              <Text
                style={{
                  fontWeight: 'bold',
                  color: debit.status === "SENT" ? "green" : "#aaaaaa"
                }}>
                {debit.status}
              </Text>
            </div>
            <div style={{flex:1}}>
              <Currency>{debit.grossAmount}</Currency>
            </div>
            <div style={{flex:1}}>
              <Currency>{debit.taxAmount}</Currency>
            </div>
            <div style={{flex:1}}>
              <Currency>{debit.agencyAmount}</Currency>
            </div>
            <div style={{flex:1}}>
              <Currency>{debit.otherAmount}</Currency>
            </div>
            <div style={{flex:1}}>
              <Text bold>${parseFloat(debit.amount || 0, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
            </div>
          </Card>

        </div>
      ))}
    </Container>
  );
}