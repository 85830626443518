/* global URL */

import React, { useContext, useRef, useState } from 'react';
import { useRouter, Loading, Redirect, Container, Text, Rule, Card, ThemeContext, Icon, Button, Modal } from 'skybolt-ui';
import { useQuery, cloudinaryUpload, updatePackTalent } from 'skybolt-api';
import { get } from 'lodash';
import { videoFormats, imageFormats } from 'config';

export default function(props) {


  // Pull data out of the route.

  const inputRef = useRef();
  const theme = useContext(ThemeContext);
  const [progress, setProgress] = useState(null);
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);

  const route = useRouter();
  const history = route.history;
  const packId = get(route, 'match.params.packId', null);
  const talentId = get(route, 'match.params.talentId', null);

  if(!packId || !talentId) {
    return <Redirect to={`/`}/>;
  }

  const data = useQuery(`{
    pack(packId:$packId) {
      mediaRequest {
        name
        type
        message
        saveToTalent
        tags
        status
      }
    }
  }`, {packId});

  async function handleUpload(e) {
    const file = e.target.files[0];

    const fileSize = ((file.size/1024)/1024).toFixed(4);
    if(fileSize > 100) {
      await Modal.error(<div>
        <Text paragraph>The file you select is too big.</Text>
        <Text paragraph>We have a maximum file size of 100mb. If you need help compressing your file, please contact support by clicking the chat button at the bottom right of your screen.</Text>
      </div>);
      return;
    }

    if(file.type.indexOf('image') > -1) {
      const reader = new FileReader();
      reader.onload = () => setPreview(reader.result);
      reader.readAsDataURL(file);
    }

    if(file.type.indexOf('video') > -1) {
      let url = URL.createObjectURL(file);
      setPreview(url);
    }

    setFile(file);
  }

  async function save() {
    const handleProgress = p => setProgress(p.progress);
    const handleComplete = async media => {
      const update = {
        mediaRequest: {
          ...media
        }
      };
      await updatePackTalent({packId, talentId, update});
      history.replace(`/talents/${talentId}`);
    };
    const handleError = async () => {
      await Modal.error("Something went wrong. Please try again.");
    };
    await cloudinaryUpload(file, handleProgress, handleComplete, handleError);
  }





  // Render

  if(!data) {
    return <Loading/>;
  }

  if(data.pack.mediaRequest.status !== "SENT") {
    return <Redirect to={`/talents/${talentId}`}/>;
  }

  let type = data.pack.mediaRequest.type;
  let acceptedFormats =  type === "video"?
    videoFormats.map(f => `video/${f},.${f}`).join(",") :
    imageFormats.map(f => `image/${f},.${f}`).join(",");

  return (
    <Container>
      <div style={{paddingTop:32}}>
        <Text title>{data.pack.mediaRequest.name}</Text>
      </div>
      <Rule style={{marginBottom:24}}/>
      <div style={{display:'flex', flexWrap:'wrap', maxWidth:1000, margin:'0 auto'}}>
        <Card style={{flex:1, padding:16, maxWidth:400, marginRight:32}}>
          {preview && type === "image" ? (
            <div style={{background:`${theme.color.greyLight} url("${preview}") no-repeat center`, backgroundSize:'contain', height:240, borderRadius:6, marginBottom:16}}/>
          ) : preview && type === "video" ? (
            <div style={{minHeight:240, marginBottom:16}}>
              <video controls width="100%">
                <source src={preview}/>
              </video>
            </div>
          ) : (
            <div style={{backgroundColor:theme.color.greyLight, height:240, display:'flex', alignItems:'center', justifyContent:'center', borderRadius:6, marginBottom:16}}>
              <Icon name={type === "video" ? "video" : "camera-alt" } duotone size={80} color="accentLight"/>
            </div>
          )}
          <div style={{textAlign:'center'}}>
            {file ? (
              <Button accent raised label={progress ? `${progress}%` : "Submit"} onClick={save} disabled={progress}/>
            ) : (
              <Button accent raised label="Choose File" onClick={() => inputRef.current.click()}/>
            )}
          </div>
        </Card>
        <div style={{flex:1, maxWidth:600, minWidth:300, paddingTop:8}}>
          <Text paragraph dangerouslySetInnerHTML={{__html:data.pack.mediaRequest.message}}></Text>
        </div>
      </div>
      <input ref={inputRef} type="file" accept={acceptedFormats} onChange={handleUpload} style={{position: 'absolute', left: -1000}}/>
    </Container>
  );
}