/* global CustomEvent */
import React, { useState } from 'react';
import { useQuery, requestPack } from 'skybolt-api';
import {
  TalentGrid,
  Modal,
  Icon,
  useRouter,
  InputText,
  Form,
  Button,
  Text,
  Header,
  Container,
  Rule,
  Theme,
  TalentSearch,
  LazyLoad,
  Card,
} from 'skybolt-ui';
import Talent from './Talent';


export default () => {


  // Load Data
  const {match:{params:{ agencyId }}} = useRouter();
  const [search, setSearch] = useState({});
  const [limit, setLimit] = useState(100);

  const data = useQuery(`{
    agency(agencyId:$agencyId) {
      agencyId
      name
      talents: publicTalents(search:$search, limit:$limit) {
        talentId
        image(width:130, height:130, face:true)
        name: stageName
        info
      }
      primaryColor
      accentColor
      talentFields
    }
  }`, {agencyId, search, limit});



  // Control Talent Modal
  // Opened when talent is clicked.

  const [talentId, setTalentId] = useState(null);
  const [talentTab, setTalentTab] = useState(null);
  const [talentOpen, setTalentOpen] = useState(false);

  function handleClick(tab, talentId) {
    setTalentId(talentId);
    setTalentTab(tab);
    setTalentOpen(true);
  }



  // Toggle talent search.
  const [isSearching, setSearching] = useState(false);
  const toggleSearching = () => setSearching(!isSearching);



  // Pull talents either from the spotlight, or from the whole agency.

  let talents = data ? data.agency.talents : [];


  // Talent selections are held in state. Instead of pulling a `selected` field
  // on each talent, we map all talents and inject it with a value from state.

  const [selected, setSelected] = useState({});
  const handleSelect = (sel, id) => {
    const newValue = {...selected};
    if(!sel) {
      delete newValue[id];
    }
    else {
      newValue[id] = true;
    }
    setSelected(newValue);
  };

  talents = talents.map(t => ({...t, selected:!!selected[t.talentId]}));


  // Handle a casting request.

  const [requestOpen, setRequestOpen] = useState(false);
  const [request, setRequest] = useState({});
  const [isRequesting, setRequesting] = useState(false);
  const [error, setError] = useState("");

  const createCastingRequest = async () => {
    if(isRequesting) {
      return;
    }
    setRequesting(true);
    try {
      await requestPack({
        ...request,
        agencyIds: [agencyId],
        talents: Object.keys(selected),
      });
    }
    catch(error) {
      console.log(error);
      setError(error.message || "Something went wrong. Please try again.");
      setRequesting(false);
      return;
    }
    setError(null);
    setRequestOpen('sent');
    setSelected([]);
    setRequesting(false);
  };


  // Only use public fields.
  const fields = data ? {...data.agency.talentFields} : {};
  for(let id in fields) {
    if(fields[id].isPublic !== true || fields[id].isEnabled === false) {
      delete fields[id];
    }
  }



  // Layout

  if(!data) {
    return (
      <Icon name="loading" style={{margin:"160px auto 0"}}/>
    );
  }

  return (
    <Theme
      theme={{
        accentColor: data.agency.accentColor,
        primaryColor: data.agency.primaryColor,
      }}>
      <Container>

        <Header
          name={data.agency.name}
          style={{marginTop:32, marginBottom:24}}
          actions={[
            {
              key:"request",
              label:"Create Casting Request",
              icon:"send",
              accent: true,
              onClick:() => setRequestOpen(true),
            },
          ]}
        />

        <div style={{display:'flex', alignItems:'flex-end', height:40}}>
          <Text title>TALENT</Text>
          <div style={{flex:1}}/>
          <Button
            title
            icon={isSearching ? "close" : "tune"}
            onClick={toggleSearching}
          />
        </div>
        <Rule/>

        {isSearching &&
          <Card style={{padding:16, marginBottom:32}}>
            <TalentSearch
              fields={fields}
              value={search}
              onChange={value => setSearch(value)}
              useLocation={false}
            />
          </Card>
        }

        <div style={{height:24}}/>

        <TalentGrid
          talents={talents}
          small
          onClick={handleClick}
          onSelect={handleSelect}
        />
        <LazyLoad onLoad={setLimit} count={talents.length}/>



        <Modal status={talentOpen} onRequestClose={() => setTalentOpen(false)}>
          <Talent talentId={talentId} tab={talentTab}/>
        </Modal>
        <Modal status={requestOpen} onRequestClose={() => setRequestOpen(false)}>
          <Form value={request} onChange={val => setRequest(val)} style={{padding:16}}>

            <Text large style={{marginBottom:24}}>Create Casting Request</Text>

            <InputText name="from.name" title="Your Name"/>
            <InputText name="from.email" title="Email Address"/>
            <InputText name="name" title="Package Name"/>
            <InputText name="message" title="Message"/>

            {error && <Text error>{error}</Text>}

            <div style={{display:'flex', justifyContent:'flex-end'}}>
              <Button disabled={isRequesting} raised accent onClick={createCastingRequest}>
                {isRequesting ? "Hang Tight..." : "Send"}
              </Button>
            </div>
          </Form>
        </Modal>
      </Container>
    </Theme>
  );

};