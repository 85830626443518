import React, { Component } from 'react';
import { colors } from 'skybolt-ui';
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { loadTalent, loadTalentAgency } from 'actions';
import { cloudinaryUrl } from 'skybolt-api';
import moment from 'moment';
import { mediaFormats } from 'config';
import { map, isNumber, isEmpty, isString, isObject, isArray, min, max } from 'lodash';

import "./index.css";




function formatPhoneNumber(s) {
  var s2 = (""+s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? s : "(" + m[1] + ") " + m[2] + "-" + m[3];
}

function getTalentImageId(talent) {
  if(talent.media) {
    const image = Object.keys(talent.media)
      .map(id => talent.media[id])
      .sort((a, b) => a.order < b.order ? -1 : 1)
      .find(media => !media.format || mediaFormats.indexOf(media.format) !== -1);

    if(image && image.cloudinary_id) {
      return image.cloudinary_id;
    }
  }

  if(talent.image && talent.image.cloudinary_id) {
    return talent.image.cloudinary_id;
  }

  return 'avatar';
}

const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const talent = state.talents.all[talentId];

  let agencyId, agency;
  if(talent && talent.agencyId) {
    agencyId = talent.agencyId;
    agency = state.agencies.all[agencyId];
  }

  return {
    talentId,
    agencyId,
    talent,
    agency,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  loadTalent,
  loadTalentAgency,
};







class PrintHeadshot extends Component {
  loaded = 0

  UNSAFE_componentWillMount() {
    if(this.props.talentId) {
      this.props.loadTalent(this.props.talentId);
      this.props.loadTalentAgency(this.props.talentId);
    }
  }

  onImageLoad = () => {
    this.loaded += 1;
    if(this.loaded === 2) {
      window.parent.document.dispatchEvent(new CustomEvent('PRINTING'));
      window.print();
    }
  }

  render() {
    if(!this.props.talent || !this.props.agency) {
      return <div></div>;
    }

    const {
      agency,
      talent,
    } = this.props;

    const {
      firstName,
      lastName,
    } = talent;

    const imageId = getTalentImageId(this.props.talent);
    const imageSource = cloudinaryUrl(imageId, {width:400});

    const logoId = agency.logo.cloudinary_id;
    const logoSource = cloudinaryUrl(logoId, {height: 320});

    const fields = Object.keys(agency.talentFields || {})
      .map(id => ({...agency.talentFields[id], id}))
      .filter(field => {
        if(!talent[field.id]) {
          return false;
        }

        if(isObject(talent[field.id]) && isEmpty(talent[field.id])) {
          return false;
        }

        if(isArray(talent[field.id]) && isEmpty(talent[field.id])) {
          return false;
        }
        
        if(field.isVisibleInPrint) {
          return true;
        }

        if(!field.isPublic) {
          return false;
        }

        if(field.category === 'system') {
          return false;
        }

        return true;
      })
      .reduce((all, field) => {
        const category = field.category || 'Other';
        const fields = all[category] || {};
        return {
          ...all,
          [category]: {
            ...fields,
            [field.id]: field
          }
        };
      }, {});

    return (
      <div className="printheadshot-wrapper">
        <div className="printheadshot-agency-logo">
          <img
            alt={agency.name}
            src={logoSource}
            onLoad={this.onImageLoad}
            height="80"
          />
        </div>

        <h1 className="printheadshot-name">{firstName} {lastName}</h1>

        <div className="printheadshot-content">

          <div className="printheadshot-headshot-wrapper">
            <img
              alt="Headshot"
              src={imageSource}
              onLoad={this.onImageLoad}
              className="printheadshot-headshot"
            />
          </div>

          <div className="printheadshot-info">
            <ul className="printheadshot-fields">
              {map(fields, (categoryFields, category) =>
                <div key={category} style={{paddingBottom:32}}>
                  {category !== "system" && 
                    <div style={{fontSize:12, fontWeight:'bold', color:colors.grey[6], paddingBottom:4}}>{category}</div>
                  }

                  {Object.keys(categoryFields)
                    .map(i => categoryFields[i])
                    .sort((a, b) => !a.order || a.order < b.order ? -1 : 1)
                    .map((field) => {
                      let value = talent[field.id];
                      if(!value) {
                        return null;
                      }

                      let units = field.units;

                      if(field.type === 'range' && isArray(value)) {
                        value = `${min(value)}-${max(value)}`;
                      }

                      // todo: remove this check after September 2018
                      else if(field.type === 'range' && isObject(value) && value.from && value.to) {
                        value = `${value.from}-${value.to}`;
                      }

                  	  else if(field.type === 'date') {
                        value = moment(value).format('MM/DD/YYYY');
                      }

                      else if(isArray(value)) {
                        value = value.join(', ');
                      }

                      else if(field.isFeet) {
                        value = `${Math.floor(value / 12)}'${Math.floor(value % 12)}"`.trim();
                        units = "";
                      }

                      if(value === "" || (!isNumber(value) && !isString(value))) {
                        return null;
                      }

                      return (
                        <li key={field.id} className="printheadshot-field">
                          <span className="printheadshot-field-name">{field.name}: </span>
                          <span className="printheadshot-field-value">{value} {units}</span>
                        </li>
                      );
                    })
                  }
                </div>
              )}
            </ul>
          </div>

        </div>

        <p className="printheadshot-contact">
          {agency.name}<br/>
          <strong>{agency.contactName || ""}</strong><br/>
          <strong>{formatPhoneNumber(agency.primaryPhone || "")}</strong><br/>
          {agency.displayEmail || agency.contactEmail || ""}<br/>
          {agency.website || ""}<br/>
        </p>
      </div>
    );
  }
}







export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(PrintHeadshot));