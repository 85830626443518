import React, { useState, useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import {
  useQuery,
  createId,
  createEpayAccount,
  updateEpayAccount,
  chargeEpayAccount,
  updateTalent,
  updateUser,
} from 'skybolt-api';
import {
  useRouter,
  ThemeContext,
  Loading,
  Text,
  Rule,
  Card,
  Form,
  InputText,
  Button,
  Modal,
  Redirect,
} from 'skybolt-ui';
import moment from 'moment';



export default function Payment() {

  const router = useRouter();
  const talentId = router.match.params.talentId;

  const data = useQuery(`{
    talent(talentId:$talentId) {
      agencyId
      epayId
      legacyId
      userId
      agencyId
      code
      requiredSubscription(status:"PAID")
      status
      approved
      agency {
        requireTalentSubscription
        requireUserSubscription
        requireTalentApproval
      }
      user {
        talents {
          talentId,
          dateExpires
          status
        }
      }
    }
  }`, {talentId});


  // Handle form data, errors, and submissions.

  const [value, setValue] = useState({});
  const [error, setError] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = val => {
    setValue(val);
    setError("");
  };

  const handleSubmit = async () => {
    if(isSubmitting) {
      return;
    }
    setSubmitting(true);

    let {
      epayId,
      amount,
      next,
      cycle,
      setup,
      initial,
      commission,
    } = data.talent.requiredSubscription;

    let confirm = null;
    if(setup && initial) {
      confirm = `
        You will be charged a setup fee of $${parseFloat(setup).toFixed(2)} plus
        the first month for a total of $${parseFloat(setup + initial).toFixed(2)}
        today, and $${parseFloat(amount).toFixed(2)} every ${cycle} until you cancel.
      `;
    }
    else if(setup) {
      confirm = `
        You will be charged a setup fee of $${parseFloat(setup).toFixed(2)},
        then $${parseFloat(amount).toFixed(2)} on ${moment(next).format("MM/DD/YYYY")}
        and every ${cycle} until you cancel.
      `;
    }
    else if(initial) {
      confirm = `
        You will be charged $${parseFloat(initial).toFixed(2)} for the
        first month, and $${parseFloat(amount).toFixed(2)} every ${cycle} until
        you cancel.
      `;
    }
    else if(amount) {
      confirm = `
        You will be charged $${parseFloat(amount).toFixed(2)} every ${cycle} until
        you cancel.
      `;
    }
    if(confirm) {
      const confirmed = await Modal.confirm(<div>
        <Text bold paragraph>Please Confirm</Text>
        <Text>{confirm}</Text>
      </div>);
      if(!confirmed) {
        setSubmitting(false);
        return;
      }
    }

    try {
      if(!epayId) {
        epayId = data.talent.legacyId;
        if(!epayId) {
          epayId = await createId();
        }
        await createEpayAccount({
          epayId,
          amount,
          cycle,
          next: data.talent.requiredSubscription.next,
          note: `
            Agency: ${data.talent.agencyId}
            URL: ${
              data.talent.agency.requireTalentSubscription ? `https://beta.skybolt.net/talents/${talentId}/settings` :
              data.talent.agency.requireUserSubscription ? `https://beta.skybolt.net/agencies/${data.talent.agencyId}` :
              ``
            }
          `,
          ...value
        });
        if(setup + initial > 0) {
          await chargeEpayAccount({epayId, amount:setup + initial});
        }
        if(data.talent.agency.requireTalentSubscription) {
          await updateTalent({talentId, change:{epayId}});
        }
        if(data.talent.agency.requireUserSubscription) {
          await updateUser({userId:data.talent.userId, change:{epayId}});
        }
        if(data.talent.agency.requireTalentApproval && !data.talent.approved) {
          await firebase.database().ref(`talentsForApproval/${data.talent.agencyId}/${talentId}`).set(true);
        }
      }
      else {
        await updateEpayAccount({
          ...data.talent.requiredSubscription,
          card: {
            number: value.number,
            expires: value.exp,
            code: value.code,
            zip: value.zip,
          },
          note: `
            Agency: ${data.talent.agencyId}
            Code: ${data.talent.code}
            URL: https://beta.skybolt.net/talents/${talentId}/settings
          `,
        });
        if(data.talent.agency.requireUserSubscription) {
          for(const userTalent of data.talent.user.talents) {
            await updateTalent({talentId:userTalent.talentId, change:{dateExpires:null}});
          }
        }
      }

      await updateTalent({
        talentId,
        update:{
          status:"PAID",
          statusHistory: {
            [Date.now()]: "PAID by user",
          },
          commission,
          requireSubscription: null,
          dateExpires: null,
        }
      });

    }
    catch(error) {
      console.log(error);
      setError(error.message || "Something went wrong. Please try again or contact support.");
      setSubmitting(false);
      return;
    }

    router.history.replace(`/talents/${talentId}`);

  };






  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  if(
    data.talent.requiredSubscription.error &&
    data.talent.requiredSubscription.error.field === 'plan'
  ) {
    return <Redirect to="plan"/>;
  }

  return (
    <div style={{width:'100%'}}>
      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          padding: '40px 16px 80px 16px',
          marginBottom: -80,
          textAlign: 'center',
          backgroundColor: theme.color.accent
        }}>
        <Text style={{fontSize:"60px", lineHeight:'.9em', color:'white'}}>{data.talent.requiredSubscription.plan.name}</Text><br/>
        <Rule small color="white" style={{margin:'32px auto 24px'}}/>
        <Text style={{color:theme.color.lightTextLighter, textAlign:'center', fontSize:16, lineHeight:'18px', marginBottom:32}}>
          Enter your payment information.
        </Text>

      </div>

      <Card
        depth={1}
        style={{
          maxWidth:600,
          padding:16,
          border: 0,
          borderTop: `20px solid ${theme.color.tint(theme.color.accent, 40)}`,
          margin:'0 auto 40px auto',
        }}>

        <Form value={value} onChange={handleChange}>

          <div style={{display:'flex'}}>
            <InputText
              title="First Name"
              name="firstName"
              style={{marginRight:8, flex:1}}
            />
            <InputText
              title="Last Name"
              name="lastName"
              style={{flex:1}}
            />
          </div>

          <InputText
            title="Street Address"
            name="street"
          />

          <div style={{display:'flex'}}>
            <InputText
              title="City"
              name="city"
              style={{marginRight:8, flex:4}}
            />
            <InputText
              title="State"
              name="state"
              clean={v => v.slice(0, 2)}
              style={{marginRight:8, flex:1}}
            />
            <InputText
              title="Zip"
              name="zip"
              style={{flex:2}}
            />
          </div>

          <InputText
            title="Credit Card Number"
            name="number"
          />

          <div style={{display:'flex'}}>
            <InputText
              title="Expiration Date"
              hint="MM/YYYY"
              name="exp"
              style={{flex:2, maxWidth:200, marginRight:8}}
            />
            <InputText
              title="CVV"
              name="code"
              style={{flex:1, maxWidth:80, marginRight:8}}
              clean={v => v.replace(/[^0-9.]*/g,"").slice(0, 6)}
            />
          </div>

          <div style={{display:'flex', alignItems:'center', paddingTop:16}}>

            <Button
              accent={!isSubmitting}
              raised
              label={!isSubmitting ? "Submit" : "Hang Tight..."}
              onClick={handleSubmit}
              style={{marginRight:16}}
            />

            <Text error>{error}</Text>

          </div>

        </Form>

      </Card>
    </div>
  );
}