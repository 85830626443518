/*

# Address Field

Usage:

<AddressField 
  value={{
    street,
    city,
    state,
    zip
  }}
  onChange={address => null}
/>

*/


import React from 'react';

import TextField from 'material-ui/TextField';

export default props => {
  const address = {
    street: "",
    city: "",
    state: "",
    zip: "",
    ...props.value,
  };
  
  const emit = update => {
    props.onChange({...address, ...update});
  };
  
  
  
  return (
    <div style={props.style}>
      <div>
        <TextField
          floatingLabelText={props.showFloatingLabels !== false ? "Street Address" : ""}
          hintText={props.showFloatingLabels === false ? "Street Address" : ""}
          fullWidth
          style={{maxWidth:600}}
          value={address.street}
          onChange={(e, street) => emit({street})}
        />
      </div>
      
      <div>
        <TextField
          style={{width:'40%', maxWidth:300, minWidth:100}}
          floatingLabelText={props.showFloatingLabels !== false ? "City" : ""}
          hintText={props.showFloatingLabels === false ? "City" : ""}
          value={address.city}
          onChange={(e, city) => emit({city})}
        />
        
        <TextField
          style={{width:'30%', maxWidth:150, minWidth:60}}
          floatingLabelText={props.showFloatingLabels !== false ? "State" : ""}
          hintText={props.showFloatingLabels === false ? "State" : ""}
          value={address.state}
          onChange={(e, state) => {
            state = state.slice(0, 2);
            emit({state});
          }}
        />
        
        {props.showZip !== false && 
          <TextField
            style={{width:'30%', maxWidth:150, minWidth:80}}
            floatingLabelText={props.showFloatingLabels !== false ? "Zip" : ""}
            hintText={props.showFloatingLabels === false ? "Zip" : ""}
            value={address.zip}
            onChange={(e, zip) => emit({zip})}
          />
        }
      </div>
    </div>
  );
};