import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { 
  updateTalent,
  addTalentExperience,
  updateTalentExperience,
  removeTalentExperience,
} from 'actions';
import { resumeSections } from 'config';

import Page from 'ui/Page';
import Title, { Action } from 'ui/Title';
import Loading from 'ui/Loading';
import Button from 'ui/Button';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Placeholder from 'ui/Placeholder';
import Icon from 'ui/Icon';

import {
  SortableContainer, 
  SortableHandle,
  SortableElement, 
} from 'react-sortable-hoc';

function isTouchDevice() {
  return 'ontouchstart' in document.documentElement;
}

function hasClass(element, name) {
  return (' ' + element.className + ' ').indexOf(' ' + name + ' ') > -1;
}

function getScrollParent(node) {
  if (node == null) {
    return null;
  }
  
  if(node.scrollHeight > node.clientHeight) {
    return node;
  }
  
  if(hasClass(node, 'scroll-container')) {
    return node;
  } 
  
  return getScrollParent(node.parentNode);
}


const DragHandle = SortableHandle(() =>(
  <div style={{margin: "16px 16px 0 0"}}>
    <Icon name="dragHand" size={12}/>
  </div>
));

class ExperienceListComponent extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        {children}
      </div>
    );
  }
}

const ExperienceList = SortableContainer(ExperienceListComponent, {withRef:true});

const ExperienceLI = SortableElement(({id, item, sections, onChange, onRemove}) => (
  <div>
    <div style={{display:'flex', fontSize:12, marginBottom:8}}>
      
      <DragHandle/>
      
      <div style={{display:'flex', alignItems:'flex-start', flexWrap:"wrap"}}>
        
        <SelectField
          style={{flex:1, minWidth:200}}
          hintText="Type"
          value={item.type}
          onChange={(e, i, type) => onChange({...item, type})}>
          
          {sections.map(i => 
            <MenuItem value={i} primaryText={i}/>
          )}
          
        </SelectField>
        
        <TextField
          style={{flex:1, minWidth:200}}
          hintText="Title"
          value={item.title}
          onChange={(e, title) => onChange({...item, title})}
        />
        
        <TextField
          style={{flex:1, minWidth:200}}
          hintText="Role"
          value={item.role}
          onChange={(e, role) => onChange({...item, role})}
        />
        
        <TextField
          style={{flex:1, minWidth:200}}
          hintText="Company"
          value={item.company}
          onChange={(e, company) => onChange({...item, company})}
        />
        
      </div>
      
      <Button
        style={{flex:0}}
        icon="remove"
        onClick={onRemove}
      />
      
    </div>
  </div>
));




export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.talentId;
  const talent = state.talents.all[talentId];
  const agencyId = talent.agencyId;
  const sections = get(state, `agencies.all[${agencyId}].resumeSections`, resumeSections);
  
  return {
    talentId,
    talent,
    sections,
  };
};

export const mapDispatchToProps = {
  updateTalent,
  addTalentExperience,
  updateTalentExperience,
  removeTalentExperience,
};


export class TalentResumeForm extends Component {
  
  handleSort = ({oldIndex, newIndex}, e) => {
    const talentId = this.props.talentId;
    const experience = this.props.talent.experience;
    let keys = Object.keys(experience)
      .sort((a, b) => {
        const aOrder = experience[a].order || 0;
        const bOrder = experience[b].order || 0;
        return aOrder > bOrder ? 1 : -1;
      });
    
    let [key] = keys.splice(oldIndex, 1);
    keys.splice(newIndex, 0, key);
    
    keys.forEach((key, i) => experience[key].order = i);
    
    this.props.updateTalent(talentId, {experience});
  }
  
  render() {
    
    if(!this.props.talent) {
      return <Loading/>;
    }
    
    const {
      talentId,
      talent,
      updateTalent,
      addTalentExperience,
      updateTalentExperience,
      removeTalentExperience,
      sections,
    } = this.props;
    
    
    const skills = talent.skills || "";
    const experience = talent.experience || {};
    const bio = talent.bio || "";
    
    const listProps = {};
    if(!this.props.useWindowAsScrollContainer) {
      listProps.getContainer = () => getScrollParent(findDOMNode(this));
    }
    
    return (
      <div>
        <Page pad={true}>
          <TextField
            name="bio"
            fullWidth
            defaultValue={bio}
            multiLine={true}
            onChange={(e, bio) => updateTalent(talentId, {bio})}
            hintText="Enter your biography here."
            underlineShow={false}
          />
        </Page>
        
        <Title 
          actions={[
            <Action onClick={()=>addTalentExperience(talentId)}/>
          ]}>
          Experience
        </Title>
        <Page pad={true}>
          <ExperienceList
            {...listProps}
            useWindowAsScrollContainer={this.props.useWindowAsScrollContainer}
            getContainer={(component) => {
              let $el = findDOMNode(component);
              let $scroller = getScrollParent($el);
              return $scroller || window;
            }}
            axis="y"
            helperClass="is-dragging"
            pressDelay={isTouchDevice() ? 200 : 0}
            useDragHandle={true}
            onSortEnd={this.handleSort}
            >
            {Object.keys(experience).length === 0 &&
              <Placeholder 
                icon="resume"
                message="List your experience."
                label="Add Experience"
                onClick={()=>addTalentExperience(talentId)}
              />
            }
          
            {Object.keys(experience)
              .sort((a, b) => {
                const aOrder = experience[a].order || 0;
                const bOrder = experience[b].order || 0;
                return aOrder > bOrder ? 1 : -1;
              })
              .map((experienceId, i) => (
                <ExperienceLI 
                  key={experienceId}
                  id={experienceId}
                  item={experience[experienceId]}
                  index={experience[experienceId].order || i}
                  onChange={update => updateTalentExperience(talentId, experienceId, update)}
                  onRemove={()=> removeTalentExperience(talentId, experienceId)}
                  sections={sections}
                />
              ))
            }
          </ExperienceList>
        </Page>
        
        <Title>Skills</Title>
        <Page pad={true}>
          <TextField
            name="skills"
            fullWidth
            defaultValue={skills}
            multiLine={true}
            onChange={(e, skills) => updateTalent(talentId, {skills})}
            hintText="Enter your special skills"
            underlineShow={false}
          />
        </Page>
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(TalentResumeForm);