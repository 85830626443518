import firebase from 'firebase/app';
import 'firebase/functions';
import React, {
  useContext,
  useRef,
  useState,
} from 'react';
import {
  useQuery,
  createChecks,
} from 'skybolt-api';
import {
  useRouter,
  Loading,
  Container,
  Text,
  Rule,
  Button,
  InputSwitch,
  InputNumber,
  InputSelect,
  Card,
  Icon,
  Currency,
  Drawer,
  ThemeContext,
  Modal,
} from 'skybolt-ui';
import moment from 'moment-timezone';
import print from 'print-js';


function HTML({children, ...props}) {
  children = children || "";
  children = children.replace(/ ?contenteditable="true"/gi, "");
  return (
    <div dangerouslySetInnerHTML={{__html:children}} {...props}/>
  );
}



export default function ChecksList(props) {

  // Manage Data

  const router = useRouter();
  const { state={}, pathname } = router.location;
  const { selection=[], expanded=[] } = state;

  const data = useQuery(`{
    agency {
      agencyId
      useBilling
      billingOrgId
      timezone
    }
    checks(status:"DRAFT") {
      checkId
      is1099
      talentId
      talentName
      talentImage
      debitIds
      debits {
        is1099
        debitId
        dateJob
        dateCheck
        status
        invoiceId
        invoiceNumber
        clientId
        clientName
        packId
        packName
        comment
        grossAmount
        taxAmount
        otherAmount
        agencyAmount
        amount
      }
      amount
    }
    user {
      permissions {
        canAgencyBilling
        canAgencyBillingReports
      }
    }
  }`);

  let timezone = data?.agency?.timezone || moment.tz.guess();


  // Handle Selections

  const setQuery = (update) => props.history.replace(pathname, {...state, ...update});

  const isCheckSelected = check => {
    for(let checkDebitId of check.debitIds) {
      for(let selectedDebitId of selection) {
        if(checkDebitId === selectedDebitId) {
          return true;
        }
      }
    }
    return false;
  };
  const selectCheck = check => {
    if(!check.debitIds) {
      return;
    }
    if(isCheckSelected(check)) {
      setQuery({selection: selection.filter(s => check.debitIds.indexOf(s) === -1)});
    }
    else {
      setQuery({selection: [...selection, ...check.debitIds]});
    }
  };

  const isDebitSelected = debit => selection.indexOf(debit.debitId) > -1;
  const selectDebit = debit => {
    if(isDebitSelected(debit)) {
      setQuery({selection: selection.filter(id => id !== debit.debitId)});
    }
    else {
      setQuery({selection: [...selection, debit.debitId]});
    }
  };

  const isAllSelected = () => {
    for(const check of data.checks) {
      for(const debitId of check.debitIds) {
        if(selection.indexOf(debitId) === -1) {
          return false;
        }
      }
    }
    return true;
  };
  const toggleSelectAll = () => {
    if(isAllSelected()) {
      setQuery({selection:[]});
    }
    else {
      setQuery({selection: data.checks.reduce((all, check) => [...all, ...check.debitIds], [])});
    }
  };

  let selectionTotal = 0;
  if(data) {
    selectionTotal = data.checks.reduce((total, check) => {
      if(!selection.length || isCheckSelected(check)) {
        return total + check.amount
      }
      return total;
    }, 0);
  }


  // Handle expanding check debits.

  const isExpanded = check => expanded.indexOf(check.checkId) > -1;

  const toggleExpanded = check => {
    if(isExpanded(check)) {
      setQuery({expanded: expanded.filter(id => id !== check.checkId)});
    }
    else {
      setQuery({expanded: [...expanded, check.checkId]});
    }
  };


  // Handle Check Printing

  const [isPrinting, setPrinting] = useState(false);
  const printFrame = useRef();

  async function printChecks() {
    const confirmation = await Modal.form(
      <div>
        <Text paragraph>Are you sure you want to print selected checks?</Text>
        <Text paragraph>Please enter the starting check number.</Text>
        <InputNumber title="Starting Check Number" name="number"/>
      </div>
    );

    if(!confirmation) {
      return;
    }

    if(!confirmation.number) {
      await Modal.error("You must enter a starting check number.");
      return;
    }

    setPrinting(true);


    // old way

    // let createdChecks = [];
    // try {
    //   createdChecks = await createChecks({debitIds:selection, startingNumber:confirmation.number});
    //   setQuery({selection:[]});
    // }
    // catch(error) {
    //   await Modal.error("Something went wrong. Please try again.");
    //   return;
    // }

    // const url = `https://beta.skybolt.net/f/checksPDF?agencyId=${data.agency.agencyId}&number=${confirmation.number}&limit=${createdChecks.length}`;
    // const frame = printFrame.current;
    // frame.onload = function() {
    //   frame.contentWindow.print();
    //   setPrinting(false);
    // };
    // frame.src = url;


    let url = 'https://us-central1-skybolt-99adb.cloudfunctions.net/printDebits';
    let payload = {
      agencyId: data.agency.agencyId,
      number: confirmation.number,
      debitIds: selection
    };
    let options = {
      method: 'post',
      body: JSON.stringify(payload),
      headers: {
      'Content-Type': 'application/json'
      },
    }

    let res = await fetch(url, options);
    let blob = await res.blob();

    
    await new Promise(resolve => {
      let url = URL.createObjectURL(blob);

      printFrame.current.onload = function() {
        printFrame.current.contentWindow.print();
        window.URL.revokeObjectURL(url)
        resolve();
      };

      printFrame.current.src = '';
      printFrame.current.src = url;
      
      // to download: download
      // let link = document.createElement('a');
      // link.href = objectURL;
      // link.download = "test.pdf";
      // link.click();

    });

    let doPay = await Modal.confirm("Did the checks print correctly?", {yes:"Yes, mark checks as paid.", no:"No"});

    if(doPay) {
      try {
        await firebase.functions().httpsCallable('payDebits')(payload);
      }
      catch(error) {
        Modal.error(error.message);
      }
    }

    setPrinting(false);

  }

  async function sendPayments() {
    const confirmation = await Modal.confirm(
      <div>
        <Text paragraph>Are you sure you want to send selected payments?</Text>
      </div>
    );

    if(!confirmation) {
      return;
    }

    let createdChecks = await createChecks({debitIds:selection, startingNumber:confirmation.number});
    setQuery({selection:[]});

    await Modal.alert(`Sent ${createdChecks.length} payments.`);
  }



  // Filters

  let checks = data ? data.checks : [];


  const [selectedTalent, setSelectedTalent] = useState(null);
  const allTalent = data && data.checks.reduce((all, check) => {
    if(!all.find(option => option.value === check.talentId)) {
      return [...all, {value:check.talentId, label:check.talentName}];
    }
    return all;
  }, []);
  if(selectedTalent) {
    checks = checks.filter(check => check.talentId === selectedTalent);
  }





  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return (
      <div style={{width:"100%"}}>
        <Container>
          <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
            <Text title>ACCOUNTS PAYABLE</Text>
            <div style={{flex:1}}/>
            <Button title icon="plus"/>
          </div>
          <Rule/>
          <div style={{marginBottom:24}}>
            <Button label="Pending" href="pending" style={{borderRadius:0, backgroundColor:theme.color.greyLight}}/>
            <Button label="Scheduled" href="scheduled" style={{borderRadius:0, backgroundColor:theme.color.primaryLighter}}/>
            <Button label="Sent" style={{borderRadius:0, backgroundColor:theme.color.greyLight}}/>
          </div>
        </Container>
        <Loading style={{paddingTop:40}}/>
      </div>
    );
  }

  return (
    <div style={{width:"100%"}}>

      {/* Title */}

      <Container>
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>ACCOUNTS PAYABLE</Text>
          <div style={{flex:1}}/>
          <InputSelect
            onRule
            placeholder="Talent..."
            options={allTalent}
            clearable="All"
            value={selectedTalent}
            onChange={setSelectedTalent}
          />
          {!data.agency.billingOrgId ? (
            isPrinting ? (
              <Text light style={{lineHeight:'30px', marginRight:16}}>Printing...</Text>
            ) : (
              selection.length > 0 && (
                <Button
                  icon="print"
                  label="Print Checks"
                  onClick={printChecks}
                  style={{
                    background:theme.color.grey,
                    color: 'white',
                    borderRadius: 0,
                    marginRight: 4,
                  }}
                />
              )
            )
          ) : (
            isPrinting ? (
              <Text light style={{lineHeight:'30px', marginRight:16}}>Sending...</Text>
            ) : (
              selection.length > 0 && (
                <Button
                  icon="send"
                  label="Send Payment"
                  onClick={sendPayments}
                  style={{
                    background:theme.color.grey,
                    color: 'white',
                    padding: '13px 16px 11px 10px',
                    borderRadius: 0,
                    marginRight: 4,
                  }}
                />
              )
            )
          )}
          <Button title icon="plus" href={{pathname:"/billing/debits/create", state:{referrer:pathname}}}/>
        </div>
        <Rule/>
        <div style={{marginBottom:24}}>
          <Button
            label="Pending"
            href="pending"
            style={{
              borderRadius: 0,
              backgroundColor: theme.color.greyLight,
            }}
          />
          <Button
            label="Scheduled"
            style={{
              borderRadius: 0,
              backgroundColor: theme.color.primaryLighter,
            }}
          />
          <Button
            label="Sent"
            href="sent"
            style={{
              borderRadius: 0,
              backgroundColor: theme.color.greyLight,
            }}
          />
        </div>
      </Container>


      {/* Container that forces horizontal scroll on mobile. */}

      <div style={{width:'100%', overflowX:'auto'}}>
        <Container style={{minWidth:900}}>


          {/* Table Heading */}

          <div style={{display:'flex', alignItems:'center'}}>
            <div style={{width:32}}>
              <InputSwitch
                value={isAllSelected}
                onChange={toggleSelectAll}
                style={{margin:0}}
              />
            </div>
            <div style={{flex:1}}>
              <Text small light style={{lineHeight:'32px'}}>Talent</Text>
            </div>
            <div style={{width:150, paddingLeft:8}}>
              <Text small light style={{lineHeight:'32px'}}>Type</Text>
            </div>
            <div style={{width:150, paddingLeft:8}}>
              <Text small light style={{lineHeight:'32px'}}>Payments</Text>
            </div>
            <div style={{width:150}}>
              <Text small light style={{lineHeight:'32px'}}>Amount</Text>
            </div>
          </div>


          {/* Checks */}

          {checks.length === 0 &&
            <div style={{padding:"32px 0", display:'flex', flexDirection:'column', alignItems:'center'}}>
              <Icon name="money-check-edit-alt" size={80} color="accentLighter" style={{marginBottom:16}}/>
              <Text paragraph style={{maxWidth:400, textAlign:'center'}}>
                There are no pending payments.
              </Text>
              <Button
                raised accent
                label="Create a Payment"
                href={{pathname:"/billing/debits/create", state:{referrer:pathname}}}
              />
            </div>
          }

          {checks.map((check, i) =>
            <div key={check.checkId} style={{display:'flex', alignItems:'flex-start'}}>
              <div style={{width:32, paddingTop:14}}>
                <InputSwitch style={{margin:0}} value={isCheckSelected(check)} onChange={()=>selectCheck(check)}/>
              </div>
              <Card style={{flex:1, marginBottom:4}}>

                <div style={{display:'flex', alignItems:'center', height:40, backgroundColor: isCheckSelected(check) ? theme.color.accentLighter : 'white'}}>

                  {/* Talent */}
                  <div style={{flex:1, display:'flex', alignItems:'center'}}>
                    {check.talentImage &&
                      <img src={check.talentImage} alt="headshot" style={{width:40, height:40, marginRight:8}}/>
                    }
                    <Text>{check.talentName}</Text>
                  </div>

                  {/* Source */}
                  <div style={{width:150, paddingLeft:8}}>
                    <Text>{check.is1099 ? "Invoice" : "Timecard"}</Text>
                  </div>

                  {/* Payments */}
                  <Button onClick={()=>toggleExpanded(check)} style={{width:150, alignSelf:'stretch', display:'flex', alignItems:'center', justifyContent:'flex-start', padding:0, paddingLeft:8}}>
                    <Text>{check.debitIds.length} {check.debitIds.length === 1 ? "payment" : "payments"} <Icon name="caret-down" small color="textLight" style={{marginLeft:4}}/></Text>
                  </Button>

                  {/* Amount */}
                  <div style={{width:150}}>
                    <Currency bold>
                      {isCheckSelected(check) ? (
                        check.debits.reduce(
                          (amount, debit) => {
                            if(isDebitSelected(debit)) {
                              amount += parseFloat(debit.amount);
                            }
                            return amount;
                          },
                          0
                        )
                      ) : (
                        check.amount
                      )}
                    </Currency>
                  </div>
                </div>


                {/* Drawer reveals linked debits. */}

                <Drawer open={isExpanded(check)}>
                  <div style={{padding:"16px 0"}}>
                    <div style={{display:'flex'}}>
                      <div style={{width:48}}/>
                      <div style={{width:100}}>
                        <Text small light>Pay Date</Text>
                      </div>
                      <div style={{width:100}}>
                        <Text small light>Invoice No.</Text>
                      </div>
                      <div style={{flex:1}}>
                        <Text small light>Package</Text>
                      </div>
                      <div style={{flex:1}}>
                        <Text small light>Client</Text>
                      </div>
                      <div style={{flex:1}}>
                        <Text small light>Comment</Text>
                      </div>
                      <div style={{width:100}}>
                        <Text small light>Gross</Text>
                      </div>
                      <div style={{width:100}}>
                        <Text small light>Tax</Text>
                      </div>
                      <div style={{width:100}}>
                        <Text small light>Other</Text>
                      </div>
                      <div style={{width:100}}>
                        <Text small light>Agency</Text>
                      </div>
                      <div style={{width:100}}>
                        <Text small light>Net</Text>
                      </div>
                    </div>
                    <Rule style={{margin:"0 16px 0 48px"}} color={theme.color.greyLight}/>
                    {check.debits.map(debit =>
                      <div key={debit.debitId} style={{minHeight:40, display:'flex', alignItems:'center'}}>
                        <div style={{width:32, paddingLeft:16}}>
                          <InputSwitch style={{margin:0}} value={isDebitSelected(debit)} onChange={() => selectDebit(debit)}/>
                        </div>
                        <Button href={{pathname:`/billing/debits/${debit.debitId}`, state:{...state, referrer:pathname}}} style={{flex:1, display:'flex', minHeight:40, padding:0}}>
                          <div style={{width:100}}>
                            {debit.is1099 ? (
                              <Text>{debit.dateJob ? moment(debit.dateJob).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
                            ):(
                              <Text>{debit.dateCheck ? moment(debit.dateCheck).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
                            )}
                          </div>
                          <Button href={debit.invoiceId ? `/billing/invoices/${debit.invoiceId}` : null} style={{width:100, padding:"8px 0"}}>
                            <Text>{debit.invoiceNumber}</Text>
                          </Button>
                          <Button href={debit.packId ? `/packs/${debit.packId}` : null} style={{flex:1, padding:"8px 0"}}>
                            <Text>{debit.packName}</Text>
                          </Button>
                          <Button href={debit.clientId ? `/billing/clients/${debit.clientId}` : null} style={{flex:1, padding:"8px 0"}}>
                            <Text>{debit.clientName}</Text>
                          </Button>
                          <HTML style={{flex:1, padding:"4px 0"}}>
                            {debit.comment}
                          </HTML>
                          <div style={{width:100}}>
                            <Currency>{debit.grossAmount}</Currency>
                          </div>
                          <div style={{width:100}}>
                            <Currency>{debit.taxAmount}</Currency>
                          </div>
                          <div style={{width:100}}>
                            <Currency>{debit.otherAmount}</Currency>
                          </div>
                          <div style={{width:100}}>
                            <Currency>{debit.agencyAmount}</Currency>
                          </div>
                          <div style={{width:100}}>
                            <Currency>{debit.amount}</Currency>
                          </div>
                        </Button>
                      </div>
                    )}
                  </div>
                </Drawer>

              </Card>
            </div>
          )}

          {data.user.permissions.canAgencyBillingReports && 
            <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-end', padding:16}}>
              <div style={{flex:'0 0 auto', minWidth:175}}>
                <Rule style={{marginBottom:8}}/>
                <Text block>Total: &nbsp;&nbsp;<Currency large bold>{selectionTotal}</Currency></Text>
                {!!selection.length && <Text small block>For selected checks.</Text>}
              </div>
            </div>
          }

        </Container>
      </div>
      <iframe type="application/pdf" title="Print Frame" ref={printFrame} style={{display:'none'}}/>
    </div>
  );

}
