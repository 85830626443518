import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import muiThemeable from 'material-ui/styles/muiThemeable';
import {
  loadTalentMedia,
  showSnackbar,
  deactivateTalent,
  addTalentImage,
} from 'actions';
import { uniq, get } from 'lodash';
import { mediaFormats } from 'config';

import Header, { Action, Tab, Image } from 'ui/Header';
import Popover, { Menu, MenuItem } from 'ui/Popover';
import Dialog from 'ui/Dialog';
import Button from 'ui/Button';
import Media from 'ui/Media';
import Loading from 'ui/Loading';
import { Icon } from 'skybolt-ui';



export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const packId = !!ownProps.location.state && ownProps.location.state.packId;

  const url = ownProps.match.url;
  const path = ownProps.match.path;
  const location = ownProps.location;

  const user = state.users.all[state.user.id] || {};
  const isLoggedIn = state.user.isLoggedIn;
  const permissions = state.user.permissions;

  const talent = ownProps.talent ? ownProps.talent :
                 talentId ? state.talents.all[talentId] :
                 {};

  const pack = ownProps.pack ? ownProps.pack :
               packId ? state.packs.all[packId] :
               null;

  const packTalents = (!!pack && state.packTalents.byPack[pack.id]) || {};
  // const activeCategoryId = state.activeCategoryId;

  const isInPack =
    // there is a pack
    !!pack

    // this talent is in the pack
    && Object.keys(packTalents).indexOf(talent.id) !== -1

    // and if there's a category, is the talent in the category?
    // && (!activeCategoryId || packTalents[talent.id].category === activeCategoryId)
  ;

  let media = [];
  if(isInPack) {
    media = packTalents[talent.id].media;
  }
  else {
    media = Object.keys(state.media.byTalent[talent.id] || {})
      .map(key => state.media.byTalent[talent.id][key])
      .filter(media => !!media.active)
      .filter(media => !!media.approved)
      .filter(media => !media.format || mediaFormats.indexOf(media.format) !== -1)
      .sort((a, b) => a.order < b.order ? -1 : 1);
  }


  const agency = talent.agencyId && state.agencies.all[talent.agencyId];

  const isOwnTalent = isLoggedIn && !!user && !!user.talents && !!user.talents[talent.id];
  const isOwnAgency = talent.agencyId === user.agencyId;
  const isShortlisted = isInPack && packTalents[talent.id].isSelected;
  const isPaid = talent.status === 'PAID';
  const canEdit = isOwnTalent || (permissions.canEditTalents && isOwnAgency) || permissions.canAdminTalents;
  const canSideline = false; //permissions.canEditTalents && isOwnAgency;
  const canMessage = permissions.canMessageTalents;
  const canAdminTalents = permissions.canAdminTalents;
  const useBilling = agency && agency.useBilling;
  const canResume = agency ? agency.useTalentResume !== false : true;
  const canMedia = isPaid || isOwnTalent;

  let isLoading = (packId && !pack) ||
                  (pack && pack.isLoading) ||
                  (!talent || talent.isLoading) ||
                  (user.isLoading);

  return {
    url,
    path,
    location,
    talentId,
    talent,
    pack,
    media,
    user,

    isLoading,

    isLoggedIn,
    permissions,

    isInPack,
    isShortlisted,
    isOwnTalent,
    isOwnAgency,
    isPaid,
    canResume,
    canEdit,
    canMedia,
    canMessage,
    canSideline,
    canAdminTalents,
    useBilling,
  };
};

export const mapDispatchToProps = {
  loadTalentMedia,
  showSnackbar,
  deactivateTalent,
  addTalentImage,
};



export class TalentHeader extends Component {

  state = {
    isDeactivateDialogOpen: false,
    isMediaDialogOpen: false,
    isPrintPopoverOpen: false,
    isHeadshotEnabled: false,
    anchorEl: null,
    selectedMedia: [],
  }

  async componentDidMount() {
    // if(this.props.talent?.status == "TRIAL") {
    //   let isHeadshotEnabled = true;
    //   let isImageEnabled = false;
    //   this.setState({isImageEnabled, isHeadshotEnabled});
    //   return;
    // }

    if(this.props.talent.canHeadshot || this.props.talent.canMedia) {
      this.setState({
        isHeadshotEnabled: !!this.props.talent.canHeadshot,
        isImageEnabled: !!this.props.talent.canMedia,
      });
      return;
    }

    if(!this.props.user || !this.props.user.stripeCustomerId || !window.skyboltsnow) {
      return;
    }
    let sub = await window.skyboltsnow.subscription(this.props.talent.userId);
    let unsubscribe = sub?.subscribe(async subscription => {
      if(subscription.isLoading) {
        return;
      }
      let isHeadshotEnabled = await window.skyboltsnow.canTalentFeature(this.props.talentId, 'headshot');
      let isImageEnabled = await window.skyboltsnow.canTalentFeature(this.props.talentId, 'image');
      this.setState({isImageEnabled, isHeadshotEnabled});
    });
    let isHeadshotEnabled = await window.skyboltsnow.canTalentFeature(this.props.talentId, 'headshot');
    let isImageEnabled = await window.skyboltsnow.canTalentFeature(this.props.talentId, 'image');
    this.setState({isImageEnabled, isHeadshotEnabled});

    return unsubscribe;
  }



  openPrintPopover = e => this.setState({isPrintPopoverOpen:true, anchorEl:e.currentTarget})
  closePrintPopover = () => this.setState({isPrintPopoverOpen:false})

  openMediaDialog = e => {
    this.props.loadTalentMedia(this.props.talent.id);
    this.setState({isMediaDialogOpen:true, isPrintPopoverOpen:false});
  }
  closeMediaDialog = () => this.setState({isMediaDialogOpen:false, selectedMedia:[]})

  openDeactivateDialog = e => this.setState({isDeactivateDialogOpen:true})
  closeDeactivateDialog = () => this.setState({isDeactivateDialogOpen:false})

  printHeadshot = () => {
    const stopPrinting = () => {
      this.props.showSnackbar(null);
      document.removeEventListener('PRINTING', stopPrinting);
    };

    document.addEventListener('PRINTING', stopPrinting);
    this.printFrame.src = `/print-headshot/${this.props.talent.id}`;

    this.setState({isPrintPopoverOpen:false});
    this.props.showSnackbar("Printing...", 0);
  }

  printMedia = () => {
    const stopPrinting = () => {
      this.props.showSnackbar();
      document.removeEventListener('PRINTING', stopPrinting);
    };

    const media = this.state.selectedMedia;

    document.addEventListener('PRINTING', stopPrinting);
    this.printFrame.src = `/print-media/${this.props.talent.id}?media=${media.join(',')}`;

    this.setState({isMediaDialogOpen:false});
    this.props.showSnackbar("Printing...", 0);
  }

  selectMedia = media => {
    this.setState({selectedMedia: uniq([...this.state.selectedMedia, media]).slice(0,4)});
  }

  printResume = () => {
    const stopPrinting = () => {
      this.props.showSnackbar();
      document.removeEventListener('PRINTING', stopPrinting);
    };

    document.addEventListener('PRINTING', stopPrinting);
    this.printFrame.src = `/print-resume/${this.props.talent.id}`;

    this.setState({isPrintPopoverOpen:false, selectedMedia:[]});
    this.props.showSnackbar("Printing...", 0);
  }

  deactivate = () => {
    this.props.deactivateTalent(this.props.talent.id);
    this.closeDeactivateDialog();
  }




  renderImage() {

    const { talent } = this.props;

    if(talent.image && talent.image.cloudinary_id && (this.props.permissions.canEditTalents || (this.props.isOwnTalent && this.state.isHeadshotEnabled))) {
      return (
        <div onClick={() => this.imageInput.click()} style={{width:'100%', height:'100%', position:'relative'}}>
          <Image cloudinaryId={talent.image.cloudinary_id} angle={talent.image.angle} crop="fill" gravity="face" width={150} height={150}/>
          <div style={{position:'absolute', bottom:8, left:0, right:0, textAlign:'center'}}>
            <Button
              raised={true}
              label="Edit"
              labelStyle={{fontSize:11}}
            />
          </div>
          <input
            name="imageinput"
            ref={el => this.imageInput = el}
            type="file"
            onChange={e => this.props.addTalentImage(this.props.talent.id, e.target.files[0])}
            style={{
              position: 'absolute',
              left: -1000,
            }}
          />
        </div>
      );
    }

    if(talent.image && talent.image.cloudinary_id) {
      return <Image cloudinaryId={talent.image.cloudinary_id} angle={talent.image.angle} crop="fill" gravity="face" width={150} height={150}/>;
    }

    if(this.props.isOwnTalent || this.props.permissions.canEditTalents) {
      return (
        <div>
          <Button
            raised={true}
            label="Headshot"
            labelStyle={{fontSize:11}}
            onClick={() => this.imageInput.click()}
          />
          <input
            name="imageinput"
            ref={el => this.imageInput = el}
            type="file"
            onChange={e => this.props.addTalentImage(this.props.talent.id, e.target.files[0])}
            style={{
              position: 'absolute',
              left: -1000,
            }}
          />
        </div>
      );
    }

    return null;
  }

  renderActions() {
    const canSideline = this.props.canSideline;
    return [
      <Action key="print" icon="print" label="Print" onClick={this.openPrintPopover}/>,
      <Action sideline="sideline" icon="sideline" label="Sideline" visible={!!canSideline} onClick={this.openDeactivateDialog}/>,
    ];
  }

  renderTabs() {
    const {
      url,
      path,
      pack,
      location,
      permissions,
      isInPack,
      isOwnTalent,
      isOwnAgency,
      canMedia,
      canEdit,
      canResume,
      canMessage,
      useBilling,
      canAdminTalents,
    } = this.props;

    let tabs = [];

    if(canEdit && isInPack && !!pack.schedule && !!pack.schedule.timeslots) {
      tabs.push(<Tab key="schedule" icon="schedule" label="Schedule" path={`${path}/schedule`} to={{pathname:`${url}/schedule`, state:location.state}}/>);
    }

    if(canEdit && isInPack) {
      tabs.push(<Tab key="pack" icon="pack" label="Pack" path={`${path}/pack`} to={{pathname:`${url}/pack`, state:location.state}}/>);
    }

    if(isInPack) {
      tabs.push(<Tab key="pack" icon={<Icon name="comment-lines" size={18}/>} label="Pack" path={`${path}/comments`} to={{pathname:`${url}/comments`, state:location.state}}/>);
    }

    tabs.push(<Tab key="info" icon="info" label="Info." path={`${path}/info`} to={{pathname:`${url}/info`, state:location.state}}/>);

    if(canEdit && permissions.canNoteTalents) {
      tabs.push(<Tab key="notes" icon="notes" label="Notes" path={`${path}/notes`} to={{pathname:`${url}/notes`, state:location.state}}/>);
    }

    // if(canMedia) {
      tabs.push(<Tab key="media" icon="media" label="Media" path={`${path}/media`} to={{pathname:`${url}/media`, state:location.state}}/>);
    // }

    if(isOwnTalent || canAdminTalents || (canMessage && isOwnAgency)) {
      tabs.push(<Tab key="messages" icon="messages" label="Messages" path={`${path}/messages`} to={{pathname:`${url}/messages`, state:location.state}}/>);
    }

    if(canResume) {
      tabs.push(<Tab key="resume" icon="resume" label="Resume" path={`${path}/resume`} to={{pathname:`${url}/resume`, state:location.state}}/>);
    }

    if(canEdit) {
      tabs.push(<Tab key="calendar" icon="calendar" label="Calendar" path={`${path}/calendar`} to={{pathname:`${url}/calendar`, state:location.state}}/>);
    }

    if(canEdit && !isOwnTalent) {
      tabs.push(<Tab key="history" icon="history" label="Package History" path={`${path}/history`} to={{pathname:`${url}/history`, state:location.state}}/>);
    }

    if(canEdit && useBilling) {
      tabs.push(<Tab key="payments" icon={<Icon name="money-check-alt" outline size={14} style={{marginTop:4}}/>} label="Payment History" path={`${path}/payments`} to={{pathname:`${url}/payments`, state:location.state}}/>);
    }

    if(permissions.canAdminTalents) {
      tabs.push(<Tab key="settings" icon="settings" path={`${path}/settings`} to={{pathname:`${url}/settings`, state:location.state}}/>);
    }

    return tabs;
  }

  renderPrintPopover() {
    return (
      <Popover
        open={this.state.isPrintPopoverOpen}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{horizontal: 'middle', vertical: 'center'}}
        targetOrigin={{horizontal: 'right', vertical: 'top'}}
        onRequestClose={this.closePrintPopover}>
        <Menu>
          <MenuItem primaryText="Headshot" onClick={this.printHeadshot}/>
          <MenuItem primaryText="Media" onClick={this.openMediaDialog}/>
          <MenuItem primaryText="Resume" onClick={this.printResume}/>
        </Menu>
      </Popover>
    );
  }

  renderMediaSelectDialog() {
    const accentColor = this.props.muiTheme.palette.accent700;

    return (
      <Dialog
        open={this.state.isMediaDialogOpen}
        onRequestClose={this.closeMediaDialog}

        title="Print Media"
        actions={[
          <Button
            key="print"
            label="Print"
            raised={true}
            primary={true}
            onClick={this.printMedia}
            disabled={this.state.selectedMedia.length !== 4}
          />,
        ]}>

        <div style={{paddingBottom:16}}>
          Select 4 images to print. {this.state.selectedMedia.length} selected.
        </div>

        {(!this.props.media || this.props.media.length === 0) &&
          <Loading/>
        }

        <div style={{textAlign:'center'}}>
          {Object.keys(this.props.media || {})
            .map(key => this.props.media[key])
            .filter(item => item.type === 'image')
            .map(item => {
              const selected = this.state.selectedMedia.indexOf(item.cloudinary_id) > -1;
              return (
                <div
                  key={item.cloudinary_id}
                  style={{
                    width:120, height:120,
                    backgroundColor: selected ? accentColor : '#eeeeee',
                    display: 'inline-block',
                    padding: 4,
                  }}
                  onClick={() => this.selectMedia(item.cloudinary_id)}>
                  <Media
                    media={item}
                    width={120}
                    height={120}
                    face
                    crop="fill"
                    effect={selected ? null : "grayscale"}
                  />
                </div>
              );
            })}
        </div>

      </Dialog>
    );
  }

  renderDeactivateDialog() {

    let name = `${this.props.talent.firstName || ""} ${this.props.talent.lastName || ""}`.trim();
    if(!name) {
      name = "Unnamed";
    }

    const actions = [
      <Button
        key="cancel"
        label="Cancel"
        onClick={this.closeDeactivateDialog}
        style={{marginRight:16}}
      />,
      <Button
        key="yes"
        label={`Yes`}
        raised={true}
        primary={true}
        onClick={this.deactivate}
      />,
    ];

    return (
      <Dialog
        open={this.state.isDeactivateDialogOpen}
        onRequestClose={this.closeDeactivateDialog}
        actions={actions}
        contentStyle={{width:300}}>

        Are you sure you want to sideline {name}?
      </Dialog>
    );
  }

  render() {
    const { talent } = this.props;
    return (
      <div>
        <Header
          isLoading={this.props.isLoading}
          title={`${talent.firstName || ""} ${talent.lastName || ""}`.trim() || "New Talent"}
          image={this.renderImage()}
          actions={this.renderActions()}
          tabs={this.renderTabs()}
        />

        {this.renderPrintPopover()}
        {this.renderMediaSelectDialog()}
        {this.renderDeactivateDialog()}

        <iframe ref={el => this.printFrame = el} title="Print" style={{position:'absolute', left:-100000}}/>
      </div>
    );
  }
}



export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    muiThemeable()(TalentHeader)
  )
);