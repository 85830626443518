import React from 'react';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { updateContact } from 'actions';
import Field from 'ui/Field';
import TextField from 'ui/TextField';
import EmailsField from 'ui/EmailsField';
import PhonesField from 'ui/PhonesField';
import MUITextField from 'material-ui/TextField';

const styles = {
  firstNameInput: {
    display: 'inline-block',
    boxSizing: 'border-box',
    width: 'calc(50% - 8px)',
    marginRight: 16,
  },
  lastNameInput: {
    display: 'inline-block',
    boxSizing: 'border-box',
    width: 'calc(50% - 8px)',
  }
};

export const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

const mapDispatchToProps = {
  updateContact
};

export class ContactForm extends React.Component {
  
  static defaultProps = {
    contact: {},
    onChange: () => null,
    style: {},
  }
  
  update = (update) => {
    if(this.props.contact.id) {
      this.props.updateContact(this.props.contact.id, update);
    }
    this.props.onChange({...this.props.contact, ...update});
  }
  
  render() {
    const {classes, contact} = this.props;
    
    return (
      <div style={this.props.style}>
        <Field label="Name" valueStyle={{paddingTop:10}}>
          <TextField
            placeholder="First"
            className={classes.firstNameInput}
            underline={true}
            initialValue={contact.firstName}
            onChangeText={firstName => this.update({firstName})}
          />
          <TextField
            placeholder="Last"
            className={classes.lastNameInput}
            underline={true}
            initialValue={contact.lastName}
            onChangeText={lastName => this.update({lastName})}
          />
        </Field>
        
        <Field label="Company" valueStyle={{paddingTop:10}}>
          <TextField
            placeholder=""
            underline={true}
            initialValue={contact.company}
            onChangeText={company => this.update({company})}
          />
        </Field>
        
        <Field label="Job Title" valueStyle={{paddingTop:10}}>
          <TextField
            placeholder=""
            underline={true}
            initialValue={contact.title}
            onChangeText={title => this.update({title})}
          />
        </Field>
        
        <Field label="Emails" valueStyle={{paddingTop:10}}>
          <EmailsField 
            key="emails"
            initialValue={contact.emails}
            onChange={emails => this.update({emails})}
          />
        </Field>
        
        <Field label="Phones" valueStyle={{paddingTop:10}}>
          <PhonesField 
            key="phones"
            initialValue={contact.phones}
            onChange={phones => this.update({phones})}
          />
        </Field>
        
        <Field label="Notes">
          <MUITextField
            name="notes"
            value={contact.notes}
            fullWidth
            multiLine={true}
            onChange={(e, notes) => this.update({notes})}
          />
        </Field>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  injectSheet(styles)(
    ContactForm
  )
);

