import React from 'react';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { createContact } from 'actions';
import Button from 'ui/Button';
import ContactForm from 'ui/ContactForm';

const styles = {
  container: {
    padding: 16,
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }
};

export const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

const mapDispatchToProps = {
  createContact
};

export class CreateContact extends React.Component {
  state = {
    contact: {},
  }
  
  create = () => {
    this.props.createContact(this.state.contact);
    if(this.props.onClose) {
      this.props.onClose();
    }
  }
  
  render() {
    const { classes } = this.props;
    
    return (
      <div className={classes.container}>
        
        <h2>Create Contact</h2>
        
        <ContactForm
          contact={this.state.contact}
          onChange={contact => this.setState({contact})}
          style={{padding:'16px 0'}}
        />
        
        <div className={classes.nav}>
          <Button label="Cancel" onClick={this.props.onClose}/>
          <Button label="Create" raised={true} primary={true} onClick={this.create}/>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  injectSheet(styles)(
    CreateContact
  )
);

