import { combineReducers } from "redux";

const all = (state={}, action) => {
  switch(action.type) {
    
    case "LOAD_FAQS": 
      return action.faqs;
    
    case "ADD_FAQ":
    case "LOAD_FAQ":
    case "UPDATE_FAQ":
      return {
        ...state,
        [action.faq.id]: action.faq,
      };
      
    case "REMOVE_FAQ":
      const {[action.faq.id]:removedItem, ...newState} = state;
      return newState;
      
    default: 
      return state;
  }
};

export default combineReducers({
  all,
});