import React from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Toggle from 'material-ui/Toggle';

export default muiThemeable()(({
  muiTheme:{ palette },
  ...props
}) => {
  return (
    <Toggle
      labelPosition="right"
      thumbStyle={{backgroundColor: palette.primary400}}
      thumbSwitchedStyle={{backgroundColor: palette.accent700}}
      {...props}
    />
  );
}) 
