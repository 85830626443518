import React from 'react';
import { Card, Text, Currency, useRouter } from 'skybolt-ui';
import moment from 'moment';

export default function(props) {

  let debit = props.debit;

  let route = useRouter();

  return (
    <Card style={{marginBottom:4, boxSizing:'border-box', padding:"8px 16px", minWidth:1200}}>
      <div style={{display:'flex', alignItems:'center'}}>
        <div style={{flex:1, display:'flex', padding:0}}>
          <div style={{flex:1}}>
            {debit.is1099 ? (
              <Text>{debit.dateJob ? moment(debit.dateJob).format("MM/DD/YYYY") : ""}</Text>
            ):(
              <Text>{debit.dateCheck ? moment(debit.dateCheck).format("MM/DD/YYYY") : ""}</Text>
            )}
          </div>
          <div style={{flex:1, overflow:'hidden'}}>
            <Text>{debit.checkNumber || ""}</Text>
          </div>
          <div style={{flex:2, overflow:'hidden'}}>
            <Text block style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
              {debit.packName || ""}
            </Text>
          </div>
          <div style={{flex:2, overflow:'hidden'}}>
            <Text block style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
              {debit.clientName || ""}
            </Text>
          </div>
          <div style={{flex:1}}>
            <Text>{debit.useType || ""}</Text>
          </div>
          <div style={{flex:1}}>
            <Text>{debit.payType || ""}</Text>
          </div>
          <div style={{flex:1}}>
            <Text>{debit.dateMaxUse ? moment(debit.dateMaxUse).format("MM/DD/YYYY") : ""}</Text>
          </div>
          <div style={{flex:1}}>
            <Text>{debit.invoiceNumber || ""}</Text>
          </div>
          <div style={{flex:1}}>
            <Currency>{debit.grossAmount}</Currency>
          </div>
          <div style={{flex:1}}>
            <Currency>{debit.taxAmount}</Currency>
          </div>
          <div style={{flex:1}}>
            <Currency>{debit.otherAmount}</Currency>
          </div>
          <div style={{flex:1}}>
            <Currency>{debit.agencyAmount}</Currency>
          </div>
          <div style={{flex:1}}>
            <Currency>{debit.amount}</Currency>
          </div>
        </div>
      </div>
    </Card>
  );
}