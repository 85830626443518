import React from 'react';
import RangeSelect from 'ui/RangeSelect';
import muiThemeable from 'material-ui/styles/muiThemeable';

export default muiThemeable()(({
  label="",
  value,
  options,
  onChange,
  style={},
  muiTheme: { palette },
  ...props
}) => {
  return (
    <RangeSelect
      className="sui-title-filter"
      hint={label}
      value={value}
      options={options}
      onChange={onChange}
      style={{width:90, height:38, marginBottom:0, ...style}}
      hintStyle={{bottom:2, color:palette.primary100}}
      underlineStyle={{bottom:-1, borderColor:"#c6c6c6"}}
      labelStyle={{height:28, lineHeight:'28px', top:0, marginTop:12}}
      iconStyle={{fill:palette.primary100}}
      {...props}
    />
  );
});