import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  Header,
  useRouter,
  Loading,
  Container,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';
import Credits from './Credits';
import Debits from './Debits';
import Messages from './Messages';
import Invoice from './Invoice';
import "./index.css";

export default function InvoiceView() {
  const {match:{ url, path, params:{ invoiceId }}} = useRouter();

  const data = useQuery(`{
    agency {
      agencyId
      invoice(invoiceId:$invoiceId) {
        agencyId
        invoiceNumber
        packId
        packName
        status
        lineItems
        debits(status:null)
      }
    }
  }`, {invoiceId});



  // Render

  if(!data) {
    return <Loading/>;
  }

  if(data.agency.invoice.status === "DRAFT") {
    let editUrl = url;
    editUrl = editUrl.split('/');
    editUrl.pop();
    editUrl.push('edit');
    editUrl = editUrl.join('/');
    return <Redirect to={editUrl}/>;
  }

  const title = `Invoice ${data.agency.invoice.invoiceNumber || ""}`;
  const tabs = [
    {label:"Invoice", icon:"file-invoice-dollar", href:`${url}/invoice`},
    // {label:"Messages", icon:"envelope", href:`${url}/messages`},
    {label:"Payments Received", icon:"file-check", href:`${url}/credits`},
    {label:"Talent Payments", icon:"receipt", href:`${url}/debits`},
  ];

  return (
    <div>
      <Container>
        <Header name={title} style={{marginTop:32}} tabs={tabs}/>
      </Container>
      <Switch>
        <Route path={`${path}/credits`} component={Credits}/>
        <Route path={`${path}/debits`} component={Debits}/>
        <Route path={`${path}/messages`} component={Messages}/>
        <Route path={`${path}/invoice`} component={Invoice}/>
        <Redirect to={`${url}/invoice`}/>
      </Switch>
    </div>
  );
}