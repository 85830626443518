import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uniq, get, difference, isNumber } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/database';

import {
  loadAgency,
  addAgencyTalentField,
  removeAgencyTalentField,
  updateAgencyTalentField,
  updateAgency,
  moveAgencyTalentField,
} from 'actions';

import './index.css';

import { Link, withRouter } from 'react-router-dom';
import Page from 'ui/Page';
import Title, { Action } from 'ui/Title';
import Field from './Field';
import ListItem from './ListItem';
// import Icon from 'ui/Icon';
import { Text, Icon } from 'skybolt-ui';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';


const systemFields = {
  firstName: {
    name: "First Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isVisibleOnCard: true,
    isRequired: true,
    order: -6,
  },
  middleName: {
    name: "Middle Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isVisibleOnCard: true,
    isRequired: true,
    order: -5,
  },
  lastName: {
    name: "Last Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isVisibleOnCard: true,
    isRequired: true,
    order: -4,
  },
  contactName: {
    name: "Contact Name",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isVisibleOnCard: true,
    isRequired: true,
    order: -3,
  },
  ssn: {
    name: "SSN",
    type: "text",
    category: "system",
    isEditableByTalent: true,
    isRequired: true,
    order: -2,
  },
  dob: {
    name: "Date of Birth",
    type: "date",
    category: "system",
    isEditableByTalent: true,
    isPublic: true,
    isVisibleOnCard: true,
    isRequired: true,
    order: -1,
  }
};

const DragHandle = SortableHandle(() =>
  <Icon name="drag" color="#bdbdbd"/>
);

const SortableList = SortableContainer((props) => <div>{props.children}</div>);

const SortableListItem = SortableElement(({children, sortable=true}) => {
  return (
    <Page style={{display:'flex', alignItems:'center', marginBottom:4, padding:'8px 16px 4px 16px'}} >
      <div style={{flex:1}}>
        {children}
      </div>
      <div style={{flex:0, paddingTop:2, cursor:sortable ? 'move' : 'default'}}>
        {sortable ? <DragHandle/> : <div style={{width:24}}/>}
      </div>
    </Page>
  );
});

const mapStateToProps = (state, ownProps) => {
  const agencyId = ownProps.agencyId ||
                   ownProps.match.params.agencyId;

  const selectedFieldId = ownProps.fieldId ||
                          ownProps.match.params.fieldId;

  const agency = state.agencies.all[agencyId];
  let fields = agency ? agency.talentFields : {};
  fields = {...systemFields, ...fields};

  let categories = [];
  if(!!fields) {
    categories = uniq(
      Object.keys(fields).reduce((all, id) => all.concat([fields[id].category]), [])
    );
  }

  return {
    agencyId,
    selectedFieldId,
    fields,
    categories,
    path: ownProps.match.path,
    url: ownProps.match.url,
  };
};


const mapDispatchToProps = {
  loadAgency,
  addAgencyTalentField,
  removeAgencyTalentField,
  updateAgencyTalentField,
  moveAgencyTalentField,
  updateAgency,
};




class TalentFields extends Component {
  UNSAFE_componentWillMount() {
    if(this.props.agencyId) {
      this.props.loadAgency(this.props.agencyId);
    }
  }

  add = () => {
    const fieldId = this.props.addAgencyTalentField(this.props.agencyId);
    const url = !!this.props.selectedFieldId ? fieldId : `${this.props.url}/${fieldId}`;
    this.props.history.push(url);
  }

  closeSelected = () => {
    if(!this.props.selectedFieldId) {
      return;
    }
    this.props.history.replace(
      this.props.url.replace(`/${this.props.selectedFieldId}`, '')
    );
  }

  sort = ({oldIndex, newIndex}, e) => {
    let fields = this.props.fields;
    let sorted = Object.keys(fields)
      // .filter(id => fields[id].category !== 'system')
      .sort((a, b) => 
        isNumber(fields[a].order) && fields[a].order > fields[b].order ? 1 : -1
      );
    const fieldId = sorted[oldIndex];
    const beforeFieldId = sorted[newIndex];

    const field = fields[fieldId] || {};
    const beforeField = fields[beforeFieldId] || {};

    const thisOrder = field.order || 0;
    const beforeOrder = beforeField.order || 0;

    fields[fieldId].order = beforeOrder > thisOrder ? beforeOrder+0.5 : beforeOrder-0.5;

    Object.keys(fields)
      // .filter(id => fields[id].category !== 'system')
      .sort((a, b) => 
        isNumber(fields[a].order) && fields[a].order > fields[b].order ? 1 : -1
      )
      .forEach((id, i) => {
        fields[id].order = i;
      });


    console.log('sort', this.props.agencyId, fields)

    this.props.updateAgency(this.props.agencyId, {talentFields:fields})
  }

  renderField(fieldId, index) {
    const update = this.props.updateAgencyTalentField;
    const remove = this.props.removeAgencyTalentField;
    const agencyId = this.props.agencyId;
    const categories = this.props.categories;
    const fields = this.props.fields;

    if(fieldId === this.props.selectedFieldId) {
      const conditionFields = Object.keys(fields)
        .sort((a, b) => 
          isNumber(fields[a].order) && fields[a].order > fields[b].order ? 1 : -1
        )
        .filter(id => fields[id].type === 'select')
        .map(id => ({
          label: fields[id].name,
          value: id
        }));

      let conditionField = !!fields[fieldId].condition && fields[fieldId].condition.field;
      let conditionValues = get(fields, `${conditionField}.options`, []);

      return (
        <Page key={fieldId} style={{marginBottom:4, padding:'8px 16px 4px 16px'}} >
          <Field
            field={fields[fieldId]}
            onChange={data => update(agencyId, fieldId, data)}
            onRemove={() => remove(agencyId, fieldId)}
            onClose={this.closeSelected}
            categories={categories}
            conditionFields={conditionFields}
            conditionValues={conditionValues}
          />
        </Page>
      );
    }

    const url = !!this.props.selectedFieldId ? fieldId : `${this.props.url}/${fieldId}`;

    const field = fields[fieldId];

    if(!field) {
      return null;
    }

    // if(field.category === 'system') {
    //   return (
    //     <Page key={fieldId} style={{display:'flex', alignItems:'center', marginBottom:4, padding:'8px 16px 4px 16px'}} >
    //       <div style={{flex:1}}>
    //         <Link to={url} style={{textDecoration:'none'}} replace={true}>
    //           <ListItem field={field}/>
    //         </Link>
    //       </div>
    //       <div style={{flex:0}}>
    //         <div style={{width:24}}/>
    //       </div>
    //     </Page>
    //   );
    // }

    return (
      <SortableListItem key={fieldId} index={index}>
        <Link to={url} style={{textDecoration:'none'}} replace={true}>
          <ListItem field={field}/>
        </Link>
      </SortableListItem>
    );
  }

  // renderSystemFields() {
  //   let fields = difference(['firstName', 'middleName', 'lastName', 'contactName', 'ssn', 'dob'], Object.keys(this.props.fields) || []);
  //   return fields.map((id, index) => this.renderField(id, index));
  // }

  renderFields() {
    let fields = this.props.fields;
    let sorted = Object.keys(fields);
    sorted.sort((a, b) => 
      isNumber(fields[a].order) && fields[a].order > fields[b].order ? 1 : -1
    );
    return sorted.map((id, index) => this.renderField(id, index));
  }

  render() {
    return (
      <div style={this.props.style}>
        <Title
          style={{marginBottom:0}}
          actions={[<Action tooltip="Add Field" icon="add" onClick={this.add}/>]}>
          Talent Fields
        </Title>
        <div style={{padding:"4px 0 0 0", height:56}}>
          <Text light>
            Define info. fields available to Talent.<br/>
            Drag fields to set display order.
          </Text>
        </div>

        {/*{this.renderSystemFields()}*/}

        <SortableList
          axis="y"
          useWindowAsScrollContainer={true}
          useDragHandle={true}
          onSortEnd={this.sort}>
          {this.renderFields()}
        </SortableList>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TalentFields)
);