import React, { useContext, useState, useRef, useLayoutEffect, useEffect } from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';

import "./index.css";

import Toggle from 'material-ui/Toggle';
import ColorPicker from './ColorPicker';
import Field from 'ui/Field';
import Title from 'ui/Title';
import Page from 'ui/Page';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import DateField from 'ui/DateField';
import { Text, ThemeContext, Button, InputText, InputSwitch, InputArray, InputNumber, InputTag } from 'skybolt-ui';
import { map, debounce, pick } from 'lodash';

function KitComponent(props) {
  let wrapper = useRef();
  let app = useRef();
  let instance = useRef();

  let { component, ...forwardProps } = props;

  useLayoutEffect(() => {
    if(!component) {
      return;
    }
    let Component = window.skyboltkit[component];
    if(!Component) {
      return;
    }
    if(app.current == Component) {
      if(instance.current) {
        instance.current.$set(forwardProps);
      }
      return;
    }

    if(instance.current) {
      instance.current.$destroy();
    }
    
    app.current = Component;
    instance.current = new Component({target:wrapper.current, props:forwardProps});

  }, [wrapper.current, window.skyboltkit, component, forwardProps]);

  useEffect(() => {
    return () => {
      if(instance.current) {
        instance.current.$destroy();
      }
    }
  }, []);

  return (
    <div
      ref={wrapper}
      style={{
        display: 'block',
        width: '100%',
      }}
    />
  );
}


function TalentBilling(props) {
  let wrapper = useRef();
  let app = useRef();
  let instance = useRef();

  useLayoutEffect(() => {
    if(!window.skyboltsnow) {
      return;
    }
    let Component = window.skyboltsnow.AgencyTalentBilling;
    if(app.current == Component) {
      return;
    }
    if(instance.current) {
      instance.current.$destroy();
    }
    app.current = Component;
    instance.current = new Component({target:wrapper.current, props});
    window.skyboltsnow.attach(wrapper.current);
  }, [wrapper.current, window.skyboltsnow]);

  return (
    <div
      ref={wrapper}
      style={{
        display: 'block',
        width: '100%',
      }}
    />
  );
}


let subscriptions = [];

export default muiThemeable()(props => {


  const theme = useContext(ThemeContext);


  // Emit changes.

  const emitUpdate = (key, val) => props.onUpdate({[key]:val});


  // Copy code snippet to clipboard

  const snippetRef = useRef();
  const [coppied, setCoppied] = useState(false);
  function copy() {
    const el = document.createElement('textarea');
    el.value = snippetRef.current.innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCoppied(true);
    setTimeout(() => setCoppied(false), 2000);
  }


  const [types, _setTypes] = useState(props.agency.billingTypes);
  const setTypes = types => {
    _setTypes(types);
    props.onUpdate({billingTypes:types});
  };


  // Mock a svelte stream to pass into talent billing card.

  let subscriptionProps = pick(props.agency, [
    "allowTalentRegistration",
    "allowTrialTalents",
    "allowUnassignedTalents",
    "chargeTalentsToAgency",
    "commissionPercent",
    "planSelectText",
    "requireTalentSubscription",
    "showTalentPlanOnCard",
    "showTalentTrialOnCard",
    "stripeId",
    "stripeSubscriptionId",
    "stripeTalentSubscriptionPriceIds",
    "stripeTalentSetupPriceId",
    "stripeTalentAddOnPriceIds",
  ]);

  let debouncedSet = debounce(value => {
      props.onUpdate(value);
    }, 1000);
  
  let agencyStream = {
    subscribe(callback) {
      subscriptions.push(callback);
      callback(subscriptionProps);
      return (callback) => subscriptions = subscriptions.filter(cb => cb != callback);
    },
    set(value) {
      subscriptions.forEach(callback => callback(value));
      debouncedSet(value);
    }
  };

  useEffect(() => {
    subscriptions.forEach(callback => callback(subscriptionProps));
  }, [subscriptionProps]);

  console.log(props.agency);


  // Render
  return (
    <div className="agency-settings" style={{padding:"0 0 40px 0"}}>

      <Title>Theme Options</Title>
      <Page style={{padding:16}}>
        <h6 style={{paddingBottom:16}}>Color and branding options.</h6>

        <Field label="Primary Color">
          <ColorPicker
            style={{marginTop:8}}
            color={props.agency.primaryColor}
            onChange={primaryColor => props.onUpdate({primaryColor})}
          />
        </Field>

        <Field label="Accent Color">
          <ColorPicker
            style={{marginTop:8}}
            color={props.agency.accentColor}
            onChange={accentColor => props.onUpdate({accentColor})}
          />
        </Field>
      </Page>


      <Title>Meta Data</Title>

      <Page style={{padding:16}}>
        <Field label="Search Terms">
          <TextField
            multiLine={true}
            fullWidth
            placeholder={props.agency.name}
            value={props.agency.meta || ""}
            onChange={(e, meta) => props.onUpdate({meta})}
          />
        </Field>

        <Field label="Send system emails from">
          <TextField
            multiLine={true}
            fullWidth
            placeholder="Skybolt"
            value={props.agency.fromName || ""}
            onChange={(e, fromName) => props.onUpdate({fromName})}
          />
        </Field>
      </Page>


      <Title>Talent Options</Title>
      <Page style={{padding:16}}>
        
        {!props.agency.useStripe && 
          <Toggle
            labelPosition="right"
            label={
              <div>
                <div className="agency-settings-toggle-title">Charge Talents to Agency.</div>
                <div className="agency-settings-toggle-desc">
                  All new talent will be charged to the agency's subscription.
                </div>
              </div>
            }
            style={{paddingBottom: !!props.agency.chargeTalentsToAgency ? 0 : 32}}
            toggled={!!props.agency.chargeTalentsToAgency}
            onToggle={(e, val) => emitUpdate('chargeTalentsToAgency', val)}
            thumbStyle={{backgroundColor:"#aaaaaa"}}
          />
        }

        {!props.agency.useStripe && !!props.agency.chargeTalentsToAgency &&
          <div style={{padding:'0px 0 32px 46px'}}>
            <span className="agency-settings-subtext">USAePay ID</span>
            <TextField
              id="subscriptionStartAfter"
              style={{width:200, margin:'0 16px'}}
              value={props.agency.epayId || ""}
              onChange={(e, val) => emitUpdate('epayId', parseInt(val, 10))}
            />
          </div>
        }

        {/* TODO: The agency needs some way to select, or be assigned, a plan */}

        {!props.agency.useStripe && 
          <Toggle
            labelPosition="right"
            label={
              <div>
                <div className="agency-settings-toggle-title">Require User Subscription.</div>
                <div className="agency-settings-toggle-desc">
                  Require users to choose a subscription. This subscription will be used
                  for all talent created by the user.
                </div>
              </div>
            }
            style={{paddingBottom: !!props.agency.requireUserSubscription ? 0 : 32}}
            toggled={!!props.agency.requireUserSubscription}
            onToggle={(e, val) => emitUpdate('requireUserSubscription', val)}
            thumbStyle={{backgroundColor:"#aaaaaa"}}
          />
        }

        {!props.agency.useStripe && !!props.agency.requireUserSubscription &&
          <div style={{padding:'8px 0 0px 46px'}}>
            <span className="agency-settings-subtext">Start billing on</span>
            <DateField
              name="subscriptionStart"
              placeholder="MM/DD/YYYY"
              style={{display:'inline-block', width:100, margin:'0 16px'}}
              underline={true}
              initialValue={props.agency.subscriptionStart}
              onChange={val => emitUpdate('subscriptionStart', val)}
            />
          </div>
        }

        {!props.agency.useStripe && !!props.agency.requireUserSubscription &&
          <div style={{padding:'0px 0 32px 46px'}}>
            <span className="agency-settings-subtext">Start billing after</span>
            <TextField
              id="subscriptionStartAfter"
              style={{width:60, margin:'0 16px'}}
              value={props.agency.subscriptionStartAfter || ""}
              onChange={(e, val) => emitUpdate('subscriptionStartAfter', parseInt(val, 10))}
            />
            <span className="agency-settings-subtext">days</span>
          </div>
        }

        {!props.agency.useStripe && 
          <Toggle
            labelPosition="right"
            label={
              <div>
                <div className="agency-settings-toggle-title">Require Talent Subscription.</div>
                <div className="agency-settings-toggle-desc">
                  Require users to create a subscription and pay for services for each talent they create.
                </div>
              </div>
            }
            style={{paddingBottom: !!props.agency.requireTalentSubscription ? 0 : 32}}
            toggled={!!props.agency.requireTalentSubscription}
            onToggle={(e, val) => emitUpdate('requireTalentSubscription', val)}
            thumbStyle={{backgroundColor:"#aaaaaa"}}
          />
        }

        {!props.agency.useStripe && !!props.agency.requireTalentSubscription &&
          <div style={{padding:'8px 0 0px 46px'}}>
            <span className="agency-settings-subtext">Start billing on</span>
            <DateField
              name="subscriptionStart"
              placeholder="MM/DD/YYYY"
              style={{display:'inline-block', width:100, margin:'0 16px'}}
              underline={true}
              initialValue={props.agency.subscriptionStart}
              onChange={val => emitUpdate('subscriptionStart', val)}
            />
          </div>
        }

        {!props.agency.useStripe && !!props.agency.requireTalentSubscription &&
          <div style={{padding:'0px 0 32px 46px'}}>
            <span className="agency-settings-subtext">Start billing after</span>
            <TextField
              id="subscriptionStartAfter"
              style={{width:60, margin:'0 16px'}}
              value={props.agency.subscriptionStartAfter || ""}
              onChange={(e, val) => emitUpdate('subscriptionStartAfter', parseInt(val, 10))}
            />
            <span className="agency-settings-subtext">days</span>
          </div>
        }

        {!props.agency.useStripe && 
          <Toggle
            labelPosition="right"
            label={
              <div>
                <div className="agency-settings-toggle-title">Allow talent trial membership.</div>
                <div className="agency-settings-toggle-desc">
                  Enabling this feature will allow talents to join your agency on a trial basis.
                </div>
              </div>
            }
            style={{paddingBottom:32}}
            toggled={!!props.agency.allowTrialTalents}
            onToggle={(e, val) => emitUpdate('allowTrialTalents', val)}
            thumbStyle={{backgroundColor:"#aaaaaa"}}
            disabled={!!props.agency.chargeTalentsToAgency || !props.agency.requireTalentSubscription}
          />
        }

        {!props.agency.useStripe && 
          <Toggle
            labelPosition="right"
            label={
              <div>
                <div className="agency-settings-toggle-title">Allow open registration.</div>
                <div className="agency-settings-toggle-desc">
                  Allow users to register as part of this agency without an invitation. Users will be
                  able to register at <span style={{whiteSpace:'nowrap'}}>{window.location.origin}/register/{props.agency.id}.</span>
                </div>
              </div>
            }
            style={{paddingBottom:32}}
            toggled={!!props.agency.allowTalentRegistration}
            onToggle={(e, val) => emitUpdate('allowTalentRegistration', val)}
            thumbStyle={{backgroundColor:"#aaaaaa"}}
          />
        }

        {!props.agency.useStripe && 
          <Toggle
            labelPosition="right"
            label={
              <div>
                <div className="agency-settings-toggle-title">Allow unassigned talents.</div>
                <div className="agency-settings-toggle-desc">
                  Allow agents to create talents that aren't assigned or billed to any user.
                </div>
              </div>
            }
            style={{paddingBottom:32}}
            toggled={!!props.agency.allowUnassignedTalents}
            onToggle={(e, val) => emitUpdate('allowUnassignedTalents', val)}
            thumbStyle={{backgroundColor:"#aaaaaa"}}
          />
        }

        <Toggle
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Require talent media approval.</div>
              <div className="agency-settings-toggle-desc">
                Require all media uploaded by talents to be approved by an agent.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.requireTalentMediaApproval}
          onToggle={(e, val) => emitUpdate('requireTalentMediaApproval', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        <Toggle
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Require talent approval.</div>
              <div className="agency-settings-toggle-desc">
                Require all talents to be approved by an agent.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.requireTalentApproval}
          onToggle={(e, val) => emitUpdate('requireTalentApproval', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        <Toggle
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Allow talent to set headshots.</div>
              <div className="agency-settings-toggle-desc">
                Disabling this will stop talent users from modifying their headshots after being set by the agent.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={props.agency.allowTalentChangeHeadshot !== undefined ? props.agency.allowTalentChangeHeadshot : true}
          onToggle={(e, val) => emitUpdate('allowTalentChangeHeadshot', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        <Toggle
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Show talent resumes.</div>
              <div className="agency-settings-toggle-desc">
                This will make the Resume tab available to the talent, agents, and guest users.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={props.agency.useTalentResume !== undefined ? props.agency.useTalentResume : true}
          onToggle={(e, val) => emitUpdate('useTalentResume', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        <Toggle
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Allow talent to add tags to their media.</div>
              <div className="agency-settings-toggle-desc">
                Allow talent to add tags to their own media items.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={props.agency.allowTalentToTagMedia !== undefined ? props.agency.allowTalentToTagMedia : true}
          onToggle={(e, val) => emitUpdate('allowTalentToTagMedia', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        <Toggle
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Allow talent to select division and labels.</div>
              <div className="agency-settings-toggle-desc">
                Allow talent to select or change their own divisions and labels.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.allowTalentToSelectDivision}
          onToggle={(e, val) => emitUpdate('allowTalentToSelectDivision', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        <Toggle
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Filter Talent Media by Division</div>
              <div className="agency-settings-toggle-desc">
                If set, talent media will automatically be filtered to the agent's devision when being added to a package.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.filterTalentMediaByDivision}
          onToggle={(e, val) => emitUpdate('filterTalentMediaByDivision', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        <Toggle
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Use Stripe for Talent Billing.</div>
              <div className="agency-settings-toggle-desc">
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.useStripe}
          onToggle={(e, val) => emitUpdate('useStripe', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        

      </Page>

      <div style={{height:40}}/>


      {props.agency.useStripe && 
        <TalentBilling agency={agencyStream}/>
      }

      <Title>Casting Director Account Options</Title>
      <Page style={{padding:"16px 16px 40px 16px"}}>

        <Field label="Divisions">
          <div>
            <SelectField
              autoWidth={true}
              value={props.agency.cdDivisions}
              onChange={(e, i, value) => {
                if(value.indexOf(-1) > -1) {
                  value = null;
                }
                emitUpdate("cdDivisions", value);
              }}
              fullWidth
              style={{maxWidth:400}}
              multiple>
              <MenuItem
                value={-1}
                primaryText="All"
                insetChildren={true}
                checked={!props.agency.cdDivisions || props.agency.cdDivisions.length === 0}
              />
              {map(props.agency.divisions, division =>
                <MenuItem
                  key={division}
                  value={division}
                  primaryText={division}
                  insetChildren={true}
                  checked={props.agency.cdDivisions && props.agency.cdDivisions.indexOf(division) > -1}
                />
              )}
            </SelectField>
          </div>
          <div>
            <Text color={theme.color.textLighter}>
              Casting Directors will only see Talent in the selected Divisions.
            </Text>
          </div>
        </Field>


      </Page>

      <Title>Export Options</Title>
      <Page style={{padding:16}}>
        <Toggle
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Include in changes report.</div>
              <div className="agency-settings-toggle-desc">
                Include talent from {props.agency.name} in the recent changes XML export.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.includeInXMLExport}
          onToggle={(e, val) => emitUpdate('includeInXMLExport', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />
      </Page>

      <Title>Display Settings</Title>
      <Page style={{padding:16}}>
        <Toggle
          name="showTalentPlanOnCard"
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Show Plan on Talent Cards</div>
              <div className="agency-settings-toggle-desc">
                Show the talent's payment plan on talent cards.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.showTalentPlanOnCard}
          onToggle={(e, val) => emitUpdate('showTalentPlanOnCard', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        <Toggle
          name="showTalentDivisionOnCard"
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Show Divisions on Talent Cards</div>
              <div className="agency-settings-toggle-desc">
                Show the talent's divisions on talent cards.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.showTalentDivisionOnCard}
          onToggle={(e, val) => emitUpdate('showTalentDivisionOnCard', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        <Toggle
          name="showTalentTrialOnCard"
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Indicate Trial Talent on Talent Cards</div>
              <div className="agency-settings-toggle-desc">
                Displays "Trial" under the talent's name on talent cards for trial talent.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.showTalentTrialOnCard}
          onToggle={(e, val) => emitUpdate('showTalentTrialOnCard', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

      </Page>

      <Title>Event Types</Title>
      <KitComponent component="AgencyEventTypes" agencyId={props.agency.id}/>

      <Title>Features</Title>
      <Page style={{padding:16}}>
        <Toggle
          name="useContacts"
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Use Contacts</div>
              <div className="agency-settings-toggle-desc">
                Enable Contacts section under Admin and search contacts in email composer.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.useContacts}
          onToggle={(e, val) => emitUpdate('useContacts', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />
        <Toggle
          name="canSearchLocation"
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Search by Location</div>
              <div className="agency-settings-toggle-desc">
                Allows agents to search for talent by geolocation.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.canSearchLocation}
          onToggle={(e, val) => emitUpdate('canSearchLocation', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />
        <Toggle
          name="canRequestPackMedia"
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Pack Media Request</div>
              <div className="agency-settings-toggle-desc">
                Enables the media requests tab on packages in {props.agency.name}.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.canRequestPackMedia}
          onToggle={(e, val) => emitUpdate('canRequestPackMedia', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />
        <Toggle
          name="useBilling"
          labelPosition="right"
          label={
            <div>
              <div className="agency-settings-toggle-title">Billing</div>
              <div className="agency-settings-toggle-desc">
                Enables billing features for {props.agency.name}.
              </div>
            </div>
          }
          style={{paddingBottom:32}}
          toggled={!!props.agency.useBilling}
          onToggle={(e, val) => emitUpdate('useBilling', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />
      </Page>

      {props.agency.useBilling && (
        <div>
        <Title>Billing</Title>
          <Page style={{padding:16}}>
            <div style={{maxWidth:600}}>
              <InputText
                title="Bill.com Organization Id"
                value={props.agency.billingOrgId || ""}
                onChange={billingOrgId => props.onUpdate({billingOrgId})}
                style={{maxWidth:600}}
              />
            </div>

            <div style={{maxWidth:600}}>
              <InputTag
                title="Job Types"
                value={props.agency.jobTypes || []}
                onChange={jobTypes => props.onUpdate({jobTypes})}
                style={{maxWidth:600}}
              />
            </div>

            <div style={{maxWidth:600}}>
              <InputTag
                title="Use Types"
                value={props.agency.useTypes || []}
                onChange={useTypes => props.onUpdate({useTypes})}
                style={{maxWidth:600}}
                hint="Leave blank to use default values."
              />
            </div>

            <InputArray value={types} onChange={setTypes} name="types" addLabel="Add Line Item Type" addValue={{label:""}}>
              <div style={{display:'flex', alignItems:'center'}}>
                <div style={{marginRight:16}}>
                  <InputText name="label" title="Line Item Type"/>
                </div>
                <div style={{marginRight:8}}>
                  <InputSwitch name="applyAgencyFee" title="Subject to Agency Fee"/>
                </div>
                <div style={{marginRight:8}}>
                  <InputSwitch name="deductCommission" title="Deduct Commission"/>
                </div>
                <div>
                  <InputSwitch name="isHourly" title="Hourly"/>
                </div>
              </div>
            </InputArray>

            <div style={{maxWidth:600, display:'flex', flexWrap:'wrap'}}>
              <InputNumber
                initialValue={props.agency.billingAgencyFee}
                onChange={billingAgencyFee => props.onUpdate({billingAgencyFee})}
                title="Standard Fee"
                hint="Percent"
                style={{width:292, marginRight:16}}
              />
              <InputNumber
                initialValue={props.agency.billingCommission}
                onChange={billingCommission => props.onUpdate({billingCommission})}
                title="Standard Commission"
                hint="Percent"
                style={{width:292}}
              />
              <InputNumber
                initialValue={props.agency.billingRate}
                onChange={billingRate => props.onUpdate({billingRate})}
                title="Standard Rate"
                hint="USD"
                style={{width:292}}
              />
            </div>
          </Page>
        </div>
      )}



      <Title>Spotlights</Title>
      <Page style={{padding:16}}>
        <Toggle
          name="publicspotlights"
          labelPosition="right"
          label={<div className="agency-settings-toggle-title">Enable Public Spotlights</div>}
          style={{paddingBottom:32}}
          toggled={!!props.agency.usePublicSpotlights}
          onToggle={(e, val) => emitUpdate('usePublicSpotlights', val)}
          thumbStyle={{backgroundColor:"#aaaaaa"}}
        />

        {props.agency.usePublicSpotlights &&
          <div>
            <Text paragraph>Link to public spotlight at the link below.</Text>
            <Text paragraph>
              <a href={`https://beta.skybolt.net/talents/agency/${props.agency.id}`} target="_blank" rel="noopener noreferrer">
                https://beta.skybolt.net/talents/agency/{props.agency.id}
              </a>
            </Text>

            <Text paragraph>Copy and paste the code below into your site where you want the spotlight to appear.</Text>
            <div ref={snippetRef} style={{paddingBottom:8}}>
              <Text
                style={{
                  display:'block',
                  backgroundColor: theme.color.greyLight,
                  padding: 16,
                  color: theme.color.textLight,
                  fontFamily: 'monospace',
                }}>
                {`<iframe src="https://beta.skybolt.net/embed/agency/${props.agency.id}" style="border:none;" name="${props.agency.name}" id="skybolt" frameborder="0" marginheight="0px" marginwidth="0px" width="100%"></iframe><script type="application/javascript" src="https://beta.skybolt.net/embed.js"></script>`}
              </Text>
            </div>
            <Button raised onClick={copy} style={{marginRight:16}}>{coppied ? "Coppied" : "Copy to Clipboard"}</Button>
          </div>
        }
      </Page>

    </div>
  );
});