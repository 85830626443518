import React, { useContext } from 'react';
import {
  useRouter,
  ThemeContext,
  Text,
  Button,
  Card,
  Rule,
  Loading,
  Icon,
  Container,
  Currency,
  Modal,
} from 'skybolt-ui';
import { useQuery, updateInvoice } from 'skybolt-api';
import moment from 'moment-timezone';

export default function(props) {

  const {
    history,
    match: {
      url,
      params:{
        invoiceId
      }
    }
  } = useRouter();

  const data = useQuery(`{
    agency {
      agencyId
      timezone
      invoice(invoiceId:$invoiceId) {
        invoiceId
        invoiceNumber
        clientId
        packId
        packName
        packImage
        dateDue
        total
        status
        credits(status:null, clientId:null, packId:null) {
          creditId
          dateCheck
          dateDeposit
          checkNumber
          referenceNumber
          amount
        }
      }
    }
  }`, {invoiceId});

  let timezone = data?.agency?.timezone || moment.tz.guess();

  function addCredit() {
    history.push({
      pathname: `/billing/accounts-receivable/create`,
      state: {
        initialData:{
          type: "INVOICE",
          clientId: data.agency.invoice.clientId,
          invoiceNumber: data.agency.invoice.invoiceNumber,
          packId: data.agency.invoice.packId,
          packName: data.agency.invoice.packName,
          packImage: data.agency.invoice.packImage,
          amount: data.agency.invoice.total,
        },
        referrer: url,
      }
    });
  }


  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  return (
    <div>
      <Container>
        <div style={{display:"flex", alignItems:"flex-end", height:40, paddingTop:32}}>
          <Text title>Payments Received</Text>
          <div style={{flex:1}}/>
          <Button title onClick={addCredit}>
            <Icon name="plus" color="white"/>
          </Button>
        </div>
        <Rule style={{marginBottom:24}}/>
      </Container>

      <div style={{width:'100%', overflowX:'auto'}}>
        <Container style={{minWidth:900}}>

          <div style={{display:'flex', alignItems:'center', padding:"0 8px 8px 8px"}}>
            <Text block small light style={{flex:1}}>Check Date</Text>
            <Text block small light style={{flex:1}}>Deposit Date</Text>
            <Text block small light style={{flex:1}}>Reference Number</Text>
            <Text block small light style={{flex:1}}>Check Number</Text>
            <Text block small light style={{flex:1}}>Amount</Text>
          </div>

          {data.agency.invoice.credits.length === 0 &&
            <div style={{padding:"60px 0", display:'flex', flexDirection:'column', alignItems:'center'}}>
              <Icon name="surprise" duotone size={80} color="accentLighter" style={{marginBottom:16}}/>
              <Text paragraph style={{maxWidth:400, textAlign:'center'}}>
                No payments have been received for this invoice.
              </Text>
              <Button
                raised accent
                label="Add a Payment"
                onClick={addCredit}
              />
            </div>
          }

          {data.agency.invoice.credits.map(credit => (
            <div key={credit.creditId} style={{display:'flex', alignItems:'center'}}>
              <Card
                key={credit.creditId}
                style={{
                  flex:1,
                  display:'flex',
                  alignItems:'center',
                  padding: '0 8px',
                  marginBottom:4,
                  cursor:'pointer',
                  height:40,
                }}
                onClick={() => {
                  history.push(`/billing/accounts-receivable/${credit.creditId}`, {referrer:url});
                }}>

                <Text block style={{flex:1}}>{moment(credit.dateCheck).tz(timezone).format("MM/DD/YYYY")}</Text>
                <Text block style={{flex:1}}>{moment(credit.dateDeposit).tz(timezone).format("MM/DD/YYYY")}</Text>
                <Text block style={{flex:1}}>{credit.referenceNumber}</Text>
                <Text block style={{flex:1}}>{credit.checkNumber}</Text>
                <Currency block bold style={{flex:1}}>{credit.amount}</Currency>

              </Card>

            </div>
          ))}

        </Container>

      </div>

    </div>
  );
}