import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import Header, {Action, Tab} from 'ui/Header';
import Media from 'ui/Media';
import ShareDialog from '../ShareDialog';
import ReplyDialog from './ReplyDialog';
import InviteAgentsDialog from '../InviteAgentsDialog';



export const mapStateToProps = (state, ownProps) => {
  const packId = ownProps.match.params.packId;
  const userId = _.get(state, 'user.id');
  const pack = state.packs.all[packId];

  // If this user `canPack` if:
  // 1. the user has a canPack permission
  // 2a. the user owns this package
  // 2b. the user is in the same agency as the package
  // 3. if the user's agency is in the recipients
  const canPackPermission = _.get(state, 'user.permissions.canPack', false);
  const canMessagePermission = _.get(state, 'user.permissions.canMessageTalents', false);
  const packUserId = _.get(state, `packs.all[${packId}].userId`, false);
  const userAgencyId = _.get(state, 'user.agencyId');
  const packAgencyId = _.get(pack, 'agencyId');
  const isOwnPack = userId === packUserId;
  const isSharedWithUser = !!_.find(pack.recipients, r => r.agencyId === userAgencyId);
  const hasAgency = !!userAgencyId;
  const isOwnAgency = !!userAgencyId && userAgencyId === packAgencyId;
  const canRequestPackMedia = _.get(state, `agencies.all[${packAgencyId}].canRequestPackMedia`, false);

  const canPack = canPackPermission && (isOwnAgency || isOwnPack || isSharedWithUser);

  // Can this user see all pack threads, or just the discussion?
  const canMessage = canPackPermission || canMessagePermission;

  // If not, show the agency logo as the `headerImage`.
  let headerImage = null;
  if(!canPack) {
    headerImage = _.get(state, `agencies.all[${packAgencyId}].logo`, null);
  }

  const threadId = _.get(state, `packs.all[${packId}].threadId`, false);

  return {
    pack,
    canPack,
    canMessage,
    canRequestPackMedia,
    headerImage,
    isOwnPack,
    isOwnAgency,
    hasAgency,
    threadId,
  };
};





export class PackHeader extends Component {

  static defaultProps = {
    editable: false,
  }

  state = {
    isShareDialogOpen: false,
    isReplyDialogOpen: false,
    isInviteDialogOpen: false,
  }

  openShareDialog = () => this.setState({isShareDialogOpen:true})
  closeShareDialog = () => this.setState({isShareDialogOpen:false})

  openReplyDialog = () => this.setState({isReplyDialogOpen:true})
  closeReplyDialog = () => this.setState({isReplyDialogOpen:false})

  openInviteDialog = () => this.setState({isInviteDialogOpen:true})
  closeInviteDialog = () => this.setState({isInviteDialogOpen:false})


  renderTabs() {
    const match = this.props.match;
    const tabs = [];

    if(this.props.canPack && (this.props.isOwnPack || this.props.isOwnAgency)) {
      tabs.push(
        <Tab
          icon="event"
          label="Event"
          path={match.path + '/event'}
          to={match.url + '/event'}
        />
      );
    }
    if(this.props.canPack) {
      tabs.push(
        <Tab
          icon="talent"
          label="Talent"
          path={match.path + '/talents'}
          to={match.url + '/talents'}
        />
      );
    }
    if(this.props.canPack && this.props.canMessage) {
      tabs.push(
        <Tab
          icon="messages"
          label="Messages"
          path={match.path + '/messages'}
          to={match.url + '/messages'}
        />
      );
    }

    tabs.push(
      <Tab
        icon="shortlist"
        label={this.props.canPack ? "Shortlist" : "Talent"}
        path={match.path + '/shortlist'}
        to={match.url + '/shortlist'}
      />
    );

    if(this.props.canRequestPackMedia) {
      tabs.push(
        <Tab
          icon="media"
          label="Requested Media"
          path={match.path + '/media'}
          to={match.url + '/media'}
        />
      );
    }

    if(this.props.canPack && !this.props.canMessage) {
      tabs.push(
        <Tab
          icon="messages"
          label="Messages"
          path={match.path + '/threads'}
          to={match.url + '/threads'}
        />
      );
    }

    // if(!this.props.canPack) {
    //   tabs.push(
    //     <Tab
    //       icon="messages"
    //       label="Messages"
    //       path={match.path + '/discussion'}
    //       to={match.url + '/discussion'}
    //     />
    //   );
    // }

    tabs.push(
      <Tab
        icon="schedule"
        label="Schedule"
        path={match.path + '/schedule'}
        to={match.url + '/schedule'}
      />
    );

    return tabs;
  }

  renderActions() {
    const actions = [];


    if(this.props.canPack) {
      actions.push(
        <Action icon="download" label="Download" onClick={() => this.downloadLink.click()}/>
      );
    }


    if(
      this.props.canPack && // if this user can pack
      this.props.isOwnAgency // in this agency
    ) {
      actions.push(
        <Action
          icon="send"
          label="Share"
          onClick={this.openShareDialog}
          primary={true}
        />
      );
    }

    else if(
      !this.props.hasAgency && // user doesn't have an agency
      this.props.canPack && // and this user can pack
      this.props.isOwnPack  // and this is their pack
    ) {
      actions.push(
        <Action
          icon="send"
          label="Share Package"
          onClick={this.openShareDialog}
          primary={true}
        />
      );

      actions.push(
        <Action
          icon="send"
          label="Contact Agent"
          onClick={this.openInviteDialog}
          primary={true}
        />
      );
    }

    else {
      actions.push(
        <Action
          icon="reply"
          label="Reply"
          onClick={this.openReplyDialog}
          primary={true}
        />
      );
    }


    return actions;
  }

  render() {
    const { pack, headerImage } = this.props;
    if(!pack) return null;

    const isCastingDirector = this.props.isOwnPack && !this.props.isOwnAgency;

    return (
      <div>
        <Header
          style={{marginTop:32}}
          title={pack.name}
          image={headerImage && <Media media={headerImage} format='png' style={{padding:8, boxSizing:'border-box'}}/>}
          actions={this.renderActions()}
          tabs={this.renderTabs()}
        />

        <ShareDialog
          open={this.state.isShareDialogOpen}
          onRequestClose={this.closeShareDialog}
          onSend={this.closeShareDialog}
          pack={pack}
        />

        {isCastingDirector &&
          <InviteAgentsDialog
            open={this.state.isInviteDialogOpen}
            onRequestClose={this.closeInviteDialog}
            onSend={this.closeInviteDialog}
            pack={pack}
          />
        }

        <ReplyDialog
          open={this.state.isReplyDialogOpen}
          onRequestClose={this.closeReplyDialog}
          onSend={this.closeReplyDialog}
          pack={pack}
        />

        <a
          ref={el => this.downloadLink = el}
          style={{
            position: 'absolute',
            left: -1000,
          }}
          href={`https://us-central1-skybolt-99adb.cloudfunctions.net/packCSV?packId=${pack.id}`}
          download={true}
          rel="noopener noreferrer"
          target="_blank">
        </a>
      </div>
    );
  }
}


export default withRouter(
  connect(mapStateToProps)(PackHeader)
);
