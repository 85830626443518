import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadTalentEvents } from 'actions';
import moment from 'moment';
import _ from 'lodash';

import { Switch, Route, Redirect } from 'react-router-dom';
import Title, { Action } from 'ui/Title';
import EventCalendar from 'ui/EventCalendar';
import EventList from 'ui/EventList';
import EventDialog from 'ui/EventDialog';
import Placeholder from 'ui/Placeholder';
import Loading from 'ui/Loading';



export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const events = state.events.byTalent[talentId] || {};
  
  const agencyId = _.get(state, `talents.all[${talentId}].agencyId`, null);
  const agency = _.get(state, `agencies.all[${agencyId}]`, null);

  const url = ownProps.match.url;
  const path = ownProps.match.path;
  
  const isLoading = state.events.isLoading;
  
  return {
    path,
    url,
    talentId,
    agency,
    events,
    isLoading,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  loadTalentEvents,
};





export class TalentCalendar extends Component {
  
  state = {
    date: moment().valueOf(),
    isDialogOpen: false,
    viewWidth: null,
  }
  
  componentDidMount() {
    if(this.props.talentId) {
      this.loadEvents();
    }
    
    this.calcViewWidth();
    document.addEventListener('resize', this.calcViewWidth);
  }
  
  componentWillUnmount() {
    document.removeEventListener('resize', this.calcViewWidth);
  }
  
  
  loadEvents = () => {
    const date = moment(this.state.date);
    const filters = {
      fromDate: date.startOf('month').subtract(1, 'months').valueOf(),
      toDate: date.endOf('month').add(6, 'months').valueOf()
    };
    this.props.loadTalentEvents(this.props.talentId, filters);
  }
  
  calcViewWidth = () => {
    if(this.el) {
      this.setState({viewWidth:this.el.clientWidth});
    }
  }
  
  
  
  showSchedule = () => {
    this.props.history.push({pathname:'schedule', state:this.props.location.state});
  }
  
  showCalendar = () => {
    this.props.history.push({pathname:'month', state:this.props.location.state});
  }
  
  nextMonth = () => {
    this.setState({date: moment(this.state.date).add(1, 'month').valueOf()}, this.loadEvents);
  }
  
  prevMonth = () => {
    this.setState({date: moment(this.state.date).subtract(1, 'month').valueOf()}, this.loadEvents);
  }
  
  openDialog = event => {
    this.setState({isDialogOpen:true, event});
  }
  
  closeDialog = () => {
    this.setState({isDialogOpen:false});
  }
  
  openDate = date => {
    const location = this.props.location;
    this.setState({date}, () => {
      this.props.history.push({pathname:'schedule', state:location.state});
      this.loadEvents();
    });
  }
  
  
  
  
  render() {
    const path = this.props.path;
    const url = this.props.url;
    const location = this.props.location;
    
    if(!this.state.viewWidth) {
      return (
        <div ref={el => this.el = el}>
          <Title
            actions={[
              <Action key="add" onClick={this.openDialog}/>
            ]}
            onNext={this.nextMonth}
            onPrev={this.prevMonth}>
            {moment(this.state.date).format("MMMM, YYYY")}
          </Title>
          <Loading/>
          <EventDialog 
            open={this.state.isDialogOpen}
            onRequestClose={this.closeDialog}
            event={this.state.event} 
            talent={this.props.talent}
            talentId={this.props.talentId}
          />
        </div>
      );
    }
    
    // if(Object.keys(this.props.events).length === 0) {
    //   return (
    //     <div ref={el => this.el = el}>
    //       <Title
    //         actions={[
    //           <Action key="add" onClick={this.openDialog}/>
    //         ]}
    //         isLoading={this.props.isLoading}>
    //         Schedule
    //       </Title>
    //       <Placeholder 
    //         icon="calendar"
    //         message="There are no events in your calendar."
    //         label="Add Event"
    //         onClick={this.openDialog}
    //       />
    //       <EventDialog 
    //         open={this.state.isDialogOpen}
    //         onRequestClose={this.closeDialog}
    //         event={this.state.event} 
    //         talent={this.props.talent}
    //         talentId={this.props.talentId}
    //       />
    //     </div>
    //   );
    // }
    
    return (
      <Switch>
        <Route path={`${path}/month`} render={props => (
          <div ref={el => this.el = el}>
            
            <Title
              actions={[
                <Action key="schedule" icon="schedule" onClick={this.showSchedule} hidden={this.state.viewWidth < 400}/>,
                <Action key="add" onClick={this.openDialog}/>
              ]}
              onNext={this.nextMonth}
              onPrev={this.prevMonth}
              isLoading={this.props.isLoading}>
              {moment(this.state.date).format("MMM YYYY")}
            </Title>
            
            <EventCalendar 
              date={this.state.date}
              events={this.props.events}
              agency={this.props.agency}
              onClickEvent={this.openDialog}
              onClickDay={this.openDate}
              showName={false}
            />
            
            <EventDialog 
              open={this.state.isDialogOpen}
              onRequestClose={this.closeDialog}
              event={this.state.event} 
              talent={this.props.talent}
              talentId={this.props.talentId}
            />
            
          </div>
        )}/>
        
        <Route path={`${path}/schedule`} render={props => (
          <div ref={el => this.el = el}>
          
            <Title 
              actions={[
                <Action key="calendar" icon="calendar" onClick={this.showCalendar} hidden={this.state.viewWidth < 400}/>,
                <Action key="add" onClick={this.openDialog}/>
              ]}
              isLoading={this.props.isLoading}>
              Schedule
            </Title>
            
            <EventList 
              date={this.state.date}
              events={this.props.events}
              onClickEvent={this.openDialog}
              onClickAdd={this.openDialog}
            />
            
            <EventDialog 
              open={this.state.isDialogOpen}
              onRequestClose={this.closeDialog}
              event={this.state.event} 
              talentId={this.props.talentId}
            />
            
          </div>
        )}/>
        
        <Redirect to={{pathname:`${url}/month`, state:location.state}}/>
      </Switch>
    );
    
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(TalentCalendar);