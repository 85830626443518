import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updatePackTalent } from 'actions';
import { debounce } from 'lodash';

import Title from 'ui/Title';
import Page from 'ui/Page';
import TextField from 'material-ui/TextField';


export const mapStateToProps = (state, ownProps) => {
  const packId = !!ownProps.location.state && ownProps.location.state.packId;
  const talentId = ownProps.match.params.talentId;

  let notes = "";
  try {
    notes = state.packTalents.byPack[packId][talentId].comments;
  }
  catch(e) {
  }
  
  return {
    packId,
    talentId,
    notes
  };
};

export const mapDispatchToProps = {
  updatePackTalent,
};


export class TalentNotes extends Component {
  state = {
    notes: ""
  }
  
  componentDidMount() {
    this.setState({notes:this.props.notes});
  }
  
  deferredUpdate = debounce(() => {
    this.props.updatePackTalent(this.props.packId, this.props.talentId, {comments:this.state.notes});
  }, 1000)
  
  update = (notes) => {
    this.setState({notes}, this.deferredUpdate);
  }
  
  render() {
    return (
      <div>
      
        <Title>Notes</Title>
        
        <Page pad={true} style={{minHeight:400}}>
          <TextField
            fullWidth
            multiLine={true}
            underlineShow={false}
            hintText="Comments"
            value={this.state.notes}
            onChange={(e, notes) => this.update(notes)}
          />
        </Page>
        
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(TalentNotes);