import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  loadThread,
} from 'actions';

import Media from 'ui/Media';
import Button from 'ui/Button';
import TextField from 'material-ui/TextField';
import Icon from 'ui/Icon';



export const mapStateToProps = (state, ownProps) => {
  const userId = state.user.id;
  let senderIcon = 'user';
  let senderImage = null;
  
  if(state.user.isLoggedIn) {
    const user = state.users.all[userId];
    const userTalents = user.talents || {};
    
    const threadId = ownProps.threadId;
    const thread = state.threads.all[threadId] || {};
    
    const senderRecipient = Object.keys(thread.recipients || {})
      .map(id => thread.recipients[id])
      .find(recipient => !!userTalents[recipient.talentId]);
    if(senderRecipient) {
      const talent = state.talents.all[senderRecipient.talentId] || {};
      senderImage = talent.image;
    }
    
    senderIcon = user.role;
  }
    
  return {
    senderImage,
    senderIcon,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  loadThread,
};



export class Composer extends Component {
  
  static defaultProps = {
    className: "",
    hintText: "Reply...",
  }
  
  state = {
    body: "",
    attachments: [],
  }
  
  componentDidMount() {
    this.props.loadThread(this.props.threadId);
  }
  
  addAttachment = file => {
    if(!file) {
      return;
    }
    
    if(file.size / 1048576 > 10) {
      this.props.showSnackbar('Your file is too big. Keep it under 10MB');
      return;
    }
    
    let totalSize = file.size;
    for(const i in this.state.attachments) {
      totalSize += this.state.attachments[i].file.size;
    }
    if(totalSize / 1048576 > 30) {
      this.props.showSnackbar("Emails can't be bigger than 30MB");
      return;
    }
    
    
    if(file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg') {
      var fr = new FileReader();
      fr.onload = () => {
        this.setState({
          attachments: [
            ...this.state.attachments,
            {file, url:fr.result}
          ]
        });
      };
      fr.readAsDataURL(file);
      return;
    }
    
    this.setState({
      attachments: [
        ...this.state.attachments,
        {file, url: ""}
      ]
    });
  }
  
  removeAttachment = (i) => {
    let attachments = [...this.state.attachments];
    attachments.splice(i, 1);
    this.setState({attachments});
  } 
  
  send = () => {
    this.props.onSend({
      body: this.state.body,
      attachments: this.state.attachments,
    });
    this.setState({body:"", attachments:[]});
  }
  
  render() {
    return (
      <div 
        className={`threadslist-composer ${this.props.className}`}
        onClick={() => this.input && this.input.focus()}>
        
        <div className="threadslist-composer-input">
          <Media 
            className="threadslist-composer-image"
            width={48}
            height={48}
            crop="thumb"
            face
            media={this.props.senderImage}
            icon="user"
          />
          
          <div className="threadslist-composer-body">
            <div className="threadslist-composer-body-text">
              <TextField 
                ref={el => this.input = el}
                underlineShow={false}
                fullWidth
                multiLine={true}
                hintText={this.props.hintText}
                value={this.state.body}
                onChange={(e, body) => this.setState({body})}
              />
              
              {this.state.attachments.length > 0 && 
                <div className="threadslist-composer-attachments">
                  {this.state.attachments.map((attachment, i) => {
                    return (
                      <div key={attachment.file.name} className="threadslist-composer-attachment">
                        {attachment.url ? (
                          <img alt="Attachment" src={attachment.url} className="threadslist-composer-attachment-preview"/>
                        ) : (
                          <Icon name="file" size={80} style={{marginTop:-6}}/>
                        )}
                        <div className="threadslist-composer-attachment-name">{attachment.file.name}</div>
                        
                        <div className="threadslist-composer-attachment-remove-overlay">
                          <Button icon="remove" size={24} color="white" onClick={() => this.removeAttachment(i)} className="threadslist-composer-attachment-remove"/>
                        </div>
                      </div>
                    );
                  })}
                </div>
              }
            </div>
            {(this.state.body || this.state.attachments.length > 0) && 
              <Button 
                width={40}
                height={40}
                key="send"
                icon="send"
                onClick={this.send}
                backgroundColor="#E0E0E0"
                color="#BDBDBD"
              />
            }
          </div>
        </div>
        
        <div className="threadslist-composer-actions">
          <Button
            icon="attach"
            label="Add Attachment"
            color="#aaa"
            labelStyle={{color:"#aaa"}}
            onClick={() => this.hiddenInput.click()}
          />
          
        </div>
        
        <input 
          name="imageinput"
          ref={el => this.hiddenInput = el}
          type="file" 
          onChange={e => {
            this.addAttachment(e.target.files[0]);
          }}
          style={{position: 'absolute', left: -1000}}
        />
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Composer);