import React from 'react';
import {
  Switch,
  Route,
  useRouter,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';


import Form from './Form';
import Sent from './Sent';

export default props => {
  const {match:{path}} = useRouter();
  const data = useQuery(`{
    user {
      permissions {
        canAddTalents
      }
    }
  }`);

  if(!data) {
    return null;
  }

  if(!data.user.permissions.canAddTalents) {
    return null;
  }

  return (
    <Switch>
      <Route path={`${path}/sent/:invitationId?`} component={Sent}/>
      <Route path={`${path}`} component={Form}/>
    </Switch>
  );
};