import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadPackThread } from 'actions';
import { get } from 'lodash';

import Thread from 'ui/ThreadsList/Thread';
import Title from 'ui/Title';
import Placeholder from 'ui/Placeholder';
import Container from 'ui/Container';
import ShareDialog from '../ShareDialog';




export const mapStateToProps = (state, ownProps) => {
  const packId = ownProps.match.params.packId;
  const pack = !!packId ? state.packs.all[packId] : null;
  const thread = !!pack ? state.threads.all[pack.threadId] : null;
  const canShare = get(state, 'user.permissions.canPack', false);
  let hasShared = false;

  if(thread) {
    const recipients = thread.recipients;
    const recipientIds = Object.keys(recipients);
    hasShared = recipientIds.length > 1 || recipients[recipientIds[0]].userId !== state.user.id;
  }

  return {
    packId,
    pack,
    thread,
    hasShared,
    canShare,
  };
};

export const mapDispatchToProps = {
  loadPackThread,
};



export class Discussion extends Component {
  state = {
    isShareDialogOpen: false,
  }

  UNSAFE_componentWillMount() {
    this.props.loadPackThread(this.props.packId);
  }

  openShareDialog = () => this.setState({isShareDialogOpen:true})
  closeShareDialog = () => this.setState({isShareDialogOpen:false})

  renderContent() {
    if(!this.props.thread) {
      return null;
    }

    if(this.props.canShare && !this.props.hasShared) {
      return [
        <Placeholder
          key="placeholder"
          icon="message"
          message="This package hasn't been shared with anyone."
          label="Share Package"
          onClick={this.openShareDialog}
        />,
        <ShareDialog
          key="dialog"
          open={this.state.isShareDialogOpen}
          onRequestClose={this.closeShareDialog}
          onSend={this.closeShareDialog}
          pack={this.props.pack}
        />
      ];
    }

    return (
      <Thread
        threadId={this.props.pack.threadId}
        subject="Discussion"
      />
    );
  }

  render() {
    return (
      <Container>
        <Title>Discussion</Title>
        {this.renderContent()}
      </Container>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Discussion);