import React, { useContext } from 'react';
import Hamburger from './Hamburger';
import {
  Button,
  Text,
  Rule,
  Icon,
  Flag,
  ThemeContext,
} from 'skybolt-ui';
import User from '../User';
import { useQuery } from 'skybolt-api';

const SKYBOLT_LOGO = "https://res.cloudinary.com/hllycw0yx/image/upload/v1530075335/emails/logo-white.png";



export default function NavigationMobile(props) {


  // Data

  const data = useQuery(`{
    user {
      permissions {
        canOwnTalents
        canPack
        canViewPacks
        canRequestPacks
        canViewTalents
        canAgencyCalendar
        canMessageTalents
        canSeeSeekingAgencyTalent
        canApproveTalents
        canApproveMedia
        canConfigAgency
        canAdminUsers
        canAdminAgencies
        canAddTalents
        canAddAgents
        canAddCastingDirectors
        canSpotlight
        canAdminTalents
      }
      activeDivision
      talents {
        fullName
        image(width:40, height:40, face:true)
        talentId
        status
      }
    }
    agency {
      agencyId
      image(height:40)
      requireTalentApproval
      requireTalentMediaApproval
      allowUnassignedTalents
    }
    activeTalent {
      agency {
        image(height:40)
      }
    }
    unreadPacksCount
    unreadMessagesCount
    unapprovedMediaCount
    unapprovedTalentsCount
  }`);

  const activeTalents = data
    ? data.user.talents.filter(talent =>
        talent.status === 'PAID' ||
        talent.status === 'TRIAL' ||
        talent.status === 'INACTIVE' ||
        talent.status === 'EXPIRED'
      )
    : [];
  const incompleteTalents = data
    ? data.user.talents.filter(t => t.status === "INCOMPLETE")
    : [];


  // The logo that appears in the menu.
  // If this is a talent user, use the active talent's agency's logo.
  // If this is an agenct user, use that agency's logo.
  // Default to a skybolt logo.

  let agencyLogo = SKYBOLT_LOGO;
  if(data) {
    if(data.user.canOwnTalents && data.activeTalent.agency.image) {
      agencyLogo = data.activeTalent.agency.image;
    }
    if(data.user.canPack && data.agency.image) {
      agencyLogo = data.agency.image;
    }
  }



  // Layout

  const theme = useContext(ThemeContext);

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        height:60,
        backgroundColor: theme.color.primaryDarker,
        boxShadow: "0 0 4px rgba(0,0,0,0.3)",
      }}>

      {!!data &&
        <Hamburger color={theme.color.lightText} style={{padding:(60-24)/2}}>
          <div style={{height:16}}/>
          {data.user.permissions.canOwnTalents &&
            <div>
              {activeTalents.map(talent =>
                <Button
                  key={talent.talentId}
                  href={`/talents/${talent.talentId}`}
                  style={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding:"2px 4px 0px 20px",
                  }}
                  color={theme.color.lightText}
                  >
                  <img style={{flex:0, marginRight:16}}
                    src={talent.image}
                    alt="headshot"
                  />
                  <Text style={{flex:1}} color={theme.color.lightText}>
                    {talent.fullName}
                  </Text>
                </Button>
              )}

              {incompleteTalents.length > 0 ? (
                incompleteTalents.map(talent =>
                  <Button
                    key={talent.talentId}
                    icon="plus"
                    style={{
                      display: 'block',
                      padding: "24px 0 0 20px",
                      color: theme.color.lightText,
                    }}
                    href={`/talents/${talent.talentId}`}>
                    Continue {talent.fullName || "New Talent"}
                  </Button>
                )
              ) : (
                <Button
                  icon="plus"
                  style={{
                    display: 'block',
                    padding: "24px 0 0 20px",
                    color: theme.color.lightText,
                  }}
                  href="/talents/create">
                  Add Talent
                </Button>
              )}


              <Rule
                color={theme.color.lightTextDarker}
                style={{margin:'24px 0'}}
              />
            </div>
          }

          {(data.user.permissions.canPack || data.user.permissions.canViewPacks) &&
            <HamburgerButton icon="pack" href="/packs" label="Packages" flag={data.unreadPacksCount}/>
          }

          {(data.user.permissions.canPack || data.user.permissions.canViewTalents) &&
            <HamburgerButton icon="talent" href="/talent-list" label="Talent"/>
          }

          {(data.user.permissions.canAgencyCalendar || data.user.talents.length > 1) &&
            <HamburgerButton icon="calendar" href="/calendar" label="Calendar"/>
          }

          {(data.user.permissions.canMessageTalents || data.user.talents.length > 1) &&
            <HamburgerButton icon="messages" href="/messages" label="Messages" flag={data.unreadMessagesCount}/>
          }

          {data.user.permissions.canSpotlight &&
            <HamburgerButton icon="spotlights" href="/spotlights" label="Spotlights"/>
          }

          {data.user.permissions.canSeeSeekingAgencyTalent &&
            <HamburgerButton icon="talent" href="/seeking-agency" label="Seeking Rep."/>
          }

          {data.user.permissions.canRequestPack &&
            <HamburgerButton icon="pack" href="/casting-request" label="Create Casting Request"/>
          }

          {(
            (data.agency.requireTalentApproval && data.user.permissions.canApproveTalents) ||
            (data.agency.requireTalentMediaApproval && data.user.permissions.canApproveMedia) ||
            data.user.permissions.canConfigAgency ||
            (data.user.permissions.canAdminUsers || data.user.permissions.canAddAgents || data.user.permissions.canAddCastingDirectors) ||
            data.user.permissions.canAdminAgencies ||
            data.user.permissions.canAdminTalents ||
            (data.user.permissions.canConfigAgency && data.agency.useContacts) ||
            data.user.permissions.canAdminFAQ
          ) && (
            <div>
              <Rule
                color={theme.color.lightTextDarker}
                style={{margin:'24px 0 0 0'}}
              />
              <Text color="lightTextDark" style={{padding:"16px 0 0 25px"}}>Maintenance</Text>
            </div>
          )}

          {data.agency.requireTalentApproval && data.user.permissions.canApproveTalents &&
            <HamburgerButton icon="approvetalent" href="/admin/approve-talents" label="Approve Talent" flag={data.unapprovedTalentsCount}/>
          }
          {data.agency.requireTalentMediaApproval && data.user.permissions.canApproveMedia &&
            <HamburgerButton icon="approvemedia" href="/media/approve" label="Approve Media" flag={data.unapprovedMediaCount}/>
          }
          {data.user.permissions.canConfigAgency &&
            <HamburgerButton icon="gear" href={`/admin/agencies/${data.agency.agencyId}`} label="Agency Config."/>
          }
          {(data.user.permissions.canAdminUsers || data.user.permissions.canAddAgents || data.user.permissions.canAddCastingDirectors) &&
            <HamburgerButton icon="user" href="/admin/users" label="Users"/>
          }
          {data.user.permissions.canAdminAgencies &&
            <HamburgerButton icon="agency" href="/admin/agencies" label="Agencies"/>
          }
          {data.user.permissions.canAdminTalents &&
            <HamburgerButton icon="talent" href="/admin/talents" label="Talent"/>
          }
          {data.user.permissions.canConfigAgency && data.agency.useContacts &&
            <HamburgerButton icon="contact" href="/admin/contacts" label="Contacts"/>
          }
          {data.user.permissions.canAdminTalents &&
            <HamburgerButton icon="invitation" href="/admin/invites" label="Invitations"/>
          }
          {data.user.permissions.canAdminFAQ &&
            <HamburgerButton icon="help" href="/admin/faq" label="FAQ"/>
          }


          {(
            data.user.permissions.canAddTalents ||
            data.user.permissions.canAddAgents ||
            data.user.permissions.canAddCastingDirectors
          ) && (
            <div>
              <Rule
                color={theme.color.lightTextDarker}
                style={{margin:'24px 0 0 0'}}
              />
              <Text color="lightTextDark" style={{padding:"16px 0 0 25px"}}>Network</Text>
            </div>
          )}

          {data.user.permissions.canAddTalents && data.agency.allowUnassignedTalents &&
            <HamburgerButton icon="plus" href="/add-talent" label="Add Talent"/>
          }

          {data.user.permissions.canAddTalents &&
            <HamburgerButton icon="invitation" href="/admin/invite-talent" label="Invite Talent"/>
          }

          {data.user.permissions.canAddAgents &&
            <HamburgerButton icon="invitation" href="/admin/invite-agent" label="Invite Agent"/>
          }

          {data.user.permissions.canAddCastingDirectors &&
            <HamburgerButton icon="invitation" href="/admin/invite-castingdirector" label="Invite Guest"/>
          }

          <div style={{height:64}}/>

        </Hamburger>
      }

      <div style={{flex:1}}/>

      {!!data &&
        <div style={{position:'relative'}}>
          <img src={agencyLogo} alt="logo" style={{height:40, padding:10}}/>
          {data.user?.activeDivision && <Flag top={40} right={0}>{data.user?.activeDivision}</Flag>}
        </div>
      }

      <div style={{flex:1}}/>

      <User/>

    </div>
  );
}

function HamburgerButton({icon, label, flag, href, style, ...props}) {
  const theme = useContext(ThemeContext);

  return (
    <Button
      href={href}
      style={{
        position: 'relative',
        display:'flex',
        alignItems:'center',
        justifyContent: 'flex-start',
        ...style,
      }}
      {...props}>

      <Icon
        name={icon}
        size={24}
        color={theme.color.lightText}
        style={{
          width: 40,
          marginTop: (40-24)/2,
          marginBottom: (40-24)/2,
          marginRight: 16,
        }}
      />

      <Text
        color={theme.color.lightText}>
        {label}
      </Text>

      {!!flag && <Flag top={20} right={16}>{flag}</Flag>}
    </Button>
  );
}