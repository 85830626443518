/* global skybolt */

import React, { Component, useRef, useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  showSnackbar,
  watchVersion,
  // addPack,
  // addTalent,
  // logout,
  // setActiveDivision,
  // setAgency,
  // loadAgencies,
  // loadPacks,
} from 'actions';

import "./index.css";

import {
  // BrowserRouter as Router,
  Router,
  Route,
  Switch,
  Redirect,
  // withRouter,
  // matchPath,
} from 'react-router-dom';

import AddIcon from 'material-ui/svg-icons/content/add';

import CreatingPack from 'ui/CreatingPack';
import ApproveTalents from 'ui/ApproveTalents';
import PrivateRoute from './PrivateRoute';
import OldAgencies from 'ui/Agencies';
import CircularProgress from 'material-ui/CircularProgress';
import Snackbar from 'material-ui/Snackbar';
import Dialog from 'ui/Dialog';
import Theme from 'ui/Theme';
import AgencyTalents from 'ui/AgencyTalents';
import FAQAdmin from 'ui/FAQAdmin';
// import CreateSubscription from 'ui/CreateSubscription';
import OldUsers from 'ui/Users';
import Talent from 'ui/Talent';
import Container from 'ui/Container';
import Packs from 'ui/Packs';
import Pack from 'ui/Pack';
import Messages from 'ui/Messages';
import Invitations from 'ui/Invitations';
import VerifyEmail from 'ui/VerifyEmail';
import PrintResume from 'ui/PrintResume';
import PrintMedia from 'ui/PrintMedia';
import PrintHeadshot from 'ui/PrintHeadshot';
import Calendar from 'ui/Calendar';
// import InviteTalent from 'ui/InviteTalent';
// import InviteAgent from 'ui/InviteAgent';
// import InviteCastingDirector from 'ui/InviteCastingDirector';
import Notice from 'ui/Notice';
import Agency from 'ui/Agency';
import Transactions from 'ui/Transactions';
import AcceptPackRequest from 'ui/AcceptPackRequest';
import CreatePackRequest from 'ui/CreatePackRequest';
import Contacts from 'ui/AgencyContacts';
import Contact from 'ui/Contact';
import CreateContact from 'ui/CreateContact';
import LinkingGoogle from 'ui/LinkingGoogle';
import TalentsSeekingAgency from 'ui/TalentsSeekingAgency';
import PackTalentSchedule from 'ui/Talent/Schedule';

import { Modal } from 'skybolt-ui';

import Title, { Tab, Action } from 'ui/Title';


// TODO: Do we progressively load these here.

import Navigation from './Navigation';
import Logout from './Logout';
import Embed from './Embed';
import Spotlights from './Spotlights';
import Billing from './Billing';
import Reports from './Reports';
import Agencies from './Agencies';
import Media from './Media';
import Dashboard from './Dashboard';
import Admin from './Admin';


// import User from 'ui/User';
import Users from './Users';
import User from './Users/User';

// TODO: Once all of packs is migrated, fold this into ./Packs.
import CreatePack from './Packs/Create';

// TODO: This should be folded into Talent once we get all of 
// talent over to the new system.
import TalentCreate from './Talents/Create';
import TalentSubscription from './Talents/Talent/Subscription';
import TalentAgencySelect from './Talents/Talent/SelectAgency';
import TalentAddons from './Talents/Talent/Addons';
import PublicAgencyTalents from './Agencies/Agency/Talents';
import TalentWelcome from './Talents/Talent/Welcome';
import UnassignedTalents from './Talents/Unassigned';
import TalentMediaRequest from './Talents/Talent/MediaRequest';
import ScheduledMessages from './Admin/ScheduledMessages';


window.skybolt = window.skybolt || {};
window.skyboltsnow = window.skyboltsnow || {};
// window.skyboltkit = window.skyboltkit || {};

function SvelteComponent(props) {
  let wrapper = useRef();
  let app = useRef();
  let instance = useRef();

  let component = props.component;

  useLayoutEffect(() => {
    if(!component) {
      return;
    }
    let Component = window.skybolt[component];
    if(app.current == Component) {
      return;
    }

    if(instance.current) {
      instance.current.$destroy();
    }
    
    app.current = Component;
    instance.current = new Component({target:wrapper.current});
    window.skybolt.attach(wrapper.current);

  }, [wrapper.current, window.skybolt, component]);

  return (
    <div
      ref={wrapper}
      style={{
        display: 'block',
        width: '100%',
      }}
    />
  );
}

function SnowComponent(props) {
  let wrapper = useRef();
  let app = useRef();
  let instance = useRef();

  let component = props.component;

  useLayoutEffect(() => {
    if(!component) {
      return;
    }
    let Component = window.skyboltsnow[component];
    if(app.current == Component) {
      return;
    }

    if(instance.current) {
      instance.current.$destroy();
    }
    
    app.current = Component;
    instance.current = new Component({target:wrapper.current});
    window.skyboltsnow.attach(wrapper.current);

  }, [wrapper.current, window.skyboltsnow, component]);

  return (
    <div
      ref={wrapper}
      style={{
        display: 'block',
        width: '100%',
      }}
    />
  );
}

function KitComponent(props) {
  let wrapper = useRef();
  let app = useRef();
  let instance = useRef();

  let { component, ...forwardProps } = props;

  useLayoutEffect(() => {
    if(!component) {
      return;
    }
    let Component = window.skyboltkit[component];
    if(!Component) {
      return;
    }
    if(app.current == Component) {
      if(instance.current) {
        instance.current.$set(forwardProps);
      }
      return;
    }

    if(instance.current) {
      instance.current.$destroy();
    }
    
    app.current = Component;
    instance.current = new Component({target:wrapper.current, props:forwardProps});

  }, [wrapper.current, window.skyboltkit, component, forwardProps]);

  useEffect(() => {
    return () => {
      if(instance.current) {
        instance.current.$destroy();
      }
    }
  }, []);

  return (
    <div
      ref={wrapper}
      style={{
        display: 'block',
        width: '100%',
      }}
    />
  );
}


class AppRouter extends React.Component {

  previousLocation = null
  modalHistoryLength = 0

  componentDidMount() {
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props;
    const inModal = location.state && location.state.modal;
    const isPop = nextProps.history.action === 'POP';

    if(!isPop && !inModal) {
      this.previousLocation = location;
    }
  }

  closeModal = e => {
    this.props.history.push(this.previousLocation.pathname);
  }

  render() {
    const { location } = this.props;
    const isModal = !!(
      location.state &&
      location.state.modal &&
      !!this.previousLocation // not initial render
    );

    return (
      <div className="router-page-wrapper">

        <Navigation/>

        <Switch location={isModal ? this.previousLocation : location}>


          {/*
          Routes from Svelte App.
          Note: These routes have to match what the svelte component expects.
          */}

          <Route path="/notices" render={(props) =>
            <SvelteComponent component="Notices"/>
          }/>

          <Route path="/opt-in" render={(props) =>
            <SvelteComponent component="OptIn"/>
          }/>

          <Route path="/create-talent" render={(props) =>
            <SnowComponent component="CreateTalent"/>
          }/>

          <Route path="/admin/invitations/:tab?" render={(props) =>
            <Container>
              <Title
                tabs={[
                  <Tab path="/admin/invitations" to="/admin/invitations" label="All"/>,
                  <Tab path="/admin/invitations/pending" to="/admin/invitations/pending" label="Pending"/>,
                ]}
              >
                Invitations
              </Title>
              <KitComponent component="InviteList" pending={props.match.params.tab == "pending"}/>
            </Container>
          }/>

          <Route path="/admin/scheduled-messages/:id?" component={ScheduledMessages}/>
          


          {/* 
          Routes from Skybolt Snow App.
          Note: These routes have to match what the svelte component expects.
          */}

          <Route path="/admin/spotlights" render={(props) =>
            <SnowComponent component="Spotlights"/>
          }/>


          <PrivateRoute exact path="/" component={Dashboard}/>

          <PrivateRoute path="/billing" component={Billing}/>

          {/*
          <PrivateRoute exact path="/subscription/:talentId?" render={props =>
            <Theme talentId={props.match.params.talentId}>
              <CreateSubscription {...props}/>
            </Theme>
          }/>
          <PrivateRoute exact path="/change-subscription/:talentId?" render={props =>
            <CreateSubscription redirectIfSubscribed={false} {...props}/>
          }/>
          */}
          <PrivateRoute exact path="/talent-list/:agencyId?" component={AgencyTalents}/>
          <PrivateRoute exact path="/packs" component={Packs}/>
          <Route path="/packs/create" component={CreatingPack}/>
          {/*<Route path="/packs/:packId" render={props => {
            if(this.props.isLoggedIn) {
              return <Pack {...props}/>;
            }
            return <Theme packId={props.match.params.packId}><PublicPack {...props}/></Theme>;
          }}/>*/}

          <PrivateRoute exact path="/create-pack" component={CreatePack}/>

          <Route path="/packs/:packId" render={props => {
            // if(this.props.isLoggedIn) {
            //   return <Pack {...props}/>;
            // }
            // return <Theme packId={props.match.params.packId}><PublicPack {...props}/></Theme>;
            return <Theme packId={props.match.params.packId}><Pack {...props}/></Theme>;
          }}/>


          <Route path="/spotlights" component={Spotlights}/>
          <PrivateRoute path="/agencies" component={Agencies}/>

          <PrivateRoute path="/messages" component={Messages}/>

          <PrivateRoute path="/talents/create" component={TalentCreate}/>
          <Route path="/talents/unassigned" component={UnassignedTalents}/>
          <Route path="/talents/agency/:agencyId" component={PublicAgencyTalents}/>
          <Route path="/talents/:talentId/select-agency" component={TalentAgencySelect}/>
          <Route path="/talents/:talentId/subscription" render={props => 
            <Theme talentId={props.match.params.talentId}>
              <TalentSubscription {...props}/>
            </Theme>
          }/>
          <Route path="/talents/:talentId/addons" render={props => 
            <Theme talentId={props.match.params.talentId}>
              <TalentAddons {...props}/>
            </Theme>
          }/>
          <Route path="/talents/:talentId/add-media/:packId" render={props => 
            <Theme talentId={props.match.params.talentId}>
              <TalentMediaRequest {...props}/>
            </Theme>
          }/>

          <Route path="/talents/:talentId/pack-schedule/:packId" render={props => {
            return (
              <Theme talentId={props.match.params.talentId}>
                <Container>
                  <PackTalentSchedule talentId={props.match.params.talentId} packId={props.match.params.packId}/>
                </Container>
              </Theme>
            );
          }}/>

          {/* <PrivateRoute path="/talents/:talentId/subscription" component={TalentSubscription}/> */}
          {/* <Route path="/talents/:talentId/addons" component={TalentAddons}/> */}
          <Route path="/talents/:talentId/welcome" component={TalentWelcome}/>
          {/* <Route path="/talents/:talentId/add-media/:packId" component={TalentMediaRequest}/> */}

          <Route path="/talents/:talentId" render={props =>
            <Theme talentId={props.match.params.talentId}>
              <Container style={{paddingTop:32, paddingBottom:32}}>
                <Talent {...props} useWindowAsScrollContainer={true}/>
              </Container>
            </Theme>
          }/>

          <PrivateRoute path="/users" component={Users}/>

          <PrivateRoute path="/contacts/:contactId" render={props =>
            <Container style={{paddingTop:32, paddingBottom:32}}>
              <Contact {...props} onRemove={() => this.props.history.push('/contacts')}/>
            </Container>
          }/>
          <PrivateRoute path="/contacts" component={Contacts}/>
          <PrivateRoute path="/admin/contacts/:contactId" render={props =>
            <Container style={{paddingTop:32, paddingBottom:32}}>
              <Contact {...props} onRemove={() => this.props.history.push('/contacts')}/>
            </Container>
          }/>
          <PrivateRoute path="/admin/contacts" component={Contacts}/>
          <PrivateRoute path="/calendar" component={Calendar}/>
          <PrivateRoute path="/casting-request" component={CreatePackRequest}/>
          <PrivateRoute path="/user" component={User}/>
          <PrivateRoute path="/media" component={Media}/>
          <PrivateRoute path="/admin/approve-talents" component={ApproveTalents}/>
          <PrivateRoute path="/admin/agencies/:agencyId" component={Agency}/>
          <PrivateRoute path="/admin/agencies" component={OldAgencies}/>
          <PrivateRoute path="/admin/users/:userId" component={User}/>
          <PrivateRoute path="/admin/users" component={OldUsers}/>
          <PrivateRoute exact path="/admin/invites" component={Invitations}/>
          <PrivateRoute exact path="/seeking-agency" component={TalentsSeekingAgency}/>
          <PrivateRoute exact path="/admin/seeking-agency" component={TalentsSeekingAgency}/>
          <PrivateRoute exact path="/admin/faq" component={FAQAdmin}/>
          <PrivateRoute exact path="/admin/transactions" component={Transactions}/>
          <Route path="/notice" component={Notice}/>
          <PrivateRoute path="/welcome/:talentId?" component={TalentWelcome}/>
          <Route path="/verifyemail/:talentId/:emailId" component={VerifyEmail}/>
          <Route path="/acceptpack/:requestId" component={AcceptPackRequest}/>
          <PrivateRoute path="/admin" component={Admin}/>
        </Switch>

        {isModal ? (
          <Route path="/talents/:talentId" children={props => (
            <Modal status={!!props.match} onRequestClose={this.closeModal}>
              <Talent onClose={this.closeModal} {...props}/>
            </Modal>
          )}/>
        ) : null}

        {isModal ? (
          <Route path="/admin/talents/:talentId" render={props => (
            <Dialog
              className="router-dialog scroll-container"
              open={true}
              onRequestClose={this.closeModal}
              repositionOnUpdate={true}
              bodyStyle={{padding:0}}>
              <Talent onClose={this.closeModal} {...props}/>
            </Dialog>
          )}/>
        ) : null}

        {isModal ? (
          <Route path="/create-contact" render={props => (
            <Dialog
              className="router-dialog scroll-container"
              open={true}
              onRequestClose={this.closeModal}
              repositionOnUpdate={true}
              bodyStyle={{padding:0}}>
              <CreateContact onClose={this.closeModal} {...props}/>
            </Dialog>
          )}/>
        ) : (
          <Route path="/create-contact" render={props => (
            <Redirect to="/contacts"/>
          )}/>
        )}

        {isModal ? (
          <Route path="/contacts/:contactId" render={props => (
            <Dialog
              className="router-dialog scroll-container"
              open={true}
              onRequestClose={this.closeModal}
              repositionOnUpdate={true}
              bodyStyle={{padding:0}}>
              <Contact onRemove={this.closeModal} {...props}/>
            </Dialog>
          )}/>
        ) : null}

        <Snackbar
          open={!!this.props.snackbar.message}
          message={this.props.snackbar.message}
          autoHideDuration={this.props.snackbar.timeout}
          onRequestClose={() => this.props.showSnackbar(null)}
          action={this.props.snackbar.action}
          onActionClick={() => {
            switch(this.props.snackbar.action) {
              case "REFRESH":
                window.location.reload();
                return;
              default:
                return;
            }
          }}
        />

        <SnowComponent component="Alerts"/>
      </div>
    );
  }
}

class SessionRouter extends Component {

  componentDidMount() {
    this.props.watchVersion();
  }

  render() {
    const {
      snackbar,
      isLoading,
      isLoggedIn,
    } = this.props;

    if(isLoading || isLoggedIn === null) {
      return (
        <Theme>
          <div className="router-loading-wrapper">
            <CircularProgress size={20} color="#333333"/>
          </div>
        </Theme>
      );
    }

    return (
      <Router history={this.props.history}>
        <div className="router-wrapper">
          <Switch>
            {/*<Route path="/login/:agencyId?" component={Login}/>*/}
            <Route path="/login" render={() =>
              <SvelteComponent component="Login"/>
            }/>
            {/*<Route path="/register/:agencyId?" component={Register}/>*/}
            <Route path="/register" render={() =>
              <SvelteComponent component="Register"/>
            }/>
            <Route path="/forgot-password" render={() =>
              <SvelteComponent component="ForgotPassword"/>
            }/>
            <Route path="/logout" component={Logout}/>


            <Route path="/embed/spotlight/:spotlightId" render={(props) =>
              <SnowComponent component="EmbedSpotlight"/>
            }/>


            <Route path="/embed" component={Embed}/>
            {/*<Route path="/login/:agencyId?" render={props => <Theme agencyId={props.match.params.agencyId}><Login {...props}/></Theme>}/>*/}
            {/*<Route path="/forgot-password/:agencyId?" render={props => <Theme agencyId={props.match.params.agencyId}><ForgotPassword {...props}/></Theme>}/>*/}
            {/*<Route path="/register/castingdirector/:invitationId?" render={props => <Theme agencyId={props.match.params.agencyId}><Register userRole="castingdirector" {...props}/></Theme>}/>*/}
            {/*<Route path="/register/:agencyId?/:invitationId?" render={props => <Theme agencyId={props.match.params.agencyId}><Register {...props}/></Theme>}/>*/}
            <Route path="/print-resume/:talentId" render={props =>
              <Theme talentId={props.match.params.talentId}>
                <PrintResume {...props}/>
              </Theme>
            }/>
            <Route path="/print-headshot/:talentId" render={props =>
              <Theme talentId={props.match.params.talentId}>
                <PrintHeadshot {...props}/>
              </Theme>
            }/>
            <Route path="/print-media/:talentId" render={props =>
              <Theme talentId={props.match.params.talentId}>
                <PrintMedia {...props}/>
              </Theme>
            }/>
            <Route path="/google" component={LinkingGoogle}/>

            <Route render={props =>
              <Theme agencyId={props.agencyId}>
                <AppRouter
                  {...props}
                  showSnackbar={this.props.showSnackbar}
                  snackbar={snackbar}
                  isLoggedIn={isLoggedIn}
                  user={this.props.user}
                />
              </Theme>
            }/>
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    snackbar: state.snackbar,
    agencyId: state.user.agencyId || null,
    user: state.user,
    ...state.user
  };
};

const mapDispatchToProps = {
  showSnackbar,
  watchVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionRouter);