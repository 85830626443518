import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadInvitations } from 'actions';
import moment from "moment";

import "./index.css";

import Icon from 'ui/Icon';
import Container from 'ui/Container';
import Title from 'ui/Title';
import List from 'ui/List';



const mapStateToProps = (state, ownProps) => {
  return {
    invitations: state.invitations.all,
  };
};

const mapDispatchToProps = {
  loadInvitations
};



class Invitations extends Component {
  UNSAFE_componentWillMount() {
    this.props.loadInvitations();
  }

  renderInvitation = invitation => {
    return (
      <div className="invitations-invite" key={invitation.id}>
        <div className="invitations-icon">
          <Icon name={!!invitation.talent || invitation.talentId ? 'talent' : 'agency'}/>
        </div>

        {!!invitation.user &&
          <div className="invitations-agency">
            <div className="invitations-value">
              <div className="invitations-value">{invitation.agencyId}</div>
              {/*<div className="invitations-label">{invitation.id}</div>*/}
            </div>
          </div>
        }

        {!!invitation.user &&
          <div className="invitations-user">
            <div className="invitations-value">{invitation.user.firstName} {invitation.user.lastName}</div>
            <div className="invitations-label">{invitation.user.email}</div>
          </div>
        }

        {!!invitation.dateSent ? (
          <div className="invitations-date">
            <div className="invitations-label">Date Sent</div>
            <div className="invitations-value">{moment(invitation.dateSent).format('MM/DD/YY hh:mm')}</div>
          </div>
        ) : (
          <div className="invitations-date"></div>
        )}

        {!!invitation.dateUsed ? (
          <div className="invitations-date">
            <div className="invitations-label">Date Accepted</div>
            <div className="invitations-value">{moment(invitation.dateUsed).format('MM/DD/YY hh:mm')}</div>
          </div>
        ) : (
          <div className="invitations-date"></div>
        )}
      </div>
    );
  }

  renderInvitations = () => {
    const {invitations} = this.props;
    return Object.keys(invitations)
      .map(id => ({id, dateSent:0, ...invitations[id]}))
      .sort((a, b) => a.dateSent > b.dateSent ? -1 : 1)
      .map(this.renderInvitation);
  }

  render() {
    return (
      <Container>
        <Title>Invitations</Title>
        <List>
          {this.renderInvitations()}
        </List>
      </Container>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Invitations);