import React from 'react';
import { Switch, Route, Redirect } from 'skybolt-ui';
import Edit from './Edit';
import View from './View';

export default function Credit(props) {
  const {match:{ url, path }, location:{ state }} = props;

  return (
    <Switch>
      <Route path={`${path}/edit`} component={Edit}/>
      <Route path={`${path}/view`} component={View}/>
      <Redirect to={{pathname:`${url}/view`, state}}/>
    </Switch>
  );
}