import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import moment from 'moment';

import Month from './Month';
import Schedule from './Schedule';


export default class Calendar extends Component {
  
  state = {
    date: moment(),
  }
  
  openDate = date => {
    this.setState({date:moment(date)}, () => this.props.history.push('schedule'));
  }
  
  
  render() {
    const path = this.props.match.path;
    const url = this.props.match.url;
    
    return (
      <Switch>
        <Route path={`${path}/month`} render={props => 
          <Month date={this.state.date} onClickDay={this.openDate} {...props}/>
        }/>
        <Route path={`${path}/schedule`} render={props => 
          <Schedule date={this.state.date} {...props}/>
        }/>
        <Redirect to={`${url}/month`}/>
      </Switch>
    );
    
  }
}

