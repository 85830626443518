import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadTalent, loadUser, loadTalentAgency, updateTalent } from 'actions';

import './index.css';

import { Switch, Route, Redirect } from 'react-router';
import Loading from 'ui/Loading';
import Header from './Header';
import Schedule from './Schedule';
import Pack from './Pack';
import Calendar from './Calendar';
import Media from './Media';
import Messages from './Messages';
import PackHistory from './PackHistory';
import Resume from './Resume';
import Settings from './Settings';
import Info from './Info';
import Notes from './Notes';
import Comments from './Comments';

import Status from 'App/Talents/Talent/Status';
import Debits from 'App/Talents/Talent/Debits';






export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.talentId || ownProps.match.params.talentId;
  const packId = !!ownProps.location.state && ownProps.location.state.packId;
  const talent = state.talents.all[talentId];
  let agency = null;
  if(talent && talent.agencyId) {
    agency = state.agencies.all[talent.agencyId];
  }

  const url = ownProps.match.url;
  const path = ownProps.match.path;
  const location = ownProps.location;

  const user = state.users.all[ state.user.id ];
  const isLoggedIn = user && state.user.isLoggedIn;
  const isOwnTalent = user && isLoggedIn && !!user.talents && !!user.talents[talentId];

  const onClose = ownProps.onClose;

  const lastLoggedIn = localStorage.getItem('skybolt-lastloggedin');

  return {
    url,
    path,
    location,

    talentId,
    packId,

    talent,
    agency,
    userId: state.user.id,
    user,

    isLoggedIn,
    isOwnTalent,
    lastLoggedIn,

    onClose,
  };
};

export const mapDispatchToProps = {
  loadTalent,
  loadUser,
  loadTalentAgency,
  updateTalent,
};



export class Talent extends Component {
  componentDidMount() {
    this.props.loadUser(this.props.userId, {refresh:true});
    this.props.loadTalent(this.props.talentId, {refresh:true});
    this.props.loadTalentAgency(this.props.talentId);
    localStorage.setItem('skybolt-lastloggedin', Date.now());
  }

  componentDidUpdate() {
    if(!this.props.user) {
      this.props.loadUser(this.props.userId);
    }
    if(!this.props.talent) {
      this.props.loadTalent(this.props.talentId);
    }


    let { isOwnTalent, talent, talentId, history } = this.props;
    if(talentId && isOwnTalent && talent && !!talent.requireSubscription && talent.agencyId) {
      history.push(`/talents/${talentId}/subscription`);
    }
  }

  render() {
    const {
      talentId,
      packId,
      talent,
      user,
      path,
      url,
      isOwnTalent,
      location,
      onClose,
      lastLoggedIn,
    } = this.props;

    if(!talent || talent.isLoading) {
      return <Loading style={{padding:80}}/>;
    }

    if(isOwnTalent && talent.status === "TRIAL" && (!lastLoggedIn || Date.now() - lastLoggedIn > 1000*60*60*8)) {
      return <Redirect to={`/talents/${talentId}/subscription`}/>;
    }

    if(isOwnTalent && !talent.agencyId) {
      return <Redirect to={`/talents/${talentId}/select-agency`}/>;
    }

    if(isOwnTalent && !!talent.requireShowWelcome) {
      return <Redirect to={`/talents/${talentId}/welcome`}/>;
    }

    if(isOwnTalent && !!talent.requireAddons) {
      return <Redirect to={`/talents/${talentId}/addons`}/>;
    }

    return (
      <div className="sui-talent">
        <Header talent={talent} onClose={onClose}/>

        <Status/>

        <div
          style={{
            padding: !!onClose ? 16 : 0, // pad if it's a modal
            paddingTop: 0,
          }}>
          <Switch>
            <Route path={`${path}/pack`} render={props =>
              <Pack packId={packId} onSelectTime={onClose} {...props}/>
            }/>
            <Route path={`${path}/schedule`} render={props =>
              <Schedule packId={packId} {...props}/>
            }/>
            <Route path={`${path}/comments`} component={Comments}/>
            <Route path={`${path}/info`} component={Info}/>
            <Route path={`${path}/notes`} component={Notes}/>
            <Route path={`${path}/media`} render={props =>
              <Media
                useWindowAsScrollContainer={this.props.useWindowAsScrollContainer}
                {...props}
              />
            }/>
            <Route path={`${path}/messages`} component={Messages}/>
            <Route path={`${path}/resume`} render={props =>
              <Resume
                useWindowAsScrollContainer={this.props.useWindowAsScrollContainer}
                {...props}
              />
            }/>
            <Route path={`${path}/calendar`} component={Calendar}/>
            <Route path={`${path}/history`} component={PackHistory}/>
            <Route path={`${path}/settings`} component={Settings}/>
            <Route path={`${path}/payments`} component={Debits}/>
            <Redirect to={{pathname:`${url}/info`, state:location.state}}/>
          </Switch>
        </div>
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Talent);