import moment from 'moment';
import { load, callGetFunction } from 'skybolt-api';
import { useEffect, useState } from 'react';


export async function loadTransactions(userId) {
  if(!userId) {
    return [];
  }

  // Build a list of epayIds associated with the user. This is their own user
  // `epayId`, and all managed talent's `epayId`s.

  const epayIds = [];

  const userEpayId = await load(`users/${userId}/epayId`);
  const userTalents = await load(`users/${userId}/talents`);

  if(userEpayId) {
    epayIds.push(userEpayId);
  }

  const talentIds = Object.keys(userTalents || {});
  for(const talentId of talentIds) {
    const talentEpayId = await load(`talents/${talentId}/epayId`);
    if(talentEpayId) {
      epayIds.push(talentEpayId);
    }
  }


  // For each epay id, load transactions.

  let transactions = [];

  for(const epayId of epayIds) {
    if(epayId) {
      const data = await callGetFunction(`getUsaepayTransactions?customerId=${epayId}`);
      if(data) {
        if(data.error) {
          // todo: we're ignoring errors for now
        }
        else {
          transactions = [...transactions, ...data];
        }
      }
    }
  }


  // Sort all transactions by date.

  transactions.sort((a, b) => moment(a.date).isBefore(b.date) ? 1 : -1);


  // and return.

  return transactions;
}


export default function useTransactions(userId) {
  const [state, setState] = useState(null);

  useEffect(() => {
    if(!userId) {
      return;
    }
    loadTransactions(userId).then(t => setState(t));
  }, [userId]);

  return state;
}