/*

# Date Field


Example Usage:
    
    import SSNField from 'ui/SSNField';

    <SSNField 
      name="ssn"
      style={{maxWidth:600, paddingTop:16}}
      underline={true}
      initialValue={talent.ssn}
      onChange={ssn => update({ssn})}
    />


*/


import React from 'react';

import TextField from 'ui/TextField';

export const formatSSN = val => {
  const m = val.replace(/[^0-9.]*/g,"")
     .slice(0, 9)
     .match(/^(\d{1,3})(\d{0,2})?(\d{0,4})?$/);
     
  if(!m) {
    return val;
  }
  
  var str = "";
  if(m[1]) str += `${m[1]}`;
  if(m[2]) str += `-${m[2]}`;
  if(m[3]) str += `-${m[3]}`;
  return str;
};

export const validateSSN = val => {
  if(!val) {
    return;
  }
  if(val.replace(/[^0-9.]*/g,"").length !== 9) {
    return "Please enter a valid SSN.";
  }
};


export default ({
  onChange,
  initialValue,
  ...props
}) => (
  <TextField
    placeholder="XXX-XX-XXXX"
    clean={val => val.replace(/[^0-9.]*/g,"").slice(0, 9)}
    format={formatSSN}
    error={validateSSN}
    onChangeText={val => {
      if(!val) {
        onChange("");
        return "";
      }
        
      onChange(val.replace(/[^0-9.]*/g,""));
    }}
    initialValue={initialValue ? formatSSN(initialValue) : ""}
    
    {...props}
  />
);