import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadTalent, loadTalentAgency } from 'actions';
import { cloudinaryUrl } from 'skybolt-api';
import { isArray, isObject } from 'lodash';

import "./index.css";




function formatPhoneNumber(s) {
  var s2 = (""+s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? s : "(" + m[1] + ") " + m[2] + "-" + m[3];
}

const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const talent = state.talents.all[talentId];

  let agencyId, agency;
  if(talent && talent.agencyId) {
    agencyId = talent.agencyId;
    agency = state.agencies.all[agencyId];
  }

  const media = window.location.search.slice(7).split(',');

  return {
    talentId,
    agencyId,
    talent,
    agency,
    media,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  loadTalent,
  loadTalentAgency,
};





class PrintMedia extends Component {
  UNSAFE_componentWillMount() {
    if(this.props.talentId) {
      this.props.loadTalent(this.props.talentId);
      this.props.loadTalentAgency(this.props.talentId);
    }
  }

  count = 0
  onImageLoad = () => {
    this.count += 1;

    if(this.count === 5) { // media + agency logo = 5.
      window.parent.document.dispatchEvent(new CustomEvent('PRINTING'));
      window.print();
    }
  }

  render() {
    if(!this.props.talentId) {
      return (
        <div>Talent not found.</div>
      );
    }

    if(!this.props.talent || !this.props.agency) {
      return <div></div>;
    }

    const {
      agency,
      talent,
      media,
    } = this.props;

    const {
      firstName,
      lastName,
    } = talent;

    const logoId = agency.logo.cloudinary_id;
    const logoSource = cloudinaryUrl(logoId, {
      height: 320,
      secure: true,
    });

    const info = Object.keys(agency.talentFields)
      .filter(fieldId => agency.talentFields[fieldId].isPublic)
      .filter(fieldId => agency.talentFields[fieldId].category !== "system")
      .map(id => ({...agency.talentFields[id], id}))
      .sort((a, b) => !a.order || a.order < b.order ? -1 : 1)
      .map(field => {
        let value = talent[field.id];
        let units = field.units;

        if(isArray(value)) {
          value = value.join(', ');
        }
        if(isObject(value)) {
          if(value.from && value.to) {
            value = `${value.from} - ${value.to}`;
          }
          else {
            value = null;
          }
        }
        if(field.id === 'height') {
          value = `${Math.floor(value / 12)}'${Math.floor(value % 12)}"`.trim();
          units = "";
        }

        if(!value) {
          return null;
        }

        return (
          <div key={field.id} className="printmedia-field">
            <span className="printmedia-field-name">{field.name}: </span>
            <span className="printmedia-field-value">{value} {units}</span>
          </div>
        );
      });

    return (
      <div className="printmedia-wrapper">
        <section className="printmedia-page">
          <div className="printmedia-agency-logo">
            <img
              alt={agency.name}
              src={logoSource}
              onLoad={this.onImageLoad}
              height={80}
            />
          </div>
          <h1 className="printmedia-name">{firstName} {lastName}</h1>
          <div className="printmedia-info">{info}</div>
        </section>

        <section className="printmedia-grid">

          {media.map(id => {
            const url = cloudinaryUrl(id, {
              width: 800,
              secure: true,
            });

            return (
              <section key={id} className="printmedia-media">
                <img
                  alt="Headshot"
                  src={url}
                  onLoad={this.onImageLoad}
                  className="printmedia-media-image"
                />
              </section>
            );
          })}

        </section>

        <p className="printheadshot-contact">
          {agency.name}<br/>
          <strong>{agency.contactName || ""}</strong><br/>
          <strong>{formatPhoneNumber(agency.primaryPhone || "")}</strong><br/>
          {agency.displayEmail || agency.contactEmail || ""}<br/>
          {agency.website || ""}<br/>
        </p>
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(PrintMedia);