import { combineReducers } from "redux";


const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_CANNED_MESSAGES_SUCCESS':
      return {
        ...state,
        ...action.messages,
      };
      
    case 'ADD_CANNED_MESSAGE':
    case 'UPDATE_CANNED_MESSAGE':
      return {
        ...state,
        [action.message.id]: action.message,
      };
      
    case 'REMOVE_CANNED_MESSAGE':
      const { [action.messageId]:removedMessage, ...rest } = state;
      return rest;
      
    case 'LOGOUT':
    case 'SET_AGENCY':
      return {};
      
    default: 
      return state;
  }
};

const byAgency = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_CANNED_MESSAGES_SUCCESS':
      return {
        ...state,
        [action.agencyId]: {
          ...state[action.agencyId],
          ...action.messages,
        }
      };
      
    case 'ADD_CANNED_MESSAGE':
    case 'UPDATE_CANNED_MESSAGE':
      return {
        ...state,
        [action.agencyId]: {
          ...state[action.agencyId],
          [action.message.id]: action.message,
        },
      };
      
    case 'REMOVE_CANNED_MESSAGE':
      const activeState = {};
      for(const agencyId in state) {
        activeState[agencyId] = {};
        for(const messageId in state[agencyId]) {
          if(messageId !== action.messageId) {
            activeState[agencyId][messageId] = state[agencyId][messageId];
          }
        }
      }
      return activeState;
      
    case 'LOGOUT':
    case 'SET_AGENCY':
      return {};
      
    default: 
      return state;
  }
};


export default combineReducers({
  all,
  byAgency,
});