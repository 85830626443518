import React, { useContext } from 'react';
import { InputNumber, FormContext } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';

export default function(props) {

  const form = useContext(FormContext);
  const type = form.get('type');

  const data = useQuery(`{
    agency {
      billingTypes
    }
  }`);

  const billingType = data?.agency.billingTypes.find(t => t.label.toLowerCase() == type.toLowerCase());
  const deductCommission = billingType?.deductCommission;

  return (
    <InputNumber
      disabled={!deductCommission}
      name="commission"
      style={{marginRight:8, flex:1}}
    />
  );
}