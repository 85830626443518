import React from 'react';
import {
  Container,
  Switch,
  Route,
  Redirect,
  useRouter,
  Header,
  Icon,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';
import Info from './Info';
import Talents from './Talents';


export default function Spotlight(props) {

  const {
    match: {
      url,
      path,
      params: {
        spotlightId,
      }
    },
  } = useRouter();

  const data = useQuery(`{
    spotlight(spotlightId:$spotlightId) {
      name
    }
  }`, {spotlightId});



  // Loading

  if(!data) {
    return (
      <div style={{padding:"160px 16px"}}>
        <Icon name="loading" large/>
      </div>
    );
  }


  return (
    <Container>
      <Header
        name={data.spotlight.name}
        tabs={[
          {label:"Info.", icon:"notes", href:`${url}/info`},
          {label:"Talent", icon:"talent", href:`${url}/talent`},
        ]}
        style={{margin:"40px 0"}}
      />
      <Switch>
        <Route path={`${path}/info`} component={Info}/>
        <Route path={`${path}/talent`} component={Talents}/>
        <Redirect to={`${url}/talent`}/>
      </Switch>
    </Container>
  );
}