import React from 'react';
import { connect } from 'react-redux';
import { Card, Icon, Text, Rule } from 'skybolt-ui';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { updateAgency } from 'actions';
import { resumeSections } from 'config';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

const mapStateToProps = (state, ownProps) => {
  const agencyId = ownProps.agencyId ||
                   ownProps.match.params.agencyId;
  const sections = get(state, `agencies.all[${agencyId}].resumeSections`, resumeSections);
  return {
    agencyId,
    sections,
  };
};

const mapDispatchToProps = {
  updateAgency,
};

const DragHandle = SortableHandle(() => <Icon name="drag" color="#bdbdbd"/>);

const SortableList = SortableContainer((props) => <div>{props.children}</div>);

const SortableListItem = SortableElement(({children}) => {
  return (
    <Card style={{display:'flex', alignItems:'center', marginBottom:2}}>
      <div style={{flex:1, padding:"4px 16px"}}>
        <Text>{children}</Text>
      </div>
      <div style={{flex:0, paddingTop:2, cursor:'move', paddingRight:16}}>
        <DragHandle/>
      </div>
    </Card>
  );
});

const ResumeSectionsList = props => {
  const handleSort = ({oldIndex, newIndex}) => {
    const sections = [...props.sections];

    // Pull dragged section out of the sections array.
    let [movedSection] = sections.splice(oldIndex, 1);
    // Then add it back in at the new index.
    sections.splice(newIndex, 0, movedSection);

    if(props.agencyId && Array.isArray(sections)) {
      props.updateAgency(props.agencyId, {resumeSections:sections});
    }
  };

  return (
    <div>
      <div style={{paddingTop:24}}>
        <Text title>RESUME SECTIONS</Text>
        <Rule/>
      </div>
      <div style={{padding:"4px 0 0 0", height:56}}>
        <Text light>Drag to rearrange how experience is displayed on Talent resumes.</Text>
      </div>
      <SortableList
        axis="y"
        useWindowAsScrollContainer={true}
        useDragHandle={false}
        onSortEnd={handleSort}>

        {props.sections.map((section, i) =>
          <SortableListItem key={section} index={i}>
            {section}
          </SortableListItem>
        )}

      </SortableList>
    </div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResumeSectionsList)
);

