import React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { loadAgencyContacts } from 'actions';
import { isEmpty } from 'lodash';
import formatPhone from 'utils/formatPhone';

import {Link} from 'react-router-dom';
import Title, { Action } from 'ui/Title';
import Container from 'ui/Container';
import Loading from 'ui/Loading';
import Placeholder from 'ui/Placeholder';
import Card from 'ui/Card';
import Avatar from 'react-avatar';


export const styles = {
  container: {
    paddingBottom: 40,
  },
  contact: {
    display: 'flex',
    alignItems: 'center',
    marginBottom:4,
  },
  column: {
    width: '14%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box',
    padding: 8,
    textDecoration: 'none',
    color: '#424242',
  },
  wideColumn: {
    width: '28%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box',
    padding: "4px 8px",
    textDecoration: 'none',
    color: '#424242',
  },
  image: {
    marginRight: 8,
  },
  name: {
    fontSize: 16,
    lineHeight: '16px',
    color: "#424242",
  },
  email: {
    fontSize: 13,
    lineHeight: '16px',
    color: "#424242",
  },
  phone: {
    fontSize: 13,
    lineHeight: '16px',
    color: "#424242",
  },
  agency: {
    paddingRight: 8,
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: '16px',
  },
  title: {
    fontSize: 13,
    lineHeight: '16px',
    color: "#a6a6a6",
  },
  notes: {
    fontSize: 13,
    lineHeight: '13px',
    color: "#a6a6a6",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
};

export const mapStateToProps = (state, ownProps) => {
  const contacts = state.agencyContacts;
  
  return {
    isLoading: !contacts.searchCount && contacts.isSearching,
    isEmpty: isEmpty(contacts.searchResults),
    contacts: contacts.searchResults,
    search: contacts.search,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  loadAgencyContacts,
};

export class Contacts extends React.Component {
  
  componentDidMount() {
    this.props.loadAgencyContacts();
  }
  
  add = () => {
    if(!this.props.history) {
      console.warn('<Contacts/> must given a history prop.');
      return;
    }
    
    this.props.history.push({
      pathname:`/create-contact`,
      state:{modal:true}
    });
  }
  
  search = (query) => {
    this.props.loadAgencyContacts({search:{query}});
  }
  
  render() {
    const { classes, search } = this.props;
    
    return (
      <Container className={classes.container}>
        <Title
          actions={[
            <Action tooltip="Add Contact" icon="plus" onClick={this.add}/>,
          ]}
          search={search.query}
          onSearch={this.search}>
          Contacts
        </Title>
        {this.renderContent()}
      </Container>
    );
  }
  
  renderContent() {
    const { isLoading, isEmpty, contacts, classes } = this.props;
    
    if(isLoading) {
      return <Loading/>;
    }
    
    if(isEmpty) {
      return (
        <Placeholder 
          icon="contact"
          message="You don't have any contacts on Skybolt."
          label="Add a contact"
          onClick={this.add}
        />
      );
    }
    
    return Object.keys(contacts)
      .sort((a,b) => contacts[a].firstName < contacts[b].firstName ? -1 : 1) 
      .map(contactId => {
        const contact = contacts[contactId];
        
        const name = `${contact.firstName || ""} ${contact.lastName || ""}`.trim();
        
        let email = null;
        try {
          email = contact.emails[Object.keys(contact.emails)[0]].email;
        } catch(e) {}
        
        let phone = null;
        try {
          phone = contact.phones[Object.keys(contact.phones)[0]].number;
        } catch(e) {}
        
        return (
          <Card key={contactId} className={classes.contact}>
            <Link to={{pathname:`/contacts/${contactId}`, state:{modal:true}}} className={classes.image}>
              <Avatar 
                key={`${contactId}-avatar`}
                name={name} 
                email={email}
                size={40}
              />
            </Link>
            <Link to={{pathname:`/contacts/${contactId}`, state:{modal:true}}} className={classes.column}>
              <span className={classes.name}>{name}</span>
            </Link>
            <Link to={{pathname:`/contacts/${contactId}`, state:{modal:true}}} className={classes.column}>
              <span className={classes.email}>{email}</span>
            </Link>
            <Link to={{pathname:`/contacts/${contactId}`, state:{modal:true}}} className={classes.column}>
              <span className={classes.phone}>{formatPhone(phone)}</span>
            </Link>
            <Link to={{pathname:`/contacts/${contactId}`, state:{modal:true}}} className={classes.column}>
              <span className={classes.agency}>{contact.agency}</span>
              <span className={classes.title}>{contact.title}</span>
            </Link>
            <Link to={{pathname:`/contacts/${contactId}`, state:{modal:true}}} className={classes.wideColumn}>
              <span className={classes.notes}>{contact.notes}</span>
            </Link>
            <div className={classes.actions}>
            </div>
          </Card>
        );
      });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  injectSheet(styles)(
    Contacts
  )
);

