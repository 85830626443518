import { combineReducers } from "redux";

import user from './user';
import agencies from './agencies';
import agencyTalents from './agencyTalents';
import agencyContacts from './agencyContacts';
import contacts from './contacts';
import packs from './packs';
import talents from './talents';
import packTalents from './packTalents';
import events from './events';
import users from './users';
import messages from './messages';
import unreadMessages from './unreadMessages';
import messageReceipts from './messageReceipts';
import threads from './threads';
import media from './media';
import cannedMessages from './cannedMessages';
import invitations from './invitations';
import subscriptions from './subscriptions';
import transactions from './transactions';
import snackbar from './snackbar';
import faqs from './faqs';
import appTransactions from './appTransactions';



const activeCategoryId = (state=null, action) => {
  switch(action.type) {
    case 'SET_ACTIVE_CATEGORY':
      return action.categoryId || null;
    
    case 'LOGOUT':
      return null;
      
    default:
      return state;
  }
};

const version = (state=null, action) => {
  switch(action.type) {
    case 'SET_VERSION':
      return action.version || null;
    
    default:
      return state;
  }
};


export default combineReducers({
  activeCategoryId,
  snackbar,
  version,
  users,
  user,
  agencies,
  packs,
  talents,
  packTalents,
  events,
  messages,
  unreadMessages,
  messageReceipts,
  threads,
  media,
  cannedMessages,
  invitations,
  subscriptions,
  agencyTalents,
  agencyContacts,
  transactions,
  faqs,
  appTransactions,
  contacts,
});