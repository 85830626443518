import React, { useContext, useCallback, useState } from 'react';
import {
  Text,
  Button,
  Card,
  Form,
  InputText,
  InputNumber,
  InputDate,
  InputSelect,
  InputHTML,
  InputSwitch,
  useRouter,
  ThemeContext,
  Redirect,
} from 'skybolt-ui';
import { useQuery, updateCredit, createDebit, updateInvoice, updateDebit } from 'skybolt-api';
import InputTalent from './components/InputTalent';
import InputPackage from './components/InputPackage';
import InputJob from './components/InputJob';
import InputClient from './components/InputClient';
import { map, get, debounce, isEmpty } from 'lodash';
import "./index.css";
import firebase from 'firebase';
import 'firebase/firestore';


export default function(props) {

  // Load data and update data.

  const route = useRouter();
  const history = route.history;
  const creditId = get(route, 'match.params.creditId', null);
  const referrer = get(route, 'location.state.referrer', null);

  const data = useQuery(`{
    credit(creditId:$creditId) {
      creditId
      type
      comment
      commission
      dateCheck
      dateCycle
      dateDeposit
      dateMaxUse
      payType
      useType
      notes
      talentId
      talentName
      talentImage
      clientId
      clientName
      amount
      invoiceNumber
      invoiceId
      pendingDebits: debits(status:"PENDING", talentId:null, packId:null, clientId:null, dateFrom:null, dateTo:null) {
        debitId
      }
      invoice {
        status
      }
      packId
      packName
      pack {
        date
        client {
          clientId
          name
        }
      }
      amount
      debitSubtotal
      debitAgencyAmount
      debitAmount
      debitTaxAmount
      debitOtherAmount
      status
    }
    agency {
      clients
      useTypes
    }
    user {
      permissions {
        canAdminBilling
      }
    }
  }`, {creditId});

  const clientOptions = data ? map(data.agency.clients, c => ({value:c.clientId, label:c.name})) : [];

  const debouncedUpdate = useCallback(debounce(data => {
    // todo: if changed from timecard to invoice, remove bad fields
    let { pendingDebits, ...update } = data;
    updateCredit({creditId, update});
  }, 1000), [creditId]);
  const handleChange = update => debouncedUpdate(update);



  // Commit changes with all side affects.

  const [errors, setErrors] = useState({});
  const [createPayments, setCreatePayments] = useState(true);
  
  const commitChange = async () => {

    // Do some error checking
    let err = {};
    if(data.credit.type === "TIMECARD" && !data.credit.talentId) {
      err.talent = "Please choose a talent.";
    }

    if(data.credit.type === "TIMECARD" && !data.credit.packId) {
      err.pack = "Please choose a package.";
    }

    if(data.credit.type === "INVOICE" && !data.credit.amount) {
      err.amount = "Please enter an amount.";
    }

    if(data.credit.type === "INVOICE" && !data.credit.invoiceNumber) {
      err.invoiceNumber = "An invoice number is required.";
    }

    if(data.credit.type === "INVOICE" && data.credit.invoice.status == "PAID") {
      err.invoiceNumber = "This invoice has already been paid.";
    }

    setErrors(err);
    if(!isEmpty(err)) {
      return false;
    }

    if(data.credit.type === "TIMECARD") {
      const debit = await createDebit({
        is1099: false,
        talentId: data.credit.talentId,
        talentName: data.credit.talentName,
        talentImage: data.credit.talentImage,
        packId: data.credit.packId,
        packName: data.credit.packName,
        clientId: data.credit.pack.client.clientId,
        clientName: data.credit.pack.client.name,
        dateJob: data.credit.pack.date,
        dateCheck: data.credit.dateCheck,
        dateCycle: data.credit.dateCycle,
        useType: data.credit.useType,
        payType: data.credit.payType,
        grossAmount: data.credit.amount,
        taxAmount: data.credit.debitTaxAmount,
        otherAmount: data.credit.debitOtherAmount,
        agencyAmount: data.credit.debitAgencyAmount,
        amount: data.credit.debitAmount,
        comment: data.credit.comment,
      });

      await updateCredit({creditId, update:{debitId:debit.debitId}});
    }

    if(data.credit.type === "INVOICE" && data.credit.invoiceId) {
      await updateInvoice({invoiceId:data.credit.invoiceId, update:{status:"PAID", datePaid:Date.now()}});
    }
    if(data.credit.type === "INVOICE" && Array.isArray(data.credit.pendingDebits)) {
      for(let debit of data.credit.pendingDebits) {
        await updateDebit({debitId:debit.debitId, update:{status:"DRAFT"}});
      }
    }

    await updateCredit({creditId, update:{status:"SAVED"}});

    return true;
  };

  const save = async () => {
    const redirect = await commitChange();
    if(redirect) {
      history.push(referrer || '../list');
    }
  };

  const deleteCredit = async () => {
    await updateCredit({creditId, update:{status:"DELETED"}});
    history.push(referrer || '../list');
  };

  const saveAndAddAnother = async () => {
    const redirect = await commitChange();
    if(redirect) {
      history.push({
        pathname: "/billing/accounts-receivable/create",
        state: {
          initialData: {
            type: data.credit.type,
            talentId: data.credit.talentId,
            talentName: data.credit.talentName,
            talentImage: data.credit.talentImage,
            packId: data.credit.packId,
            packName: data.credit.packName,
          },
          referrer,
        }
      });
    }
  };



  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return null;
  }

  if(data.credit.status === "SAVED" && !data.user.permissions.canAdminBilling) {
    return <Redirect to="view"/>;
  }

  return (
    <div className="billingcreditedit">
      <Form value={data.credit} onChange={handleChange}>

        <div className="billingcreditedit__type">
          <Text style={{marginRight:8}}>Payment for:</Text>
          <Button
            label="Invoice"
            icon="file-invoice-dollar"
            onClick={() => updateCredit({creditId, update:{type:"INVOICE"}})}
            style={{
              borderRadius: 0,
              borderBottomStyle: "solid",
              borderBottomWidth: 2,
              borderBottomColor: data.credit.type === 'INVOICE' ? theme.color.accent : "transparent",
            }}
          />
          <Button
            label="Timecard"
            icon="stopwatch"
            onClick={() => updateCredit({creditId, update:{type:"TIMECARD"}})}
            style={{
              borderRadius: 0,
              borderBottomStyle: "solid",
              borderBottomWidth: 2,
              borderBottomColor: data.credit.type === 'TIMECARD' ? theme.color.accent : "transparent",
            }}
          />
        </div>

        <Card style={{padding:16, marginBottom:40}}>

          <div className="billingcreditedit__info">
            <div>

              {data.credit.type === "INVOICE" &&
                <div className="billingcreditedit__section">
                  <InputNumber name="invoiceNumber" title="Invoice Number" error={errors.invoiceNumber}/>
                  <InputPackage title="Package" error={errors.pack}/>
                  <InputSelect name="clientId" strict options={clientOptions} title="Client"/>
                </div>
              }

              {data.credit.type === "TIMECARD" &&
                <div className="billingcreditedit__section">
                  <InputTalent title="Talent" error={errors.talent}/>
                  <InputJob title="Package" talentId={data.credit.talentId} error={errors.pack}/>
                  <InputClient title="Client"/>
                  <InputNumber name="commission" title="Commission %"/>
                  <InputDate name="dateMaxUse" title="Max Use Date"/>
                </div>
              }

              {data.credit.type === "INVOICE" &&
                <div className="billingcreditedit__section">
                  <InputText name="checkNumber" title="Check Number"/>
                  <InputText name="referenceNumber" title="Reference Number"/>
                  <InputDate name="dateCheck" title="Check Date"/>
                  <InputDate name="dateDeposit" title="Deposit Date"/>
                </div>
              }

              {data.credit.type === "INVOICE" &&
                <div className="billingcreditedit__section">
                  <InputNumber name="amount" title="Amount" error={errors.amount}/>
                </div>
              }

              {data.credit.type === "TIMECARD" &&
                <div className="billingcreditedit__section">
                  <InputDate title="Pay Date" name="dateCheck" hint={false} placeholder="MM/DD/YYYY"/>
                  <InputDate title="Cycle Date" name="dateCycle" hint={false} placeholder="MM/DD/YYYY"/>
                  <InputSelect
                    title="Pay Type"
                    options={[
                      "On Camera",
                      "Print", 
                      "Voice Over"
                    ]}
                    strict
                    name="payType"
                  />
                  <InputSelect
                    title="Use Type"
                    options={data?.agency.useTypes?.length ? data.agency.useTypes : ["Agency Fee", "Buyout", "CABLE", "CABLE UPGRD", "Class A Network", "Commercial", "Commercial Only", "Film", "Foreign Use", "HOLDING FEE", "Industrial", "Industrial Use", "Internet Use", "Lift", "Live Event", "Non U Comm", "Non U Film", "Non U Indust.", "Non U TV", "Non U Voice Over", "Print", "Reuse", "Session Fee", "Trade Show", "TV", "Voice Over", "Wardrobe", "Wildspot"]}
                    strict
                    name="useType"
                  />
                  <InputNumber title="Gross" name="amount"/>
                  <InputNumber title="Tax" name="debitTaxAmount"/>
                  <InputNumber title="Other" name="debitOtherAmount"/>
                  <div className="billingcreditedit__field">
                    <div className="billingcreditedit__label">Subtotal</div>
                    <div className="billingcreditedit__value">{data.credit.debitSubtotal}</div>
                  </div>
                  <InputNumber title="Agency" name="debitAgencyAmount"/>
                  <InputNumber title="Net" name="debitAmount"/>
                </div>
              }

              {data.credit.type === "TIMECARD" &&
                <div className="billingcreditedit__comment">
                  <InputHTML name="comment" title="Comment" hint="Shown on talent payments."/>
                </div>
              } 
            </div>

            <div className="billingcreditedit__notes">
              <InputHTML name="notes" title="Notes" hint="Only visible to agents"/>
            </div>
          </div>
          
          {/*data.user.permissions.canAdminBilling && 
            <div style={{maxWidth:400}}>
              <InputNumber title="Debits Subtotal" name="debitSubtotal"/>
              <InputNumber title="Owed to Talent" name="debitAmount"/>
              <InputNumber title="Agency Total" name="debitAgencyAmount"/>
            </div>
          */}

          <div>
            <Button raised accent label={"Save"} onClick={save}/>
            <Button accent label="Save and Add Another" onClick={saveAndAddAnother}/>
            <Button label="Cancel" onClick={deleteCredit}/>
          </div>
        </Card>

      </Form>

    </div>
  );
}