import React from 'react';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { loadContact, updateContact, removeContact } from 'actions';
import { get } from 'lodash';
import formatPhone from 'utils/formatPhone';

import Header, { Action } from 'ui/Header';
import Loading from 'ui/Loading';
import Avatar from 'react-avatar';
import ContactForm from 'ui/ContactForm';
import Icon from 'ui/Icon';
import Dialog from 'ui/Dialog';
import Button from 'ui/Button';

const styles = {
  wrapper: {
    width: '100%',
  },
  field: {
    display: "flex",
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 16,
  },
  fieldColumn: {
    marginRight: 16,
  },
  fieldLabel: {
    fontSize: 12,
    color: "#9e9e9e",
  }
};

export const mapStateToProps = (state, ownProps) => {
  const id = ownProps.contactId || ownProps.match.params.contactId;
  const contact = get(state, `contacts.all[${id}]`, null);
  
  return {
    isLoading: !contact || contact.isLoading,
    id,
    contact,
    
    path: ownProps.match.path,
    url: ownProps.match.url,
    location: ownProps.match.location,
    
    ...ownProps,
  };
};

const mapDispatchToProps = {
  loadContact,
  updateContact,
  removeContact,
};

export class Contact extends React.Component {
  
  static defaultProps = {
    onRemove: ()=>null,
  }
  
  state = {
    isEditing: false,
  }
  
  componentDidMount() {
    if(!this.props.id) {
      if(this.props.onClose) {
        this.props.onClose();
      }
      return;
    }
    this.props.loadContact(this.props.id);
  }
  
  toggleEdit = () => {
    this.setState({isEditing: !this.state.isEditing});
  }
  
  update = contact => {
    this.props.updateContact(this.props.id, contact);
  }
  
  remove = () => {
    this.props.onRemove(this.props.id);
    this.props.removeContact(this.props.id);
  }
  
  render() {
    
    if(this.props.isLoading) {
      return <Loading style={{padding:40}}/>;
    }
    
    const { contact, classes } = this.props;
    
    let name = `${contact.firstName || ""} ${contact.lastName || ""}`.trim();
    if(!name && contact.emails) {
      try {
        name = contact.emails[Object.keys(contact.emails)[0]].email;
      } catch(e) {}
    }
    return (
      <div className={classes.wrapper}>
        <Header 
          title={name}
          image={<Avatar name={`${contact.firstName} ${contact.lastName}`} size={120}/>}
          actions={[
            <Action icon="edit" label="Edit" onClick={this.toggleEdit}/>,
            <Action icon="remove" label="Remove" onClick={() => this.setState({isRemoveDialogOpen:true})}/>,
          ]}
        />
        {this.state.isEditing &&
          <ContactForm 
            contact={contact}
            onChange={this.update}
            style={{padding:16}}
          />
        }
        {!this.state.isEditing && 
          <div style={{padding:16}}>
            {(contact.firstName || contact.lastName) && 
              <div className={classes.field}>
                <Icon className={classes.fieldColumn} name="contact" style={{marginTop:3, width:18, height:18, color:"#9e9e9e"}}/>
                {contact.firstName && 
                  <div className={classes.fieldColumn}>
                    <div className={classes.fieldLabel}>First</div>
                    <div>{contact.firstName}</div>
                  </div>
                }
                {contact.lastName && 
                  <div className={classes.fieldColumn}>
                    <div className={classes.fieldLabel}>Last</div>
                    <div>{contact.lastName}</div>
                  </div>
                }
              </div>
            }
            
            {contact.company && 
              <div className={classes.field}>
                <Icon className={classes.fieldColumn} name="company" style={{marginTop:3, width:18, height:18, color:"#9e9e9e"}}/>
                <div className={classes.fieldColumn}>
                  <div>{contact.title}</div>
                  <div className={classes.fieldLabel}>{contact.company}</div>
                </div>
              </div>
            }
            
            {contact.emails && 
              <div className={classes.field}>
                <Icon className={classes.fieldColumn} name="email" style={{marginTop:3, width:18, height:18, color:"#9e9e9e"}}/>
                <div className={classes.fieldColumn}>
                  {Object.keys(contact.emails || {})
                    .map(key => ({key, ...contact.emails[key]}))
                    .map(email => 
                      <div key={email.key} style={{marginBottom:8}}>
                        <div className={classes.fieldLabel}>{email.label}</div>
                        <div>{email.email}</div>
                      </div>
                    )
                  }
                </div>
              </div>
            }
            
            {contact.phones && 
              <div className={classes.field}>
                <Icon className={classes.fieldColumn} name="phone" style={{marginTop:3, width:18, height:18, color:"#9e9e9e"}}/>
                <div className={classes.fieldColumn}>
                  {Object.keys(contact.phones || {})
                    .map(key => ({key, ...contact.phones[key]}))
                    .map(phone => 
                      <div key={phone.key} style={{marginBottom:8}}>
                        <div className={classes.fieldLabel}>{phone.label}</div>
                        <div>{formatPhone(phone.number)}</div>
                      </div>
                    )
                  }
                </div>
              </div>
            }
            
            {contact.notes && 
              <div className={classes.field}>
                <Icon className={classes.fieldColumn} name="notes" style={{marginTop:3, width:18, height:18, color:"#9e9e9e"}}/>
                <div className={classes.fieldColumn}>
                  {contact.notes}
                </div>
              </div>
            }
            
            <Dialog 
              open={!!this.state.isRemoveDialogOpen}
              onRequestClose={() => this.setState({isRemoveDialogOpen:false})}
              contentStyle={{maxWidth:500}}
              actions={[
                <Button label="Nevermind" onClick={() => this.setState({isRemoveDialogOpen:false})} style={{marginRight:8}}/>,
                <Button raised={true} primary={true} label="Yes" onClick={this.remove}/>
              ]}>
              
              Are you sure you want to remove this contact?
              
            </Dialog>
            
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  injectSheet(styles)(
    Contact
  )
);

