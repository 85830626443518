import React, { Component } from 'react';
import { isFunction } from 'lodash';

import './index.css';


export default class Container extends Component {
  state = {
    width: '',
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = e => {
    if(isFunction(this.props.width)) {
      this.setState({width: this.props.width()});
    }
    else if(!!this.props.width && this.props.width !== this.state.width) {
      this.setState({width: this.props.width});
    }
  }

  render() {
    const {
      className="",
      style={},
      children=[],
      width,
      ...props
    } = this.props;

    return (
      <div
        className={`sui-container ${className}`}
        style={{width:this.state.width, ...style}}
        {...props}>
        {children}
      </div>
    );
  }
}