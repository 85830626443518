import React, { Component } from 'react';
import { connect } from 'react-redux';

import Title, { Action } from 'ui/Title';
import Placeholder from 'ui/Placeholder';
import Display from './Display';
import Form from './Form';


import './index.css';


export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const isLoggedIn = state.user.isLoggedIn;
  const userId = state.user.id;
  const user = state.users.all[userId] || {permissions:{}};
  const talent = state.talents.all[talentId] || {};
  const isOwnTalent = isLoggedIn && !!user && !!user.talents && !!user.talents[talentId];
  const canEdit = isOwnTalent || (isLoggedIn && talent.agencyId === user.agencyId && user.permissions.canEditTalents) || (isLoggedIn && user.permissions && user.permissions.canAdminTalents);

  return {
    talentId,
    talent,
    isLoggedIn,
    canEdit,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
};




export class TalentInfo extends Component {

  state = {
    isEditing: false,
  }

  renderActions() {
    if(!this.props.canEdit) {
      return [];
    }

    const actions = [];

    if(this.state.isEditing) {
      actions.push(
        <Action key="edit" icon="close" label="Edit" onClick={() => this.setState({isEditing:false})}/>
      );
    }
    else {
      actions.push(
        <Action key="edit" icon="edit" label="Edit" onClick={() => this.setState({isEditing:true})}/>
      );
    }

    return actions;
  }

  renderContent() {
    let talentId = this.props.talentId;

    if(this.state.isEditing) {
      return <Form talentId={talentId}/>;
    }

    if(!this.props.talent.dateUpdated) {

      if(!this.props.isOwnTalent) {
        return (
          <Placeholder
            message="No talent information."
            icon="talent"
          />
        );
      }

      return (
        <Placeholder
          message="Welcome to your new talent profile. Get started by editing your information."
          icon="talent"
          label="Edit Talent"
          onClick={this.props.canEdit ? () => this.setState({isEditing:true}) : null}
        />
      );
    }

    return <Display talentId={talentId}/>;
  }

  render() {
    return (
      <div>

        <Title
          actions={this.renderActions()}>
          Info.
        </Title>

        {this.renderContent()}

      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(TalentInfo);