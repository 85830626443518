import React, { Component } from 'react';
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import moment from 'moment';
import { get } from 'lodash';
import {
  loadPack,
  loadPackEvents,
  updateEvent,
  addPackEvent,
} from 'actions';

import "./index.css";

import Button from 'ui/Button';
import Title from 'ui/Title';
import Subtitle from 'ui/Subtitle';






const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.talentId || ownProps.match.params.talentId;
  const packId = ownProps.packId;
  const canPack = get(state, 'user.permissions.canPack', false);

  return {
    ...ownProps,
    canPack,
    talentId,
    packId,
    pack: state.packs.all[packId],
    events: state.events.byPack[packId],
  };
};

const mapDispatchToProps = {
  loadPack,
  loadPackEvents,
  updateEvent,
  addPackEvent,
};






class Schedule extends Component {

  static defaultProps = {
    events: {},
    onSelectTime: ()=>null,
  }

  UNSAFE_componentWillMount() {
    if(!this.props.packId) {
      return;
    }

    this.props.loadPack(this.props.packId);
    this.props.loadPackEvents(this.props.packId);
  }

  render() {
    const pack = this.props.pack;
    if(!pack || !pack.schedule) {
      // todo: loader?
      return null;
    }

    const {
      canPack,
      packId,
      talentId,
      events,
      updateEvent,
      addPackEvent,
      onSelectTime,
      muiTheme: { palette },
    } = this.props;

    const {
      timeslots,
      interval=60,
    } = pack.schedule;

    const talentEvents = Object.values(events)
      .filter(event => event.talentId === talentId);

    const days = Object.keys(timeslots)
      .map(id => {
        const timeslot = timeslots[id];
        let { from, to, type } = timeslot;

        from = moment(from);
        to = moment(to);

        const times = [];
        let timeFrom = from.valueOf();
        let timeTo = to.valueOf();
        let d = timeFrom;
        while(d < timeTo) {
          const time = moment(d);
          const filled = !!Object.keys(events || {}).find(id => events[id].talentId !== talentId && events[id].date == d);
          if(!filled || canPack) times.push( time );
          d += interval*60*1000;
        }

        return {
          timeslotId: id,
          date:from,
          times,
          type,
        };
      })
      .filter(day => day.times.length > 0);

    return (
      <div className="talent-schedule-schedule">

        <Title>Schedule</Title>

        <div>Select a time from the following available openings.</div>

        {days.map(day =>
          <div key={day.date.valueOf()} style={{margin:"32px 0"}}>
            <Subtitle>{day.type} - {day.date.format("dddd, MMMM Do")}</Subtitle>
            <div className="talent-schedule-schedule-times">
              {day.times.map(time => (
                <Button
                  raised={true}
                  key={time.valueOf()}
                  className="talent-schedule-schedule-time"
                  backgroundColor={
                    !!talentEvents.find(e => e.timeslotId == day.timeslotId && e.date == time.valueOf()) ? palette.accent700 : "#fafafa"
                    // !!event && time.valueOf() === event.date ? palette.accent700 : '#fafafa'
                  }
                  onClick={() => {
                    let event = talentEvents.find(e => e.timeslotId == day.timeslotId);
                    if(event) {
                      updateEvent(event.id, {status:"pending", date:time.valueOf()});
                    }
                    else {
                      addPackEvent(packId, talentId, time.valueOf(), day.timeslotId, day.type);
                    }
                    onSelectTime(time.valueOf());
                  }}
                  label={time.format('h:mma')}
                />
              ))}
            </div>

            {talentEvents.find(e => e.timeslotId == day.timeslotId && e.status == 'pending') && (
              <div style={{display:'flex'}}>
                <Button
                  raised={true} primary={true} label="Confirm" style={{marginRight:8}}
                  onClick={() => updateEvent(talentEvents.find(e => e.timeslotId == day.timeslotId && e.status == 'pending').id, {status:'confirmed'})}
                />
                <Button
                  raised={true} primary={false} label="Decline" style={{marginRight:8}}
                  onClick={() => updateEvent(talentEvents.find(e => e.timeslotId == day.timeslotId && e.status == 'pending').id, {status:'declined'})}
                />
              </div>
            )}

            {talentEvents.find(e => e.timeslotId == day.timeslotId && e.status != 'pending') && (
              <div className="eventli2-status" style={{color:palette.accent700}}>
                {talentEvents.find(e => e.timeslotId == day.timeslotId && e.status != 'pending').status.toUpperCase()}
              </div>
            )}

          </div>
        )}

        

      </div>
    );
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(
  muiThemeable()(Schedule)
);