import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  InputText,
  FormContext,
  Button,
  ThemeContext,
  Menu,
} from 'skybolt-ui';
import algoliasearch from 'algoliasearch';

import { algoliaConfig } from 'config';

const algoliaClient = algoliasearch(algoliaConfig.applicationId, algoliaConfig.apiKey);
const usersIndex = algoliaClient.initIndex('users');


// With a pack id supplied by the containing form, show the pack fullName
// and update the form when the input is changed.
export default function InputAgent(props) {
  const form = useContext(FormContext);
  const agentId = form.get('agentId');

  const inputRef = useRef();
  const [focused, setFocused] = useState(false);

  const [options, setOptions] = useState([]);
  const [name, setName] = useState("");

  const emitChange = agent => {
    let id = agent.objectID;
    
    form.update({
      agentId: id
    });
    if(props.onChange) {
      props.onChange(id);
    }
  };

  function clear(value) {
    if(!value) {
      form.update({
        agentId: null
      });
      if(props.onChange) {
        props.onChange(null);
      }
    }
  }

  useEffect(() => {
    usersIndex
      .search({
        query: "",
        filters: `role:agent AND agencyId:${props.agencyId}`,
        length: 50,
        offset: 0
      })
      .then(({hits}) => {
        setOptions(hits);
      });

  }, []);

  useEffect(() => {
    if(options.length && agentId) {
      let agent = options.find(agent => agent.objectID === agentId);
      setName(agent.firstName + " " + agent.lastName);
    }
    else {
      setName("");
    }
  }, [options, agentId]);

  const theme = useContext(ThemeContext);

  const containerRef = useRef();

  return (
    <div style={{display:'flex', alignItems:'flex-start', justifyContent:'stretch', marginBottom:16, ...props.style}} ref={containerRef} className={props.className}>
      <InputText 
        title="Agent" 
        style={{flex:1, marginBottom:0}}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        ref={inputRef}
        onChange={clear}
        value={name}
      />
      <Menu key="menu" open={focused} anchor={containerRef} onRequestClose={() => inputRef.current.blur()}>
        {options && options.map((agent, i) =>
          <Button
            key={i}
            style={{
              display: 'block',
              minWidth: 80,
              backgroundColor: agent.id === agentId ? theme.color.accent : 'transparent',
              borderRadius: 0,
            }}
            onMouseDown={e => e.preventDefault()}
            onClick={e => emitChange(agent)}>
            {agent.firstName} {agent.lastName}
          </Button>
        )}
      </Menu>
    </div>
  );

}