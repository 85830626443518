/*

Use skybolt-ui <Modal/> instead.
This component maps from old <Dialog> components.

*/

import React from 'react';
import { Modal, Text } from 'skybolt-ui';


export default props => {
  
  const style = {
    padding: 16,
    ...props.modalStyle,
    ...props.contentStyle,
    ...props.bodyStyle,
  };
  
  return (
    <Modal
      modalStyle={style}
      status={props.open}
      onRequestClose={() => {
        props.onRequestClose();
      }}
      >
      
      {props.title && 
        <Text large>{props.title}</Text>
      }
      {props.children}
      {props.actions && (
        <div style={{paddingTop:16, display:'flex', justifyContent:'flex-end'}}>
          {props.actions}
        </div>
      )}
      
      
    </Modal>
  );
};

// import React, { Component } from 'react';
// import MaterialDialog from 'material-ui/Dialog';
// import Button from 'ui/Button';


// export default class Dialog extends Component {
  
//   render() {
//     const {
//       children=[], 
//       contentStyle={},
//       bodyStyle={},
//       actionsContainerStyle={
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'flex-end',
//       },
//       actions=[],
//       ...props
//     } = this.props;
    
//     const isMobile = window.innerWidth < 600;
    
//     let baseContentStyle = {
//       position: 'relative',
//       marginBottom: 40,
//     };
    
//     let baseBodyStyle = {
//       WebkitOverflowScrolling:'touch',
//       overflowX: 'hidden',
//       backgroundColor: 'white',
//     };
    
    
//     if(isMobile) {
//       baseContentStyle = {
//         ...baseContentStyle,
//         width: '96%',
//         marginBottom: 0,
//       };
//     }
    
//     return (
//       <MaterialDialog 
//         style={{overflowY:'auto'}}
//         contentStyle={{...baseContentStyle, ...contentStyle}}
//         bodyStyle={{...baseBodyStyle, ...bodyStyle}}
//         actionsContainerStyle={actionsContainerStyle}
//         autoDetectWindowHeight={isMobile}
//         autoScrollBodyContent={isMobile}
        
//         actions={actions}
        
//         {...props}>
        
//         {children}
        
//         {props.modal !== false &&
//           <Button 
//             icon="close" 
//             onClick={props.onRequestClose}
//             style={{
//               position: 'absolute',
//               top: 2, right: 2,
//               backgroundColor: bodyStyle.backgroundColor,
//               zIndex: 100000,
//             }}
//           />
//         }
        
//       </MaterialDialog>
//     );
//   }
// }