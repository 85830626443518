import React, { useContext, useState, useRef } from 'react';
import { useQuery, query } from 'skybolt-api';
import {
  useRouter,
  Loading,
  Container,
  Text,
  InputDate,
  InputSelect,
  InputNumber,
  Button,
  Rule,
  Icon,
  Card,
  Currency,
  ThemeContext,
  Modal,
} from 'skybolt-ui';
import moment from 'moment-timezone';
import InputTalent from './InputTalent.js';
import download from 'downloadjs';


export default function ChecksList(props) {



  // Manage Data

  const router = useRouter();
  const { state={}, pathname } = router.location;
  const {
    orderBy="date",
    orderDirection="desc",
    type=null,
    filterByStatus=null,
    filterByTalent=null,
    filterByDateFrom=null,
    filterByDateTo=null,
    limit=50,
  } = state;

  const data = useQuery(`{
    agency {
      agencyId
      useBilling
      billingOrgId
      timezone
    }
    checks(orderBy:$orderBy, orderDirection:$orderDirection, talentId:$filterByTalent, dateTo:$filterByDateTo, dateFrom:$filterByDateFrom, is1099:$is1099, limit:$limit, status:$filterByStatus) {
      checkId
      is1099
      number
      date
      status
      talentId
      talentName
      talentImage
      debitIds
      amount
    }
    filterByTalent: talent(talentId:$filterByTalent) {
      fullName
    }
    user {
      permissions {
        canAgencyBilling
        canAgencyBillingReports
      }
    }
  }`, {
    orderBy,
    orderDirection,
    filterByTalent,
    filterByDateFrom,
    filterByDateTo,
    is1099: type === 'Invoice' ? true :
            type === 'Timecard' ? false :
            null,
    filterByStatus,
    limit: filterByDateFrom ? 10000 : limit,
  });

  let timezone = data?.agency?.timezone || moment.tz.guess();

  const setQuery = (update) => props.history.replace(pathname, {...state, ...update});

  function setOrderBy(val) {
    if(orderBy === val) {
      if(orderDirection === "desc") {
        setQuery({orderDirection:"asc"});
      }
      else {
        setQuery({orderDirection:"desc"});
      }
    }
    else {
      setQuery({orderBy:val, orderDirection:"desc"});
    }
  } 

  let amountsTotal = data && data.checks
    .filter(check => {
      return check.status != "VOID" && check.status !== "ERROR" && check.status !== "CANCELED";
    })
    .reduce((t, c) => t + (Number(c.amount) || 0), 0);;

  let amountsPending = data && data.checks
    .filter(check => check.status == "OPEN" || check.status == "APPROVING" || check.status == "SCHEDULED")
    .reduce((t, c) => t + (Number(c.amount) || 0), 0);

  function downloadCSV() {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    let title = "Checks ";
    let name = "Checks ";
    let description = "";

    if(filterByDateFrom) {
      name += moment(filterByDateFrom, 'x').tz(timezone).format("MM/DD/YYYY") + " ";
      description += moment(filterByDateFrom, 'x').tz(timezone).format("MM/DD/YYYY") + " ";
      if(filterByDateTo) {
        name += "to ";
        description += "to ";
      }
    }
    if(filterByDateTo) {
      if(!filterByDateFrom) {
        name += "To ";
        description += "To ";
      }
      name += moment(filterByDateTo, 'x').tz(timezone).format("MM/DD/YYYY") + " ";
      description += moment(filterByDateTo, 'x').tz(timezone).format("MM/DD/YYYY") + " ";
    }
    if(filterByTalent) {
      name = `${data.filterByTalent.fullName} ${name}`;
      description = `${data.filterByTalent.fullName} ${description}`;
    }

    name = name.trim();
    description = description.trim();


    let csv = `${title}\n${description}\n`;
    csv += "Check Date,Check Number,Talent,Type,Amount\n";

    csv += data.checks
      .reduce((rows, check) => {
        if(check.status === 'VOID') {
          return rows;
        }
        let cols = [];
        cols.push(moment(check.date).tz(timezone).format("MM/DD/YYYY"));
        cols.push(check.number || "");
        cols.push(check.talentName || "");
        cols.push(check.is1099 ? "Invoice" : "Timecard");
        cols.push(`"${currencyFormatter.format(check.amount)}"`);
        return [...rows, cols.join(',')];
      }, [])
      .join('\n');
    csv += '\n';
    
    csv += `,,,Total,"${currencyFormatter.format(amountsTotal)}"\n`;

    download(csv, `${name}.csv`, 'text/csv');

  }


  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return (
      <div style={{width:"100%"}}>
        <Container>
          <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
            <Text title>ACCOUNTS PAYABLE</Text>
            <div style={{flex:1}}/>
            <Button title icon="plus" href={{pathname:"/billing/debits/create", state:{referrer:pathname}}}/>
          </div>
          <Rule/>
          <div style={{marginBottom:24}}>
            <Button label="Pending" href="pending" style={{borderRadius:0, backgroundColor:theme.color.greyLight}}/>
            <Button label="Scheduled" href="scheduled" style={{borderRadius:0, backgroundColor:theme.color.greyLight}}/>
            <Button label="Sent" style={{borderRadius:0, backgroundColor:theme.color.primaryLighter}}/>
          </div>
        </Container>
        <Loading style={{paddingTop:40}}/>
      </div>
    );
  }

  return (
    <div style={{width:"100%"}}>



      {/* Title */}

      <Container>
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>ACCOUNTS PAYABLE</Text>
          <div style={{flex:1}}/>
          {data.user.permissions.canAgencyBillingReports &&
            <InputDate
              placeholder="From..."
              value={filterByDateFrom}
              onChange={val => setQuery({filterByDateFrom:val})}
              onRule={true}
              style={{width:120}}
              tailStyle={{paddingTop:18, paddingBottom:8}}
              clearable
            />
          }
          {data.user.permissions.canAgencyBillingReports &&
            <InputDate
              placeholder="To..."
              value={filterByDateTo}
              onChange={val => setQuery({filterByDateTo:val})}
              onRule={true}
              style={{width:120}}
              tailStyle={{paddingTop:18, paddingBottom:8}}
              clearable
            />
          }
          <InputTalent
            value={filterByTalent}
            onChange={val => setQuery({filterByTalent:val})}
          />
          <InputSelect
            onRule
            placeholder="Type..."
            clearable="All"
            value={type}
            strict options={["Invoice", "Timecard"]}
            onChange={type => setQuery({type})}
          />
          <InputSelect
            onRule
            placeholder="Status..."
            clearable="All"
            value={filterByStatus}
            strict options={["ERROR", "SCHEDULED", "SENT"]}
            onChange={filterByStatus => setQuery({filterByStatus})}
          />
          <Button title icon="plus" href={{pathname:"/billing/debits/create", state:{referrer:pathname}}}/>
          {data.user.permissions.canAgencyBillingReports &&
            <Button
              onClick={downloadCSV}
              style={{
                background:theme.color.grey,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40,
                height: 40,
                padding: 0,
                borderRadius: 0,
                marginLeft: 2,
              }}>
              <Icon name="file-download" color="white"/>
            </Button>
          }
        </div>
        <Rule/>
        <div style={{marginBottom:24}}>
          <Button label="Pending" href="pending" style={{borderRadius:0, backgroundColor:theme.color.greyLight}}/>
          <Button label="Scheduled" href="scheduled" style={{borderRadius:0, backgroundColor:theme.color.greyLight}}/>
          <Button label="Sent" style={{borderRadius:0, backgroundColor:theme.color.primaryLighter}}/>
        </div>
      </Container>




      <div style={{width:'100%', overflowX:'auto'}}>
        <Container style={{minWidth:900}}>


          {/* Table Heading */}

          <div style={{display:'flex', alignItems:'center'}}>
            <Button onClick={() => setOrderBy('number')} block style={{width:150, padding:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
              <Text small style={{lineHeight:'32px', display:'inline-block', marginRight:8, paddingLeft:8}} color={orderBy === 'number' ? theme.color.accent : theme.color.textLight}>
                Check Number
              </Text>
              <Icon
                color={orderBy === 'number' ? theme.color.accent : theme.color.textLighter}
                name={orderBy === 'number' && orderDirection === 'asc' ? "caret-up" : "caret-down"}
                small
                style={{marginTop:-1}}
              />
            </Button>
            <Button onClick={() => setOrderBy('date')} block style={{width:150, padding:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
              <Text small style={{lineHeight:'32px', display:'inline-block', marginRight:8, paddingLeft:8}} color={orderBy === 'date' ? theme.color.accent : theme.color.textLight}>
                Date
              </Text>
              <Icon
                color={orderBy === 'date' ? theme.color.accent : theme.color.textLighter}
                name={orderBy === 'date' && orderDirection === 'asc' ? "caret-up" : "caret-down"}
                small
                style={{marginTop:-1}}
              />
            </Button>
            <div style={{flex:1}}>
              <Text small light style={{lineHeight:'32px'}}>Talent</Text>
            </div>
            <div style={{width:150}}>
              <Text small light style={{lineHeight:'32px'}}>Status</Text>
            </div>
            <div style={{width:150}}>
              <Text small light style={{lineHeight:'32px'}}>Type</Text>
            </div>
            <div style={{width:150}}>
              <Text small light style={{lineHeight:'32px'}}>Payments</Text>
            </div>
            <div style={{width:150}}>
              <Text small light style={{lineHeight:'32px'}}>Amount</Text>
            </div>
          </div>




          {/* Checks */}

          {data.checks.map(check =>
            <div key={check.checkId}>

              <Card style={{flex:1, marginBottom:4}}>
                <Button style={{display:'flex', alignItems:'center', height:40, padding:0}} href={check.checkId}>
                  <div style={{width:150}}>
                    <Text style={{paddingLeft:8}}>{check.number}</Text>
                  </div>
                  <div style={{width:150}}>
                    <Text style={{paddingLeft:8}}>{moment(check.date).tz(timezone).format("MM/DD/YYYY")}</Text>
                  </div>
                  <div style={{flex:1, display:'flex', alignItems:'center'}}>
                    {check.talentImage &&
                      <img src={check.talentImage} alt="headshot" style={{width:40, height:40, marginRight:8}}/>
                    }
                    <Text>{check.talentName}</Text>
                  </div>
                  <div style={{width:150}}>
                    <Text bold color={check.status === "ERROR" ? "error" : "text"}>
                      {check.status}
                    </Text>
                  </div>
                  <div style={{width:150}}>
                    <Text>{check.is1099 ? "Invoice" : "Timecard"}</Text>
                  </div>
                  <div style={{width:150}}>
                    <Text>{check.debitIds.length} {check.debitIds.length === 1 ? "payment" : "payments"}</Text>
                  </div>
                  <div style={{width:150}}>
                    <Currency bold>{check.amount}</Currency>
                  </div>
                </Button>
              </Card>

            </div>
          )}

          {data.checks.length === limit &&
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', padding:16}}>
              <Button label="Load More" onClick={() => setQuery({limit:limit + 50})}/>
            </div>
          }

          {/* 
          TOTAL 
          We show this if:
          1. We've exhausted a limited list; the list is shorter than the limit
          2. We're querying from a date, where no limit is applied.
          */}

          {data.user.permissions.canAgencyBillingReports && (!!filterByDateFrom || (data.checks.length > 0 && data.checks.length < limit)) &&
            <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-end', padding:16}}>
              <div style={{flex:'0 0 auto', minWidth:175}}>
                <Rule style={{marginBottom:8}}/>
                <Text block>Total: &nbsp;&nbsp;<Currency large bold>{amountsTotal}</Currency></Text>
                <Text small block>Excluding voided checks.</Text>
              </div>
            </div>
          }

        </Container>
      </div>
    </div>
  );

}