import React, {useRef, useContext} from 'react';
import { useQuery } from 'skybolt-api';
import { Text, useLayout, ThemeContext } from 'skybolt-ui';
import {Video as CloudinaryVideo} from 'cloudinary-react';
import YouTube from 'react-youtube';
import Vimeo from 'react-vimeo';


export default props => {
  
  // Load talent media data.
  
  const data = useQuery(`{
    talent(talentId:$talentId) {
      fullName
      media(active:true, spotlight:true, private:false) {
        mediaId
        title
        type
        url
        youtube_url
        cloudinary_id
        format
        private
        image(width:200, height:null)
        order
      }
    }
  }`, {talentId:props.talentId});

  
  
  // Layout
  
  const theme = useContext(ThemeContext);
  const containerRef = useRef();
  const { width } = useLayout(containerRef);
  const cols = Math.floor(width/200);
  
  return (
    <div ref={containerRef} style={{display:'grid', gridTemplateColumns:`repeat(${cols}, 1fr)`, gridGap:8, padding:16, backgroundColor:theme.color.greyLighter}}>
      {!!data && data.talent.media 
        .sort((a, b) => a.order < b.order ? -1 : 1)
        .map(m => <Media key={m.mediaId} media={m}/>)
      }
    </div>
  );
};


function Media({media}) {
  const ref = useRef();
  const { width } = useLayout(ref);
  let el = null;
          
  if(width && media.type === 'video' && media.cloudinary_id) {
    el = (
      <div style={{width:'100%'}}>
        <CloudinaryVideo
          publicId={media.cloudinary_id}
          controls={true}
          secure={true}
          videoCodec="auto"
          width={width}
        />
      </div>
    );
  }
  
  else if(width && media.type === 'link' && media.format === 'youtube' && (media.url || media.youtube_url)) {
    el = (
      <div style={{width:'100%'}}>
        <YouTube 
          videoId={youtubeId(media.url || media.youtube_url)}
          opts={{
            width: width,
            height: width * (.75),
          }}
        />
      </div>
    );
  }
  
  else if(media.type === 'link' && media.format === 'vimeo' && media.url) {
    el = (
      <div style={{width:'100%'}}>
        <Vimeo videoId={vimeoId(media.url)}/>
      </div>
    );
  }
  
  else if(media.image) {
    el = <img src={media.image} alt={media.title} style={{width:'100%'}}/>;
  }
  
  return (
    <div key={media.mediaId} ref={ref}>
      {el}
      {media.title && <Text block centered>{media.title}</Text>}
    </div>
  );
}

function youtubeId(url){
  var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[1].length === 11) ? match[1] : "";
}

function vimeoId(url){
  var regExp = /https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  var match = url.match(regExp);
  return (match && match[2]) ? match[2] : "";
}