import React from 'react';
import { Container, Text, Rule, useRouter, Loading } from 'skybolt-ui';
import { get } from 'lodash';
import { useQuery } from 'skybolt-api';
import Request from './Request';
import Media from './Media';



export default function PackMediaRequest(props) {

  const route = useRouter();
  const packId = get(route, 'match.params.packId', null);

  const data = useQuery(`{
    pack(packId:$packId) {
      agencyId
      mediaRequest {
        status
      }
    }
    user {
      permissions {
        canMessageTalents
      }
    }
  }`, {packId});

  if(!data) {
    return <Loading/>;
  }

  return (
    <Container>
      <div style={{paddingTop:32}}>
        <Text title>MEDIA</Text>
      </div>
      <Rule style={{marginBottom:24}}/>
      {data.pack.mediaRequest.status !== "SENT" && data.user.permissions.canMessageTalents ? <Request/> : <Media/>}
    </Container>
  );
}