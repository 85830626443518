import React, { useEffect, useState } from 'react';
import { Icon, Text, useRouter } from 'skybolt-ui';
import { createInvoice } from 'skybolt-api';
import { get } from 'lodash';

export default function CreateInvoice(props) {

  const route = useRouter();
  const initialData = get(route, 'location.state.data', {});

  const [error, setError] = useState(null);

  useEffect(() => {
    createInvoice({
      date: Date.now(),
      ...initialData
    })
      .then(invoice =>
        props.history.replace(invoice.invoiceId)
      )
      .catch(error => {
        setError(error);
      });
  }, []);

  if(error) {
    return (
      <div style={{textAlign:"center", paddingTop:80}}>
        <div>
          <Icon name="bomb" size={120} color="error"/>
        </div>
        <Text>{error.message}</Text>
      </div>
    );
  }

  return (
    <div style={{textAlign:"center", paddingTop:80}}>
      <div>
        <Icon name="loading" size={120} color="greyLight"/>
      </div>
      <Text small>Creating Invoice...</Text>
    </div>
  );
}