import React, { useCallback } from 'react';
import {
  Container,
  Text,
  Rule,
  Loading,
  Redirect,
  Card,
  InputHTML,
} from 'skybolt-ui';
import moment from 'moment-timezone';
import { useQuery, updateDebit } from 'skybolt-api';
import { debounce } from 'lodash';
import "./index.css";

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});



export default function Payment(props) {

  // Load data

  const {match:{params:{ debitId }}} = props;

  const data = useQuery(`{
    agency {
      timezone
    }
    debit(debitId:$debitId)
  }`, {debitId});

  let timezone = data?.agency?.timezone || moment.tz.guess();

  const debouncedUpdate = useCallback(debounce(update => {
    updateDebit({debitId, update});
  }, 500), [debitId]);


  // Render

  if(!data) {
    return <Loading/>;
  }

  if(data.debit.status !== "SENT") {
    return <Redirect to="edit"/>;
  }

  return (
    <Container className="billingpaymentview">
      <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
        <Text title>PAYMENT</Text>
      </div>
      <Rule style={{marginBottom:24}}/>

      <Card className="card">
        <div>
          <div className="billingpaymentview__talent">
            {data.debit.talentImage && <img src={data.debit.talentImage} alt="headshot"/>}
            <Text large>{data.debit.talentName}</Text>
          </div>
          <Text small>Date Sent</Text>
          <Text large paragraph>{moment(data.debit.dateSent).tz(timezone).format("MM/DD/YYYY")}</Text>
          {data.debit.packName &&
            <div>
              <Text small>Package</Text>
              <Text large paragraph>{data.debit.packName}</Text>
            </div>
          }
          {data.debit.checkNumber &&
            <div>
              <Text small>Check Number</Text>
              <Text large paragraph>{data.debit.checkNumber}</Text>
            </div>
          }
          {data.debit.invoiceNumber &&
            <div>
              <Text small>Invoice</Text>
              <Text large paragraph>{data.debit.invoiceNumber}</Text>
            </div>
          }
          <Text small>Gross Amount</Text>
          <Text large paragraph>{currencyFormatter.format(data.debit.grossAmount || 0)}</Text>
          <Text small>Tax Amount</Text>
          <Text large paragraph>{currencyFormatter.format(data.debit.taxAmount || 0)}</Text>
          <Text small>Agency Fee</Text>
          <Text large paragraph>{currencyFormatter.format(data.debit.agencyAmount || 0)}</Text>
          <Text small>Other Amount</Text>
          <Text large paragraph>{currencyFormatter.format(data.debit.otherAmount || 0)}</Text>
          <Text small>Amount</Text>
          <Text large paragraph>{currencyFormatter.format(data.debit.amount || 0)}</Text>
        </div>
        <InputHTML
          className="notes"
          title="Notes"
          hint="Only visible to agents"
          initialValue={data.debit.notes}
          onChange={notes => debouncedUpdate({notes})}
        />
      </Card>

    </Container>
  );
}