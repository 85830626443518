import React, { Component } from 'react';
import { connect } from 'react-redux';
import { subscribeToTransactions } from 'actions';
import { debounce } from 'lodash';
import moment from 'moment';

import { Redirect } from 'react-router-dom';
import Container from 'ui/Container';
import Title, {
  //Filter
} from 'ui/Title';
import List from 'ui/List';
import Icon from 'ui/Icon';

import "./index.css";





const mapStateToProps = (state, ownProps) => {
  return {
    canAccess: state.user.permissions.canAdminApp,
    transactions: state.appTransactions.all,
  };
};

const mapDispatchToProps = {
  subscribeToTransactions,
};






class Transactions extends Component {
  state = {
    type: null,
    active: null,
  }

  UNSAFE_componentWillMount() {
    this.search();
  }

  componentWillUnmount() {
    if(this.unsubscribeFromTransactions) {
      this.unsubscribeFromTransactions();
    }
  }

  setType = type => {
    this.setState({type}, this.search);
  }

  search = debounce(() => {
    if(this.unsubscribeFromTransactions) {
      this.unsubscribeFromTransactions();
    }
    this.unsubscribeFromTransactions = this.props.subscribeToTransactions(this.state.type);
  }, 300)


  renderTransaction = transaction => {
    if(transaction.id === this.state.active) {
      return (
        <div key={transaction.id}>
          <div className="transactions-code">
            {JSON.stringify(transaction, null, 2)}
          </div>
        </div>
      );
    }
    return (
      <div key={transaction.id} id={transaction.id} className="transactions-transaction" onClick={() => this.setState({active:transaction.id})}>
        <div style={{flex:1}}>
          {transaction.type}
        </div>
        <div style={{flex:1}}>
          {transaction.date && moment(transaction.date).format('MM/DD hh:mm')}
        </div>
        <div style={{flex:0}}>
          {!!transaction.dateCompleted && <Icon name="check" color="green" size={16}/>}
        </div>
        <div style={{flex:0}}>
          {!!transaction.error && <Icon name="error" color="red" size={16}/>}
        </div>
      </div>
    );
  }

  render() {
    const { transactions, canAccess } = this.props;
    // const { type } = this.state;

    if(!canAccess) {
      return <Redirect to="/"/>;
    }


    return (
      <Container>

        <Title
          filters={[
            // <Filter
            //   key="type"
            //   label="Type"
            //   value={type}
            //   options={[
            //     {value:'CANCEL_TALENT', name:'Cancel Talent'},
            //     {value:'SEND_EMAIL', name:'Send Email'},
            //     {value:'SEND_SMS', name:'Send SMS'},
            //   ]}
            //   optionValueKey="value"
            //   optionDisplayKey="name"
            //   onChange={type => this.filter(type)}
            //   style={{width:100}}
            // />,
          ]}>
          Transactions
        </Title>

        <List>
          {transactions.map(transaction => this.renderTransaction(transaction))}
        </List>

      </Container>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Transactions);