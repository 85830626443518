import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouter,
} from 'skybolt-ui';

import View from './View';
import Edit from './Edit';

export default function InvoiceView() {
  const {match:{ url, path }} = useRouter();

  return (
    <Switch>
      <Route path={`${path}/view`} component={View}/>
      <Route path={`${path}/edit`} component={Edit}/>
      <Redirect to={`${url}/view`}/>
    </Switch>
  );
}