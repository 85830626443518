import React, { Component } from 'react';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import {
  filterPackTalentMedia,
  resetPackTalentMedia,
  movePackTalentMedia,
} from 'actions';

import './index.css';

import Button from 'ui/Button';
import Title from 'ui/Title';
import MediaItem from './MediaItem';
import Placeholder from 'ui/Placeholder';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';



function isTouchDevice() {
  return 'ontouchstart' in document.documentElement;
}

function hasClass(element, name) {
  return (' ' + element.className + ' ').indexOf(' ' + name + ' ') > -1;
}

function getScrollParent(node) {
  if (node == null) {
    return null;
  }
  
  if(node.scrollHeight > node.clientHeight) {
    return node;
  }
  
  if(hasClass(node, 'scroll-container')) {
    return node;
  } 
  
  return getScrollParent(node.parentNode);
}

const SortableItem = SortableElement(props => {
  return <MediaItem {...props}/>;
});


class SortableListComponent extends Component {
  render() {
    const {children} = this.props;
    return (
      <div 
        className="talent-media-grid" 
        style={{gridTemplateColumns:`repeat(4, 1fr)`}}>
        {children}
      </div>
    );
  }
}
const SortableList = SortableContainer(SortableListComponent, {withRef:true});


const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const packId = ownProps.packId;
  
  const packTalent = !!packId && !!talentId ? (state.packTalents.byPack[packId] || {})[talentId] : {};
  const { divisions, tags } = state.agencies.all[state.user.agencyId];
  
  return {
    packId,
    talentId,
    packTalent,
    divisions, 
    tags,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  filterPackTalentMedia,
  resetPackTalentMedia,
  movePackTalentMedia,
};





class PackMedia extends Component {
  state = {
    isDivisionsOpen: false,
    isTagsOpen: false,
  }
  
  handleRequestClose = () => {
    this.setState({
      isDivisionsOpen: false,
      isTagsOpen: false,
    });
  }
  
  open = (event, modal) => {
    event.preventDefault();
    this.setState({
      isDivisionsOpen: false,
      isTagsOpen: false,
      [modal]: true,
      anchorEl: event.currentTarget,
    });
  };
  
  handleSort = ({oldIndex, newIndex}, e) => {
    const media = this.filteredMedia();
    const mediaId = media[oldIndex].id;
    const beforeId = media[newIndex].id;
    this.props.movePackTalentMedia(this.props.packId, this.props.talentId, mediaId, beforeId);
  }
  
  filteredMedia() {
    const packTalent = this.props.packTalent;
    return Object.keys(packTalent.media || {})
      .map(key => packTalent.media[key])
      .sort((a, b) => a.order < b.order ? -1 : 1);
  }
  
  render() {
    const {
      packId,
      talentId,
      packTalent,
      divisions,
      tags,
      resetPackTalentMedia,
      filterPackTalentMedia,
    } = this.props;
    
    
    if(!packTalent) {
      return null;
    }
    
    const media = this.filteredMedia();
    
    return (
        <div>
          
          <Title>Package Media</Title>
        
          <div style={{display:'flex', paddingBottom:16}}>
            <Button
              raised={true}
              label="Limit to tag..."
              onClick={e => this.open(e, 'isTagsOpen')}
              style={{marginRight:4}}
            />
            {!!divisions && divisions.length > 0 &&
              <Button
                raised={true}
                label="Limit to division..."
                onClick={e => this.open(e, 'isDivisionsOpen')}
              />
            }
            <div style={{flex:1}}/>
            <Button
              raised={true}
              label="Add All Media"
              onClick={() => resetPackTalentMedia(packId, packTalent.id)}
            />
          </div>
          
          <div>
            {media.length === 0 && 
              <Placeholder icon="media" message="No Media"/>
            }
            
            <SortableList 
              useWindowAsScrollContainer={this.props.useWindowAsScrollContainer}
              getContainer={() => getScrollParent(findDOMNode(this))}
              cols={this.state.cols} 
              axis="xy"
              helperClass="is-dragging"
              pressDelay={isTouchDevice() ? 200 : 0}
              useDragHandle={true}
              onSortEnd={this.handleSort}>
              
              {media.map((item, i) =>
                <SortableItem 
                  index={i} 
                  media={item} 
                  packId={packId}
                  talentId={talentId}
                  key={item.id} 
                />
              )}
              
            </SortableList>
          </div>
          
          
          <Popover
            open={this.state.isTagsOpen}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'left', vertical: 'top'}}
            onRequestClose={this.handleRequestClose}>
            <Menu 
              onChange={(e, tag) => {
                this.handleRequestClose();
                filterPackTalentMedia(packId, packTalent.id, {tags:[tag]});
              }}>
              {tags.map(tag => 
                <MenuItem key={tag} primaryText={tag} value={tag}/>
              )}
            </Menu>
          </Popover>
          
          <Popover
            open={this.state.isDivisionsOpen}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'left', vertical: 'top'}}
            onRequestClose={this.handleRequestClose}>
            <Menu 
              onChange={(e, division) => {
                this.handleRequestClose();
                filterPackTalentMedia(packId, packTalent.id, {divisions:[division]});
              }}>
              {divisions.map(division => 
                <MenuItem key={division} primaryText={division} value={division}/>
              )}
            </Menu>
          </Popover>
        </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(PackMedia);