/* global navigator */
import React, {useRef, useEffect, useState} from 'react';
import { useQuery } from 'skybolt-api';
import Login from './Login';
import Desktop from './Desktop';
import Mobile from './Mobile';

import "./index.css";

export default function Navigation() {


  // Check if the user is logged in. If not, we just show a login button.
  const data = useQuery(`{
    isLoggedIn
  }`);


  // Continually set the navigation height so the content gets pushed down
  // by the right amount as the navigation changes.
  const outerRef = useRef();
  const innerRef = useRef();
  useEffect(() => {
    if(innerRef.current && outerRef.current) {
      outerRef.current.style.height = innerRef.current.scrollHeight + "px";
    }
  });


  // Detect mobile, and display a hamburger menu instead of a navbar.
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    setMobile( navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/) );
  }, []);


  // Layout
  return (
    <div className="navigation" ref={outerRef} style={{width:'100%', minHeight:60}}>
      <div ref={innerRef} style={{minWidth:'100%', position: 'fixed', zIndex: 1000}}>
        {data &&
          data.isLoggedIn ? (
            isMobile ? (
              <Mobile/>
            ) : (
              <Desktop/>
            )
          ) : (
            <Login/>
          )
        }
      </div>
    </div>
  );
}