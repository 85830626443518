import React, { useContext, useState } from 'react';
import { useQuery, updateTalent } from 'skybolt-api';
import {
  Container,
  Card,
  Text,
  Button,
  InputSelect,
  ThemeContext,
  Rule,
} from 'skybolt-ui';
import Fuse from 'fuse.js';

import "./index.css";


export default function TalentAgencySelect(props) {
  
  
  // When a search value is entered into the agency select input.
  
  const data = useQuery(`{
    agencies: availableAgencies {
      agencyId
      name
      meta
    }
  }`);
  const [ options, setOptions] = useState([]);
  const search = str => {
    // If the data is still loading, use empty options.
    if(!data) {
      setOptions([]);
      return;
    }
    
    // The the search value is empty, set empty options.
    if(!str) {
      setOptions([]);
      return;
    }
    
    // Filter available agencies by th search value.
    var options = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: [
        "meta",
        "name",
      ]
    };
    const fuse = new Fuse(data.agencies, options);
    const results = fuse.search(str).slice(0,3).map(a => a.name);
    
    setOptions(results);
  };
  
  
  
  
  // Use an internal state value to hold selection. When `setAgency` is called, 
  // set the talent agency and redirect back to the talent home.
  
  const { match:{params:{ talentId }}, history } = props;
  const [ value, _setValue ] = useState("");
  
  const setValue = val => {
    if(val === value) {
      return;
    }
    _setValue(val);
    setOptions([]);
  };
  
  async function selectAgency() {
    // If no value is set, ignore.
    if(!value) {
      return;
    }
    
    // Set the agency,
    try {
      const agencyId = data.agencies.find(a => a.name === value).agencyId;
      await updateTalent({talentId, update:{agencyId}});
    }
    catch(error) {
      return;
    }
    
    // then redirect.
    history.replace('info');
    
  }
  
  async function setSeekingAgency() {
    // Set the user to seeking agency,
    await updateTalent({talentId, update:{isSeekingAgency:true, agencyId:null}});
    
    // then redirect.
    history.replace('info');
  }
  
  

  // Layout
  
  const theme = useContext(ThemeContext);
  
  return (
    <div style={{width:'100%'}}>
      <div className="TalentAgencySelect-header" style={{backgroundColor: theme.color.accent}}>
        <Text>
          <Text style={{fontSize:"60px", lineHeight:'.9em', color:'white', letterSpacing:4}} bold>SELECT</Text><br/>
          <Text style={{fontSize:"54px", lineHeight:'.9em', color:'white', letterSpacing:4}}>AGENCY</Text>
        </Text>
        <Rule small color="white" style={{margin:'32px auto 24px'}}/>
        <Text style={{color:theme.color.lightTextLighter, textAlign:'center', fontSize:16, lineHeight:'18px'}}>
          Search for your agency below.<br/>
          If you don't find your agency, or aren't a member of an agency, just <Button inline onClick={setSeekingAgency} style={{textDecoration:'underline'}}>click here</Button>.    
        </Text>
      </div>
      
      <Container style={{textAlign:'center', paddingBottom:40}}>
        
        <Card className="TalentAgencySelect-card">
          
          <InputSelect 
            title="Agency" 
            placeholder="Search..."
            value={value}
            strict
            onSearch={search}
            onChange={setValue}
            options={options}
          />
          
        </Card>
        
        <Button raised accent label="Continue" onClick={selectAgency} disabled={value === ""}/>
        
      </Container>
    </div>
  );
}