import React from 'react';
import TextField from 'material-ui/TextField';
import muiThemeable from 'material-ui/styles/muiThemeable';

export default muiThemeable()(({
  search,
  onChange,
  style={},
  muiTheme:{ palette },
  ...props
}) => {
  return (
    <TextField
      className="sui-title-search"
      value={search}
      onChange={(e, val) => onChange(val)}
      hintText="Search..."
      hintStyle={{bottom:2, color:palette.primary100}}
      style={{...style, width:'auto', height:39, paddingRight:8}}
      underlineStyle={{bottom:-1, borderColor:"#c6c6c6"}}
      inputStyle={{boxSizing:'border-box', paddingTop:11}}
      {...props}
    />
  );
});