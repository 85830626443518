import React, {useRef, useContext, useState} from 'react';
import { Icon, Menu, Text, Button, Rule, ThemeContext } from 'skybolt-ui';
import NavButton from './NavButton';
import { useQuery } from 'skybolt-api';
import moment from 'moment';

export default function() {

  const theme = useContext(ThemeContext);
  const anchor = useRef();
  const [isOpen, setOpen] = useState(false);
  const open = () => setOpen(true);
  const close = () => setOpen(false);

  const data = useQuery(`{
    user {
      permissions {
        canPack,
        canRequestPacks
      }
    }
    packs(limit:6) {
      packId
      name
      image(width:60, height:60)
      dateUpdated
    }
  }`);

  if(!data) {
    return null;
  }


  // The button.

  const buttons = (
    <div key="buttons" style={{display:'flex'}} ref={anchor}>
      <NavButton style={{padding:'24px 2px 20px 16px'}} onClick={close} href="/packs">Packages</NavButton>
      <NavButton style={{padding:'24px 16px 20px 2px'}} onClick={open}><Icon name="caret" small color={theme.color.lightTextDark}/></NavButton>
    </div>
  );


  // The menu.

  const menu = (
    <Menu
      style={{padding:16, borderTopLeftRadius:0, borderTopRightRadius:0, background:theme.color.primaryDarker, borderTop:'1px solid #aaa', display:'flex', alignItems:'flex-start'}}
      key="menu"
      anchor={anchor}
      open={isOpen}
      onRequestClose={close}>

        <div style={{flex:1}}>
          <Text block color={theme.color.lightText}>Recent Packages</Text>
          <Rule small color={theme.color.lightTextDarker} style={{margin:'8px 0 16px'}}/>
          {data.packs.map(pack =>
            <Button key={pack.packId} href={`/packs/${pack.packId}`} style={{display:'flex', justifyContent:'flex-start', paddingLeft:0}}>
              <img src={pack.image} style={{flex:'0 0 60px', width:60, height:60, marginRight:16}} alt={pack.name}/>
              <div>
                <Text block white>{pack.name}</Text>
                <Text block small light>{pack.dateUpdated && moment(pack.dateUpdated).format("MM/DD/YYYY")}</Text>
              </div>
            </Button>
          )}
        </div>

        <div style={{flex:1}}>
          <Text block color={theme.color.lightText}>Manage Packages</Text>
          <Rule small color={theme.color.lightTextDarker} style={{margin:'8px 0 16px'}}/>
          <Button icon="plus" style={{display:'block', width:250, marginLeft:-10, color:theme.color.lightText}} href="/create-pack">Create Package</Button>
          {data.user.permissions.canRequestPacks && <Button icon="send" style={{display:'block', width:250, marginLeft:-10, color:theme.color.lightText}} href="/casting-request">Create Casting Request</Button>}
        </div>

    </Menu>
  );


  return [buttons, menu];

}