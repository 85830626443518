/*

# Message Pack Talent Dialog

*/


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadPackTalents, messageSelectedPackTalents } from 'actions';

import Dialog from 'ui/Dialog';
import MessageComposer from 'ui/MessageComposer';



export const mapStateToProps = (state, ownProps) => {
  let packId = ownProps.packId;
  let talents = state.packTalents.byPack[packId] || {};
  let talentCount = Object.keys(talents)
    .filter(talentId => !!talents[talentId].isSelected)
    .length;

  return {
    packId,
    talentCount,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  loadPackTalents,
  messageSelectedPackTalents,
};



export class PackMessageSelectedTalentsDialog extends Component {

  state = {
    isSending: false
  }

  UNSAFE_componentWillMount() {
    this.props.loadPackTalents(this.props.packId);
  }

  send = async message => {
    if(this.state.isSending) {
      return;
    }
    this.setState({isSending:true});
    try {
      await this.props.messageSelectedPackTalents(this.props.packId, message);
    }
    catch(error) {};
    this.setState({isSending:false});
    this.props.onRequestClose();
  }

  render() {
    if(this.props.isLoading) {
      return null;
    }

    return (
      <Dialog
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
        bodyStyle={{backgroundColor:'#fafafa'}}>

        <MessageComposer
          draftKey={`packselected-${this.props.packId}`}
          onCancel={this.props.onRequestClose}
          onSend={this.send}
          showToField={false}
          showBCCSelf={false}
          title={`To ${this.props.talentCount} Package Talent`}
        />

      </Dialog>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PackMessageSelectedTalentsDialog);