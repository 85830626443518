import { combineReducers } from "redux";

const defaultSearch = {
  query: "",
};

const all = (state={}, action) => {
  switch(action.type) {
    case 'ADD_TALENT':
    case 'LOAD_TALENT_SUCCESS':
      return {
        ...state,
        [action.talent.id]: {...action.talent, isLoading:null},
      };

    case 'LOAD_UNAPPROVED_TALENTS_SUCCESS':
    case 'SET_TALENTS_SEARCH_RESULTS':
    case 'ADD_TALENTS_SEARCH_RESULTS':
    case 'SET_AGENCY_TALENTS_SEARCH_RESULTS':
    case 'ADD_AGENCY_TALENTS_SEARCH_RESULTS':
      return {...state, ...action.talents};

    case 'LOAD_TALENT_REQUEST':
    case 'UPDATE_TALENT_REQUEST':
      return {
        ...state,
        [action.talentId]: {...state[action.talentId], isLoading:true},
      };

    case 'CREATE_TALENT_SUBSCRIPTION':
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          ...action.talent,
        },
      };

    case 'ASSIGN_TALENT_SUCCESS':
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          userId: action.userId,
        }
      };

    case 'UPDATE_TALENT_ERROR':
      return {
        ...state,
        [action.talentId]: {...state[action.talentId], isLoading:null},
      };

    case 'UPDATE_TALENT':
      return {
        ...state,
        [action.talentId]: {...state[action.talentId], ...action.talent, isLoading:null},
      };

    case 'EMAIL_TALENT_VERIFICATION_SENT':
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          emails: {
            ...state[action.talentId].emails,
            [action.emailId]: {
              ...state[action.talentId].emails[action.emailId],
              verificationSent: true,
            }
          }
        }
      };

    case 'VERIFY_TALENT_EMAIL':
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          emails: {
            ...state[action.talentId].emails,
            [action.emailId]: {
              ...state[action.talentId].emails[action.emailId],
              isVerified: true,
            }
          }
        }
      };

    case 'MARK_WELCOME_SEEN':
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          requireShowWelcome: false,
          hasSeenWelcome: action.hasSeenWelcome,
        }
      };

    case 'LOGOUT':
      return {};

    default:
      return state;
  }
};

const search = (state=defaultSearch, action) => {
  switch(action.type) {
    case 'SET_TALENTS_SEARCH':
      return action.search;

    case 'CLEAR_TALENTS_SEARCH':
    case 'LOGOUT':
      return defaultSearch;

    default:
      return state;
  }
};

const searchResults = (state={}, action) => {
  switch(action.type) {
    case 'CLEAR_TALENTS_SEARCH':
      return {};

    case 'SET_TALENTS_SEARCH_RESULTS':
      return action.talents;

    case 'ADD_TALENTS_SEARCH_RESULTS':
      return {...state, ...action.talents};

    case 'UPDATE_TALENT':
      return {
        ...state,
        [action.talentId]: {...state[action.talentId], ...action.talent},
      };

    case 'LOGOUT':
      return {};

    default:
      return state;
  }
};

const searchCount = (state=null, action) => {
  switch(action.type) {
    case 'CLEAR_TALENTS_SEARCH':
      return null;

    case 'SET_TALENTS_SEARCH_RESULTS':
      return action.count;

    case 'LOGOUT':
      return null;

    default:
      return state;
  }
};

const isSearching = (state=false, action) => {
  switch(action.type) {
    case 'SET_TALENTS_SEARCH':
      return true;

    case 'SET_TALENTS_SEARCH_RESULTS':
    case 'ADD_TALENTS_SEARCH_RESULTS':
    case 'LOGOUT':
      return false;

    default:
      return state;
  }
};



export default combineReducers({
  all,
  isSearching,
  search,
  searchResults,
  searchCount,
});