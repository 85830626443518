import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  subscribeToThreads,
  unsubscribeFromThreads,
} from 'actions';

import Loading from 'ui/Loading';
import Placeholder from 'ui/Placeholder';
import ThreadsList from 'ui/ThreadsList';



const mapStateToProps = (state, ownProps) => {
  const userId = state.user.id;
  const threads = state.threads.byUser[userId] || {};

  return {
    history: ownProps.history,
    path: ownProps.match.path,
    url: ownProps.match.url,
    isLoading: state.threads.isLoading,
    threads,
  };
};

const mapDispatchToProps = {
  subscribeToThreads,
  unsubscribeFromThreads,
};




class All extends Component {


  componentDidMount() {
    this.limit = 25;
    this.props.subscribeToThreads(null, this.limit);
  }

  componentWillUnmount() {
    this.props.unsubscribeFromThreads();
  }


  loadMore = () => {
    if(!this.props.isLoading) {
      this.limit += 25;
      this.props.subscribeToThreads(null, this.limit);
    }
  }


  render() {
    const showThreads = !isEmpty(this.props.threads);
    const showLoader = this.props.isLoading;
    return (
      <div>
        {!showThreads && <Placeholder icon="message"/>}
        {showThreads &&
          <ThreadsList
            threads={this.props.threads}
            onLazyLoad={this.loadMore}
          />
        }
        {showLoader && <Loading style={{margin:40}}/>}
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(All);