import React from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';
import TextField from 'material-ui/TextField';


class NumberField extends React.Component {
  
  state = {
    text: "",
  }
  
  componentDidMount() {
    if(this.props.value) {
      this.setState({text:this.props.value.toString()});
    }
  }
  
  render() {
    let errorText = "";
  
    if(!!this.props.value && isNaN(this.props.value)) {
      errorText = "not a number";
    }
    
    if(!!this.props.value && this.props.min && this.props.value < this.props.min) {
      errorText = "must be greater than " + this.props.min;
    }
    
    if(!!this.props.value && this.props.max && this.props.value > this.props.max) {
      errorText = "must be less than " + this.props.max;
    }
    
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          ...this.props.style,
        }}>
        <TextField
          name={this.props.name}
          value={this.state.text}
          onChange={(e, val) => {
            this.setState({text:val});
            this.props.onChange(e, val ? parseFloat(val) : null);
          }}
          fullWidth
          errorText={errorText}
        />
        {!!this.props.units && 
          <div 
            style={{
              paddingLeft: 16,
              fontSize: 13,
              color:this.props.muiTheme.palette.primary400,
            }}>
            {this.props.units}
          </div>
        }
      </div>
    );
  }
}

export default muiThemeable()(NumberField);