import moment from 'moment-timezone';


export default function filtersDescription(filters, timezone = moment.tz.guess()) {
  let message = "";
  
  message += 'Credits ';

  if(filters.dateFrom && !filters.dateTo) {
    message += `received since ${moment(filters.dateFrom).tz(timezone).calendar()} `;
  } 

  if(!filters.dateFrom && filters.dateTo) {
    message += `received before ${moment(filters.dateTo).tz(timezone).calendar()} `;
  } 

  if(filters.dateFrom && filters.dateTo) {
    message += `received between ${moment(filters.dateFrom).tz(timezone).calendar()} and ${moment(filters.dateTo).tz(timezone).calendar()} `;
  } 

  if(filters.orderBy == 'payType') {
    message += 'ordered by Pay Type ';
  }
  else if(filters.orderBy == 'useType') {
    message += 'ordered by Use Type ';
  }
  else {
    message += 'ordered by date '
  }

  return message;
}