/**

# Generic Form Field Container.

Supply with `label` and `children` properties.

Usage:

<Field label="My Field">
  <input {...input props}/>
</Field>

**/

import React from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';

const style = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex',
  flexWrap: 'wrap',
  paddingBottom: 16
};

const baseLabelStyle = {
  width:100,
  marginRight:16,
  marginTop:17,
  color:"#aaaaaa",
  fontSize: 13,
};

const baseValueStyle = {
  flex: 1,
  display:'block',
  minWidth:300,
};

export default muiThemeable()(({
  label,
  children,
  labelStyle={},
  valueStyle={},
  valueClassName="",
  muiTheme:{palette:{ accent300 }},
  ...props
}) => (
  <div style={style} {...props}>
    <div style={{...baseLabelStyle, ...labelStyle}}>{label}</div>
    <div className={valueClassName} style={{...baseValueStyle, ...valueStyle}}>
      {children}
    </div>
  </div>
));
