import React, { useContext, useMemo } from 'react';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { Text, Rule, Button, Container, Icon, ThemeContext, Card, InputSwitch, Loading, /*Modal*/ } from 'skybolt-ui';
import { useQuery, /*updateDebit*/ } from 'skybolt-api';

export default function DebitsList(props) {


  // Manage Data

  const { state={}, pathname } = props.location;
  let {
    selection = [],
    status = "DRAFT",
  } = state;

  const data = useQuery(`{
    agency {
      timezone
      debits(status:$status) {
        debitId
        talentName
        talentImage
        packName
        packImage
        status
        invoiceNumber
        amount
      }
    }
  }`, {
    status: status === "SENT" ? "SENT" :
            status === "DRAFT" ? "DRAFT" :
            null
  });

  let timezone = data?.agency?.timezone || moment.tz.guess();

  const setQuery = (update) => props.history.replace(pathname, {...state, ...update});

  const setTab = tab => setQuery({status:tab, selection:[]});

  const isSelected = debitId => selection.indexOf(debitId) > -1;
  const toggleSelection = debitId => {
    if(!isSelected(debitId)) {
      setQuery({selection: [...selection, debitId]});
    }
    else {
      const sel = selection.filter(s => s !== debitId);
      setQuery({selection:sel});
    }
  };

  const isAllSelected = useMemo(() => {
    for(const debit of data ? data.agency.debits : []) {
      if(!isSelected(debit.debitId)) {
        return false;
      }
    }
    return true;
  }, [selection, data && data.agency.debits]);

  const toggleSelectAll = () => {
    if(!isAllSelected) {
      setQuery({selection:data.agency.debits.map(d => d.debitId)});
    }
    else {
      setQuery({selection:[]});
    }
  };



  // Actions
  // NOTE: We don't do this here anymore. This is done when including a debit in a check.

  // const selectionByStatus = status => {
  //   if(!data) {
  //     return [];
  //   }
  //   return selection.filter(debitId => {
  //     const debit = data.agency.debits.find(d => d.debitId === debitId);
  //     return debit && debit.status === status;
  //   });
  // };

  // const sentSelection = selectionByStatus("SENT");
  // const draftSelection = selectionByStatus("DRAFT");

  // const markSent = async () => {
  //   const confirmed = await Modal.confirm(`Mark ${draftSelection.length} payments as paid.`);
  //   if(!confirmed) {
  //     return;
  //   }
  //   for(const debitId of draftSelection) {
  //     await updateDebit({
  //       debitId,
  //       update:{
  //         status:"SENT",
  //         dateSent: Date.now(),
  //       }
  //     });
  //   }
  // };

  // const markDraft = async () => {
  //   const confirmed = await Modal.confirm(`Mark ${sentSelection.length} payments as outstanding.`);
  //   if(!confirmed) {
  //     return;
  //   }
  //   for(const debitId of sentSelection) {
  //     await updateDebit({
  //       debitId,
  //       update:{
  //         status:"DRAFT",
  //         dateSent: null,
  //       }
  //     });
  //   }
  // };



  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  if(isEmpty(data.agency.debits)) {
    return (
      <Container>
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>ACCOUNTS PAYABLE</Text>
          <div style={{flex:1}}/>
          <Button
            href="create"
            style={{
              background:theme.color.grey,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 40,
              height: 40,
              padding: 0,
              borderRadius: 0,
            }}>
            <Icon name="plus" color="white"/>
          </Button>
        </div>
        <Rule/>
        <div style={{marginBottom:24}}>
          <Button
            label="Outstanding"
            onClick={() => setTab("DRAFT")}
            style={{
              borderRadius: 0,
              backgroundColor: status === "DRAFT" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
          <Button
            label="Paid"
            onClick={() => setTab("SENT")}
            style={{
              borderRadius: 0,
              backgroundColor: status === "SENT" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
          <Button
            label="All"
            onClick={() => setTab("ALL")}
            style={{
              borderRadius: 0,
              backgroundColor: status === "ALL" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
        </div>
        <div style={{textAlign:'center'}}>
          <div>
            <Icon name="receipt" size={80} color="accentLighter" style={{marginBottom:16}}/>
          </div>
          <Button raised accent href="create" label="Create Payment"/>
        </div>
      </Container>
    );
  }

  return (
    <div style={{width:"100%"}}>
      <Container>
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>PAYMENTS</Text>
          <div style={{flex:1}}/>

          {selection.length > 0 &&
            <Text light block style={{margin:"0 8px 7px 0"}}>{selection.length} selected:</Text>
          }

          <Button
            href="create"
            style={{
              background:theme.color.grey,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 40,
              height: 40,
              padding: 0,
              borderRadius: 0,
            }}>
            <Icon name="plus" color="white"/>
          </Button>
        </div>
        <Rule/>
        <div style={{marginBottom:24}}>
          <Button
            label="Outstanding"
            onClick={() => setTab("DRAFT")}
            style={{
              borderRadius: 0,
              backgroundColor: status === "DRAFT" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
          <Button
            label="Paid"
            onClick={() => setTab("SENT")}
            style={{
              borderRadius: 0,
              backgroundColor: status === "SENT" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
          <Button
            label="All"
            onClick={() => setTab("ALL")}
            style={{
              borderRadius: 0,
              backgroundColor: status === "ALL" ? theme.color.primaryLighter : theme.color.greyLight
            }}
          />
        </div>
      </Container>

      <div style={{width:'100%', overflowX:'auto'}}>

        <Container style={{minWidth:900}}>

          <div style={{display:'flex', alignItems:'center', paddingBottom:8}}>
            <div style={{width:32}}>
              <InputSwitch
                value={isAllSelected}
                onChange={toggleSelectAll}
                style={{margin:0}}
              />
            </div>

            <div style={{flex:1}}>
              <Text small light>Talent</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Date</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Package</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Invoice No.</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Status</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Amount</Text>
            </div>
          </div>

          {data.agency.debits.map(debit => (
            <div key={debit.debitId} style={{display:'flex', alignItems:'center'}}>

              <div style={{width:32}}>
                <InputSwitch
                  style={{margin:0}}
                  value={selection.indexOf(debit.debitId) > -1}
                  onChange={() => toggleSelection(debit.debitId)}
                />
              </div>

              <Card
                key={debit.debitId}
                style={{
                  flex:1,
                  display:'flex',
                  alignItems:'center',
                  marginBottom:4,
                  cursor:'pointer',
                  height:40,
                  backgroundColor: isSelected(debit.debitId) ? theme.color.accentLighter : 'white',
                }}
                onClick={() => props.history.push(debit.debitId)}>

                <div style={{flex:1, display:'flex', alignItems:'center'}}>
                  {debit.talentImage &&
                    <img src={debit.talentImage} alt="headshot" style={{width:40, height:40, marginRight:8}}/>
                  }
                  <Text>{debit.talentName}</Text>
                </div>
                <div style={{flex:1}}>
                  <Text>
                    {debit.dateSent ? moment(debit.dateSent).tz(timezone).format("MM/DD/YYYY") : ""}
                  </Text>
                </div>
                <div style={{flex:1}}>
                  <Text>{debit.packName}</Text>
                </div>
                <div style={{flex:1}}>
                  <Text>{debit.invoiceNumber}</Text>
                </div>
                <div style={{flex:1}}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color: debit.status === "SENT" ? "green" :
                             "#aaaaaa"
                    }}>
                    {debit.status === "DRAFT" ? "OUTSTANDING" : "PAID"}
                  </Text>
                </div>
                <div style={{flex:1}}>
                  <Text bold>${parseFloat(debit.amount || 0, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
                </div>
              </Card>

            </div>
          ))}

        </Container>

      </div>

    </div>
  );
}
