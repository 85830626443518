import React from 'react';
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import moment from 'moment';

import Media from 'ui/Media';



const mapStateToProps = (state, ownProps) => {
  const userId = state.user.id;
  const thread = ownProps.thread || state.threads.all[ownProps.threadId] || {};

  const recipients = Object.keys(thread.recipients)
    .map(id => thread.recipients[id])
    .reduce((all, r) => !userId || r.userId !== userId ? [...all, r.name] : all, [])
    .join(', ');

  const subject = thread.subject;
  
  let body = null;
  if(thread.lastMessage) {
    const bodyEl = document.createElement("DIV");
    bodyEl.innerHTML = thread.lastMessage.body;
    body = bodyEl.textContent || bodyEl.innerText || null;
  }
  if(thread.preview) {
    body = thread.preview;
  }
  
  const time = moment(thread.dateUpdated).format("M/DD/YY h:mma");

  let unread = Object.keys(state.unreadMessages.byThread[thread.id] || {}).length;
  if(unread > 99) {
    unread = "!";
  }
  
  const icon = "thread";

  return {
    unread,
    recipients,
    subject,
    body,
    time,
    icon,
    ...ownProps,
  };
};




const ThreadLI = ({
  recipients,
  subject,
  body,
  time,
  unread,
  icon,
  muiTheme:{ palette },
  onSelect,
}) => (
  
  <div className="threadslist-subthreadli" onClick={onSelect}>
    
    <Media
      width={48}
      height={48}
      icon={icon}
      iconSize={24}
      className="threadslist-subthreadli-image"
      style={{
        backgroundColor: "#9E9E9E",
      }}
    />
    
    {!!unread ? (
      <div 
        className="threadslist-subthreadli-unread"
        style={{backgroundColor: palette.accent700}}>
        {unread}
      </div>
    ) : (
      <div className="threadslist-subthreadli-unreadplaceholder"/>
    )}
    
    <div className="threadslist-subthreadli-recipients">{recipients}</div>
    
    <div className="threadslist-subthreadli-preview">{body}</div>
    
    <div className="threadslist-subthreadli-time">
      {time}
    </div>
  </div>
  
);


export default connect(mapStateToProps)(muiThemeable()(ThreadLI));