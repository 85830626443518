import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadFAQ } from 'actions';


export function mapStateToProps(state, ownProps) {
  const id = ownProps.id;
  return {
    id,
    faq: state.faqs.all[id],
    ...ownProps,
  };
}

export const mapDispatchToProps = {
  loadFAQ,
};


export class FAQLI extends Component {

  UNSAFE_componentWillMount() {
    this.props.loadFAQ(this.props.id);
  }

  render() {
    return (
      <div onClick={this.props.onClick} className="faqadmin-li">
        {this.props.faq.question || "No Question"}
      </div>
    );
  }

}


export default connect(mapStateToProps, mapDispatchToProps)(FAQLI);