/*

# Event List

### TODO: 

- lazy loading callback 

*/

import React, { Component } from 'react';
import EventLI from 'ui/EventLI';
import moment from 'moment';
import { isEmpty } from 'lodash';
import './index.css';

import Placeholder from 'ui/Placeholder';



export default class EventList extends Component {
  
  state = {
    limit: 100,
  }
  
  showMore = () => {
    this.setState({limit: this.state.limit + 50});
  }
  
  render() {
    const {date, events} = this.props;
    
    let currDay = moment(date).startOf('day');
    let eventEls = Object.keys(events || {})
      .map(id => events[id])
      .filter(event => currDay.isBefore(event.endDate) || currDay.isSame(event.endDate, 'day'))
      .sort((a, b) => a.date < b.date ? -1 : 1)
      .slice(0, this.state.limit)
      .reduce((all, event) => {
        if(!currDay.isSame(event.date, 'day')) {
          currDay = moment(event.date);
          all = [...all,
            <div key={currDay.valueOf()} className="eventlist2-date">
              {currDay.calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                sameElse: 'MMMM Do, YYYY'
              })}
            </div>
          ];
        }
        all = [...all, 
          <EventLI 
            event={event} 
            key={event.id} 
            style={{marginBottom:4}}
            onClick={() => this.props.onClickEvent(event)}
          />
        ];
        
        return all;
      }, []);
    
    if(isEmpty(eventEls)) {
      return (
        <Placeholder
          icon="calendar"
          message={`There are no events scheduled after ${moment(date).format("dddd MMMM Do, YYYY")}.`}
          label="Add Event"
          onClick={this.props.onClickAdd}
        />
      );
    }
    
    return (
      <div className="eventlist2">
        {eventEls}
      </div>
    );
  }
}
