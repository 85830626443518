import React, { useState } from 'react';
import { useQuery, createTalent, invite } from 'skybolt-api';
import { Container, Card, Form, InputText, Text, Rule, Button, useRouter } from 'skybolt-ui';

export default function() {

  const { history } = useRouter();

  const data = useQuery(`{
    agency {
      agencyId,
    }
  }`);

  const [value, setValue] = useState({});

  if(!data) {
    return null;
  }

  async function send() {
    const talent = await createTalent({
      ...value,
      agencyId: data.agency.agencyId,
      approved: true,
    });
    const invitationId = await invite({
      agencyId: data.agency.agencyId,
      talentId: talent.talentId,
      email: value.email,
      user: {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        role: 'castingdirector',
        agencyIds: [data.agency.agencyId],
        permissions: {
          canViewPacks: true,
          canViewTalents: true,
          canOwnTalents: null,
        }
      }
    });
    history.replace(`/admin/invite-guest/sent/${invitationId}`);
  }

  return (
    <Container>
      <div style={{paddingTop:32}}>
        <Text title>INVITE GUEST</Text>
      </div>
      <Rule style={{marginBottom:24}}/>
      <Card style={{padding:16}}>
        <Form onChange={setValue} value={value} style={{maxWidth:600}}>
          <InputText title="First Name" name="firstName"/>
          <InputText title="Last Name" name="lastName"/>
          <InputText title="Email Address" name="email"/>
          <Button accent raised onClick={send}>Send Invitation</Button>
        </Form>
      </Card>
    </Container>
  );
}