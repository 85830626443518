import React from 'react';
import { useQuery } from 'skybolt-api';
import {
  Form,
  Text,
  InputText,
  InputPhone,
  InputTag,
  Rule,
  Loading,
  Card,
  useRouter,
} from 'skybolt-ui';

export default props => {

  const route = useRouter();

  const data = useQuery(`{
    agency(agencyId:$agencyId)
    user {
      permissions {
        canAdminAgencies
      }
    }
  }`, {agencyId:route.match.params.agencyId});

  if(!data) {
    return <Loading/>;
  }

  function handleChange(val) {
    console.log('change', val);
  }

  return (
    <Form initialValue={data.agency} onChange={handleChange}>

      <div style={{display:'flex', alignItems:'center', height:40}}>
        <Text title>INFO</Text>
      </div>
      <Rule style={{marginBottom:24}}/>
      <Card style={{padding:16, marginBottom:24}}>
        <div style={{maxWidth:600}}>
          <InputText
            name="name"
            title="Agency Name"
          />
          <InputText
            name="contactName"
            title="Contact Name"
          />
          <InputText
            name="contactEmail"
            title="Contact Email"
            clean={val => val.replace(/\s/g, "")}
          />
          <InputPhone
            name="primaryPhone"
            title="Contact Phone"
          />
          <InputText
            name="website"
            title="Website"
          />
        </div>
      </Card>

      <div style={{display:'flex', alignItems:'center', height:40}}>
        <Text title>TAGS</Text>
      </div>
      <Rule style={{marginBottom:8}}/>
      <Text block small light style={{marginBottom:24}}>Separate tags with commas.</Text>

      <Card style={{padding:16, marginBottom:40}}>
        <div style={{maxWidth:600}}>
          <InputTag
            name="divisions"
            title="Divisions"
          />
          <InputTag
            name="talentTags"
            title="Talent Tags"
          />
          <InputTag
            name="mediaTags"
            title="Media Tags"
          />
        </div>
      </Card>

    </Form>
  );
};
