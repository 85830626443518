import { load, loadEpayAccount } from 'skybolt-api';
import moment from 'moment';
import { useState, useEffect } from 'react';

export async function loadUpcomingPayments(userId) {
  if(!userId) {
    return [];
  }

  // Load all user's associated epayIds

  const epayIds = [];

  const userEpayId = await load(`users/${userId}/epayId`);
  const userTalents = await load(`users/${userId}/talents`);

  if(userEpayId) {
    epayIds.push(userEpayId);
  }

  const talentIds = Object.keys(userTalents || {});
  for(const talentId of talentIds) {
    const talentEpayId = await load(`talents/${talentId}/epayId`);
    if(talentEpayId) {
      epayIds.push(talentEpayId);
    }
  }


  // For each epay id, load next payment.

  let nextPayments = [];

  for(const epayId of epayIds) {
    if(epayId) {
      try {
        const res = await loadEpayAccount(epayId);
        if(res && res.enabled) {
          nextPayments.push({
            amount: res.amount,
            cycle: res.cycle.toLowerCase().indexOf('mon') > -1 ? 'month' : 'year',
            date: res.next,
            failures: res.failures,
            enabled: res.enabled,
            status: "Pending",
            card: res.card,
            address: res.address,
          });
        }
      }
      catch(error) {
      }
    }
  }


  // Sort all payments by date.

  nextPayments.sort((a, b) => moment(a.date).isBefore(b.date) ? 1 : -1);


  // and return.

  return nextPayments;

}

export default function useUpcomingPayments(userId) {
  const [state, setState] = useState(null);

  useEffect(() => {
    if(!userId) {
      return;
    }
    loadUpcomingPayments(userId).then(t => setState(t));
  }, [userId]);

  return state;
}