import { combineReducers } from "redux";

const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_USER_THREADS_SUCCESS':
    case 'LOAD_TALENT_THREADS_SUCCESS':
    case 'LOAD_PACK_THREADS_SUCCESS':
      return {
        ...state,
        ...action.threads,
      };

    case 'REMOVE_THREAD':
      const { [action.threadId]:removedThread, ...rest } = state;
      return rest;

    case 'ADD_THREAD':
    case 'LOAD_THREAD':
    case 'UPDATE_THREAD':
    case 'ADD_PACK_THREAD':
      return {
        ...state,
        [action.threadId]: action.thread,
      };

    case 'ADD_THREAD_THREAD':
      return {
        ...state,
        [action.thread.id]: {...action.thread, parentThreadId:action.parentThreadId}
      };

    case 'LOGOUT':
      return {};

    default:
      return state;
  }
};

const byUser = (state={}, action) => {
  let newState = {};

  switch(action.type) {
    case 'LOAD_USER_THREADS_SUCCESS':
      if(action.userId) {
        return {
          ...state,
          [action.userId]: action.threads,
        };
      }
      return state;

    case 'UPDATE_THREAD':
    case 'ADD_PACK_THREAD':
      for(const userId in state) {
        newState[userId] = {};
        for(const threadId in state[userId]) {
          if(threadId !== action.threadId) {
            newState[userId][threadId] = state[userId][threadId];
          }
          else {
            newState[userId][threadId] = action.thread;
          }
        }
      }
      return newState;

    case 'REMOVE_USER_THREAD':
      const { [action.threadId]:removedThread, ...userThreads } = state[action.userId] || {};
      return {
        ...state,
        [action.userId]: userThreads,
      };

    case 'LOGOUT':
      return {};

    default:
      return state;
  }
};

const byPack = (state={}, action) => {
  const newState = {};

  switch(action.type) {
    case 'LOAD_PACK_THREADS_SUCCESS':
      return {
        ...state,
        [action.packId]: {...state[action.packId], ...action.threads},
      };

    case 'UPDATE_THREAD':
    case 'LOAD_THREAD':
      for(const packId in state) {
        newState[packId] = {};
        for(const threadId in state[packId]) {
          if(threadId !== action.threadId) {
            newState[packId][threadId] = state[packId][threadId];
          }
          else {
            newState[packId][threadId] = action.thread;
          }
        }
      }
      return newState;

    case 'ADD_PACK_THREAD':
      return {
        ...state,
        [action.packId]: {
          ...state[action.packId],
          [action.thread.id]: action.thread,
        }
      };

    case 'REMOVE_PACK_THREAD':
      const { [action.threadId]:removedThread, ...packThreads } = state[action.packId] || {};
      return {
        ...state,
        [action.packId]: packThreads,
      };

    case 'LOGOUT':
      return {};

    default:
      return state;
  }
};

const byTalent = (state={}, action) => {
  let newState = {};

  switch(action.type) {
    case 'LOAD_TALENT_THREADS_SUCCESS':
      return {
        ...state,
        [action.talentId]: action.threads,
      };

    case 'LOAD_TALENT_THREADS_FAILURE':
      return {
        ...state,
        [action.talentId]: {},
      };

    case 'REMOVE_TALENT_THREAD':
      const { [action.threadId]:removedThread, ...talentThreads } = state[action.talentId] || {};
      return {
        ...state,
        [action.talentId]: talentThreads,
      };

    case 'UPDATE_THREAD':
    case 'LOAD_THREAD':
    case 'ADD_PACK_THREAD':
      for(const packId in state) {
        newState[packId] = {};
        for(const threadId in state[packId]) {
          if(threadId !== action.threadId) {
            newState[packId][threadId] = state[packId][threadId];
          }
          else {
            newState[packId][threadId] = action.thread;
          }
        }
      }
      return newState;

    case 'ADD_TALENT_THREAD':
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          [action.thread.id]: action.thread,
        }
      };

    case 'LOGOUT':
      return {};

    default:
      return state;
  }
};

const byThread = (state={}, action) => {
  let newState = {};

  switch(action.type) {
    case 'LOAD_THREAD_THREADS_SUCCESS':
      return {
        ...state,
        [action.parentThreadId]: action.threads,
      };

    case 'UPDATE_THREAD':
    case 'LOAD_THREAD':
    case 'ADD_PACK_THREAD':
      for(const parentThreadId in state) {
        newState[parentThreadId] = {};
        for(const threadId in state[parentThreadId]) {
          if(threadId !== action.threadId) {
            newState[parentThreadId][threadId] = state[parentThreadId][threadId];
          }
          else {
            newState[parentThreadId][threadId] = action.thread;
          }
        }
      }
      return newState;

    case 'ADD_THREAD_THREAD':
      return {
        ...state,
        [action.parentThreadId]: {
          ...state[action.parentThreadId],
          [action.thread.id]: action.thread,
        }
      };

    case 'LOGOUT':
      return {};

    default:
      return state;
  }
};

const isLoading = (state=false, action) => {
  switch(action.type) {

    case 'LOAD_USER_THREADS_REQUEST':
      return true;

    case 'LOAD_USER_THREADS_FAILURE':
    case 'LOAD_USER_THREADS_SUCCESS':
      return false;

    default:
      return state;
  }
};


export default combineReducers({
  all,
  byUser,
  byPack,
  byTalent,
  byThread,
  isLoading,
});