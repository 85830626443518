import React, { useEffect } from 'react';
import { Loading, useRouter } from 'skybolt-ui';
import {
  requiredSubscription,
  updateEpayAccount,
  chargeEpayAccount,
  updateTalent,
} from 'skybolt-api';

export default function ChargeAgency(props) {
  const route = useRouter();
  const talentId = route.match.params.talentId;

  useEffect(() => {
    let isCancelled = false;

    async function updateSubscription() {
      const sub = await requiredSubscription({talentId, status:"PAID"});
      if(isCancelled) {
        return;
      }

      await updateEpayAccount({epayId:sub.epayId, amount:sub.amount});
      if(sub.setup || sub.initial) {
        await chargeEpayAccount({epayId:sub.epayId, amount:sub.setup + sub.initial});
      }

      await updateTalent({
        talentId,
        change:{
          status:"PAID",
          statusHistory: {
            [Date.now()]: "PAID by agency"
          },
          commission: sub.commission,
          requireSubscription: null,
          dateExpires: null,
        }
      });

      route.history.replace('/talents/'+talentId);
    }
    updateSubscription();

    return () => isCancelled = true;
  }, [talentId]);

  return <Loading/>;
}