import React from 'react';
import { Icon, Button/*, matchPath*/ } from 'skybolt-ui';

export default React.forwardRef(function NavButton({external, style, children, href, ...props}, ref) {

  // const active = matchPath(href, {path:window.location.pathname, exact:false, strict:false});
  // console.log(href, window.location.pathname, active);

  if(external) {
    style = {
      display: 'block',
      padding: "9px 16px 7px 8px",
      fontFamily: 'proxima-nova, sans-serif',
      fontSize: props.inline ? "inherit" : '14px',
      lineHeight: props.inline ? "inherit" : '16px',
      textAlign: 'left',
      cursor: 'pointer',
      userSelect: 'none',
      ...style,
    };
    return (
      <a
        style={style}
        href={href}
        ref={ref}
        className={props.className}>
        <Icon name={props.icon} size={16} style={{marginRight:3}}/> 
        {props.label || props.children}
      </a>
    );
  }

  return (
    <Button
      ref={ref}
      style={{color:'white', whiteSpace:'nowrap', flexShrink:0, ...style}}
      href={href}
      {...props}>
      {children}
    </Button>
  );
});