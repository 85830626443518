import React from 'react';
import './index.css';

export default ({
  children,
  style={},
}) => (
  <div className="subtitle" style={style}>
    {children}
  </div>
);