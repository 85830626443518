import React from 'react';
import {
  Text,
  Rule,
  useLayout,
} from 'skybolt-ui';
import Talent from './Talent';
import Resume from './Resume';

export default props => {

  const { width } = useLayout();

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: width > 600 ? '2fr 1fr' : '1fr',
        gridGap: 16,
        paddingBottom: 120,
      }}>

      <div>
        <div style={{display:'flex', alignItems:'center', height:40}}>
          <Text title>TALENT FIELDS</Text>
        </div>
        <Rule style={{marginBottom:8}}/>
        <Text block small light>Define fields available to Talent.</Text>
        <Text block small light style={{marginBottom:24}}>Drag fields to set display order.</Text>
        <Talent/>
      </div>


      <Resume/>

    </div>
  );
};
