/* global Modernizr */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import configureStore from "store";
import SiteError from 'ui/SiteError';
import BrowserUpgrade from 'ui/BrowserUpgrade';
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/messaging";
import { firebaseConfig, cloudinaryConfig, algoliaConfig } from 'config';
import { loadLoggedInUser, logout } from 'actions';
import { CloudinaryContext } from 'cloudinary-react';
import { createBrowserHistory } from "history";
import 'whatwg-fetch';
import {
  initializeFirebase,
  initializeAlgolia,
  initializeCloudinary,
  on,
} from 'skybolt-api';
import "./index.css";
import "skybolt-ui/dist/bundle.css";
import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'modernizr';
import App from './App/index.js';


// Expose firebase globally so svelte can use it.

window.firebase = firebase;


// If not browser compliant, render <BrowserUpgrade/>.

let isBrowserCompliant = true;
for(var feature in Modernizr) {
  if(Modernizr.hasOwnProperty(feature) && !Modernizr[feature]) {
    isBrowserCompliant = false;
    break;
  }
}

if(!isBrowserCompliant) {
  ReactDOM.render(<BrowserUpgrade/>, document.getElementById('root'));
}

else {

  // Initialize the store.

  const store = configureStore();



  // Dispatch api updates to the store.

  on(action => {
    store.dispatch(action);
  });


  // Create the history.

  const history = createBrowserHistory();




  // When skybolt-web is loaded, tie it's router and 
  // evnet pool into the app.

  window.addEventListener('skybolt-ready', () => {
    // console.log('skybolt ready', window.skybolt.router);

    window.skybolt.on(action => {
      store.dispatch(action);
    });

    window.skybolt.router().subscribe(match => {
      history.push(match.url);
    });
  });

  window.addEventListener('skybolt-snow-ready', () => {

    // window.skyboltsnow.on(action => {
    //   store.dispatch(action);
    // });
    if(window.skyboltsnow.watchSubscription) {
      window.skyboltsnow.watchSubscription();
    }
    window.skyboltsnow.router().subscribe(match => {
      history.push(match.url);
    });
  });



  // Initialize Skybolt API

  // todo: replace firebase init with skybolt api init
  // once whole app is on skybolt api.
  firebase.initializeApp(firebaseConfig);
  initializeFirebase({functionsUrl:firebaseConfig.functionsUrl});
  // initializeFirebase(firebaseConfig);

  initializeAlgolia(algoliaConfig);
  initializeCloudinary(cloudinaryConfig);



  // Check for a logged in user. Only do this once.

  const unsubscribe = firebase.auth().onAuthStateChanged(user => {
    if(user) {
      if(window.skyboltkit) {
        window.skyboltkit.authSetUser(user.uid);
      }
      store.dispatch(loadLoggedInUser());
    } else {
      store.dispatch(logout());
    }
    unsubscribe();
  });

  window.addEventListener('skybolt-snow-ready', () => {
    let uid = firebase.auth().currentUser?.uid;
    if(uid && window.skyboltkit) {
      window.skyboltkit.authSetUser(uid);
    } 
  });


  // Get intercom set up.

  // let isIntercomLoggedIn = false;
  if(!window.location.pathname.startsWith("/embed")) {
    window.Intercom('boot', {app_id: 'ohdhl5me'});

    let isIntercomLoggedIn = false;
    history.listen(loc => {
      const user = store.getState().user;

      if(!user.isLoggedIn && isIntercomLoggedIn) {
        window.Intercom('shutdown');
        window.Intercom('boot', {app_id: 'ohdhl5me'});
        isIntercomLoggedIn = false;
        return;
      }

      let userData = {};
      if(user.isLoggedIn) {
        userData = {
          user_id: user.id,
          email: user.email,
          name: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
        };
        isIntercomLoggedIn = true;
      }
      window.Intercom('update', {
        app_id: 'ohdhl5me',
        ...userData
      });
    });


  }



  // Render the DOM.
  ReactDOM.render(
    <SiteError>
      <Provider store={store}>
        <CloudinaryContext cloudName={cloudinaryConfig.cloud_name}>
          <App history={history}/>
        </CloudinaryContext>
      </Provider>
    </SiteError>,
    document.getElementById('root')
  );

}