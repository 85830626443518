import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import muiThemeable from 'material-ui/styles/muiThemeable';
import {
  updateMedia,
  moveTalentMediaToTop,
  moveTalentMediaToBottom,
  moveTalentMediaUp,
  moveTalentMediaDown,
  approveMedia,
} from 'actions';
import { cloudinaryUrl } from 'skybolt-api';
import { Button as UIButton, Icon as UIIcon } from 'skybolt-ui';

import Icon from 'ui/Icon';
import Media from 'ui/Media';
import Button from 'ui/Button';
import {
  SortableHandle,
} from 'react-sortable-hoc';


export const mapStateToProps = (state, ownProps) => {
  const media = ownProps.media || state.media.all[ownProps.mediaId];
  const canEdit = state.user.isLoggedIn;
  const canApprove = state.user.permissions.canApproveMedia;
  const canSpotlight = state.user.permissions.canSpotlight;
  return {
    userId: state.user.id,
    media,
    canEdit,
    canApprove,
    canSpotlight,
    ...ownProps,
  };
};


export const mapDispatchToProps = {
  updateMedia,
  moveTalentMediaToTop,
  moveTalentMediaToBottom,
  moveTalentMediaUp,
  moveTalentMediaDown,
  approveMedia,
};


const DragHandle = SortableHandle(() =>
  <div
    className="talent-media-item-action"
    style={{
      backgroundColor: 'white',
    }}>
    <Icon name="dragHand" size={12}/>
  </div>
);

export class TalentMediaItem extends Component {

  static defaultProps = {
    onEditPress: () => null,
    width: 400,
  }



  edit = () => {
    this.props.onEditPress(this.props.media);
  }

  activate = () => {
    const id = this.props.media.id;
    const active = !!!this.props.media.active;
    this.props.updateMedia(id, {active});
  }

  approve = () => {
    const id = this.props.media.id;
    if(!this.props.media.approved) {
      this.props.approveMedia(id);
    }
  }

  toggleSpotlight = () => {
    const id = this.props.media.id;
    const spotlight = this.props.media.spotlight === false ? true : false;
    this.props.updateMedia(id, {spotlight});
  }

  download = () => {
    const url = cloudinaryUrl(this.props.media.cloudinary_id);
    const link = document.createElement("a");
    link.download = (this.props.media.title || "Document") + "." + this.props.media.format;
    link.href = url;
    link.target= "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    if(!this.props.media) {
      return null;
    }

    const activeColor = this.props.muiTheme.palette.accent700;

    const {
      title,
      date,
      active,
      spotlight,
      approved,
      format,
    } = this.props.media;

    const {
      canEdit,
      canApprove,
      canSpotlight,
    } = this.props;

    const doGrayscale = !active || (canApprove && !approved);

    return (
      <div className="talent-media-item">

        <div className="talent-media-item-image">
          <Media
            className="talent-media-item-image-media"
            icon="media"
            media={this.props.media}
            grayscale={doGrayscale}
            effect={doGrayscale ? 'grayscale' : null}
            style={{opacity: doGrayscale ? 0.2 : 1}}
            width={this.props.width}
            crop="scale"
          />
        </div>

        <div className="talent-media-item-title">
          {title}
        </div>
        {date && <div className="talent-media-item-date">
          {moment(date).format('MMM Do, YYYY')}
        </div>}


        {canEdit && (
          <div className="talent-media-item-actions">

            <div className="talent-media-item-edit talent-media-item-action">
              <Button
                icon="edit"
                onClick={this.edit}
              />
            </div>

            <div
              className="talent-media-item-action"
              style={{
                backgroundColor: active ? activeColor : 'white',
              }}>
              <Button
                icon="active"
                color={active ? 'white' : null}
                onClick={this.activate}
              />
            </div>

            {canSpotlight &&
              <div
                className="talent-media-item-action"
                style={{
                  backgroundColor: spotlight !== false ? activeColor : 'white',
                }}>
                <UIButton
                  icon={<UIIcon name="star" size={12}/>}
                  color={spotlight !== false ? 'white' : null}
                  onClick={this.toggleSpotlight}
                  style={{padding:0}}
                />
              </div>
            }

            {canApprove && !approved &&
              <div
                className="talent-media-item-action"
                style={{
                  backgroundColor: approved ? activeColor : 'white',
                }}>
                <Button
                  icon="approve"
                  color={approved ? 'white' : null}
                  onClick={this.approve}
                />
              </div>
            }

            {(format === 'pdf' || format === 'jpg') &&
              <div className="talent-media-item-action">
                <Button icon="download" onClick={this.download}/>
              </div>
            }

            <DragHandle/>

          </div>
        )}

      </div>
    );
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(TalentMediaItem));