import React from 'react';
import { CustomPicker } from 'react-color';
import { Swatch } from 'react-color/lib/components/common';


export const colors = {
  '#F44336': 'red',
  '#E91E63': 'pink',
  '#9C27B0': 'purple',
  '#673AB7': 'deepPurple',
  '#3F51B5': 'indigo',
  '#2196F3': 'blue',
  '#03A9F4': 'lightBlue',
  '#00BCD4': 'cyan',
  '#009688': 'teal',
  '#4CAF50': 'green',
  '#8BC34A': 'lightGreen',
  '#CDDC39': 'lime',
  '#FFEB3B': 'yellow',
  '#FFC107': 'amber',
  '#FF9800': 'orange',
  '#FF5722': 'deepOrange',
  '#795548': 'brown',
  '#607D8B': 'blueGrey',
  '#9E9E9E': 'grey',
};

export const values = {
  'red': '#F44336',
  'pink': '#E91E63',
  'purple': '#9C27B0',
  'deepPurple': '#673AB7',
  'indigo': '#3F51B5',
  'blue': '#2196F3',
  'lightBlue': '#03A9F4',
  'cyan': '#00BCD4',
  'teal': '#009688',
  'green': '#4CAF50',
  'lightGreen': '#8BC34A',
  'lime': '#CDDC39',
  'yellow': '#FFEB3B',
  'amber': '#FFC107',
  'orange': '#FF9800',
  'deepOrange': '#FF5722',
  'brown': '#795548',
  'blueGrey': '#607D8B',
  'grey': '#9E9E9E',
};

export const Picker = CustomPicker(({ onChange, onSwatchHover, hex, colors, width, triangle }) => {
  return (
    <div
      style={{
        width,
        background: '#fff',
        border: '0 solid rgba(0,0,0,0.25)',
        boxShadow: '0 1px 4px rgba(0,0,0,0.25)',
        borderRadius: '4px',
        position: 'relative',
      }}>

      <div
        style={{
          width: '0px',
          height: '0px',
          borderStyle: 'solid',
          borderWidth: '0 9px 10px 9px',
          borderColor: 'transparent transparent rgba(0,0,0,.1) transparent',
          position: 'absolute',
        }}
      />
      <div
        style={{
          width: '0px',
          height: '0px',
          borderStyle: 'solid',
          borderWidth: '0 9px 10px 9px',
          borderColor: 'transparent transparent #fff transparent',
          position: 'absolute',
        }}
      />

      <div style={{padding:'15px 9px 9px 15px'}}>
        {colors.map((c, i) => {
          return (
            <Swatch
              key={ i }
              color={ c }
              hex={ c }
              style={{
                display: 'inline-block',
                width: '30px',
                height: '30px',
                borderRadius: '4px',
                margin: '0 6px 6px 0',
              }}
              onClick={ onChange }
              focusStyle={{
                boxShadow: `0 0 4px ${ c }`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
});


export default class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: "rbga(0,0,0,0)",
  }

  UNSAFE_componentWillMount() {
    this.setState({
      color: values[this.props.color],
    });
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  }

  handleChange = (color) => {
    const name = colors[color.hex.toUpperCase()];
    this.setState({ color: color.hex });
    this.props.onChange(name);
  }

  render() {

    const styles = {
      color: {
        width: '120px',
        height: '14px',
        borderRadius: '2px',
        background: this.state.color,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };

    return (
      <div style={this.props.style}>
        <div style={ styles.swatch } onClick={ this.handleClick }>
          <div style={ styles.color } />
        </div>
        {this.state.displayColorPicker && (
          <div style={ styles.popover } onClick={e => e.stopPropagation()}>
            <div style={ styles.cover } onClick={ this.handleClose }/>
            <Picker
              colors={Object.keys(colors)}
              onChange={ this.handleChange }
            />
          </div>
        )}
      </div>
    );
  }
}
