import React, { useRef, useLayoutEffect } from 'react';

export default function(props) {
  let wrapper = useRef();
  let app = useRef();
  let instance = useRef();

  useLayoutEffect(() => {
    if(!window.skyboltsnow) {
      return;
    }
    let Component = window.skyboltsnow.UserSubscription;
    if(app.current == Component) {
      return;
    }
    if(instance.current) {
      instance.current.$destroy();
    }
    app.current = Component;
    instance.current = new Component({
      target:wrapper.current, 
      props: {
        id: props.match.params.userId,
      }
    });
    window.skyboltsnow.attach(wrapper.current);
  }, [wrapper.current, window.skyboltsnow]);

  return (
    <div
      ref={wrapper}
      style={{
        display: 'block',
        width: '100%',
      }}
    />
  );
}