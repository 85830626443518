import React, { useCallback, useContext } from 'react';
import {
  Container,
  Form,
  Text,
  Rule,
  Card,
  Button,
  Modal,
  InputSelect,
  InputText,
  InputTime,
  InputNumber,
  InputDate,
  InputHTML,
  InputArray,
  InputSwitch,
  Loading,
  Redirect,
  useRouter,
  ThemeContext,
} from 'skybolt-ui';
import InputTalent from './InputTalent';
import InputClient from './InputClient';
import InputPackage from './InputPackage';
import InputCommission from './InputCommission';
import InputAgent from './InputAgent';
import { useQuery, updateInvoice, removeInvoice, createDebits } from 'skybolt-api';
import { debounce, map } from 'lodash';
import "./index.css";

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});



export default function Invoice(props) {
  const {match:{ url, params:{ invoiceId }}, history} = useRouter();

  // Load and manage invoice data.

  const data = useQuery(`{
    invoice(invoiceId:$invoiceId) {
      packId
      packName
      packImage
      agentId
      clientId
      client {
        rate
        commission
        agencyFee
        name
        contactName
        emails
        email
      }
      isToBeEmailed
      attnName
      agencyFee
      jobType
      invoiceNumber
      poNumber
      vendorNumber
      date
      dateDue
      rate
      notes
      status
      comments
      lineItems {
        date
        talentId
        talentName
        talentImage
        comment
        type
        timeFrom
        timeTo
        hours
        rate
        commission
        total
      }
      subtotal
      agencyFeeTotal
      total
      commissionTotal
      debits(status:"PENDING")
      error
    }
    agency {
      agencyId
      billingOrgId
      jobTypes
      billingTypes
      billingAgencyFee
      billingCommission
      billingRate
      timezone
    }
  }`, {invoiceId});
  

  let timezone = data?.agency?.timezone;

  // Function to commit updates to an invoice on a debounce.

  const debouncedUpdateInvoice = useCallback(debounce(update => {
    updateInvoice({invoiceId, update});
  }, 500), [invoiceId]);
  const handleChange = (_, update) => debouncedUpdateInvoice(update);


  async function post() {
    if(!data.invoice.invoiceNumber) {
      await Modal.error("An invoice number is required.");
      return;
    }
    if(!data.invoice.clientId) {
      await Modal.error("Please select or create a client.");
      return;
    }
    if(!!data.invoice.lineItems.find(item => !item.type)) {
      await Modal.error("All line-items must be assigned a 'Type'.");
      return;
    }

    const confirm = await Modal.confirm(<div>
      <Text bold paragraph>Are you sure?</Text>
      {data.agency.billingOrgId && (
        <Text paragraph>Posting will lock this invoice and send it to the client. This cannot be undone.</Text>
      )}
    </div>);
    if(confirm) {
      await updateInvoice({invoiceId, update:{status:"POSTED"}});
      if(!data.invoice.debits.length) {
        await createDebits({invoiceId, status:"PENDING"});
      }
    }
  }

  async function discard() {
    const confirm = await Modal.confirm(<div>
      <Text bold paragraph>Are you sure?</Text>
      <Text paragraph>This will delete the current draft.</Text>
    </div>);
    if(confirm) {
      await removeInvoice({invoiceId});
      history.push('/billing/invoices') ;
    }
  }



  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  if(data.invoice.status !== "DRAFT") {
    return <Redirect to="view"/>;
  }

  if(!Array.isArray(data.invoice.lineItems)) {
    data.invoice.lineItems = [];
  }

  return (
    <Container className="billing-invoice">

      <div style={{display:"flex", alignItems:"flex-end", height:40, paddingTop:32}}>
        <Text title>Invoice {data.invoice.invoiceNumber}</Text>
        <div style={{flex:1}}/>
        <Button label="Discard" onClick={discard} color="textLight"/>
        <Button
          icon="check"
          label="Post Invoice"
          onClick={post}
          style={{
            background:theme.color.accent,
            color: 'white',
            borderRadius: 0,
            lineHeight: '24px',
          }}
        />
      </div>
      <Rule style={{marginBottom:24}}/>

      {data.invoice.error && (
        <div style={{marginBottom:16, marginLeft:16}}>
          <Text block>There was an error posting this invoice. Please correct this error and try again.</Text>
          <Text block error>{data.invoice.error}</Text>
        </div>
      )}

      <Card style={{marginBottom:40}}>
        <Form value={data.invoice} onChange={handleChange}>

          <div className="info">
            <div className="client">
              <InputClient name="clientId" strict title="Client"/>
            </div>

            {data.invoice.clientId &&
              <div className="client-info">
                <div className="client-name">
                  <Text light block>{data.invoice.client.contactName}</Text>
                  <Text light block>{data.invoice.client.email}</Text>
                </div>
                <Button
                  href={{
                    pathname:`/billing/clients/${data.invoice.clientId}`,
                    state: {
                      referrer: url,
                    }
                  }}
                  icon="external-link-square"
                />
              </div>
            }

            <div className="email-invoice">
              <InputSwitch title="Email invoice" name="isToBeEmailed"/>
            </div>

            <InputAgent className="agent" agencyId={data.agency.agencyId} name="agentId" title="Agent"/>

            <InputText className="attn" name="attnName" title="Attn." placeholder={data.invoice.client.contactName}/>

            <InputSelect className="type" name="jobType" strict options={data.agency.jobTypes} title="Job Type"/>
            <InputPackage className="pack" title="Package"/>
            <InputNumber className="num" name="invoiceNumber" title="Invoice Number"/>
            <InputText className="vendor" name="vendorNumber" title="Vendor Number"/>
            <InputText className="po" name="poNumber" title="PO Number" format={v => v.slice(0, 20)}/>
            <InputDate className="date" name="date" title="Date" timezone={timezone}/>
            <InputDate className="due" name="dateDue" title="Date Due" timezone={timezone}/>
            <InputNumber className="percent" name="agencyFee" title="Agency Fee %"/>
            <InputNumber className="rate" name="rate" title="Rate"/>
            <InputHTML className="comments" name="comments" title="Comment" hint="Displayed on the invoice."/>
            <InputHTML className="notes" name="notes" title="Notes" hint="Only visible to agents."/>
          </div>

          <div className="details-wrapper">
            <div className="details">
              <Text>Line Items</Text>
              <Rule style={{marginBottom:16}}/>
              <div className="titles" style={{marginBottom:8}}>
                <Text small light block style={{flex:1, marginRight:8}}>Date</Text>
                <Text small light block style={{flex:2, marginRight:8}}>Talent</Text>
                <Text small light block style={{flex:1, marginRight:8}}>Type</Text>
                <Text small light block style={{flex:1, marginRight:8}}>Comment</Text>
                <Text small light block style={{flex:1, marginRight:8}}>From Time</Text>
                <Text small light block style={{flex:1, marginRight:8}}>To Time</Text>
                <Text small light block style={{flex:1, marginRight:8}}>Hours</Text>
                <Text small light block style={{flex:1, marginRight:8}}>Rate</Text>
                <Text small light block style={{flex:1, marginRight:8}}>Commission %</Text>
                <Text small light block style={{flex:1, marginRight:32}}>Total</Text>
              </div>

              <InputArray
                name="lineItems"
                addValue={{
                  date: data.invoice.date || Date.now(),
                  rate: data.invoice.rate || data.invoice.client.rate || data.agency.billingRate,
                  commission: data.agency.billingCommission
                }}
                addLabel="Add Line Item">

                <InputDate
                  className="date" name="date" title="Date" hint={false}
                  style={{marginRight:8, flex:1}}
                  timezone={timezone}
                />
                <InputTalent
                  style={{marginRight:8, flex:2}}
                />
                <InputSelect
                  name="type"
                  strict
                  options={map(data.agency.billingTypes, t => t.label)}
                  style={{marginRight:8, flex:1}}
                />
                <InputText
                  name="comment"
                  style={{marginRight:8, flex:1}}
                />
                <InputTime
                  name="timeFrom"
                  date={data.invoice.date || Date.now()}
                  style={{marginRight:8, flex:1}}
                  timezone={timezone}
                />
                <InputTime
                  name="timeTo"
                  date={data.invoice.date || Date.now()}
                  style={{marginRight:8, flex:1}}
                  timezone={timezone}
                />
                <InputNumber
                  name="hours"
                  style={{marginRight:8, flex:1}}
                />
                <InputNumber
                  name="rate"
                  style={{marginRight:8, flex:1}}
                />
                <InputCommission/>
                <InputNumber
                  name="total"
                  style={{flex:1}}
                />
              </InputArray>
            </div>
          </div>

          <div className="total-wrapper">
            <div className="totals">
              <Text small style={{marginRight:16}}>Subtotal</Text>
              <Text large>{currencyFormatter.format(data.invoice.subtotal || 0)}</Text>
              <Text small style={{marginRight:16}}>Agency Fee</Text>
              <Text large>{currencyFormatter.format(data.invoice.agencyFeeTotal || 0)}</Text>
              <Text small style={{marginRight:16}}>Total</Text>
              <Text large bold>{currencyFormatter.format(data.invoice.total || 0)}</Text>
              <Text small style={{marginRight:16}}>Commission</Text>
              <Text large>{currencyFormatter.format(data.invoice.commissionTotal || 0)}</Text>
            </div>
          </div>

        </Form>
      </Card>
    </Container>
  );
}