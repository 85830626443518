import React, { useContext } from 'react';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import {
  useRouter,
  Text,
  Rule,
  Button,
  Icon,
  ThemeContext,
  Card,
  Loading,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';

export default function AccountsReceivableList(props) {


  // Manage Data

  const route = useRouter();
  const {history, match:{ url, params:{ clientId }}} = route;

  const data = useQuery(`{
    agency {
      timezone
    }
    credits(clientId:$clientId) {
      creditId
      dateCheck
      dateDeposit
      talentName
      talentImage
      packName
      packImage
      invoiceNumber
      amount
      commission
    }
  }`, {clientId});

  let timezone = data?.agency?.timezone || moment.tz.guess();


  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }


  const CreateButton = () => (
    <Button
      onClick={() => {
        history.push("/billing/accounts-receivable/create", {
          referrer: url,
          initialData: {
            clientId,
            date: Date.now(),
          }
        });
      }}
      style={{
        background:theme.color.grey,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        padding: 0,
        borderRadius: 0,
      }}>
      <Icon name="plus" color="white"/>
    </Button>
  );


  if(isEmpty(data.credits)) {
    return (
      <div>
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>PAYMENTS</Text>
          <div style={{flex:1}}/>
          <CreateButton/>
        </div>
        <Rule style={{marginBottom:24}}/>
        <div style={{textAlign:'center'}}>
          <div>
            <Icon name="money-check" size={80} color="accentLighter" style={{marginBottom:16, width:"auto"}}/>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{width:"100%"}}>
      <div>
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>PAYMENTS</Text>
          <div style={{flex:1}}/>
          <CreateButton/>
        </div>
        <Rule style={{marginBottom:24}}/>
      </div>

      <div style={{width:'100%', overflowX:'auto'}}>

        <div style={{minWidth:900}}>

          <div style={{display:'flex', alignItems:'center', padding:"0 8px 8px 8px"}}>
            <Text block small light style={{flex:1}}>Check Date</Text>
            <Text block small light style={{flex:1}}>Deposit Date</Text>
            <Text block small light style={{flex:1}}>Talent</Text>
            <Text block small light style={{flex:1}}>Package</Text>
            <Text block small light style={{flex:1}}>Invoice</Text>
            <Text block small light style={{flex:1}}>Commission</Text>
            <Text block small light style={{flex:1}}>Amount</Text>
          </div>

          {data.credits.map(credit => (
            <div key={credit.creditId} style={{display:'flex', alignItems:'center'}}>
              <Card
                key={credit.creditId}
                style={{
                  flex:1,
                  display:'flex',
                  alignItems:'center',
                  padding: '0 8px',
                  marginBottom:4,
                  cursor:'pointer',
                  height:40,
                }}
                onClick={() => props.history.push(credit.creditId)}>

                <Text block style={{flex:1}}>{moment(credit.dateCheck).tz(timezone).format("MM/DD/YYYY")}</Text>
                <Text block style={{flex:1}}>{moment(credit.dateDeposit).tz(timezone).format("MM/DD/YYYY")}</Text>
                <div style={{flex:1}}>
                  <div style={{flex:1, display:'flex', alignItems:'center'}}>
                    {credit.talentImage &&
                      <img src={credit.talentImage} alt="headshot" style={{width:40, height:40, marginRight:8}}/>
                    }
                    <Text>{credit.talentName}</Text>
                  </div>
                </div>
                <div style={{flex:1}}>
                  <div style={{flex:1, display:'flex', alignItems:'center'}}>
                    {credit.packImage &&
                      <img src={credit.packImage} alt="headshot" style={{width:40, height:40, marginRight:8}}/>
                    }
                    <Text>{credit.packName}</Text>
                  </div>
                </div>
                <Text block style={{flex:1}}>{credit.invoiceNumber}</Text>
                <Text block style={{flex:1}}>${parseFloat(credit.amount * ((credit.commission || 0)/100) || 0, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
                <Text block bold style={{flex:1}}>${parseFloat(credit.amount || 0, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>

              </Card>

            </div>
          ))}

        </div>

      </div>

    </div>
  );
}
