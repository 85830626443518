import React, { Component } from 'react';
import { connect } from 'react-redux';
import { range, debounce, get as getProperty } from 'lodash';
import { baseTalentFields } from 'config';
import {
  setAgencyTalentsSearchMode,
  loadMoreAgencyTalentsSearchResults,
  searchAgencyTalents,
  downloadAgencyTalentSearch,
  loadAgencies,
} from 'actions';

import "./index.css";

import { Redirect } from 'react-router-dom';
// import Card from 'ui/Card';
import Container from 'ui/Container';
import Title, { Action, Filter, Tab } from 'ui/Title';
import Icon from 'ui/Icon';
import TalentSearch from 'ui/TalentSearch';
import TalentCard from 'ui/TalentCard';
import TalentSelectDialog from './TalentSelectDialog';
import MessageTalentDialog from 'ui/MessageTalentDialog';
import MessageAgencySearchTalentsDialog from 'ui/MessageAgencySearchTalentsDialog';

import { InputSelect } from 'skybolt-ui';


// TODO: get rid of these.
import Paper from 'material-ui/Paper';
import OldTalentCard from 'ui/OldTalentCard';


const mapStateToProps = (state, ownProps) => {
  const agency = getProperty(state, `agencies.all[${state.user.agencyId}]`, {});
  const talentFields = {...baseTalentFields, ...agency.talentFields};


  const searchCount = state.agencyTalents.searchCount;
  const activeDivision = state.user.activeDivision;
  let subTitle = "";
  if(searchCount) {
    subTitle += `${searchCount} talent `;
  }
  if(activeDivision) {
    subTitle += `in ${activeDivision}`;
  }

  const agencyIds = state.user.agencyIds || [];
  const agencies = agencyIds.map(id => state.agencies.all[id]).filter(agency => !!agency);

  const showTalentAgencies = state.user.role !== 'agent';
  const isLoggedIn = state.user.isLoggedIn;
  const canMessage = !!state.user.permissions.canMessageTalents;
  const canAddTalents = !!state.user.permissions.canAddTalents;
  const canMessageAllTalents = !!state.user.permissions.canMessageTalents;
  const canAccess = isLoggedIn && (!!state.user.permissions.canPack || !!state.user.permissions.canViewTalents);
  const canSelect = !!state.user.permissions.canPack;
  const isSearching = !!state.agencyTalents.search.query || !!state.agencyTalents.search.age || !!state.agencyTalents.search.gender;
  const canEdit = state.user.permissions.canEditTalents || state.user.permissions.canAdminTalents;


  return {
    isLoggedIn,
    canAccess,
    canSelect,
    canMessage,
    canEdit,
    canAddTalents,
    agencies,
    agencyIds,
    showTalentAgencies,
    genderField: talentFields['gender'],
    ageField: talentFields['age'],
    mode: state.agencyTalents.searchMode,
    searchResults: state.agencyTalents.searchResults,
    subTitle,
    search: state.agencyTalents.search,
    canMessageAllTalents,
    isSearching,
  };
};

const mapDispatchToProps = {
  loadMoreAgencyTalentsSearchResults,
  searchAgencyTalents,
  setAgencyTalentsSearchMode,
  downloadAgencyTalentSearch,
  loadAgencies,
};

class AgencyTalents extends Component {

  state = {
    isSelectDialogOpen: false,
    isMessageTalentsDialogOpen: false,
    isMessageSearchTalentsDialogOpen: false,
    talentId: null,
    search: {},
  }

  componentDidMount() {
    if(!this.props.canAccess) {
      return;
    }
    this.props.loadAgencies(this.props.agencyIds);

    this.search(this.props.search);

    document.body.addEventListener('scroll', this.handleDocumentScroll);
  }

  componentWillUnmount() {
    document.body.removeEventListener('scroll', this.handleDocumentScroll);
  }

  handleDocumentScroll = e => {
    const body = document.body;
    const html = document.documentElement;
    const scrollY = body.scrollTop;
    const windowHeight = window.innerHeight;

    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    if(documentHeight - (scrollY + windowHeight) < 300) {
      this.props.loadMoreAgencyTalentsSearchResults();
    }
  }

  handleSelectTalent = (e, talentId) => {
    this.setState({
      isSelectDialogOpen: true,
      talentId,
    });
  }

  handleMessageTalent = talentId => {
    this.setState({
      isMessageTalentsDialogOpen: true,
      talentId,
    });
  }

  downloadTalentCSV = () => {
    this.props.downloadAgencyTalentSearch();
  }

  closeSelectDialog = () => this.setState({isSelectDialogOpen:false})
  closeMessageDialog = () => this.setState({isMessageTalentsDialogOpen:false})
  closeMessageSearchDialog = () => this.setState({isMessageSearchTalentsDialogOpen:false})

  searchAgencyTalents = debounce(() => {
    this.props.searchAgencyTalents(this.state.search);
  }, 300)

  searchAgency = agencyId => {
    this.search({...this.state.search, agencyId});
  }

  search = search => {
    this.setState({search}, this.searchAgencyTalents);
  }

  addTalent = () => {
    this.props.history.push(`admin/invite-talent`);
  }

  advanced = () => {
    this.props.setAgencyTalentsSearchMode('advanced');
  }

  simple = () => {
    this.setState({search:{}}, () => this.props.setAgencyTalentsSearchMode('simple'));
  }

  render() {
    if(!this.props.canAccess) {
      return <Redirect to="/"/>;
    }

    const {
      mode,
      ageField,
      genderField,
      searchResults,
      subTitle,
      canSelect,
      canEdit,
      canAddTalents,
      canMessage,
      showTalentAgencies,
      agencies,
      isSearching,
    } = this.props;

    const search = this.state.search;


    // Do we hide the age search?
    // - if search is showing a specific agency, look at that agencies settings, or
    // - go through all agency settings.
    let showAgeSearch = false;
    if(!!search.agencyId) {
      const agency = agencies.find(a => a.id === search.agencyId);
      showAgeSearch = agency && agency.showTalentDOBPublic !== false;
    }
    else {
      showAgeSearch = true;
      for(const agency of agencies) {
        if(agency.showTalentDOBPublic === false) {
          showAgeSearch = false;
        }
      }
    }

    const {
      isSelectDialogOpen
    } = this.state;

    const actions = [];
    const filters = [];
    const tabs = [];
    let onSearch = null;

    if(mode !== 'advanced') {
      onSearch = query => this.search({...search, query});

      if(ageField && showAgeSearch) {
        filters.push(
          <Filter
            label="Age"
            value={search.age}
            options={range(ageField.min, ageField.max, ageField.step || 1)}
            onChange={age => this.search({...search, age})}
            style={{minWidth:100}}
          />
        );
      }

      if(genderField) {
        filters.push(
          <Filter
            label="Gender"
            value={search.gender}
            options={genderField.options}
            onChange={gender => this.search({...search, gender})}
            style={{minWidth:120}}
          />
        );
      }

      filters.push(
        <InputSelect
          placeholder="Sort By"
          value={search.sortBy}
          onChange={sortBy => this.search({...search, sortBy})}
          options={[{label:"Last Name", value:"lastName"}, {label:"Date Created", value:"dateCreated"}]}
          style={{margin:0, height:38, backgroundColor:'transparent'}}
        />
      );

      actions.push(
        <Action
          icon={<Icon name="close" size={12}/>}
          tooltip="Clear"
          onClick={this.simple}
          style={{
            width: 18,
            height: 18,
            borderRadius: 9,
            marginBottom: 6,
            opacity: isSearching ? 1 : 0,
          }}
        />
      );

      actions.push(
        <Action
          icon={<Icon name="tune"/>}
          tooltip="Advanced Search"
          onClick={this.advanced}
        />
      );
    }
    else {
      actions.push(
        <Action
          icon={<Icon name="close"/>}
          tooltip="Clear Search"
          onClick={this.simple}
        />
      );
    }

    if(this.props.canMessageAllTalents) {
      actions.push(
        <Action
          tooltip={"Message Talent"}
          icon="multimessage"
          onClick={() => this.setState({isMessageSearchTalentsDialogOpen:true})}
        />
      );
    }

    if(this.props.canEdit) {
      actions.push(
        <Action
          tooltip={"Download Talent"}
          icon="download"
          onClick={this.downloadTalentCSV}
        />
      );
    }

    if(canAddTalents) {
      actions.push(
        <Action
          tooltip={"Add Talent"}
          icon="plus"
          onClick={this.addTalent}
        />
      );
    }

    if(agencies.length > 0) {
      tabs.push(
        <Tab label="All" isActive={!search.agencyId} onClick={() => this.searchAgency(null)}/>
      );
      agencies.forEach(agency => {
        tabs.push(
          <Tab label={agency.name} isActive={search.agencyId === agency.id} onClick={() => this.searchAgency(agency.id)}/>
        );
      });
    }


    return (
      <div className="agencytalents-wrapper">
        <Container>
          <Title
            search={search.query}
            onSearch={onSearch}
            actions={actions}
            tabs={tabs}
            subTitle={subTitle}
            filters={filters}>
            Talent
          </Title>
        </Container>

        {mode === 'advanced' &&
          <Container className="agencytalents-advancedsearch">
            <TalentSearch
              search={search}
              agencyId={this.state.search.agencyId}
              onSearch={search => this.search(search)}
            />
          </Container>
        }

        <Container className="agencytalents-talents">
          {Object.keys(searchResults)
            .map(talentId => searchResults[talentId])
            .sort((a, b) => a.order < b.order ? -1 : 1)
              .map(talent => {

                if(!canEdit) {
                  return (
                    <Paper key={talent.id}>
                      <OldTalentCard
                        view="medium"
                        talentId={talent.id}
                        talent={talent}
                        onSelect={canSelect && this.handleSelectTalent}
                        onClick={() => this.props.history.push({pathname:`/talents/${talent.id}/info`, state:{modal:true}})}
                      />
                    </Paper>
                  );
                }

                return (
                  <TalentCard
                    key={talent.id}
                    talentId={talent.id}
                    onClick={(e, tab="info") => {
                      this.props.history.push({
                        pathname:`/talents/${talent.id}/${tab}`,
                        state:{
                          modal:true
                        }
                      });
                    }}
                    onNewMessageClick={canMessage ? () => this.handleMessageTalent(talent.id) : null}
                    onSelect={canSelect && this.handleSelectTalent}
                    showAgency={showTalentAgencies}
                  />
                );
              }
          )}
        </Container>

        <TalentSelectDialog
          title="Add talent to..."
          modal={false}
          open={isSelectDialogOpen}
          onRequestClose={this.closeSelectDialog}
          talentId={this.state.talentId}
          history={this.props.history}
        />

        <MessageTalentDialog
          open={this.state.isMessageTalentsDialogOpen}
          onRequestClose={this.closeMessageDialog}
          talentId={this.state.talentId}
        />

        <MessageAgencySearchTalentsDialog
          open={this.state.isMessageSearchTalentsDialogOpen}
          onRequestClose={this.closeMessageSearchDialog}
        />

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyTalents);