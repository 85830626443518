import React, { useState } from 'react';
import { useQuery, createTalent, invite } from 'skybolt-api';
import { Container, Card, Form, InputText, InputSelect, Text, Rule, Button, useRouter } from 'skybolt-ui';

export default function() {

  const { history } = useRouter();

  const data = useQuery(`{
    agency {
      agencyId,
      divisions
    }
    user {
      permissions {
        canAddTalents
      }
    }
  }`);

  const [value, setValue] = useState({});

  if(!data) {
    return null;
  }

  if(!data.user.permissions.canAddTalents) {
    return null;
  }

  let divisions = [];
  if(Array.isArray(data.agency.divisions)) {
    divisions = data.agency.divisions;
  }

  async function send() {
    const talent = await createTalent({
      ...value,
      userId: null,
      agencyId: data.agency.agencyId || "skybolt",
      approved: true,
    });
    const invitationId = await invite({
      agencyId: data.agency.agencyId || "skybolt",
      talentId: talent.talentId,
      email: value.email,
      user: {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        role: 'talent',
        permissions: {
          canOwnTalents: true
        }
      }
    });
    history.replace(`/admin/invite-talent/sent/${invitationId}`);
  }

  return (
    <Container>
      <div style={{paddingTop:32}}>
        <Text title>INVITE TALENT</Text>
      </div>
      <Rule style={{marginBottom:24}}/>
      <Card style={{padding:16}}>
        <Form onChange={setValue} value={value} style={{maxWidth:600}}>
          <InputText title="First Name" name="firstName"/>
          <InputText title="Last Name" name="lastName"/>
          <InputText title="Email Address" name="email"/>
          {!!divisions.length && <InputSelect options={divisions} multiple title="Divisions" name="divisions"/>}
          <Button accent raised onClick={send}>Send Invitation</Button>
        </Form>
      </Card>
    </Container>
  );
}