import { combineReducers } from "redux";


const defaultSearch = {
  query: "",
};

const search = (state=defaultSearch, action) => {
  switch(action.type) {
    case 'SET_AGENCY_CONTACTS_SEARCH':
      return action.search;
    
    case 'CLEAR_AGENCY_CONTACTS_SEARCH':
    case 'LOGOUT':
    case 'SET_AGENCY':
      return defaultSearch;
    
    default: 
      return state;
  }
};

const searchResults = (state={}, action) => {
  switch(action.type) {
    case 'SET_AGENCY_CONTACTS_SEARCH_RESULTS':
      return action.contacts;
      
    case 'ADD_AGENCY_CONTACTS_SEARCH_RESULTS':
      return {...state, ...action.contacts};
    
    case 'CONTACT':
      let contact = state[action.id] || {};
      contact = {
        ...contact,
        ...action.contact,
      };
      return {
        ...state,
        [action.id]: contact,
      };
    
    case 'REMOVE_CONTACT': 
      const { [action.id]:removedContact, ...rest } = state;
      return rest;
    
    case 'LOGOUT':
    case 'CLEAR_AGENCY_CONTACTS_SEARCH':
    case 'SET_AGENCY':
      return {};
    
    default: 
      return state;
  }
};

const searchCount = (state=null, action) => {
  switch(action.type) {
      
    case 'SET_AGENCY_CONTACTS_SEARCH_RESULTS':
      if(action.count) {
        return action.count;
      }
      return state;
    
    case 'CLEAR_AGENCY_CONTACTS_SEARCH':
    case 'SET_AGENCY':
    case 'LOGOUT':
      return null;
    
    default: 
      return state;
  }
};

const isSearching = (state=false, action) => {
  switch(action.type) {
    case 'SET_AGENCY_CONTACTS_SEARCH':
      return true;
    
    case 'CLEAR_AGENCY_CONTACTS_SEARCH':
    case 'SET_AGENCY_CONTACTS_SEARCH_RESULTS':
    case 'ADD_AGENCY_CONTACTS_SEARCH_RESULTS':
    case 'LOGOUT':
    case 'SET_AGENCY':
      return false;
      
    default:
      return state;
  }
};

export default combineReducers({
  isSearching,
  search,
  searchResults,
  searchCount,
});