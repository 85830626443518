import React from 'react';
// import IconButton from 'material-ui/IconButton';
import Icon from 'ui/Icon';
import { isString } from 'lodash';

export default ({
  icon = "add",
  hidden = false,
  disabled = false,
  style={},
  ...props
}) => {
  if(hidden) {
    return null;
  }

  if(isString(icon)) {
    icon = <Icon name={icon} size={24}/>;
  }

  return (
    <div
      className="sui-title-action"
      // tooltipPosition="top-center"
      style={{
        ...style,
      }}
      {...props}>

      {React.cloneElement(icon, {
        color: disabled ? "#E0E0E0" : "white"
      })}

    </div>
  );
};