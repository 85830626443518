/*

The talent primary navigation.

This shows the currently active talent, and a menu of all talent.

*/

import React, { useContext, useRef, useState } from 'react';
import { useQuery, updateUser } from 'skybolt-api';
import { Icon, Menu, Button, Text, Rule, ThemeContext } from 'skybolt-ui';
import NavButton from './NavButton';

export default function() {

  const data = useQuery(`{
    user {
      permissions {
        canOwnTalents
      }
      activeTalent {
        fullName
        talentId
        image(height:60, width:60, face:true)
      }
      talents {
        fullName
        image(width:40, height:40, face:true)
        talentId
        status
      }
    }
  }`);

  const theme = useContext(ThemeContext);
  const anchor = useRef();
  const [isOpen, setOpen] = useState(false);
  const close = () => setOpen(false);
  const open = () => setOpen(true);

  if(!data) {
    return null;
  }

  if(!data.user.talents.length) {
    return (
      <div key="talent" style={{display:'flex'}} ref={anchor}>
        <NavButton href={`/create-talent`} style={{padding:'24px 2px 20px 16px'}}>Create Talent</NavButton>
      </div>
    );
  }


  // Split user talents into
  // - active talents,
  // - inactive talents, and
  // - the most recently used talent.

  const activeTalents = data
    ? data.user.talents.filter(talent =>
        talent.status === 'PAID' ||
        talent.status === 'TRIAL' ||
        talent.status === 'INACTIVE' ||
        talent.status === 'EXPIRED'
      )
    : [];

  const activeTalent = data.user.activeTalent || activeTalents[0];

  const incompleteTalents = data
    ? data.user.talents.filter(t => t.status === "INCOMPLETE")
    : [];


  // The buttons to navigate to the active user,
  // or open the talents menu.

  const buttons = (
    <div key="talent" style={{display:'flex'}} ref={anchor}>
      <img
        style={{flexShrink:0, flexGrow:0, width:60, height:60, marginRight:4}}
        src={activeTalent.image}
        alt="headshot"
      />
      <NavButton href={`/talents/${activeTalent.talentId}`} style={{padding:'24px 2px 20px 16px'}}>{activeTalent.fullName}</NavButton>
      <NavButton onClick={open} style={{padding:'24px 16px 20px 2px'}}><Icon name="caret" small color={theme.color.lightTextDark}/></NavButton>
    </div>
  );



  // The menu shows all this user's active talents to choose from.
  // Shows buttons to continue any incomplete talent setups.

  function clickTalent(activeTalent) {
    updateUser({data:{activeTalent}});
  }

  const menu = (
    <Menu
      key="menu"
      style={{width:250, paddingTop:8, paddingBottom:16, borderTopLeftRadius:0, borderTopRightRadius:0, background:theme.color.primaryDarker, borderTop:'1px solid #aaa'}}
      anchor={anchor}
      direction="down right"
      open={isOpen}
      onRequestClose={close}>

      {activeTalents.map(talent =>
        <Button key={talent.talentId} href={`/talents/${talent.talentId}`} onClick={() => clickTalent(talent.talentId)} style={{boxSizing: 'border-box', display: 'flex', alignItems: 'center', padding:"2px 4px 2px 20px"}} color={theme.color.lightText}>
          <img style={{flex:0, marginRight:16}} src={talent.image} width="40" height="40" alt="headshot"/>
          <Text style={{flex:1}} color={theme.color.lightText}>{talent.fullName}</Text>
        </Button>
      )}
      <Rule color={theme.color.lightTextDarker} style={{margin:"16px 0"}}/>

      {incompleteTalents.length > 0 ? (
        incompleteTalents.map(talent =>
          <Button key={talent.talentId} icon="plus" style={{display:'block', paddingLeft:20, color:theme.color.lightText}} href={`/talents/${talent.talentId}`} label={`Continue ${talent.fullName || "New Talent"}`}/>
        )
      ) : (
        <Button icon="plus" style={{display:'block', paddingLeft:20, color:theme.color.lightText}} href="/create-talent" label="Add Talent"/>
      )}

    </Menu>
  );

  return [buttons, menu];
}