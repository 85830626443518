import React from 'react';
import { Route, Redirect, Switch, useRouter } from 'skybolt-ui';

import Spotlight from './Spotlight';
import Agency from './Agency';

export default props => {
  const {match:{ path }} = useRouter();

  return (
    <Switch>
      <Route path={`${path}/agency/:agencyId/label/:tag`} component={Agency}/>
      <Route path={`${path}/agency/:agencyId/division/:division`} component={Agency}/>
      <Route path={`${path}/agency/:agencyId`} component={Agency}/>
      {/*<Route path={`${path}/spotlight/:spotlightId`} component={Spotlight}/>*/}
      <Redirect to="/"/>
    </Switch>
  );
};