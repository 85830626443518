import { combineReducers } from "redux";
import { clone } from 'lodash';

const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_MESSAGES_SUCCESS':
      const newState = clone(state);
      for(const key in action.messages) {
        newState[key] = action.messages[key];
      }
      return newState;
      
    case 'ADD_MESSAGE':
      return {
        ...state,
        [action.id]: action.message,
      };
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};


const byThread = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_MESSAGES_SUCCESS':
      return {
        ...state,
        [action.threadId]: action.messages,
      };
      
    case 'ADD_MESSAGE':
      return {
        ...state,
        [action.threadId]: {
          ...state[action.threadId],
          [action.messageId]: action.message,
        }
      };
    
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};




export default combineReducers({
  all,
  byThread,
});