import React from 'react';

import "./index.css";

import TagInput from 'ui/TagInput';
import TextField from 'material-ui/TextField';
import Title from 'ui/Title';
import Page from 'ui/Page';

export default props => {
  const emitUpdate = (key, val) => props.onUpdate({[key]:val});

  return (
    <div className="agency-info" style={{padding:"0 0 40px 0"}}>

      <Title>Agency</Title>

      <Page style={{padding:"0 16px 16px 16px"}}>
        <div>
          <TextField
            hintText=""
            floatingLabelText="Agency Name"
            value={props.agency.name}
            onChange={(e, val) => emitUpdate('name', val)}
          />
        </div>

        <div>
          <TextField
            hintText=""
            floatingLabelText="Contact Name"
            value={props.agency.contactName}
            onChange={(e, val) => emitUpdate('contactName', val)}
          />
        </div>

        <div>
          <TextField
            hintText=""
            floatingLabelText="Contact Email"
            value={props.agency.contactEmail}
            onChange={(e, val) => emitUpdate('contactEmail', val)}
          />
        </div>

        <div>
          <TextField
            hintText=""
            floatingLabelText="Display Email"
            value={props.agency.displayEmail || ""}
            onChange={(e, val) => emitUpdate('displayEmail', val)}
          />
        </div>

        <div>
          <TextField
            hintText=""
            floatingLabelText="Primary Phone"
            value={props.agency.primaryPhone}
            onChange={(e, val) => emitUpdate('primaryPhone', val)}
          />
        </div>

        <div>
          <TextField
            hintText=""
            floatingLabelText="Street"
            value={props.agency.street}
            onChange={(e, val) => emitUpdate('street', val)}
          />
        </div>

        <div>
          <TextField
            hintText=""
            floatingLabelText="City"
            value={props.agency.city}
            onChange={(e, val) => emitUpdate('city', val)}
          />
        </div>

        <div>
          <TextField
            hintText=""
            floatingLabelText="State"
            value={props.agency.state}
            onChange={(e, val) => emitUpdate('state', val)}
          />
        </div>

        <div>
          <TextField
            hintText=""
            floatingLabelText="Zip"
            value={props.agency.zip}
            onChange={(e, val) => emitUpdate('zip', val)}
          />
        </div>

        <div>
          <TextField
            hintText=""
            floatingLabelText="Website"
            value={props.agency.website}
            onChange={(e, val) => emitUpdate('website', val)}
          />
        </div>

        {props.showNotes &&
          <div>
            <TextField
              hintText=""
              floatingLabelText="Notes"
              multiLine={true}
              fullWidth
              value={props.agency.notes || ""}
              onChange={(e, val) => emitUpdate('notes', val)}
            />
          </div>
        }
      </Page>

      <Title>Divisions</Title>
      <Page style={{padding:"8px 16px 16px 16px"}}>
        <TagInput
          tags={props.agency.divisions}
          onChange={divisions => emitUpdate('divisions', divisions)}
        />
      </Page>


      <Title>Talent Labels</Title>
      <Page style={{padding:"8px 16px 16px 16px"}}>
        <TagInput
          tags={props.agency.talentTags}
          onChange={tags => emitUpdate('talentTags', tags)}
        />
      </Page>


      <Title>Image Tags</Title>
      <Page style={{padding:"8px 16px 16px 16px"}}>
        <TagInput
          tags={props.agency.mediaTags}
          onChange={tags => emitUpdate('mediaTags', tags)}
        />
      </Page>

    </div>
  );
};