import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import muiThemeable from 'material-ui/styles/muiThemeable';
import {
  videoFormats,
  imageFormats,
  audioFormats,
  documentFormats,
} from 'config';
import {
  updateMedia,
  removeMedia,
  rotateMedia,
  updateTalent,
  approveMedia,
  uploadTalentMedia,
  linkTalentMedia,
  loadUser,
} from 'actions';

import { Text, Card, Button, Icon } from 'skybolt-ui';
import UIIcon from 'ui/Icon';
import Media from 'ui/Media';
import UIButton from 'ui/Button';
import { Modal } from 'skybolt-ui';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';



export const mapStateToProps = (state, ownProps) => {
  let divisions,
      tags,
      media,
      talentId,
      agency,
      requireApproval=false,
      canApprove=false,
      canTag=true,
      canChangeHeadshot=true,
      canSpotlight=false,
      talent=null,
      useStripe=false,
      user=null,
      canSetDivision = state.user.role === 'agent';

  if(ownProps.mediaId) {
    media = state.media.all[ownProps.mediaId];
  }

  talentId = media ? media.talentId : ownProps.talentId;

  if(talentId) {
    talent = state.talents.all[talentId] || {};
    agency = state.agencies.all[talent.agencyId] || {};
    divisions = agency.divisions || [];
    tags = agency.mediaTags || [];
    requireApproval = agency.requireTalentMediaApproval;
    canApprove = state.user.permissions.canApproveMedia;
    canChangeHeadshot = agency.allowTalentChangeHeadshot !== false || state.user.role === 'agent';
    user = state.users.all[talent.userId];
    useStripe = agency.useStripe;
    canTag = canSetDivision || agency.allowTalentToTagMedia !== false;
    canSpotlight = state.user.permissions.canSpotlight;
  }

  return {
    talentId,
    media,
    agency,
    divisions,
    tags,
    requireApproval,
    canApprove,
    canTag,
    talent,
    canChangeHeadshot,
    canSetDivision,
    canSpotlight,
    useStripe,
    user,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  updateMedia,
  removeMedia,
  rotateMedia,
  updateTalent,
  approveMedia,
  uploadTalentMedia,
  linkTalentMedia,
  loadUser,
};





export class TalentMediaDialog extends Component {

  static defaultProps = {
    onRemove: ()=>null,
    onRequestClose: ()=>null,
  }

  state = {
    isLinking: false,
    isUploading: false,
    isVideoEnabled: true,
    uploadProgress: 0,
    uploadError: null,
    linkURL: "",
    file: null,
    fileURL: "",
    fileError: null,
    media: {
      title: "",
      date: Date.now(),
      tags: [],
      order: 0,
      active: true,
    },
  }

  async componentDidUpdate() {
    if(this.props.open && this.props.division && !this.state.media.divisions) {
      this.setState({
        media: {
          ...this.state.media, 
          divisions:[this.props.division]
        }
      });
    }

    if(!this.props.user && this.props.talent?.userId) {
      loadUser(this.props.talent?.userId);
    }

    let isVideoEnabled = this.state.isVideoEnabled;

    if(this.props.talent.canVideo !== undefined) {
      isVideoEnabled = !!this.props.talent.canVideo;
    }
    else if(this.props.useStripe && this.props.user?.stripeCustomerId && window.skyboltsnow) {
      try {
        isVideoEnabled = await window.skyboltsnow.canTalentFeature(this.props.talentId, 'video');
      }
      catch(error) {
        isVideoEnabled = false;
      }
    }

    if(isVideoEnabled != this.state.isVideoEnabled) {
      this.setState({isVideoEnabled});
    }

  }

  handleFileSelect = file => {
    const fileSize = ((file.size/1024)/1024).toFixed(4);
    if(fileSize > 100) {
      this.setState({
        fileError: "Maximum file size: 100mb",
      });
      return;
    }
    if(file.type.indexOf('video') > -1 && !this.state.isVideoEnabled) {
      this.setState({
        fileError: "Video upload are not available for your subscription",
      });
      return;
    }

    if(file.type.indexOf('image') > -1) {
      var fr = new FileReader();
      fr.onload = () => {
        this.setState({fileURL: fr.result});
      };
      fr.readAsDataURL(file);
    }
    this.setState({file});
  }





  save = async () => {
    if(!this.state.file && !this.state.linkURL) {
      return;
    }

    if(!this.props.talentId) {
      this.close();
    }

    if(this.state.isUploading) {
      return;
    }

    if(this.state.file) {
      this.setState({isUploading:true, uploadError:null});
      this.props.uploadTalentMedia(this.props.talentId, this.state.file, this.state.media, uploadProgress => this.setState({uploadProgress}))
        .then(() => {
          this.setState({isUploading:false}, this.close);
        })
        .catch(error => {
          console.log(error);
          this.setState({uploadError:true, isUploading:false});
        });
    }

    if(this.state.linkURL) {
      this.props.linkTalentMedia(this.props.talentId, this.state.linkURL, this.state.media)
        .then(() => {
          this.close();
        })
        .catch(error => {
          console.log(error);
          this.setState({uploadError:true, isUploading:false});
        });
    }

    // return media;
  }

  trash = () => {
    if(this.props.media && this.props.media.id) {
      this.props.removeMedia(this.props.media.id);
    }
    this.close();
  }

  close = () => {
    if(this.state.isUploading) {
      return;
    }

    this.setState({
      isUploading: false,
      isLinking: false,
      linkURL: "",
      file: null,
      fileURL: "",
      media: {
        title: "",
        date: Date.now(),
        tags: [],
        order: 0,
        active: true,
      },
    });
    this.props.onRequestClose();
  }

  rotate = () => {
    if(!this.props.media || !this.props.media.id) {
      return;
    }

    this.props.rotateMedia(this.props.media.id);
  }

  useAsHeadshot = () => {
    if(!this.props.canChangeHeadshot) {
      this.setState({message:"Your Agency has restricted the selection of headshots."});
      return;
    }

    if(!this.props.media || !this.props.talentId) {
      return;
    }
    this.props.updateTalent(this.props.talentId, {image:this.props.media});
  }

  update = data => {
    if(!this.props.media || !this.props.media.id) {
      this.setState({media: {...this.state.media, ...data}});
      return;
    }

    this.props.updateMedia(this.props.media.id, data);
  }






  renderMedia() {

    if(this.state.isLinking) {
      return (
        <form
          className="mediadialog-media-form"
          onSubmit={() => this.setState({isLinking:false})}>

          <TextField
            fullWidth
            hintText="Enter YouTube or Vimeo URL"
            value={this.state.linkURL}
            onChange={(e, linkURL) => this.setState({linkURL})}
            autoFocus={true}
          />

          <div style={{display:'flex'}}>
            <UIButton
              label="Cancel"
              onClick={() => this.setState({isLinking:false, linkURL:""})}
            />

            <div style={{flex:1}}/>

            <UIButton
              label="Link"
              raised={true}
              primary={true}
              onClick={() => this.setState({isLinking:false})}
            />

          </div>

        </form>
      );
    }

    if(this.state.linkURL && !this.state.isLinking) {
      return (
        <Media
          media={{
            type: 'link',
            format: this.state.linkURL.indexOf("vimeo") > -1 ? 'vimeo' : 'youtube',
            url: this.state.linkURL,
          }}
          width={300}
        />
      );
    }

    if(this.state.file && this.state.file.type.indexOf('video') > -1) {
      return <UIIcon name="media" size="60" color="white"/>;
    }

    if(!!this.state.file && !this.state.fileURL) {
      // return <Loading/>;
      return <UIIcon name="media" size="60" color="white"/>;
    }

    if(this.state.fileURL) {
      return <img alt="Headshot" src={this.state.fileURL} style={{width:300}}/>;
    }
    
    if(!this.props.media) {
      return (
        <div>
          <div style={{display:'flex', justifyContent:'center'}}>
            <UIButton
              floating={true}
              icon="upload"
              tooltip={
                this.state.isVideoEnabled
                  ? "Upload Photo, Video, Audio, or Document"
                  : "Upload Photo, Audio, or Document"
              }
              onClick={() => this.hiddenInput.click()}
              style={{margin:"0 8px", width:60, height:60}}
              iconSize={24}
            />

            {this.state.isVideoEnabled &&
              <UIButton
                floating={true}
                icon="link"
                tooltip="Link YouTube or Vimeo Video"
                onClick={() => this.setState({isLinking:true})}
                style={{margin:"0 8px", width:60, height:60}}
                iconSize={24}
              />
            }

            <input
              name="imageinput"
              ref={el => this.hiddenInput = el}
              type="file"
              accept={
                this.state.isVideoEnabled
                  ? [
                    ...audioFormats,
                    ...imageFormats,
                    ...videoFormats,
                    ...documentFormats,
                  ]
                  : [
                    ...imageFormats,
                    ...documentFormats,
                  ]

              }
              onChange={e => {
                this.handleFileSelect(e.target.files[0]);
              }}
              style={{position: 'absolute', left: -1000}}
            />
          </div>
          <div style={{paddingTop:24}}>
            {this.state.fileError ? (
              <Text centered block color="red" style={{margin:'0 16px', textAlign:'center'}}>
                {this.state.fileError}
              </Text>
            ) : (
              <Text centered block>Maximum file size: 100mb</Text>
            )}
          </div>

          {!this.state.isVideoEnabled &&
            <Card style={{padding:8, margin:"40px 16px 0 16px"}}>
              <div>
                <Text paragraph large accent><Icon name="video"/> Upload Video</Text>
                <Text paragraph>Add video to your profile. Upload unlimited video to your media library, or link YouTube and Vimeo videos.</Text>
              </div>
              <Button icon="video" label="Enable Addon" raised accent href={this.props.useStripe ? `/user/subscription` : 'addons'}/>
            </Card>
          }
        </div>
      );
    }

    return (
      <div>
        <Media media={this.props.media} width={300}/>
        <UIButton
          icon="rotate"
          style={{position:'absolute', bottom:16, right:16}}
          floating={true}
          tooltip="Rotate Image"
          tooltipPosition="top-center"
          onClick={this.rotate}
        />
        {this.props.canChangeHeadshot && 
          <UIButton
            icon="talent"
            style={{position:'absolute', bottom:16, right:64}}
            floating={true}
            tooltip="Use As Headshot"
            tooltipPosition="top-center"
            onClick={this.useAsHeadshot}
          />
        }
      </div>
    );
  }

  renderInfo() {
    const {
      canSetDivision,
      canTag,
      canSpotlight,
      canApprove,
      canChangeHeadshot,
      divisions,
      tags,
    } = this.props;
    const media = this.props.media || this.state.media;

    return (
      <div>

        <TextField
          fullWidth
          floatingLabelText="Title"
          value={media.title || ""}
          onChange={(e, title) => this.update({title})}
        />

        <DatePicker
          fullWidth
          floatingLabelText="Date"
          value={moment(media.date).toDate()}
          onChange={(e, date) => this.update({date:moment(date).valueOf()})}
          formatDate={date => moment(date).format("MMMM Do YYYY")}
        />

        {canSetDivision && !!divisions && divisions.length > 0 &&
          <div style={{maxWidth:'300px', overflow:'hidden'}}>
            <SelectField
              multiple
              fullWidth
              floatingLabelText="Divisions"
              value={media.divisions || []}
              onChange={(e, i, divisions) => this.update({divisions})}>
              {divisions.map(name =>
                <MenuItem
                  key={name}
                  insetChildren={true}
                  checked={media.divisions && media.divisions.indexOf(name) > -1}
                  value={name}
                  primaryText={name}
                />
              )}
            </SelectField>
          </div>
        }

        {!!tags && tags.length > 0 && canTag &&
          <div style={{maxWidth:'300px', overflow:'hidden'}}>
            <SelectField
              multiple
              fullWidth
              floatingLabelText="Tags"
              value={media.tags || []}
              onChange={(e, i, tags) => this.update({tags})}>
              {tags.map(name =>
                <MenuItem
                  key={name}
                  insetChildren={true}
                  checked={media.tags && media.tags.indexOf(name) > -1}
                  value={name}
                  primaryText={name}
                />
              )}
            </SelectField>
          </div>
        }

        <Checkbox
          label="Active"
          checked={!!media.active}
          onCheck={(e, active) => this.update({active})}
        />
        {canSpotlight && 
          <Checkbox
            label="Show in spotlights"
            checked={media.spotlight !== false}
            onCheck={(e, spotlight) => {
              this.update({spotlight:!!spotlight})
            }}
          />
        }
        {canApprove && 
          <Checkbox
            label="Private"
            checked={media.private}
            onCheck={(e, value) => {
              this.update({private: value ? true : null})
            }}
          />
        }

        {!canChangeHeadshot && 
          <p><em>Primary headshot selection is restricted to your agent.</em></p>
        }

      </div>
    );
  }

  renderActions() {
    if(!this.props.media) {
      let disabled = true;
      if(this.state.isUploading) {
        disabled = true;
      }
      else if(this.state.file) {
        disabled = false;
      }
      else if(this.state.linkURL && !this.state.isLinking) {
        disabled = false;
      }

      return (
        <div
          style={{
            marginRight: 32,
            marginBottom: 40,
            textAlign: 'right',
          }}>
          <UIButton
            disabled={disabled}
            label={this.state.isUploading ? `Uploading... ${this.state.uploadProgress || 0}%` : "Save"}
            raised={true} primary={true}
            onClick={this.save}
          />
          {this.state.uploadError &&
            <div style={{color:'red', fontSize:12, paddingTop:8}}>There was a problem. Please try again.</div>
          }
        </div>
      );
    }

    if(!this.props.media.approved && this.props.canApprove) {
      return [
        <UIButton
          key="approve"
          icon="approve"
          label="Approve"
          raised={true}
          onClick={() => this.props.approveMedia(this.props.media.id)}
          style={{
            marginRight: 8,
            marginBottom: 40,
          }}
        />,
        <UIButton
          key="reject"
          label="Reject"
          onClick={this.trash}
          style={{
            marginRight: 32,
            marginBottom: 40,
          }}
        />
      ];
    }

    return (
      <UIButton
        icon="trash"
        onClick={this.trash}
      />
    );
  }

  render() {
    return (
      <Modal
        status={!!this.props.open}
        onRequestClose={this.close}
        modalStyle={{maxWidth:740}}>

        <div className="mediadialog-content">
          <div className="mediadialog-media">
            {this.renderMedia()}
          </div>

          <div className="mediadialog-info">
            <div className="mediadialog-info-form">
              {this.renderInfo()}
            </div>
            <div className="mediadialog-actions">
              {this.renderActions()}
            </div>
          </div>
        </div>

        <Modal
          status={!!this.state.message}
          onRequestClose={() => this.setState({message:""})}
          modalStyle={{maxWidth:300}}>
          <div>
            {this.state.message}
          </div>
          <UIButton label="Ok" raised={true} primary={true} onClick={() => this.setState({message:""})}/>
        </Modal>

      </Modal>
    );
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(TalentMediaDialog));