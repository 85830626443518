import React, { useContext } from 'react';
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useQuery } from 'skybolt-api';
import { ThemeContext } from 'skybolt-ui';

import Media from 'ui/Media';
// import Loading from 'ui/Loading';
// import Icon from 'ui/Icon';



const mapStateToProps = (state, ownProps) => {
  const userId = state.user.id;
  const thread = ownProps.thread || state.threads.all[ownProps.threadId] || {};
  
  let recipients = Object.keys(thread.recipients || {})
    .map(id => thread.recipients[id])
    .reduce((all, r) => !userId || r.userId !== userId ? [...all, r.name || r.email] : all, [])
    .join(', ');
    
  if(!recipients) {
    recipients = "Me";
  }
    
  const subject = thread.subject;
  
  let body = null;
  if(thread.lastMessage) {
    const bodyEl = document.createElement("DIV");
    bodyEl.innerHTML = thread.lastMessage.body;
    body = bodyEl.textContent || bodyEl.innerText || null;
  }
  
  const time = moment(thread.dateUpdated).format('h:mma');

  let unread = Object.keys(state.unreadMessages.byThread[thread.id] || {}).length;
  if(unread > 99) {
    unread = "!";
  }
  
  const icon = "thread";

  const unsent = Object.keys(thread.receipts || {}).find(id => !thread.receipts[id].dateSent);
  const errors = Object.keys(thread.receipts || {}).find(id => !!thread.receipts[id].error);
  let status = !isEmpty(errors) ? 'ERROR' :
               !isEmpty(unsent) ? 'SENDING' :
               "SENT";
  
  return {
    unread,
    recipients,
    subject,
    body,
    status,
    time,
    icon,
    ...ownProps,
  };
};




const ThreadLI = ({
  thread,
  recipients,
  subject,
  body,
  status,
  time,
  icon,
  muiTheme:{ palette },
  onSelect,
}) => {
  
  
  const data = useQuery(`{
    unreadMessages(threadId:$threadId)
  }`, {threadId:thread.threadId});
  
  const theme = useContext(ThemeContext);
  
  const style = {};
  if(data && data.unreadMessages.length) {
    style.backgroundColor = theme.color.accentBackground;
  }
  
  
  return (
    
    <div className="threadslist-threadli" onClick={onSelect} style={style}>
      
      <Media
        width={48}
        height={49}
        icon={icon}
        iconSize={24}
        className="threadslist-threadli-image"
        style={{
          backgroundColor: "#9E9E9E",
          marginBottom:-1,
        }}
      />
      
      {!!data && data.unreadMessages.length ? (
        <div 
          className="threadslist-threadli-unread"
          style={{backgroundColor: palette.accent700}}>
          {data.unreadMessages.length}
        </div>
      ) : (
        <div className="threadslist-threadli-unreadplaceholder"/>
      )}
      
      <div className="threadslist-threadli-recipients">{recipients}</div>
      
      <div className="threadslist-threadli-preview">
        <span className="threadslist-threadli-subject">{subject}</span>
        <span className="threadslist-threadli-body">{body}</span>
      </div>
      
      <div className="threadslist-threadli-time">
        {time}
      </div>
      
    </div>
    
  );
};


export default connect(mapStateToProps)(muiThemeable()(ThreadLI));