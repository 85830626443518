import React, { useContext, useCallback } from 'react';
import moment from 'moment-timezone';
import { debounce, isEmpty } from 'lodash';
import {
  Text,
  Rule,
  Button,
  Icon,
  ThemeContext,
  Card,
  InputNumber,
  Loading,
  useRouter,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';

export default function ClientInvoices(props) {
  const route = useRouter();
  const {history, match:{params:{ clientId }}} = route;
  const { state={}, pathname } = route.location;
  const { search="", status } = state;

  const data = useQuery(`{
    agency {
      timezone
    }
    invoices(status:$status, clientId:$clientId, search:$search) {
      invoiceId
      invoiceNumber
      jobType
      date
      client {
        clientId
        name
      }
      status
      total
      agencyFeeTotal
      commissionTotal
    }
  }`, {status, search, clientId});

  let timezone = data?.agency?.timezone || moment.tz.guess();


  const setQuery = useCallback((update) => props.history.replace(pathname, {...state, ...update}), [pathname]);
  const setSearch = useCallback(debounce(search => setQuery({search}), 300), [setQuery]);

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  const title = (
    <div>
      <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
        <Text title>INVOICES</Text>
        <div style={{flex:1}}/>
        <InputNumber
          key="invoice-search"
          placeholder="Invoice Number"
          initialValue={search}
          onChange={setSearch}
          clearable
          style={{margin:0, height:38}}
        />
        <Button
          onClick={() => {
            history.push("/billing/invoices/create", {
              data: {
                clientId,
              }
            });
          }}
          style={{
            background:theme.color.grey,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            padding: 0,
            borderRadius: 0,
          }}>
          <Icon name="plus" color="white"/>
        </Button>
      </div>
      <Rule/>
      <div style={{marginBottom:24}}>
        <Button
          label="All"
          onClick={() => setQuery({status:null})}
          style={{
            borderRadius: 0,
            backgroundColor: !status ? theme.color.primaryLighter : theme.color.greyLight
          }}
        />
        <Button
          label="Drafts"
          onClick={() => setQuery({status:"DRAFT"})}
          style={{
            borderRadius: 0,
            backgroundColor: status === "DRAFT" ? theme.color.primaryLighter : theme.color.greyLight
          }}
        />
        <Button
          label="Posted"
          onClick={() => setQuery({status:"POSTED"})}
          style={{
            borderRadius: 0,
            backgroundColor: status === "POSTED" ? theme.color.primaryLighter : theme.color.greyLight
          }}
        />
        <Button
          label="Paid"
          onClick={() => setQuery({status:"PAID"})}
          style={{
            borderRadius: 0,
            backgroundColor: status === "PAID" ? theme.color.primaryLighter : theme.color.greyLight
          }}
        />
        <Button
          label="Overdue"
          onClick={() => setQuery({status:"OVERDUE"})}
          style={{
            borderRadius: 0,
            backgroundColor: status === "OVERDUE" ? theme.color.primaryLighter : theme.color.greyLight
          }}
        />
      </div>
    </div>
  );

  if(isEmpty(data.invoices)) {
    return (
      <div>
        {title}
        <div style={{textAlign:'center'}}>
          <div>
            <Icon name="surprise" duotone size={80} color="accentLighter" style={{marginBottom:16}}/>
          </div>
          <Text>This list is empty.</Text>
        </div>
      </div>
    );
  }

  return (
    <div>
      {title}
      <div style={{display:'flex', alignItems:'center', padding:8}}>
        <div style={{flex:1}}>
          <Text small light>Invoice Date</Text>
        </div>
        <div style={{flex:1}}>
          <Text small light>Invoice Number</Text>
        </div>
        <div style={{flex:1}}>
          <Text small light>Job Type</Text>
        </div>
        <div style={{flex:1}}>
          <Text small light>Invoice Status</Text>
        </div>
        <div style={{flex:1}}>
          <Text small light>Invoice Amount</Text>
        </div>
        <div style={{flex:1}}>
          <Text small light>Agency Fee</Text>
        </div>
        <div style={{flex:1}}>
          <Text small light>Commission to Agency</Text>
        </div>
      </div>
      {data.invoices.map(invoice => (
        <Card
          key={invoice.invoiceId}
          style={{display:'flex', alignItems:'center', boxSizing:'border-box', minHeight:40, padding:8, marginBottom:4, cursor:'pointer'}}
          onClick={() => props.history.push(`/billing/invoices/${invoice.invoiceId}`)}>
          <div style={{flex:1}}>
            <Text>{moment(invoice.date).tz(timezone).format("MM/DD/YYYY")}</Text>
          </div>
          <div style={{flex:1}}>
            <Text bold>{invoice.invoiceNumber}</Text>
          </div>
          <div style={{flex:1}}>
            <Text>{invoice.jobType}</Text>
          </div>
          <div style={{flex:1}}>
            <Text
              style={{
                fontWeight: 'bold',
                color: invoice.status === "PAID" ? "green" :
                       invoice.status === "POSTED" ? "orange" :
                       "#aaaaaa"
              }}>
              {invoice.status}
            </Text>
          </div>
          <div style={{flex:1}}>
            <Text>${parseFloat(invoice.total || 0, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
          </div>
          <div style={{flex:1}}>
            <Text>${parseFloat(invoice.agencyFeeTotal || 0, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
          </div>
          <div style={{flex:1}}>
            <Text>${parseFloat(invoice.commissionTotal || 0, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
          </div>
        </Card>
      ))}

    </div>
  );
}
