import React, { Component } from 'react';
import { connect } from 'react-redux';
import { subscribeToPackThreads, unsubscribeFromPackThreads } from 'actions';
import { isEmpty } from 'lodash';

import ThreadsList from 'ui/ThreadsList';
import Placeholder from 'ui/Placeholder';
import ShareDialog from '../ShareDialog';



export const mapStateToProps = (state, ownProps) => {
  const { url, path } = ownProps.match;

  const packId = ownProps.match.params.packId;
  const pack = state.packs.all[packId];
  const threadId = ownProps.match.params.threadId;
  const threads = state.threads.byPack[packId] || {};

  return {
    url,
    path,
    packId,
    pack,
    threadId,
    threads,
  };
};

export const mapDispatchToProps = {
  subscribeToPackThreads,
  unsubscribeFromPackThreads,
};





export class Messages extends Component {

  state = {
    isShareDialogOpen: false,
  }

  UNSAFE_componentWillMount() {
    this.props.subscribeToPackThreads(this.props.packId);
  }

  componentWillUnmount() {
    this.props.unsubscribeFromPackThreads(this.props.packId);
  }

  openShareDialog = () => this.setState({isShareDialogOpen:true})
  closeShareDialog = () => this.setState({isShareDialogOpen:false})

  render() {

    if(isEmpty(this.props.threads)) {
      return (
        <div>
          <Placeholder
            icon="message"
            message="This package hasn't been shared with anyone."
            label="Share Package"
            onClick={this.openShareDialog}
          />
          <ShareDialog
            open={this.state.isShareDialogOpen}
            onRequestClose={this.closeShareDialog}
            onSend={this.closeShareDialog}
            pack={this.props.pack}
          />
        </div>
      );
    }

    return <ThreadsList threads={this.props.threads}/>;
  }
}






export default connect(mapStateToProps, mapDispatchToProps)(Messages);