/*

# Message Pack Talent Dialog

*/


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadPackTalents, messagePackTalents } from 'actions';

import Dialog from 'ui/Dialog';
import MessageComposer from 'ui/MessageComposer';



export const mapStateToProps = (state, ownProps) => {
  let packId = ownProps.packId;
  let talents = state.packTalents.byPack[packId] || {};
  let talentCount = Object.keys(talents).length;

  return {
    packId,
    talentCount,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  loadPackTalents,
  messagePackTalents,
};



export class PackMessageTalentsDialog extends Component {
  UNSAFE_componentWillMount() {
    this.props.loadPackTalents(this.props.packId);
  }

  send = message => {
    this.props.messagePackTalents(this.props.packId, message);
    this.props.onRequestClose();
  }

  render() {
    if(this.props.isLoading) {
      return null;
    }

    return (
      <Dialog
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
        bodyStyle={{backgroundColor:'#fafafa'}}>

        <MessageComposer
          draftKey={`packtalent-${this.props.packId}`}
          onCancel={this.props.onRequestClose}
          onSend={this.send}
          showToField={false}
          showBCCSelf={false}
          title={`To ${this.props.talentCount} Package Talent`}
        />

      </Dialog>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PackMessageTalentsDialog);