import React, { useState } from 'react';
import {
  Modal,
  Button,
  Text,
  Form,
  InputText,
  InputSwitch,
} from 'skybolt-ui';
import { useQuery, updateEpayAccount, enableEpayAccount, updateTalent } from 'skybolt-api';

export default function UserBillingCCButton(props) {


  // Load the given user's epay account and the epay account for each of their
  // talents.

  const data = useQuery(`{
    user(userId:$userId) {
      epayId
      talents {
        talentId
        fullName
        status
        epayId
        requiredSubscription(status:"PAID")
      }
    }
  }`, {userId:props.userId});



  // Keep hold of the modal and form value.

  const [status, setStatus] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [card, setCard] = useState({});



  // Get the expired talent to prompt the user to upgrade,
  // keep track of which talents need to be upgraded, and
  // calculate the setup fee for enabling those talent.

  const [enableTalents, setEnableTalents] = useState({});
  let setupFee = null;
  let expiredTalents = [];
  if(data) {
    expiredTalents = data.user.talents
      .filter(t => t.status === 'EXPIRED')
      .filter(talent => {
        if(talent.requiredSubscription.error) {
          return false;
        }
        return true;
      });
    setupFee = expiredTalents
      .filter(talent => !!enableTalents[talent.talentId])
      .reduce((all, talent) => all + talent.requiredSubscription.setup + talent.requiredSubscription.initial, 0);
  }



  // Change CC details. This will update all accounts for the user and for
  // and talent that the user owns.

  const changeCC = async () => {
    if(isUpdating) {
      return;
    }
    setUpdating(true);

    if(!card.number || !card.expires || !card.code || !card.zip) {
      setError("Please enter all fields.");
      setUpdating(false);
      return;
    }

    if(data.user.epayId) {
      try {
        await updateEpayAccount({
          epayId:data.user.epayId,
          enabled:true,
          card
        });
      }
      catch(error) {
      }
    }

    for(const talent of data.user.talents) {

      if(talent.status === 'CANCELED' || talent.status === 'TRIAL') {
        continue;
      }
      if(!talent.epayId) {
        continue;
      }

      // Update all epay accounts with the given card, enabled or not.
      await updateEpayAccount({epayId:talent.epayId, card});

      // Enable all epay accounts associated with an active talent. This will
      // catch those epay accounts that have been disabled during the grace
      // 26 day period.
      if(talent.status !== "EXPIRED") {
        try {
          await enableEpayAccount(talent.epayId);
        }
        catch(error) {
          continue;
        }
      }

      // If a talent is expired, and have been set to reactivate, update the
      // epay account with the requiredSubcription, and update the talent
      // to mark them paid.
      if(talent.status === "EXPIRED" && enableTalents[talent.talentId]) {
        try {
          await updateEpayAccount({...talent.requiredSubscription, card});
          await updateTalent({
            talentId:talent.talentId,
            change:{
              status: "PAID",
              [`statusHistory.${Date.now()}`]: "PAID by owner cc update"
            }
          });
        }
        catch(error) {
          continue;
        }
      }

      if(talent.dateExpires) {
        await updateTalent({
          talentId:talent.talentId,
          update:{
            dateExpires:null
          }
        });
      }
    }

    if(props.onChange) {
      props.onChange({
        ...card,
        number: card.number.replace(/.(?=.{4,}$)/g, 'X'),
      });
    }

    setStatus("sent");
    setTimeout(() => {
      setUpdating(false);
      setCard({});
      setError(null);
      setEnableTalents({});
    }, 200);

  };


  // Render

  return [
    <Button key="button" raised onClick={() => setStatus('open')}>Update Credit Card</Button>,
    <Modal
      key="modal"
      status={status}
      onRequestClose={() => setStatus(false)}
      modalStyle={{
        maxWidth:400,
        padding:16,
      }}>
      <Text paragraph>Update Credit Card</Text>

      <Form value={card} onChange={v => setCard(v)}>
        <InputText
          title="Credit Card Number"
          name="number"
        />
        <div style={{display:'flex'}}>
          <InputText
            title="Expiration Date"
            hint="MM/YY"
            name="expires"
            clean={val => val.replace(/\D/g,'').slice(0, 4)}
            format={val => {
              if(val.length > 2) {
                return val.slice(0,2) + '/' + val.slice(2);
              }
              return val;
            }}
            style={{flex:4, marginRight:8}}
          />
          <InputText
            title="CVV"
            name="code"
            style={{flex:1, marginRight:8}}
            clean={v => v.replace(/[^0-9.]*/g,"")}
          />
          <InputText
            title="Zip"
            name="zip"
            style={{flex:2}}
            clean={v => v.replace(/[^0-9.]*/g,"")}
          />
        </div>
      </Form>

      {
      // If there are expired talents, prompt user to restart those talent.

      expiredTalents.length > 0 && (
        <div>
          <Text paragraph>
            You have {expiredTalents.length} expired talent accounts. Would you
            like to restart any of these accounts?
          </Text>

          {
            // If no talents are selected, warn of setup fee in doing so.
            // If they are restarting accounts, calculate the setup fee they're
            // going to be billed.
            Object.keys(enableTalents).filter(k => !!enableTalents[k]).length === 0 ? (
              <Text paragraph>
                You will be charged a setup fee and the first month for each
                account you restart.
              </Text>
            ) : (
              <Text paragraph>
                You will be charged a ${setupFee.toFixed(2)} to restart&nbsp;
                {Object.keys(enableTalents).filter(k => !!enableTalents[k]).length}
                &nbsp;accounts.
              </Text>
            )
          }

          {expiredTalents
            .map(talent =>
              <InputSwitch
                key={talent.talentId}
                title={talent.fullName}
                value={enableTalents[talent.talentId]}
                onChange={val => setEnableTalents({...enableTalents, [talent.talentId]:val})}
              />
            )
          }
        </div>
      )}

      <Text centered error>{error}</Text>

      <div style={{display:'flex', justifyContent:'flex-end'}}>
        <Button label="Cancel" style={{marginRight:8}} onClick={() => setStatus(false)}/>
        <Button
          raised
          accent={!isUpdating}
          label={isUpdating ? "Hang Tight..." : "Change"}
          onClick={changeCC}
        />
      </div>
    </Modal>
  ];
}