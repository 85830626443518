import React, { useContext } from 'react';
import { useQuery } from 'skybolt-api';
import { isEmpty } from 'lodash';
import { Text, Rule, Card, ThemeContext } from 'skybolt-ui';
import { resumeSections } from 'config';



export default props => {
  
  const theme = useContext(ThemeContext);
  
  // Load the resume data for the passed in talent id.
  // Until that data loads, just return null.
  
  const data = useQuery(`{
    talent(talentId:$talentId) {
      bio
      skills
      experience
      agencyId
      agency {
        resumeSections
      }
    }
  }`, {talentId:props.talentId});
  
  if(!data) {
    return null;
  }
  
  
  // Get the bio.
  
  let bio = null;
  if(data.talent.bio) {
    bio = (
      <Text>{data.talent.bio}</Text>
    );
  }
  
  
  // Group experience items by type.
  
  let experience = null;
  
  if(data.talent.experience) {
    const experienceByType = Object.keys(data.talent.experience || {})
      .reduce((all, id) => {
        const item = data.talent.experience[id];
        if(!all[item.type]) all[item.type] = [];
        all[item.type].unshift({...item, id});
        return all;
      }, {});
      
    experience = (data.talent.agency.resumeSections || resumeSections)
      .map(section => {
        if(isEmpty(experienceByType[section])) {
          return null;
        }
        
        return (
          <div key={section}>
            <Text title>{section}</Text>
            <Rule style={{marginBottom:8}}/>
            <Card style={{padding:8}}>
              {
              // Sort each experience item by it's order property
              // then display in a line item.
              (experienceByType[section] || [])
                .sort((a, b) => (a.order || 0) > (b.order || 0) ? 1 : -1)
                .map((item, i, all) => 
                  <div key={item.id}>
                    <div style={{display:'flex'}}>
                      <Text style={{display:'block', flex:1}}>{item.title}</Text>
                      <Text style={{display:'block', flex:1}}>{item.role}</Text>
                      <Text style={{display:'block', flex:1}}>{item.company}</Text>
                    </div>
                    {i < all.length-1 && <Rule color="greyLight" style={{margin:"4px 0"}}/>}
                  </div>
                )
              }
            </Card>
          </div>
        );
      });
  }
  
  
  // Skills
  
  let skills = null;
  if(data.talent.skills) {
    skills = (
      <div>
        <Text title>Skills</Text>
        <Rule style={{marginBottom:8}}/>
        <Card style={{padding:8}}>
          <Text>{data.talent.skills}</Text>
        </Card>
      </div>
    );
  }
  
  
  return (
    <div style={{padding:16, backgroundColor:theme.color.greyLighter}}>
      {bio}
      {experience}
      {skills}
    </div>
  );
};