/*

TODO: Material UIs SelectField is really being pushed here. 
You can make this cleaner.

*/


import React from 'react';
import { range, uniq, first, last } from 'lodash';
import muiThemeable from 'material-ui/styles/muiThemeable';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';








export default muiThemeable()(({
  className="",
  style={},
  hintStyle={},
  underlineStyle={},
  labelStyle={},
  iconStyle={},
  label="",
  hint="",
  value=[],
  options=[],
  optionValueKey=null,
  optionDisplayKey=null,
  units="",
  multiple=true,
  onChange=()=>null,
  muiTheme:{ palette },
}) => {
  
  
  const selectionStyle = {
    position:'absolute', 
    left: 0,
    top: 0,
    right:56, 
    overflow:'hidden', 
    textOverflow:'ellipsis'
  };
  
  const selectedMenuItemStyle = {
    color: 'white',
    backgroundColor: palette.accent700,
  };
  
  function handleChange(e, i, v) {
    if(v.indexOf(-1) > -1) {
      onChange([]);
      return;
    }
    
    if(e.nativeEvent.shiftKey && v.length >= 2) {
      let r = v.slice(-2);
      let from = options.indexOf(r[0]);
      let to = options.indexOf(r[1]);
      range(from, to).forEach(i => v.push(options[i]));
      v = uniq(v);
    }
    
    onChange(v);
  }
  
  function renderSelection(val) {
    let content;
    
    if(Array.isArray(val)) {
      if(val.length > 4) {
        let f = first(val);
        let l = last(val);
        content = `${f} - ${l}`;
      }
      
      else if(val.length > 1) {
        content = val.join(', ');
      }
      
      else if(val.length === 1) {
        content = val[0];
      }
    }
    else {
      content = val;
    }
    
    if(content) {
      return <div style={selectionStyle}>{content}</div>;
    }
    
    return null;
  }
  
  function renderOption(option) {
    let val = optionValueKey ? option[optionValueKey] : option;
    let disp = optionDisplayKey ? option[optionDisplayKey] : option;
    
    return (
      <MenuItem 
        key={val}
        value={val}
        primaryText={`${disp} ${units}`}
      />
    );
  }
  
  function renderOptions() {
    let res = [];
    
    if(multiple) {
      res.push(<MenuItem key="_all" value={-1} primaryText="All"/>);
    }
    
    let rest = options.map(i =>  renderOption(i));
    res = [...res, ...rest];
    
    return res;
  }
  
  return (
    <SelectField
      floatingLabelText={label}
      floatingLabelStyle={{whiteSpace:'nowrap', overflow:'hidden', width:'70%', textOverflow:'ellipsis'}}
      hintText={hint}
      selectionRenderer={renderSelection}
      
      autoWidth={true}
      fullWidth
      
      className={className}
      style={style}
      hintStyle={hintStyle}
      underlineStyle={underlineStyle}
      labelStyle={labelStyle}
      iconStyle={iconStyle}
      selectedMenuItemStyle={selectedMenuItemStyle}
      
      multiple={multiple}
      value={value}
      onChange={handleChange}
      >
      
      {renderOptions()}
      
    </SelectField>  
  );
});
  