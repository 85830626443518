import React, { useState, useEffect } from 'react';
import { Button } from 'skybolt-ui';
import { callGetFunction } from 'skybolt-api';


export default function LinkGoogleButton({userId, ...props}) {
  const [link, setLink] = useState("");

  useEffect(() => {
    callGetFunction(`googleoauth2url?uid=${userId}`, {format:'text'}).then(url => {
      setLink(url);
    });
  }, []);

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" style={{color:'inherit'}}>
      <Button
        key="button"
        label="Link Google Account"
        raised
        {...props}
      />
    </a>
  );
}