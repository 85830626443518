import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadAgencies } from 'actions';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';



const mapStateToProps = (state) => {
  return {
    agencies: state.agencies.all,
  };
};

const mapDispatchToProps = {
  loadAgencies
};



class AgencySelect extends Component {
  UNSAFE_componentWillMount() {
    this.props.loadAgencies();
  }

  emitChange(agencyId) {
    const agency = this.props.agencies[agencyId];
    this.props.onChange(agencyId, agency);
  }

  render() {
    return (
      <SelectField
        fullWidth
        style={{maxWidth:400}}
        floatingLabelText={this.props.floatingLabelText}
        value={this.props.value}
        hintText="Agency"
        onChange={(e, i, val) => this.emitChange(val)}>

        {!this.props.required &&
          <MenuItem value={null} primaryText=""/>
        }

        {Object.keys(this.props.agencies || {}).map(agencyId =>
          <MenuItem key={agencyId} value={agencyId} primaryText={this.props.agencies[agencyId].name}/>
        )}
      </SelectField>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AgencySelect);