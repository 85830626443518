import React, { useState, useContext } from 'react';
import { useQuery, updateTalent, updateUser } from 'skybolt-api';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import {
  Text,
  Container,
  Rule,
  ThemeContext,
  Button,
  Loading,
  useRouter,
  useLayout,
  Redirect,
  push,
} from 'skybolt-ui';
import Plan from './Plan/index.js';


export default function Plans() {
  const router = useRouter();
  const talentId = router.match.params.talentId;

  const data = useQuery(`{
    talent(talentId:$talentId) {
      agencyId
      agency {
        plans
        planSelectText
        requireTalentSubscription
        requireUserSubscription
        requireTalentApproval
        subscriptionStart
        subscriptionStartAfter
        allowTrialTalents
      }
      user {
        plan,
        userId,
        status,
      }
      plan
      approved
    }
  }`, {talentId});


  // When a plan is selected, set the talent's plan and direct to payment.

  const [isSelecting, setSelecting] = useState(false);
  const handlePlanSelect = async plan => {
    if(isSelecting) {
      return;
    }
    setSelecting(true);
    if(data.talent.agency.requireTalentSubscription) {
      await updateTalent({
        talentId,
        update: {
          plan,
          requireAddons: !!plan.addons,
        }
      });
    }
    if(data.talent.agency.requireUserSubscription) {
      await updateUser({
        userId:data.talent.user.userId,
        update: {
          plan,
        }
      });
    }
  };

  const selectTrial = async () => {
    if(isSelecting) {
      return;
    }
    setSelecting(true);
    await updateTalent({
      talentId,
      update: {
        status:"TRIAL",
        requireSubscription: false,
        requireAddons: false,
      }
    });
    if(data.talent.agency.requireTalentApproval && data.talent.agencyId && !data.talent.approved) {
      await firebase.database().ref(`talentsForApproval/${data.talent.agencyId}/${talentId}`).set(true);
    }
    router.history.push(`/talents/${talentId}`);
  };





  // If plans are split between monthly and yearly. Create a toggle to switch
  // between them.

  const [cycle, setCycle] = useState('month');
  const planIds = data ? Object.keys(data.talent.agency.plans) : [];
  const splitByCycle = planIds.length > 3 && !!planIds.find(planId => data.talent.agency.plans[planId].cycle === 'year');



  // Render

  const { width } = useLayout();
  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  if(data.talent.agency.requireTalentSubscription && data.talent.plan) {
    return <Redirect to={`/talents/${talentId}`}/>;
  }

  if(data.talent.agency.requireUserSubscription && data.talent.user.plan) {
    return <Redirect to={`/talents/${talentId}`}/>;
  }

  return (
    <div style={{width:'100%'}}>
      <div
        style={{
          boxSizing: 'border-box',
          width: '100%',
          padding: '40px 16px 80px 16px',
          marginBottom: -80,
          textAlign: 'center',
          backgroundColor: theme.color.accent
        }}>
        <Text style={{fontSize:"47px", lineHeight:'.9em', color:'white'}}>SELECT</Text><br/>
        <Text style={{fontSize:"60px", lineHeight:'.9em', color:'white', letterSpacing:3, fontWeight:'bold'}}>PLAN</Text><br/>
        <Rule small color="white" style={{margin:'32px auto 24px'}}/>
        <Text block style={{color:theme.color.lightTextLighter, textAlign:'center', fontSize:16, lineHeight:'18px', marginBottom:16}}>
          {data.talent.agency.planSelectText || "Choose a plan to get started."}
        </Text>
        {data.talent.agency.subscriptionStart && moment().isBefore(data.talent.agency.subscriptionStart) &&
          <Text block style={{color:theme.color.lightTextLighter, textAlign:'center', fontSize:16, lineHeight:'18px', marginBottom:16}}>
            Your subscription will start on {moment(data.talent.agency.subscriptionStart).format("MM/DD/YYYY")}.
          </Text>
        }
        {data.talent.agency.subscriptionStartAfter &&
          <Text block style={{color:theme.color.lightTextLighter, textAlign:'center', fontSize:16, lineHeight:'18px', marginBottom:16}}>
            Your subscription will start on {moment().add(data.talent.agency.subscriptionStartAfter, 'days').format("MM/DD/YYYY")}.
          </Text>
        }

        {splitByCycle && (
          <div style={{display:'flex', justifyContent:'center', marginTop:16}}>
            <Button
              onClick={() => setCycle('month')}
              style={{
                borderRadius: "8px 0 0 8px",
                color: 'white',
                backgroundColor: `rgba(255,255,255,${cycle === 'month' ? 0.4 : 0.1})`,
              }}>
              Monthly
            </Button>
            <Button
              onClick={() => setCycle('year')}
              style={{
                borderRadius: "0 8px 8px 0",
                color: 'white',
                backgroundColor: `rgba(255,255,255,${cycle === 'year' ? 0.4 : 0.1})`,
              }}>
              Annual
            </Button>
          </div>
        )}

        <div style={{height:32}}/>

      </div>

      <Container style={{paddingBottom:40}}>
        <div
          style={{
            display: width > 800 ? 'flex' : 'block',
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
          {Object.keys(data.talent.agency.plans)
            .map(key => data.talent.agency.plans[key])
            .filter(plan => splitByCycle ? plan.cycle === cycle : true)
            .sort((a, b) => a.talent > b.talent ? 1 : -1)
            .map((plan, key) =>
              <Plan
                key={key}
                plan={plan}
                onSelect={handlePlanSelect}
              />
            )
          }
        </div>

        {data.talent.agency.allowTrialTalents && 
          <div 
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div 
              style={{
                padding: 24,
                cursor: 'pointer',
                fontSize: 16,
                color: "#aaa",
              }}
              onClick={selectTrial}>
              Continue with free trial account.
            </div>
          </div>
        }
      </Container>
    </div>
  );
}