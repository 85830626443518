import React from 'react';
import {
  Switch,
  Route,
  useRouter,
} from 'skybolt-ui';

import AddTalent from './AddTalent';
import InviteTalent from './InviteTalent';
import InviteAgent from './InviteAgent';
import InviteGuest from './InviteGuest';
import Talents from './Talents';

export default props => {
  const router = useRouter();
  const {match:{path}} = router;
  return (
    <Switch>
      <Route path={`${path}/talents`} component={Talents}/>
      <Route path={`${path}/invite-talent`} component={InviteTalent}/>
      <Route path={`${path}/invite-agent`} component={InviteAgent}/>
      <Route path={`${path}/invite-guest`} component={InviteGuest}/>
      <Route path={`${path}/invite-castingdirector`} component={InviteGuest}/>
      <Route path={`${path}/add-talent`} component={AddTalent}/>
    </Switch>
  );
};