import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadPacks, addPack } from 'actions';
import { isEmpty, get as getProperty } from 'lodash';

import "./index.css";

import PackCard from './PackCard';
import RaisedButton from 'material-ui/RaisedButton';
import Container from 'ui/Container';
import Loading from 'ui/Loading';
import AddIcon from 'material-ui/svg-icons/content/add';
import Title, { Action } from 'ui/Title';


const mapStateToProps = state => {
  const agencyId = state.user.agencyId;
  let packs = state.packs.byUser || {};

  // packs = Object.keys(packs).map(id => packs[id]);
  packs = Object.keys(packs)
    .map(id => ({id, ...packs[id]}))
    .filter(pack => !!pack)
    .sort((a, b) => a.dateCreated > b.dateCreated ? -1 : 1);

  const canAddPack = getProperty(state, 'user.permissions.canPack', false);
  const canRequestPacks = getProperty(state, 'user.permissions.canRequestPacks', false);

  return {
    isLoading: state.packs.isLoading,
    agencyId,
    packs,
    canAddPack,
    canRequestPacks,
  };
};

const mapDispatchToProps = {
  loadPacks,
  addPack,
};

class PackHistory extends Component {

  state = {
    search: "",
    limit: 20,
    showArchived: false,
  }

  componentDidMount() {
    this.props.loadPacks();
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = e => {
    var rect = this.lastElement.getBoundingClientRect();
    if(rect.y - 200 < (window.innerHeight || document.documentElement.clientHeight)) {
      this.loadMore();
    }
  }

  add = async () => {
    const { addPack, history:{ push, replace } } = this.props;
    const pack = await addPack();

    push(`/packs/create`);
    setTimeout(() => replace(`/packs/${pack.id}`), 1000);
  }

  // todo: look at talent lazy loading and do that here too.

  loadMore = () => {
    if(!this.props.isLoading) {
      this.props.loadPacks({query:this.state.search, limit:this.state.limit+10});
    }
    this.setState({limit:this.state.limit+10});
  }

  setSearch = search => {
    this.props.loadPacks({query:search, limit:20});
    this.setState({search, limit:20, showArchived:!!search});
  }

  renderPackCards() {
    let cards = this.props.packs
      .sort((a, b) => a.dateUpdated < b.dateUpdated ? 1 : -1);

    if(!this.state.showArchived) {
      cards = cards.filter(a => !a.isArchived);
    }
    
    cards = cards
      .slice(0, this.state.limit)
      .map(pack =>
        <PackCard
          key={pack.id}
          packId={pack.id}
          className="packs-card"
        />
      );

    return cards;
  }

  renderPacks() {
    if(isEmpty(this.props.packs)) {
      return null;
    }

    return (
      <Container>
        {this.renderPackCards()}
      </Container>
    );
  }

  renderNoPacks() {
    if(!isEmpty(this.props.packs)) {
      return;
    }

    return (
      <div className="packs-instructions">
        <p>You don't have any active packages.</p>
        {this.props.canAddPack &&
          <RaisedButton
            primary={true}
            label="Create a package"
            onClick={this.add}
          />
        }
      </div>
    );
  }

  render() {
    let actions = [];

    if(this.props.canAddPack) {
      actions.push(
        <Action
          tooltip={"Create New Package"}
          icon={<AddIcon/>}
          onClick={this.add}
        />
      );
    }

    return (
      <div className="packs-wrapper">

        <Container>
          <Title actions={actions} search={this.state.search} onSearch={this.setSearch}>
            Packages
          </Title>
        </Container>

        {this.renderPacks()}
        {this.renderNoPacks()}

        {this.props.isLoading &&
          <div className="packs-loading">
            <Loading/>
          </div>
        }
        <div key="lastelement" ref={el => this.lastElement = el}/>

      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PackHistory);