import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import muiThemeable from 'material-ui/styles/muiThemeable';
import moment from 'moment';
import {
  subscribeToMessageReceipts,
  unsubscribeToMessageReceipts,
} from 'actions';

import Icon from 'ui/Icon';



function formatPhoneNumber(s) {
  var s2 = (""+s).replace(/\D/g, '');
  var m = s2.match(/^\+?\d?(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? s : "(" + m[1] + ") " + m[2] + "-" + m[3];
}


export const mapStateToProps = (state, ownProps) => {
  
  let receipts = state.messageReceipts.byThread[ownProps.threadId];
  
  // todo: remove once old receipts become irrelevant
  const oldReceipts = state.threads.all[ownProps.threadId].receipts;
  if(oldReceipts && (!receipts || isEmpty(receipts))) {
    receipts = oldReceipts;
  }
  
  return {
    receipts,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  subscribeToMessageReceipts,
  unsubscribeToMessageReceipts,
};



export class MessageReceipts extends Component {
  
  componentDidMount() {
    if(this.props.threadId) {
      this.props.subscribeToMessageReceipts(this.props.threadId);
    }
  }
  
  componentWillUnmount() {
    if(this.props.threadId) {
      this.props.unsubscribeToMessageReceipts(this.props.threadId);
    }
  }
  
  
  renderStatus = receipt => {
    const errorColor = this.props.muiTheme.palette.error;
    const accentColor = this.props.muiTheme.palette.accent700;
    
    if(receipt.modality === 'email') {
      if(receipt.error) {
        return <td style={{color:errorColor}}>Failed</td>;
      }
      if(receipt.dateRead) {
        return <td style={{color:accentColor}}>Read {moment(receipt.dateRead).format('MM/DD h:mma')}</td>;
      }
      if(receipt.dateSent) {
        return <td style={{color:accentColor}}>Sent {moment(receipt.dateSent).format('MM/DD h:mma')}</td>;
      }
      return <td>Sending...</td>;
    }
    
    if(receipt.modality === 'sms') {
      return <td style={{color:accentColor}}>{receipt.twilioStatus} {moment(receipt.dateUpdated || receipt.dateRead).format('MM/DD h:mma')}</td>;
    }
    
    return <td></td>;
  }
  
  
  render() {
    const receipts = this.props.receipts;

    // console.log('receipts', receipts);
    
    return (
      <table className="threadslist-thread-receipts">
        <tbody>
          {receipts === null && <tr><td colSpan="6">Loading...</td></tr>}
          
          {!!receipts && isEmpty(receipts) && <tr><td colSpan="6">No receipts</td></tr>}
          
          {Object.keys(receipts || {})
            .map(id => ({...receipts[id], id}))
            .map(receipt => 
              <tr key={receipt.id}>
                <td><Icon name={receipt.modality} size={12} color="#BDBDBD"/></td>
                <td>{receipt.name}</td>
                <td>{receipt.modality === 'sms' ? formatPhoneNumber(receipt.locator) : receipt.locator}</td>
                {this.renderStatus(receipt)}
              </tr>
            )
          }
        </tbody>
      </table>
      
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(
  muiThemeable()(
    MessageReceipts
  )
);