import moment from 'moment-timezone';
import filtersDescription from './filtersDescription.js';
import download from 'downloadjs';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});


function row(invoice, timezone = moment.tz.guess()) {
  let dateSent = invoice.date ? moment(invoice.date).tz(timezone).format("MM/DD/YYYY") : "";
  let datePaid = invoice.datePaid ? moment(invoice.datePaid).tz(timezone).format("MM/DD/YYYY") : "";
  let num = invoice.invoiceNumber || "";
  let jobType = invoice.jobType || "";
  let client = invoice.client.name || "";
  let status = invoice.status || "";
  let total = invoice.total ? currencyFormatter.format(invoice.total) : "";
  let subtotal = invoice.subtotal ? currencyFormatter.format(invoice.subtotal) : "";
  let talent = currencyFormatter.format(Number(invoice.total || 0) - Number(invoice.agencyFeeTotal || 0) - Number(invoice.commissionTotal || 0)) || "";
  let agency = currencyFormatter.format(Number(invoice.agencyFeeTotal || 0) + Number(invoice.commissionTotal || 0)) || "";
  
  return `${dateSent},${datePaid},${num},"${jobType}","${client}",${status},"${total}","${subtotal}","${talent}","${agency}"\r`;
}

function totals(invoices, timezone = moment.tz.guess()) {
  let totalTotal = currencyFormatter.format( invoices.reduce((total, invoice) => total + Number(invoice.total || 0), 0) );
  let subtotalTotal = currencyFormatter.format( invoices.reduce((total, invoice) => total + Number(invoice.subtotal || 0), 0) );
  let talentTotal = currencyFormatter.format( invoices.reduce((total, invoice) => total + (Number(invoice.total || 0) - Number(invoice.agencyFeeTotal || 0) - Number(invoice.commissionTotal || 0)), 0) );
  let agencyTotal = currencyFormatter.format( invoices.reduce((total, invoice) => total + (Number(invoice.agencyFeeTotal || 0) + Number(invoice.commissionTotal || 0)), 0) );

  return `,,,,,Total,"${totalTotal}","${subtotalTotal}","${talentTotal}","${agencyTotal}"\r`;
}

export default function downloadCSV(invoices, filters={}, timezone = moment.tz.guess()) {
  let title = "Invoice Report";
  let description = filtersDescription(filters);
  let filename = "Invoices " + moment().tz(timezone).format("MM/DD/YYYY");
  let csv = ``;

  csv += `"${title}",,,,,,,,,\r`;
  csv += `"${description}",,,,,,,,,\r`;
  csv += `,,,,,,,,,\r`;
  csv += "Invoice Date,Date Paid,Invoice Number,Job Type,Client,Status,Total,Subtotal,Owed to Talent,Total Commission\r";

  if(filters.orderBy == 'jobType') {
    let jobTypes = invoices.reduce((jobTypes, invoice) => {
      if(!jobTypes.includes(invoice.jobType)) {
        jobTypes.push(invoice.jobType);
      }
      return jobTypes;
    }, []);
    jobTypes.sort();

    for(let jobType of jobTypes) {
      let invoicesByType = invoices.filter(invoice => invoice.jobType == jobType).sort((a,b) => b.invoiceNumber - a.invoiceNumber);
      for(let invoice of invoicesByType) {
        csv += row(invoice, timezone);
      }
      csv += totals(invoicesByType, timezone);
      csv += `,,,,,,,,,\r`;
    }
  }
  else if(filters.orderBy == 'clientId') {
    let clients = invoices.reduce((clients, invoice) => {
      if(!clients.includes(invoice.clientId)) {
        clients.push(invoice.clientId);
      }
      return clients;
    }, []);
    clients.sort();

    for(let clientId of clients) {
      let invoicesByClient = invoices.filter(invoice => invoice.clientId == clientId).sort((a,b) => b.invoiceNumber - a.invoiceNumber);
      for(let invoice of invoicesByClient) {
        csv += row(invoice, timezone);
      }
      csv += totals(invoicesByClient, timezone);
      csv += `,,,,,,,,,\r`;
    }
  }
  else {
    for(let invoice of invoices) {
      csv += row(invoice, timezone);
    }
  }

  csv += `,,,,,,,,,\r`;
  csv += totals(invoices, timezone);
  csv += `,,,,,,,,,\r`;
  
  download(csv, `${filename}.csv`, 'text/csv');

}