import React, {Component} from 'react';
import { connect } from 'react-redux';
import { loadPack } from 'actions';
import _ from 'lodash';

import { Switch, Route, Redirect } from 'react-router-dom';
import Header from './Header';
import Container from 'ui/Container';
import Loading from 'ui/Loading';
import Talents from './Talents';
// import Talents from 'App/Packs/Pack/Talents';
import Messages from './Messages';
import Discussion from './Discussion';
import Threads from './Threads';
import Shortlist from './Shortlist';
// import Info from './Info';
import Info from 'App/Packs/Pack/Info';
import Schedule from './Schedule';
import MediaRequest from 'App/Packs/Pack/MediaRequest';

import './index.css';


const mapStateToProps = (state, ownProps) => {
  const packId = ownProps.match.params.packId;
  const pack = state.packs.all[packId];

  const canPackPermission = _.get(state, 'user.permissions.canPack', false);
  const userAgencyId = _.get(state, 'user.agencyId', null);
  const packAgencyId = _.get(pack, 'agencyId', null);
  const isOwnAgency = !!userAgencyId && userAgencyId === packAgencyId;
  const isOwnPack = _.get(pack, 'userId', null) === state.user.id;
  const isSharedWithUser = !!pack && !!_.find(pack.recipients, r => r.agencyId === userAgencyId);
  const canPack = canPackPermission && (isOwnAgency || isOwnPack || isSharedWithUser);

  const isLoading = state.user.isLoading || !pack || pack.isLoading;

  const isLoggedIn = state.user.isLoggedIn;

  return {
    url: ownProps.match.url,
    path: ownProps.match.path,
    packId: packId,
    pack,
    isLoading,
    isOwnPack,
    canPack,
    isLoggedIn,
  };
};

const mapDispatchToProps = {
  loadPack,
};

export class Pack extends Component {
  UNSAFE_componentWillMount() {
    this.props.loadPack(this.props.packId);
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.packId !== nextProps.packId) {
      this.props.loadPack(nextProps.packId);
    }
  }

  render() {
    if(this.props.isLoading) {
      return <Loading style={{marginTop: 40}}/>;
    }

    const path = this.props.path;
    const url = this.props.url;
    const pack = this.props.pack;

    return (
      <div className="pack-wrapper">
        <Container>
          <Header pack={pack}/>
        </Container>
        <Switch>
          <Route path={`${path}/event`} component={Info}/>
          <Route path={`${path}/media`} render={() => <MediaRequest/>}/>
          <Route path={`${path}/talents`} component={Talents}/>
          <Route path={`${path}/messages`} component={Messages}/>
          <Route path={`${path}/discussion`} component={Discussion}/>
          <Route path={`${path}/threads`} component={Threads}/>
          <Route path={`${path}/shortlist`} component={Shortlist}/>
          <Route path={`${path}/schedule`} component={Schedule}/>
          <Redirect to={`${url}/${this.props.canPack ? 'event' : `shortlist/${Object.keys(this.props.pack.categories || {})[0]}`}`}/>
        </Switch>
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Pack);