import firebase from 'firebase/app';
import 'firebase/database';
import React, { useContext, useState, useCallback } from 'react';
import { useRouter, Loading, Form, InputText, InputSelect, /*InputTag, InputSwitch,*/ InputHTML, Text, Card, Icon, Button, ThemeContext, Modal } from 'skybolt-ui';
import { get, debounce } from 'lodash';
import { useQuery, updatePack } from 'skybolt-api';



export default function PackMediaRequestRequest(props) {

  const route = useRouter();
  const packId = get(route, 'match.params.packId', null);

  const data = useQuery(`{
    pack(packId:$packId) {
      agencyId
      mediaRequest {
        name
        type
        message
        saveToTalent
        tags
      }
      talents {
        isSelected
      }
    }
  }`, {packId});

  const [saving, setSaving] = useState(null);

  let timers = [];
  const handleChange = useCallback(
    debounce(mediaRequest => {
      timers.forEach(t => clearTimeout(t));
      timers = [];
      setSaving(<Text color="textLighter">Saving...</Text>);
      updatePack({packId, update:{mediaRequest}});
      timers.push(setTimeout(() => setSaving(<Text color="textLighter">Saved</Text>), 1000));
      timers.push(setTimeout(() => setSaving(null), 4000));
    }, 500)
  , [packId]);

  async function send() {

    // Check that there are selected talent.
    if(!data.pack.talents.find(t => t.isSelected)) {
      await Modal.error("There are no shortlisted talent in this package.");
      return;
    }

    // TODO: Replace this with api function once it's ready.
    const transaction = {
      type:"MESSAGE_SELECTED_PACK_TALENTS",
      packId,
      message: {
        subject: data.pack.mediaRequest.name,
        body: `${data.pack.mediaRequest.message}<br/><br/>${window.location.origin}/talents/-talentId-/add-media/-packId-`,
        sms: false,
      },
      userId: firebase.auth().currentUser.uid,
      date: Date.now(),
      source: 'WEB'
    };
    firebase.database().ref(`transactions`).push(transaction);

    updatePack({packId, update:{mediaRequest:{...data.pack.mediaRequest, status:"SENT"}}});
  }

  // Rendering the form.

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  return (
    <Card style={{position:'relative', overflow:'hidden', padding:"16px 300px 8px 16px", maxWidth:800, margin:'0 auto 40px'}}>
      <Form value={data.pack.mediaRequest} onChange={handleChange}>
        <Icon name="camera-alt" duotone style={{position:'absolute', top:16, right:50, transform:"rotateZ(15deg)"}} size={200} color={theme.color.accentBackground}/>
        <div style={{display:'flex'}}>
          <InputSelect name="type" title="Type" options={["image", "video"]} style={{flex:"0 0 auto", width:134, marginRight:16}}/>
          <InputText name="name" title="Name" style={{flex:1, maxWidth:450}}/>
        </div>
        <InputHTML name="message" title="Message" style={{maxWidth:600}}/>
        {/*
        <InputSwitch name="saveToTalent" title="Save to talent media." style={{maxWidth:600}}/>
        {data.pack.mediaRequest.saveToTalent &&
          <InputTag name="tags" style={{maxWidth:600}}/>
        }
        */}
        <nav style={{display:'flex', alignItems:'center', paddingTop:24}}>
          <Button raised accent label="Send" icon="send" style={{marginRight:24}} onClick={send}/>
          {saving}
        </nav>
        <Text block small light style={{paddingTop:40}}>
          This will send a message to all shortlisted talent with a request to upload new media.<br/>
          {data.pack.mediaRequest.saveToTalent && "New media will be saved in the talent's media library."}
        </Text>
      </Form>
    </Card>
  );

}