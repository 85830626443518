import { combineReducers } from "redux";


const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_MESSAGE_RECEIPTS':
      return {
        ...state,
        ...action.receipts,
      };
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byThread = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_MESSAGE_RECEIPTS_REQUEST': 
      return {
        ...state,
        [action.threadId]: null,
      };
      
    case 'LOAD_MESSAGE_RECEIPTS':
      if(action.threadId) {
        return {
          ...state,
          [action.threadId]: action.receipts
        };
      }
      return state;
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};





export default combineReducers({
  all,
  byThread,
});