/*
# Invite Agents Dialog
*/


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { inviteToPack } from 'actions';
import { get, map } from 'lodash';

import Dialog from 'ui/Dialog';
import MessageComposer from 'ui/MessageComposer';


export const mapStateToProps = (state, ownProps) => {
  let packId = ownProps.packId || ownProps.pack.id;
  const pack = get(state, `packs.all[${packId}]`, {});

  const agencies = {};
  const agencyIds = state.user.agencyIds || [];
  for(const id of agencyIds) {
    const agency = get(state, `agencies.all[${id}]`, null);
    if(!!agency) {
      agencies[id] = agency;
    }
  }

  const talents = get(state, `packTalents.byPack[${packId}]`, {});
  const talentAgencies = map(talents, talent => agencies[talent.agencyId]).filter(i => !!i);

  return {
    packId,
    pack,
    talents,
    agencies,
    talentAgencies,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  inviteToPack,
};

export class InviteAgentsDialog extends Component {

  componentDidMount() {
    if(!this.props.packId) {
      this.props.onRequestClose();
    }
  }

  send = message => {
    const recipients = {};

    for(const agency of this.props.talentAgencies) {
      recipients[agency.id] = {
        agencyId: agency.id,
        name: agency.contactName || "",
        email: agency.contactEmail || "",
      };
    }

    const invite = {
      recipients: recipients,
      bccSender: false,
      ccSender: false,
      subject: message.subject || this.props.pack.name,
      body: message.body,
      attachments: message.attachments,
    };

    this.props.inviteToPack(this.props.packId, invite);

    this.props.onRequestClose();
  }

  render() {
    if(this.props.isLoading) {
      return null;
    }

    const packName = this.props.pack.name || "";
    const packUrl = `https://${window.location.hostname}/packs/${this.props.pack.id}`;

    return (
      <Dialog
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
        bodyStyle={{backgroundColor:'#fafafa'}}>

        <MessageComposer
          onCancel={this.props.onRequestClose}
          onSend={this.send}

          showToField={false}
          showBCCSelf={false}
          showSMS={false}
          title={`Invite ${this.props.talentAgencies.length} Agent${this.props.talentAgencies.length === 1 ? "" : "s"} to Contribute`}

          html={`
            <div>
              <div contenteditable="true" data-name="message"></div>
              <div style="padding:8px 0">
                Click here to view ${packName}<br/>
                <a href="${packUrl}" target="_blank" style="text-decoration:none;">${packUrl}</a>
              </div>
            </div>
          `}
        />

      </Dialog>

    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(InviteAgentsDialog);