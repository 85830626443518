import React from 'react';

import Paper from 'material-ui/Paper';

import "./index.css";

export default props => {
  const title = props.location.state.title;
  const body = props.location.state.body;
  return (
    <div className="sbui-notice-wrapper">
      <Paper zDepth={1}>
        <div className="sbui-notice">
          <h2>{title}</h2>
          <p>{body}</p>
        </div>
      </Paper>
    </div>
  );
};