import React from 'react';
import { get} from 'lodash';
import { Container, Text, Rule, useRouter } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';
import Form from './components/Form';
import Outstanding from './components/Outstanding';
import History from './components/History';

export default function(props) {

  const route = useRouter();
  const creditId = get(route, 'match.params.creditId', null);

  const data = useQuery(`{
    credit(creditId:$creditId) {
      talentId
      packId
    }
  }`, {creditId});

  return (
    <Container>
      <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
        <Text title>PAYMENT</Text>
      </div>
      <Rule/>

      <Form/>
      {data && data.credit.talentId && data.credit.packId && <Outstanding {...data.credit}/>}
      {data && data.credit.talentId && data.credit.packId && <History {...data.credit}/>}

    </Container>
  );
}