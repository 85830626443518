/* global skyboltsnow */
import React, { useCallback } from 'react';
import {
  Container,
  Text,
  Rule,
  Loading,
  Card,
  InputHTML,
  Currency,
  Button,
  Icon,
  Redirect,
} from 'skybolt-ui';
import moment from 'moment-timezone';
import { useQuery, updateCredit, updateDebit } from 'skybolt-api';
import { debounce } from 'lodash';
import "./index.css";

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});



export default function AccountsReveivableView(props) {

  // Load data

  const {match:{params:{ creditId }}} = props;

  const data = useQuery(`{
    agency {
      timezone
    }
    credit(creditId:$creditId) {
      client {
        clientId
        name
      }
      talentImage
      talentName
      packName
      packImage
      dateCheck
      dateDeposit
      checkNumber
      referenceNumber
      packName
      invoiceId
      invoiceNumber
      amount
      commission
      notes
      debitAmount
      lineItems
      status
      debit {
        debitId
        talentImage
        talentName
        dateSent
        packName
        invoiceNumber
        status
        taxAmount
        agencyAmount
        otherAmount
        amount
      }
    }
  }`, {creditId});

  let timezone = data?.agency?.timezone || moment.tz.guess();

  const debouncedUpdate = useCallback(debounce(update => {
    updateCredit({creditId, update});
  }, 500), [creditId]);

  async function deleteCredit() {
    if(data.credit.debit.status != "DRAFT") {
      skyboltsnow.alert(`Payment has already been sent to ${data.credit.debit.talentName}. Cannot delete.`);
      return;
    }
    let go = await skyboltsnow.confirm(`Are you sure you want to delete this payment. The scheduled payment to ${data.credit.debit.talentName} will also be removed.`);
    if(go !== "OK") {
      return;
    }

    updateCredit({
      creditId, 
      update:{status:"DELETED"}
    });
    updateDebit({
      debitId: data.credit.debit.debitId,
      update: {status:"DELETED"}
    });


  }

  // Render

  if(!data) {
    return <Loading/>;
  }

  if(data.credit.status == "DRAFT") {
    return <Redirect to="edit"/>;
  }

  return (
    <Container className="billingcredit">
      <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
        <Text title>PAYMENT {data.credit.status == 'DELETED' ? "(DELETED)" : ""}</Text>
        <div style={{flex:1}}/>
        {data.credit.status != 'DELETED' && 
          <Button onRule onClick={deleteCredit}>
            <Icon name="trash" size={16}/>
          </Button>
        }
      </div>
      <Rule style={{marginBottom:24}}/>

      <Card className="billingcredit__card">
        <div className="billingcredit__wrapper">
          <div className="billingcredit__info">
            {data.credit.talentName &&
              <div className="billingcredit__talent">
                <Text small>Talent</Text>
                <div>
                  {data.credit.talentImage && <img src={data.credit.talentImage} alt="headshot"/>}
                  <Text large paragraph>{data.credit.talentName}</Text>
                </div>
              </div>
            }
            {data.credit.packName &&
              <div className="billingcredit__pack">
                <Text small>Package</Text>
                <div>
                  {data.credit.packImage && <img src={data.credit.packImage} alt="package"/>}
                  <Text large paragraph>{data.credit.packName}</Text>
                </div>
              </div>
            }
            {data.credit.client.name &&
              <div className="billingcredit__client">
                <Text small>Client</Text>
                <Text large paragraph>{data.credit.client.name}</Text>
              </div>
            }
            <div className="billingcredit__datecheck">
              <Text small>Check Date</Text>
              <Text large paragraph>{data.credit.dateCheck ? moment(data.credit.dateCheck).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
            </div>
            <div className="billingcredit__datedeposit">
              <Text small>Date Deposit</Text>
              <Text large paragraph>{data.credit.dateDeposit ? moment(data.credit.dateDeposit).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
            </div>
            {data.credit.debit.dateSent &&
              <div className="billingcredit__datesent">
                <Text small>Date Paid</Text>
                <Text large paragraph>{data.credit.debit.dateSent ? moment(data.credit.debit.dateSent).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
              </div>
            }

            {data.credit.checkNumber &&
              <div className="billingcredit__checknumber">
                <Text small>Check Number</Text>
                <Text large paragraph>{data.credit.checkNumber}</Text>
              </div>
            }
            {data.credit.referenceNumber &&
              <div className="billingcredit__referencenumber">
                <Text small>Reference Number</Text>
                <Text large paragraph>{data.credit.referenceNumber}</Text>
              </div>
            }
            {data.credit.invoiceNumber &&
              <Button href={`/billing/invoices/${data.credit.invoiceId}`} className="billingcredit__invoice" style={{padding:0}}>
                <Text small>Invoice</Text>
                <Text large paragraph>{data.credit.invoiceNumber}</Text>
              </Button>
            }
            <div className="billingcredit__amount">
              <Text small>Amount</Text>
              <Text bold large paragraph>{currencyFormatter.format(data.credit.amount || 0)}</Text>
            </div>

            {data.credit.commission && 
              <div className="billingcredit__commission">
                <Text small>Commission</Text>
                <Text large paragraph>{data.credit.commission}%</Text>
              </div>
            }

            {data.credit.debit.taxAmount &&
              <div className="billingcredit__tax">
                <Text small>Tax</Text>
                <Currency large paragraph>{data.credit.debit.taxAmount || 0}</Currency>
              </div>
            }

            {data.credit.debit.agencyAmount &&
              <div className="billingcredit__tax">
                <Text small>Agency</Text>
                <Currency large paragraph>{data.credit.debit.agencyAmount || 0}</Currency>
              </div>
            }

            {data.credit.debit.otherAmount &&
              <div className="billingcredit__tax">
                <Text small>Other</Text>
                <Currency large paragraph>{data.credit.debit.otherAmount || 0}</Currency>
              </div>
            }

            {data.credit.debit.amount &&
              <div className="billingcredit__tax">
                <Text small>Net</Text>
                <Currency bold large paragraph>{data.credit.debitAmount}</Currency>
              </div>
            }

          </div>

          <InputHTML
            className="billingcredit__notes"
            title="Notes"
            hint="Only visible to agents"
            initialValue={data.credit.notes}
            onChange={notes => debouncedUpdate({notes})}
          />
        </div>
      </Card>

    </Container>
  );
}