import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadPackThread } from 'actions';

import Thread from 'ui/ThreadsList/Thread';
import Placeholder from 'ui/Placeholder';
import ShareDialog from '../ShareDialog';




export const mapStateToProps = (state, ownProps) => {
  const packId = ownProps.match.params.packId;
  const pack = !!packId ? state.packs.all[packId] : null;
  const thread = !!pack ? state.threads.all[pack.threadId] : null;
  let hasShared = false;

  if(thread) {
    const recipients = thread.recipients;
    const recipientIds = Object.keys(recipients);
    hasShared = recipientIds.length > 1 || recipients[recipientIds[0]].userId !== state.user.id;
  }

  return {
    packId,
    pack,
    thread,
    hasShared,
  };
};

export const mapDispatchToProps = {
  loadPackThread,
};



export class Discussion extends Component {
  state = {
    isShareDialogOpen: false,
  }

  UNSAFE_componentWillMount() {
    this.props.loadPackThread(this.props.packId);
  }

  openShareDialog = () => this.setState({isShareDialogOpen:true})
  closeShareDialog = () => this.setState({isShareDialogOpen:false})

  render() {
    if(!this.props.thread) {
      return null;
    }

    if(!this.props.hasShared) {
      return (
        <div>
          <Placeholder
            icon="message"
            message="This package hasn't been shared with anyone."
            label="Share Package"
            onClick={this.openShareDialog}
          />
          <ShareDialog
            open={this.state.isShareDialogOpen}
            onRequestClose={this.closeShareDialog}
            onSend={this.closeShareDialog}
            pack={this.props.pack}
          />
        </div>
      );
    }

    return (
      <Thread
        threadId={this.props.pack.threadId}
        subject="Discussion"
      />
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Discussion);