import React from 'react';
import { useQuery } from 'skybolt-api';
import { Redirect, Loading } from 'skybolt-ui';

export default function() {


  const data = useQuery(`{
    user {
      userId
      activeTalent
      permissions {
        canPack
        canViewPacks
        canOwnTalents
      }
    }
  }`);

  if(!data) {
    return <Loading/>;
  }

  if(data.user.permissions.canPack || data.user.permissions.canViewPacks) {
    return <Redirect to="/packs"/>;
  }

  if(data.user.permissions.canOwnTalents) {
    if(data.user.activeTalent.talentId) {
      return <Redirect to={`/talents/${data.user.activeTalent.talentId}`}/>;
    }
    return <Redirect to="/create-talent"/>;
  }

  return null;

}