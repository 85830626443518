/*

The user primary navigation menu.

This shows the currently active talent, and a menu of all talent.

*/

import React, { useContext, useRef, useState } from 'react';
import { useQuery, updateUser } from 'skybolt-api';
import { Icon, Menu, Button, Text, Rule, ThemeContext } from 'skybolt-ui';
import { map } from 'lodash';


export default function() {

  const data = useQuery(`{
    user {
      name: fullName
      activeDivision
      permissions {
        canPack
        canAdminAgencies
      }
    }
    agencies
    agency {
      name
      divisions
      useFitDivision
    }
  }`);

  const theme = useContext(ThemeContext);

  // Main menu

  const anchor = useRef();
  const [isOpen, setOpen] = useState(false);
  const close = () => setOpen(false);
  const open = () => setOpen(true);

  // Submenus

  const [ subMenu, setSubmenu ] = useState(null);
  const divisionsRef = useRef();
  const agenciesRef = useRef();

  const showSubmenu = menu => e => {
    e && e.stopPropagation();
    setSubmenu(menu);
  };

  // Selections

  function handleDivisionSelect(activeDivision) {
    setSubmenu(null);
    updateUser({update:{activeDivision}});
  }
  function handleAgencySelect(agency) {
    setSubmenu(null);
    updateUser({update:{agencyId:agency.agencyId, activeDivision:null}});
  }


  // Layout

  if(!data) {
    return null;
  }

  // The buttons to open the user menu.

  const buttons = (
    <Button key="button" style={{flex:'0 0 60px', padding:0, background:theme.color.accent}} ref={anchor} onClick={open}>
      <Icon name="user" style={{padding:(60-24)/2}}/>
    </Button>
  );


  // User menu.

  const menu = (
    <Menu
      key="menu"
      style={{width:250, borderTopLeftRadius:0, borderTopRightRadius:0, background:theme.color.primaryDarker, borderTop:'1px solid #aaa'}}
      anchor={anchor}
      direction="down left"
      open={isOpen}
      onRequestClose={close}>

      <div style={{padding:16}}>
        <Text color={theme.color.lightText} large>{data.user.name}</Text><br/>
        {data.user.permissions.canPack && !!data.agency.name &&
          <Text color={theme.color.lightText}>{data.agency.name}</Text>
        }
      </div>

      <Rule color={theme.color.greyDark}/>

      {data.user.permissions.canPack && !!data.agency.divisions &&
        <div>
          <Button style={{display:'block', paddingBottom:12}} onClick={showSubmenu('divisions')} ref={divisionsRef}>
            <Text color={theme.color.lightTextDark} small>Division</Text><br/>
            <Text color={theme.color.lightText}>{data.user.activeDivision || "All"}</Text>
            <Icon name="caret" small color={theme.color.lightTextDark}/>
          </Button>

          <Rule color={theme.color.greyDark}/>
        </div>
      }

      {data.user.permissions.canAdminAgencies && !!data.agencies.length &&
        <div>
          <Button style={{display:'block', paddingBottom:12}} onClick={showSubmenu('agencies')} ref={agenciesRef}>
            <Text color={theme.color.lightTextDark} small>Agency</Text><br/>
            <Text color={theme.color.lightText}>{data.agency.name}</Text>
            <Icon name="caret" small color={theme.color.lightTextDark}/>
          </Button>
          <Rule color={theme.color.greyDark}/>
        </div>
      }

      <Button color={theme.color.lightText} style={{display:'block'}} href="/user">Account Settings</Button>
      <Button color={theme.color.lightText} style={{display:'block', paddingBottom:16}} href="/logout">Log out</Button>

      <Menu
        open={subMenu === 'divisions'}
        onRequestClose={showSubmenu(null)}
        style={{
          backgroundColor: theme.color.primaryDarker,
          borderTopRightRadius: 0,
        }}
        anchor={divisionsRef}
        direction="left">
        <Button color={theme.color.lightText} style={{display:'block'}} onClick={() => handleDivisionSelect(null)}>All</Button>
        {map(data.agency.divisions, division => 
          <Button key={division} color={theme.color.lightText} style={{display:'block'}} onClick={() => handleDivisionSelect(division)}>
            {division}
          </Button>
        )}
      </Menu>

      <Menu
        open={subMenu === 'agencies'}
        onRequestClose={showSubmenu(null)}
        style={{
          backgroundColor: theme.color.primaryDarker,
          borderTopRightRadius: 0,
        }}
        anchor={agenciesRef}
        direction="left">
        {map(data.agencies, (agency, id) =>
          <Button key={id} color={theme.color.lightText} style={{display:'block'}} onClick={() => handleAgencySelect(agency)}>
            {agency.name}
          </Button>
        )}
      </Menu>

    </Menu>
  );

  return [buttons, menu];
}