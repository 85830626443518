import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { isString } from 'lodash';
import { matchPath } from 'react-router-dom';

import Icon from 'ui/Icon';

class Tab extends Component {

  static defaultProps = {
    className: "",
    exact: true,
    containerRef: ()=>null,
    style: {},
    activeStyle: {},
    labelStyle: {},
    activeLabelStyle: {},
    size: 19,
  }

  renderIcon(isActive) {
    const icon = this.props.icon;
    if(!icon) {
      return null;
    }

    const style = {
      width:this.props.size,
      height:this.props.size,
      color:'currentColor',
    };

    const showTooltip = this.props.hideLabel === true || this.props.showLabel === false;
    const tooltip = showTooltip ? this.props.label : null;

    if(isString(icon)) {
      return (
        <Icon
          name={icon}
          style={style}
          tooltip={tooltip}
        />
      );
    }

    return React.cloneElement(icon, {style:{...style, ...icon.props.style}});
  }

  renderLabel(isActive) {
    if(this.props.hideLabel === true || this.props.showLabel === false) {
      return null;
    }

    const labelStyle = isActive ? this.props.activeLabelStyle : this.props.labelStyle;
    return (
      <div className="tabs-tab-label">
        <span style={labelStyle}>{this.props.label}</span>
      </div>
    );
  }

  renderExpand() {
    if(!this.props.onExpand) {
      return null;
    }

    return (
      <div className="tabs-tab-expand" onClick={this.props.onExpand}>
        <Icon name="caret" style={{width:18, height:18}} color="#fff"/>
      </div>
    );
  }

  renderBadge() {
    if(!this.props.badge) {
      return null;
    }

    const backgroundColor = this.props.muiTheme.palette.accent1Color;

    return (
      <div
        className="tabs-tab-badge"
        style={{backgroundColor}}>
        {this.props.badge}
      </div>
    );
  }

  render() {
    if(this.props.hidden === true) {
      return null;
    }

    const currentPath = window.location.pathname;
    const {path, exact} = this.props;

    const isActive = this.props.isActive || (path && !!matchPath(currentPath, {path, exact}));
    const {
      to,
      className,
      onClick,
    } = this.props;

    const style = isActive ? this.props.activeStyle : this.props.style;

    return (
      <div
        className={`tabs-tab ${className}`}
        ref={el => this.props.containerRef(el)}>

        <div
          style={style}
          className="tabs-tab-content"
          ref={el => this.contentEl = el}>

          {!!to ? (
            <Link className="tabs-tab-link" to={to} onClick={onClick}>
              {this.renderIcon(isActive)}
              {this.renderLabel(isActive)}
            </Link>
          ) : (
            <div className="tabs-tab-link" onClick={onClick}>
              {this.renderIcon(isActive)}
              {this.renderLabel(isActive)}
            </div>
          )}

          {this.renderExpand()}

          {this.renderBadge()}

        </div>

      </div>
    );

  }
}

export default muiThemeable()(Tab);