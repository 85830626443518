import React, { Component } from 'react';
import { connect } from 'react-redux';
import { acceptPackRequest } from 'actions';
import { get } from 'lodash';

import Placeholder from 'ui/Placeholder';
import Loading from 'ui/Loading';






const mapStateToProps = (state, ownProps) => {
  const requestId = ownProps.match.params.requestId;
  const canPack = get(state, 'user.permissions.canPack', false);
  const canAccept = canPack;

  return {
    requestId,
    canAccept,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  acceptPackRequest,
};








class AcceptPackRequest extends Component {
  
  async componentDidMount() {
    if(this.props.canAccept) {
      const packId = await this.props.acceptPackRequest(this.props.requestId);
      this.props.history.push(`/packs/${packId}`);
    }
  }
  
  render() {
    if(this.props.canAccept) {
      return (
        <div style={{paddingTop:80}}>
          <Loading/>
        </div>
      );
    }
    return (
      <div style={{paddingTop:80}}>
        <Placeholder 
          icon="pack"
          message={"You need to be logged in to accept a package request with skybolt"}
          label="Log in here"
          onClick={()=>this.props.history.push(`/login`)}
        />
      </div>
    );
  }

}



export default connect(mapStateToProps, mapDispatchToProps)(AcceptPackRequest);