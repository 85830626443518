import React, { useContext } from 'react';
import { useQuery, createPack, addTalentToPack } from 'skybolt-api';
import InputClient from './InputClient';
import { InputSelect, InputDate, InputNumber, InputHTML, InputText, FormContext, Modal, Text } from 'skybolt-ui';

export default function({talentId, ...props}) {

  const data = useQuery(`{
    packs(talentId:$talentId, type:"job") {
      packId
      name
      type
    }
  }`, {talentId});

  const form = useContext(FormContext);

  if(!data) {
    return null;
  }

  const packId = form.get('packId');
  let options = data && talentId ? data.packs : [];

  async function emitChange(packId) {
    let pack;
    if(packId === "create") {
      if(!talentId) {
        await Modal.error("You must select a talent before creating a job.");
        return;
      }

      const createForm = (
        <div>
          <Text large bold paragraph>Create Package</Text>
          <InputText title="Package Name" name="name"/>
          <InputClient title="Client"/>
          <InputNumber title="Commission" name="billingCommission" hint="Percentage"/>
          <InputText title="Product Title" name="product"/>
          <InputDate title="Job Date" name="date"/>
          <InputDate title="Max Use Date" name="billingMaxUseDate"/>
          <InputHTML title="Notes" name="notes"/>
        </div>
      );
      const job = await Modal.form(createForm, {yes:"Create", no:"Cancel"});
      if(!job) {
        return;
      }
      pack = await createPack({...job, type:"job"});
      options.push(pack);
      await addTalentToPack({talentId, packId:pack.packId});
    }
    else {
      pack = data.packs.find(p => p.packId === packId);
    }
    if(!pack) {
      return;
    }
    form.update({
      packId: pack.packId,
      packName: pack.name,
    });
    if(props.onChange) {
      props.onChange(pack);
    }
  }

  return (
    <InputSelect
      value={packId}
      onChange={emitChange}
      options={[
        ...options.map(pack => ({value:pack.packId, label:pack.name})),
        {value:"create", label:"Create Package"}
      ]}
      {...props}
    />
  );
}