import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Switch, Route, Redirect } from 'react-router-dom';
import Title, { Tab } from 'ui/Title';
import Container from 'ui/Container';
import MessageAgencyTalentsDialog from 'ui/MessageAgencyTalentsDialog';

import All from './All';
import Unread from './Unread';
import Archived from './Archived';



const mapStateToProps = (state, ownProps) => {
  const url = ownProps.match.url;
  const path = ownProps.match.path;
  const canMessageAllTalents = state.user.permissions.canPack;
  
  return {
    url,
    path,
    canMessageAllTalents,
  };
};

const mapDispatchToProps = {
};



class Messages extends Component {
  
  state = {
    isMessageTalentsDialogOpen: false,
  }
  
  render() {
    
    const actions = [];
    // if(this.props.canMessageAllTalents) {
    //   actions.push(
    //     <Action 
    //       tooltip={"Message All Talent"} 
    //       icon="multimessage"
    //       onClick={() => this.setState({isMessageTalentsDialogOpen:true})}
    //     />
    //   );
    // }
    
    const {url, path} = this.props;
    const tabs = [
      <Tab path={`${path}/all`} exact={false} to={`${url}/all`} label="All"/>,
      <Tab path={`${path}/unread`} exact={false} to={`${url}/unread`} label="Unread"/>,
      // <Tab path={`${path}/archived`} exact={false} to={`${url}/archived`} label="Archived"/>
    ];
    
    return (
      <Container className="messages-container" onClick={this.close}>
        <Title 
          actions={actions}
          tabs={tabs}
          style={{marginBottom:16}}>
          Messages
        </Title>
        
        <Switch>
          <Route path={`${path}/all`} component={All}/>
          <Route path={`${path}/unread`} component={Unread}/>
          <Route path={`${path}/archived`} component={Archived}/>
          
          {/* This will redirect links to `/messages/{id}` from things like
          text messages to the new `/messages/all/{id}`. If you've changed 
          those links and given it a little time, you can probably remove. */}
          <Route path={`${path}/:threadId`} render={props => 
            <Redirect to={`${url}/all/${props.match.params.threadId}`}/>
          }/>
          
          <Redirect to={`${url}/all`}/>
        </Switch>
        
        
        <MessageAgencyTalentsDialog 
          open={this.state.isMessageTalentsDialogOpen}
          onRequestClose={() => this.setState({isMessageTalentsDialogOpen:false})}
        />
        
      </Container>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Messages);