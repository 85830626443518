import React, { useState } from 'react';
import { useQuery } from 'skybolt-api';
import { Card, Text, Currency, Button } from 'skybolt-ui';
import moment from 'moment-timezone';

export default function(props) {

  const [limit, setLimit] = useState(5);

  const data = useQuery(`{
    agency {
      timezone
    }
    debits(status:"SENT", talentId:$talentId, packId:$packId, limit:$limit)
  }`, {talentId:props.talentId, packId:props.packId, limit});

  if(!data) {
    return null;
  }

  if(data.debits.length === 0) {
    return null;
  }

  let timezone = data?.agency?.timezone || moment.tz.guess();

  return (
    <div>
      <Text title paragraph>History</Text>

      <div style={{display:'flex', padding:"0 16px", marginBottom:4}}>
        <Text block style={{flex:1}}>Date</Text>
        <Text block style={{flex:1}}>Pay Date</Text>
        <Text block style={{flex:1}}>Cycle Date</Text>
        <Text block style={{flex:1}}>Pay Type</Text>
        <Text block style={{flex:1}}>Use Type</Text>
        <Text block style={{flex:1, textAlign:'right'}}>Gross</Text>
        <Text block style={{flex:1, textAlign:'right'}}>Tax</Text>
        <Text block style={{flex:1, textAlign:'right'}}>Other</Text>
        <Text block style={{flex:1, textAlign:'right'}}>Agency</Text>
        <Text block style={{flex:1, textAlign:'right'}}>Amount</Text>
      </div>

      {data.debits.map(debit =>
        <Card
          key={debit.debitId}
          style={{
            flex:1,
            display:'flex',
            alignItems:'center',
            padding: "0 16px",
            marginBottom:4,
            height:40,
            backgroundColor: 'white',
          }}>
          <div style={{flex:1}}>
            <Text>{moment(debit.date).tz(timezone).format("MM/DD/YYYY")}</Text>
          </div>
          <div style={{flex:1}}>
            <Text>{debit.dateCheck ? moment(debit.dateCheck).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
          </div>
          <div style={{flex:1}}>
            <Text>{debit.dateCycle ? moment(debit.dateCycle).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
          </div>
          <div style={{flex:1}}>
            <Text>{debit.payType}</Text>
          </div>
          <div style={{flex:1}}>
            <Text>{debit.useType}</Text>
          </div>
          <div style={{flex:1, textAlign:'right'}}>
            <Currency>{debit.grossAmount}</Currency>
          </div>
          <div style={{flex:1, textAlign:'right'}}>
            <Currency>{debit.taxAmount}</Currency>
          </div>
          <div style={{flex:1, textAlign:'right'}}>
            <Currency>{debit.otherAmount}</Currency>
          </div>
          <div style={{flex:1, textAlign:'right'}}>
            <Currency>{debit.agencyAmount}</Currency>
          </div>
          <div style={{flex:1, textAlign:'right'}}>
            <Currency bold>{debit.amount}</Currency>
          </div>
        </Card>
      )}

      {data.debits.length === limit &&
        <nav>
          <Button onClick={() => setLimit(limit + 5)}>Load More</Button>
        </nav>
      }
    </div>
  );
}