import React from 'react';
import { isArray, isNaN, min, max, range } from 'lodash';
import TextField from 'material-ui/TextField';

export default class RangeInput extends React.Component {
    
  static defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    initialValue: null,
    onChange: ()=>null,
  }  
  
  state = {
    from: "",
    to: "",
  }
  
  componentDidMount() {
    if(isArray(this.props.initialValue)) {
      this.setState({
        from: min(this.props.initialValue),
        to: max(this.props.initialValue),
      });
    }
  }
  
  change = (e, from, to) => {
    this.setState({from, to});
    
    let step = this.props.step ? parseFloat(this.props.step) : 1;
    if(step === 0) {
      step = 1;
    }
    const maxVal = this.props.max;
    const minVal = this.props.min;
    
    from = parseFloat(from);
    to = parseFloat(to);
    
    if(isNaN(from) && isNaN(to)) {
      this.props.onChange(e, null);
      return;
    }
    
    if(isNaN(from)) {
      to = Math.max( Math.min(to, maxVal), minVal);
      this.props.onChange(e, [to]);
      return;
    }
    
    if(isNaN(to)) {
      from = Math.max( Math.min(from, maxVal), minVal);
      this.props.onChange(e, [from]);
      return;
    }
    
    to = Math.max( Math.min(to, maxVal), minVal);
    from = Math.max( Math.min(from, maxVal), minVal);
    
    this.props.onChange(e, range(from, to+step, step));
  }
  
  render() {
    return (
      <div style={{display:'flex'}}>
        <TextField
          name="from"
          style={{width:'50%', maxWidth:300}}
          fullWidth
          hintText="From"
          value={this.state.from || ""}
          onChange={(e, from) => this.change(e, from, this.state.to)}
        />
        <TextField
          name="to"
          style={{width:'50%', maxWidth:300}}
          fullWidth
          hintText="To"
          value={this.state.to || ""}
          onChange={(e, to) => this.change(e, this.state.from, to)}
        />
      </div>
    );
  }
}