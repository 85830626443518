/*

# Message Talent Dialog

A dialog window to create a new message to a single talent.

Props:

- talentId
- open
- onRequestClose

*/


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadTalent, addThread, addMessage } from 'actions';

import Dialog from 'ui/Dialog';
import MessageComposer from 'ui/MessageComposer';



export const mapStateToProps = (state, ownProps) => {
  let talentId = ownProps.talentId;
  let talent = state.talents.all[talentId];

  if(!talent) {
    return {isLoading:true, talentId, ...ownProps};
  }

  return {
    talentId,
    to: [{
      name: `${talent.firstName} ${talent.lastName}`.trim(),
      talentId,
    }],
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  loadTalent,
  addThread,
  addMessage,
};



export class MessageTalentDialog extends Component {
  UNSAFE_componentWillMount() {
    this.props.loadTalent(this.props.talentId);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.talentId !== this.props.talentId) {
      this.props.loadTalent(this.props.talentId);
    }
  }

  send = async message => {
    this.props.onRequestClose();

    let thread = await this.props.addThread({
      recipients: message.to,
      subject: message.subject,
    });

    let res = await this.props.addMessage(thread.id, message);
    console.log(res);
    return res;
  }

  render() {
    if(this.props.isLoading) {
      return null;
    }

    return (
      <Dialog
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
        bodyStyle={{backgroundColor:'#fafafa'}}>

        <MessageComposer
          draftKey={`talent${this.props.talentId}`}
          onCancel={this.props.onRequestClose}
          onSend={this.send}
          showToField={false}
          showBCCSelf={true}
          to={this.props.to}
        />

      </Dialog>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageTalentDialog);