import React, { useContext, useRef, useState } from 'react';
import {
  Form,
  InputText,
  InputSwitch,
  InputSelect,
  Text,
  Rule,
  Icon,
  Card,
  useRouter,
  ThemeContext,
  Button,
} from 'skybolt-ui';

import { useQuery, updateSpotlight } from 'skybolt-api';


export default function SpotlightInfo(props) {

  const { match, location } = useRouter();
  const theme = useContext(ThemeContext);

  const spotlightId = match.params.spotlightId;
  if(!spotlightId) {
    location.replace('/spotlights');
  }


  // Load data.

  const data = useQuery(`{
    spotlight(spotlightId:$id) {
      name
      numTalents
      width
      height
      backgroundColor
      thumbnailSize
      showThumbnailInfo
    }
  }`, {id:spotlightId});


  // Update

  function update(data) {
    Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
    updateSpotlight({spotlightId, data});
  }


  // Copy code snippet to clipboard

  const snippetRef = useRef();
  const [coppied, setCoppied] = useState(false);
  function copy() {
    const el = document.createElement('textarea');
    el.value = snippetRef.current.innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCoppied(true);
    setTimeout(() => setCoppied(false), 2000);
  }


  // Loading

  if(!data) {
    return (
      <div style={{padding:"160px 16px"}}>
        <Icon name="loading" large/>
      </div>
    );
  }


  // Spotlight Form

  return (
    <Form value={data.spotlight} onChange={update} style={{paddingBottom:40}}>
      <Text title>Spotlight Info.</Text>
      <Rule style={{marginBottom:24}}/>
      <Card style={{padding:16}}>
        <div style={{maxWidth:600}}>
          <InputText name="name" title="Name"/>
          <Text paragraph>
            <Text>{data.spotlight.numTalents} <Text small color="textLight">talent.</Text></Text>
          </Text>
        </div>
      </Card>

      <Text title style={{marginBottom:8}}>Features</Text>
      <Card style={{padding:16}}>
        <InputSwitch
          name="allowCastingRequests"
          title="Allow Casting Requests"
          hint="This will allow spotlight viewers to select talent and create a casting request."
        />
      </Card>

      <Text title style={{marginBottom:8}}>Appearance</Text>
      <Card style={{padding:16}}>
        <div style={{maxWidth:600}}>
          <InputText name="width" title="Container Width" hint="Leave blank to have spotlight fill available width."/>
          <InputText name="height" title="Container Height" hint="Leave blank to have spotlight scale to fit content."/>
          <InputText name="backgroundColor" title="Background Color" hint="Hex. value for background color."/>
          <InputSelect name="thumbnailSize" title="Thumbnail Size" options={["small", "large"]}/>
          <InputSwitch name="showThumbnailInfo" title="Show talent info on thumbnails"/>
        </div>
      </Card>

      <Text title style={{marginBottom:8}}>Embed Code</Text>
      <Card style={{padding:16}}>
        <Text paragraph>Copy and paste the code below into your site where you want the spotlight to appear.</Text>
        <div ref={snippetRef} style={{paddingBottom:8}}>
          <Text
            style={{
              display:'block',
              backgroundColor: theme.color.greyLight,
              padding: 16,
              color: theme.color.textLight,
              fontFamily: 'monospace',
            }}>
            {`<iframe src="https://beta.skybolt.net/embed/spotlight/${spotlightId}" style="border:none;" name="${data.spotlight.name}" id="skybolt" frameborder="0" marginheight="0px" marginwidth="0px" height="${data.spotlight.height || "100%"}" width="${data.spotlight.width || "100%"}"></iframe><script type="application/javascript" src="https://beta.skybolt.net/embed.js"></script>`}
          </Text>
        </div>
        <Button raised onClick={copy} style={{marginRight:16}}>{coppied ? "Coppied" : "Copy to Clipboard"}</Button>
        <a href={`https://beta.skybolt.net/embed/spotlight/${spotlightId}`} target="_blank" rel="noopener noreferrer" style={{color:theme.color.text}}>
          <Button raised>Preview</Button>
        </a>
      </Card>
    </Form>
  );
}