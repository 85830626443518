import React from 'react';
import { useQuery } from 'skybolt-api';
import {
  Container,
  Header,
  Icon,
  Switch,
  Route,
  Redirect,
  useRouter,
} from 'skybolt-ui';
import Info from './Info';
import Fields from './Fields';
import Messaging from './Messaging';
import Settings from './Settings';
import Talents from './Talents';

export default function Agency(props) {

  const agencyId = props.match.params.agencyId;
  const {match:{path, url}} = useRouter();
  const data = useQuery(`{
    agency(agencyId:$agencyId) {
      agencyId
      name
      image(width:150)
    }
  }`, {agencyId});

  if(!data) {
    return (
      <div style={{textAlign:"center", paddingTop:120}}>
        <Icon name="loading" size={120} color="greyLight"/>
      </div>
    );
  }

  return (
    <Container>
      <Header
        image={data.agency.image}
        name={data.agency.name}
        style={{marginTop:32, marginBottom:24}}
        tabs={[
          {label:"Info.", icon:"agency", href:`${url}/info`},
          {label:"Talent Fields", icon:"talent", href:`${url}/fields`},
          {label:"Messaging", icon:"messages", href:`${url}/messaging`},
          {label:"", icon:"settings", href:`${url}/settings`},
        ]}
      />

      <Switch>
        <Route path={`${path}/info`} component={Info}/>
        <Route path={`${path}/fields`} component={Fields}/>
        <Route path={`${path}/messaging`} component={Messaging}/>
        <Route path={`${path}/settings`} component={Settings}/>
        <Route path={`${path}/talents`} component={Talents}/>
        <Redirect to={`${url}/info`}/>
      </Switch>
    </Container>
  );
}