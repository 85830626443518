import React from 'react';
import { useQuery } from 'skybolt-api';
import {
  Form,
  Text,
  InputText,
  Rule,
  Loading,
  Card,
  useRouter,
} from 'skybolt-ui';

export default props => {

  const route = useRouter();

  const data = useQuery(`{
    agency(agencyId:$agencyId)
    user {
      permissions {
        canAdminAgencies
      }
    }
  }`, {agencyId:route.match.params.agencyId});

  if(!data) {
    return <Loading/>;
  }

  function handleChange(val) {
    console.log('change', val);
  }

  return (
    <Form initialValue={data.agency} onChange={handleChange}>
      <div style={{display:'flex', alignItems:'center', height:40}}>
        <Text title>SETTINGS</Text>
      </div>
      <Rule style={{marginBottom:24}}/>

      <Card style={{padding:16}}>
        <div style={{maxWidth:600}}>
          <InputText
            name="notes"
            title="Notes"
          />
        </div>
      </Card>


    </Form>
  );
};
