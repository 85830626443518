import React from 'react';
import { connect } from 'react-redux';
import {Icon} from 'skybolt-ui';
import { linkGoogle } from 'actions';

import './index.css';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps
  };
};

const mapDispatchToProps = {
  linkGoogle
};

class LinkingGoogle extends React.Component {
  async componentDidMount() {
    const { linkGoogle, history:{ replace } } = this.props;
    await linkGoogle(window.location.search);
    replace(`/user`);
  }
  
  render() {
    return (
      <div className="linking-google">
        <div className="linking-google-body">
          <Icon name='loading'/>
          <span className="linking-google-message">
            Linking to your google account...
          </span>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkingGoogle);