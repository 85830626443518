import React from 'react';
import { Text } from 'skybolt-ui';

export default function() {
  return (
    <div style={{display:'flex', boxSizing:'border-box', padding:'0 16px 8px', minWidth:1200}}>
      <div style={{flex:1}}>
        <Text small light>Job Date</Text>
      </div>
      <div style={{flex:1}}>
        <Text small light>Check #</Text>
      </div>
      <div style={{flex:2}}>
        <Text small light>Package</Text>
      </div>
      <div style={{flex:2}}>
        <Text small light>Client</Text>
      </div>
      <div style={{flex:1}}>
        <Text small light>Use Type</Text>
      </div>
      <div style={{flex:1}}>
        <Text small light>Pay Type</Text>
      </div>
      <div style={{flex:1}}>
        <Text small light>Max Use Date</Text>
      </div>
      <div style={{flex:1}}>
        <Text small light>Invoice #</Text>
      </div>
      <div style={{flex:1}}>
        <Text small light>Gross</Text>
      </div>
      <div style={{flex:1}}>
        <Text small light>Tax</Text>
      </div>
      <div style={{flex:1}}>
        <Text small light>Other</Text>
      </div>
      <div style={{flex:1}}>
        <Text small light>Agency</Text>
      </div>
      <div style={{flex:1}}>
        <Text small light>Net</Text>
      </div>
    </div>
  )
}