import { combineReducers } from "redux";

const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_TALENT_MEDIA_SUCCESS':
      return {
        ...state,
        ...action.media,
      };
      
    case 'UPDATE_MEDIA':
    case 'LOAD_MEDIA':
    case 'ADD_MEDIA':
      return {
        ...state,
        [action.mediaId]: {...state[action.mediaId], ...action.media},
      };
      
    case 'REMOVE_MEDIA':
      const { [action.mediaId]:removedMedia, ...rest } = state;
      return rest;
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byTalent = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_TALENT_MEDIA_REQUEST':
      return {
        ...state,
        [action.talentId]: {
          isLoading: true,
        }
      };
      
    case 'LOAD_TALENT_MEDIA_SUCCESS':
      return {
        ...state,
        [action.talentId]: action.media
      };
      
    case 'UPDATE_MEDIA':
      let updatedMedia = {};
      
      for(const talentId in state) {
        updatedMedia[talentId] = {};
        
        for(const mediaId in state[talentId]) {
          updatedMedia[talentId][mediaId] = (mediaId === action.mediaId) 
            ? action.media 
            : state[talentId][mediaId];
        }
        
      }
      
      return updatedMedia;
      
    case 'LOAD_MEDIA':
      if(!action.media.talentId) {
        return state;
      }
      return {
        ...state,
        [action.media.talentId]: {
          ...state[action.media.talentId],
          [action.media.id]: action.media,
        }, 
      };
      
    case 'ADD_MEDIA':
      if(!action.talentId) {
        return state;
      }
      return {
        ...state,
        [action.talentId]: {
          ...state[action.talentId],
          [action.mediaId]: action.media,
        }, 
      };
      
    case 'REMOVE_MEDIA':
      let afterRemoveMedia = {};
      
      for(const talentId in state) {
        afterRemoveMedia[talentId] = {};
        
        for(const mediaId in state[talentId]) {
          if((mediaId !== action.mediaId) ) {
            afterRemoveMedia[talentId][mediaId] = state[talentId][mediaId];
          }
        }
        
      }
      
      return afterRemoveMedia;
    
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const forApproval = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_UNAPPROVED_MEDIA_SUCCESS':
      return action.media;
      
    case 'UPDATE_MEDIA':
      if(action.media.approved) {
        const { [action.mediaId]:removedMedia, ...rest } = state;
        return rest;
      }
      return state;
      
    case 'REMOVE_MEDIA':
      const { [action.mediaId]:removedMedia, ...rest } = state;
      return rest;
    
    case 'SET_AGENCY':
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};



export default combineReducers({
  all,
  byTalent,
  forApproval
});