import React from 'react';

export default ({children, style, ...props}) => (
  <div 
    style={{
      color: "#aaaaaa",
      fontSize: 13,
      lineHeight: "15px",
      ...style
    }}
    {...props}>
    {children}
  </div>
);