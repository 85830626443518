import React, { useEffect, useState } from 'react';
import { Icon, Text } from 'skybolt-ui';
import { createClient } from 'skybolt-api';

export default function CreateClient(props) {

  const [error, setError] = useState(null);

  useEffect(() => {
    createClient({})
      .then(client =>
        props.history.replace(client.clientId)
      )
      .catch(error => {
        setError(error);
      });
  }, []);

  if(error) {
    return (
      <div style={{textAlign:"center", paddingTop:80}}>
        <div>
          <Icon name="bomb" size={120} color="error"/>
        </div>
        <Text>{error.message}</Text>
      </div>
    );
  }

  return (
    <div style={{textAlign:"center", paddingTop:80}}>
      <div>
        <Icon name="loading" size={120} color="greyLight"/>
      </div>
      <Text small>Creating Client...</Text>
    </div>
  );
}