/*

# Thread List



Example

    <ThreadsList 
      threads={threads}
      onLazyLoad={loadMore}
    />


*/



import React from 'react';
import moment from 'moment';
import { Route, withRouter } from 'react-router-dom';

import './index.css';

import Date from './Date';
import ThreadLI from './ThreadLI';
import Thread from './Thread';



export class ThreadsList extends React.Component {
  
  static defaultProps = {
    threads: {},
    onLazyLoad: ()=>null,
  }
  
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }
  
  handleScroll = e => {
    if(!this.props.onLazyLoad) {
      return;
    }
    
    var rect = this.lastElement.getBoundingClientRect();
      
    if(rect.y - 200 < (window.innerHeight || document.documentElement.clientHeight)) {
      this.props.onLazyLoad();
    }
  }
  
  render() {
    const {
      showEmptyThreads=false,
      match: { url, path },
      history,
      location,
    } = this.props;
    
    let threads = this.props.threads;
    if(!Array.isArray(threads)) {
      threads = Object.keys(threads)
        .map(threadId => ({threadId, ...threads[threadId]}));
    }
    
    return <Route path={`${path}/:threadId?`} render={props => {
      let date = null;
      const list = [];
      const threadId = props.match.params.threadId;
      
      threads
        .sort((a, b) => a.dateUpdated < b.dateUpdated ? 1 : -1)
        .filter(thread => !!showEmptyThreads || !!thread.hasMessages)
        .forEach(thread => {
          if(!date || !moment(thread.dateUpdated).isSame(date, 'day')) {
            date = thread.dateUpdated;
            list.push(
              <Date key={`${thread.threadId}-date`} date={date}/>
            );
          }
          
          if(thread.threadId === threadId) {
            list.push(
              <Thread 
                key={thread.threadId}
                thread={thread}
                onClose={() => history.replace({pathname:url, state:location.state})}
              />
            );
          }
          else {
            list.push(
              <ThreadLI 
                key={thread.threadId}
                thread={thread}
                onSelect={() => history.replace({pathname:`${url}/${thread.threadId}`, state:location.state})}
              />
            );
          }
        });
        
      list.push(<div key="lastelement" ref={el => this.lastElement = el}/>);
      
      return list;
    }}/>;
    
  }
}

export default withRouter(ThreadsList);
