import React from 'react';
import {
  Container,
  Text,
  Rule,
  Loading,
  useRouter,
} from 'skybolt-ui';
import SendModalButton from '../components/SendModalButton';
import { useQuery } from 'skybolt-api';



// TODO:
// [ ] query messages by invoice id
// [ ] share invoice

export default function(props) {

  const {
    match: {
      params:{
        invoiceId
      }
    }
  } = useRouter();


  const data = useQuery(`{
    threads(invoiceId:$invoiceId)
  }`, {invoiceId});




  // Actions





  // Render

  if(!data) {
    return <Loading/>;
  }

  return (
    <Container>
      <div style={{display:"flex", alignItems:"flex-end", height:40, paddingTop:32}}>
        <Text title>Messages</Text>
        <div style={{flex:1}}/>
        <SendModalButton/>
      </div>
      <Rule/>



    </Container>
  );
}