import React from 'react';
import { useRouter, Container, Text, Rule, Card, Button } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';
import { get } from 'lodash';

export default function() {
  const {
    match: {
      params: {
        invitationId
      }
    }
  } = useRouter();

  const data = useQuery(`{
    invitation(invitationId:$invitationId) {
      email
      talentId
    }
  }`, {invitationId});

  const email = get(data, 'invitation.email', null);
  const talentId = get(data, 'invitation.talentId', null);

  return (
    <Container>
      <div style={{paddingTop:32}}>
        <Text title>INVITE TALENT</Text>
      </div>
      <Rule style={{marginBottom:24}}/>
      <Card style={{padding:16}}>
        <Text large paragraph>An invitation has been sent{email ? ` to ${email}.` : '.'}</Text>
        {talentId && <Button raised href={`/talents/${talentId}`} style={{marginRight:16}}>View Talent</Button>}
        <Button raised href="/admin/invite-talent">Send another Invitation</Button>
      </Card>
    </Container>
  );
}