import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { isEmpty, get } from 'lodash';
import { resumeSections } from 'config';
import Title from 'ui/Title';
import Page from 'ui/Page';
import Placeholder from 'ui/Placeholder';


export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.talentId;
  const talent = state.talents.all[talentId];
  const canEdit = state.user.isLoggedIn;
  const agencyId = talent.agencyId;
  const sections = get(state, `agencies.all[${agencyId}].resumeSections`, resumeSections);
  
  return {
    talentId,
    talent,
    canEdit,
    sections,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
};





function TalentResumeDisplay(props) {
  const wrapperRef = useRef();
  
  if(!props.talent) {
    return null;
  }

  console.log(props.talent);
  
  const bio = props.talent.bio || "";
  const skills = props.talent.skills || "";
  const experience = props.talent.experience || {};
  const canEdit = props.canEdit;
  const sections = props.sections;
  
  if(isEmpty(bio) && isEmpty(skills) && isEmpty(experience)) {
    if(canEdit) {
      return (
        <Placeholder 
          icon="resume"
          message="Add a bio, experience, and special skills to build your resume."
          label="Edit"
          onClick={props.onEdit}
        />
      );
    }
    return (
      <Placeholder 
        icon="resume"
        message="No resume."
      />
    );
  }
  
  const byType = Object.keys(experience || {})
    .reduce(function(byType, id) {
      const item = experience[id];
      if(!byType[item.type]) byType[item.type] = [];
      byType[item.type].unshift({...item, id});
      return byType;
    }, {});
  
  return (
    <div ref={wrapperRef}>
      {bio && <Page pad={true}>{bio}</Page>}
      
      {sections.map(type => {
        if(isEmpty(byType[type])) {
          return null;
        }
        return (
          <div key={type}>
            <Title>{type}</Title>
            <Page pad={true}>
              {byType[type]
                .sort((a, b) => {
                  const aOrder = a.order || 0;
                  const bOrder = b.order || 0;
                  return aOrder > bOrder ? 1 : -1;
                })
                .map(item => 
                  <div key={item.id} style={{display:'flex', borderBottom:'1px solid #fafafa'}}>
                    <div style={{flex:1, padding:"8px 4px"}}>{item.title}</div>
                    <div style={{flex:1, padding:"8px 4px"}}>{item.role}</div>
                    <div style={{flex:1, padding:"8px 4px"}}>{item.company}</div>
                  </div>
                )
              }
            </Page>
          </div>
        );
      })}
      
      
      {skills && 
        <div>
          <Title>Skills</Title>
          <Page pad={true}>{skills}</Page>
        </div>
      }
    </div>
  );
}




export default connect(mapStateToProps, mapDispatchToProps)(TalentResumeDisplay);