import React, { useState } from 'react';
import { useQuery, invite } from 'skybolt-api';
import { Container, Card, Form, InputText, InputSelect, Text, Rule, Button, useRouter } from 'skybolt-ui';

export default function() {

  const { history } = useRouter();

  const data = useQuery(`{
    agency {
      agencyId,
      divisions
    }
  }`);

  const [value, setValue] = useState({});

  if(!data) {
    return null;
  }

  let divisions = [];
  if(Array.isArray(data.agency.divisions)) {
    divisions = data.agency.divisions;
  }

  async function send() {
    const invitationId = await invite({
      agencyId: data.agency.agencyId,
      email: value.email,
      user: {
        agencyId: data.agency.agencyId,
        firstName: value.firstName,
        lastName: value.lastName,
        role: 'agent',
        email: value.email,
        permissions: {
          canPack: true,
          canMessageTalents: true,
          canEditTalents: true,
          canApproveTalents: true,
          canApproveMedia: true,
          canConfigAgency: true,
          canAddTalents: true,
          canNoteTalents: true,
          canAgencyCalendar: true,
          canOwnTalents: null,
        },
        divisions: value.divisions,
      }
    });
    history.replace(`/admin/invite-agent/sent/${invitationId}`);
  }

  return (
    <Container>
      <div style={{paddingTop:32}}>
        <Text title>INVITE AGENT</Text>
      </div>
      <Rule style={{marginBottom:24}}/>
      <Card style={{padding:16}}>
        <Form onChange={setValue} value={value} style={{maxWidth:600}}>
          <InputText title="First Name" name="firstName"/>
          <InputText title="Last Name" name="lastName"/>
          <InputText title="Email Address" name="email"/>
          <InputSelect options={divisions} multiple title="Divisions" name="divisions"/>
          <Button accent raised onClick={send}>Send Invitation</Button>
        </Form>
      </Card>
    </Container>
  );
}