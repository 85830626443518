import { combineReducers } from "redux";


const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_UNREAD_MESSAGES':
      return action.messages;
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byTalent = (state={}, action) => {
  let newState = {};
  
  switch(action.type) {
    case 'LOAD_UNREAD_MESSAGES':
      Object.keys(action.messages)
        .map(id => ({id, ...action.messages[id]}))
        .forEach(message => {
          Object.keys(message.talents || {}).forEach(talentId => {
            if(!newState[talentId]) 
              newState[talentId] = {};
            newState[talentId][message.id] = message;
          });
        });
      return newState;
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byPack = (state={}, action) => {
  let newState = {};
  
  switch(action.type) {
    case 'LOAD_UNREAD_MESSAGES':
      Object.keys(action.messages)
        .map(id => ({id, ...action.messages[id]}))
        .forEach(message => {
          Object.keys(message.packs || {}).forEach(packId => {
            if(!newState[packId]) 
              newState[packId] = {};
            newState[packId][message.id] = message;
          });
        });
      return newState;
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byThread = (state={}, action) => {
  let newState = {};
  
  switch(action.type) {
    case 'LOAD_UNREAD_MESSAGES':
      Object.keys(action.messages)
        .map(id => ({id, ...action.messages[id]}))
        .forEach(message => {
          if(message.threadId) {
            if(!newState[message.threadId]) {
              newState[message.threadId] = {};
            }
            newState[message.threadId][message.id] = message;
          }
          if(message.parentThreadId) {
            if(!newState[message.parentThreadId]) {
              newState[message.parentThreadId] = {};
            }
            newState[message.parentThreadId][message.id] = message;
          }
        });
      return newState;
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};


export default combineReducers({
  all,
  byTalent,
  byPack,
  byThread,
});