import React from 'react';
import {
  Text,
  Rule,
} from 'skybolt-ui';

export default props => {

  return (
    <div>
      <div style={{display:'flex', alignItems:'center', height:40}}>
        <Text title>MESSAGING</Text>
      </div>
      <Rule/>


    </div>
  );
};
