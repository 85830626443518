import React, { Component } from 'react';
import { connect } from 'react-redux';

import Title, { Action } from 'ui/Title';
import Form from './Form';
import Display from './Display';
import FirstMile from './FirstMile';


export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const talent = state.talents.all[talentId];
  const isLoggedIn = state.user.isLoggedIn;
  const user = state.user;
  const isOwnTalent = user.isLoggedIn && !!user.talents && !!user.talents[talentId];
  const canEdit = isOwnTalent || (isLoggedIn && talent.agencyId === user.agencyId && user.permissions.canEditTalents) || (isLoggedIn && user.permissions.canAdminTalents);
  const showFirstMile = false;//user.permissions.canAdminApp;
  
  return {
    showFirstMile,
    talentId,
    talent,
    canEdit,
  };
};

export const mapDispatchToProps = {
};




export class TalentResume extends Component {
  
  state = {
    isEditing: false,
    showFirstMile: false, // remove once in database
  }
  
  // // remove one in database
  componentDidMount() {
    this.setState({showFirstMile: this.props.showFirstMile});
  }
  
  
  toggleEditing = () => this.setState({isEditing:!this.state.isEditing})
  
  
  render() {
    
    const Content = this.state.showFirstMile ? FirstMile :
                    this.state.isEditing ? Form : 
                    Display;
    
    return (
      <div>
        <Title 
          actions={[
            <Action 
              icon={this.state.isEditing ? 'close' : 'edit'} 
              onClick={this.toggleEditing}
              hidden={!this.props.canEdit}
            />
          ]}>
          Resume
        </Title>
        
        <Content 
          talentId={this.props.talentId} 
          onEdit={this.toggleEditing}
          useWindowAsScrollContainer={this.props.useWindowAsScrollContainer}
          onClick={() => this.setState({showFirstMile:false})}
        />
        
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(TalentResume);