/*

# Event

## Props

- event
- style
- actions


*/


import React, { Component } from 'react';
import { connect } from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import moment from 'moment';
import { loadPack, removeEvent, updateEvent } from 'actions';
import _ from 'lodash';

import Page from 'ui/Page';
import Media from 'ui/Media';
import Icon from 'ui/Icon';
import Button from 'ui/Button';
import Dialog from 'ui/Dialog';

import './index.css';




export const mapStateToProps = (state, ownProps) => {
  const event = ownProps.event || {};

  let pack = null;
  if(event.packId) {
    pack = state.packs.all[event.packId];
  }


  const user = state.users.all[state.user.id];
  let isOwnEvent = false;
  try {
    isOwnEvent = user.talents[event.talentId];
  }
  catch(err){}
  const canEdit = isOwnEvent || user.permissions.canPack;


  let canRemove = false;
  if(event.packId && user.permissions.canPack) {
    canRemove = true;
  }
  if(!event.packId && isOwnEvent) {
    canRemove = true;
  }

  const agency = _.get(state, `agencies.all[${user.agencyId}]`, null);


  return {
    canEdit,
    canRemove,

    event,
    pack,
    agency,

    ...ownProps,
  };
};

export const mapDispatchToProps = {
  loadPack,
  removeEvent,
  updateEvent,
};




export class EventLI extends Component {

  state = {
    isRemoveDialogOpen: false,
  }

  UNSAFE_componentWillMount() {
    if(this.props.event.packId && !this.props.pack) {
      this.props.loadPack(this.props.event.packId);
    }
  }



  showRemoveDialog = () => this.setState({isRemoveDialogOpen:true})
  hideRemoveDialog = () => this.setState({isRemoveDialogOpen:false})

  remove = (e) => {
    e && e.stopPropagation();
    this.props.removeEvent(this.props.event.id);
    this.hideRemoveDialog();
  }

  confirm = (e) => {
    e && e.stopPropagation();
    this.props.updateEvent(this.props.event.id, {status:'confirmed'});
  }

  decline = (e) => {
    e && e.stopPropagation();
    this.props.updateEvent(this.props.event.id, {status:'declined'});
  }

  pending = (e) => {
    e && e.stopPropagation();
    this.props.updateEvent(this.props.event.id, {status:'pending'});
  }


  render() {
    const {
      event,
      pack,
      style = {},
    } = this.props;

    const accentColor = this.props.muiTheme.palette.accent700;

    const date = moment(event.date);

    const actions = [];

    if(this.props.canEdit && event.status === 'pending') {
      actions.push(<Button key="confirm" raised={true} primary={true} label="Confirm" style={{marginRight:8}} onClick={this.confirm}/>);
      actions.push(<Button key="decline" label="Decline" onClick={this.decline}/>);
    }

    if(this.props.canRemove) {
      actions.push(<Button key="remove" icon="remove" onClick={this.showRemoveDialog}/>);
    }


    return (
      <Page className="eventli2" style={style} onClick={this.props.onClick}>

        <Media
          className="eventli2-image"
          media={event.image}
          icon="event"
          width={64}
          height={64}
          crop="fill"
          face
        />

        {event.allDay !== true ? (
          <div className="eventli2-date">
            <div className="eventli2-date-hour">{date.format('h')}</div>
            <div className="eventli2-date-minutes">{date.format('mm')}</div>
            <div className="eventli2-date-m">{date.format('a')}</div>
          </div>
        ) : (
          <div className="eventli2-date-allday">
            <div className="eventli2-date-all">All</div>
            <div className="eventli2-date-day">Day</div>
          </div>
        )}


        <div className="eventli2-name">{event.firstName || ""} {event.lastName || ""}</div>
        
        <div className="eventli2-notes">{event.notes}</div>

        <div className="eventli2-pack">
          {pack &&
            <div>
              <Icon name="pack" size={16} color="#95989A" className="eventli2-pack-icon"/>
              <span>{pack.name}</span>
            </div>
          }
        </div>

        <div className="eventli2-type">{event.type}</div>

        <div className="eventli2-status" style={{color:event.status === 'confirmed' ? accentColor : '#aaa'}} onClick={this.pending}>
          {!!event.status && event.status !== 'pending' ? event.status.toUpperCase() : ""}
        </div>

        <div className="eventli2-actions">
          {actions}
        </div>


        <Dialog
          open={this.state.isRemoveDialogOpen}
          onRequestClose={this.hideRemoveDialog}
          contentStyle={{maxWidth:500}}
          title="Are you sure?"
          actions={[
            <Button key="no" label="Nevermind" onClick={this.hideRemoveDialog} style={{marginRight:8}}/>,
            <Button key="yes" raised={true} primary={true} label="Yes" onClick={this.remove}/>
          ]}>

          Are you sure you want to remove this event?

        </Dialog>


      </Page>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(EventLI));
