import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateTalent } from 'actions';
import { debounce } from 'lodash';

import Title from 'ui/Title';
import Page from 'ui/Page';
import TextField from 'material-ui/TextField';


export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const talent = state.talents.all[talentId];
  
  return {
    talentId,
    notes: talent.notes || "",
    comments: talent.comments || "",
  };
};

export const mapDispatchToProps = {
  updateTalent,
};


export class TalentNotes extends Component {
  state = {
    notes: "",
    comments: "",
  }
  
  componentDidMount() {
    this.setState({
      notes:this.props.notes,
      comments:this.props.comments,
    });
  }
  
  deferredUpdate = debounce(() => {
    this.props.updateTalent(this.props.talentId, {
      notes: this.state.notes,
      comments: this.state.comments,
    });
  }, 1000)
  
  updateNotes = (notes) => {
    this.setState({notes}, this.deferredUpdate);
  }

  updateComments = (comments) => {
    this.setState({comments}, this.deferredUpdate);
  }
  
  render() {
    return (
      <div>
      
        <Title>Notes</Title>
        
        <Page pad={true} style={{minHeight:100}}>
          <TextField
            fullWidth
            multiLine={true}
            underlineShow={false}
            hintText="Private notes only shown to agents."
            value={this.state.notes}
            onChange={(e, notes) => this.updateNotes(notes)}
          />
        </Page>

        <Title>Comments</Title>

        <Page pad={true} style={{minHeight:100}}>
          <TextField
            fullWidth
            multiLine={true}
            underlineShow={false}
            hintText="Comments are editable by agents, and visible to casting directors."
            value={this.state.comments}
            onChange={(e, comments) => this.updateComments(comments)}
          />
        </Page>
        
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(TalentNotes);