import React, { useState } from 'react';
import { Button, Modal, Form, InputPassword, Text, Rule } from 'skybolt-ui';
import { updateUserPassword } from 'skybolt-api';


export default function UserChangePasswordButton({onClick, userId, ...props}) {
  const [menu, setMenu] = useState('false');
  const [formValue, setFormValue] = useState({});
  const [isChanging, setIsChanging] = useState(false);
  const [error, setError] = useState("");
  
  const changePassword = async () => {
    if(isChanging) {
      return;
    }
    
    if(!formValue.oldValue) {
      setError("Current password is required.");
      setIsChanging(false);
      return;
    }
    
    if(!formValue.newValue) {
      setError("New password is required.");
      setIsChanging(false);
      return;
    }
    
    if(formValue.newValue !== formValue.confirmValue) {
      setError("Confirmation password doesn't match.");
      setIsChanging(false);
      return;
    }
    
    setError("");
    setIsChanging(true);
    
    try {
      await updateUserPassword({
        password: formValue.oldValue, 
        newPassword: formValue.newValue
      });
    }
    catch(error) {
      setError(error.message);
      setIsChanging(false);
      return;
    }
    
    setMenu('sent');
    
    // Give the menu some time to fly away then clear.
    setTimeout(() => {
      setIsChanging(false);
      setFormValue({});
    }, 400);
  };
  
  const close = () => {
    if(isChanging) {
      return;
    }
    setMenu('closed');
  };
  
  return [
    <Button 
      key="button"
      label="Change Password"
      raised
      onClick={() => setMenu('open')}
      {...props}
    />,
    <Modal 
      key="modal"
      status={menu} 
      onRequestClose={close}
      modalStyle={{
        boxSizing: 'border-box',
        maxWidth: 400,
        padding: 16,
      }}>
      
      <Text title>Change Password</Text>
      <Rule style={{marginBottom:16}}/>
      
      <Form 
        value={formValue} 
        onChange={val => {
          setFormValue(val);
        }}>
        <InputPassword title="Current Password" name="oldValue"/>
        <InputPassword title="New Password" name="newValue"/>
        <InputPassword title="Confirm New Password" name="confirmValue"/>
      </Form>
      
      <Text error paragraph style={{height:16}}>{error}</Text>
      
      <div style={{display:'flex', justifyContent:'flex-end'}}>
        <Button raised onClick={close} style={{marginRight:16}}>Nevermind</Button>
        <Button 
          raised 
          accent={!isChanging}
          onClick={changePassword}>
          {isChanging ? "Saving..." : "Change Password"}
        </Button>
      </div>
    </Modal>
  ];
}