import React, { useEffect, useState } from 'react';
import { Icon, Text, useRouter } from 'skybolt-ui';
import { createDebit } from 'skybolt-api';
import { get } from 'lodash';

export default function CreatePayment(props) {

  const route = useRouter();
  const referrer = get(route, 'location.state.referrer', null);

  const [error, setError] = useState(null);

  useEffect(() => {
    createDebit({})
      .then(debit =>
        route.history.replace({
          pathname: debit.debitId,
          state: {
            referrer,
          }
        })
      )
      .catch(error => {
        setError(error);
      });
  }, []);

  if(error) {
    return (
      <div style={{textAlign:"center", paddingTop:80}}>
        <div>
          <Icon name="bomb" size={120} color="error"/>
        </div>
        <Text>{error.message}</Text>
      </div>
    );
  }

  return (
    <div style={{textAlign:"center", paddingTop:80}}>
      <div>
        <Icon name="loading" size={120} color="greyLight"/>
      </div>
      <Text small>Creating Payment...</Text>
    </div>
  );
}