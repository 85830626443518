import { combineReducers } from "redux";


const byUser = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_USER_TRANSACTIONS_REQUEST':
      return {
        ...state,
        [action.userId]: {
          isLoading: true,
        },
      };
      
    case 'LOAD_USER_TRANSACTIONS':
      return {
        ...state,
        [action.userId]: {
          data: action.transactions, 
          isLoading:false
        }
      };
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byAgency = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_AGENCY_TRANSACTIONS_REQUEST':
      return {
        ...state,
        [action.agencyId]: {
          isLoading: true,
        },
      };
      
    case 'LOAD_AGENCY_TRANSACTIONS':
      return {
        ...state,
        [action.agencyId]: {
          data: action.transactions, 
          isLoading:false
        }
      };
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const byTalent = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_TALENT_TRANSACTIONS_REQUEST':
      return {
        ...state,
        [action.talentId]: {
          isLoading: true,
        },
      };
      
    case 'LOAD_TALENT_TRANSACTIONS':
      return {
        ...state,
        [action.talentId]: {
          data: action.transactions, 
          isLoading:false
        }
      };
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
}


export default combineReducers({
  byUser,
  byAgency,
  byTalent,
});