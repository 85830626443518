import React from 'react';
import { Switch, Route, Redirect } from 'skybolt-ui';
import Invoices from './Invoices';
import Clients from './Clients';
import Debits from './Debits';
import AccountsReceivable from './AccountsReceivable';
import AccountsPayable from './AccountsPayable';
import Dashboard from './Dashboard';
import Reports from './Reports';

export default function Billing(props) {
  const {match:{ url, path }} = props;

  // TODO: check that the logged in user's agency has `useBilling` flag
  // and redirect away if it doesn't.

  return (
    <Switch>
      <Route path={`${path}/invoices`} component={Invoices}/>
      <Route path={`${path}/clients`} component={Clients}/>
      <Route path={`${path}/debits`} component={Debits}/>
      <Route path={`${path}/accounts-payable`} component={AccountsPayable}/>
      <Route path={`${path}/accounts-receivable`} component={AccountsReceivable}/>
      <Route path={`${path}/dashboard`} component={Dashboard}/>
      <Route path={`${path}/reports`} component={Reports}/>
      <Redirect to={`${url}/invoices`}/>
    </Switch>
  );
}