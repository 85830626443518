import moment from 'moment-timezone';


export default function filtersDescription(filters, timezone = moment.tz.guess()) {
  let message = "";
  
  if(filters.status == "SENT") {
    message = `PAID, POSTED, and OVERDUE `;
  }
  else if(filters.status) {
    message = `${filters.status} `;
  }
  else {
    message = `All `;
  }

  if(filters.jobType) {
    message += `${filters.jobType} `;
  }

  message += 'invoices ';

  if(filters.dateFrom && !filters.dateTo && filters.status !== 'PAID') {
    message += `sent since ${moment(filters.dateFrom).tz(timezone).calendar()} `;
  } 

  if(!filters.dateFrom && filters.dateTo && filters.status !== 'PAID') {
    message += `sent before ${moment(filters.dateTo).tz(timezone).calendar()} `;
  } 

  if(filters.dateFrom && filters.dateTo && filters.status !== 'PAID') {
    message += `sent between ${moment(filters.dateFrom).tz(timezone).calendar()} and ${moment(filters.dateTo).tz(timezone).calendar()} `;
  } 

  if(filters.dateFrom && !filters.dateTo && filters.status == 'PAID') {
    message += `paid since ${moment(filters.dateFrom).tz(timezone).calendar()} `;
  } 

  if(!filters.dateFrom && filters.dateTo && filters.status == 'PAID') {
    message += `paid before ${moment(filters.dateTo).tz(timezone).calendar()} `;
  } 

  if(filters.dateFrom && filters.dateTo && filters.status == 'PAID') {
    message += `paid between ${moment(filters.dateFrom).tz(timezone).calendar()} and ${moment(filters.dateTo).tz(timezone).calendar()} `;
  }

  if(filters.orderBy == 'jobType') {
    message += 'ordered by Job Type ';
  }
  else if(filters.orderBy == 'clientId') {
    message += 'ordered by Client ';
  }
  else if (filters.orderBy == 'datePaid') {
    message += 'ordered by date paid ';
  }
  else {
    message += 'ordered by date sent ';
  }

  return message;
}