/**

Tag Input

**/




import React, { Component } from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';
import TextField from 'material-ui/TextField';
import Chip from 'material-ui/Chip';
import { uniq } from 'lodash';

import { clone } from 'lodash';

import './index.css';

class TagInput extends Component {
  static defaultProps = {
    tags: [],
    style: {},
    label: "",
    displayKey: null,
    validate: () => false,
    errorText: "Entry is invalid.",
    underlineShow: true,
  }
  
  state = {
    newTag: "",
    errorText: "",
  }
  
  add = e => {
    e && e.preventDefault();
    
    let newTag = this.props.search || this.state.newTag.trim();
    if(!newTag) {
      return;
    }
    
    const error = this.props.validate(newTag);
    if(error) {
      this.setState({errorText:error});
      return;
    }
    
    if(this.props.onAdd) {
      this.props.onAdd(newTag);
    }
    
    if(this.props.onChange) {
      let newTags = [...this.props.tags, newTag];
      newTags = uniq(newTags);
      this.props.onChange(newTags);
    }
  
    if(this.props.onSearch) {
      this.props.onSearch("");
    }
    else {
      this.setState({newTag:""});
    }
  }
  
  remove = i => {
    if(this.props.onChange) {
      let tags = clone(this.props.tags);
      tags.splice(i, 1);
      this.props.onChange(tags);
    }
    
    if(this.props.onRemove) {
      this.props.onRemove(this.props.tags[i], i);
    }
  }
  
  handleFocus = e => {
    if(this.props.onFocus) {
      this.props.onFocus();
    }
  }
  
  handleBlur = e => {
    this.add();
    if(this.props.onBlur) {
      this.props.onBlur();
    }
  }
  
  handleKeyDown = e => {
    if(this.props.onKeyDown) {
      const keepGoing = this.props.onKeyDown(e);
      if(keepGoing === false) {
        e.preventDefault();
        return;
      }
    }
    
    this.setState({errorText:""});
    if(
      e.keyCode === 188 //||    // comma
      // e.keyCode === 32        // space
    ) {
      e.preventDefault();
      this.add();
    }
    
    const newTag = this.props.search || this.state.newTag;
    if(e.keyCode === 8 && !newTag) {
      let tags = clone(this.props.tags);
      let lastTag = tags.pop();
      if(this.props.onChange) {
        this.props.onChange(tags);
      }
      if(this.props.onRemove) {
        this.props.onRemove(lastTag, tags.length);
      }
    }
  }
  
  handleSearch = (e, val) => {
    if(this.props.onSearch) {
      this.props.onSearch(val);
    }
    else {
      this.setState({newTag:val});
    }
  }
  
  render() {
    return (
      <div style={this.props.style}>
        {this.props.label && 
          <div 
            className="taginput-label"
            style={{
              color: this.props.muiTheme.palette.primary200
            }}>
            {this.props.label}
          </div>
        }
      
        <div className="taginput-tags">
          {this.props.tags.map((tag, i) => 
            <Chip
              key={this.props.displayKey ? tag[this.props.displayKey] : tag}
              onRequestDelete={() => this.remove(i)}
              style={{marginRight:8, marginTop:8, borderRadius:4}}>
              {tag}
            </Chip>
          )}
          
          <form className="taginput-input" onSubmit={this.add}>
            <TextField
              hintText={this.props.hint !== undefined ? this.props.hint : `Add ${this.props.label}`}
              name="newtag"
              value={this.props.search || this.state.newTag}
              onChange={this.handleSearch}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyDown={this.handleKeyDown}
              underlineShow={this.props.underlineShow}
              autoComplete="false"
            />
          </form>
          
        </div>
        {this.state.errorText && <div className="taginput-error">{this.state.errorText}</div>}
      </div>
    );
  }
}

export default muiThemeable()(TagInput);