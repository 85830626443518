import React, { useCallback, useState, useRef } from 'react';
import {
  Container,
  Currency,
  Text,
  Rule,
  Loading,
  Modal,
  Card,
  Button,
  InputHTML,
  useRouter,
  Icon,
} from 'skybolt-ui';
import moment from 'moment-timezone';
import { useQuery, updateCheck, loadCheck, sendCheck, pollCheckStatus, updateDebit } from 'skybolt-api';
import { debounce, get, last } from 'lodash';
import './index.css';



function HTML({children, ...props}) {
  children = children || "";
  children = children.replace(/ ?contenteditable="true"/gi, "");
  return (
    <div dangerouslySetInnerHTML={{__html:children}} {...props}/>
  );
}


export default function Check(props) {

  const route = useRouter();
  const { pathname } = route.location;
  const checkId = get(route, 'match.params.checkId', null);

  const data = useQuery(`{
    agency {
      agencyId
      useBilling
      billingOrgId
      timezone
    }
    check(checkId:$checkId) {
      checkId
      status
      errorMessage
      number
      date
      talentId
      talentName
      talentImage
      notes
      amount
      debits {
        debitId
        packId
        packName
        invoiceNumber
        dateJob
        dateCheck
        dateInvoiced
        comment
        amount
        clientId
        clientName
        status
      }
      logs
    }
  }`, {checkId});


  let timezone = data?.agency?.timezone || moment.tz.guess();

  const debouncedUpdate = useCallback(debounce(update => {
    updateCheck({checkId, update});
  }, 500), [checkId]);




  // Void a check.

  async function voidCheck() {
    const confirm = await Modal.confirm("Are you sure? This check will be marked as void and all payments as unsent. This cannot be undone.");
    if(!confirm) {
      return;
    }

    updateCheck({checkId, update: {
      status: "VOID"
    }});

    for(let debit of data.check.debits) {
      await updateDebit({
        debitId: debit.debitId,
        agencyId: data.agency.agencyId,
        update: {
          status: "DRAFT"
        }
      });
    }
  }

  // Handle Check Printing and Sending

  const [isSending, setSending] = useState(false);

  async function send() {
    if(isSending) {
      return;
    }
    const confirmation = await Modal.confirm("Are you sure you want to re-send this check?");
    if(!confirmation) {
      return;
    }

    setSending(true);

    try {
      await sendCheck({
        agencyId: data.agency.agencyId,
        checkId: data.check.checkId,
      });
    }
    catch(error) {
      await Modal.error("Something went wrong. Please try again or contact Skybolt Support.");
      setSending(false);
      return;
    }

    await Modal.alert("Check sent.");
    setSending(false);
  }



  // Render

  const SendButton = () => {
    if(!data.agency.billingOrgId) {
      return null;
    }
    if(isSending) {
      return <Button title icon="loading"/>;
    }
    return <Button title icon="send" onClick={send}/>;
  };

  const [isPollingStatus, setPollingStatus] = useState(false);
  const RefreshButton = () => {
    if(!data.agency.agencyId || !data.check.checkId) {
      return null;
    }

    async function pollStatus() {
      setPollingStatus(true);
      await pollCheckStatus({agencyId:data.agency.agencyId, checkId:data.check.checkId});
      setPollingStatus(false);
    }

    return (
      <Button title onClick={pollStatus}>
        <Icon name="sync" style={{fontSize:18}} spin={isPollingStatus}/>
      </Button>
    );
  };



  // Render

  if(!data) {
    return (
      <Container className="billingcheck">
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>CHECK</Text>
        </div>
        <Rule/>
        <Loading style={{paddingTop:40}}/>
      </Container>
    );
  }

  if(!data.agency.useBilling) {
    return (
      <Container className="billingcheck">
        <Text center>You do not have access to billing features. Please contact Skybolt Support for more information.</Text>
      </Container>
    );
  }

  let errorMessage = data.check.errorMessage || last(data.check.logs)?.status == "Error" && last(data.check.logs)?.message;

  return (
    <Container className="billingcheck">
      <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
        <Text>
          <Text title style={{marginRight:16}}>CHECK {data.check.number}</Text>
          <Text bold color={data.check.status === "ERROR" ? "error" : "textLight"}>{data.check.status}</Text>
        </Text>
        <div style={{flex:1}}/>
        {data && data.check.status !== "VOID" &&
          <Button label="Void Check" onClick={voidCheck} color="textLight"/>
        }
        <RefreshButton/>
        <SendButton/>
      </div>
      <Rule style={{marginBottom:24}}/>
      
      {errorMessage && (
        <Text paragraph error style={{paddingLeft:16}}>
          {errorMessage}<br/>
          Please correct this error and send again.
        </Text>
      )}

      <Card>

        <div className="billingcheck__info">
          <div>
            <a href={`/talents/${data.check.talentId}`} style={{display:'flex', alignItems:'center', height:40, marginBottom:16}}>
              {data.check.talentImage && <img src={data.check.talentImage} alt="headshot" style={{width:40, height:40, marginRight:8}}/>}
              <Text large style={{lineHeight:"40px"}}>{data.check.talentName}</Text>
            </a>
            <Text small>Date</Text>
            <Text large paragraph>{moment(data.check.date).tz(timezone).format("MM/DD/YYYY")}</Text>
            {data.check.number && (
              <div>
                <Text small>Check Number</Text>
                <Text large paragraph>{data.check.number}</Text>
              </div>
            )}
            <Text small>Amount</Text>
            <Currency paragraph large>{data.check.amount}</Currency>
          </div>

          <InputHTML
            title="Notes"
            hint="Only visible to agents"
            initialValue={data.check.notes}
            onChange={notes => debouncedUpdate({notes})}
          />
        </div>

        <div className="billingcheck__details">
          <div className="billingcheck__detailsinner">
            <Text>Details</Text>
            <Rule style={{marginBottom:16}}/>
            <div style={{display:'flex'}}>
              <div style={{flex:1}}>
                <Text small light>Date</Text>
              </div>
              <div style={{flex:1}}>
                <Text small light>Package</Text>
              </div>
              <div style={{flex:1}}>
                <Text small light>Client</Text>
              </div>
              <div style={{flex:1}}>
                <Text small light>Invoice No.</Text>
              </div>
              <div style={{flex:2}}>
                <Text small light>Comment</Text>
              </div>
              <div style={{width:200}}>
                <Text small light>Amount</Text>
              </div>
            </div>
            {data.check.debits.map(debit =>
              <div key={debit.debitId} style={{minHeight:40, display:'flex', alignItems:'center'}}>
                <Button href={{pathname:`/billing/debits/${debit.debitId}`, state:{referrer:pathname}}} style={{flex:1, display:'flex', minHeight:40, padding:0}}>
                  <div style={{flex:1}}>
                    {debit.is1099 ? (
                      <Text>{debit.dateJob ? moment(debit.dateJob).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
                    ):(
                      <Text>{debit.dateCheck ? moment(debit.dateCheck).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
                    )}
                  </div>
                  <div style={{flex:1}}>
                    <Text>{debit.packName}</Text>
                  </div>
                  <Button style={{flex:1, padding:0}} href={debit.clientId ? `/billing/clients/${debit.clientId}` : null}>
                    <Text>{debit.clientName}</Text>
                  </Button>
                  <Button style={{flex:1, padding:0}} href={debit.invoiceId ? `/billing/invoices/${debit.invoiceId}` : null}>
                    <Text>{debit.invoiceNumber}</Text>
                  </Button>
                  <div style={{flex:2}}>
                    <HTML style={{paddingRight:8}}>{debit.comment}</HTML>
                  </div>
                  <div style={{width:200}}>
                    <Currency>{debit.amount}</Currency>
                  </div>
                </Button>
              </div>
            )}
          </div>
        </div>

      </Card>

      <div className="billingcheck__logs">
        {data?.check.logs?.map(log => 
          <div className="billingcheck__log">
            <div className="date">{moment(log.date).tz(timezone).format("MM/DD/YYYY hh:mm")}</div>
            <div className="status">{log.status}</div>
            <div className="message">{log.message}</div>
          </div>  
        )}
      </div>
    </Container>
  );
}