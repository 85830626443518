import React, { useContext } from 'react';
import { debounce } from 'lodash';
import { Text, Rule, Button, Container, Icon, ThemeContext, Card, InputText, Loading, PhoneNumber } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';
import Fuse from 'fuse.js';

export default function Invoices(props) {

  const {
    search
  } = props.location.state || {};

  const data = useQuery(`{
    agency {
      clients {
        clientId
        name
        contactName
        phone
        email
        notes
      }
    }
  }`);

  const setQuery = (update) => {
    props.history.replace(
      props.location.pathname,
      {...props.location.state, ...update}
    );
  };

  const theme = useContext(ThemeContext);


  if(!data) {
    return (
      <Loading/>
    );
  }

  let clients = data.agency.clients;

  if(search) {
    const fuse = new Fuse(clients, {keys:['clientId', 'name', 'contactName', 'phones']});
    clients = fuse.search(search);
  }

  if(!search && clients.length === 0) {
    return (
      <Container>
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>CLIENTS</Text>
          <div style={{flex:1}}/>
          <Button
            href="create"
            style={{
              background:theme.color.grey,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 40,
              height: 40,
              padding: 0,
              borderRadius: 0,
            }}>
            <Icon name="plus" color="white"/>
          </Button>
        </div>
        <Rule style={{marginBottom:24}}/>
        <div style={{textAlign:'center'}}>
          <div>
            <Icon name="book-user" size={80} color="accentLighter"/>
          </div>
          <div>
            <Button raised accent href="create" label="Create Client"/>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
        <Text title>CLIENTS</Text>
        <div style={{flex:1}}/>
        <InputText
          placeholder="Search..."
          initialValue={search}
          onChange={debounce(search => setQuery({search}), 200)}
          clearable
          style={{margin:0, backgroundColor:'transparent', height:38}}
        />
        <Button
          href="create"
          style={{
            background:theme.color.grey,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            padding: 0,
            borderRadius: 0,
          }}>
          <Icon name="plus" color="white"/>
        </Button>
      </div>
      <Rule style={{marginBottom:24}}/>

      <div style={{display:'flex', alignItems:'center', padding:8}}>
        <div style={{flex:1}}>
          <Text small light>Client Name</Text>
        </div>
        <div style={{flex:1}}>
          <Text small light>Contact</Text>
        </div>
        <div style={{flex:1}}>
          <Text small light>Phone Number</Text>
        </div>
        <div style={{flex:1}}>
          <Text small light>Email Address</Text>
        </div>
        <div style={{flex:1}}>
          <Text small light>Notes</Text>
        </div>
      </div>

      {clients.map(client => (
        <Card key={client.clientId} style={{marginBottom:4}}>
          <Button
            style={{display:'flex', alignItems:'center', padding:"0 8px", minHeight:40}}
            href={client.clientId}>
            <div style={{flex:1}}>
              <Text bold>{client.name}</Text>
            </div>
            <div style={{flex:1}}>
              <Text>{client.contactName}</Text>
            </div>
            <div style={{flex:1}}>
              <PhoneNumber>{client.phone}</PhoneNumber>
            </div>
            <div style={{flex:1}}>
              <Text>{client.email}</Text>
            </div>
            <div style={{flex:1}}>
              <Text html>{client.notes}</Text>
            </div>
          </Button>
        </Card>
      ))}

    </Container>
  );
}
