import React from 'react';

import {
  Container,
  Header,
  Icon,
  Switch,
  Route,
  Redirect,
  useRouter,
} from 'skybolt-ui';

import { useQuery } from 'skybolt-api';

import Account from './Account';
import Billing from './Billing';
import Talents from './Talents';
import Subscription from './Subscription';

export default function User() {

  const {
    match: {
      url,
      path,
      params: {
        userId
      }
    }
  } = useRouter();

  const data = useQuery(`{
    me: user {
      permissions {
        canAdminUsers
      }
    }
    user(userId:$userId) {
      fullName
      permissions {
        canOwnTalents
      }
      agency {
        useStripe
        requireTalentSubscription
        requireUserSubscription
      }
      stripeCustomerId
    }
  }`, {
    userId
  });

  if(!data) {
    return (
      <div style={{padding:"160px 16px"}}>
        <Icon name="loading" large/>
      </div>
    );
  }


  // Build tabs for the user based on their permissions.

  const tabs = [{label:"Account", icon:"user", href:`${url}/account`}];

  const canAdminUsers = data.me.permissions.canAdminUsers;
  const isOwnUser = data.me.userId === data.user.userId;
  const canUserOwnTalent = data.user.permissions.canOwnTalents;

  // if(!data.user.stripeCustomerId && (canAdminUsers || isOwnUser) && canUserOwnTalent) {
  //   tabs.push({label:"Talent", icon:"talent", href:`${url}/talent`});
  // }

  // // todo: if no parent billing
  // if(!data.user.stripeCustomerId && (canAdminUsers || isOwnUser) && canUserOwnTalent) {
  //   tabs.push({label:"Billing", icon:"creditcard", href:`${url}/billing`});
  // }

  if((canAdminUsers || isOwnUser) && canUserOwnTalent) {
    tabs.push({label:"Subscription", icon:"creditcard", href:`${url}/subscription`});
  }


  // Render

  return (
    <Container>
      <Header
        name={data.user.fullName}
        tabs={tabs}
        style={{marginTop:32, marginBottom:32}}
      />

      <Switch>
        <Route path={`${path}/account`} component={Account}/>
        <Route path={`${path}/talent`} component={Talents}/>
        <Route path={`${path}/billing`} component={Billing}/>
        <Route path={`${path}/subscription`} component={Subscription}/>
        <Redirect to={`${url}/account`}/>
      </Switch>

    </Container>
  );
}