import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "reducers";
import { DEV } from 'config';

let createStoreWithMiddleware;

if(DEV) {
// if(true) {
  const loggerMiddleware =  createLogger();
  createStoreWithMiddleware = applyMiddleware(thunkMiddleware, loggerMiddleware)(createStore);
}
else {
  createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);
}

const configureStore = function(initialState) {
  return createStoreWithMiddleware(rootReducer, initialState);
};

export default configureStore;