import { combineReducers } from "redux";


const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_INVITATION':
      return {
        ...state,
        [action.invitationId]:action.invitation,
      };
      
    case 'LOAD_INVITATIONS':
      return {
        ...state,
        ...action.invitations,
      };
      
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};

const isLoading = (state=false, action) => {
  switch(action.type) {
    case 'LOAD_INVITATION_REQUEST':
      return true;
      
    case 'LOAD_INVITATION':
      return false;
      
    default: 
      return state;
  }
};



export default combineReducers({
  all,
  isLoading,
});