import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './index.css';
import _ from 'lodash';
import { removeEvent, updateEvent, addTalentEvent } from 'actions';

import Dialog from 'ui/Dialog';
import Button from 'ui/Button';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import Checkbox from 'material-ui/Checkbox';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Media from 'ui/Media';
import PackLI from 'ui/PackLI';


export const mapStateToProps = (state, ownProps) => {
  let showPack = false;
  try {
    showPack = state.user.permissions.canPack;
  }
  catch(err){}

  const agencyId = _.get(state, 'user.agencyId', null);
  const eventTypes = _.get(state, `agencies.all[${agencyId}].eventTypes`, {});

  return {
    user: state.user,
    showPack,
    eventTypes,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  removeEvent,
  updateEvent,
  addTalentEvent,
};




export class EventDialog extends Component {

  static defaultProps = {
    event: {},
  }

  state = {
    date: null,
    endDate: null,
    notes: "",
    allDay: false,
    type: "",
  }

  componentWillReceiveProps(nextProps) {
    if(!nextProps.event) {
      return;
    }
    this.setState({
      date: nextProps.event.date || null,
      endDate: nextProps.event.endDate || null,
      notes: nextProps.event.notes || "",
      allDay: !!nextProps.event.allDay,
      type: nextProps.event.type || "",
    });
  }




  setDate = date => {
    date = moment(date);
    let endDate = this.state.endDate;

    if(date.isAfter(this.state.endDate)) {
      endDate = date;
    }

    this.setState({date, endDate}, this.save);
  }

  setEndDate = endDate => {
    let date = this.state.date;
    endDate = moment(endDate);

    if(endDate.isBefore(this.state.date)) {
      date = endDate;
    }

    this.setState({date, endDate}, this.save);
  }

  setNotes = notes => {
    this.setState({notes}, this.save);
  }

  setAllDay = allDay => {
    this.setState({allDay}, this.save);
  }

  setType = type => {
    this.setState({type}, this.save);
  }

  remove = () => {
    if(this.props.event.id) {
      this.props.removeEvent(this.props.event.id);
      this.props.onRequestClose();
    }

    this.props.onRequestClose();
  }

  save = () => {
    if(this.props.event.id) {
      const data = {
        date: !!this.state.date ? this.state.date.valueOf() : null,
        endDate: !!this.state.endDate ? this.state.endDate.valueOf() : null,
        notes: this.state.notes || "",
        allDay: !!this.state.allDay,
        type: this.state.type || "",
      };
      this.props.updateEvent(this.props.event.id, data);
    }
  }

  createEvent = () => {
    if(this.props.talentId) {

      let date, endDate;

      try {
        date = this.state.date.valueOf();
      }
      catch(err) {}

      try {
        endDate = this.state.endDate.valueOf();
      }
      catch(err) {}

      const data = {
        date: date,
        endDate: endDate,
        notes: this.state.notes || "",
        allDay: !!this.state.allDay,
      };
      this.props.addTalentEvent(this.props.talentId, data);
      this.props.onRequestClose();
    }
  }


  render() {
    const { date, endDate, allDay, notes, type } = this.state;
    const event = this.props.event;

    if(!event) {
      return null;
    }

    let title = type || "Event";

    return (
      <Dialog
        open={this.props.open}
        contentStyle={{maxWidth:740}}
        bodyStyle={{
          backgroundColor:'#f5f5f5',
          padding: 0,
        }}
        onRequestClose={this.props.onRequestClose}>


        {event.talentId &&
          <div className="eventdialog-talent">
            <Media
              className="eventdialog-talent-image"
              media={event.image}
              icon="talent"
              width={80}
              height={80}
              crop="fill"
              face
            />
            <div className="eventdialog-talent-info">
              <div className="eventdialog-talent-name">
                {event.firstName} {event.lastName}
              </div>
              <div className="eventdialog-talent-event">
                {title}
              </div>
            </div>
          </div>
        }

        <div className="eventdialog-wrapper">

          <div className="eventdialog-title">Date and Time</div>
          <div className="eventdialog-date">
            <DatePicker
              hintText="Date"
              value={date ? moment(date).toDate() : undefined}
              onChange={(e, val) => this.setDate(val)}
              fullWidth
              style={{width:120}}
              formatDate={date => moment(date).format('MMM D, YYYY')}
            />
            {!allDay &&
              <TimePicker
                hintText="Time"
                value={date ? moment(date).toDate() : undefined}
                onChange={(e, val) => this.setDate(val)}
                fullWidth
                style={{width:80}}
              />
            }

            <div style={{padding:'0 16px'}}>to</div>

            <DatePicker
              hintText="Date"
              value={endDate ? moment(endDate).toDate() : undefined}
              onChange={(e, val) => this.setEndDate(val)}
              fullWidth
              style={{width:120}}
              formatDate={date => moment(date).format('MMM D, YYYY')}
            />
            {!allDay &&
              <TimePicker
                hintText="Time"
                value={endDate ? moment(endDate).toDate() : undefined}
                onChange={(e, val) => this.setEndDate(val)}
                fullWidth
                style={{width:80}}
              />
            }
          </div>

          <Checkbox
            label="All day"
            checked={allDay}
            onCheck={(e, val) => this.setAllDay(val)}
            labelStyle={{fontSize:14, top:2}}
          />

          <div className="eventdialog-title">Notes</div>
          <TextField
            hintText="Enter text here"
            fullWidth
            multiLine={true}
            value={notes}
            onChange={(e, notes) => this.setNotes(notes)}
          />

          {/* TODO package details for talent go here */}

          {this.props.showPack &&
            <div>
              <div className="eventdialog-title">Type</div>
              <SelectField
                maxHeight={200}
                style={{maxWidth:600}}
                value={type || ""}
                onChange={(e, i, type) => this.setType(type)}>

                <MenuItem checked={!type} value={""} primaryText={"Unavailable"}/>

                {Object.values(this.props.eventTypes).map(option => (
                  <MenuItem 
                    value={option.label} 
                    checked={type === option.label}
                    primaryText={option.label}
                  />
                ))}
                
              </SelectField>
            </div>
          }

          {this.props.showPack && event.packId &&
            <div>
              <div className="eventdialog-title">Package</div>
              <PackLI packId={event.packId}/>
            </div>
          }

        </div>

        <div className="eventdialog-nav">
          <div className="eventdialog-nav-space"/>

          {this.props.event.id &&
            <Button
              icon="remove"
              onClick={this.remove}
              style={{marginRight:4}}
            />
          }

          {!this.props.event.id &&
            <Button
              raised={true}
              primary={true}
              label="Save"
              onClick={this.createEvent}
              style={{marginRight:16, marginBottom:16}}
            />
          }
        </div>

      </Dialog>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(EventDialog);