import React from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';

import './index.css';


export default muiThemeable()(({
  children,
  muiTheme:{ palette },
  className="",
  pad=false,
  style,
  ...props
}) => {
  return (
    <div 
      style={{
        borderBottom: `1px solid ${palette.primary50}`,
        backgroundColor: '#ffffff',
        ...style,
      }} 
      className={`page ${pad ? 'with-padding' : ''} ${className}`.trim()}
      {...props}>
      
      {children}
      
    </div>
  );
});