import React, { useState, useContext } from 'react';
import {
  InputSelect,
  FormContext,
} from 'skybolt-ui';
import { useQuery, createClient } from 'skybolt-api';
import Fuse from 'fuse.js';

export default function InputClient({
  name,
  options,
  onSearch,
  onChange,
  onCreate,
  ...props
}) {

  // Load and manage client data and search value.

  const data = useQuery(`{
    agency {
      clients
    }
  }`);

  const [search, setSearch] = useState("");


  // Handle search inputs by
  // updating the internal search and
  // calling `onSearch` prop.
  // Note: search string can be modified by prop onSearch.

  function handleSearch(value) {
    if(onSearch) {
      value = onSearch(value) || value;
    }
    setSearch(value);
  }


  // Handle changes to the input. This overrides the InputSelect's change
  // handlers, so:
  // - if creating a new client, that client is created and the new value used,
  // - the change is emitted to onChange or parent form values.

  const form = useContext(FormContext);

  async function handleChange(value) {
    if(value === "CREATE") {
      const client = await createClient({name:search});
      if(onChange) {
        onChange(client.clientId);
      }
      if(form) {
        form.update({
          clientId: client.clientId,
          clientName: client.name,
        });
      }
      return;
    }
    if(onChange) {
      onChange(value);
    }
    else if(form) {
      let client = data.agency.clients.find(c => c.clientId == value);
      if(form && client) {
        form.update({
          clientId: client.clientId,
          clientName: client.name,
        });
      }
    }
  }

  const clientId = form.get('clientId');
  const clientName = form.get('clientName');

  // Create options array. This is:
  // - all agency clients
  // - filtered by the search value,
  // - or a quick link to create the agency.

  let clients = [];

  if(data) {
    clients = data.agency.clients
      .map(c => ({
        value:c.clientId,
        label:c.name
      }));
  }

  if(search) {
    const fuse = new Fuse(clients, {keys:['label']});
    clients = fuse.search(search);
  }

  if(clientId && clientName && !clients.find(c => c.value == clientId)) {
    clients.push({
      value: clientId,
      label: clientName,
    });
  }

  if(!clients.find(c => c.label === search) && search) {
    clients.push({
      label: `Create Client "${search}"`,
      value: `CREATE`,
    });
  }


  // Render

  clients = clients.slice(0, 5);
  
  if(!data) {
    return <div/>;
  }

  return (
    <InputSelect
      strict
      value={clientId}
      name={name}
      options={clients}
      onSearch={handleSearch}
      onChange={handleChange}
      {...props}
    />
  );
}