import React from 'react'
import { Container, Text, Rule } from 'skybolt-ui';

export default function BillingDashboard(props) {

  return (
    <Container>

      <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
        <Text title>Billing</Text>
        <div style={{flex:1}}/>
      </div>
      <Rule/>


    </Container>
  );
}