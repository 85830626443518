/*

# Text Input Field


## Props

### style

### initialValue

### underline

### clearable

### autoFocus

### autoSelect

### onChangeText

### onChange

### placeholder


*/


import React, { Component } from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { debounce } from 'lodash';
import './index.css';
import Button from 'ui/Button';



export class TextField extends Component {
  
  static defaultProps = {
    style: {},
    initialValue: "",
    onChangeText: ()=>null,
    onChange: ()=>null,
  }
  
  state = {
    value:"",
    error: "",
  }
  
  componentDidMount() {
    this.setState({value:this.props.initialValue}, () => {
      if(this.props.autoFocus) {
        this.el.focus();
      }
      
      if(this.props.autoSelect) {
        this.el.select();
        this.el.focus();
      }
    });
  }
  
  
  clear = e => {
    this.setState({value:""});
    this.props.onChange(e, "");
    this.props.onChangeText("");
  }
  
  clean = val => {
    if(this.props.clean) {
      return this.props.clean(val);
    }
    return val;
  }
  
  format = val => {
    if(this.props.format) {
      return this.props.format(val);
    }
    return val;
  }
  
  error = val => {
    if(this.props.error) {
      return this.props.error(val);
    }
    return null;
  }
  
  emitChangeText = debounce(() => {
    // todo: some validation here
    this.props.onChangeText(this.state.value);
  }, 250)
  
  handleChange = e => {
    let cursor = this.el.selectionStart;
    
    const raw = this.el.value;
    const clean = this.clean(raw);
    const formatted = this.format(clean);
    
    let cleanBeforeCursor = this.clean(
      raw.slice(0, cursor)
    );
    
    cursor = 0;
    while(cleanBeforeCursor.length > 0 && cursor < formatted.length) {
      if(formatted[cursor] === cleanBeforeCursor[0]) {
        cleanBeforeCursor = cleanBeforeCursor.slice(1);
      }
      cursor += 1;
    }
    
    this.setState({value:formatted, error:""}, () => {
      this.el.setSelectionRange(cursor, cursor);
    });
    
    this.props.onChange(e, formatted);
    
    if(!this.error(clean)) {
      this.props.onChangeText(clean);
    }
  }
  
  handleBlur = e => {
    const raw = this.el.value;
    const clean = this.clean(raw);
    const error = this.error(clean);
    
    if(error) {
      this.setState({error});
    }
  }
  
  setValue = value => {
    value = this.props.format(value);
    this.setState({value});
  }
  
  
  render() {
    const classes = ['textfield'];
    if(this.props.className) {
      classes.push(this.props.className);
    }
    if(this.props.underline === true) {
      classes.push('is-underlined');
    }
    
    return (
      <div 
        className={classes.join(' ')} 
        style={this.props.style}
        data-error={this.state.error}>
        
        <input 
          key={this.props.inputKey}
          name={this.props.name}
          className="textfield-input"
          ref={el => this.el = el}
          type={this.props.type}
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
        
        {this.state.value && this.props.clearable && 
          <Button 
            icon="close" 
            className="textfield-clear" 
            color={this.props.muiTheme.palette.primary700}
            onClick={this.clear}
          />
        }
        
      </div>
    );
  }
  
}


export default muiThemeable()(TextField);