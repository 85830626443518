import React, { useContext } from 'react';
import { Card, Text, Icon, ThemeContext } from 'skybolt-ui';

export default function Feature(props) {
  const theme = useContext(ThemeContext);

  return (
    <Card
      style={{
        textAlign: 'center',
        maxWidth: 240 - 32,
        padding: 16,
        margin: `0 0 16px 0`,
        borderBottom:`4px solid ${theme.color.accent}`,
      }}>

      <Icon
        name={props.icon}
        color={theme.color.accent}
        size={64}
      />

      <Text bold paragraph>{props.title}</Text>

      <Text paragraph style={{maxWidth:600}}>{props.text}</Text>

    </Card>
  );
}