import React from 'react';
import {
  Switch,
  Route,
  Text,
  useRouter,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';


import Form from './Form';
import Sent from './Sent';

export default props => {
  const {match:{path}} = useRouter();
  const data = useQuery(`{
    user {
      permissions {
        canAddCastingDirectors
      }
    }
  }`);

  if(!data) {
    return null;
  }

  if(!data.user.permissions.canAddCastingDirectors) {
    return (
      <div style={{padding:80, textAlign:'center'}}>
        <Text>You do not have permission to invite guest users.</Text>
      </div>
    );
  }

  return (
    <Switch>
      <Route path={`${path}/sent/:invitationId?`} component={Sent}/>
      <Route path={`${path}`} component={Form}/>
    </Switch>
  );
};