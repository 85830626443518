/*

Icon consolidates all icons into a single <Icon/> component. This simplified 
importing of icons, allows for easy substitution across the app, and provides a
complete list of imported icons for user selection.

Usage:

<Icon name="star"/>


Properties:

- name 
- color
- size 
- style
- isActive 
- isReverse 


Icons:
- add
- card
- close
- edit
- visible

*/

import React from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';

import BurgerIcon from 'material-ui/svg-icons/navigation/menu';
import TuneIcon from 'material-ui/svg-icons/image/tune';
import MultiMediaUploadIcon from 'material-ui/svg-icons/image/add-to-photos';
import CastingDirectorIcon from 'material-ui/svg-icons/action/event-seat';
import VisibleIcon from 'material-ui/svg-icons/action/visibility';
import VisibleOffIcon from 'material-ui/svg-icons/action/visibility-off';
import EditIcon from 'material-ui/svg-icons/image/edit';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import PhoneIcon from 'material-ui/svg-icons/communication/call';
import ForumIcon from 'material-ui/svg-icons/communication/forum';
import AddressIcon from 'material-ui/svg-icons/communication/location-on';
import CardIcon from 'material-ui/svg-icons/action/chrome-reader-mode';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import AddIcon from 'material-ui/svg-icons/content/add';
import ReplyIcon from 'material-ui/svg-icons/content/reply';
import TrashIcon from 'material-ui/svg-icons/action/delete';
import CheckIcon from 'material-ui/svg-icons/action/check-circle';
import UncheckedIcon from 'material-ui/svg-icons/toggle/radio-button-unchecked';
import SendIcon from 'material-ui/svg-icons/content/send';
import LinkIcon from 'material-ui/svg-icons/content/link';
import MessageAllIcon from 'material-ui/svg-icons/content/send';
import SelectNoneIcon from 'material-ui/svg-icons/av/not-interested';
import SelectAllIcon from 'material-ui/svg-icons/action/done-all';
import PrintIcon from 'material-ui/svg-icons/action/print';
import CaretIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import InfoIcon from 'material-ui/svg-icons/action/info';
import NotesIcon from 'material-ui/svg-icons/action/speaker-notes';
import UserIcon from 'material-ui/svg-icons/social/person';
import RotateIcon from 'material-ui/svg-icons/image/rotate-right';
import PackIcon from 'material-ui/svg-icons/action/work';
import DocumentsIcon from 'material-ui/svg-icons/file/folder';
import ResumeIcon from 'material-ui/svg-icons/action/assignment';
import CalendarIcon from 'material-ui/svg-icons/action/date-range';
import HistoryIcon from 'material-ui/svg-icons/action/history';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import ShortlistIcon from 'material-ui/svg-icons/av/playlist-add-check';
import EventIcon from 'material-ui/svg-icons/action/event';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import BoldIcon from 'material-ui/svg-icons/editor/format-bold';
import DragHandleIcon from 'material-ui/svg-icons/editor/drag-handle';
import DragHandIcon from 'material-ui/svg-icons/action/pan-tool';
import FileIcon from 'material-ui/svg-icons/editor/insert-drive-file';
import MoveToTop from 'material-ui/svg-icons/editor/vertical-align-top';
import MoveToBottom from 'material-ui/svg-icons/editor/vertical-align-bottom';
import ItalicIcon from 'material-ui/svg-icons/editor/format-italic';
import UnderlineIcon from 'material-ui/svg-icons/editor/format-underlined';
import AttachIcon from 'material-ui/svg-icons/editor/attach-file';
import FontSizeIcon from 'material-ui/svg-icons/editor/format-size';
import ChangePasswordIcon from 'material-ui/svg-icons/action/lock';
import ArrowIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import MediaIcon from 'material-ui/svg-icons/action/camera-enhance';
import SkillsIcon from 'material-ui/svg-icons/action/done-all';
import SMSIcon from 'material-ui/svg-icons/communication/phonelink-ring';
import Contact from 'material-ui/svg-icons/communication/contacts';
import AgenciesIcon from 'material-ui/svg-icons/communication/business';
import TalentsIcon from 'material-ui/svg-icons/action/account-box';
import RightIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
import LeftIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import DateIcon from 'material-ui/svg-icons/action/query-builder';
import MoreHoriz from 'material-ui/svg-icons/navigation/more-horiz';
import CheckOpenIcon from 'material-ui/svg-icons/navigation/check';
import MoreVert from 'material-ui/svg-icons/navigation/more-vert';
import UploadIcon from 'material-ui/svg-icons/file/file-upload';
import UpArrow from 'material-ui/svg-icons/navigation/arrow-upward';
import DownArrow from 'material-ui/svg-icons/navigation/arrow-downward';
import ThumbsUp from 'material-ui/svg-icons/action/thumb-up';
import ThumbsDown from 'material-ui/svg-icons/action/thumb-down';
import Open from 'material-ui/svg-icons/action/open-in-new';
import SelectedIcon from 'material-ui/svg-icons/action/thumb-up';
import ErrorIcon from 'material-ui/svg-icons/alert/error';
import HelpIcon from 'material-ui/svg-icons/action/help-outline';

const icons = {
  active: CheckOpenIcon,
  add: AddIcon,
  address: AddressIcon,
  agency: AgenciesIcon,
  agent: AgenciesIcon,
  approve: ThumbsUp,
  arrow: ArrowIcon,
  attach: AttachIcon,
  bold: BoldIcon,
  burger: BurgerIcon,
  calendar: CalendarIcon,
  card: CardIcon,
  caret: CaretIcon,
  castingdirector: CastingDirectorIcon,
  castingDirector: CastingDirectorIcon,
  changePassword: ChangePasswordIcon,
  check: CheckIcon,
  close: CloseIcon,
  contact: Contact,
  date: DateIcon,
  documents: DocumentsIcon,
  down: DownArrow,
  download: DownloadIcon,
  dragHandle: DragHandleIcon,
  dragHand: DragHandIcon,
  edit: EditIcon,
  error: ErrorIcon,
  email: EmailIcon,
  event: EventIcon,
  file: FileIcon,
  fontSize: FontSizeIcon,
  help: HelpIcon,
  history: HistoryIcon,
  info: InfoIcon,
  italic: ItalicIcon,
  link: LinkIcon,
  media: MediaIcon,
  message: EmailIcon,
  messages: EmailIcon,
  more: MoreHoriz,
  moreHoriz: MoreHoriz,
  moreVert: MoreVert,
  moveToBottom: MoveToBottom,
  moveToTop: MoveToTop,
  multimessage: MessageAllIcon,
  multiupload: MultiMediaUploadIcon,
  next: RightIcon,
  notes: NotesIcon,
  open: Open,
  pack: PackIcon,
  phone: PhoneIcon,
  prev: LeftIcon,
  print: PrintIcon,
  reject: ThumbsDown,
  remove: TrashIcon,
  reply: ReplyIcon,
  resume: ResumeIcon,
  rotate: RotateIcon,
  schedule: CalendarIcon,
  selected: SelectedIcon,
  selectAll: SelectAllIcon,
  selectNone: SelectNoneIcon,
  send: SendIcon,
  share: SendIcon,
  settings: SettingsIcon,
  shortlist: ShortlistIcon,
  sideline: VisibleOffIcon,
  skills: SkillsIcon,
  sms: SMSIcon,
  ssn: InfoIcon,
  talent: TalentsIcon,
  thread: ForumIcon,
  trash: TrashIcon,
  tune: TuneIcon,
  uncheck: UncheckedIcon,
  underline: UnderlineIcon,
  up: UpArrow,
  upload: UploadIcon,
  user: UserIcon,
  visible: VisibleIcon,
};


export default muiThemeable()(({
  name, 
  color,
  backgroundColor,
  size=24,
  style,
  isActive,
  isReversed,
  tooltip="",
  muiTheme:{ palette },
  padding=0,
  ...rest
}) => {
  
  const Icon = icons[name] || icons.add;
  
  if(!color) {
    color = palette.primary800;
  }

  if(isActive) {
    color = palette['accent700'];
  }
  
  if(isReversed) {
    const tmp = color;
    color = backgroundColor || 'white';
    backgroundColor = tmp;
    padding = 4;
  }
  
  const props = {
    style: {
      boxSizing:'border-box',
      color,
      backgroundColor,
      borderRadius: '50%',
      width: `${size}px`,
      height: `${size}px`,
      padding,
      ...style
    },
    ...rest
  };
  
  return (
    <Icon {...props}/>
  );
});
