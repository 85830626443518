import React from 'react';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';
import AutoComplete from 'material-ui/AutoComplete';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import Icon from 'ui/Icon';
import Button from 'ui/Button';
import { Text, InputSelect } from 'skybolt-ui';
import Options from './Options';


export default class Field extends React.Component {
  state = {
    categorySearchText: "",
  }

  componentDidMount() {
    this.setState({
      categorySearchText: this.props.field.category || "",
    });
  }

  render() {
    const {
      field={},
      categories=[],
      conditionFields=[],
      conditionValues=[],
      onClose=()=>null,
      onChange=()=>null,
      onRemove=()=>null,
    } = this.props;

    const condition = field.condition || {};

    return (
      <div className="talentfields-field">
        <div className="talentfields-field-row">

          <div className="talentfields-field-info">
            <TextField
              floatingLabelText="Field Name"
              name="name"
              fullWidth
              defaultValue={field.name}
              onChange={(e, name) => onChange({...field, name})}
            />

            <TextField
              floatingLabelText="Hint"
              name="hint"
              fullWidth
              defaultValue={field.hint}
              onChange={(e, hint) => onChange({...field, hint})}
            />

            {field.category !== "system" &&
              <AutoComplete
                floatingLabelText="Category"
                filter={AutoComplete.fuzzyFilter}
                dataSource={categories}
                maxSearchResults={5}
                searchText={this.state.categorySearchText}
                onUpdateInput={categorySearchText => this.setState({categorySearchText})}
                onBlur={e => onChange({...field, category:e.currentTarget.value})}
                onNewRequest={category => onChange({...field, category})}
                fullWidth
              />
            }

            {field.category !== "system" &&
              <SelectField
                floatingLabelText="Type"
                value={field.type}
                fullWidth
                onChange={(e, i, type) => onChange({...field, type})}>
                <MenuItem value="select" primaryText="Select" />
                <MenuItem value="text" primaryText="Text" />
                <MenuItem value="number" primaryText="Number" />
                <MenuItem value="range" primaryText="Range" />
                <MenuItem value="date" primaryText="Date" />
              </SelectField>
            }

            {field.type === 'select' &&
              <div>
                <Text color="primaryLight">Options</Text>
                <Options
                  value={field.options || []}
                  onChange={options => onChange({...field, options})}
                />
              </div>
            }

            {(field.type === 'number' || field.type === 'range') &&
              <div>
                <TextField
                  floatingLabelText="Units"
                  name="units"
                  defaultValue={field.units}
                  onChange={(e, units) => onChange({...field, units})}
                  style={{width:"40%"}}
                />
                <TextField
                  floatingLabelText="Minimum"
                  name="min"
                  defaultValue={field.min}
                  style={{width:"20%"}}
                  onChange={(e, min) => onChange({...field, min})}
                />
                <TextField
                  floatingLabelText="Maximum"
                  name="max"
                  defaultValue={field.max}
                  style={{width:"20%"}}
                  onChange={(e, max) => onChange({...field, max})}
                />
                <TextField
                  floatingLabelText="Step"
                  name="max"
                  defaultValue={field.step || 1}
                  style={{width:"20%"}}
                  onChange={(e, step) => onChange({...field, step})}
                />
              </div>
            }

            {field.category !== "system" &&
              <div style={{paddingTop:16}}>
                <Text block color="primaryLight" style={{margin:'4px 0'}}>Only show if</Text>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <InputSelect
                    inline
                    placeholder="field"
                    style={{flex:1}}
                    value={condition.field}
                    options={conditionFields}
                    clearable
                    onChange={f => onChange({...field, condition:{field:f || null, value:null}})}
                  />
                  <Text block style={{flex:0, padding:"0 8px 10px 8px"}}>=</Text>
                  <InputSelect
                    inline
                    placeholder="value"
                    style={{flex:1}}
                    value={condition.value}
                    options={conditionValues}
                    clearable
                    onChange={v => onChange({...field, condition:{...condition, value:v || null}})}
                  />
                </div>
              </div>
            }

            {field.type === "date" && !!field.isExpiration && !!field.notifyOnExpiration && (
              <TextField
                floatingLabelText="Expiration Message"
                value={field.expirationMessage}
                onChange={(e, expirationMessage) => onChange({...field, expirationMessage})}
                multiLine
                fullWidth
              />
            )}
          </div>
          <div className="talentfields-field-options">
            <Checkbox
              className="talentfields-field-check"
              label="enabled"
              checked={field.isEnabled !== false}
              onCheck={(e, isEnabled) => {
                onChange({...field, isEnabled});
              }}
            />

            <Checkbox
              className="talentfields-field-check"
              label="required"
              checked={field.isRequired}
              onCheck={(e, isRequired) => {
                onChange({...field, isRequired});
              }}
            />

            {field.type === 'select' &&
              <Checkbox
                className="talentfields-field-check"
                label="allow multiple selections"
                checked={!!field.multiple}
                onCheck={(e, multiple) => onChange({...field, multiple})}
              />
            }

            <Checkbox
              className="talentfields-field-check"
              checkedIcon={<Icon name="edit"/>}
              label="editable by talent"
              checked={!!field.isEditableByTalent}
              onCheck={(e, isEditableByTalent) => onChange({...field, isEditableByTalent})}
            />

            <Checkbox
              className="talentfields-field-check"
              checkedIcon={<Icon name="card"/>}
              label="show on talent cards"
              checked={!!field.isVisibleOnCard}
              onCheck={(e, isVisibleOnCard) => onChange({...field, isVisibleOnCard})}
            />

            {!!field.isVisibleOnCard && field.category !== "system" &&
              <div style={{paddingLeft:40}}>
                <RadioButtonGroup
                  name="type"
                  defaultSelected={field.placement || "info"}
                  onChange={(e, placement) => onChange({...field, placement})}
                  style={{display:'flex', paddingBottom:8}}>

                  <RadioButton
                    value="info"
                    label="Info."
                    iconStyle={{width:14, height:14, marginRight:8}}
                    labelStyle={{fontSize:12, lineHeight:"14px"}}
                    style={{width:'auto', marginRight:16}}
                  />
                  <RadioButton
                    value="meta"
                    label="Metadata"
                    iconStyle={{width:14, height:14, marginRight:8}}
                    labelStyle={{fontSize:12, lineHeight:"14px"}}
                    style={{width:'auto'}}
                  />

                </RadioButtonGroup>
              </div>
            }

            <Checkbox
              className="talentfields-field-check"
              checkedIcon={<Icon name="visible"/>}
              label="visible to guest viewers"
              checked={!!field.isPublic}
              onCheck={(e, isPublic) => onChange({...field, isPublic})}
            />

            <Checkbox
              className="talentfields-field-check"
              checkedIcon={<Icon name="visible"/>}
              label="show on headshot prints"
              checked={!!field.isVisibleInPrint}
              onCheck={(e, isVisibleInPrint) => onChange({...field, isVisibleInPrint})}
            />

            <Checkbox
              className="talentfields-field-check"
              checkedIcon={<Icon name="history"/>}
              label="track changes"
              checked={!!field.trackChanges}
              onCheck={(e, trackChanges) => onChange({...field, trackChanges})}
            />

            {!!field.trackChanges && (
              <div style={{paddingLeft:40}}>
                <span style={{fontSize:12}}>Invalidate after</span>
                <TextField
                  name="invalidAfter"
                  value={field.invalidAfter || ""}
                  style={{width:40, height:31, textAlign:'center', margin:'0 8px', paddingTop:0}}
                  inputStyle={{height:24}}
                  onChange={(e, val) => onChange({...field, invalidAfter:parseInt(val, 10) || null})}
                />
                <span style={{fontSize:12}}>days.</span>
              </div>
            )}

            {field.type === "date" &&
              <Checkbox
                className="talentfields-field-check"
                checkedIcon={<Icon name="error"/>}
                label="is expiration date"
                checked={!!field.isExpiration}
                onCheck={(e, isExpiration) => onChange({...field, isExpiration})}
              />
            }

            {field.type === "date" && !!field.isExpiration && (
              <Checkbox
                className="talentfields-field-check"
                style={{marginBottom:0}}
                checkedIcon={<Icon name="send"/>}
                label="notify talent when date approaches"
                checked={!!field.notifyOnExpiration}
                onCheck={(e, notifyOnExpiration) => onChange({...field, notifyOnExpiration})}
              />
            )}

          </div>

        </div>

        {field.category !== "system" &&
          <div className="talentfields-field-actions">
            <Button
              icon="trash"
              label="Remove Field"
              raised={true}
              onClick={onRemove}
              disabled={field.isRemovable === false}
            />
          </div>
        }

        <Button
          icon="close"
          className="talentfields-field-close"
          onClick={onClose}
        />

      </div>

    );
  }
}