import React from 'react';
import { Card, Icon, Container, Text, Button, Rule, useLayout } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';

export default function AllAgencies(props) {

  const {width} = useLayout();

  const data = useQuery(`{
    agencies {
      agencyId
      name
      contactName
      contactEmail
    }
  }`);

  if(!data) {
    return (
      <div style={{textAlign:"center", paddingTop:120}}>
        <Icon name="loading" size={120} color="greyLight"/>
      </div>
    );
  }

  return (
    <Container>
      <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
        <Text title>AGENCIES</Text>
        <div style={{flex:1}}/>
        <Button title icon="plus" href="create"/>
      </div>
      <Rule style={{marginBottom:24}}/>

      {width > 600 &&
        <div style={{display:'flex', padding:8}}>
          <Text block small light style={{flex:1}}>Agency</Text>
          <Text block small light style={{flex:1}}>Contact Name</Text>
          {width > 960 && <Text block small light style={{flex:1}}>Contact Email</Text>}
        </div>
      }

      {data.agencies.map(agency =>
        <Card style={{marginBottom:2}} key={agency.agencyId}>
          <Button style={{padding:8, display:'flex', alignItems:'center'}} href={agency.agencyId}>
            <Text block bold style={{flex:1}}>{agency.name}</Text>
            {width > 600 && <Text block style={{flex:1}}>{agency.contactName}</Text>}
            {width > 960 && <Text block style={{flex:1}}>{agency.contactEmail}</Text>}
          </Button>
        </Card>
      )}

    </Container>
  );
}