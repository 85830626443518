import moment from 'moment-timezone';
import filtersDescription from './filtersDescription.js';
import download from 'downloadjs';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});


function row(credit, timezone = moment.tz.guess()) {
  let date = credit.date ? moment(credit.date).tz(timezone).format("MM/DD/YYYY") : "";
  let talent = credit.talentName || "";
  let payType = credit.payType || "";
  let useType = credit.useType || "";
  let pack = credit.packName || "";
  let num = credit.invoiceNumber || "";
  let total = credit.amount ? currencyFormatter.format(credit.amount) : "";
  let subnet = currencyFormatter.format((credit.debitSubtotal || 0)) || "";
  let owed = credit.debitAmount ? currencyFormatter.format(credit.debitAmount) : "";
  let com = credit.debitAgencyAmount ? currencyFormatter.format(credit.debitAgencyAmount) : "";
  
  return `${date},"${talent}","${payType}","${useType}","${pack}","${num}","${total}","${subnet}","${owed}","${com}"\r`;
}

function totals(credits, timezone = moment.tz.guess()) {
  // let totalTotal = currencyFormatter.format( credits.reduce((total, credit) => total + Number(credit.total || 0), 0) );
  let totalTotal = currencyFormatter.format( credits.reduce((t, c) => t + (Number(c.amount) || 0), 0) );
  let subtotalTotal = currencyFormatter.format( 
    credits.reduce((total, credit) => {
      return total + (credit.debitSubtotal || 0);
    }, 0)
  );
  let talentTotal = currencyFormatter.format( credits.reduce((t, c) => t + (Number(c.debitAmount) || 0), 0) );
  let agencyTotal = currencyFormatter.format( credits.reduce((t, c) => t + (Number(c.debitAgencyAmount) || 0), 0) );

  return `,,,,,Total,"${totalTotal}","${subtotalTotal}","${talentTotal}","${agencyTotal}"\r`;
}

export default function downloadCSV(credits, filters={}, timezone = moment.tz.guess()) {
  let title = "Credit Report";
  let description = filtersDescription(filters);
  let filename = "credits " + moment().tz(timezone).format("MM/DD/YYYY");
  let csv = ``;

  csv += `"${title}",,,,,,,,,\r`;
  csv += `"${description}",,,,,,,,,\r`;
  csv += `,,,,,,,,,\r`;
  csv += "Date,Talent,Pay Type,Use Type,Package,Invoice Number,Total,Sub Net,Owed to Talent,Total Commission\r";

  if(filters.orderBy == 'payType') {
    let payTypes = credits.reduce((payTypes, credit) => {
      if(!payTypes.includes(credit.payType)) {
        payTypes.push(credit.payType);
      }
      return payTypes;
    }, []);
    payTypes.sort();

    for(let payType of payTypes) {
      let creditsByType = credits.filter(credit => credit.payType == payType).sort((a,b) => b.creditNumber - a.creditNumber);
      for(let credit of creditsByType) {
        csv += row(credit, timezone);
      }
      csv += totals(creditsByType);
      csv += `,,,,,,,,,\r`;
    }
  }
  else {
    for(let credit of credits) {
      csv += row(credit, timezone);
    }
    csv += totals(credits, timezone);
    csv += `,,,,,,,,,\r`;
  }
  
  download(csv, `${filename}.csv`, 'text/csv');

}