import React, { useState, useContext } from 'react';
import { ThemeContext, useRouter, Form, Text, Button, Icon, Modal, InputTo, InputText, InputHTML } from 'skybolt-ui';
import { useQuery, saveDraft, removeDraft, sendMessage, updateInvoice, cloudinaryUpload } from 'skybolt-api';
import { isEmpty } from 'lodash';

export default function InvoiceSendButtonModal() {
  const {match:{ params:{ invoiceId }}} = useRouter();
  const draftId = `sendinvoice-${invoiceId}`;

  const [status, setStatus] = useState("");
  const [contactSearch, setContactSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [value, setValue] = useState({to:[]});

  const theme = useContext(ThemeContext);

  const data = useQuery(`{
    invoice(invoiceId:$invoiceId) {
      invoiceNumber
      client {
        clientId
        contactName
        emails
      }
    }
    draft(draftId:$draftId)
    agency {
      agencyId
    }
    contacts(limit:5, search:$contactSearch) {
      fullName
      emails
    }
  }`, {invoiceId, draftId, contactSearch});

  async function open() {
    if(!data.invoice.invoiceNumber) {
      Modal.error("An Invoice Number is required");
      return;
    }

    if(!data.invoice.client.clientId) {
      Modal.error("A Client is required.");
      return;
    }

    if(!isEmpty(data.draft)) {
      setValue(data.draft);
      setStatus("Saved");
    }
    else {
      setValue({
        to: (data.invoice.client.emails || [])
          .map((email, i, all) => {
            return {
              name: data.invoice.client.contactName || email.email,
              email: email.email,
            };
          }),
        subject: `Invoice ${data.invoice.invoiceNumber}`,
      });
    }

    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  function cancel() {
    setIsOpen(false);
    setValue({to:[]});
    removeDraft({draftId});
  }

  function save(value) {
    saveDraft({
      draftId,
      draft: value,
    });

    setValue(value);

    setStatus("Saving...");
    setTimeout(() => setStatus("Saved"), 1000);
  }

  async function send() {

    if(isSending) {
      return;
    }
    setIsSending(true);

    setStatus("Creating invoice...");

    let file;
    try {
      file = await cloudinaryUpload(`https://us-central1-skybolt-99adb.cloudfunctions.net/invoicePDF?invoiceId=${invoiceId}&agencyId=${data.agency.agencyId}`);
    }
    catch(error) {
      console.log('error', error);
      Modal.error("Something went wrong. Please try again.");
      setIsSending(false);
      setStatus("");
      return;
    }

    setStatus("Sending...");

    let res = await sendMessage({
      to: value.to.map(to => {
        if(typeof to === 'string') {
          return {
            email: to,
            name: to,
          };
        }
        return to;
      }),
      subject: value.subject,
      message: value.message,
      attachments: [
        {
          cloudinary_id: file.cloudinary_id,
          name: `Invoice_${data.invoice.invoiceNumber}.pdf`
        }
      ],
      invoiceId: invoiceId,
    });

    setIsOpen("sent");

    // After the modal is sefely off screen.
    setTimeout(() => {
      removeDraft({draftId});
      setValue({to:[]});
      setIsSending(false);
      // updateInvoice({
      //   invoiceId,
      //   update:{
      //     status:"POSTED",
      //     dateSent: Date.now(),
      //   }
      // });
    }, 400);

  }

  // Turn contacts into individual To's.
  let contacts = [];
  for(let contact of data ? data.contacts : []) {
    if(!contact.emails) {
      continue;
    }
    for(let emailKey in contact.emails) {
      let email = contact.emails[emailKey].email;
      if(!email) {
        continue;
      }
      let label = contact.emails[emailKey].label;
      let name = contact.fullName;

      if(!name) {
        name = email;
      }
      else if(Object.keys(contact.emails).length > 1) {
        name += ` <${label || email}>`;
      }
      contacts.push({name, email});
    }
  }


  return [
    <Button key="button" title onClick={open}>
      <Icon name="send" color="white"/>
    </Button>,
    <Modal key="modal" status={isOpen} onRequestClose={close}>

      <Form value={value} onChange={save} style={{padding:16}}>
        <Text paragraph large>Send Invoice</Text>
        <InputTo name="to" options={contacts} onSearch={setContactSearch} title="To" style={{marginBottom:1}}/>
        <InputText name="subject" title="Subject"/>
        <InputHTML name="message" title="Message" style={{minHeight:150}}/>
      </Form>

      <div style={{height:48, display:'flex', justifyContent:'flex-end'}}>
        {status && <Text block color="textLight" style={{padding:"16px 24px"}}>{status}</Text>}
        {status && <Button icon="trash" small color="textLight" style={{padding:"16px 24px"}} onClick={cancel}/>}
        <Button icon={isSending ? 'spinner-third' : 'send'} iconProps={{spin:isSending}} style={{padding:0, width:48, height:48, display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:theme.color.greyLight, borderRadius:0}} onClick={send}/>
      </div>

    </Modal>
  ];
}