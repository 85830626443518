import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { map, isArray, isEmpty, isObject, min, max, get } from 'lodash';
import {
} from 'actions';

import Icon from 'ui/Icon';
import Media from 'ui/Media';
import Page from 'ui/Page';
import Subtitle from 'ui/Subtitle';
import { formatSSN } from 'ui/SSNField';
import {Icon as UIIcon} from 'skybolt-ui';




function isValidURL(str) {
  const expression = /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
  return expression.test(str);
}

function formatPhoneNumber(s) {
  var s2 = (""+s).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? s : "(" + m[1] + ") " + m[2] + "-" + m[3];
}



export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.talentId;

  const talent = state.talents.all[talentId];
  const agency = talent && state.agencies.all[talent.agencyId] ? state.agencies.all[talent.agencyId] : {};

  const talentFields = agency.talentFields || {};

  const isLoggedIn = state.user.isLoggedIn;
  const isOwnAgency = state.user.agencyId === talent.agencyId;
  const isPublic = (!isLoggedIn || !isOwnAgency) && !state.user.permissions.canAdminTalents && !state.user.permissions.canPack;
  const user = state.users.all[ state.user.id ];
  const isOwnTalent = isLoggedIn && !!user.talents && !!user.talents[talentId];
  const canEditTalents = isOwnAgency && state.user.permissions.canEditTalents;
  const canAdminBilling = get(user, 'permissions.canAdminBilling');
  const canAgencyBilling = get(user, 'permissions.canAgencyBilling');
  const canBilling = canAdminBilling || (canAgencyBilling && isOwnAgency);
  const showContact = false;

  return {
    talentId,

    talent,
    agency,
    talentFields,

    isLoggedIn,
    isPublic,
    isOwnTalent,
    canEditTalents,
    canBilling,
    showContact,

    ...ownProps,
  };
};


export const mapDispatchToProps = {
};



export class TalentInfoDisplay extends Component {

  render() {
    const {
      canEditTalents,
      canBilling,
      isPublic,
      isOwnTalent,
      talentFields,
      agency,
      talent,
      isLoggedIn,
      showContact,
    } = this.props;

    const isSeekingAgency = talent.isSeekingAgency && isLoggedIn;

    let age = null;
    if(talent.dob) {
      age = moment().diff(talent.dob, 'years');
      if(age < 3) {
        const months = moment().diff(talent.dob, 'months') % 12;
        age = `${age} Years, ${months} Months Old`;
      }
      else {
        age = `${age} Years Old`;
      }
    }

    let dateUpdated = 0;
    let fieldsExpired = false;
    for(let fieldId in (talent.dateFieldUpdated || {})) {
      const date = get(talent, `dateFieldUpdated.${fieldId}.date`,  0);
      if(date > dateUpdated) {
        dateUpdated = date;
      }

      let invalidAfter = get(agency, `talentFields.${fieldId}.invalidAfter`, null);
      if(invalidAfter) {
        const timeSinceUpdate = Date.now() - date;
        const validFor = moment.duration(invalidAfter, 'days').valueOf();
        fieldsExpired = timeSinceUpdate > validFor;
      }
    }
    dateUpdated = dateUpdated > 0 ? moment(dateUpdated).format("MM/DD/YYYY") : "";

    const fields = Object.keys(talentFields || {})
      .map(id => ({...talentFields[id], id}))
      .filter(field => {
        if(isPublic && !field.isPublic) {
          return false;
        }

        if(!talent[field.id]) {
          return false;
        }

        if(isObject(talent[field.id]) && isEmpty(talent[field.id])) {
          return false;
        }

        if(isArray(talent[field.id]) && isEmpty(talent[field.id])) {
          return false;
        }

        return true;
      })
      .reduce((all, field) => {
        const category = field.category || 'Other';
        if(category === "system") {
          return all;
        }
        const fields = all[category] || {};
        return {
          ...all,
          [category]: {
            ...fields,
            [field.id]: field
          }
        };
      }, {});

    return (
      <div className="talentinfo-row">
        <div className="talentinfo-col-sm">

          {(talent.firstName || talent.middleName || talent.lastName) &&
            <div className="talentinfo-primary">
              <div className="talentinfo-primary-icon">
                <Icon
                  name="contact"
                  className="talentinfo-primary-icon"
                  style={{
                    width: 18,
                    height: 18,
                    color: '#9E9E9E',
                  }}
                />
              </div>
              <div className="talentinfo-name">
                {talent.firstName &&
                  <div className="talentinfo-name-first">
                    <div className="talentinfo-field-label">{talentFields.firstName ? talentFields.firstName.name : "First"}</div>
                    <div className="talentinfo-field-value">{talent.firstName}</div>
                  </div>
                }
                {talent.middleName &&
                  <div className="talentinfo-name-middle">
                    <div className="talentinfo-field-label">{talentFields.middleName ? talentFields.middleName.name : "Middle"}</div>
                    <div className="talentinfo-field-value">{talent.middleName}</div>
                  </div>
                }
                {talent.lastName &&
                  <div className="talentinfo-name-last">
                    <div className="talentinfo-field-label">{talentFields.lastName ? talentFields.lastName.name : "Last"}</div>
                    <div className="talentinfo-field-value">{talent.lastName}</div>
                  </div>
                }
              </div>
            </div>
          }

          {
            (
              // If the talent has a dob and
              age &&

              // on of
              (
                // this is private, or
                !isPublic ||

                // if there's an agency dob talent field and it isPublic, or
                (talentFields.dob && talentFields.dob.isPublic) ||

                // there isn't an agency dob talent field, and the agency's
                // `showTalentDOBPublic` isn't false. (depricated)
                (!talentFields.dob && agency.showTalentDOBPublic !== false)
              )
            ) &&

            <div className="talentinfo-primary">
              <div className="talentinfo-primary-icon">
                <Icon
                  name="calendar"
                  className="talentinfo-primary-icon"
                  style={{
                    width: 18,
                    height: 18,
                    color: '#9E9E9E',
                  }}
                />
              </div>
              <div className="talentinfo-age">
                {age} <span className="talentinfo-field-label">({moment(talent.dob).format('MM/DD/YYYY')})</span>
              </div>
            </div>
          }

          {!isPublic && talent.ssn &&
            <div className="talentinfo-primary">
              <div className="talentinfo-primary-icon">
                <Icon
                  name="ssn"
                  className="talentinfo-primary-icon"
                  style={{
                    width: 18,
                    height: 18,
                    color: '#9E9E9E',
                  }}
                />
              </div>
              <div className="talentinfo-field-value">
                {formatSSN(String(talent.ssn || ""))}
              </div>
            </div>
          }

          {(isSeekingAgency || !isPublic || showContact) && talent.phones &&
            <div className="talentinfo-primary">
              <div className="talentinfo-primary-icon">
                <Icon
                  name="phone"
                  className="talentinfo-primary-icon"
                  style={{
                    width: 18,
                    height: 18,
                    color: '#9E9E9E',
                  }}
                />
              </div>
              <div>
                {talent.contactName &&
                  <div className="talentinfo-field-value" style={{paddingBottom:8}}>
                    {talent.contactName}
                  </div>
                }
                {map(talent.phones, (phone, i) => {
                  if(!phone) {
                    return null;
                  }
                  return (
                    <div key={'phone'+i}>
                      <a href={`tel:${phone.number}`} className={`talentinfo-field-value ${phone.doContact !== false ? 'do-contact' : ''}`.trim()}>
                        {formatPhoneNumber(phone.number)}
                      </a>
                      <span className="talentinfo-field-label">{phone.label}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          }

          {(isSeekingAgency || !isPublic || showContact) && talent.emails &&
            <div className="talentinfo-primary">
              <div className="talentinfo-primary-icon">
                <Icon
                  name="email"
                  className="talentinfo-primary-icon"
                  style={{
                    width: 18,
                    height: 18,
                    color: '#9E9E9E',
                  }}
                />
              </div>
              <div>
                {map(talent.emails, (email, i) => {
                  if(!email) {
                    return null;
                  }
                  return (
                    <div key={'email'+i}>
                      <span className={`talentinfo-field-value ${email.doContact !== false ? 'do-contact' : ''}`.trim()}>
                        {email.email}
                      </span>
                      <span className="talentinfo-field-label">{email.label}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          }

          {!isPublic && talent.street &&
            <div className="talentinfo-primary">
              <div className="talentinfo-primary-icon">
                <Icon
                  name="address"
                  className="talentinfo-primary-icon"
                  style={{
                    width: 18,
                    height: 18,
                    color: '#9E9E9E',
                  }}
                />
              </div>
              <div className="talentinfo-field-value">
                {talent.street || ""} {talent.street && <br/>}
                {talent.city || ""} {talent.state || ""} {talent.zip || ""}
              </div>
            </div>
          }

          {isPublic && isSeekingAgency && talent.street &&
            <div className="talentinfo-primary">
              <div className="talentinfo-primary-icon">
                <Icon
                  name="address"
                  className="talentinfo-primary-icon"
                  style={{
                    width: 18,
                    height: 18,
                    color: '#9E9E9E',
                  }}
                />
              </div>
              <div className="talentinfo-field-value">
                {talent.city || ""} {talent.state || ""} {talent.zip || ""}
              </div>
            </div>
          }

          {!isSeekingAgency &&
            <div className="talentinfo-primary">
              <div className="talentinfo-primary-icon">
                <Icon
                  name="agency"
                  className="talentinfo-primary-icon"
                  style={{
                    width: 18,
                    height: 18,
                    color: '#9E9E9E',
                  }}
                />
              </div>
              <div className="talentinfo-agency">
                <Media media={agency.logo} width={100} style={{marginRight:8}} format={null}/>
                <div style={{paddingTop:4}}>{agency.name}</div>
              </div>
            </div>
          }

          {canBilling && talent.billingRate &&
            <div className="talentinfo-primary">
              <div className="talentinfo-primary-icon" style={{width:20, paddingLeft:6}}>
                <UIIcon
                  name="dollar-sign"
                  className="talentinfo-primary-icon"
                  size={16}
                  color={'#9E9E9E'}
                />
              </div>
              <div className="talentinfo-agency">
                <div style={{paddingTop:4}}>{talent.billingRate} / hour</div>
              </div>
            </div>
          }

          {canEditTalents &&
            <div>
              <div className="talentinfo-meta">
                {talent.code}
              </div>
              <div
                className="talentinfo-meta"
                style={{
                  color: fieldsExpired ? 'red' : null,
                }}>
                {dateUpdated}
              </div>
            </div>
          }

        </div>
        <div className="talentinfo-col-lg">

          {Object.keys(fields).map(category =>
            <div className="talentinfo-category" key={category}>
              <Subtitle>{category}</Subtitle>
              <Page className="talentinfo-category-fields">

                {Object.keys(fields[category])
                  .map(fieldId => fields[category][fieldId])
                  .sort((a, b) => !a.order || a.order < b.order ? -1 : 1)
                  .map(field => {

                    const fieldId = field.id;

                  	let val = talent[fieldId];
                	  let name = field.name || "";
                	  let units = field.units || "";

                	  if(field.isFeet) {
                	    val = `${Math.floor(val / 12)}'${Math.floor(val % 12)}"`.trim();
                	    units = "";
                	  }
                	  if(field.type === 'date') {
                	    val = moment(val).format('MM/DD/YYYY');
                	  }
                	  if(field.type === 'range') {
                	    if(isArray(val)) {
                	      if(min(val) === max(val)) {
                	        val = min(val);
                	      }
                	      else {
                	        val = `${min(val)}-${max(val)}`;
                	      }
                	    }
                	    else if(isObject(val)) {
                	      val = `${val.from}-${val.to}`;
                	    }
                	  }
                	  if(isArray(val)) {
                	    val = val.join(', ');
                	  }
                	  if(isValidURL(val)) {
                	    val = <a href={val} target="_blank" rel="noopener noreferrer">Link</a>;
                	  }

                	  let expired = false;
                    let updated = get(talent, `dateFieldUpdated[${fieldId}].date`, 0);
                  	if(field.trackChanges && field.invalidAfter) {
                    	const timeSinceUpdate = Date.now() - updated;
                      const validFor = moment.duration(field.invalidAfter, 'days').valueOf();
                      expired = timeSinceUpdate > validFor;
                  	}

                    return (
                      <div className="talentinfo-field" key={field.id}>

                        <div className="talentinfo-field-label">
                          {name}
                        </div>

                        <div
                          className="talentinfo-field-value"
                          style={{color: isOwnTalent && expired ? 'red' : null}}>
                          {val} {units}
                        </div>

                      </div>
                    );
                  })
                }

              </Page>
            </div>
          )}

        </div>
      </div>
    );
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(TalentInfoDisplay);