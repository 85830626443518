import { combineReducers } from "redux";

const all = (state={}, action) => {
  let newState = {};

  switch(action.type) {
    case 'LOAD_USER_REQUEST':
      return {
        ...state,
        [action.userId]: {
          isLoading: true,
        }
      };

    case 'LOAD_USERS_SUCCESS':
      return action.users;

    case 'LOAD_USER_SUCCESS':
    case 'LOGIN':
    case 'UPDATE_USER':
    case 'UPDATE_LOGGED_IN_USER':
      return {
        ...state,
        [action.userId || action.user.id || action.user.userId]: action.user,
      };

    case 'LOAD_USER_BILLING_REQUEST':
      return {
        ...state,
        [action.userId]: {...state[action.userId], isLoadingBilling:true},
      };

    case 'LOAD_USER_BILLING':
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          nextPayments: action.nextPayments,
          isLoadingBilling:false
        },
      };

    case 'ASSIGN_TALENT_SUCCESS':
      newState = {...state};

      if(action.fromUserId && state[action.fromUserId]) {
        const {
          [action.talentId]: removedTalent,
          ...talents
        } = state[action.fromUserId].talents;

        newState[action.fromUserId].talents = talents;
      }

      if(newState[action.userId] && newState[action.userId].talents) {
        newState[action.userId].talents[action.talentId] = Date.now();
      }
      return newState;

    case 'ADD_USER_TALENT':
      newState = {...state};
      if(!newState[action.userId]) {
        newState[action.userId] = {};
      }
      if(!newState[action.userId].talents) {
        newState[action.userId].talents = {};
      }
      newState[action.userId].talents[action.talentId] = Date.now();
      return newState;

    case 'SET_AGENCY':
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          agencyId: action.agencyId,
        }
      };

    case 'AGREE_TO_TERMS':
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          agreedToTerms: action.agreedToTerms,
        }
      };

    case 'LOAD_SUBSCRIPTION':
      if(!action.userId) {
        return state;
      }
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          subscriptionId: action.subscription.id,
          planId: action.planId || action.subscription.planId,
          requireSubscription: false,
        },
      };

    case 'LOGOUT':
      return {};

    default:
      return state;
  }
};

const isSearching = (state=false, action) => {
  switch(action.type) {
    case "SEARCH_USERS_REQUEST":
      return true;

    case "SEARCH_USERS":
      return false;

    default:
      return state;
  }
};

const search = (state={}, action) => {
  switch(action.type) {
    case "SEARCH_USERS":
      return action.users;

    default:
      return state;
  }
};





export default combineReducers({
  all,
  isSearching,
  search,
});