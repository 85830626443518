import React, { useState, useContext } from 'react';
import { useQuery, updatePack } from 'skybolt-api';
import {
  Card,
  Loading,
  Form,
  InputText,
  InputHTML,
  InputNumber,
  InputDate,
  InputSwitch,
  useRouter,
  Button,
  Drawer,
  ThemeContext,
} from 'skybolt-ui';
import { get } from 'lodash';
import "./index.css";
import InputClient from './InputClient';



export default function PackInfo(props) {


  // Load package data.

  const route = useRouter();
  const packId = get(route, 'match.params.packId', null);

  const data = useQuery(`{
    pack(packId:$packId)
    agency {
      useBilling
      billingAgencyFee
      billingCommission
      billingRate
      billingMaxUseDate
    }
  }`, {packId});

  const handleChange = update => {
    updatePack({packId, update});
  };


  // Handle info drawers.
  // Default to closed, but open where appropriate when data updates.

  const [isBilling, setBilling] = useState(false);
  const [isProduct, setProduct] = useState(false);
  const [isAdAgency, setAdAgency] = useState(false);
  const [isProductionCo, setProductionCo] = useState(false);



  // Render the form.

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  const hasBilling =
    data.pack.clientId ||
    data.pack.billingAgencyFee ||
    data.pack.billingCommission ||
    data.pack.billingRate ||
    data.pack.billingMaxUseDate;

  const hasProduct =
    data.pack.product ||
    data.pack.castingco ||
    data.pack.role ||
    data.pack.castingphone;

  const hasAdAgency =
    data.pack.adAgency ||
    data.pack.adAgencyProducer ||
    data.pack.adAgencyEmail ||
    data.pack.adAgencyPhone ||
    data.pack.adAgencyAddress;

  const hasProductionCo =
    data.pack.prodCo ||
    data.pack.prodCoProducer ||
    data.pack.prodCoDirector ||
    data.pack.prodCoEmail ||
    data.pack.prodCoPhone ||
    data.pack.prodCoOther;

  return (
    <Card style={{padding:16}}>
      <Form value={data.pack} onChange={(_, update) => handleChange(update)} className="packinfoform">
        <div>
          <InputText name="name" title="Package Name"/>
          <div style={{display:'flex', alignItems:'center'}}>
            <InputSwitch
              title="Booking"
              value={data.pack.type === "booking"}
              onChange={sel => handleChange({type: sel ? "booking" : null})}
              style={{marginRight:16}}
            />
            <InputSwitch
              title="Casting"
              value={data.pack.type === "casting"}
              onChange={sel => handleChange({type: sel ? "casting" : null})}
              style={{marginRight:16}}
            />
            <InputSwitch
              title="Job"
              value={data.pack.type === "job"}
              onChange={sel => handleChange({type: sel ? "job" : null})}
              style={{marginRight:16}}
            />
          </div>
          <InputDate name="date" title={`${data.pack.type ? data.pack.type.charAt(0).toUpperCase() + data.pack.type.slice(1) : "Package"} Date`}/>
          <InputHTML name="wardrobe" title="Wardrobe"/>
          <InputHTML name="notes" title="Notes"/>
        </div>
        <div>
          {data.agency.useBilling && (
            <div>
              <Button
                color={theme.color.textLight}
                label="Billing"
                icon={isBilling ? "caret-down" : "caret-right"}
                iconProps={{
                  style:{width:16, textAlign:'center'}
                }}
                onClick={() => setBilling(!isBilling)}
                style={{
                  display:'block',
                  fontWeight: hasBilling ? "bold" : "normal",
                }}
              />
              <Drawer open={isBilling}>
                <div className="packinfoform-section">
                  <InputClient name="clientId" title="Client"/>
                  <InputNumber name="billingAgencyFee" title="Agency Fee" hint="Percent"/>
                  <InputNumber name="billingCommission" title="Commission" hint="Percent"/>
                  <InputNumber name="billingRate" title="Rate" hint="Dollars"/>
                  <InputDate name="billingMaxUseDate" title="Max Use Date"/>
                </div>
              </Drawer>
            </div>
          )}
          <div>
            <Button
              color={theme.color.textLight}
              label="Product"
              icon={isProduct ? "caret-down" : "caret-right"}
              iconProps={{
                style:{width:16, textAlign:'center'}
              }}
              onClick={() => setProduct(!isProduct)}
              style={{
                display:'block',
                fontWeight: hasProduct ? "bold" : "normal",
              }}
            />
            <Drawer open={isProduct}>
              <div className="packinfoform-section">
                <InputText name="product" title="Product"/>
                <InputText name="castingco" title="Casting Co."/>
                <InputText name="role" title="Role"/>
                <InputText name="castingphone" title="Phone"/>
              </div>
            </Drawer>
          </div>
          <div>
            <Button
              color={theme.color.textLight}
              label="Ad Agency"
              icon={isAdAgency ? "caret-down" : "caret-right"}
              iconProps={{
                style:{width:16, textAlign:'center'}
              }}
              onClick={() => setAdAgency(!isAdAgency)}
              style={{
                display:'block',
                fontWeight: hasAdAgency ? "bold" : "normal",
              }}
            />
            <Drawer open={isAdAgency}>
              <div className="packinfoform-section">
                <InputText name="adAgency" title="Ad Agency"/>
                <InputText name="adAgencyProducer" title="Producer"/>
                <InputText name="adAgencyEmail" title="Email"/>
                <InputText name="adAgencyPhone" title="Phone"/>
                <InputText name="adAgencyAddress" title="Address"/>
              </div>
            </Drawer>
          </div>
          <div>
            <Button
              color={theme.color.textLight}
              label="Production Co."
              icon={isProductionCo ? "caret-down" : "caret-right"}
              iconProps={{
                style:{width:16, textAlign:'center'}
              }}
              onClick={() => setProductionCo(!isProductionCo)}
              style={{
                display:'block',
                fontWeight: hasProductionCo ? "bold" : "normal",
              }}
            />
            <Drawer open={isProductionCo}>
              <div className="packinfoform-section">
                <InputText name="prodCo" title="Company"/>
                <InputText name="prodCoProducer" title="Producer"/>
                <InputText name="prodCoDirector" title="Director"/>
                <InputText name="prodCoEmail" title="Email"/>
                <InputText name="prodCoPhone" title="Phone"/>
                <InputText name="prodCoOther" title="Other Contact"/>
              </div>
            </Drawer>
          </div>
        </div>
      </Form>
    </Card>
  );
}