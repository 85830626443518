import React, { useContext, useMemo } from 'react';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { Text, Rule, Button, Container, Icon, ThemeContext, Card, Currency, InputSwitch, Loading, /*Modal*/ } from 'skybolt-ui';
import { useQuery, /*updateDebit*/ } from 'skybolt-api';

export default function DebitsList(props) {


  // Manage Data

  const { pathname } = props.location;

  const data = useQuery(`{
    agency {
      timezone
      debits(status:"PENDING") {
        debitId
        talentName
        talentImage
        packName
        packImage
        clientId
        clientName
        dateJob
        status
        invoiceNumber
        grossAmount
        taxAmount
        otherAmount
        agencyAmount
        amount
      }
    }
  }`);

  let timezone = data?.agency?.timezone || moment.tz.guess();

  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return (
      <div style={{width:"100%"}}>
        <Container>
          <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
            <Text title>ACCOUNTS PAYABLE</Text>
            <div style={{flex:1}}/>
            <Button title icon="plus"/>
          </div>
          <Rule/>
          <div style={{marginBottom:24}}>
            <Button label="Pending" style={{borderRadius:0, backgroundColor:theme.color.primaryLighter}}/>
            <Button label="Scheduled" href="scheduled" style={{borderRadius:0, backgroundColor:theme.color.greyLight}}/>
            <Button label="Sent" href="sent" style={{borderRadius:0, backgroundColor:theme.color.greyLight}}/>
          </div>
        </Container>
        <Loading style={{paddingTop:40}}/>
      </div>
    );
  }

  

  return (
    <div style={{width:"100%"}}>



      {/* Title */}

      <Container>
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>ACCOUNTS PAYABLE</Text>
          <div style={{flex:1}}/>
          <Button title icon="plus" href={{pathname:"/billing/debits/create", state:{referrer:pathname}}}/>
        </div>
        <Rule/>
        <div style={{marginBottom:24}}>
          <Button label="Pending" style={{borderRadius:0, backgroundColor:theme.color.primaryLighter}}/>
          <Button label="Scheduled" href="scheduled" style={{borderRadius:0, backgroundColor:theme.color.greyLight}}/>
          <Button label="Sent" href="sent" style={{borderRadius:0, backgroundColor:theme.color.greyLight}}/>
        </div>
      </Container>

      <div style={{width:'100%', overflowX:'auto'}}>

        <Container style={{minWidth:900}}>

          <div style={{display:'flex', alignItems:'center', paddingBottom:8}}>
            <div style={{flex:2}}>
              <Text small light>Talent</Text>
            </div>
            <div style={{flex:2}}>
              <Text small light>Job Date</Text>
            </div>
            <div style={{flex:2}}>
              <Text small light>Package</Text>
            </div>
            <div style={{flex:2}}>
              <Text small light>Client</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Invoice #</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Gross</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Tax</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Other</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Agency</Text>
            </div>
            <div style={{flex:1}}>
              <Text small light>Net</Text>
            </div>
          </div>

          {data.agency.debits.map(debit => (
            <div key={debit.debitId} style={{display:'flex', alignItems:'center'}}>
              <Card
                key={debit.debitId}
                style={{
                  flex:1,
                  display:'flex',
                  alignItems:'center',
                  marginBottom:4,
                  height:40,
                }}>

                <div style={{flex:2, display:'flex', alignItems:'center'}}>
                  {debit.talentImage &&
                    <img src={debit.talentImage} alt="headshot" style={{width:40, height:40, marginRight:8}}/>
                  }
                  <Text>{debit.talentName}</Text>
                </div>
                <div style={{flex:2}}>
                  <Text>{debit.dateJob ? moment(debit.dateJob).tz(timezone).format("MM/DD/YYYY") : ""}</Text>
                </div>
                <div style={{flex:2, overflow:'hidden'}}>
                  <Text block style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                    {debit.packName || ""}
                  </Text>
                </div>
                <div style={{flex:2, overflow:'hidden'}}>
                  <Text block style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                    {debit.clientName || ""}
                  </Text>
                </div>
                <div style={{flex:1}}>
                  <Text>{debit.invoiceNumber || ""}</Text>
                </div>
                <div style={{flex:1}}>
                  <Currency>{debit.grossAmount}</Currency>
                </div>
                <div style={{flex:1}}>
                  <Currency>{debit.taxAmount}</Currency>
                </div>
                <div style={{flex:1}}>
                  <Currency>{debit.otherAmount}</Currency>
                </div>
                <div style={{flex:1}}>
                  <Currency>{debit.agencyAmount}</Currency>
                </div>
                <div style={{flex:1}}>
                  <Currency>{debit.amount}</Currency>
                </div>
              </Card>

            </div>
          ))}

        </Container>

      </div>

    </div>
  );
}
