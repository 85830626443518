import React from 'react';
import { Switch, Route, Redirect } from 'skybolt-ui';
import PendingList from './PendingList';
import DraftList from './DraftList';
import SentList from './SentList';
import Check from './Check';

export default function Payments(props) {
  const {match:{ url, path }} = props;
  return (
    <Switch>
      <Route path={`${path}/pending`} component={PendingList}/>
      <Route path={`${path}/scheduled`} component={DraftList}/>
      <Route path={`${path}/sent`} component={SentList}/>
      <Route path={`${path}/:checkId`} component={Check}/>
      <Redirect to={`${url}/scheduled`}/>
    </Switch>
  );
}