import { combineReducers } from "redux";

const defaultSearch = {
  query: "",
  status: ["active"],
};

const search = (state=defaultSearch, action) => {
  switch(action.type) {
    case 'SET_AGENCY_TALENTS_SEARCH':
      return action.search;
    
    case 'CLEAR_AGENCY_TALENTS_SEARCH':
    case 'LOGOUT':
    case 'SET_AGENCY':
      return defaultSearch;
    
    default: 
      return state;
  }
};

const searchResults = (state={}, action) => {
  switch(action.type) {
    case 'SET_AGENCY_TALENTS_SEARCH_RESULTS':
      return action.talents;
      
    case 'ADD_AGENCY_TALENTS_SEARCH_RESULTS':
      return {...state, ...action.talents};
      
    case 'UPDATE_TALENT':
      return {
        ...state,
        [action.talentId]: {...state[action.talentId], ...action.talent},
      };
      
    case 'LOGOUT':
    case 'CLEAR_AGENCY_TALENTS_SEARCH':
    case 'SET_AGENCY':
      return {};
    
    default: 
      return state;
  }
};

const searchCount = (state=null, action) => {
  switch(action.type) {
    case 'SET_AGENCY_TALENTS_SEARCH_RESULTS':
      return action.count;
    
    case 'CLEAR_AGENCY_TALENTS_SEARCH':
    case 'SET_AGENCY':
    case 'LOGOUT':
      return null;
    
    default: 
      return state;
  }
};

const searchMode = (state="simple", action) => {
  switch(action.type) {
    case 'SET_AGENCY_TALENTS_SEARCH_MODE':
      return action.mode;
    
    case 'LOGOUT':
    case 'SET_AGENCY':
      return 'simple';
      
    default:
      return state;
  }
};

const isSearching = (state=false, action) => {
  switch(action.type) {
    case 'SET_AGENCY_TALENTS_SEARCH':
      return true;
    
    case 'CLEAR_AGENCY_TALENTS_SEARCH':
    case 'SET_AGENCY_TALENTS_SEARCH_RESULTS':
    case 'ADD_AGENCY_TALENTS_SEARCH_RESULTS':
    case 'LOGOUT':
    case 'SET_AGENCY':
      return false;
      
    default:
      return state;
  }
};

const forApproval = (state=null, action) => {
  switch(action.type) {
    case 'LOAD_UNAPPROVED_TALENTS':
      return action.talents;
      
    case 'UPDATE_TALENT':
      if(state && state[action.talentId]) {
        return {...state, [action.talentId]:action.talent};
      }
      return state;
      
    case 'LOGOUT':
    case 'SET_AGENCY':
      return null;
      
    default:
      return state;
  }
};

export default combineReducers({
  isSearching,
  searchMode,
  search,
  searchResults,
  searchCount,
  forApproval,
});