import React from 'react';
import Checkbox from 'material-ui/Checkbox';
import muiThemeable from 'material-ui/styles/muiThemeable';

export default muiThemeable()(({
  label = "",
  checked = false,
  onChange = ()=>null,
  style = {},
  muiTheme:{ palette },
  ...props
}) => {
  return (
    <div className="sui-title-checkbox">
      <Checkbox
        label={label}
        checked={checked}
        onCheck={(e, checked) => onChange(checked)}

        labelStyle={{
          fontSize: 14,
          color: "#aaa",
        }}

        iconStyle={{
          width: 18,
          height: 18,
          marginTop: 1,
          marginRight: 4,
        }}

        style={{
          marginRight: 16,
          ...style,
        }}

        {...props}
      />
    </div>
  );
});