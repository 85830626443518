import React, {useCallback} from 'react';
import {
  Text,
  Rule,
  Card,
  useRouter,
  Loading,
  Form,
  Icon,
  InputArray,
  InputSelect,
  InputText,
  InputPhone,
  InputNumber,
  InputHTML,
} from 'skybolt-ui';
import { useQuery, updateClient } from 'skybolt-api';
import { debounce } from 'lodash';
import "./index.css";


export default function ClientInfo(props) {

  const {match:{ params:{ clientId }}} = useRouter();

  const data = useQuery(`{
    client(clientId:$clientId)
  }`, {clientId});

  const handleChange = useCallback(
    debounce((_, update) => {
      updateClient({clientId, update});
    }, 500)
  , [clientId]);


  // Render

  if(!data) {
    return <Loading/>;
  }

  return (
    <div className="billing-client-info" >
      <div style={{display:'flex', alignItems:'flex-end', height:40}}>
        <Text title>Info.</Text>
      </div>
      <Rule style={{marginBottom:24}}/>

      <Card style={{padding:16, marginBottom:40}}>
        <Form className="billing-client-info-card" value={data.client} onChange={handleChange}>
          <div className="info">
            <InputText name="name" title="Client Name"/>
            <InputText name="poNumber" title="PO Number"/>
            <InputText name="vendorNumber" title="Vendor Number"/>
          </div>

          <div className="notes">
            <InputHTML name="notes" title="Notes" hint="Only visible to agents."/>
          </div>

          <div className="rates">
            <Text small>Rates</Text>
            <Rule style={{marginBottom:16}}/>
            <InputNumber
              name="rate"
              title="Standard Rate"
              hint="Hourly Rate in Dollars"
            />
            <InputNumber
              name="agencyFee"
              title="Standard Fee"
              hint="Agency Fee Percentage"
            />
          </div>

          <div className="billing">
            <Text small>Billing Information</Text>
            <Rule style={{marginBottom:16}}/>

            <InputText name="contactName" title="Name"/>

            <InputText name="contactTitle" title="Title"/>

            <InputPhone
              title="Phone Number"
              name="phone"
              style={{flex:1}}
            />

            <InputText
              title="Email Address"
              name="email"
              style={{flex:1}}
            />

            <InputText
              name="address.street"
              title="Address"
            />
            <div style={{display:'flex'}}>
              <InputText
                name="address.city"
                title="City"
                style={{flex:1, marginRight:8}}
              />
              <InputText
                name="address.state"
                title="State"
                style={{width:60, marginRight:8}}
              />
              <InputText
                name="address.zip"
                style={{width:100}}
                title="Zip"
              />
            </div>

          </div>

          <div className="contact">
            <Text small>Contact</Text>
            <Rule style={{marginBottom:16}}/>
            <InputArray name="contacts" addValue={{name:"", label:"Office", address:""}} addLabel="Add Contact">
              <div className="contact-contact">
                <InputText
                  title="Name"
                  name="name"
                  style={{width:'100%'}}
                />
                <InputSelect
                  title="Label"
                  name="label"
                  options={["Office", "Home", "Mobile", "Fax"]}
                  style={{marginRight:8, width:100}}
                />
                <InputText
                  title="Phone Number or Email Address"
                  name="address"
                  style={{flex:1}}
                />
              </div>
            </InputArray>
          </div>
        </Form>
      </Card>
    </div>
  );
}


/*
<InputArray name="phones" addValue={{label:"Office", number:""}} addLabel="Add Phone Number">
  <Icon
    name="phone-rotary"
    small
    duotone
    style={{paddingTop:14, marginRight:8}}
  />
  <InputSelect
    title="Label"
    name="label"
    options={["Office", "Home", "Mobile", "Fax"]}
    style={{marginRight:8, width:100}}
  />
  <InputPhone
    title="Phone Number"
    name="number"
    style={{flex:1}}
  />
</InputArray>
*/