import { combineReducers } from "redux";


const message = (state="", action) => {
  switch(action.type) {
    case 'SNACKBAR':
      return action.message || "";
      
    default: 
      return state;
  }
};

const timeout = (state=4000, action) => {
  switch(action.type) {
    case 'SNACKBAR':
      return action.timeout;
      
    default: 
      return state;
  }
};

const action = (state=null, action) => {
  switch(action.type) {
    case 'SNACKBAR':
      return action.action || null;
      
    default: 
      return state;
  }
};

export default combineReducers({
  message,
  timeout,
  action,
});