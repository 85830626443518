import React, { useContext, useState, useEffect } from 'react';
import {
  useQuery,
  createTalent,
} from 'skybolt-api';
import {
  Container,
  Card,
  Text,
  Button,
  Form,
  InputText,
  InputDate,
  InputPhone,
  InputNumber,
  InputSelect,
  ThemeContext,
  Rule,
} from 'skybolt-ui';
import { systemTalentFields } from 'config';
import { isEmpty, get } from 'lodash';
import "./index.css";


export default function CreateTalent(props) {

  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ value, setValue ] = useState({});
  const [ search, setSearch ] = useState("");
  const [ error, setError ] = useState({});


  const data = useQuery(`{
    search: availableAgencies(search:$search) {
      agencyId
      name
      meta
    }
    agency(agencyId:$agencyId) {
      agencyId
      talentFields
      requireTalentSubscription
      requireUserSubscription
      chargeTalentsToAgency
    }
    user {
      userId
      agency {
        agencyId
        name
      }
      talents {
        status
        agency {
          agencyId
          name
        }
      }
      plan
      epayId
    }
  }`, {search:search, agencyId:value.agencyId});


  const userAgency = get(data, 'user.agency', null);

  const talentAgencies = get(data, 'user.talents', [])
    .reduce((agencies, talent) => {
      if(!talent.agency) {
        return agencies;
      }
      if(agencies.find(a => a.agencyId === talent.agency.agencyId)) {
        return agencies;
      }
      return [...agencies, talent.agency];
    }, []);

  const agencies = [userAgency, ...talentAgencies];


  // If all this user's talents are in the same agency, default to that agency.
  const defaultAgency = userAgency ? userAgency.agencyId :
                        talentAgencies.length === 1 ? talentAgencies[0].agencyId :
                        null;
  useEffect(() => {
    if(defaultAgency && !value.agencyId) {
      setValue({...value, agencyId:defaultAgency});
    }
  }, [defaultAgency]);


  // Create handler

  const create = async () => {

    // Validation.

    if(isSubmitting) {
      return;
    }
    if(!value) {
      return;
    }
    let error = {};
    if(!value.emails || !value.emails[0].email) {
      error.email = "Please enter an email address.";
    }
    if(!value.phones || !value.phones[0].number) {
      error.phone = "Please a phone number.";
    }
    for(const field of fields) {
      if(field.isRequired && !value[field.fieldId]) {
        error[field.fieldId] = `Please enter a ${field.name}`;
      }
    }
    if(!isEmpty(error)) {
      setError(error);
      return;
    }
    setIsSubmitting(true);


    // Create the talent.

    try {
      const talent = await createTalent({
        ...value,
        requireSubscription: true,
        requireShowWelcome: false,
      });
      props.history.replace(talent.talentId);
    }
    catch(error) {
      setError({error:"Something went wrong, please try again."});
      setIsSubmitting(false);
      return;
    }

  };



  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return null;
  }

  // If the user is searching show search results,
  // otherwise show user's own agencies to choose from.
  let searchOptions = [];
  if(search.length > 2) {
    searchOptions = data.search
      .map(a => ({label:a.name, value:a.agencyId}))
      .slice(0, 3);
  }
  else if(agencies.length > 0) {
    searchOptions = agencies
      .map(a => ({label:a.name, value:a.agencyId}));
  }

  // Combine agency fields with system fields.
  let fields = {
    ...systemTalentFields,
    ...data.agency.talentFields
  };

  // Filter those to required fields.
  fields = Object.keys(fields)
    .map(fieldId => ({fieldId, ...fields[fieldId]}))
    .filter(field => field.isRequired)
    .sort((a, b) => a.order - b.order);


  return (
    <div style={{width:'100%'}}>
      <div className="CreateTalent-header" style={{backgroundColor: theme.color.accent}}>
        <Text>
          <Text style={{fontSize:"64px", lineHeight:'.9em', color:'white', letterSpacing:4}} bold>ADD</Text><br/>
          <Text style={{fontSize:"38px", lineHeight:'.9em', color:'white', letterSpacing:2}}>TALENT</Text>
        </Text>
        <Rule small color="white" style={{margin:'32px auto 24px'}}/>
        <Text style={{color:theme.color.lightTextLighter, textAlign:'center', fontSize:16, lineHeight:'18px', maxWidth:600}}>
          Fill out the form below to add a profile to your account.
          You will automatically receive a discounted rate for each additional profile.
          The new profile will be joined to your account automatically.
        </Text>
      </div>

      <Container style={{textAlign:'center', paddingBottom:40}}>

        <Card className="CreateTalent-card">

          <Form value={value} onChange={val => setValue(val)}>

            <InputSelect
              title="Agency"
              placeholder="Search..."
              hint="Leave blank if you don't have an agency or can't find your agency."
              name="agencyId"
              strict
              onSearch={setSearch}
              options={searchOptions}
            />

            <Rule color={theme.color.greyLight} style={{margin:'8px 0 16px 0'}}/>

            {fields.map(field => {
              switch(field.type) {
                case 'number':
                case 'range':
                  return (
                    <InputNumber
                      key={field.fieldId}
                      name={field.fieldId}
                      title={field.name}
                      min={field.min}
                      max={field.max}
                      units={field.units}
                      error={error[field.fieldId]}
                    />
                  );
                case 'select':
                  return (
                    <InputSelect
                      key={field.fieldId}
                      name={field.fieldId}
                      title={field.name}
                      options={field.options}
                      error={error[field.fieldId]}
                    />
                  );
                case 'date':
                  return (
                    <InputDate
                      key={field.fieldId}
                      name={field.fieldId}
                      title={field.name}
                      error={error[field.fieldId]}
                    />
                  );
                default:
                  return (
                    <InputText
                      key={field.fieldId}
                      name={field.fieldId}
                      title={field.name}
                      error={error[field.fieldId]}
                    />
                  );
              }
            })}

            <InputText
              name="emails.0.email"
              title="Primary Email Address"
              error={error.email}
            />

            <InputPhone
              name="phones.0.number"
              title="Primary Phone Number"
              error={error.phone}
            />

            <Text error centered>{error.error || null}</Text>
          </Form>

        </Card>

        <Button
          raised
          accent={!isSubmitting}
          label={!isSubmitting ? "Continue" : "Hang Tight!"}
          onClick={create}
        />

      </Container>
    </div>
  );
}