/*

Display a message to the talent between their header and content.
This is used to inform them that the talent:

- has been cancelled,
- has expired,
- will expire,
- has been sent a verification email,
- has been re-sent a verification email,
- has out of date fields
- is missing required fields.

*/

import React, { useContext, useState } from 'react';
import moment from 'moment';
import { omit } from 'lodash';
import {
  useRouter,
  Text,
  Button,
  ThemeContext,
} from 'skybolt-ui';
import {
  useQuery,
  updateTalent,
  invite,
  sendTalentEmailVerification,
} from 'skybolt-api';
import './index.css';


export default function TalentStatus() {
  const route = useRouter();
  const theme = useContext(ThemeContext);
  const talentId = route.match.params.talentId;

  const data = useQuery(`{
    user {
      agencyId
      permissions {
        canAddTalents
        canEditTalents
        canPack
      }
      talents
    }
    talent(talentId:$talentId) {
      talentId
      agencyId
      fullName
      status
      dateExpires
      fields
      invitation
      emails
      agency {
        talentFields
      }
    }
  }`, {talentId});


  const [state, _setState] = useState({});
  const setState = update => _setState({...state, ...update});

  if(!data) {
    return null;
  }

  const isOwnTalent = data.user.talents.find(t => t.talentId === talentId);
  const isOwnAgency = data.user.agencyId === data.talent.agencyId;
  const canAdmin = isOwnAgency && (data.user.permissions.canEditTalents || data.user.permissions.canPack);



  // If the talent is on a trial account

  if((isOwnTalent || canAdmin) && data.talent.status === 'TRIAL') {
    return (
      <div className="talentstatus" style={{backgroundColor:theme.color.errorLighter}}>
        <Text block>Upgrade your account to a paid plan and give your agent unlimited images to work with.</Text>
        <Button
          block
          label="Upgrade"
          style={{backgroundColor:theme.color.errorDark, margin:"8px 0"}}
          href={`/talents/${talentId}/subscription`}
        />
      </div>
    );
  }


  // If the talent has been canceled.

  if((isOwnTalent || canAdmin) && data.talent.status === 'CANCELED') {
    return (
      <div className="talentstatus" style={{backgroundColor:theme.color.errorLighter}}>
        <Text block>{data.talent.fullName} has been canceled.</Text>
        <Button
          block
          label="Restart"
          style={{backgroundColor:theme.color.errorDark, margin:"8px 0"}}
          href={`/talents/${talentId}/subscription`}
        />
      </div>
    );
  }


  // If the talent is expired.

  if((isOwnTalent || canAdmin) && data.talent.status === 'EXPIRED') {
    return (
      <div className="talentstatus" style={{backgroundColor:theme.color.errorLighter}}>
        <Text block>{data.talent.fullName} has expired.</Text>
        <Button
          block
          label="Restart"
          style={{backgroundColor:theme.color.errorDark, margin:"8px 0"}}
          href={`/talents/${talentId}/subscription`}
        />
      </div>
    );
  }


  // If the talent will expire.

  if(isOwnTalent) {
    const days = -moment().diff(data.talent.dateExpires, 'days');
    if(days < 30 && days > 0) {
      return (
        <div className="talentstatus" style={{backgroundColor:theme.color.errorLighter}}>
          <Text block>{data.talent.fullName} will expire in {days} days.</Text>
          <Button
            block
            raised
            label="Update Payment Details"
            style={{backgroundColor:theme.color.errorDark, margin:"8px 0"}}
            href={`/talents/${talentId}/subscription`}
          />
        </div>
      );
    }
  }


  // Look for expired fields and call them out.
  if(isOwnTalent) {
    const expiredField = data.talent.fields.find(f => f.isExpired);
    if(expiredField) {
      return (
        <div className="talentstatus" style={{backgroundColor:theme.color.errorLighter}}>
          <Text block>
            Your <strong>{expiredField.name}</strong> expiration date
            is {moment(parseInt(expiredField.value, 10)).format("MM/DD/YYYY")}.
            If you have already renewed it please enter the new expiration
            date. {expiredField.expirationMessage}
          </Text>
        </div>
      );
    }
  }


  // Look for out-of-date fields and call them out.

  if(isOwnTalent) {
    const outOfDateField = data.talent.fields.find(f => f.isOutOfDate);
    if(outOfDateField) {
      return (
        <div className="talentstatus" style={{backgroundColor:theme.color.errorLighter}}>
          <Text block>Is your {outOfDateField.name} field up to date?</Text>
          <Button
            block
            label="Yes"
            style={{backgroundColor:theme.color.errorDark, margin:"8px 0"}}
            onClick={() => updateTalent({
              talentId,
              changes: {
                [`dateFieldUpdated.${outOfDateField.fieldId}.date`]: Date.now(),
              }
            })}
          />
        </div>
      );
    }
  }


  // Invitations

  if(data.user.permissions.canAddTalents) {
    if(state.invitationSent) {
      return (
        <div className="talentstatus" style={{backgroundColor:theme.color.primaryLighter}}>
          <Text block>Invitation sent to {data.talent.invitation.user.email}</Text>
        </div>
      );
    }
    if(data.talent.invitation && !data.talent.invitation.dateUsed && !state.invitationSent) {
      return (
        <div className="talentstatus" style={{backgroundColor:theme.color.accentBackground}}>
          <Text block>An invitation has been sent to {data.talent.fullName}.</Text>
          <Button
            block
            label="Resend"
            style={{backgroundColor:theme.color.accent, margin:"8px 0"}}
            onClick={() => {
              const inv = omit(data.talent.invitation, ['dateSent', 'dateCreated']);
              invite(inv);
              setState({invitationSent:true});
            }}
          />
        </div>
      );
    }
  }


  // Email Verification

  if(isOwnTalent) {
    if(state.verificationSent) {
      return (
        <div className="talentstatus" style={{backgroundColor:theme.color.primaryLighter}}>
          <Text block>A verification email has been sent to {state.verificationSent}</Text>
        </div>
      );
    }

    const handleSend = (emailId) => () => {
      sendTalentEmailVerification({talentId, emailId});
      setState({verificationSent:data.talent.emails[emailId].email});
    };
    for(var emailId in data.talent.emails) {
      if(data.talent.emails[emailId].doContact !== false && !data.talent.emails[emailId].isVerified) {
        if(data.talent.emails[emailId].verificationSent) {
          return (
            <div className="talentstatus" style={{backgroundColor:theme.color.primaryLighter}}>
              <Text block>
                A verification email has been sent to <em>{data.talent.emails[emailId].email}</em>.
              </Text>
              <Button
                block
                label="Resend"
                style={{backgroundColor:theme.color.accent, margin:"8px 0"}}
                onClick={handleSend(emailId)}
              />
            </div>
          );
        }
        return (
          <div className="talentstatus" style={{backgroundColor:theme.color.primaryLighter}}>
            <Text block>
              Please verify your email address <em>{data.talent.emails[emailId].email}</em>.
            </Text>
            <Button
              block
              label="Send Verification"
              style={{backgroundColor:theme.color.accent, margin:"8px 0"}}
              onClick={handleSend(emailId)}
            />
          </div>
        );
      }
    }
  }


  // Required Fields

  if(isOwnTalent) {
    for(const fieldId in data.talent.agency.talentFields) {
      const field = data.talent.agency.talentFields[fieldId];
      const isRequired = field.isRequired;
      const exists = !!data.talent.fields.find(f => f.fieldId === fieldId);
      if(isRequired && !exists) {
        return (
          <div className="talentstatus" style={{backgroundColor:theme.color.primaryLighter}}>
            <Text block>
              Please add a <em>{field.name}</em>.
            </Text>
          </div>
        );
      }
    }
  }


  // TODO: Required Image Tags


  return null;
}

