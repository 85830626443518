import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouter,
} from 'skybolt-ui';

import List from './List';
import Spotlight from './Spotlight';
import Create from './Create';

export default props => {
  const {match:{url, path}} = useRouter();
  return (
    <Switch>
      <Route path={`${path}/all`} component={List}/>
      <Route path={`${path}/create`} component={Create}/>
      <Route path={`${path}/:spotlightId`} component={Spotlight}/>
      <Redirect to={`${url}/all`}/>
    </Switch>
  );
};