import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import {
  loadPack,
  loadPackTalents,
  subscribeToPackEvents,
  updateEvent,
  updatePackSchedule,
  addPackScheduleTimeslot,
  removePackScheduleTimeslot,
  subscribeToPackTalents,
  subscribeToChildPackTalents,
  subscribeToChildPackEvents,
} from 'actions';

import './index.css';

import Container from 'ui/Container';
import Page from 'ui/Page';
import Field from 'ui/Field';
import Placeholder from 'ui/Placeholder';
import AddressField from 'ui/AddressField';
import Title, {
  Action,
} from 'ui/Title';
import TalentCard from 'ui/OldTalentCard';
import MessageScheduledDialog from '../MessageScheduledDialog';
import Button from 'ui/Button';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import TimeslotField from 'ui/TimeslotField';



export const mapStateToProps = (state, ownProps) => {
  const packId = ownProps.match.params.packId;
  const isLoggedIn = state.user.isLoggedIn;

  const pack = state.packs.all[packId] || {};
  const childPacks = state.packs.byParent[packId] || {};


  let events = state.events.byPack[packId] || {};
  for(const childPackId in childPacks) {
    events = {...events, ...(state.events.byPack[childPackId] || {})};
  }

  let packTalents = state.packTalents.byPack[packId] || {};
  for(const childPackId in childPacks) {
    packTalents = {...packTalents, ...(state.packTalents.byPack[childPackId] || {})};
  }

  const talents = {};
  const scheduledTalents = {};
  const unscheduledTalents = {};
  const messageTo = {};

  // If the talent in packTalents is selected, add them to the `talents`.
  // And if there's no event for this talent, add them to `unscheduledTalents`.
  for(const id in packTalents) {
    if(packTalents[id].isSelected) {
      talents[id] = packTalents[id];
      if(!Object.keys(events).find(key => events[key].talentId === id)) {
        unscheduledTalents[id] = packTalents[id];
      }
      else {
        const talent = packTalents[id];
        scheduledTalents[id] = talent;
        messageTo[id] = {
          name: talent.contactName || `${talent.firstName} ${talent.lastName}` || "Unnamed Talent",
          talentId: talent.id,
        };
      }
    }
  }

  const agencyId = _.get(state, 'user.agencyId', null);
  const agency = _.get(state, `agencies.all[${agencyId}]`, null);


  const canMessage = _.get(state, 'user.permissions.canMessageTalents', false);

  const canSchedule = _.get(state, `packs.all[${packId}].schedule.editable`, true);

  let timeslots = _.get(state, `packs.all[${packId}].schedule.timeslots`, {});
  timeslots = Object.keys(timeslots).map(id => ({...timeslots[id], id}));

  return {
    packId,
    pack,
    timeslots,
    agency,
    events,
    talents,
    scheduledTalents,
    unscheduledTalents,
    messageTo,
    canMessage,
    canSchedule,
    isLoggedIn,
  };
};

export const mapDispatchToProps = {
  loadPack,
  loadPackTalents,
  subscribeToPackEvents,
  updateEvent,
  updatePackSchedule,
  addPackScheduleTimeslot,
  removePackScheduleTimeslot,
  subscribeToPackTalents,
  subscribeToChildPackTalents,
  subscribeToChildPackEvents,
};


export class PackSchedule extends Component {

  state = {
    isMessageTalentsDialogOpen: false,
    isEditing: false,
  }


  async componentDidMount() {
    if(!this.props.packId) {
      return;
    }
    this.props.loadPack(this.props.packId);

    this.unsubscribeFromPackTalents = await this.props.subscribeToPackTalents(this.props.packId);
    this.unsubscribeFromChildPackTalents = await this.props.subscribeToChildPackTalents(this.props.packId);

    this.unsubscribeFromPackEvents = this.props.subscribeToPackEvents(this.props.packId);
    this.unsubscribeFromChildPackEvents = await this.props.subscribeToChildPackEvents(this.props.packId);
  }

  componentWillUnmount() {
    if(_.isFunction(this.unsubscribeFromPackTalents)) {
      this.unsubscribeFromPackTalents();
    }
    if(_.isFunction(this.unsubscribeFromChildPackTalents)) {
      this.unsubscribeFromChildPackTalents();
    }
    if(_.isFunction(this.unsubscribeFromPackEvents)) {
      this.unsubscribeFromPackEvents();
    }
    if(_.isFunction(this.unsubscribeFromChildPackEvents)) {
      this.unsubscribeFromChildPackEvents();
    }
  }



  scheduleAll = () => {
  }

  openMessageTalentsDialog = () => this.setState({isMessageTalentsDialogOpen:true})
  closeMessageTalentsDialog = () => this.setState({isMessageTalentsDialogOpen:false})



  renderUnscheduledTalents() {
    // const talents = Object.keys(this.props.unscheduledTalents)
    //   .map(id => this.props.unscheduledTalents[id])
    //   .sort((a, b) => a.lastName > b.lastName ? 1 : -1);
    const talents = Object.keys(this.props.talents)
      .map(id => this.props.talents[id])
      .sort((a, b) => a.lastName > b.lastName ? 1 : -1);

    if(talents.length === 0) {
      return (
        <div className="packschedule-notalent">
          No Talent
        </div>
      );
    }

    return talents.map(talent =>
      <TalentCard
        view="small"
        className="packschedule-talent"
        key={talent.id}
        talentId={talent.id}
        packId={talent.packId || this.props.packId}
        talent={talent}
        tab="schedule"
      />
    );
  }

  renderForm() {
    const packId = this.props.packId;
    const schedule = this.props.pack.schedule || {};
    const timeslots = schedule.timeslots || {};
    const updatePackSchedule = this.props.updatePackSchedule;
    const removePackScheduleTimeslot = this.props.removePackScheduleTimeslot;
    const addPackScheduleTimeslot = this.props.addPackScheduleTimeslot;

    if(!this.props.canSchedule) {
      return (
        <Placeholder
          icon="schedule"
          message="A schedule hasn't been created."
        />
      );
    }

    return (
      <Page pad={true} style={{margin:'0 auto'}}>
        <Field label="Location">
          <AddressField
            value={schedule}
            onChange={address => updatePackSchedule(packId, address)}
            showFloatingLabels={false}
          />
        </Field>

        <Field label="Duration">
          <SelectField
            value={schedule.interval || 60}
            onChange={(e, i, interval) => updatePackSchedule(packId, {interval})}>
            <MenuItem value={10} primaryText="10 minutes"/>
            <MenuItem value={15} primaryText="15 minutes"/>
            <MenuItem value={30} primaryText="30 minutes"/>
            <MenuItem value={60} primaryText="1 hour"/>
            <MenuItem value={120} primaryText="2 hours"/>
          </SelectField>
        </Field>

        <Field label="Timeslots">
          {Object.keys(timeslots)
            .map(id =>
              <TimeslotField
                key={id}
                value={timeslots[id]}
                types={this.props.agency.eventTypes}
                onChange={(e, val) =>
                  updatePackSchedule(
                    packId,
                    {timeslots:{...timeslots, [id]:val}}
                  )
                }
                onRemove={() => removePackScheduleTimeslot(packId, id)}
              />
            )
          }
          <Button
            raised={true}
            label="Add Timeslot"
            onClick={() => addPackScheduleTimeslot(packId)}
            style={{marginTop:8}}
          />
        </Field>

        <Button
          raised={true}
          primary={true}
          label="Done"
          onClick={() => this.setState({isEditing:false})}
        />
      </Page>
    );
  }

  renderTimeslots() {
    const {
      packId,
      pack,
      events,
      talents,
      timeslots,
      canSchedule,
    } = this.props;

    if(!timeslots && !canSchedule) {
      return (
        <Placeholder
          key="cantschedule"
          icon="schedule"
          message={<span>A schedule hasn't been created.<br/>Please contact the package owner to create a schedule.</span>}
        />
      );
    }

    if(!timeslots || timeslots.length === 0) {
      return (
        <Placeholder
          key="noschedule"
          icon="schedule"
          message="A schedule hasn't been created."
          label="Create Schedule"
          onClick={() => this.setState({isEditing:true})}
        />
      );
    }

    const interval = pack.schedule.interval || 60;

    return timeslots.map(timeslot => {
      let { from, to, type } = timeslot;

      from = moment(from);
      to = moment(to);

      const markers = [];
      let markFrom = from.valueOf();
      let markTo = to.valueOf();
      let d = markFrom;
      while(d < markTo) {
        markers.push(moment(d));
        d += interval*60*1000;
      }

      return (
        <div key={timeslot.id} className="packtimeslots-timeslot">
          <div className="packtimeslots-timeslot-heading">
            <div className="packteimslots-timeslot-heading-type">{type}</div>
            <div className="packtimeslots-timeslot-heading-day">
              {from.format('ddd, D MMM')}
            </div>
            <div className="packtimeslots-timeslot-heading-time">
              {from.format('h:mma')} - {to.format('h:mma')}
            </div>
          </div>

          {markers.map(marker => (
            <div key={marker.valueOf()} className="packtimeslots-timeslot-slot">
              <div className="packtimeslots-timeslot-time">
                <div className="packtimeslots-timeslot-time-hour">{marker.format('h')}</div>
                <div className="packtimeslots-timeslot-time-minutes">{marker.format('mm')}</div>
                <div className="packtimeslots-timeslot-time-a">{marker.format('a')}</div>
              </div>

              <div className="packtimeslots-timeslot-events">

                {Object.keys(events)
                  .map(key => events[key])
                  .filter(event => event.date === marker.valueOf())
                  .filter(event => !!talents[event.talentId])
                  .map(event => (
                    <div key={event.id} style={{marginBottom:4}}>
                      <TalentCard
                        packId={packId}
                        view="small"
                        talent={talents[event.talentId]}
                        className="schedule-list-item"
                        tab="schedule"
                        event={event}
                        onEventClick={() => {
                          this.props.updateEvent(event.id, {
                            status: event.status === 'confirmed' ? 'declined' :
                                    event.status === 'declined' ? 'pending' :
                                    'confirmed'
                          });
                        }}
                      />
                    </div>
                  ))
                }

                <div className="packtimeslots-timeslot-empty"></div>
              </div>
            </div>
          ))}

        </div>
      );
    });
  }

  renderActions() {
    const actions = [];
    if(this.props.canMessage) {
      actions.push(
        <Action
          key="message"
          tooltip="Message Scheduled Talent"
          icon="multimessage"
          onClick={this.openMessageTalentsDialog}
        />
      );
    }
    if(this.props.canSchedule && !this.state.isEditing) {
      actions.push(
        <Action
          key="edit"
          tooltip="Edit Schedule"
          icon="edit"
          onClick={() => this.setState({isEditing:true})}
        />
      );
    }
    if(this.props.canSchedule && this.state.isEditing) {
      actions.push(
        <Action
          key="done"
          tooltip="Done Editing"
          icon="close"
          onClick={() => this.setState({isEditing:false})}
        />
      );
    }
    return actions;
  }

  renderContent() {
    if(this.state.isEditing) {
      return this.renderForm();
    }

    return [
      <div
        key="talents"
        className="packschedule-talents"
        style={{
          top: this.props.isLoggedIn ? 60 : 0
        }}>
        {this.renderUnscheduledTalents()}
      </div>,
      <div key="timeslots" className="packschedule-timeslots">
        {this.renderTimeslots()}
      </div>
    ];
  }

  render() {
    return (
      <Container>
        <Title
          title="Schedule"
          actions={this.renderActions()}
          style={{marginBottom:0}}
        />

        <div className="packschedule-wrapper">
          {this.renderContent()}
        </div>

        <MessageScheduledDialog
          to={this.props.messageTo}
          pack={this.props.pack}
          open={this.state.isMessageTalentsDialogOpen}
          onSend={this.closeMessageTalentsDialog}
          onRequestClose={this.closeMessageTalentsDialog}
        />

      </Container>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(PackSchedule);