import React, { useState, useContext } from 'react';
import { useQuery, updatePackTalent, sendMessage } from 'skybolt-api';
import { LazyLoad, Button, Card, Loading, useRouter, Modal, Text, ThemeContext, Icon, InputHTML } from 'skybolt-ui';
import { get } from 'lodash';
import imagePlaceholder from './assets/image-placeholder.png';
import videoPlaceholder from './assets/video-placeholder.png';

export default function(props) {
  const route = useRouter();
  const history = route.history;
  const packId = get(route, 'match.params.packId', null);
  const [ modal, setModal ] = useState({open:false});

  const [limit, setLimit] = useState(40);

  const data = useQuery(`{
    user {
      permissions {
        canApproveMedia
      }
    }
    pack(packId:$packId) {
      mediaRequest
      agency {
        requireTalentMediaApproval
      }
    }
    talents(packId:$packId, limit:$limit) {
      talentId
      name: fullName
      mediaRequest {
        thumbnail: image(width:300, height:300)
        image: image(width:768)
        video
        approved
      }
      isSelected
    }
  }`, {packId, limit});

  function openTalent(talent) {
    history.push({
      pathname:`/talents/${talent.talentId}/info`,
      state:{modal:true, packId:packId}
    });
  }

  function openModal(talent) {
    setModal({open:true, talent});
  }

  function closeModal() {
    setModal({open:false});
  }

  async function approve(talent) {
    updatePackTalent({packId, talentId:talent.talentId, change:{'mediaRequest.approved':true}});
  }

  async function reject(talent) {
    const form = (
      <div>
        <Text paragraph large>Reject Media</Text>
        <InputHTML title="Message Talent" name="message" hint="Leave blank to not send message."/>
      </div>
    );
    const res = await Modal.form(form, {yes:"Reject", no:"Cancel"});
    if(!res) {
      return;
    }

    if(res.message) {
      await sendMessage({
        subject: "Skybolt Media",
        talentId: talent.talentId,
        message: res.message,
      });
    }

    updatePackTalent({packId, talentId:talent.talentId, change:{'mediaRequest':null}});
  }

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  if(!data.pack.mediaRequest) {
    return (
      <div style={{paddingBottom:40, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
        <Icon name="camera-alt" duotone size={200} color={theme.color.greyLight}/>
        <Text block style={{marginTop:-150, zIndex:1, textAlign:'center'}}>
          Additional images or video specific to your casting may be requested.<br/>
          Please contact the agent to make a media request and they will appear here.
        </Text>
      </div>
    );
  }

  const talents = data.talents
    .filter(talent => talent.isSelected)
    .map(talent => {

      let t = {
        talentId: talent.talentId,
        name: talent.name,
      };

      // Require approval if the talent
      // - does have media, and
      // - that media hasn't been approved/rejected yet, and
      // - the agency requires approval, and
      // - the user has permissios to approve media.
      if(talent.mediaRequest.thumbnail &&
         !talent.mediaRequest.approved &&
         data.pack.agency.requireTalentMediaApproval &&
         data.user.permissions.canApproveMedia
      ) {
        t.requiresApproval = true;
      }

      // If the talent
      // - has a thumbnail and
      // - it's been approved, or needs approval
      if(talent.mediaRequest.thumbnail && (talent.mediaRequest.approved || t.requiresApproval)) {
        t.thumbnail = talent.mediaRequest.thumbnail;
        t.image = talent.mediaRequest.image;
        t.video = talent.mediaRequest.video;
      } else {
        t.thumbnail = data.pack.mediaRequest.type === 'image' ? imagePlaceholder : videoPlaceholder;
      }

      return t;
    });

  return (
    <div style={{paddingBottom:40}}>
      <div style={{display:'grid', gridTemplateColumns:'repeat(auto-fill, minmax(200px, 300px))', gridGap:10}}>
        {talents.map(talent =>
          <Card depth={1} key={talent.talentId}>
            <Button onClick={() => openModal(talent)} style={{display:'block', padding:0, lineHeight:0, width:'100%'}}>
              <img src={talent.thumbnail} alt={talent.name} style={{width:'100%'}}/>
            </Button>
            <Button onClick={() => openTalent(talent)} style={{display:'block', textAlign:'center'}}>
              <Text large block>{talent.name}</Text>
              <Text block>{talent.info}</Text>
            </Button>
            {talent.requiresApproval &&
              <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:8}}>
                <Button icon="thumbs-up" label="Approve" onClick={() => approve(talent)}/>
                <Button icon="thumbs-down" label="Reject" onClick={() => reject(talent)}/>
              </div>
            }
          </Card>
        )}
      </div>

      <LazyLoad onLoad={() => setLimit(limit+40)}/>
      <Modal status={modal.open} onRequestClose={closeModal}>
        {modal.talent && (
          data.pack.mediaRequest.type === 'image' && modal.talent.image ? (
            <div style={{padding:16}}>
              <img src={modal.talent.image} style={{width:'100%'}} alt={modal.talent.name}/>
            </div>
          ) :
          data.pack.mediaRequest.type === 'video' && modal.talent.video ? (
            <div style={{padding:"32px 16px"}}>
              <video controls width="100%">
                <source src={modal.talent.video} type="video/mp4"/>
              </video>
            </div>
          ) :
          (
            <div style={{padding:"32px 16px"}}>
              <Text>{modal.talent.name} has not uploaded media.</Text>
            </div>
          )
        )}
      </Modal>
    </div>
  );
}