import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { 
  movePackTalentMediaUp,
  movePackTalentMediaDown,
  removePackTalentMedia,
  movePackTalentMediaToTop,
  movePackTalentMediaToBottom,
} from 'actions';

import Media from 'ui/Media';
import Button from 'ui/Button';
import {
  SortableHandle,
} from 'react-sortable-hoc';
import Icon from 'ui/Icon';

const DragHandle = SortableHandle(() =>
  <div 
    className="talent-media-item-action"
    style={{
      backgroundColor: 'white',
    }}>
    <Icon name="dragHand" size={12}/>
  </div>
);

export const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  movePackTalentMediaUp,
  movePackTalentMediaDown,
  removePackTalentMedia,
  movePackTalentMediaToTop,
  movePackTalentMediaToBottom,
};





export class PackMediaItem extends Component {
  
  moveUp = () => {
    const media = this.props.media;
    const packId = this.props.packId;
    const talentId = this.props.talentId;
    
    if(!media || !media.id) {
      return;
    }
    
    if(!talentId) {
      return;
    }
    
    if(!packId) {
      return;
    }
    
    this.props.movePackTalentMediaUp(packId, talentId, media.id);
  }
  
  moveDown = () => {
    const media = this.props.media;
    const packId = this.props.packId;
    const talentId = this.props.talentId;
    
    if(!media || !media.id) {
      return;
    }
    
    if(!talentId) {
      return;
    }
    
    if(!packId) {
      return;
    }
    
    this.props.movePackTalentMediaDown(packId, talentId, media.id);
  }
  
  moveToTop = () => {
    const media = this.props.media;
    const packId = this.props.packId;
    const talentId = this.props.talentId;
    
    if(!media || !media.id) {
      return;
    }
    
    if(!talentId) {
      return;
    }
    
    if(!packId) {
      return;
    }
    
    this.props.movePackTalentMediaToTop(packId, talentId, media.id);
  }
  
  moveToBottom = () => {
    const media = this.props.media;
    const packId = this.props.packId;
    const talentId = this.props.talentId;
    
    if(!media || !media.id) {
      return;
    }
    
    if(!talentId) {
      return;
    }
    
    if(!packId) {
      return;
    }
    
    this.props.movePackTalentMediaToBottom(packId, talentId, media.id);
  }
  
  remove = () => {
      const media = this.props.media;
    const packId = this.props.packId;
    const talentId = this.props.talentId;
    
    if(!media || !media.id) {
      return;
    }
    
    if(!talentId) {
      return;
    }
    
    if(!packId) {
      return;
    }
    
    this.props.removePackTalentMedia(packId, talentId, media.id);
  }
  
  
  render() {
    
    const {
      title,
      date,
    } = this.props.media;
    
    return (
      <div className="talent-media-item">
        
        <div className="talent-media-item-image">
          <Media 
            className="talent-media-item-image-media"
            icon="media"
            media={this.props.media} 
            width={200}
            crop="scale"
          />
        </div>
        
        <div className="talent-media-item-title">
          {title}
        </div>
        <div className="talent-media-item-date">
          {moment(date).format('MMM Do, YYYY')} {this.props.media.order}
        </div>
        
        
        
        <div className="talent-media-item-actions">
          
          {/*
          <div 
            className="talent-media-item-action"
            style={{backgroundColor:'white'}}>
            <Button 
              icon="moveToTop" 
              onClick={this.moveToTop}
            />
          </div>
          <div 
            className="talent-media-item-action"
            style={{backgroundColor:'white'}}>
            <Button 
              icon="up" 
              onClick={this.moveUp}
            />
          </div>
          <div 
            className="talent-media-item-action"
            style={{backgroundColor:'white'}}>
            <Button 
              icon="down" 
              onClick={this.moveDown}
            />
          </div>
          <div 
            className="talent-media-item-action"
            style={{backgroundColor:'white'}}>
            <Button 
              icon="moveToBottom" 
              onClick={this.moveToBottom}
            />
          </div>
          */}
          
          <div 
            className="talent-media-item-action"
            style={{backgroundColor:'white'}}>
            <Button 
              icon="remove" 
              onClick={this.remove}
            />
          </div>
          
          <DragHandle/>
          
        </div>
        
        
      </div>
    );
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(muiThemeable()(PackMediaItem));