import React, { useEffect, useState } from 'react';
import { Text, Icon, Button, useRouter } from 'skybolt-ui'
import { createPack } from 'skybolt-api';

export default function(props) {

  const {history} = useRouter();

  const [error, setError] = useState(null);

  useEffect(() => {
    let isCanceled = false;
    let timer;
    createPack()
      .then(({packId}) => {
        if(isCanceled) {
          return;
        }
        timer = setTimeout(() => history.replace('/packs/'+packId), 2000);
      })
      .catch(err => {
        console.error(err);
        setError("Could not create package. Please try again.");
      });
    return () => {
      isCanceled = true;
      if(timer) clearTimeout(timer);
    };
  }, []);

  if(error) {
    return (
      <div style={{padding:"160px 16px 0", textAlign:'center'}}>
        <Icon name="exclamation-triangle" color="error" large/>
        <Text error paragraph>{error}</Text>
        <Button href="/packs" raised>Back to Packages</Button>
      </div>
    );
  }

  return (
    <div style={{padding:"160px 16px 0", textAlign:'center'}}>
      <Icon name="loading" color="textLight" large/>
      <Text block light>Creating Pack...</Text>
    </div>
  );
}