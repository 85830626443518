import React from 'react';
import moment from 'moment';
import muiThemeable from 'material-ui/styles/muiThemeable';

import './index.css';


export default muiThemeable()(props => {

  const statusColors = (status) => {
    if(status === 'processor_declined') {
      return 'red';
    }
    return props.muiTheme.palette.primary1Color;
  };
  
  return (
    <div className="talent-settings-transaction">
      <div className="talent-settings-transaction-date">
        {moment(props.transaction.date).format('MM/DD/YYYY')}
      </div>
      <div className="talent-settings-transaction-type">
        {props.transaction.description}
      </div>
      <div className="talent-settings-transaction-amount">
        ${parseFloat(props.transaction.amount).toFixed(2)}
      </div>
      <div className="talent-settings-transaction-status" style={{color:statusColors(props.transaction.status)}}>
        {props.transaction.status}
      </div>
    </div>
  );
  
});