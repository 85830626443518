import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replyToPack } from 'actions';

import './index.css';

import Dialog from 'ui/Dialog';
import MessageComposer from 'ui/MessageComposer';
import TextField from 'material-ui/TextField';


export const mapStateToProps = (state, ownProps) => {
  const pack = ownProps.pack;

  return {
    user: state.user,
    packId: pack.id,
    packName: pack.name,
    threadId: pack.threadId,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  replyToPack,
};



export class ReplyDialog extends Component {
  
  state = {
    requireEmail: true,
    name: "",
    email: "",
    emailError: "",
  }
  
  componentDidMount() {
    const user = this.props.user || {};
    const name = `${user.firstName || ""} ${user.lastName || ""}`.trim();
    const email = user.email || "";
    
    if(name && email) {
      this.setState({
        requireEmail: false,
        name, 
        email
      });
    }
  }
  
  send = message => {
    
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!re.test(this.state.email)) {
      this.setState({emailError:`"${this.state.email}" is not a valid email address.`});
      return;
    }
    
    message = {
      from: {
        name: this.state.name,
        email: this.state.email,
      },
      ...message,
    };
    
    this.props.replyToPack(this.props.packId, message);
    
    // const threadId = this.props.threadId;
    // if(!threadId) {
    //   return;
    // }
    
    // const packUrl = `https://${window.location.hostname}/packs/${this.props.packId}`;
    // const packName = this.props.packName;
    // const userName = `${this.props.user.firstName || ""} ${this.props.user.lastName || ""}`.trim();
    
    // message.subject = packName;
    
    // message.body = `
    //   <div>
    //     ${message.body}
    //     <div style="border-top:1px solid #eeeeee; margin-top:20px; padding-top:8px; font-size:10px;">
    //       ${userName ? `${userName}<br/>` : ""}
    //       In reply to ${packName}.<br/>
    //       <a href="${packUrl}" target="_blank" style="text-decoration:none;">${packUrl}</a>
    //     </div>
    //   </div>
    // `;
   
    // Note: This adds the user as a recipient on all messages. It's likely they're
    // already on the message, so they'll start getting duplicates.
    
    // We could check outgoing messages for email addresses we recognize,
    // We would create an edge case if a casting director signs up after recieving a package, then responds. 
    
    // if(this.props.user) {
    //   message.from = {
    //     userId: this.props.user.id,
    //     name: `${this.props.user.firstName || ""} ${this.props.user.lastName || ""}`.trim(),
    //   };
    // }
    
    // this.props.addMessage(threadId, message);
    this.props.onRequestClose(); 
  }
  
  render() {
    
    return (
      <Dialog 
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
        bodyStyle={{backgroundColor:"#fafafa"}}>
        
        <MessageComposer
          onCancel={this.props.onRequestClose}
          onSend={this.send}
          title="Reply to Agent"
          above={this.state.requireEmail ? (
            <div>
              <div className="pack-replydialog-input">
                <TextField
                  fullWidth
                  hintText="Your Name"
                  name="name"
                  value={this.state.name}
                  onChange={(e, name) => this.setState({name})}
                  underlineShow={false}
                />
              </div>
              <div className="pack-replydialog-input">
                <TextField
                  fullWidth
                  hintText="Your Email Address"
                  name="name"
                  value={this.state.email}
                  onChange={(e, email) => this.setState({email, emailError:""})}
                  underlineShow={false}
                />
                {this.state.emailError && 
                  <div style={{fontSize:12, paddingBottom:4, color:'red'}}>{this.state.emailError}</div>
                }
              </div>
            </div>
          ) : null}
          showToField={false}
          showSubject={false}
          showCannedMessages={false}
          showBCCSelf={false}
          showSMS={false}
        />
        
      </Dialog>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(ReplyDialog);