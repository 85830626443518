import React, { Component } from 'react';
import { connect } from 'react-redux';
import { searchUsers } from 'actions';
import { debounce } from 'lodash';

import AutoComplete from 'material-ui/AutoComplete';

export const mapStateToProps = (state, ownProps) => {
  const users = Object.keys(state.users.search || {})
    .map(id => {
      const user = state.users.search[id];
      return {
        value: id,
        text: `${user.firstName || ""} ${user.lastName || ""} (${user.email})`,
        name: `${user.firstName || ""} ${user.lastName || ""}`
      };
    })
    .slice(0, 5);
  
  return {
    users,
  };
};

export const mapDispatchToProps = {
  searchUsers,
};





export class UserSearchField extends Component {
  
  static defaultProps = {
    filters: {},
    onChange: ()=>null,
    hint: "Search Users"
  }
  
  state = {
    query: "",
  }
  
  search = debounce(() => {
    const query = this.state.query;
    if(query.length < 3) {
      return;
    }
    this.props.searchUsers(query, this.props.filters);
  }, 300)
  
  query = query => {
    this.setState({query}, this.search);
  }
  
  select = val => {
    this.props.onChange({
      userId: val.value,
      userName: val.name,
    });
  }
  
  render() {
    
    const {
      hint,
      users,
      style,
      fullWidth,
      name,
    } = this.props;
    
    return (
      <AutoComplete
        name={name}
        hintText={hint}
        searchText={this.state.query}
        onUpdateInput={this.query}
        onNewRequest={this.select}
        dataSource={users}
        openOnFocus={true}
        filter={() => true}
        style={style}
        fullWidth={fullWidth}
      />
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(UserSearchField);