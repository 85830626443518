import { combineReducers } from "redux";

const byPack = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_PACK_TALENTS_SUCCESS':
      return {
        ...state,
        [action.packId]: {...state[action.packId], ...action.talents},
      };
    
    case 'ADD_PACK_TALENT':
    case 'UPDATE_PACK_TALENT':
      return {
        ...state,
        [action.packId]: {
          ...state[action.packId],
          [action.talentId]: action.talent,
        },
      };
      
    case 'REMOVE_PACK_TALENT':
      let {
        [action.talentId]:deletedTalent, 
        ...talents
      } = state[action.packId];
      
      return {
        ...state,
        [action.packId]: talents,
      };
      
    case 'SELECT_PACK_TALENT':
      return {
        ...state,
        [action.packId]: {
          ...state[action.packId],
          [action.talentId]: {
            ...state[action.packId][action.talentId],
            isSelected: true,
          },
        },
      };
      
    case 'DESELECT_PACK_TALENT':
      return {
        ...state,
        [action.packId]: {
          ...state[action.packId],
          [action.talentId]: {
            ...state[action.packId][action.talentId],
            isSelected: false,
          },
        },
      };
    
    case 'LOGOUT':
      return {};
      
    default: 
      return state;
  }
};






export default combineReducers({
  byPack,
});