import React from 'react';
import { Switch, Route, Redirect } from 'skybolt-ui';
import List from './List';
import Create from './Create';
import Client from './Client';

export default function Clients(props) {
  const {match:{ url, path }} = props;
  return (
    <Switch>
      <Route path={`${path}/list`} component={List}/>
      <Route path={`${path}/create`} component={Create}/>
      <Route path={`${path}/:clientId`} component={Client}/>
      <Redirect to={`${url}/list`}/>
    </Switch>
  );
}