import React from 'react';
import moment from 'moment';

export default ({date}) => (
  <div className="threadslist-date">
    {moment(date).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'dddd, MMMM Do YYYY'
    })}
  </div>
);