import React, { useState } from 'react';
import {
  Container,
  Text,
  Rule,
  Form,
  InputText,
  Button,
  Icon,
  TalentSearch,
  Card,
  TalentGrid,
  LazyLoad,
  useRouter,
} from 'skybolt-ui';

import {
  useQuery,
} from 'skybolt-api';


export default function SpotlightTalent(props) {

  const {
    history,
  } = useRouter();


  // Talent Search

  const [search, setSearch] = useState({filters:"isSeekingAgency:true"});
  const [limit, setLimit] = useState(40);

  const query = `{
    agency {
      talentFields
    }
    talents: adminTalents(search:$search, limit:$limit) {
      talentId
      status
      image(width:130, height:130, face:true)
      name: fullName
      info
      contact
      notes
      tags
      meta
      dateUpdated
      code
    }
  }`;
  const queryVars = {search, limit};
  const data = useQuery(query, queryVars);


  // Advanced Toggle

  const [advanced, _setAdvanced] = useState(false);
  function toggleAdvanced() {
    const val = !advanced;
    if(!val) {
      setSearch({});
    }
    _setAdvanced(val);
  }


  // DOM

  if(!data) {
    return (
      <Icon name="loading" size={24} style={{display:"block", margin:"80px auto"}}/>
    );
  }


  // Map talent data with talentFields to create objects consumable by
  // the talent grid.

  return (
    <Container>
      <Form
        style={{display:'flex', alignItems:'flex-end', height:46}}
        value={search}
        onChange={val => setSearch(val)}
      >
        <Text title>TALENT</Text>
        <div style={{flex:1}}/>
        <InputText
          name="query"
          placeholder="Search..."
          clearable
          style={{margin:0, height:38, backgroundColor:'transparent'}}
        />
        <Button
          style={{
            background:"#cccccc",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width:40,
            height:40,
            padding: 0,
            borderRadius: 0,
          }}
          onClick={toggleAdvanced}>
          <Icon name={advanced ? "close" : "tune"} color="white" size={24}/>
        </Button>
      </Form>

      <Rule style={{marginBottom:32}}/>

      {advanced &&
        <Card style={{padding:16, marginBottom:32}}>
          <TalentSearch
            fields={data.agency.talentFields}
            value={search}
            onChange={value => setSearch(value)}
          />
        </Card>
      }

      <TalentGrid
        talents={data.talents}
        onClick={(tab, talentId) => {
          history.push({
            pathname:`/talents/${talentId}/${tab}`,
            state:{
              modal:true
            }
          });
        }}
      />

      <LazyLoad onLoad={() => {
        setLimit(limit+40);
      }}/>

    </Container>
  );
}