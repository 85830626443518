import React, { Component } from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Popover, {Menu, MenuItem} from 'ui/Popover';
import Tabs from 'ui/Tabs';
import Icon from 'ui/Icon';



class Header extends Component {
  
  static defaultProps = {
    tabs: [],
    actions: [],
  }
  
  state = {
    isPopoverOpen: false,
    anchorEl: null,
  }
  
  
  showPopover = e => this.setState({isPopoverOpen:true, anchorEl:e.target})
  hidePopover = () => this.setState({isPopoverOpen:false})
  
  render() {
    const { image, title, actions, tabs } = this.props;
    
    let imageEl = null;
    if(image && React.isValidElement(image)) {
      imageEl = (
        <div className="header-image">
          {image}
        </div>
      );
    }
    
    let actionEls = null;
    if(actions.length > 0) {
      actionEls = React.Children.map(this.props.actions, (el, i) => {
        if(!React.isValidElement(el)) {
          return null;
        }
        
        if(el.props.visible === false) {
          return null;
        }
        
        return (
          <MenuItem 
            primaryText={el.props.label} 
            leftIcon={<Icon name={el.props.icon}/>}
            onClick={el.props.onClick}
          />
        );
      });
    }
    
    let tabEls = React.Children.map(tabs, (el, i) => {
      if(!React.isValidElement(el)) {
        return null;
      }
      return React.cloneElement(el, {key:i, size:24});
    });
    
    return (
      <div>
        
        <div className="header-content">
          
          {imageEl}
          
          <div 
            className="header-title" 
            onClick={this.showPopover}>
            
            <div className="header-title-text">{title}</div>
            <Icon name="caret" size={18}/>
            
          </div>
          
        </div>
        
        <Tabs className="header-tabs">
          {tabEls}
        </Tabs>
        
        
        <Popover
          open={this.state.isPopoverOpen}
          onRequestClose={this.hidePopover}
          anchorEl={this.state.anchorEl}
          >
          <Menu>
            {actionEls}
          </Menu>
        </Popover>
        
      </div>
    );
  }
}

export default muiThemeable()(Header);