import React from 'react';
import { Button } from 'skybolt-ui';

export default function() {
  return (
    <div style={{display:'flex', height:32}}>
      <div style={{flex:1}}></div>
      <Button href="/login" style={{backgroundColor:"#fafafa", color:"#aaa"}}>Login to Skybolt</Button>
    </div>
  );
}