/**

# Media

## Props


### cloudinary_id
Pulls media from cloudinary given the cloudinary id.

### src
Renders a simple <img/> with `src`.


Example

    <Media media={media}/>


TODO: connect to state and support props for `talentId`, `packId`, etc.


**/


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadMedia } from 'actions';
import { cloudinaryUrl } from 'skybolt-api';

import './vimeo.css';


import Loading from 'ui/Loading';
import Icon from 'ui/Icon';
import {Video as CloudinaryVideo} from 'cloudinary-react';
import YouTube from 'react-youtube';
import Vimeo from 'react-vimeo';


export const mapStateToProps = (state, ownProps) => {
  const media = ownProps.media || state.media.all[ownProps.mediaId] || {};

  return {
    /* deprecated */ cloudinaryId: ownProps.cloudinaryId,
    media,
    ...ownProps,
    angle: (!!media && media.angle) || ownProps.angle || 0,
  };
};


export const mapDispatchToProps = {
  loadMedia,
};




function youtubeId(url){
  var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[1].length === 11) ? match[1] : "";
}

function vimeoId(url){
  var regExp = /https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  var match = url.match(regExp);
  return (match && match[2]) ? match[2] : "";
}



export class Media extends Component {
  UNSAFE_componentWillMount() {
    if(!this.props.media && this.props.mediaId) {
      this.props.loadMedia(this.props.mediaId);
    }
  }

  render() {
    const {
      cloudinaryId,
      media,
      src,
      icon,
      iconSize="80%",
      className,
      backgroundColor="#fafafa",
      style={},
      width,
      height,
      face=false,
      angle,
      greyscale=false,
      // format='jpg',
      onClick,
    } = this.props;

    if(!!src) {
      return (
        <img
          src={src}
          alt=""
          className={className}
          style={style}
          onClick={onClick}
          width={width}
          height={height}
        />
      );
    }

    if(cloudinaryId) {
      const url = cloudinaryUrl(cloudinaryId, {width, height, face, greyscale, angle});
      return (
        <img
          src={url}
          alt=""
          className={className}
          style={style}
          onClick={onClick}
        />
      );
    }

    if(media && media.isLoading) {
      return (
        <Loading
          className={className}
          height={width * 0.75}
          onClick={onClick}
        />
      );
    }

    if(media && media.cloudinary_id) {
      if(media.type === 'video') {
        return (
          <CloudinaryVideo
            className={className}
            publicId={media.cloudinary_id}
            width={width}
            height={
              media.format === 'mp3' || media.format === 'wav'
                ? 38
                : height
            }
            controls={true}
            secure={true}
            style={style}
            videoCodec="auto"
          />
        );
      }
      else {
        const url = cloudinaryUrl(media.cloudinary_id, {width, height, face, greyscale, angle});
        return <img src={url} alt={media.title} className={className} style={style} onClick={onClick}/>;
      }
    }

    if(media && media.type === 'link' && media.format === 'youtube') {
      return (
        <YouTube
          className={className}
          videoId={youtubeId(media.url || media.youtube_url)}
          opts={{
            width: width,
            height: height || width * (.75),
          }}
        />
      );
    }

    if(media && media.type === 'link' && media.format === 'vimeo') {
      return (
        <div className={className}>
          <Vimeo videoId={vimeoId(media.url)}/>
        </div>
      );
    }

    if(icon) {
      return (
        <div
          className={className}
          onClick={onClick}
          style={{
            width,
            height,
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:backgroundColor,
            ...style
          }}>
          <Icon name={icon} style={{width:iconSize, height:iconSize, color:'#e6e6e6'}}/>
        </div>
      );
    }

    return <div style={{width, height, backgroundColor:'#e6e6e6'}} onClick={onClick}/>;
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Media);