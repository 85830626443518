import React, { Component } from 'react';
import { connect } from 'react-redux';
import { inviteToPack } from 'actions';
import { cloudinaryUrl } from 'skybolt-api';
import { get } from 'lodash';

import './index.css';

import Dialog from 'ui/Dialog';
import MessageComposer from 'ui/MessageComposer';


export const mapStateToProps = (state, ownProps) => {
  const pack = ownProps.pack;
  const packId = pack.id;
  const agency = state.agencies.all[pack.agencyId];
  
  let packUrl, packName, agencyName, logoUrl;
  
  packUrl = `https://${window.location.hostname}/packs/${pack.id}`;
  packName = pack.name;
  
  if(agency) {
    agencyName = agency.name;
    logoUrl = cloudinaryUrl(agency.logo.cloudinary_id, {width:160});
  }
  
  const isLoggedIn = get(state, 'user.isLoggedIn', false);
  
  return {
    isLoggedIn,
    packUrl,
    packName,
    packId,
    logoUrl,
    agencyName,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  inviteToPack,
};



export class PackShareDialog extends Component {
  
  send = message => {
    this.props.inviteToPack(this.props.pack.id, {
      recipients: message.to,
      bccSender: message.bccSender,
      ccSender: message.ccSender,
      subject: message.subject || this.props.pack.name,
      body: message.body,
      attachments: message.attachments,
    });
    
    this.props.onRequestClose(); 
  }
  
  render() {
    
    return (
      <Dialog 
        open={this.props.open}
        onRequestClose={this.props.onRequestClose}
        bodyStyle={{backgroundColor:"#fafafa"}}>
        
        <MessageComposer
          draftKey={`packshare-${this.props.packId}`}
          onCancel={this.props.onRequestClose}
          onSend={this.send}
          title="Share Package"
          showSubject={true}
          showCannedMessages={false}
          showBCCSelf={!!this.props.isLoggedIn}
          showSMS={false}
          html={`
            <div>
              ${this.props.logoUrl ? (`
                <div style="text-align:center; padding-top:16px; padding-bottom:16px">
                  <img src="${this.props.logoUrl}" alt="${this.props.agencyName}"/>
                </div>
              `) : (
                ""
              )}
              <div contenteditable="true" data-name="message"></div>
              <div style="padding:8px 0">
                Click here to view ${this.props.packName}<br/>
                <a href="${this.props.packUrl}" target="_blank" style="text-decoration:none;">${this.props.packUrl}</a>
              </div>
            </div>
          `}
        />
        
      </Dialog>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(PackShareDialog);