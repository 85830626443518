import { combineReducers } from "redux";


const all = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_AGENCY_PACKS_SUCCESS':
    case 'LOAD_PACKS':
      return Object.assign({}, state, action.packs);

    case 'LOAD_PACK_REQUEST':
      return {
        ...state,
        [action.packId]: {
          ...state[action.packId],
          isLoading: true,
        }
      };

    case 'LOAD_PACK_SUCCESS':
    case 'ADD_PACK':
    case 'UPDATE_PACK':
    case 'ADD_PACK_TALENT':
    case 'REMOVE_PACK_TALENT':
    case 'SELECT_PACK_TALENT':
    case 'DESELECT_PACK_TALENT':
      const id = action.packId || action.pack.id;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...action.pack,
          isLoading: false,
        },
      };

    case 'LOGOUT':
    case 'SET_AGENCY':
      return {};

    default:
      return state;
  }
};

const recent = (state={}, action) => {
  let packs, recent;
  switch(action.type) {
    case 'LOAD_RECENT_PACKS':
      return action.packs;

    case 'ADD_PACK':
    case 'UPDATE_PACK':
    case 'ADD_PACK_TALENT':
    case 'REMOVE_PACK_TALENT':
    case 'SELECT_PACK_TALENT':
    case 'DESELECT_PACK_TALENT':
      const update = action.pack || {};
      const packId = action.pack.id || action.packId;
      if(!update || !packId) {
        return state;
      }
      const existing = state ? state[packId] : {};
      packs = {
        ...state,
        [packId]: {
          ...existing,
          ...update
        }
      };
      recent = {};
      Object.keys(packs)
        .map(id => packs[id])
        .sort((a, b) => a.dateUpdated < b.dateUpdated ? -1 : 1)
        .slice(0, 3)
        .forEach(pack => recent[pack.id] = pack);
      return recent;

    case 'SET_AGENCY':
      return {};

    case 'LOGOUT':
      return null;

    default:
      return state;
  }
};

const byUser = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_PACKS':
      return action.packs;

    case 'LOAD_PACK_REQUEST':
      return {
        ...state,
        [action.packId]: {
          ...state[action.packId],
          isLoading: true,
        }
      };

    case 'LOAD_PACK_SUCCESS':
    case 'ADD_PACK':
    case 'UPDATE_PACK':
    case 'ADD_PACK_TALENT':
    case 'REMOVE_PACK_TALENT':
    case 'SELECT_PACK_TALENT':
    case 'DESELECT_PACK_TALENT':
      // don't include child packs.
      if(action.pack.parentPackId) {
        return state;
      }
      const id = action.packId || action.pack.id;
      return {
        ...state,
        [id]: {...state[id], ...action.pack},
      };

    case 'LOGOUT':
    case 'SET_AGENCY':
      return {};

    default:
      return state;
  }
};

const byAgency = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_AGENCY_PACKS_SUCCESS':
      if(action.agencyId) {
        return {
          ...state,
          [action.agencyId]: action.packs,
        };
      }
      return state;

    case 'LOAD_PACK_SUCCESS':
    case 'ADD_PACK':
    case 'UPDATE_PACK':
    case 'ADD_PACK_TALENT':
      return {
        ...state,
        [action.pack.agencyId]: {
          ...state[action.pack.agencyId],
          [action.pack.id]: {
            ...state[action.pack.id],
            ...action.pack
          },
        }
      };

    case 'LOGOUT':
    case 'SET_AGENCY':
      return {};

    default:
      return state;
  }
};

const byTalent = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_TALENT_PACKS_SUCCESS':
      if(!action.talentId) {
        return state;
      }
      return {
        ...state,
        [action.talentId]: action.packs,
      };

    // case 'ADD_PACK_TALENT':
    //   return {
    //     ...state,
    //     [action.talentId]: {
    //       ...state[action.talentId],
    //       [action.packId]: action.pack,
    //     }
    //   };

    // case 'REMOVE_PACK_TALENT':
    //   const {[action.packId]:removed, ...talentPacks} = state[action.talentId];
    //   return {
    //     ...state,
    //     [action.talentId]: talentPacks,
    //   };

    case 'LOGOUT':
      return {};

    default:
      return state;
  }
};

const byParent = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_PACK_SUCCESS':
      if(!action.pack.parentPackId) {
        return state;
      }
      return {
        ...state,
        [action.pack.parentPackId]: {
          [action.pack.id]: action.pack,
        },
      };

    case 'LOGOUT':
    case 'SET_AGENCY':
      return {};

    default:
      return state;
  }
};

const isLoading = (state=false, action) => {
  switch(action.type) {
    case 'LOAD_PACKS_REQUEST':
    case 'LOAD_RECENT_PACKS_REQUEST':
    case 'LOAD_AGENCY_PACKS_REQUEST':
      return true;

    case 'LOGOUT':
    case 'LOAD_PACKS':
    case 'LOAD_RECENT_PACKS':
    case 'LOAD_AGENCY_PACKS_SUCCESS':
      return false;

    default:
      return state;
  }
};

const legacyByTalent = (state={}, action) => {
  switch(action.type) {
    case 'LOAD_LEGACY_TALENT_PACKS_REQUEST':
      return {
        ...state,
        [action.talentId]: {
          isLoading: true,
        },
      };

    case 'LOAD_LEGACY_TALENT_PACKS':
      return {
        ...state,
        [action.talentId]: action.packs
      };

    default:
      return state;
  }
};



export default combineReducers({
  isLoading,
  all,
  recent,
  byAgency,
  byTalent,
  byParent,
  byUser,
  legacyByTalent,
});