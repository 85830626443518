import { combineReducers } from "redux";

const all = (state={}, action) => {
  switch(action.type) {
    case 'CONTACT_REQUEST': 
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isLoading: true,
        },
      };
      
    case 'CONTACT': 
      return {
        ...state,
        [action.id]: {
          ...action.contact,
          isLoading: false,
        }
      };
      
    case 'REMOVE_CONTACT': 
      const { [action.id]:removedContact, ...rest } = state;
      return rest;
      
    case 'LOGOUT':
    case 'SET_AGENCY':
      return {};
    
    default: 
      return state;
  }
};


export default combineReducers({
  all,
});