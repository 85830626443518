import React from 'react';
import { Switch, Route, Redirect } from 'skybolt-ui';
import List from './List';
import Create from './Create';
import Credit from './Credit';

export default function AccountsReveivable(props) {
  const {match:{ url, path }} = props;
  return (
    <Switch>
      <Route path={`${path}/list`} component={List}/>
      <Route path={`${path}/create`} component={Create}/>
      <Route path={`${path}/:creditId`} component={Credit}/>
      <Redirect to={`${url}/list`}/>
    </Switch>
  );
}