import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';
import { Text, Rule, Button, Currency, InputDate, InputSelect, Container, Icon, Card, Loading, ThemeContext } from 'skybolt-ui';
import { useQuery, pollCredits } from 'skybolt-api';
import downloadCSV from './utils/downloadCSV.js';
import InputPackage from './components/InputPackage.js';

export default function AccountsReceivableList(props) {
  const { state={}, pathname } = props.location;
  const {
    type,
    // clientId,
    invoiceNumber,
    pack = {},
    dateFrom,
    dateTo,
    orderBy = "date",
    orderDirection,
    limit = 20,
  } = state;

  // Manage Data
  const data = useQuery(`{
    agency {
      timezone
    }
    credits(
      type: $type,
      invoiceNumber: $invoiceNumber,
      packId: $packId,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      orderBy: $orderBy,
      orderDirection: $orderDirection,
      limit: $limit,
    ) {
      creditId
      type
      date
      dateDeposit
      talentName
      talentImage
      payType
      useType
      packName
      packImage
      invoiceNumber
      amount
      commission
      debitSubtotal
      debitAgencyAmount
      debitAmount
    }
    agency {
      agencyId
      billingOrgId
      clients {
        value: clientId
        label: name
      }
    }
    user {
      permissions {
        canAgencyBilling
        canAgencyBillingReports
      }
    }
  }`, {
    type, 
    invoiceNumber, 
    packId:pack.packId,
    dateFrom, 
    dateTo, 
    orderBy, 
    orderDirection, 
    limit: !dateFrom ? limit : null,
  });

  let timezone = data?.agency?.timezone || moment.tz.guess();

  const setQuery = update => props.history.replace(pathname, {...state, ...update});
  
  function setOrderBy(val) {
    if(orderBy === val) {
      if(orderDirection === "desc") {
        setQuery({orderDirection:"asc"});
      }
      else {
        setQuery({orderDirection:"desc"});
      }
    }
    else {
      setQuery({orderBy:val, orderDirection:"desc"});
    }
  }

  function handleDownload() {
    downloadCSV(data.credits, {type, dateFrom, dateTo, orderBy, orderDirection, limit});
  }


  const [isSyncing, setSyncing] = useState(false);
  async function sync() {
    if(isSyncing) {
      return;
    }
    setSyncing(true);

    try {
      await pollCredits({agencyId:data.agency.agencyId});
    }
    catch(error) {
      console.log(error);
    }

    setSyncing(false);

  }

  
  
  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  return (
    <div style={{width:"100%"}}>
      <Container>
        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>ACCOUNTS RECEIVABLE</Text>
          <div style={{flex:1}}/>
          {data.user.permissions.canAgencyBillingReports &&
            <InputDate
              placeholder="From..."
              value={dateFrom}
              onChange={val => setQuery({dateFrom: moment(val).tz(timezone).startOf('day').valueOf()})}
              onRule={true}
              style={{width:120}}
              tailStyle={{paddingTop:18, paddingBottom:8}}
              clearable
            />
          }
          {data.user.permissions.canAgencyBillingReports &&
            <InputDate
              placeholder="To..."
              value={dateTo}
              onChange={val => setQuery({dateTo: moment(val).tz(timezone).endOf('day').valueOf()})}
              onRule={true}
              style={{width:120}}
              tailStyle={{paddingTop:18, paddingBottom:8}}
              clearable
            />
          }
          {/*<InputText
            onRule
            placeholder="Invoice Number..."
            clearable
            value={invoiceNumber}
            onChange={invoiceNumber => setQuery({invoiceNumber})}
          />*/}
          <InputSelect
            onRule
            placeholder="Type..."
            clearable="All"
            value={type}
            strict options={["INVOICE", "TIMECARD"]}
            onChange={type => setQuery({type})}
          />
          {/*<InputSelect
            onRule
            placeholder="Client..."
            clearable="All"
            value={clientId}
            strict options={data.agency.clients}
            onChange={clientId => setQuery({clientId})}
          />*/}
          <InputPackage 
            value={pack}
            placeholder="Package..."
            onChange={pack => setQuery({pack})}
          />
          <Button onRule href="create" icon="plus"/>
          {data.agency.billingOrgId &&
            <Button onRule icon="sync-alt" iconProps={{spin:isSyncing}} onClick={sync}/>
          }
          {data.user.permissions.canAgencyBillingReports &&
            <Button onRule icon="file-download" onClick={handleDownload}/>
          }

        </div>
        <Rule style={{marginBottom:24}}/>
      </Container>

      <div style={{width:'100%', overflowX:'auto'}}>

        <Container style={{minWidth:900, marginBottom:80}}>
          


          {/* TABLE HEADER */}

          {data.credits.length > 0 ? (
            <div style={{display:'flex', alignItems:'center', padding:"0 8px 8px 8px"}}>
              <Button onClick={() => setOrderBy('date')} block style={{flex:1, padding:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                <Text 
                  small
                  style={{lineHeight:'32px', display:'inline-block', marginRight:8}} 
                  color={orderBy === 'date' ? theme.color.accent : theme.color.textLighter}>
                  Date
                </Text>
                <Icon
                  color={orderBy === 'date' ? theme.color.accent : theme.color.textLighter}
                  name={orderBy === 'date' && orderDirection === 'asc' ? "caret-up" : "caret-down"}
                  small
                  style={{marginTop:-1}}
                />
              </Button>

              <Text block small light style={{flex:1}}>Talent</Text>

              <Button onClick={() => setOrderBy('payType')} block style={{flex:1, padding:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                <Text 
                  small
                  style={{lineHeight:'32px', display:'inline-block', marginRight:8}} 
                  color={orderBy === 'payType' ? theme.color.accent : theme.color.textLighter}>
                  Pay Type
                </Text>
                <Icon
                  color={orderBy === 'payType' ? theme.color.accent : theme.color.textLighter}
                  name={orderBy === 'payType' && orderDirection === 'asc' ? "caret-up" : "caret-down"}
                  small
                  style={{marginTop:-1}}
                />
              </Button>

              <Button onClick={() => setOrderBy('useType')} block style={{flex:1, padding:0, display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                <Text 
                  small
                  style={{lineHeight:'32px', display:'inline-block', marginRight:8}} 
                  color={orderBy === 'useType' ? theme.color.accent : theme.color.textLighter}>
                  Use Type
                </Text>
                <Icon
                  color={orderBy === 'useType' ? theme.color.accent : theme.color.textLighter}
                  name={orderBy === 'useType' && orderDirection === 'asc' ? "caret-up" : "caret-down"}
                  small
                  style={{marginTop:-1}}
                />
              </Button>

              <Text block small light style={{flex:1}}>Package</Text>
              <Text block small light style={{flex:1}}>Invoice Number</Text>
              <Text block small light style={{flex:1, textAlign:'right'}}>Total</Text>
              <Text block small light style={{flex:1, textAlign:'right'}}>Sub Net</Text>
              <Text block small light style={{flex:1, textAlign:'right'}}>Owed to Talent</Text>
              <Text block small light style={{flex:1, textAlign:'right'}}>Total Commission</Text>
            </div>
          ) : (
            <div style={{textAlign:'center'}}>
              <div>
                <Icon name="money-check" size={80} color="accentLighter" style={{marginBottom:16, width:"auto"}}/>
              </div>
              <Button raised accent href="create" label="Input Payment"/>
            </div>
          )}



          {/* CREDITS */}

          {data.credits.map((credit, i) => (
            <div key={credit.creditId}>
              <Card
                key={credit.creditId}
                style={{
                  flex:1,
                  display:'flex',
                  alignItems:'center',
                  padding: '0 8px',
                  marginBottom:4,
                  cursor:'pointer',
                  height:40,
                }}
                onClick={() => props.history.push(credit.creditId)}>

                <Text block style={{flex:1}}>{moment(credit.date).tz(timezone).format("MM/DD/YYYY")}</Text>
                <div style={{flex:1}}>
                  <div style={{flex:1, display:'flex', alignItems:'center'}}>
                    {credit.talentImage &&
                      <img src={credit.talentImage} alt="headshot" style={{width:40, height:40, marginRight:8}}/>
                    }
                    <Text>{credit.talentName}</Text>
                  </div>
                </div>
                <Text block style={{flex:1}}>{credit.payType || ""}</Text>
                <Text block style={{flex:1}}>{credit.useType || ""}</Text>
                <div style={{flex:1}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <Text>{credit.packName}</Text>
                  </div>
                </div>
                {credit.type === 'INVOICE' ? (
                  <Text block style={{flex:1}}>{credit.invoiceNumber}</Text>
                ) : (
                  <Text block style={{flex:1}}></Text>
                )}
                <div style={{flex:1, textAlign:'right'}}>
                  <Currency>{credit.amount || 0}</Currency>
                </div>
                <div style={{flex:1, textAlign:'right'}}>
                  <Currency>{(credit.debitSubtotal || 0)}</Currency>
                </div>
                <div style={{flex:1, textAlign:'right'}}>
                  <Currency>{credit.debitAmount || 0}</Currency>
                </div>
                <div style={{flex:1, textAlign:'right'}}>
                  <Currency>{(credit.debitAgencyAmount || 0)}</Currency>
                </div>
              </Card>

              {/* 
                Subtotals
               */}
              {data.user.permissions.canAgencyBillingReports && (orderBy === 'payType' || orderBy === 'useType') && (!data.credits[i+1] || data.credits[i][orderBy] !== data.credits[i+1][orderBy]) && (
                <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-end', padding:8, marginBottom:40}}>
                  <div style={{flex:6}}/>
                  <div style={{flex:1, textAlign:'right'}}>
                    <Currency bold>{data.credits.filter(j => j[orderBy] === data.credits[i][orderBy]).reduce((t, c) => t + (Number(c.amount) || 0), 0)}</Currency>
                  </div>
                  <div style={{flex:1, textAlign:'right'}}>
                    <Currency bold>{data.credits.filter(j => j[orderBy] === data.credits[i][orderBy]).reduce((t, c) => t + (Number(c.debitSubtotal) || 0), 0)}</Currency>
                  </div>
                  <div style={{flex:1, textAlign:'right'}}>
                    <Currency bold>{data.credits.filter(j => j[orderBy] === data.credits[i][orderBy]).reduce((t, c) => t + (Number(c.debitAmount) || 0), 0)}</Currency>
                  </div>
                  <div style={{flex:1, textAlign:'right'}}>
                    <Currency bold>{data.credits.filter(j => j[orderBy] === data.credits[i][orderBy]).reduce((t, c) => t + (Number(c.debitAgencyAmount) || 0), 0)}</Currency>
                  </div>
                </div>
              )}

            </div>
          ))}

          {data.credits.length === limit &&
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', padding:16}}>
              <Button label="Load More" onClick={() => setQuery({limit:limit + 50})}/>
            </div>
          }


          {/*
          TOTALS
          Displayed if:
          1. We've exhaused a limited list, or
          2. we're showing a date range.
          */}
          {data.user.permissions.canAgencyBillingReports && (dateFrom || (data.credits.length > 0 && data.credits.length < limit)) &&
            <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-end', padding:8}}>
              <div style={{flex:6}}/>
              <div style={{flex:1, textAlign:'right'}}>
                <Rule style={{marginBottom:8}}/>
                <Currency large bold>{data.credits.reduce((t, c) => t + (Number(c.amount) || 0), 0)}</Currency>
              </div>
              <div style={{flex:1, textAlign:'right'}}>
                <Rule style={{marginBottom:8}}/>
                <Currency large bold>{data.credits.reduce((t, c) => t + (Number(c.debitSubtotal) || 0), 0)}</Currency>
              </div>
              <div style={{flex:1, textAlign:'right'}}>
                <Rule style={{marginBottom:8}}/>
                <Currency large bold>{data.credits.reduce((t, c) => t + (Number(c.debitAmount) || 0), 0)}</Currency>
              </div>
              <div style={{flex:1, textAlign:'right'}}>
                <Rule style={{marginBottom:8}}/>
                <Currency large bold>{data.credits.reduce((t, c) => t + (Number(c.debitAgencyAmount) || 0), 0)}</Currency>
              </div>
            </div>
          }

        </Container>

      </div>

    </div>
  );
}
