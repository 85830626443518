import React, { useState, useEffect, useContext } from 'react';
import firstMile from './assets/firstmile.gif';
import { Button, Text, ThemeContext } from 'skybolt-ui';

export default function(props) {
  const [line, setLine] = useState(0);
  
  
  useEffect(() => {
    const t1 = setTimeout(() => setLine(1), 1000);
    const t2 = setTimeout(() => setLine(2), 2500);
    const t3 = setTimeout(() => setLine(3), 5000);
    const t4 = setTimeout(() => setLine(4), 7500);
    
    return () => {
      clearTimeout(t1);
      clearTimeout(t2);
      clearTimeout(t3);
      clearTimeout(t4);
    };
  }, []);
  
  const theme = useContext(ThemeContext);
  
  const Line = ({i, children}) => (
    <Text 
      paragraph
      color={theme.textLighter}
      size={'large'} 
      style={{
        opacity:line > i ? 1 : 0.5, 
        transition:'all 2000 ease-in-out',
      }}>
      {children}
    </Text>
  );
  
  return (
    <div>
      <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', flexWrap:'wrap'}}>
        <div style={{marginRight:32}}>
          <img alt="demonstration" src={firstMile} style={{width:"100%", maxWidth:400}}/>
        </div>
        <div>
          <Line i={0}>Enter a short Biography.</Line>
          <Line i={1}>Add experience.</Line>
          <Line i={2}>Drag to organize your experience.</Line>
          <Line i={3}>Enter special skills.</Line>
        </div>
      </div>
      <div style={{textAlign:'center'}}>
        <Button raised accent onClick={props.onClick}>Got It!</Button>
      </div>
    </div>
  );
}
