/*
Talent flagged as requiring a subscription are redirected here.
*/

import React, { useRef, useLayoutEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouter,
} from 'skybolt-ui';
import { useQuery } from 'skybolt-api';

import Plans from './Plans';
import Payment from './Payment';
import Check from './Check';
import Thanks from './Thanks';
import ChargeAgency from './ChargeAgency';



function SnowComponent() {
  let wrapper = useRef();
  let app = useRef();
  let instance = useRef();

  useLayoutEffect(() => {
    let Component = window.skyboltsnow.TalentSubscription;
    if(app.current == Component) {
      return;
    }

    if(instance.current) {
      instance.current.$destroy();
    }
    
    app.current = Component;
    instance.current = new Component({target:wrapper.current});
    window.skyboltsnow.attach(wrapper.current);

  }, [wrapper.current, window.skyboltsnow]);

  return (
    <div
      ref={wrapper}
      style={{
        display: 'block',
        width: '100%',
      }}
    />
  );
}


export default function TalentSubscription() {
  const route = useRouter();
  const {url, path} = route.match;
  const talentId = route.match.params.talentId;

  const data = useQuery(`{
    talent(talentId:$talentId) {
      talentId
      agency {
        agencyId
        useStripe
      }
    }
  }`, {talentId});


  if(!data) {
    return null;
  }

  if(data.talent.agency.useStripe) {
    return <SnowComponent/>;
  }

  return (
    <Switch>
      <Route path={`${path}/check`} component={Check}/>
      <Route path={`${path}/plan`} component={Plans}/>
      <Route path={`${path}/payment`} component={Payment}/>
      <Route path={`${path}/thanks`} component={Thanks}/>
      <Route path={`${path}/charge-to-agency`} component={ChargeAgency}/>
      <Redirect to={`${url}/check`}/>
    </Switch>
  );
}