import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import muiThemeable from 'material-ui/styles/muiThemeable';
import {
  addMessage,
  loadThread,
  subscribeToThreadMessages,
  subscribeToThreadThreads,
  unsubscribeFromThreadMessages,
  unsubscribeFromThreadThreads,
} from 'actions';
import { isEmpty } from 'lodash';

import Button from 'ui/Button';

import Message from './Message';
import SubthreadLI from './SubthreadLI';
import Subthread from './Subthread';
import Composer from './Composer';
import Receipts from './Receipts';




const mapStateToProps = (state, ownProps) => {
  const { url, path } = ownProps.match || {};
  const history = ownProps.history;
  const location = ownProps.location;

  const thread = ownProps.thread || state.threads.all[ownProps.threadId] || {};
  const threadId = thread.threadId;
  const userId = state.user.id;


  let recipients = Object.keys(thread.recipients || {})
    .map(id => thread.recipients[id])
    .slice(0, 5)
    .reduce((all, r) => !userId || r.userId !== userId ? [...all, r.name || r.email || r.phone] : all, [])
    .join(', ');
  if(thread.recipients && thread.recipients.length > 5) {
    recipients += ` ...and ${thread.recipients.length - 5} more.`;
  }

  const subject = thread.subject || "(No Subject)";

  const message = thread.message;

  const replies = state.messages.byThread[threadId] || {};

  const allThreads = state.threads.byThread[threadId] || {};
  const threads = {};
  Object.keys(allThreads)
    .map(id => ({...allThreads[id], id}))
    .forEach(thread => {
      if(thread.hasMessages > 1) {
        threads[thread.id] = thread;
      }
    });

  const showReply = thread.canReply !== false;

  return {
    url, path, history, location,
    threadId,
    subject,
    recipients,
    message,
    replies,
    threads,
    showReply,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  addMessage,
  loadThread,
  subscribeToThreadMessages,
  subscribeToThreadThreads,
  unsubscribeFromThreadMessages,
  unsubscribeFromThreadThreads,
};





class Thread extends Component {

  state = {
    showReceipts: false,
  }

  UNSAFE_componentWillMount() {
    const threadId = this.props.threadId;
    this.props.loadThread(threadId);
    this.props.subscribeToThreadMessages(threadId);
    this.props.subscribeToThreadThreads(threadId);
  }

  componentWillUnmount() {
    const threadId = this.props.threadId;
    this.props.unsubscribeFromThreadMessages(threadId);
    this.props.unsubscribeFromThreadThreads(threadId);
  }



  reply = message => {
    const threadId = this.props.threadId;
    const packId = this.props.packId;
    this.props.addMessage(threadId, {...message, packId});
  }

  toggleReceipts = () => {
    this.setState({
      showReceipts: !this.state.showReceipts
    });
  }

  render() {
    const {
      path, url, history, location,
      threadId,
      onClose,
      subject,
      recipients,
      receipts,
      message,
      replies,
      threads,
      showReply,
      // muiTheme:{ palette },
    } = this.props;


    const messageEls = Object.keys(replies)
      .map(id => ({...replies[id], id}))
      .map(m =>
        <Message key={m.id} message={m}/>
      );


    return (
      <div
        className="threadslist-thread">

        <div className="threadslist-thread-subject">{subject}</div>

        {this.state.showReceipts ? (
          <div onClick={this.toggleReceipts}>
            <Receipts threadId={threadId}/>
          </div>
        ) : (
          <div className="threadslist-thread-recipients" onClick={this.toggleReceipts}>
            <span>To {recipients}</span>
            {!isEmpty(receipts) && <span> ...more</span>}
          </div>
        )}



        {message &&
          <div className="threadslist-thread-message">
            <Message message={message}/>
          </div>
        }

        {messageEls.length > 0 &&
          <div className="threadslist-thread-messages">
            {messageEls}
          </div>
        }

        {Object.keys(threads || {}).length > 0 &&
          <div className="threadslist-thread-subthreads">
            <Route path={`${path}/:threadId?`} render={props => {
              const threadId = props.match.params.threadId;
              return Object.keys(threads)
                .map(id => ({id, ...threads[id]}))
                .sort((a, b) => a.dateUpdated < b.dateUpdated ? 1 : -1)
                .map(thread => {
                  if(thread.id === threadId) {
                    return (
                      <Subthread
                        key={thread.id}
                        threadId={threadId}
                        onClose={e => history.replace({pathname:url, state:location.state})}
                      />
                    );
                  }
                  else {
                    return (
                      <SubthreadLI
                        key={thread.id}
                        thread={thread}
                        threadId={thread.id}
                        onSelect={() => history.replace({pathname:`${url}/${thread.id}`, state:location.state})}
                      />
                    );
                  }
                });
            }}/>
          </div>
        }

        {showReply &&
          <Composer
            className="threadslist-thread-composer"
            threadId={threadId}
            onSend={this.reply}
          />
        }

        {onClose &&
          <Button
            icon="close"
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
            className="threadslist-thread-close"
          />
        }

      </div>
    );
  }
}




export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    muiThemeable()(
      Thread
    )
  )
);