import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadTalentPacks, loadLegacyTalentPacks } from 'actions';
import { isEmpty } from 'lodash';
import moment from 'moment';

import './index.css';

import Title from 'ui/Title';
import Subtitle from 'ui/Subtitle';
import Loading from 'ui/Loading';
import PackLI from 'ui/PackLI';
import Placeholder from 'ui/Placeholder';
import Button from 'ui/Button';


export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const packs = state.packs.byTalent[talentId];
  const legacyPacks = state.packs.legacyByTalent[talentId];
  return {
    talentId,
    packs,
    legacyPacks,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  loadTalentPacks,
  loadLegacyTalentPacks,
};




export class TalentPackHistory extends Component {
  UNSAFE_componentWillMount() {
    this.props.loadTalentPacks(this.props.talentId);
  }

  loadLegacyPacks = () => {
    this.props.loadLegacyTalentPacks(this.props.talentId);
  }

  renderPacks() {
    const packs = this.props.packs;

    if(!packs) {
      return <Loading/>;
    }

    if(isEmpty(packs)) {
      return (
        <Placeholder
          icon="history"
          message="Package history will appear here."
        />
      );
    }

    return Object.keys(packs)
      .map(id => ({...packs[id], id}))
      .sort((a, b) => a.dateCreated < b.dateCreated ? 1 : -1)
      .map(pack =>
        <PackLI pack={pack} key={pack.id}/>
      );
  }

  renderLegacyPacks() {
    const packs = this.props.legacyPacks;

    if(!packs) {
      return (
        <Button
          label="Load Legacy Packages"
          onClick={this.loadLegacyPacks}
        />
      );
    }

    if(packs.isLoading) {
      return <Loading/>;
    }

    if(isEmpty(packs)) {
      return null;
    }

    return (
      <div>
        <Subtitle style={{padding:'16px 0 8px 0'}}>Legacy Packages</Subtitle>

        {Object.keys(packs)
          .map(packId => packs[packId])
          .sort((a,b) => a.date < b.date ? 1 : -1)
          .map(pack =>
            <div key={pack.id} className="talent-packhistory-legacypack">
              <div className="talent-packhistory-sent">
                {moment(pack.date).format("MM/DD/YYYY")}
              </div>
              <div className="talent-packhistory-sentto">
                To {pack.to}
              </div>
              <div className="talent-packhistory-sentby">
                From {pack.from}
              </div>
              <div className="talent-packhistory-subject">
                {pack.subject}
              </div>
              <div className="talent-packhistory-text">
                {pack.text}
              </div>
            </div>
          )
        }
      </div>
    );
  }

  render() {
    return (
      <div>
        <Title>Package History</Title>
        {this.renderPacks()}
        {this.renderLegacyPacks()}
      </div>
    );
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(TalentPackHistory);