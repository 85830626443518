/* global location */

import React, { useState, useContext } from 'react';
import { Loading, Container, Text, Card, Rule, Button, Modal, Form, InputText, InputSwitch, InputHTML, Icon, ThemeContext } from 'skybolt-ui';
import { useQuery, approveMedia, updateMedia, removeMedia, sendMessage } from 'skybolt-api';

function youtubeId(url){
  var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[1].length === 11) ? match[1] : "";
}

function vimeoId(url){
  var regExp = /https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  var match = url.match(regExp);
  return (match && match[2]) ? match[2] : "";
}



export default props => {

  const [ mediaModal, setMediaModal ] = useState({open:false});
  const [ messageModal, setMessageModal ] = useState({open:false, talent:{}});
  const [ selection, setSelection ] = useState([]);
  const [ privateMedia, setPrivate ] = useState({});

  const data = useQuery(`{
    unapprovedMedia {
      mediaId
      title
      type
      image(height:240)
      fullImage: image(width:768)
      video
      format
      url
      private
      talentId
      talent {
        talentId
        fullName
        status
      }
    }
    cannedMessages
  }`);


  async function approve(mediaId) {
    if(privateMedia[mediaId]) {
      await updateMedia({mediaId:mediaId, update:{private:!!privateMedia[mediaId]}});
    }
    await approveMedia({mediaId});
  }

  async function approveSelected() {
    for(let sel of selection) {
      await approve(sel);
    }
    setSelection([]);
  }

  async function reject(media) {
    const form = (
      <div>
        <Text paragraph large>Reject Media</Text>
        <InputHTML title="Message Talent" name="message" hint="Leave blank to not send message."/>
      </div>
    );
    const res = await Modal.form(form, {yes:"Reject", no:"Cancel"});

    if(!res) {
      return;
    }

    if(res.message) {
      await sendMessage({
        subject: "Skybolt Media",
        talentId: media.talentId,
        message: res.message,
      });
    }

    await removeMedia({mediaId:media.mediaId});
  }

  async function rejectSelected() {
    const form = (
      <div>
        <Text paragraph large>Reject Media</Text>
        <InputHTML title="Message Talent" name="message" hint="Leave blank to not send message."/>
      </div>
    );
    const res = await Modal.form(form, {yes:"Reject", no:"Cancel"});

    if(!res) {
      return;
    }

    let talentIds = selection.reduce((ids, mediaId) => {
      let mediaItem = data.unapprovedMedia.find(i => i.mediaId === mediaId);
      if(!ids.includes(mediaItem.talentId)) {
        ids.push(mediaItem.talentId);
      }
      return ids;
    }, []);

    if(res.message) {
      for(let talentId of talentIds) {
        await sendMessage({
          subject: "Skybolt Media",
          talentId: talentId,
          message: res.message,
        });
      }
    }

    for(let mediaId of selection) {
      await removeMedia({mediaId});
    }

    setSelection([]);
  }

  async function send() {
    let message = {
      talentId: messageModal.talent.talentId,
      subject: messageModal.subject,
      message: messageModal.message,
    };

    if(!message.talentId) {
      await Modal.error("Soemthing went wrong. Please try again.");
      return;
    }

    if(!message.subject || !message.message) {
      await Modal.alert("Please make sure you have a Subject and a Message.");
      return;
    }

    sendMessage(message);
    setMessageModal({...messageModal, open:'sent'});
  }

  function selectHandler(mediaId) {
    return sel => {
      if(sel) {
        setSelection([...selection, mediaId]);
      }
      else {
        setSelection(selection.filter(s => s !== mediaId));
      }
    };
  }

  function selectAllMedia(media) {
    let ids = media.map(m => m.mediaId);
    let sel = [...selection, ...ids];
    sel = sel.reduce((all, i) => {
      if(all.includes(i)) {
        return all;
      }
      return [...all, i];
    }, []);
    setSelection(sel);
  }


  // Render

  const theme = useContext(ThemeContext);

  if(!data) {
    return <Loading/>;
  }

  const byTalent = data.unapprovedMedia
    .reduce((all, m) => {
      let t = all.find(t => t.talentId === m.talent.talentId);
      if(!t) {
        t = {
          talentId: m.talent.talentId,
          status: m.talent.status,
          name: m.talent.fullName,
          media: []
        };
        all.push(t);
      }
      t.media.push({
        mediaId: m.mediaId,
        type: m.type,
        format: m.format,
        url: m.url,
        image: m.image,
        fullImage: m.fullImage,
        video: m.video,
        private: m.private,
      });
      return all;
    }, [])
    // This just leaves media approvals hanging and
    // makes counting outstanding media for approval hard.
    // Rather work on clearing out media for approval when
    // a talent cancels, etc.
    // .filter(t => t.status === "PAID" || t.status === "TRIAL")
    .sort((a, b) => b.name < a.name ? 1 : -1);

  return (
    <Container>


      <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
        <Text title>APPROVE MEDIA</Text>
        <div style={{flex:1}}/>
        {selection.length > 0 &&
          <Text block small light style={{lineHeight:"22px", marginRight:8}}>With {selection.length} media items:</Text>
        }
        {selection.length > 0 &&
          <Button onRule onClick={approveSelected}>
            <Icon name="thumbs-up" small/>
          </Button>
        }
        {selection.length > 0 &&
          <Button onRule onClick={rejectSelected}>
            <Icon name="thumbs-down" small/>
          </Button>
        }
      </div>
      <Rule style={{marginBottom:24}}/>


      {byTalent.map(talent =>
        <div key={talent.talentId} style={{padding:"8px 16px 16px 16px", marginBottom:16, backgroundColor:'white'}}>
          <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start', marginBottom:8}}>
            <Button style={{marginLeft:-16}} href={{pathname:`/talents/${talent.talentId}/media`, state:{modal:true}}}>
              <Text large>{talent.name} <Icon inline small name="external-link-square" style={{marginLeft:4}} color="textLighter"/></Text>
            </Button>
            <div style={{flex:1}}/>
            <Button label="Select All" color="textLight" onClick={() => selectAllMedia(talent.media)}/>
            <Button icon="send" label="Send Message" color="textLight" onClick={() => setMessageModal({open:true, talent})}/>
          </div>
          <div style={{display:'flex', justifyContent:'flex-start', flexWrap:'wrap'}}>
            {talent.media.map(media =>
              <Card depth={1} key={media.mediaId} style={{position:'relative', padding:8, marginRight:8, marginBottom:8, minWidth:240, textAlign:'center'}}>
                <Button onClick={() => setMediaModal({...media, open:true})} style={{padding:0}}>
                  <img src={media.image} alt={media.title || talent.name}/>
                </Button>
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                  <Icon size="24" color="textLight"
                    name={
                      media.type === 'link' && media.format === 'youtube' ? "youtube-square" :
                      media.type === 'link' && media.format === 'vimeo' ? "vimeo-square" :
                      media.type === 'video' ? "film" :
                      "image"
                    }
                    brands={media.type === 'link'}
                  />
                  <div>
                    <Button 
                      icon={privateMedia[media.mediaId] ? 'lock' : 'lock-open-alt'} 
                      color={privateMedia[media.mediaId] ? 'accent' : 'grey'} 
                      onClick={() => {
                        setPrivate({
                          ...privateMedia, 
                          [media.mediaId]: !privateMedia[media.mediaId]
                        });
                      }}
                    />
                    <Button icon="thumbs-up" onClick={() => approve(media.mediaId)}/>
                    <Button icon="thumbs-down" onClick={() => reject({talentId:talent.talentId, mediaId:media.mediaId})}/>
                  </div>
                </div>
                <InputSwitch large value={selection.includes(media.mediaId)} onChange={selectHandler(media.mediaId)} style={{position:'absolute', left:0, top:0, padding:16}}/>
              </Card>
            )}
          </div>
        </div>
      )}


      <Modal status={mediaModal.open} onRequestClose={() => setMediaModal({...mediaModal, open:false})}>
        {mediaModal.type === 'video' ? (
          <div style={{padding:"32px 16px"}}>
            <video controls style={{width:'100%', maxHeight:'80vh'}}>
              <source src={mediaModal.video} type="video/mp4"/>
            </video>
          </div>
        ) : mediaModal.type === 'link' && mediaModal.format === 'youtube' ? (
          <div style={{padding:"32px 16px"}}>
            <iframe title="video" type="text/html" width="100%" height="400" src={`https://www.youtube.com/embed/${youtubeId(mediaModal.url)}?origin=${window.location.origin}`} frameBorder="0"></iframe>
          </div>
        ) : mediaModal.type === 'link' && mediaModal.format === 'vimeo' ? (
          <div style={{padding:"32px 16px"}}>
            <iframe title="video" src={`https://player.vimeo.com/video/${vimeoId(mediaModal.url)}`} width="100%" height="400" frameBorder="0"></iframe>
          </div>
        ) : (
          <div style={{padding:16}}>
            <img src={mediaModal.fullImage} style={{width:'100%'}} alt={mediaModal.title}/>
          </div>
        )}
      </Modal>


      <Modal status={messageModal.open} onRequestClose={() => setMessageModal({...messageModal, open:false})}>
        <Form value={messageModal} onChange={value => setMessageModal({...messageModal, ...value})} style={{padding:16}}>
          <Text large paragraph>Message {messageModal.talent.name}</Text>
          <InputText name="subject" title="Subject"/>
          <InputHTML name="message" title="Message" style={{minHeight:150}}/>
        </Form>
        <div>
          <Text block small light style={{paddingLeft:16}}>Canned Messages: </Text>
          {data.cannedMessages.map(item =>
            <Button key={item.cannedMessageId} label={item.name} onClick={value => setMessageModal({...messageModal, subject:item.subject, message:item.body})}/>
          )}
        </div>
        <div style={{height:48, display:'flex', justifyContent:'flex-end'}}>
          <Button icon="send" style={{padding:16, backgroundColor:theme.color.greyLight, borderRadius:0}} onClick={send}/>
        </div>
      </Modal>


    </Container>
  );
};