import React from 'react';
import Button from 'ui/Button';


export default ({
  hidden=false,
  visible=true,
  label="",
  icon=null,
  onClick,
  primary=false,
  ...props
}) => {
  
  if(hidden === true || visible === false) {
    return null;
  }
  
  if(primary) {
    return (
      <Button 
        icon={icon}
        label={label}
        onClick={onClick}
        active={true}
        style={{marginRight:12}}
      />
    );
  }
  
  return (
    <Button 
      icon={icon}
      iconSize={22}
      isReversed={true}
      tooltip={label}
      style={{marginLeft:-12}}
      onClick={onClick}
      color="#9e9e9e"
    />
  );
};
